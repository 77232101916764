<div class="row" [ngBusy]="busy">
    <div class="col-12">
        <div class="card card-main-header bg-gradient-1">
            <div class="card-body">
                <img class="bg-gradient-light" src="/assets/img/flats/payment.png">
                <div class="card-content row align-items-center">
                    <div class="col">
                        <h1>Le tue fatture</h1>
                        <hr>
                        <h2 class="mb-0">Lista delle fatture che hai già emesso o devi emettere.</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box elevation-1">
            <span class="info-box-icon bg-warning"><i class="fa fa-clock"></i></span>

            <div class="info-box-content">
                <span class="info-box-text">Fatture da approvare</span>
                <span class="info-box-number">{{ waitingInvoices }} fatture</span>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box elevation-1">
            <span class="info-box-icon bg-success"><i class="fa fa-check-circle"></i></span>

            <div class="info-box-content">
                <span class="info-box-text">Fatture approvate</span>
                <span class="info-box-number">{{approvedInvoices}} fatture</span>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box elevation-1">
            <span class="info-box-icon bg-info"><i class="fa fa-euro-sign"></i></span>

            <div class="info-box-content">
                <span class="info-box-text">Totale fatturato</span>
                <span class="info-box-number">{{ totalInvoiced | currency: '€ ' }}</span>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box elevation-1">
            <span class="info-box-icon bg-danger"><i class="fa fa-hand-holding-usd"></i></span>

            <div class="info-box-content">
                <span class="info-box-text">Totale da saldare</span>
                <span class="info-box-number">{{ totalToBePaid | currency: '€ ' }}</span>
            </div>
        </div>
    </div>
</div>

<div class="callout callout-warning" *ngIf="me.profile.stato_fi < 4">
    <h5><i class="fas fa-exclamation-circle"></i> Attenzione!</h5>

    <p>Abbiamo lanciato il nuovo metodo di fatturazione, dove emettiamo per tuo conto le fatture solo ed esclusivamente
        vs Master Group.</p>
    <p *ngIf="me.profile.stato_fi === 0">Il tuo profilo però non è ancora completo e per procedere alla visualizzazione e conferma dei compensi ricevuti, devi completare il profilo. <button class="btn btn-sm btn-success" [routerLink]="'/profile'">Clicca qui.</button></p>
    <p *ngIf="me.profile.stato_fi === 1">Abbiamo provato ad anagrafarti ma i dati che ci hai fornito non sono corretti. Apri un ticket per risolvere il problema.</p>
    <p *ngIf="me.profile.stato_fi === 2">Stiamo completando la tua registrazione, a breve riceverai la PEC dove dovrai accettare la delega.</p>
    <p *ngIf="me.profile.stato_fi === 3">Ti abbiamo inviato una pec contenente un link che dovrai premere per delegarci alla fatturazione automatica.</p>
</div>

<app-table tableTitle="Fatture" [pageSize]="20" [small]="true" [hideFooterDescription]="true" baseUrl="/fatturazione/fattura/" [rows]="rows" [columns]="columns"></app-table>
