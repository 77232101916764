<div class="row">
    <div class="col-12">
        <div class="card card-main-header {{gradient}}">
            <div class="card-body">
                <img class="bg-gradient-light" [src]="icon">
                <div class="card-content row align-items-center">
                    <div class="col">
                        <h1>{{title}}</h1>
                        <hr>
                        <div class="row">
                            <div class="col">
                                <div class="form-group with-icon">
                                    <label>Seleziona periodo</label>
                                    <i class="fas fa-crosshairs"></i>
                                    <select class="form-control" (change)="onFilterChange.emit($event.target.value)">
                                        <option value="" selected>Seleziona un mese</option>
                                        <option *ngFor="let month of months" [value]="month.key">{{month.value}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
