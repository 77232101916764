import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import * as moment from 'moment';

const currencyFormat = 'EUR';

@Injectable({
    providedIn: 'root'
})
export class MasterPlanningCalculationService {

    constructor() {
    }

    public calculatePlanTotal(items) {
        let total = 0;
        const obj = 'plan';
        if (items) {
            for (const item of Object.keys(items)) {
                const plan = items[item][obj];
                if (plan) {
                    total = total + parseFloat(plan.value);
                }
            }
        }
        return Number(total);
    }

    public calculateBreakDownTotal(type, monthsList, item, objName = 'plan') {
        let total = 0;
        for (const month of monthsList) {
            const breakdown = item[month].breakdown[type];
            if (breakdown && objName === 'plan' && breakdown[objName]) {
                total = total + Number(breakdown[objName].value);
            } else if (breakdown && objName === 'real' && breakdown[objName]) {
                total = total + Number(breakdown[objName]);
            }
        }
        return this.formatNumber(total);
    }

    public calculateTotal(items, obj) {
        let total = 0;
        if (items) {
            for (const item of Object.keys(items)) {
                const value = items[item][obj];
                if (value) {
                    total = total + Number(value);
                }
            }
        }
        return Number(total);
    }

    public calculateProjectionValue(value) {
        const currentDay = moment().format('D');
        const daysInMonth = moment().daysInMonth();
        return this.percentageFormat(Number(value) / Number(currentDay) * daysInMonth);
    }

    public formatNumber(num) {
        return parseFloat(num).toLocaleString(undefined, {maximumFractionDigits: 2});
    }

    public formatMonth(date: any) {
        if (date) {
            return moment(date).format('MMM');
        }
    }

    public getObjectKeysList(items) {
        if (items) {
            return Object.keys(items);
        }

        return [];
    }

    average = list => list.reduce((prev, curr) => prev + curr) / list.length;

    public salesCalculation(monthsList, items, breakDownTypes) {
        const mnpBreakDownList = [];
        const apBreakDownList = [];
        const increaseList = [];
        const percentList = [];
        const mnpBreakDownType = 'mnp';
        const apBreakDownType = 'al';
        monthsList.forEach((month) => {
            const item = items[month];
            if (item.shops !== 0) {
                increaseList.push(Number((item.real / item.shops).toFixed(2)));
            }
            if (item.breakdown) {
                breakDownTypes.forEach((type) => {
                    if (item.real !== 0) {
                        if (type === mnpBreakDownType) {
                            mnpBreakDownList.push(item.breakdown[type].real * 100 / item.real);
                        } else if (type === apBreakDownType) {
                            apBreakDownList.push(item.breakdown[type].real * 100 / item.real);
                        }
                    }
                });
            }
        });
        this.increaseCtrShop(increaseList, percentList);
        const mnpBreakDownAverage = mnpBreakDownList.length > 0 ? this.percentageFormat(this.average(mnpBreakDownList)) : '--';
        const apBreakDownAverage = apBreakDownList.length > 0 ? this.percentageFormat(this.average(apBreakDownList)) : '--';
        return {
            salesIncreaseValue: percentList.length > 0 ? this.percentageFormat(this.average(percentList)) : '--',
            involvementBreakDown: mnpBreakDownAverage + ' / ' + apBreakDownAverage
        };
    }

    increaseCtrShop(increaseList, percentList) {
        for (let i = 0; i < increaseList.length - 1; i++) {
            if (increaseList[i] > 0) {
                const per = (((increaseList[i + 1] / increaseList[i]) - 1) * 100).toFixed(2);
                percentList.push(Number(per));
            }
        }
    }

    public percentage(a, b) {
        let percentage = 0;
        if (a !== 0) {
            percentage = (((b / a) - 1) * 100);
        }
        return percentage;
    }

    calculatedCtrPerShopAverage(items) {
        const list = [];
        if (items) {
            for (const item of Object.keys(items)) {
                if (items[item]?.contract_per_shop) {
                    list.push(Number(items[item]?.contract_per_shop));
                }
            }
        }
        return list.length > 0 ? this.average(list) : 0;
    }

    public currencyFormat(num: number) {
        return (num).toLocaleString(environment.language, {
            style: 'currency',
            currency: currencyFormat,
            maximumFractionDigits: 2
        });
    }

    public percentageFormat(num) {
        return Number(num / 100).toLocaleString(undefined, {style: 'percent', maximumFractionDigits: 2});
    }

    public revenueCalculation(monthsList, items) {
        const masterList = [];
        const dealerList = [];
        const agentsList = [];
        monthsList.forEach((month) => {
            const item = items[month];
            if (item.revenue_per_contract && item.revenue_per_contract > 0) {
                masterList.push(Number(item.real) / Number(item.sales));
                dealerList.push(Number(item.shop_commission) / Number(item.sales));
                agentsList.push(Number(item.agent_commission) / Number(item.sales));
            }
        });
        return {
            master: masterList.length > 0 ? this.currencyFormat((this.average(masterList))) : '--',
            dealer: dealerList.length > 0 ? this.currencyFormat((this.average(dealerList))) : '--',
            agent: agentsList.length > 0 ? this.currencyFormat((this.average(agentsList))) : '--',
        };
    }
}
