import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Provincia} from '@models/provincia';
import {Comune} from '@models/comune';
import {DataService} from '@services/data.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
    @Output() isValid = new EventEmitter();
    @Output() addressData = new EventEmitter();
    @Input() modalSize = false;

    @Input()
    set startData (value) {
        this._startData = value;
        this.setFormData();
    }

    get startData() {
        return this._startData;
    }

    _startData = {
        city: null,
        province: null,
        pcode: null,
        address: null,
        number: null,
    };

    cities: any;
    addressForm: FormGroup;

    province: Provincia[] = [];
    comuni: Comune[] = [];
    busy: Subscription;

    @Input()
    small = false;
    @Input()
    fullColumn = false;

    constructor(
        private dataService: DataService,
        private formBuilder: FormBuilder,
    ) {
    }

    ngOnInit() {
        this.dataService.get('/get-province').subscribe((data) => this.province = data.result);

        this.setFormData();

        this.addressData.emit({
            city: this.c().city.value,
            province: this.c().province.value,
            pcode: this.c().pcode.value,
            address: this.c().address.value,
            number: this.c().number.value,
        });
        this.isValid.emit(this.addressForm.valid);
    }

    c() {
        return this.addressForm.controls;
    }

    setAddress(data: any) {
        if (data.postalCode) {
            this.c().pcode.setValue(data.postalCode);
        }
        if (data.address) {
            this.c().address.setValue(data.address);
        }
        if (data.streetNumber) {
            this.c().number.setValue(data.streetNumber);
        }
        if (data.province) {
            const province = this.province.find(item => item.sigla === data.province);
            if (province) {
                this.c().province.setValue(province.provincia);

                if (data.city) {
                    this.c().city.setValue(data.city);
                }
            }
        }
    }

    private setFormData() {
        this.addressForm = this.formBuilder.group({
            city: [this.startData.city, [Validators.required]],
            province: [this.startData.province, [Validators.required]],
            pcode: [this.startData.pcode, [Validators.required]],
            address: [this.startData.address, [Validators.required]],
            number: [this.startData.number, [Validators.required]],
        });

        this.c().province.valueChanges.subscribe((value) => {
            if (value) {
                this.busy = this.dataService.post('/get-comuni', {id_provincia: this.province.find(item => item.provincia === value).id})
                    .subscribe((data) => this.comuni = data.result);
            }
        });

        this.addressForm.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        )
            .subscribe((data) => {
                this.isValid.emit(this.addressForm.valid);

                this.addressData.emit({
                    city: this.c().city.value,
                    province: this.c().province.value,
                    pcode: this.c().pcode.value,
                    address: this.c().address.value,
                    number: this.c().number.value,
                });
            });

        this.isValid.emit(this.addressForm.valid);
    }
}
