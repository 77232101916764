<div [ngBusy]="busy">
    <div class="jumbotron bg-gradient-light elevation-1" [ngBusy]="busy">
        <div class="d-inline-block">
            <h1 class="header mb-0">Avanzamento Contratti</h1>
            <p class="subtitle mb-4">Qui puoi controllare l'avanzamento dei contratti.</p>
        </div>
        <img src="/assets/img/brands/{{this.brand}}.png" class="header-logo float-right"/>
    </div>
    <div class="row">
        <div class="col-12">
            <app-table [rows]="rows" [columns]="columns" [export]="true"></app-table>
        </div>
    </div>
</div>
