import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';

export class StoreOperator extends BaseModel {
    id: number;
    name: string;
    description: string;
}

export class StoreOperatorPagination extends BaseModel {
    data: StoreOperator[];
    _meta: MetaPagination;
    _links: Array<any>;
}
