import {BaseModel} from '@models/base-model';

export class Rule extends BaseModel {
    id: number;
    campaign_id: number;
    vendor_segment_id: number;
    points: number;
    offer: string;
    count: number;
}
