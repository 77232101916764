import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Document} from '@models/invoice/document';
import {InvoiceDocumentService} from '@services/invoice-document.service';

@Component({
    selector: 'app-payment-view',
    templateUrl: './payment-view.component.html',
    styleUrls: ['./payment-view.component.css']
})
export class PaymentViewComponent implements OnInit {
    @Input() document: Document;
    currency = '€';
    localFormat = 'DD/MM/YYYY';

    constructor(private modalService: NgbModal) {
    }

    ngOnInit(): void {
        console.log(this.document);
    }

    dismissModal() {
        this.modalService.dismissAll();
    }

    findMethod(dueMethod) {
        const method = InvoiceDocumentService.dueMethods.find(item => item.key === dueMethod);
        return method ? method.name : dueMethod;
    }
}
