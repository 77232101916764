<div class="login-box" [ngBusy]="busy">
    <div class="card">
        <div class="card-body login-card-body rounded elevation-1">
            <img src="/assets/img/logo_full.png" width="100%" class="mb-5">

<!--            <p class="login-box-msg">Inserisci i dati di accesso</p>-->

            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="input-group mb-3">
                    <input
                            formControlName="email"
                            type="text"
                            class="form-control"
                            placeholder="Username"
                    />
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-user"></span>
                        </div>
                    </div>
                </div>
                <div class="input-group mb-3">
                    <input
                            formControlName="password"
                            type="password"
                            class="form-control"
                            placeholder="Password"
                    />
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-lock"></span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- /.col -->
                    <div class="col-12">
                        <button
                                type="submit"
                                class="btn bg-gradient-yellow btn btn-block"
                        >
                            Accedi <i class="fas fa-sign-in-alt float-right pt-1"></i>
                        </button>
                        <div class="text-muted mt-3 mb-0">
                            Se hai dimenticato la password, puoi <a [routerLink]="['/reset-password']">resettarla
                            qui</a>.
                        </div>
                    </div>
                    <!-- /.col -->
                </div>
                <hr>
                <div class="row">
                    <div class="col-12">
                        <ul class="list-group list-group-horizontal no-bullet justify-content-center">
                            <li class="mr-2">
                                <a class="btn btn-circle btn-facebook" type="button"
                                   href="https://www.facebook.com/MasterGroupTLC" target="_blank"><i
                                        class="fab fa-facebook-f"></i>
                                </a>
                            </li>
                            <li class="mr-2">
                                <a class="btn btn-circle btn-linkedin" type="button"
                                   href="https://www.linkedin.com/company/master-group-master-dealer-telefonia-/"
                                   target="_blank"><i class="fab fa-linkedin-in"></i>
                                </a>
                            </li>
                            <li class="mr-2">
                                <a class="btn btn-circle btn-instagram" type="button"
                                   href="https://www.instagram.com/mastergroup_masterdealer/" target="_blank"><i
                                        class="fab fa-instagram"></i>
                                </a>
                            </li>
                            <li>
                                <a class="btn btn-circle btn-info" type="button" href="https://master-dealer.it"
                                   target="_blank"><i class="fa fa-globe"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </form>
        </div>
        <!-- /.login-card-body -->
    </div>
</div>
