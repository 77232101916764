import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Document, DocumentPayment} from '@models/invoice/document';
import {InvoiceDocumentService} from '@services/invoice-document.service';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import * as moment from 'moment';
import {Subject, SubjectPagination} from '@app/models/invoice/subject';
import {TinyMCE} from '@models/tinyMCE';

@Component({
    selector: 'app-document-modal',
    templateUrl: './document-modal.component.html',
    styleUrls: ['./document-modal.component.css']
})
export class DocumentModalComponent implements OnInit {
    @Input() documents: Document[] = [];
    @Input() selectedDocument: Document;
    @Output() refresh = new EventEmitter<any>();
    pickerOptions = {
        singleDatePicker: true,
        ranges: []
    };
    defaultConfig = new TinyMCE();
    busy: any;
    documentForm = new FormGroup({
        subject_id: new FormControl('', Validators.required),
        category: new FormControl(''),
        type: new FormControl('', Validators.required),
        number: new FormControl('', Validators.required),
        vat_date: new FormControl(''),
        net_amount: new FormControl('', [Validators.required,
            Validators.pattern('([0-9]*[.])?[0-9]+')]),
        vat_amount: new FormControl('', [Validators.required,
            Validators.pattern('([0-9]*[.])?[0-9]+')]),
        withholding_tax: new FormControl('', Validators.pattern('([0-9]*[.])?[0-9]+')),
        draft: new FormControl(''),
        note: new FormControl(''),
        pdf: new FormControl(''),
        xml: new FormControl(''),
        payments: new FormControl([]),
        vat_type: new FormControl(''),
        doc_date: new FormControl(''),
        is_credit: new FormControl(''),
    });
    date = {
        vat_date: '',
        doc_date: ''
    };
    submittedFile = {
        pdf: null,
        xml: null
    };
    subjects: Subject[] = [];
    types = InvoiceDocumentService.types;
    methods = InvoiceDocumentService.dueMethods;
    @ViewChild('paymentFormTemplate') private paymentFormTemplate;
    currency = '€';
    localFormat = 'DD/MM/YYYY';
    paymentModal: NgbModalRef;
    selectedPayment: DocumentPayment;
    selectedPaymentIndex;
    vatTypes = InvoiceDocumentService.vatTypes;

    constructor(
        private modalService: NgbModal,
        private invoiceDocumentService: InvoiceDocumentService,
        private dateRangePickerOptions: DaterangepickerConfig) {
    }

    ngOnInit(): void {
        if (this.selectedDocument) {
            this.loadSubjects(this.selectedDocument.company_name);
            this.loadData();
        } else {
            this.documentForm.get('doc_date').setValue(moment().format(this.dateRangePickerOptions.settings.locale.format));
            this.date.doc_date = moment().format(this.dateRangePickerOptions.settings.locale.apiFormat);
            this.loadSubjects();
        }
    }

    dismissModal() {
        this.subjects = [];
        this.modalService.dismissAll();
    }

    showPaymentModal(payment?: DocumentPayment, index?) {
        this.selectedPayment = payment ? payment : null;
        this.selectedPaymentIndex = index >= 0 ? index : null;
        this.paymentModal = this.modalService.open(this.paymentFormTemplate, {size: 'lg'});
    }

    saveDocument() {
        const document = new Document();
        document.subject_id = Number(this.documentForm.value.subject_id);
        document.category = this.documentForm.value.category;
        document.type = this.documentForm.value.type;
        document.number = this.documentForm.value.number;
        if (this.date.vat_date) {
            document.vat_date = this.date.vat_date;
        }
        document.date = moment().format(this.dateRangePickerOptions.settings.locale.apiFormat);
        document.net_amount = this.documentForm.value.net_amount;
        document.vat_amount = this.documentForm.value.vat_amount;
        document.withholding_tax = this.documentForm.value.withholding_tax;
        document.draft = this.documentForm.value.draft ? '1' : '0';
        document.is_credit = this.documentForm.value.is_credit ? '1' : '0';
        document.note = this.documentForm.value.note;
        document.document_pdf = this.submittedFile.pdf;
        document.document_xml = this.submittedFile.xml;
        document.payments = this.documentForm.value.payments;
        document.vat_type = this.documentForm.value.vat_type;
        if (this.date.doc_date) {
            document.date = this.date.doc_date;
        }
        if (this.selectedDocument) {
            return this.changeDocument(document);
        }
        return this.addDocument(document);
    }

    addDocument(document: Document) {
        this.busy = this.invoiceDocumentService.postDocument(document).subscribe((response: Document) => {
            this.documents.unshift(response);
            this.invoiceDocumentService.successAlert('Created Document!');
            this.refresh.emit(true);
            this.dismissModal();
        }, err => {
            this.invoiceDocumentService.errorAlert(err.message);
            this.dismissModal();
        });
    }

    changeDocument(document: Document) {
        this.busy = this.invoiceDocumentService.putDocument(this.selectedDocument.id, document).subscribe((response: Document) => {
            this.invoiceDocumentService.successAlert('Changed Document!');
            this.refresh.emit(true);
            this.dismissModal();
        }, err => {
            this.invoiceDocumentService.errorAlert(err.message);
            this.refresh.emit(true);
            this.dismissModal();
        });
    }

    selectedDate(value, date: Moment) {
        const selectedDate = date.format(this.dateRangePickerOptions.settings.locale.format);
        this.date[value] = date.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.documentForm.get(value).setValue(selectedDate);
    }

    loadData() {
        this.documentForm.setValue({
            subject_id: this.selectedDocument.subject_id,
            category: this.selectedDocument.category,
            type: this.selectedDocument.type,
            number: this.selectedDocument.number,
            vat_date: this.selectedDocument.vat_date ? moment(this.selectedDocument.vat_date)
                .format(this.dateRangePickerOptions.settings.locale.format) : '',
            net_amount: this.selectedDocument.net_amount,
            vat_amount: this.selectedDocument.vat_amount,
            withholding_tax: this.selectedDocument.withholding_tax,
            note: this.selectedDocument.note,
            draft: this.selectedDocument.draft === 1 || this.selectedDocument.draft === '1',
            pdf: this.selectedDocument?.pdf_file ? this.selectedDocument?.pdf_file : '',
            xml: this.selectedDocument?.xml_file ? this.selectedDocument?.xml_file : '',
            payments: this.selectedDocument.payments,
            vat_type: this.selectedDocument.vat_type,
            doc_date: this.selectedDocument.date ? moment(this.selectedDocument.date)
                .format(this.dateRangePickerOptions.settings.locale.format) : '',
            is_credit: this.selectedDocument.is_credit === 1 || this.selectedDocument.is_credit === '1',
        });
        this.date = {
            vat_date: this.selectedDocument.vat_date ? moment(this.selectedDocument.vat_date)
                .format(this.dateRangePickerOptions.settings.locale.apiFormat) : '',
            doc_date: this.selectedDocument.date ? moment(this.selectedDocument.date)
                .format(this.dateRangePickerOptions.settings.locale.apiFormat) : '',
        };
    }

    handleFileInput(file: File, key) {
        this.submittedFile[key] = file;
        this.documentForm.get(key).setValue(file.name);
    }

    handleFileRemoveModal(key) {
        this.documentForm.get(key).setValue('');
        this.submittedFile[key] = null;
    }

    get pdfFile() {
        return this.documentForm.get('pdf').value;
    }

    get xmlFile() {
        return this.documentForm.get('xml').value;
    }

    get payments() {
        return this.documentForm.get('payments').value as DocumentPayment[];
    }

    get totalToPay() {
        const vatType = this.documentForm.get('vat_type').value;
        const net = this.documentForm.get('net_amount').value;
        const vat = this.documentForm.get('vat_amount').value;
        const wht = this.documentForm.get('withholding_tax').value;
        if (!vatType) {
            return 0;
        }
        if (vatType === this.vatTypes[2].key) {
            return net && vat ? Number(net) - Number(wht) : 0;
        }
        return net && vat && wht ? Number(net) + Number(vat) - Number(wht) : 0;
    }

    get totalAmount() {
        const net = this.documentForm.get('net_amount').value;
        const vat = this.documentForm.get('vat_amount').value;
        return net && vat ? Number(net) + Number(vat) : 0;
    }

    get totalToPayPayment() {
        let sum = 0;
        this.payments.forEach((payment) => {
            sum += Number(payment?.amount);
        });
        return this.totalToPay > 0 ? this.totalToPay - sum : 0;
    }

    loadSubjects(term?) {
        const params = {};
        if (term) {
            params['company_name'] = term;
        }
        this.invoiceDocumentService.getSubject(params).subscribe((response: SubjectPagination) => {
            this.subjects = response.data;
        });
    }

    savePayment(payment: DocumentPayment) {
        if (!payment) {
            return;
        }
        const list = this.payments;
        if (this.selectedPayment) {
            if (this.selectedPayment?.id) {
                payment.id = this.selectedPayment.id;
            }
            list[this.selectedPaymentIndex] = payment;
            this.invoiceDocumentService.putPayment(this.selectedPayment?.id, payment).subscribe((response) => {
            });
        } else {
            list.unshift(payment);
        }
        this.documentForm.get('payments').setValue(list);
        return this.paymentModal.dismiss();
    }

    findMethod(dueMethod) {
        const method = this.methods.find(item => item.key === dueMethod);
        return method ? method.name : dueMethod;
    }

    removePayment(index) {
        const list = this.payments;
        list.splice(index, 1);
        this.documentForm.get('payments').setValue(list);
    }
}
