<div [ngBusy]="busy">
  <app-master-header
    [image]="'flats/settings.png' | assets"
    title="Gestione Ticket"
    cardClass="bg-gradient-dark"
  >
    <div class="row">
      <div class="col-12 col-md-4 col-lg-3">
        <div class="form-group">
          <label>Filtra per negozio</label>
          <app-retailer-typeahead
            (onChange)="this.filter.id_dealer = $event; this.refreshHandling()"
          ></app-retailer-typeahead>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg-3">
        <div class="form-group">
          <label>Filtra per operatore</label>
          <app-staff-select [model]="auth.currentUserValue.profile.id" (onChange)="this.filter.id_operatore = $event; this.refreshHandling()"></app-staff-select>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg-3">
        <div class="form-group">
          <label>Filtra per categoria</label>
          <select
            class="form-control"
            [(ngModel)]="filter.category"
            (ngModelChange)="
              this.filter.subcategory = ''; this.refreshHandling()
            "
          >
            <option value="">Tutte le categorie</option>
            <option *ngFor="let cat of categories" [value]="cat.id">
              {{ cat.nome }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg-3">
        <div class="form-group">
          <label>Filtra per sottocategoria</label>
          <select
            class="form-control"
            [(ngModel)]="filter.subcategory"
            (ngModelChange)="this.refreshHandling()"
            [disabled]="this.filter.category === ''"
          >
            <option value="">Tutte le sottocategorie</option>
            <ng-container *ngIf="this.filter.category !== ''">
              <option
                *ngFor="let cat of category(this.filter.category)"
                [value]="cat.id"
              >
                {{ cat.nome }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg-3">
        <div class="form-group">
          <label>Filtra per stato</label>
          <select
            class="form-control"
            [(ngModel)]="filter.status"
            (ngModelChange)="this.refreshHandling()"
          >
            <option value="">Tutte gli stati</option>
            <option [value]="0">Aperto</option>
            <option [value]="1">Chiuso</option>
          </select>
        </div>
      </div>
    </div>
  </app-master-header>

  <div class="row">
    <div class="col-12 col-lg-3">
      <div class="card mg-theme full">
        <div class="card-header">
          <h1>Categorie</h1>
          <img class="float-right" [src]="'flats/folder.png' | assets" />
        </div>
        <div class="card-body p-0">
          <ul class="nav flex-column menu">
            <li class="nav-item" *ngFor="let category of categories">
              <a class="nav-link">
                <i class="fa fa-caret-right"></i> {{ category.nome }}
                <div class="float-right">
                  <ng-container *show="['master', 'operator']">
                    <button
                      class="btn btn-circle bg-gradient-success btn-circle-xs mr-2"
                      (click)="showCreateCategoryModal(category.id)"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                    <button
                      class="btn btn-circle bg-gradient-info btn-circle-xs mr-2"
                      (click)="
                        showUpdateCategoryModal(category);
                        $event.stopPropagation()
                      "
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </button>
                    <button
                      class="btn btn-circle bg-gradient-danger btn-circle-xs mr-2"
                      [swal]="categoryConfirmDeleteOptions"
                      (confirm)="deleteCategory(category)"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </ng-container>
                </div>
              </a>
              <ul *ngIf="category.subcategories.length > 0">
                <li *ngFor="let sub of category.subcategories" class="nav-item">
                  <a class="nav-link">
                    <i class="fa fa-caret-right"></i> {{ sub.nome }}
                    <div class="float-right">
                      <ng-container *show="['master', 'operator']">
                        <button
                          class="btn btn-circle bg-gradient-info btn-circle-xs mr-2"
                          (click)="
                            showUpdateCategoryModal(sub);
                            $event.stopPropagation()
                          "
                        >
                          <i class="fas fa-pencil-alt"></i>
                        </button>
                        <button
                          class="btn btn-circle bg-gradient-danger btn-circle-xs mr-2"
                          [swal]="categoryConfirmDeleteOptions"
                          (confirm)="deleteSubCategory(sub)"
                        >
                          <i class="fas fa-times"></i>
                        </button>
                      </ng-container>
                    </div>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="card-footer" *show="['master', 'operator']">
          <button
            class="btn btn-block btn-small bg-gradient-success"
            (click)="showCreateCategoryModal()"
          >
            Aggiungi Categoria
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-9">
      <div class="card bg-gradient-light">
        <div class="card-header">
          <h1 class="card-title">Ticket in attesa di gestione</h1>
        </div>
        <div class="card-body p-0">
          <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="table-to-handle"
            [fullScreen]="false"
          >
            <p style="color: white">Caricamento...</p>
          </ngx-spinner>
          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th class="text-center"></th>
                <th class="text-center">#</th>
                <th>Dealer</th>
                <th>Categoria</th>
                <th>Subcategoria</th>
                <th class="text-center">Data</th>
                <th class="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of toHandleList">
                <td class="text-center">
                  <a class="link text-primary" (click)="showTicketModal(row)"
                    ><i class="fas fa-eye"></i
                  ></a>
                </td>
                <td class="text-center">{{ row.id }}</td>
                <td>{{ row.retailer }}</td>
                <td>{{ row.category }}</td>
                <td>{{ row.subcategory }}</td>
                <td class="text-center">
                  {{ row.created | amDateFormat: "H:mm DD/MM/YYYY" }}
                </td>
                <td class="text-center">
                  <button
                    class="btn btn-primary btn-sm"
                    (click)="takeInHandle(row)"
                  >
                    Gestisci
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <span
            >Ci sono <b>{{ this.toHandlePagination.totalCount }}</b> ticket da
            gestire.</span
          >
          <ngb-pagination
            size="sm"
            [pageSize]="toHandlePagination.pageSize"
            [(page)]="toHandlePagination.page"
            [collectionSize]="toHandlePagination.totalCount"
            (pageChange)="
              this.toHandlePagination.page = $event; refreshToHandle()
            "
          ></ngb-pagination>
        </div>
      </div>

      <div class="card bg-gradient-light">
        <div class="card-header">
          <h1 class="card-title">Ticket in tua gestione</h1>
        </div>
        <div class="card-body p-0">
          <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="table-handling"
            [fullScreen]="false"
          >
            <p style="color: white">Caricamento...</p>
          </ngx-spinner>
          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th class="text-center"></th>
                <th class="text-center">#</th>
                <th>Dealer</th>
                <th>Categoria</th>
                <th>Subcategoria</th>
                <th class="text-center">Stato</th>
                <th class="text-center">Nuove Risposte</th>
                <th class="text-center">Data</th>
                <th class="text-center">Chiuso il</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of handlingList">
                <td class="text-center">
                  <a class="link text-primary" (click)="showTicketModal(row)"
                    ><i class="fas fa-eye"></i
                  ></a>
                </td>
                <td class="text-center">{{ row.id }}</td>
                <td>{{ row.retailer }}</td>
                <td>{{ row.category }}</td>
                <td>{{ row.subcategory }}</td>
                <td class="text-center">
                  <span class="badge badge-success" *ngIf="row.stato === 0"
                    >Aperto</span
                  >
                  <span class="badge badge-danger" *ngIf="row.stato === 1"
                    >Chiuso</span
                  >
                </td>
                <td class="text-center">
                  <span *ngIf="row.new" class="badge badge-info">new</span>
                </td>
                <td class="text-center">
                  {{ row.created | amDateFormat: "HH:mm DD/MM/YYYY" }}
                </td>
                <td class="text-center">
                  {{ row.handled_date | amDateFormat: "HH:mm DD/MM/YYYY" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="card-footer">
          <span
            >Ci sono <b>{{ this.handlingPagination.totalCount }}</b> ticket
            gestisti.</span
          >
          <ngb-pagination
            size="sm"
            [pageSize]="handlingPagination.pageSize"
            [(page)]="handlingPagination.page"
            [collectionSize]="handlingPagination.totalCount"
            (pageChange)="
              this.handlingPagination.page = $event; refreshHandling()
            "
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #ticketTemplate let-modal>
  <div class="modal-header" [ngBusy]="busy">
    <h4 class="modal-title clamp-title">Ticket #{{ selectedTicket.id }}</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      size="default"
      color="#fff"
      type="square-jelly-box"
      name="ticket-modal"
      [fullScreen]="false"
    >
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <div class="row">
      <div class="col-4 border-right">
        <div class="box-profile">
          <div class="text-center">
            <img
              class="profile-user-img img-fluid img-circle"
              src="/assets/img/flats/online-store.png"
              alt="User profile picture"
            />
          </div>

          <h3 class="profile-username text-center">
            {{ selectedTicket.retailer }}
          </h3>

          <p class="text-muted text-center">
            {{ selectedTicket.created | amDateFormat: "H:mm DD/MM/YYYY" }}
          </p>

          <hr />
          <strong><i class="fas fa-box mr-1"></i> Categoria</strong>
          <p class="text-muted">
            {{ selectedTicket.category }}
          </p>
          <hr />
          <strong><i class="fas fa-boxes mr-1"></i> Subcategoria</strong>
          <p class="text-muted">
            {{ selectedTicket.subcategory }}
          </p>
          <hr />
          <strong><i class="fas fa-user mr-1"></i> Operatore</strong>
          <app-staff-select
            [small]="true"
            [disabled]="!selectedTicket.id_operatore"
            [model]="selectedTicket.id_operatore"
            (onChange)="setStaffOperatorOnTicket(selectedTicket, $event)"
          >
          </app-staff-select>
          <hr />
          <strong><i class="fas fa-user mr-1"></i> Stato</strong>
          <select
            class="form-control form-control-sm"
            [(ngModel)]="selectedTicket.stato"
            (ngModelChange)="setStatusOnTicket(selectedTicket)"
          >
            <option [value]="0">Aperto</option>
            <option [value]="1">Chiuso</option>
          </select>
          <hr />
          <strong><i class="fas fa-folder-open mr-1"></i> Allegati</strong>
          <p class="text-muted" *ngIf="selectedTicket.attachments.length === 0">
            Non ci sono allegati.
          </p>
          <ng-container *ngFor="let attachment of selectedTicket.attachments">
            <p>
              <a [href]="attachment.doc_path"
                ><i class="fa fa-file"> </i>{{ attachment.filename }}</a
              >
            </p>
            <hr />
          </ng-container>

          <form
            [formGroup]="attachmentForm"
            *ngIf="showUpload && selectedTicket.id_operatore"
          >
            <div class="form-group">
              <label class="lead">Scegli l'allegato</label>
              <div class="custom-file">
                <input
                  formControlName="ticketfile"
                  type="file"
                  class="custom-file-input"
                  id="validatedCustomFile"
                  (change)="handleFileInput($event.target.files)"
                />
                <label class="custom-file-label" for="validatedCustomFile">{{
                  fileName
                }}</label>
              </div>
            </div>
          </form>

          <button
            class="btn btn-primary btn-sm btn-block mt-2"
            [disabled]="
              !selectedTicket.id_operatore || selectedTicket.stato === 1
            "
            (click)="newAttachment(selectedTicket)"
          >
            Aggiungi Allegato
          </button>
        </div>
      </div>
      <div class="col-8">
        <div class="direct-chat direct-chat-primary">
          <div class="direct-chat-messages" style="height: auto">
            <div class="direct-chat-msg">
              <div class="direct-chat-infos clearfix">
                <span class="direct-chat-name float-left">{{
                  selectedTicket.retailer
                }}</span>
                <span class="direct-chat-timestamp float-right">{{
                  selectedTicket.created | amDateFormat: "H:mm DD/MM/YYYY"
                }}</span>
              </div>
              <!-- /.direct-chat-infos -->
              <img
                class="direct-chat-img"
                src="/assets/img/flats/online-store.png"
                alt="message user image"
              />
              <!-- /.direct-chat-img -->
              <div class="direct-chat-text" [innerHTML]="selectedTicket.testo | safeHtml">
              </div>
              <!-- /.direct-chat-text -->
            </div>
            <div
              class="direct-chat-msg"
              *ngFor="let reply of selectedTicket.replies"
              [ngClass]="{
                right: reply.id_utente !== selectedTicket.id_dealer
              }"
            >
              <div class="direct-chat-infos clearfix">
                <span
                  class="direct-chat-name"
                  [ngClass]="{
                    'float-right': reply.id_utente !== selectedTicket.id_dealer,
                    'float-left': reply.id_utente === selectedTicket.id_dealer
                  }"
                  >{{ reply.creator }}</span
                >
                <span
                  class="direct-chat-timestamp"
                  [ngClass]="{
                    'float-left': reply.id_utente !== selectedTicket.id_dealer,
                    'float-right': reply.id_utente === selectedTicket.id_dealer
                  }"
                  >{{ reply.created | amDateFormat: "H:mm DD/MM/YYYY" }}</span
                >
              </div>
              <!-- /.direct-chat-infos -->
              <img
                class="direct-chat-img"
                *ngIf="reply.id_utente !== selectedTicket.id_dealer"
                src="/assets/img/flats/support.png"
                alt="message user image"
              />
              <img
                class="direct-chat-img"
                *ngIf="reply.id_utente === selectedTicket.id_dealer"
                src="/assets/img/flats/online-store.png"
                alt="message user image"
              />
              <!-- /.direct-chat-img -->
              <div class="direct-chat-text" [innerHTML]="reply.risposta | safeHtml">
              </div>
              <!-- /.direct-chat-text -->
            </div>
          </div>
        </div>
        <hr />
        <textarea
          class="form-control form-control-sm"
          rows="5"
          placeholder="Scrivi qui la tua risposta"
          [(ngModel)]="selectedReply.risposta"
          [disabled]="
            !selectedTicket.id_operatore || selectedTicket.stato === 1
          "
        ></textarea>
        <button
          class="btn btn-sm float-right btn-primary mt-2"
          (click)="saveReply(selectedReply)"
          [disabled]="!selectedReply.risposta"
        >
          Invia risposta
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #categoryFormTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title">{{ categoryModalTitle }}</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="categoryForm">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="lead">Nome</label>
            <input
              [(ngModel)]="selectedCategory.nome"
              formControlName="nome"
              type="text"
              class="form-control"
            />
            <val-errors controlName="nome">
              <ng-template valError="required">
                Il nome della categoria è obbligatorio
              </ng-template>
            </val-errors>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label class="lead"
              >Visibile solo se abilitato con questo brand</label
            >
            <input
              [(ngModel)]="selectedCategory.id_vendor"
              formControlName="id_vendor"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-12 mt-3">
          <button
            class="btn btn-success btn-block"
            [disabled]="!this.categoryForm.valid"
            (click)="saveCategory(selectedCategory)"
          >
            <i class="fa fa-check-circle mr-2"></i>Salva
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
