import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '@services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class PasswordGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const token = this.authenticationService.currentUserToken;
        const currentUser = this.authenticationService.currentUserValue;
        if (token && currentUser?.loggedIn && !currentUser?.profile?.is_psw_expired) {
            this.router.navigate(['/'], {queryParams: {returnUrl: state.url}});
            return false;
        }
        return true;
    }
}
