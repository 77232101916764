import {Component, OnInit} from '@angular/core';
import {User} from '@models/user';
import {DataService} from '@services/data.service';
import {AuthenticationService} from '@services/authentication.service';
import {Prize, PrizeListPagination} from '@models/prize';
import {CampaignService} from '@services/campaign.service';
import {CampaignSegmentListPagination} from '@models/campaign-segment';
import {LoyaltyCard, Timeline} from '@models/loyalty-card';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfirmOptionsService} from '@services/confirm-options.service';
import * as moment from 'moment';

@Component({
    selector: 'app-loyalty-card',
    templateUrl: './loyalty-card.component.html',
    styleUrls: ['./loyalty-card.component.scss']
})
export class LoyaltyCardComponent implements OnInit {

    busy: any;
    user: User;
    prizes: PrizeListPagination;
    segments: CampaignSegmentListPagination;
    loyaltyCard: LoyaltyCard;
    format = 'd MMM y';
    timelines: Timeline[];
    dates = [];
    dayFormat = 'd MMM';
    currentDate = moment().format('YYYY-MM-DD');
    constructor(
        public dataService: DataService,
        public authenticationService: AuthenticationService,
        private campaignService: CampaignService,
        private spinner: NgxSpinnerService
    ) {
        this.authenticationService.currentUser.subscribe((user: User) => {
            this.user = user;
            }
        );
        this.busy = this.campaignService.getLoyaltyCard().subscribe((loyaltyCard: LoyaltyCard) => {
            this.spinner.show('timeline');
            this.loyaltyCard = loyaltyCard;
            this.loadPrizes(loyaltyCard);
            this.loadRules(loyaltyCard);
            this.campaignService.getSalesTimeline(loyaltyCard).subscribe((timelines: Timeline[]) => {
                this.timelines = timelines;
                this.spinner.hide('timeline');
            }, err => {
                this.spinner.hide('timeline');
            });
        });
    }

    ngOnInit() {
    }

    get profile() {
        return this.user.profile;
    }
    loadPrizes(loyaltyCard: LoyaltyCard, pageSize?) {
        this.spinner.show('prizes');
        this.campaignService.getPrizes(loyaltyCard.id, pageSize).subscribe((prizes: PrizeListPagination) => {
            this.prizes = prizes;
            this.spinner.hide('prizes');
        }, err => {
            this.prizes.data = [];
            this.spinner.hide('prizes');
        });
    }

    loadAllPrizes(loyaltyCard: LoyaltyCard, prizes: PrizeListPagination) {
        if (!this.isShownAll(prizes)) {
            return this.loadPrizes(loyaltyCard, prizes['_meta'].totalCount);
        }
        return this.loadPrizes(loyaltyCard);
    }

    loadRules(loyaltyCard: LoyaltyCard, pageSize?) {
        this.spinner.show('rules');
        this.campaignService.getSegmentsWithRules(loyaltyCard.id, pageSize).subscribe((rules: CampaignSegmentListPagination) => {
            this.segments = rules;
            this.spinner.hide('rules');
        }, err => {
            this.segments.data = [];
            this.spinner.hide('rules');
        });
    }

    loadAllRules(loyaltyCard: LoyaltyCard, segments: CampaignSegmentListPagination) {
        if (!this.isShownAll(segments)) {
            return this.loadRules(loyaltyCard, segments['_meta'].totalCount);
        }
        return this.loadRules(loyaltyCard);
    }

    isShownAll(items: any) {
        return items['_meta'].totalCount === items['_meta'].perPage;
    }

    withdrawPrize(prize: Prize) {
        this.spinner.show('prizes');
        this.campaignService.withdrawPrize(this.user.profile.id, prize.campaign_id, prize.id).subscribe(() => {
                prize.is_used = true;
                this.loadNewTimeline(prize);
                this.loyaltyCard.points.total -= prize.required_points;
                this.campaignService.successAlert('Used Prize');
                this.spinner.hide('prizes');
            }, err => {
                this.campaignService.errorAlert();
                this.spinner.hide('prizes');
            }
        );
    }

    loadNewTimeline(prize) {
        const index = this.timelines.findIndex(item => item.date === this.currentDate);
        if (index > -1) {
            if (this.timelines[index]?.used_prizes) {
                this.timelines[index].used_prizes.unshift(prize);
            } else {
                this.timelines[index].used_prizes = [prize];
            }
        } else {
            const timeLine = new Timeline();
            timeLine.date = this.currentDate;
            timeLine.sales = [];
            timeLine.used_prizes = [prize];
            this.timelines.unshift(timeLine);
        }
    }

    get withdrawPrizeOptions() {
        return new ConfirmOptionsService({
            title: 'Sei sicuro?',
            text: 'Non potrai tornare indietro!',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });
    }
}
