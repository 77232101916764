import {Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthenticationService} from "@services/authentication.service";
import {User} from "@models/user";

@Directive({
  selector: '[isVendorDisabled]'
})
export class IsVendorDisabledDirective  implements OnInit{

  private user: User;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthenticationService
    ) {
        this.authService.currentUser.subscribe(user => this.user = user);
    }

    @Input() set isVendorDisabled(op: string){
        if (this.user && this.user.profile.operatori.includes(op)) {
            this.viewContainer.clear();
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }

    }
    
    ngOnInit(): void {
    }
}
