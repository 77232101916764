import {Component, OnInit} from '@angular/core';
import {DataService} from '@services/data.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Contract} from '@models/contract/contract';

@Component({
    selector: 'app-contract-detail',
    templateUrl: './contract-detail.component.html',
    styleUrls: ['./contract-detail.component.scss']
})
export class ContractDetailComponent implements OnInit {
    dettaglio: Contract = null;
    public busy: Subscription;
    public brand = this.route.snapshot.paramMap.get('brand');
    public replyForm: FormGroup;

    hasContrattoFirmato = false;

    constructor(
        private dataService: DataService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.replyForm = new FormGroup({
            reply: new FormControl(null, [Validators.required]),
        });

        this.refreshContract();
    }

    refreshContract() {
        const id = this.route.snapshot.paramMap.get('id');

        this.busy = this.dataService
            .get(`/get-dettaglio-contratto?id=${id}`)
            .subscribe((data: any) => {
                this.dettaglio = new Contract().fromJSON(data.result);
            });
    }

    // addComment() {
    //     if (this.replyForm.invalid) {
    //         return;
    //     }
    //
    //     const formData = new FormData();
    //     formData.append('related_to', this.dettaglio.id);
    //     formData.append('commentcontent', this.replyForm.controls.reply.value);
    //
    //     this.busy = this.dataService.post('/comments', formData).subscribe((data) => {
    //         this.replyForm.reset();
    //         this.refreshContract();
    //     });
    // }
}
