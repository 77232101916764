import {Injectable} from '@angular/core';
import {RequestService} from '@services/request.service';
import {AlertService} from '@services/alert.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {BankTransactionPagination} from '@models/invoice/bank-transaction';
import {InstallmentsPagination} from '@models/product';

const product = 'product';
const installments = 'installments';
const types = 'types';

@Injectable({
    providedIn: 'root'
})
export class ProductInstallmentService {

    constructor(public request: RequestService, private alertService: AlertService) {
    }

    public getInstallments(params?): Observable<InstallmentsPagination> {
        return this.request.get(product + '/' + installments, params).pipe(
            map((response: any) => {
                return new InstallmentsPagination(response);
            })
        );
    }

    public postInstallmentsFromFile(body) {
        return this.request.post(product + '/' + installments, body.postPayloadExcelFile).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getTypes(params?): Observable<any> {
        return this.request.get(product + '/' + installments + '/' + types, params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public successAlert(text) {
        this.alertService.show(
            '',
            'Successfully ' + text,
            {classname: 'bg-success text-light', delay: 2000}
        );
    }

    public errorAlert(message?) {
        this.alertService.show(
            'An error occurred!',
            message ? message : 'Try again!',
            {classname: 'bg-danger text-light', delay: 2000}
        );
    }
}
