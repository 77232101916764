import {Component, Input, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {NgxSpinnerService} from 'ngx-spinner';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {EventInput} from '@fullcalendar/core';
import {CalendarOptions, FullCalendarComponent} from '@fullcalendar/angular';
import {InvoiceDocumentService} from '@services/invoice-document.service';
import {Document, DocumentCategory, DocumentPagination, DocumentPayment, DocumentPaymentPagination} from '@models/invoice/document';
import {NgbDropdownMenu, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject, SubjectPagination} from '@models/invoice/subject';
import {MetaPagination} from '@models/meta-pagination';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
    filter = {
        subject_id: '',
        document_id: '',
    };
    apiDateFormat = 'YYYY-MM-DD';
    currency = '€';
    localFormat = 'DD/MM/YYYY';
    @ViewChild('calendar') calendarComponent: FullCalendarComponent;
    events: EventInput[] = [];
    calendarOptions: CalendarOptions = {
        initialView: 'dayGridMonth',
        dateClick: this.handleDateClick.bind(this),
        eventClick: this.handleEventClick.bind(this),
        events: [],
        locale: 'it',
        editable: false,
        dragScroll: false,
        customButtons: {
            next: {
                click: this.nextMonth.bind(this),
            },
            prev: {
                click: this.prevMonth.bind(this),
            },
            today: {
                text: 'today',
                click: this.currentMonth.bind(this),
            },
        },
    };
    currentDate = moment().format(this.apiDateFormat);
    payment: DocumentPayment;
    categories: DocumentCategory[] = [];
    payments: DocumentPayment[] = [];
    paymentsPagination: MetaPagination;
    selectedDay;
    selectedSubjectId;
    selectedDocumentId;
    @ViewChild('subjectDetailTemplate') private subjectDetailTemplate;
    @ViewChild('documentDetailTemplate') private documentDetailTemplate;
    @ViewChild(NgbDropdownMenu) filterMenu: NgbDropdownMenu;

    subjects: Subject[] = [];
    documents: Document[] = [];
    subjectLoading = false;
    documentLoading = false;
    dueMethods = InvoiceDocumentService.dueMethods;

    constructor(private spinner: NgxSpinnerService,
                private dateRangePickerOptions: DaterangepickerConfig,
                private invoiceDocumentService: InvoiceDocumentService,
                private modalService: NgbModal) {
    }

    ngOnInit(): void {
        this.loadCalendarReport();
        this.loadSubject();
        this.loadDocuments();
    }

    loadCalendarReport() {
        this.filterMenu?.dropdown?.close();
        this.spinner.show('calendar');
        const params = {};
        if (this.currentDate) {
            params['month'] = moment(this.currentDate).format('MM');
            params['year'] = moment(this.currentDate).format('YYYY');
        }
        if (this.filter.subject_id) {
            params['subject_id'] = this.filter.subject_id;
        }
        if (this.filter.document_id) {
            params['document_id'] = this.filter.document_id;
        }
        const methods = this.dueMethods.filter(item => item.checked).map((item) => item.key);
        params['due_methods'] = methods.join(',');
        this.payments = [];
        this.selectedDay = null;
        this.invoiceDocumentService.getCalendarPayment(params).subscribe((response) => {
            this.calendarOptions.events = response;
            this.spinner.hide('calendar');
        }, err => {
            this.spinner.hide('calendar');
        });
    }

    handleDateClick(arg) {
    }

    handleEventClick(arg) {
        this.spinner.show('paymentDetail');
        this.payment = null;

        const props = arg?.event['extendedProps'];
        const date = arg?.event['_instance']['range']['start'];
        const apiDate = moment(date).format(this.apiDateFormat);
        return this.loadPayments(props.is_credit, apiDate);

    }

    loadPayments(isCredit = null, dueDate = null) {
        this.filterMenu?.dropdown?.close();
        const params = {};
        params['from'] = dueDate;
        params['to'] = dueDate;
        params['welded'] = 0;
        params['is_credit'] = isCredit;
        if (this.filter.subject_id) {
            params['subject_id'] = this.filter.subject_id;
        }
        if (this.filter.document_id) {
            params['document_id'] = this.filter.document_id;
        }
        const methods = this.dueMethods.filter(item => item.checked).map((item) => item.key);
        params['due_methods'] = methods.join(',');
        if (this.paymentsPagination?.currentPage) {
            params['page'] = this.paymentsPagination?.currentPage;
        }
        this.selectedDay = params['due_date'];
        this.invoiceDocumentService.getDocumentPayments(params).subscribe((response: DocumentPaymentPagination) => {
            this.payments = response.data;
            this.payments.forEach((payment: DocumentPayment) => {
                payment.type = this.findType(payment?.due_method);
            });
            this.paymentsPagination = response._meta;
            this.spinner.hide('paymentDetail');
        }, err => {
            this.spinner.hide('paymentDetail');
        });
    }

    dismissModal() {
        this.payment = null;
        this.modalService.dismissAll();
    }

    nextMonth(arg) {
        const calendarApi = this.calendarComponent.getApi();
        calendarApi.next();
        this.currentDate = moment(this.currentDate).add(1, 'M').format(this.apiDateFormat);
        this.loadCalendarReport();
    }

    prevMonth(arg) {
        const calendarApi = this.calendarComponent.getApi();
        calendarApi.prev();
        this.currentDate = moment(this.currentDate).add(-1, 'M').format(this.apiDateFormat);
        this.loadCalendarReport();
    }

    currentMonth(arg) {
        const calendarApi = this.calendarComponent.getApi();
        calendarApi.today();
        this.currentDate = moment().format(this.apiDateFormat);
        this.loadCalendarReport();
    }

    showSubjectModal(subjectId) {
        this.selectedSubjectId = subjectId;
        this.modalService.open(this.subjectDetailTemplate, {size: 'xl'});
    }

    showDocumentModal(documentId) {
        this.selectedDocumentId = documentId;
        this.modalService.open(this.documentDetailTemplate, {size: 'xl'});
    }

    loadSubject(term?: string) {
        const params = {};
        if (term) {
            params['company_name'] = term;
        }
        this.invoiceDocumentService.getSubject(params).subscribe((response: SubjectPagination) => {
            this.subjects = response.data;
            this.subjectLoading = false;
        }, err => {
            this.subjectLoading = false;
        });
    }

    loadDocuments(term?: string) {
        const params = {};
        if (term) {
            params['number'] = term;
        }
        this.invoiceDocumentService.getDocuments(params).subscribe((response: DocumentPagination) => {
            this.documents = response.data;
            this.documentLoading = false;
        }, err => {
            this.documentLoading = false;
        });
    }

    findType(type) {
        const method = this.dueMethods.find(item => item.key === type);
        return method ? method.name : type;
    }
}
