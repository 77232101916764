import { BaseModel } from "./base-model";

export class Company extends BaseModel {
  id: number;
  username: string;
  password: string;
  role?: string;
  car_code?: string;
  ragsociale: string;
  company_name: string;
  nome: string;
  cognome: string;
  codfiscale: string;

  cellulare: string;
  telefono: string;
  email: string;

  iban: string;
  blacklist: number;
  address: string;
  province: string;
  city: string;
  state: string;
  zipcode: string;
  street_number: string;
  company_owner: string;

  provincia: string;
  localita: string;
  indirizzo: string;
  civico: string;
  piva: string;
  cap: string;

  codicekena: string;
  retailcode: string;
  codiceeni: string;
  codice_pdv_tim: string;

  agentKena: string;
  agentTim: string;
  agentTiscali: string;
  agentEni: string;

  created: string;
  note: string;

  pec: string;
  codice_sdi: string;
  tipologia_societa: string;
  stato_freeinvoice: string;
  regime_fiscale: string;
  causale_ritenuta: string;
  tipo_ritenuta: string;
  fi_api_key: string;
  deleted: number;

  _ibanIsValid = null;
  isSelected: boolean;
  ibanRegex = /^IT[a-zA-Z0-9]{2}\s?([a-zA-Z]{1})([0-9]{3}\s?)([0-9]{4}\s?){1}([0-9]{3})([a-zA-Z0-9]{1}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{3})\s?/;

  get ibanIsValid() {
    if (this._ibanIsValid === null) {
      this._ibanIsValid = this.ibanRegex.test(this.iban);
    }

    return this._ibanIsValid;
  }

  set name(value) {
    this.company_name = value;
  }

  get name() {
    return this.company_name;
  }

  fromArray(result: []) {
    const array: Company[] = [];
    for (const item of result) {
      array.push(new Company(item));
    }
    return array;
  }
}
