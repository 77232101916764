import {Component, OnInit, ViewChild} from '@angular/core';
import {MetaPagination} from '@models/meta-pagination';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmOptionsService} from '@services/confirm-options.service';
import {StoreService} from '@services/store.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {StoreCategory, StoreCategoryPagination} from '@models/store/store-category';
import {StoreBrand, StoreBrandPagination, StoreProduct, StoreProductPagination} from '@models/store/store-product';

@Component({
    selector: 'app-shop-product',
    templateUrl: './shop-product.component.html',
    styleUrls: ['./shop-product.component.css']
})
export class ShopProductComponent implements OnInit {
    filter = {
        name: '',
        category_id: '',
        type: '',
        brand_id: '',
        visibile: '1',
        deleted: '0',
    };
    categoriesLoading = false;
    categories: StoreCategory[] = [];
    products: StoreProduct[] = [];
    productsPagination: MetaPagination;
    selectedProduct;
    @ViewChild('productFormTemplate') private productFormTemplate;
    @ViewChild('productDetailTemplate') private productDetailTemplate;
    @ViewChild('productTemplate') private productTemplate;
    deleteProductOptions: ConfirmOptionsService;
    currency = '€';
    productTypes = StoreService.productTypes;
    brands: StoreBrand[] = [];
    brandsLoading = false;

    constructor(private modalService: NgbModal,
                private storeService: StoreService,
                private spinner: NgxSpinnerService) {
        this.deleteProductOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro!',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });
    }

    ngOnInit(): void {
        this.loadProducts();
        this.loadCategories();
        this.loadBrands();
    }

    showModal(product?) {
        this.selectedProduct = product;
        this.modalService.open(this.productFormTemplate, {size: 'xl'});
    }

    showDetailModal(product?) {
        this.selectedProduct = product;
        this.modalService.open(this.productDetailTemplate, {size: 'xl'});
    }

    showTemplateModal(product?) {
        this.selectedProduct = product;
        this.modalService.open(this.productTemplate, {size: 'lg'});
    }

    deleteProduct(id: number) {
        this.storeService.deleteProduct(id).subscribe(() => {
            const index = this.products.findIndex(product => product.id === id);
            if (index > -1) {
                this.products.splice(index, 1);
                this.storeService.successAlert('Deleted Product!');
            }
        }, (err) => {
            this.storeService.errorAlert(err.message);
        });
    }

    loadProducts() {
        this.spinner.show('table-products');
        const params = {};
        if (this.productsPagination?.currentPage) {
            params['page'] = this.productsPagination?.currentPage;
        }
        if (this.filter.name) {
            params['nome'] = this.filter.name;
        }
        if (this.filter.category_id) {
            params['id_categoria'] = this.filter.category_id;
        }
        if (this.filter.type) {
            params['personalizzabile'] = this.filter.type;
        }
        if (this.filter.brand_id) {
            params['id_brand'] = this.filter.brand_id;
        }
        if (this.filter.deleted) {
            params['deleted'] = this.filter.deleted;
        }
        if (this.filter.visibile) {
            params['visibile'] = this.filter.visibile;
        }
        this.storeService.getProducts(params).subscribe((response: StoreProductPagination) => {
            this.products = response.data;
            this.productsPagination = response._meta;
            this.spinner.hide('table-products');
        }, err => {
            this.spinner.hide('table-products');
        });
    }

    loadCategories(term?) {
        this.categoriesLoading = true;
        const params = {};
        if (term) {
            params['nome'] = term;
        }
        this.storeService.getCategories(params).subscribe((response: StoreCategoryPagination) => {
            this.categories = response.data;
            this.categoriesLoading = false;
        }, err => {
            this.categoriesLoading = false;
        });
    }

    loadBrands(term?) {
        this.brandsLoading = true;
        const params = {};
        if (term) {
            params['nome'] = term;
        }
        this.storeService.getBrands(params).subscribe((response: StoreBrandPagination) => {
            this.brands = response.data;
            this.brandsLoading = false;
        }, err => {
            this.brandsLoading = false;
        });
    }

    findProductType(key: any) {
        const type = this.productTypes.find(data => data.key === key.toString());
        return type ? type.name : null;
    }

    resetFilter() {
        this.filter = {
            name: '',
            category_id: '',
            type: '',
            brand_id: '',
            visibile: '1',
            deleted: '0',
        };
    }
}
