<div class="row">
    <div class="col-12 col-lg-4">
        <div class="card">
            <div class="card-body">
                <img src="/assets/img/brands/logo_mastersms.png" width="100%"/>
                <div class="embed-responsive embed-responsive-16by9 rounded">
                    <iframe src="https://www.youtube.com/embed/cfX6JyBkRiE" class="embed-responsive-item"></iframe>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-8">
        <div class="card">
            <div class="card-body text-center align-items-center" style="padding-bottom: 0.78rem;">
                <div class="row text-center">
                    <div class="col-12 col-lg-4">
                        <h2 class="poppins color-primary">PIANO</h2>
                        <h2 class="text-bold color-primary poppins">BASE</h2>
                        <div class="base-sms-box p-3">
                            <a href="https://www.youtube.com/watch?v=cfX6JyBkRiE&list=PLNIOkwFi2gAQqql0fM4GUTboBdFuXq9Qw&index=3" target="_blank" class="info-box info-box-sms zoom">
                                <span class="info-box-icon"><i class="fa fa-sms"></i></span>
                                <div class="info-box-content">
                                    <span class="info-box-text">Guarda il video</span>
                                    <span class="info-box-number">Marketing SMS</span>
                                </div>
                            </a>
                            <div class="info-box info-box-sms">
                                <span class="info-box-icon"><i class="fa fa-cogs"></i></span>
                                <div class="info-box-content">
                                    <span class="info-box-text">&nbsp;</span>
                                    <span class="info-box-number">Automazioni</span>
                                </div>
                            </div>
                            <a href="https://www.youtube.com/watch?v=rpIxOlkvby8&list=PLNIOkwFi2gAQqql0fM4GUTboBdFuXq9Qw&index=5" target="_blank" class="info-box info-box-sms zoom">
                                <span class="info-box-icon"><i class="fa fa-image"></i></span>
                                <div class="info-box-content">
                                    <span class="info-box-text">Guarda il video</span>
                                    <span class="info-box-number">1 Landing Page</span>
                                </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=8vWRhjq44G0&list=PLNIOkwFi2gAQqql0fM4GUTboBdFuXq9Qw&index=7" target="_blank" class="info-box info-box-sms zoom">
                                <span class="info-box-icon"><i class="fa fa-users"></i></span>
                                <div class="info-box-content">
                                    <span class="info-box-text">Guarda il video</span>
                                    <span class="info-box-number">Rivenditore SMS</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <h2 class="poppins color-primary">PIANO</h2>
                        <h2 class="text-bold color-primary poppins">PREMIUM</h2>
                        <div class="premium-sms-box p-3">
                            <div class="info-box info-box-sms">
                                <span class="info-box-icon"><i class="fa fa-sms"></i></span>
                                <div class="info-box-content">
                                    <span class="info-box-text">Tutto ciò che c'è nel</span>
                                    <span class="info-box-number">Piano Base</span>
                                </div>
                            </div>
                            <a href="https://www.youtube.com/watch?v=jRq3IefhVj0&list=PLNIOkwFi2gAQqql0fM4GUTboBdFuXq9Qw&index=1" target="_blank" class="info-box info-box-sms zoom">
                                <span class="info-box-icon"><i class="fa fa-envelope"></i></span>
                                <div class="info-box-content">
                                    <span class="info-box-text">Guarda il video</span>
                                    <span class="info-box-number">Campagne E-Mail</span>
                                </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=rpIxOlkvby8&list=PLNIOkwFi2gAQqql0fM4GUTboBdFuXq9Qw&index=5" target="_blank" class="info-box info-box-sms zoom">
                                <span class="info-box-icon"><i class="fa fa-image"></i></span>
                                <div class="info-box-content">
                                    <span class="info-box-text">Guarda il video</span>
                                    <span class="info-box-number">Landing Page</span>
                                </div>
                            </a>
                            <div class="info-box info-box-sms">
                                <span class="info-box-icon"><i class="fa fa-qrcode"></i></span>
                                <div class="info-box-content">
                                    <span class="info-box-text">&nbsp;</span>
                                    <span class="info-box-number">Coupon Digitali</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <h2 class="poppins color-primary">&nbsp;</h2>
                        <h2 class="text-bold color-primary poppins">&nbsp;</h2>
                        <div class="premium-sms-box p-3">
                            <h3 class="poppins color-secondary text-bold">PREMIUM</h3>
                            <p class="text-white poppins">Scopri tutti i servizi</p>
                            <button class="btn mt-2 btn-outline-light" (click)="showPremiumPlane()">Scopri di più
                            </button>
                        </div>
                        <h2 class="color-primary poppins text-bold mt-3">Rivenditore SMS</h2>
                        <p class="poppins px-4">Tutti i piani offrono la possibilità di rivendere gli sms ai tuoi
                            clienti
                            con un pannello personalizzato.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mt-3">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col-12_5 text-center py-4">
                        <p class="sms-row sms-row-even">Master SMS</p>
                        <p class="sms-row sms-row-odd">I nostri piani</p>
                        <p class="sms-row sms-row-even text-bold">Veloce, Sicuro e Affidabile</p>
                        <img src="/assets/img/brands/logo_mastersms.png" width="100%"/>
                    </div>
                    <div class="col-12_5 px-2" *ngFor="let tier of tiers">
                        <div class="price-card mb-2 py-4" [ngClass]="{'price-special': tier.special}">
                            <h3>{{tier.title}}</h3>
                            <p>Attivazione + SMS</p>
                            <h1 class="mt-3">€ {{tier.price}}<span>,00</span></h1>
                            <p class="text-sm">{{tier.ut}}€ attivazione</p>
                            <p class="text-sm">+</p>
                            <p class="text-sm">{{tier.price - tier.ut}}€ pacchetto SMS</p>
                            <button (click)="showBasePlane(tier)" class="btn mt-2"
                                    [ngClass]="{'btn-outline-light': tier.special, 'btn-outline-dark': !tier.special}">
                                Scopri di più
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row sms-row sms-row-odd mt-2">
                    <div class="col-12_5">
                        <p class="text-bold">SMS Inclusi</p>
                    </div>
                    <div class="col-12_5" *ngFor="let tier of tiers">
                        <p class="text-center">{{tier.sms}} SMS</p>
                    </div>
                </div>
                <div class="row sms-row sms-row-even mt-2">
                    <div class="col-12_5">
                        <p class="text-bold">Costo per SMS</p>
                    </div>
                    <div class="col-12_5" *ngFor="let tier of tiers">
                        <p class="text-center">{{tier.ppm}} € / SMS</p>
                    </div>
                </div>
                <div class="row sms-row sms-row-odd">
                    <div class="col-12_5">
                        <p class="text-bold">Attivazione Una Tantum</p>
                    </div>
                    <div class="col-12_5" *ngFor="let tier of tiers">
                        <p class="text-center">{{tier.ut}} €</p>
                    </div>
                </div>
                <div class="row sms-row sms-row-even mb-2">
                    <div class="col-12_5">
                        <p class="text-bold">Rivenditore Autorizzato SMS</p>
                    </div>
                    <div class="col-12_5" *ngFor="let tier of tiers">
                        <p class="text-center"><i class="fa fa-check-circle color-secondary text-lg"></i></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #planModal let-modal>
    <div class="modal-body" style="height: 570px;">
        <div class="row">
            <div class="col-4">
                <div class="price-card mb-2 py-4 price-special">
                    <h3>{{currentPlan.title}}</h3>
                    <p>Attivazione + SMS</p>
                    <h1 class="mt-3">€ {{currentPlan.price}}<span>,00</span></h1>
                    <p class="text-sm">{{currentPlan.ut}}€ attivazione + {{currentPlan.price - currentPlan.ut}}€
                        pacchetto SMS</p>
                    <div class="details">
                        <p>CONTIENE:</p>
                        <hr>
                        <p>{{currentPlan.sms}} sms</p>
                        <p>Automazioni</p>
                        <p>1 Landing Page</p>
                        <p>Rivenditore SMS Autorizzato</p>
                        <button (click)="buy(currentPlan.productid)" class="btn mt-2 btn-light pulse-animation">Acquista
                            Ora
                        </button>
                        <p class="text-sm">Veloce, Affidabile e Sicuro</p>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <h2 class="poppins text-center color-primary text-bold">Incrementa le tue vendite con gli sms</h2>
                <hr>
                <div class="plan-modal-details">
                    <div class="row">
                        <div class="col-6">
                            <h4 class="poppins color-primary text-bold">Invio SMS multipli, con un click</h4>
                            <p class="poppins color-primary">Coinvolgi e raggiungi i tuoi clienti con SMS web. La
                                piattaforma Master-SMS è semplice da utilizzare, studiata per aiutarti a far crescere il
                                tuo business.</p>
                        </div>
                        <div class="col-6">
                            <img src="https://kdistribution.it/wp-content/uploads/2021/11/SMS.png" width="100%"/>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-6">
                            <h4 class="poppins color-primary text-bold">Workflow avanzati di marketing automation</h4>
                            <p class="poppins color-primary">Quando un contatto compie una precisa azione (es.
                                iscriversi ad un form), scatta il workflow. Cioè? Fai partire in automatico una serie di
                                SMS (anche e-mail nel piano premium) oppure dai il via ad altre azioni automatiche.</p>
                        </div>
                        <div class="col-6">
                            <img src="https://kdistribution.it/wp-content/uploads/2021/11/AUTOMAZIONE.png"
                                 width="100%"/>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-6">
                            <h4 class="poppins color-primary text-bold">Crea la tua landing page in soli 15 minuti</h4>
                            <p class="poppins color-primary">Design e strumenti "pro", alla portata di tutti. Condividi
                                la pagina su più canali, a seconda dei tuoi obiettivi. Puoi decidere di utilizzare
                                campagne social o Google per arrivare a nuovi segnmenti di pubblico, oppure raggiungere
                                i clienti esistenti inserendo la landing page nel testo dei tuoi SMS (o anche email con
                                il Piano Premium)</p>
                        </div>
                        <div class="col-6">
                            <img src="https://kdistribution.it/wp-content/uploads/2021/11/LANDING_Tavola-disegno-1.png"
                                 width="100%"/></div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-12">
                            <h4 class="poppins color-primary text-bold">Rivenditore SMS Autorizzato</h4>
                            <p class="poppins color-primary">Vuoi rivendere ai tuoi clienti i pacchetti SMS, con il
                                prezzo che decidi tu? Clicca su "Rivendita pacchetti SMS" nella barra laterale sinistra
                                della Dashboard una volta effettuato l'accesso a Master SMS.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #premiumModal let-modal>
    <div class="modal-body" style="height: 590px;">
        <div class="row">
            <div class="col-4">
                <div class="price-card mb-2 py-4 price-special">
                    <h3>PREMIUM</h3>
                    <p>Costo di attivazione</p>
                    <h1 class="mt-3">€ 280<span>,00</span></h1>
                    <p class="text-sm">canone annuale</p>
                    <div class="details">
                        <p>CONTIENE:</p>
                        <hr>
                        <p>Tutto il piano base</p>
                        <p>Campagne E-mail</p>
                        <p>100 Landing Page</p>
                        <p>Coupon Digitali</p>
                        <button class="btn mt-2 btn-light pulse-animation">Puoi acquistarlo sul portale master SMS
                        </button>
                        <p class="text-sm">Veloce, Affidabile e Sicuro</p>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <h2 class="poppins text-center color-primary text-bold">Una marcia in più per far crescere la tua
                    attività</h2>
                <hr>
                <div class="plan-modal-details">
                    <div class="row">
                        <div class="col-6">
                            <h4 class="poppins color-primary text-bold">Trovi tutto quello che c'è nel Piano Base... con
                                una marcia in più!</h4>
                            <ul class="poppins color-primary">
                                <li>acquisizione e gestione clienti</li>
                                <li>invio SMS ed EMAIL</li>
                                <li>landing page</li>
                                <li>fidelity card e coupon digitali</li>
                                <li>rivenditore autorizzato</li>
                            </ul>
                        </div>
                        <div class="col-6">
                            <img src="https://kdistribution.it/wp-content/uploads/2021/11/SMS.png" width="100%"/>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-6">
                            <h4 class="poppins color-primary text-bold">Crea Email che lasceranno a bocca aperta, è
                                facile!</h4>
                            <p class="poppins color-primary">Per realizzare un'email d'effetto non servono competenze
                                tecniche. Con l'editor drag & drop non dovrai far altro che trascinare i blocchi già
                                pronti, posizionarli dove preferisci e personalizzare il contenuto.</p>
                        </div>
                        <div class="col-6">
                            <img src="https://kdistribution.it/wp-content/uploads/2021/11/AUTOMAZIONE.png"
                                 width="100%"/>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-6">
                            <h4 class="poppins color-primary text-bold">100 Landing Page a tua disposizione</h4>
                            <p class="poppins color-primary">Design e strumenti "pro", alla portata di tutti. Condividi
                                la pagina su più canali, a seconda dei tuoi obiettivi. Puoi decidere di utilizzare
                                campagne social o Google per arrivare a nuovi segnmenti di pubblico, oppure raggiungere
                                i clienti esistenti inserendo la landing page nel testo dei tuoi SMS (o anche email con
                                il Piano Premium)</p>
                        </div>
                        <div class="col-6">
                            <img src="https://kdistribution.it/wp-content/uploads/2021/11/LANDING_Tavola-disegno-1.png"
                                 width="100%"/></div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-6">
                            <h4 class="poppins color-primary text-bold">Creare coupon digitali personalizzati</h4>
                            <p class="poppins color-primary">Crea in pochi secondi coupon digitali che ti aiutano a raccogliere nuovi clienti, aumentare le vendite e migliorare il tasso di fidelizzazione.</p>
                        </div>
                        <div class="col-6 text-right">
                            <img src="https://kdistribution.it/wp-content/uploads/2021/11/COUPON.png"
                                 width="50%"/></div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-12">
                            <h4 class="poppins color-primary text-bold">Rivenditore SMS Autorizzato</h4>
                            <p class="poppins color-primary">Vuoi rivendere ai tuoi clienti i pacchetti SMS, con il
                                prezzo che decidi tu? Clicca su "Rivendita pacchetti SMS" nella barra laterale sinistra
                                della Dashboard una volta effettuato l'accesso a Master SMS.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
