import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';

export class WassengerMessage extends BaseModel {
    id_dealer: number;
    id_agente: number;
    id_masteragente: number;
    id_wassenger: number;
    dealer: string;
    agent: string;
    masteragent: string;
    wassenger: any;
    sent: string;
}

export class WassengerPagination extends BaseModel {
    data: WassengerMessage[];
    _meta: MetaPagination;
    _links: Array<any>;
}
