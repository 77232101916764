import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
    EMailCampaign, EMailClickDetail, EMailClickDetailPagination,
    EMailClickMember, EMailClickMemberPagination,
    EMailOpenMember,
    EMailOpenMemberPagination,
    EMailRecipient,
    EMailRecipientPagination
} from '@models/e-mail-campaign';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {EMailCampaignService} from '@services/e-mail-campaign.service';

@Component({
    selector: 'app-e-mail-campaign-modal',
    templateUrl: './e-mail-campaign-modal.component.html',
    styleUrls: ['./e-mail-campaign-modal.component.css']
})
export class EMailCampaignModalComponent implements OnInit {
    @Input() campaign: EMailCampaign;
    @Input() report = {
        isRecipients: false,
        isClicks: false,
        isOpens: false,
        isContent: false
    };
    busy: any;
    clickBusy: any;
    localFormat = 'DD/MM/YYYY';
    recipientPagination: EMailRecipientPagination = {
        data: [],
        current_page: 1,
        total_items: 0
    };
    openMemberPagination: EMailOpenMemberPagination = {
        data: [],
        current_page: 1,
        total_items: 0
    };
    clicksPagination: EMailClickDetailPagination = {
        data: [],
        current_page: 1,
        total_items: 0
    };
    clickMemberPagination: EMailClickMemberPagination = {
        data: [],
        current_page: 1,
        total_items: 0
    };
    contactStatuses = [
        {
            label: 'Subscribed',
            key: 'subscribed'
        },
        {
            label: 'Unsubscribed',
            key: 'unsubscribed'
        }
    ];
    content = {
        archive_html: ''
    };
    selectedClick: EMailClickDetail;
    clickMemberModal: NgbModalRef;
    @ViewChild('clickMemberTemplate') private clickMemberTemplate;

    constructor(public modalService: NgbModal,
                private eMailCampaignService: EMailCampaignService) {
    }

    ngOnInit(): void {
        if (this.report.isRecipients) {
            this.loadRecipients();
        } else if (this.report.isOpens) {
            this.loadOpensReport();
        } else if (this.report.isClicks) {
            this.loadClicksReport();
        } else if (this.report.isContent) {
            this.loadCampaignContent();
        }
    }

    loadRecipients() {
        const params = {};
        if (this.recipientPagination?.current_page) {
            params['page'] = this.recipientPagination?.current_page;
        }
        params['campaign_id'] = this.campaign?.id;
        this.busy = this.eMailCampaignService.getRecipients(params).subscribe((response: EMailRecipientPagination) => {
            this.recipientPagination = response;
        });
    }

    loadOpensReport() {
        const params = {};
        if (this.recipientPagination?.current_page) {
            params['page'] = this.openMemberPagination?.current_page;
        }
        params['campaign_id'] = this.campaign?.id;
        this.busy = this.eMailCampaignService.getOpensReport(params).subscribe((response: EMailOpenMemberPagination) => {
            this.openMemberPagination = response;
        });
    }

    loadClicksReport() {
        const params = {};
        if (this.clicksPagination?.current_page) {
            params['page'] = this.clicksPagination?.current_page;
        }
        params['campaign_id'] = this.campaign?.id;
        this.busy = this.eMailCampaignService.getClicksReport(params).subscribe((response: EMailClickDetailPagination) => {
            this.clicksPagination = response;
        });
    }

    loadCampaignContent() {
        const params = {
            campaign_id: this.campaign?.id
        };
        this.busy = this.eMailCampaignService.getCampaignContent(params).subscribe((response) => {
            this.content = response;
        });
    }

    showClickMemberModal(click: EMailClickDetail) {
        this.selectedClick = click;
        this.clickMemberPagination.data = [];
        this.clickMemberPagination.total_items = 0;
        this.clickMemberPagination.current_page = 1;
        this.clickMemberModal = this.modalService.open(this.clickMemberTemplate, {size: 'xl'});
        this.loadClickMembers();
    }

    loadClickMembers() {
        const params = {};
        if (this.clickMemberPagination?.current_page) {
            params['page'] = this.clickMemberPagination?.current_page;
        }
        params['campaign_id'] = this.campaign?.id;
        params['link_id'] = this.selectedClick?.id;
        this.clickBusy = this.eMailCampaignService.getClickMembers(params).subscribe((response: EMailClickMemberPagination) => {
            this.clickMemberPagination = response;
        });
    }

    dismissClickMemberModal() {
        this.clickMemberModal.dismiss();
    }
}
