<div style="min-height: 250px;">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)"
                 size="default"
                 color="#fff"
                 type="square-jelly-box"
                 name="loader-chart"
                 [fullScreen]="false">
        <p style="color: white"> Caricamento... </p>
    </ngx-spinner>

    <ngx-charts-area-chart
            *ngIf="!error"
            [legend]="false"
            [showXAxisLabel]="false"
            [showYAxisLabel]="false"
            [xAxis]="true"
            [yAxis]="true"
            [showGridLines]="false"
            [timeline]="false"
            [results]="chartData"
    >
    </ngx-charts-area-chart>

    <div class="d-flex align-items-center justify-content-center flex-wrap"
         style="height: 250px; background-color: #000000d1;" *ngIf="error">
        <div class="text-center">
            <img src="/assets/img/flats/close.png" height="64px"/>
            <p class="lead text-white mt-2">C'è stato un errore nel caricamento...</p>
            <a (click)="load()" class="text-white mt-2 link"><i class="fas fa-redo-alt"></i></a>
        </div>
    </div>
</div>
