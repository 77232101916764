<div class="modal-body" [ngBusy]="busy">
    <form [formGroup]="paymentForm" (ngSubmit)="savePayment()">
        <div class="form-row">
            <div class="col-12 form-group">
                <label class="mt-2">
                    <span translate>Total to pay</span>
                    <span class="float-right">{{totalToPay|currency:currency}}</span>
                </label>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label for="dueDate" translate>Due date</label>
                <input type="text" class="form-control" id="dueDate" daterangepicker [options]="pickerOptions"
                       (selected)="selectedDate($event.start)"
                       formControlName="due_date">
                <val-errors controlName="due_date">
                    <ng-template valError="required">
                        <span translate>Due date is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label for="amount" translate>Amount</label>
                <div class="input-group">
                    <input type="text" class="form-control" id="amount" aria-describedby="basic-addon"
                           formControlName="amount">
                    <div class="input-group-append">
                        <div class="input-group-text bg-white" id="basic-addon"><strong>€</strong></div>
                    </div>
                </div>
                <val-errors controlName="amount">
                    <ng-template valError="max">
                        <span translate>Amount can't be greater than {{totalToPay|currency:currency}}</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label for="type" translate>Due method</label>
                <ng-select formControlName="due_method" [items]="methods" bindLabel="name" id="type"
                           bindValue="key"></ng-select>
                <val-errors controlName="due_method">
                    <ng-template valError="required">
                        <span translate>Due method is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-6 form-group d-flex align-items-center">
                <div class="custom-control custom-switch mt-2">
                    <input type="checkbox" class="custom-control-input" id="paymentWelded" formControlName="welded">
                    <label class="custom-control-label" for="paymentWelded" translate>Welded</label>
                </div>
            </div>
            <div class="col-12 form-group">
                <label for="description" translate>Note</label>
                <editor apiKey="1nilu7q8vcp0ntuviwq5nhcskmj17c6awfhpbnr0hhv4smdw" id="description" [init]="defaultConfig" formControlName="note"></editor>
            </div>
            <div class="col-12">
                <button type="submit" class="btn btn-success btn-block" [disabled]="paymentForm.invalid">
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                </button>
            </div>
        </div>
    </form>
</div>
