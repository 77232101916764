<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngClass]="bodyClass">
        <ng-content></ng-content>
    </div>
    <div class="modal-footer" *ngIf="showFooter">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Chiudi</button>
    </div>
</ng-template>
