<app-master-header
        [ngBusy]="busy"
        cardClass="bg-gradient-info"
        image="assets/img/flats/destination.png"
        title="Gestione Visite"
>
    <div class="row align-items-end">
        <div class="col-lg-4 col-12">
            <label translate>Seleziona periodo</label>
            <input
                    class="form-control"
                    type="text"
                    [(ngModel)]="filterForm.dateRange"
                    daterangepicker
                    (selected)="setSelectedDateRange($event.start, $event.end)"
            />
        </div>
    </div>
</app-master-header>

<div class="card">
    <div class="card-body">
        <div class="row" *ngIf="global">
            <div class="col-12 col-md-4 col-xl-2" *ngIf="defaultView">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/online-store.png"/>
                    <span class="title">{{ global.today_visits }}</span>
                    <span class="description"> Visite di oggi </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/calendar_2.png"/>
                    <span class="title">{{ global.period_visits }}</span>
                    <span class="description"> Visite nel periodo </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/percentage.png"/>
                    <span class="title">{{ global.average_period_visits }}</span>
                    <span class="description"> Media visite </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/settings_2.png"/>
                    <span class="title">{{ global.period_our_retailers }}</span>
                    <span class="description"> Visite uniche MG </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/new.png"/>
                    <span class="title">{{ global.period_other_retailers }}</span>
                    <span class="description"> Visite uniche altri pdv </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/internet.png"/>
                    <span class="title">{{ global.total_shops }}</span>
                    <span class="description"> Negozi totali </span>
                </div>
            </div>
        </div>

        <div class="row mt-4" *ngIf="global">
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/map.png"/>
                    <span class="title">{{ global.period_cities }}</span>
                    <span class="description"> Comuni visitati </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/placeholder.png"/>
                    <span class="title">{{ global.cities_with_shops }}</span>
                    <span class="description"> Comuni con negozi MG </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/global-network.png"/>
                    <span class="title">{{ global.covered_cities }}</span>
                    <span class="description">Comuni totali</span>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-12 col-xl-8">
        <div class="mb-3">
            <app-table
                    title="Visite programmate per oggi"
                    [rows]="visiteProgrammate.rows"
                    [columns]="visiteProgrammate.columns"
                    [small]="true"
                    [hideFooter]="true"
                    (onTrigger)="triggerProgrammata($event)"
            >
            </app-table>
        </div>

        <app-master-card
                bodyClass="p-0"
                title="Lista Dealer"
                image="assets/img/flats/map.png"
        >
            <app-table
                    [rows]="table.rows"
                    [columns]="table.columns"
                    [small]="true"
                    [hideFooterDescription]="true"
                    (onTrigger)="trigger($event)"
            >
                <p class="mt-3 ml-3">
                    Hai visitato un negozio non ancora Master Group?
                    <button class="btn btn-sm btn-success ml-3" (click)="triggerCustom()">
                        Segna la visita qui
                    </button>
                </p>
                <hr/>
            </app-table>
        </app-master-card>
    </div>
    <div class="col-12 col-xl-4">
        <h1 class="display-6 mb-0 mb-2">Timeline</h1>
        <div class="timeline" style="max-height: 700px; overflow-y: scroll">
            <ng-container *ngFor="let visits of timeline">
                <div class="time-label lead">
                    <span class="bg-gradient-success">{{ visits.periodo }}</span>
                </div>
                <div *ngFor="let item of visits.visite">
                    <i class="fas fa-sim-card bg-gradient-info"></i>
                    <div class="timeline-item bg-gradient-light elevation-1">
            <span class="time"
            ><i class="far fa-clock mr-2"></i>{{ item.ora }}</span
            >
                        <h3 class="timeline-header">
                            <a
                                    *ngIf="item.id_dealer"
                                    [routerLink]="['/dealer', item.id_dealer]"
                            >{{ item.negozio }}</a
                            >
                            <span *ngIf="!item.id_dealer">{{ item.negozio }}</span>
                        </h3>
                        <div class="timeline-body">
                            <p class="lead font-italic"></p>
                            <p>{{ item.note_visita }}</p>

                            <p *ngIf="item.affiliato" class="mt-2"><i class="fas fa-check-circle text-success"></i>
                                Affiliato</p>
                            <p *ngIf="item.attivita_chiusa" class="mt-2"><i class="fas fa-check-circle text-danger"></i>
                                Attivita chiusa</p>


                            <hr>
                            <ul>
                                <li>Plafond segnalato: {{item?.actual_plafond | currency: '€ '}}</li>
                                <li>Sin in magazzino: {{item?.warehouse_sim}}</li>
                            </ul>

                            <ng-container *ngIf="item?.documents.length > 0">
                                <hr>
                                <p class="text-italic">Foto del negozio:</p>
                                <ul>
                                    <li *ngFor="let document of item?.documents; let i = index;">
                                        <a [href]="baseAwsUrl+'/'+document?.file_path"
                                           target="_blank">Foto {{i + 1}}</a>
                                    </li>
                                </ul>
                            </ng-container>


                            <ng-container *ngFor="let reply of item.replies">
                                <hr/>
                                <div [ngClass]="{ 'text-right': reply.direction === 'right' }">
                                    <p class="lead font-italic">{{ reply.nome_autore }}</p>
                                    <p>{{ reply.testo }}</p>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="item.showReply">
                                <hr/>
                                <textarea
                                        class="form-control"
                                        [(ngModel)]="item.reply"
                                ></textarea>
                                <button
                                        class="btn btn-sm btn-success mt-2"
                                        (click)="sendReply(item)"
                                >
                                    Invia
                                </button>
                            </ng-container>
                        </div>
                        <div class="timeline-footer">
                            <em>{{ item.citta }}, {{ item.provincia }}</em>

                            <a
                                    class="float-right font-italic link"
                                    (click)="item.showReply = !item.showReply"
                            ><i class="fa fa-reply"></i> Rispondi</a
                            >
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #nuovaVisita let-modal class="bg-gradient-light">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Nuova Visita</h4>
        <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busyModal">
        <form [formGroup]="form">
            <div class="row">
                <div class="col-12">
                    <label class="lead">Dealer</label>
                    <select class="form-control" disabled formControlName="id_dealer">
                        <option [value]="curDealerId">{{ curDealerName }}</option>
                    </select>
                    <val-errors controlName="id_dealer"></val-errors>
                </div>
                <div class="col-12 my-2">
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" formControlName="affiliato"
                               id="flag-affiliato">
                        <label for="flag-affiliato" class="custom-control-label">Affiliato</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" formControlName="attivita_chiusa"
                               id="flag-chiuso">
                        <label for="flag-chiuso" class="custom-control-label">Attività chiusa</label>
                    </div>
                </div>
                <div class="col-12 my-2">
                    <label class="lead">Date</label>
                    <input
                            class="form-control"
                            placeholder="gg-mm-aaaa"
                            ngbDatepicker
                            #dateTwo="ngbDatepicker"
                            formControlName="date"
                            (click)="dateTwo.toggle()"
                    />
                </div>
                <div class="col-6">
                    <label class="lead" translate>Sim in warehouse</label>
                    <input class="form-control" formControlName="warehouse_sim" type="number"/>
                    <val-errors controlName="warehouse_sim">
                    </val-errors>
                </div>
                <div class="col-6">
                    <label class="lead" translate>Actual plafond</label>
                    <input class="form-control" formControlName="actual_plafond" type="number"/>
                    <val-errors controlName="actual_plafond"></val-errors>
                </div>
                <div class="col-12">
                    <label class="lead">Note</label>
                    <textarea
                            formControlName="note_visita"
                            class="form-control w-100"
                            rows="6"
                    ></textarea>
                    <val-errors controlName="note_visita"></val-errors>
                </div>
                <div class="col-12">
                    <h4>Foto del negozio
                        <button class="btn btn-link float-right" type="button" (click)="addDocument(form)">
                            <i class=" fa fa-plus text-info"></i>
                        </button>
                    </h4>
                </div>
                <div class="col-12 mt-2"
                     *ngFor="let document of form?.controls?.documents?.controls; let i=index;">
                    <div class="row" [formGroup]="document">
                        <div class="col-10">
                            <label translate>File</label>
                            <div class="custom-file" *ngIf="!document?.get('file_name')?.value">
                                <input type="file" class="custom-file-input"
                                       (change)="handleFileInput($event?.target?.files[0], document)">
                                <label class="custom-file-label" for="file" translate>
                                    Upload file...
                                </label>
                            </div>
                            <div *ngIf="document?.get('file_name').value">
                                <span class="text-break">{{document?.get('file_name')?.value}}</span>
                                <button class="btn btn-xs btn-warning float-right" type="button"
                                        (click)="handleFileRemove(document)">
                                    <i class="fa fa-times m-0 px-1"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col d-flex align-items-end justify-content-end">
                            <button class="btn btn-link" type="button"
                                    (click)="removeDocument(form, i)">
                                <i class=" fa fa-trash text-danger"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <button
                            class="btn btn-success btn-block"
                            [disabled]="!this.form.valid"
                            (click)="saveVisita()"
                    >
                        <i class="fa fa-check-circle mr-2"></i> Salva
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #visitaProgrammata let-modal class="bg-gradient-light">
    <div class="modal-header">
        <h4 class="modal-title">Conferma Visita</h4>
        <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busyModal">
        <form [formGroup]="formProgrammata">
            <div class="row">
                <div class="col-12 my-2">
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" formControlName="affiliato"
                               id="flag-affiliato">
                        <label for="flag-affiliato" class="custom-control-label">Affiliato</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" formControlName="attivita_chiusa"
                               id="flag-chiuso">
                        <label for="flag-chiuso" class="custom-control-label">Attività chiusa</label>
                    </div>
                </div>
                <div class="col-12 my-2">
                    <label class="lead" translate>Date</label>
                    <input
                            class="form-control"
                            placeholder="gg-mm-aaaa"
                            ngbDatepicker
                            #dateTwo="ngbDatepicker"
                            formControlName="date"
                            (click)="dateTwo.toggle()"
                    />
                </div>
                <div class="col-6">
                    <label class="lead" translate>Sim in warehouse</label>
                    <input class="form-control" formControlName="warehouse_sim" type="number"/>
                    <val-errors controlName="warehouse_sim"></val-errors>
                </div>
                <div class="col-6">
                    <label class="lead" translate>Actual plafond</label>
                    <input class="form-control" formControlName="actual_plafond" type="number"/>
                    <val-errors controlName="actual_plafond"></val-errors>
                </div>
                <div class="col-12">
                    <label class="lead">Note</label>
                    <textarea
                            formControlName="note_visita"
                            class="form-control w-100"
                            rows="6"
                    ></textarea>
                    <val-errors controlName="note_visita"></val-errors>
                </div>
                <div class="col-12 mt-2">
                    <h4>Foto del negozio
                        <button class="btn btn-link float-right" type="button" (click)="addDocument(formProgrammata)">
                            <i class=" fa fa-plus text-info"></i>
                        </button>
                    </h4>
                </div>
                <div class="col-12"
                     *ngFor="let document of formProgrammata?.controls?.documents?.controls; let i=index;">
                    <div class="row" [formGroup]="document">
                        <div class="col-10">
                            <label translate>File</label>
                            <div class="custom-file" *ngIf="!document?.get('file_name')?.value">
                                <input type="file" class="custom-file-input"
                                       (change)="handleFileInput($event?.target?.files[0], document)">
                                <label class="custom-file-label" for="file" translate>
                                    Upload file...
                                </label>
                            </div>
                            <div *ngIf="document?.get('file_name').value">
                                <span class="text-break">{{document?.get('file_name')?.value}}</span>
                                <button class="btn btn-xs btn-warning float-right" type="button"
                                        (click)="handleFileRemove(document)">
                                    <i class="fa fa-times m-0 px-1"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col d-flex align-items-end justify-content-end">
                            <button class="btn btn-link" type="button"
                                    (click)="removeDocument(formProgrammata, i)">
                                <i class=" fa fa-trash text-danger"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <button
                            class="btn btn-success btn-block"
                            [disabled]="!this.formProgrammata.valid"
                            (click)="saveVisitaProgrammata()"
                    >
                        <i class="fa fa-check-circle mr-2"></i> Salva
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #nuovaVisitaCustom let-modal class="bg-gradient-light">
    <div class="modal-header">
        <h4 class="modal-title">Nuova Visita</h4>
        <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busyModal">
        <form [formGroup]="formCustom">
            <div class="row">
                <div class="col-12">
                    <label class="lead">Dealer</label>
                    <input class="form-control" formControlName="negozio"/>
                    <val-errors controlName="negozio"></val-errors>
                </div>
                <div class="col-12 my-2">
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" formControlName="affiliato"
                               id="flag-affiliato">
                        <label for="flag-affiliato" class="custom-control-label">Affiliato</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" formControlName="attivita_chiusa"
                               id="flag-chiuso">
                        <label for="flag-chiuso" class="custom-control-label">Attività chiusa</label>
                    </div>
                </div>
                <div class="col-12 my-2">
                    <label class="lead">Date</label>
                    <input
                            class="form-control"
                            placeholder="gg-mm-aaaa"
                            ngbDatepicker
                            #dateTwo="ngbDatepicker"
                            formControlName="date"
                            (click)="dateTwo.toggle()"
                    />
                </div>
                <div class="col-6">
                    <label class="lead" translate>Sim in warehouse</label>
                    <input class="form-control" formControlName="warehouse_sim" type="number"/>
                    <val-errors controlName="warehouse_sim"></val-errors>
                </div>
                <div class="col-6">
                    <label class="lead" translate>Actual plafond</label>
                    <input class="form-control" formControlName="actual_plafond" type="number"/>
                    <val-errors controlName="actual_plafond"></val-errors>
                </div>
                <div class="col-12">
                    <label class="lead">Note</label>
                    <textarea
                            formControlName="note_visita"
                            class="form-control w-100"
                            rows="6"
                    ></textarea>
                    <val-errors controlName="note_visita"></val-errors>
                </div>
                <div class="col-12">
                    <app-address
                            [small]="true"
                            [fullColumn]="true"
                            (addressData)="this.setIndirizzo($event)"
                    ></app-address>
                </div>
                <div class="col-12">
                    <h4>Foto del negozio
                        <button class="btn btn-link float-right" type="button" (click)="addDocument(formCustom)">
                            <i class=" fa fa-plus text-info"></i>
                        </button>
                    </h4>
                </div>
                <div class="col-12"
                     *ngFor="let document of formCustom?.controls?.documents?.controls; let i=index;">
                    <div class="row" [formGroup]="document">
                        <div class="col-10">
                            <label for="file" translate>File</label>
                            <div class="custom-file" *ngIf="!document?.get('file_name')?.value">
                                <input id="file" type="file" class="custom-file-input"
                                       (change)="handleFileInput($event?.target?.files[0], document)">
                                <label class="custom-file-label" for="file" translate>
                                    Upload file...
                                </label>
                            </div>
                            <div *ngIf="document?.get('file_name').value">
                                <span class="text-break">{{document?.get('file_name')?.value}}</span>
                                <button class="btn btn-xs btn-warning float-right" type="button"
                                        (click)="handleFileRemove(document)">
                                    <i class="fa fa-times m-0 px-1"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col d-flex align-items-end justify-content-end">
                            <button class="btn btn-link" type="button"
                                    (click)="removeDocument(formCustom, i)">
                                <i class=" fa fa-trash text-danger"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <button
                            class="btn btn-success btn-block"
                            [disabled]="!this.formCustom.valid"
                            (click)="saveVisitaCustom()"
                    >
                        <i class="fa fa-check-circle mr-2"></i> Salva
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
