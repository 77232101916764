<div class="card bg-gradient-light">
  <form (ngSubmit)="loadEventsList()">
    <div class="card-header">
      <h1 class="card-title" translate>Filters</h1>
    </div>

    <div class="card-body">
      <div class="form-row">
        <div class="form-group col-12 col-lg-4">
          <label for="dealer" translate>Dealer</label>
          <app-retailer-typeahead (onChange)="setSubject($event)" [small]="true"></app-retailer-typeahead>
        </div>
        <div class="form-group col-12 col-lg-4">
          <label for="status" translate>Status</label>
          <ng-select name="status" [(ngModel)]="filter.status" id="status" class = "form-control-sm">
            <ng-option *ngFor="let status of eventStatuses" [value]="status.key" translate>{{ status.name }}</ng-option>
          </ng-select>
        </div>
        <div class="form-group col-12 col-lg-4">
          <label for="date_range" translate>From date - To date</label>
          <input
            id="date_range"
            class="form-control form-control-sm"
            type="text"
            daterangepicker
            [(ngModel)]="filter.date_range"
            name="date_range"
            (selected)="generateDateRangeString($event.start, $event.end, 'date_range')"
          />
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button class="btn btn-dark btn-sm mr-2" type="button" (click)="resetFilter()" translate>Reset</button>
      <button class="btn btn-primary btn-sm" type="submit" translate>Filter</button>
    </div>
  </form>
</div>
<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-lg-12 col-12">
        <h4 class="mb-0" translate>Events</h4>
      </div>
    </div>
  </div>
  <div class="card-body p-0 rounded-bottom table-responsive">
    <table class="table table-sm table-striped">
      <thead>
        <tr>
          <th translate>Name</th>
          <th translate>Company name</th>
          <th translate>From date</th>
          <th translate>To date</th>
          <th translate>Event type</th>
          <th translate>Event set-ups</th>
          <th translate class="text-center">Status</th>
          <th class="text-right"></th>
        </tr>
      </thead>
      <tbody class="bg-gradient-white">
        <ngx-spinner
          bdColor="rgba(0, 0, 0, 0.8)"
          size="default"
          color="#fff"
          type="square-jelly-box"
          name="table-events"
          [fullScreen]="false"
        >
          <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <tr *ngFor="let event of events">
          <td>{{ event.nome }}</td>
          <td>
            <a [routerLink]="['/dealer', event?.dealer?.id]" target="_blank">
              {{ event?.dealer?.company_name }}
            </a>
          </td>
          <td>{{ event.from_date | amDateFormat: localFormat }}</td>
          <td>{{ event.to_date | amDateFormat: localFormat }}</td>
          <td>{{ event.evento_tipologia }}</td>
          <td>{{ event.evento_allestimenti }}</td>
          <td class="text-center">
            <ng-container *ngFor="let status of eventStatuses">
              <ng-container *ngIf="event.stato == status?.key">
                <span [ngClass]="'badge ' + status.class" translate>
                  {{ status?.name }}
                </span>
              </ng-container>
            </ng-container>
          </td>
          <td class="text-right">
            <button class="btn btn-link" (click)="showDetailModal(event)">
              <i class="fa fa-eye text-info"></i>
            </button>
            <button class="btn btn-link" (click)="showEditModal(event)">
              <i class="fa fa-edit text-muted"></i>
            </button>
          </td>
        </tr>
        <tr *ngIf="events?.length <= 0">
          <td class="text-muted" colspan="15" translate>List is empty</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer" *ngIf="events?.length > 0">
    <span
      ><span translate>There are</span><b> {{ this.eventsPagination.totalCount }}</b>
      {{ this.eventsPagination.totalCount <= 1 ? "document" : ("documents" | translate) }}.</span
    >
    <ngb-pagination
      *ngIf="eventsPagination.pageCount > 1"
      size="sm"
      [pageSize]="eventsPagination.perPage"
      [maxSize]="10"
      [(page)]="eventsPagination.currentPage"
      [collectionSize]="eventsPagination.totalCount"
      (pageChange)="loadEventsList()"
      [boundaryLinks]="true"
    ></ngb-pagination>
  </div>
</div>

<ng-template #eventDetailTemplate let-modal>
  <app-event-view [event]="selectedEvent"></app-event-view>
</ng-template>
<ng-template #eventEditTemplate let-modal>
  <app-event-edit-form-template [events]="events" [event]="selectedEvent"></app-event-edit-form-template>
</ng-template>
