import {Injectable} from '@angular/core';
import {RequestService} from './request.service';
import {map} from 'rxjs/operators';
import {PbxCallListPagination} from '@models/pbx/pbx-call';
import {MandrillMailPagination} from '@models/mandrill-mail';
import {WassengerPagination} from '@models/wassenger-message';

@Injectable({
    providedIn: 'root',
})
export class MailService {
    ENDPOINT = 'mail';

    constructor(public request: RequestService) {
    }

    getOverview(date) {
        return this.request.get(
            `${this.ENDPOINT}/overview/${date}`
        );
    }

    getTemplate(id) {
        return this.request.get(
            `${this.ENDPOINT}/template/${id}`
        );
    }

    getDetail(slug: string, fromDate: string, toDate: string, page: number) {
        return this.request.get(
            `${this.ENDPOINT}/detail/${slug}/${fromDate}/${toDate}?expand=mandrill,dealer,agent,masteragent&page=${page}&per-page=10`
        ).pipe(
            map((response: any) => {
                return new MandrillMailPagination(response);
            })
        );
    }

    getWassengerDetail(slug: string, fromDate: string, toDate: string, page: number) {
        return this.request.get(
            `${this.ENDPOINT}/detail/${slug}/${fromDate}/${toDate}?expand=wassenger,dealer,agent,masteragent&page=${page}&per-page=10`
        ).pipe(
            map((response: any) => {
                return new WassengerPagination(response);
            })
        );
    }
}
