import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {RequestService} from './request.service';

@Injectable({
    providedIn: 'root',
})
export class InvoiceService {
    ENDPOINT = 'invoices';

    constructor(public request: RequestService) {
    }

    list(params) {
        return this.request.get(`${this.ENDPOINT}/list`, params, {observe: 'response'})
            .pipe(
                map((data: HttpResponse<any>) => {
                    return {
                        rows: data.body,
                        pagination: {
                            totalCount: parseInt(data.headers.get('X-Pagination-Total-Count')),
                            page: parseInt(data.headers.get('X-Pagination-Current-Page')),
                            pageSize: parseInt(data.headers.get('X-Pagination-Per-Page')),
                            pages: parseInt(data.headers.get('X-Pagination-Page-Count')),
                        },
                    };
                })
            );
    }

    get(id) {
        return this.request.get(`${this.ENDPOINT}/view`, {
            id: id,
            expand: 'company,company.city,company.province,lines,lines.sublines,lines.descrizione,lines.monthName'
        });
    }


    overview() {
        return this.request.get(`${this.ENDPOINT}/overview`);
    }

    regenInvoice(id) {
        return this.request.post(`${this.ENDPOINT}/regen-invoice/${id}`);
    }

    updateInvoice(id, data) {
        return this.request.put(`${this.ENDPOINT}/${id}`, data);
    }

    updateInvoiceLine(id, data) {
        return this.request.put(`${this.ENDPOINT}/update-line/${id}`, data);
    }

    updateInvoiceDetailLine(id, data) {
        return this.request.put(`${this.ENDPOINT}/update-detail/${id}`, data);
    }

    sendToSDI(id) {
        return this.request.post(`${this.ENDPOINT}/send-to-sdi/${id}`);
    }

    updateSdiStatus(id) {
        return this.request.post(`${this.ENDPOINT}/update-sdi-status/${id}`);
    }

    redoInvoice(id: number, $event: any) {
        return this.request.post(`${this.ENDPOINT}/redo-invoice/${id}`, {
            description: $event
        });
    }

    mergeDocuments(selectedInvoices: any[]) {
        return this.request.post(`${this.ENDPOINT}/merge-documents`, {
            documents: selectedInvoices
        });
    }
}
