import {ShopProduct} from '@models/e-pay/shop-product';
import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';

export class ShopOrder {
    id: number;
    dealer_id: number;
    phone_number: string;
    total_price: any;
    status: string;
    created_at: string;
    company_name?: string;
    company_owner?: string;
    order_items: ShopOrderItem[];
    payments: ShopPayment[];
    txt_id?: string;
}

export class ShopOrderPagination extends BaseModel {
    data: ShopOrder[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class ShopOrderItem {
    id: number;
    order_id: number;
    product_id: number;
    product_variant_id?: number;
    product_name: string;
    product_variant_name?: string;
    price: string;
    dealer_price: string;
    our_price: string;
    type: string;
    ean: string;
    pan: string;
    brand_name?: string;
    category_name?: string;
    product?: ShopProduct;
}

export class ShopPayment {
    id: number;
    order_id: number;
    amount: string;
    status: string;
    type: string;
    name: string;
    token: string;
    transaction_id: string;
    cancel_transaction_id: string;
    card_type: string;
    card_number: string;
    card_code: string;
    expiration_year: number;
    expiration_month: number;
    receipt: string;
}
