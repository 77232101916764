<div class="card bg-gradient-light">
    <form (ngSubmit)="loadProducts()">
        <div class="card-header">
            <h1 class="card-title" translate>Filters</h1>
        </div>
        <div class="card-body">

            <div class="form-row">
                <div class="form-group col-12 col-md-3">
                    <label translate for="name">Name</label>
                    <input type="text" class="form-control form-control-sm" id="name" [(ngModel)]="filter.name" name="name">
                </div>
                <div class="form-group col-12 col-md-3">
                    <label translate for="type">Type</label>
                    <ng-select name="type" id="type" [(ngModel)]="filter.type"
                               [items]="productTypes" class="form-control-sm"
                               bindValue="key" bindLabel="name"></ng-select>
                </div>
                <div class="form-group col-12 col-md-3">
                    <label translate for="category">Category</label>
                    <ng-select name="category" id="category" [(ngModel)]="filter.category_id"
                               [items]="categories" class="form-control-sm"
                               bindValue="id" bindLabel="nome" (search)="loadCategories($event?.term)"
                               [loading]="categoriesLoading"></ng-select>
                </div>
                <div class="form-group col-12 col-md-3">
                    <label translate for="brand">Brand</label>
                    <ng-select name="category" id="brand" [(ngModel)]="filter.brand_id"
                               [items]="brands" class="form-control-sm"
                               bindValue="id" bindLabel="nome" (search)="loadBrands($event?.term)"
                               [loading]="brandsLoading"></ng-select>
                </div>

                <div class="form-group col-12 col-md-3">
                    <label translate for="visible">Visibile</label>
                    <ng-select id="visible" name="matched_bank" [(ngModel)]="filter.visibile"
                               class="form-control-sm">
                        <ng-option translate value="1">Yes</ng-option>
                        <ng-option translate value="0">No</ng-option>
                    </ng-select>
                </div>
                <div class="form-group col-12 col-md-3">
                    <label translate for="deleted">Cancellato</label>
                    <ng-select id="deleted" name="matched_bank" [(ngModel)]="filter.deleted"
                               class="form-control-sm">
                        <ng-option translate value="1">Yes</ng-option>
                        <ng-option translate value="0">No</ng-option>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button class="btn btn-dark btn-sm mr-2" type="button" (click)="resetFilter()" translate>
                Reset
            </button>
            <button
                    class="btn btn-primary btn-sm"
                    type="submit" translate
            >
                Filter
            </button>
        </div>
    </form>
</div>
<div class="card">
    <div class="card-header">
        <div class="row">
            <div class="col-lg-10 col-12">
                <h4 class="mb-0" translate>Products</h4>
            </div>
            <div class="col-lg-2 col-12">
                <button class="btn btn-success btn-sm float-right" translate (click)="showModal()">
                    Add Product
                </button>
            </div>
        </div>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th translate>Name</th>
                <th translate>Type</th>
                <th translate>Category</th>
                <th translate>Brand</th>
                <th translate class="text-right">Price</th>
                <th></th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="table-products"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let product of products">
                <td>
                    <div class="media d-flex align-items-center">
                        <div class="logo-img">
                            <img class="mr-3"
                                 [src]="product.immagine_path"
                                 alt="">
                        </div>
                        <div class="media-body ml-2">
                            <h5 class="mt-0 mb-0"><strong>{{product.nome}}</strong></h5>
                            <small>
                                <app-rich-text-truncate [text]="product.descrizione"></app-rich-text-truncate>
                            </small>
                        </div>
                    </div>
                </td>
                <td>{{findProductType(product.personalizzabile)}}</td>
                <td>{{product.category}}</td>
                <td>{{product.brand}}</td>
                <td class="text-right">{{product.prezzo|currency:currency}}</td>
                <td class="text-right">
                    <button class="btn btn-link" (click)="showTemplateModal(product)"
                            *ngIf="product.personalizzabile == productTypes[1]?.key">
                        <i class="fas fa-file-upload text-success"></i>
                    </button>
                    <button class="btn btn-link" (click)="showDetailModal(product)">
                        <i class="fa fa-eye text-info"></i>
                    </button>
                    <button class="btn btn-link">
                        <i class="fa fa-edit text-muted" (click)="showModal(product)"></i>
                    </button>
                    <button class="btn btn-link" [swal]="deleteProductOptions"
                            (confirm)="deleteProduct(product.id)">
                        <i class="fa fa-trash text-danger"></i>
                    </button>
                </td>
            </tr>
            <tr *ngIf="products.length<=0">
                <td translate colspan="6" class="text-muted">List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="products.length>0 && productsPagination">
    <span><span translate>There are</span><b> {{ this.productsPagination.totalCount }}</b>
        {{this.productsPagination.totalCount <= 1 ? 'product' : 'products'}}.</span>
        <ngb-pagination *ngIf="productsPagination.pageCount>1"
                        size="sm"
                        [pageSize]="productsPagination.perPage"
                        [maxSize]="10"
                        [(page)]="productsPagination.currentPage"
                        [collectionSize]="productsPagination.totalCount"
                        (pageChange)="loadProducts()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>
<ng-template #productFormTemplate let-modal>
    <app-shop-product-modal [products]="products" [selectedProduct]="selectedProduct"></app-shop-product-modal>
</ng-template>
<ng-template #productDetailTemplate let-modal>
    <app-shop-product-detail [product]="selectedProduct"></app-shop-product-detail>
</ng-template>
<ng-template #productTemplate let-modal>
    <app-shop-product-template [product]="selectedProduct"></app-shop-product-template>
</ng-template>
