import {CostsManagementComponent} from './operators/gestione-incentivazione/costs-management/costs-management.component';

import {KenaVisiteComponent} from './kena/kena-visite/kena-visite.component';
import {WhatsappComponent} from './operators/whatsapp/whatsapp.component';
import {MonthRangePickerComponent} from './widgets/month-range-picker/month-range-picker.component';
import {KenaRecapComponent} from './kena/kena-recap/kena-recap.component';
import {CodeManagementComponent} from './operators/code-management/code-management.component';
import {MasterAnalyticsComponent} from './master/master-analytics/master-analytics.component';
import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {CommonModule, registerLocaleData} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import localeIT from '@angular/common/locales/it';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {FullCalendarModule} from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './pages/main/main.component';
import {LoginComponent} from './pages/login/login.component';
import {HeaderComponent} from './pages/main/header/header.component';
import {FooterComponent} from './pages/main/footer/footer.component';
import {MenuSidebarComponent} from './pages/main/menu-sidebar/menu-sidebar.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesDropdownMenuComponent} from './pages/main/header/messages-dropdown-menu/messages-dropdown-menu.component';
import {NotificationsDropdownMenuComponent} from './pages/main/header/notifications-dropdown-menu/notifications-dropdown-menu.component';
import {OrderModule} from 'ngx-order-pipe';
import {NgbDateAdapter, NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TicketListComponent} from './pages/tickets/list/ticket-list.component';
import {TicketDetailComponent} from './pages/tickets/detail/ticket-detail.component';
import {NgArrayPipesModule, NgObjectPipesModule, NgPipesModule} from 'angular-pipes';
import {InvoiceListComponent} from './pages/invoices/list/invoice-list.component';
import {FaqListComponent} from './pages/faq/list/faq-list.component';
import {DocumentsListComponent} from './pages/documents/list/documents-list.component';
import {ContractListComponent} from './pages/contract/list/contract-list.component';
import {ContractNewComponent} from './pages/contract/new/contract-new.component';
import {ValdemortModule} from 'ngx-valdemort';
import {ErrorLogService} from '@helpers/error-log.service';
import {GlobalErrorHandler} from '@helpers/global-error.handler';
import {ToastsContainerComponent} from '@widgets/toasts-container/toasts-container.component';
import {ShopIndexComponent} from './pages/shop/index/shop-index.component';
import {FilledPdfComponent} from './pages/contract/pda/filled-pdf.component';
import {SafePipe} from '@helpers/safe.pipe';
import {FirstWordPipe} from '@helpers/first-word.pipe';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {MomentModule} from 'ngx-moment';
import 'moment/locale/it';
import {ContractDetailComponent} from './pages/contract/detail/contract-detail.component';
import {TicketModalComponent} from '@app/pages/tickets/modal/ticket-modal.component';
import {DocumentsModalComponent} from './pages/documents/modal/documents-modal.component';
import {PbxListComponent} from './pages/pbx/list/pbx-list.component';
import {SlotMachineIndexComponent} from './pages/slot-machine/index/slot-machine-index.component';
import {NotFoundComponent} from './pages/main/not-found/not-found.component';
import {ResetPasswordComponent} from './pages/main/reset-password/reset-password.component';
import {ComparatorIndexComponent} from './pages/comparator/index/comparator-index.component';
import {DealerPlusIndexComponent} from './pages/dealer-plus/dealer-plus-index/dealer-plus-index.component';
import {CustomerFormComponent} from './pages/customers/form/customer-form.component';
import {CreditCardDirectivesModule} from 'angular-cc-library';
import {PlafondTiscaliIndexComponent} from './pages/plafond/tiscali/index/plafond-tiscali-index.component';
import {PlafondKenaIndexComponent} from './pages/plafond/kena/index/plafond-kena-index.component';
import {CreditoIndexComponent} from './pages/credito/index/credito-index.component';
import {AssetsKenaIndexComponent} from './pages/assets/kena/index/assets-kena-index.component';
import {AssetsTiscaliIndexComponent} from './pages/assets/tiscali/index/assets-tiscali-index.component';
import {ProductComponent} from './pages/shop/partials/product/product.component';
import {CartComponent} from './pages/shop/partials/cart/cart.component';
import {ShopMenuComponent} from './pages/shop/partials/shop-menu/shop-menu.component';
import {ShopSubProductsPickerComponent} from './pages/shop/partials/sub-products/shop-sub-products-picker.component';
import {ShopBuyComponent} from './pages/shop/buy/shop-buy.component';
import {AddressComponent} from './pages/partials/address/address.component';
import {ShopDetailComponent} from './pages/shop/detail/shop-detail.component';
import {DocumentsCreateModalComponent} from './pages/documents/create-modal/documents-create-modal.component';
import {ShopListComponent} from './pages/shop/list/shop-list.component';
import {VideoIndexComponent} from './pages/video/index/video-index.component';
import {VisiteAgenteIndexComponent} from './pages/visite-agente/index/visite-agente-index.component';
import {DashboardRiepilogoComponent} from './pages/dashboard/partials/riepilogo/dashboard-riepilogo.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MomentDateFormatter} from '@helpers/datepicker.formatter';
import {DatepickerAdapter} from '@helpers/datepicker.adapter';
import {NgSelectModule} from '@ng-select/ng-select';
import {EventiIndexComponent} from './pages/eventi/eventi-index/eventi-index.component';
import {EventiNewComponent} from './pages/eventi/eventi-new/eventi-new.component';
import {EventiDetailComponent} from './pages/eventi/eventi-detail/eventi-detail.component';
import {OcrModalComponent} from './pages/contract/ocr-modal/ocr-modal.component';
import {NgxFileDropModule} from 'ngx-file-drop';
import {CommentsModalComponent} from './pages/comments/comments-modal/comments-modal.component';
import {InvoiceDetailComponent} from './pages/invoices/detail/invoice-detail.component';
import {PeriodSelectorComponent} from './pages/partials/period-selector/period-selector.component';
import {DataRangeComponent} from './pages/partials/data-range/data-range.component';
import {TableComponent} from './pages/partials/table/table.component';
import {FormComponent} from './pages/partials/form/form.component';
import {DialogFieldEditComponent} from './pages/partials/dialog-field-edit/dialog-field-edit.component';
import {ContractNewOffertaComponent} from '@app/pages/contract/new/steps/offerta/contract-new-offerta.component';
import {ContractNewPagamentoComponent} from '@app/pages/contract/new/steps/pagamento/contract-new-pagamento.component';
import {RicaricheIndexComponent} from '@app/pages/ricariche/index/ricariche-index.component';
import {ErrorInterceptor} from '@helpers/error.interceptor';
import {BasicAuthInterceptor} from '@helpers/basic-auth.interceptor';
import {NgBusyModule} from 'ng-busy';
import {ShopBlockedComponent} from './pages/shop/blocked/shop-blocked.component';
import {ShopPosComponent} from './pages/shop/pos/shop-pos.component';
import {CustomerBaseIndexComponent} from './pages/customer-base/index/customer-base-index.component';
import {CustomerBaseListComponent} from './pages/customer-base/list/customer-base-list.component';
import {CustomerBaseWidgetComponent} from './pages/customer-base/partials/widget/customer-base-widget.component';
import {CustomerBaseSendComponent} from './pages/customer-base/send/customer-base-send.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {AutocompleteAddressComponent} from './widgets/autocomplete-address/autocomplete-address.component';
import {ClassificaStandComponent} from './widgets/classifica-stand/classifica-stand.component';
import {ClassificaVenditoriComponent} from './widgets/classifica-venditori/classifica-venditori.component';
import {CustomerBaseImportComponent} from './pages/customer-base/import/customer-base-import.component';
import {CustomerBaseManualComponent} from './pages/customer-base/manual/customer-base-manual.component';
import {CustomerBaseCampaignComponent} from './pages/customer-base/campaign/customer-base-campaign.component';
import {CustomerBaseDisabledComponent} from './pages/customer-base/disabled/customer-base-disabled.component';
import {PlyrModule} from 'ngx-plyr';
import {ShopPrintingProductComponent} from './pages/shop/partials/printing-product/shop-printing-product.component';
import {ContractSimulatoreEniComponent} from './pages/contract/simulatore-eni/contract-simulatore-eni.component';
import {AvanzamentoComponent} from './pages/contract/avanzamento/avanzamento.component';
import {MasterDashboardComponent} from './master/master-dashboard/master-dashboard.component';
import {MasterCardComponent} from './master/master-dashboard/partials/master-card/master-card.component';
import {AutoloadChartComponent} from './master/master-dashboard/partials/autoload-chart/autoload-chart.component';
import {AutoloadRiepilogoComponent} from './master/master-dashboard/partials/autoload-riepilogo/autoload-riepilogo.component';
import {AutoloadPuntiVenditaComponent} from './master/master-dashboard/partials/autoload-punti-vendita/autoload-punti-vendita.component';
import {AutoloadTargetComponent} from './master/master-dashboard/partials/autoload-target/autoload-target.component';
import {AutoloadFatturatoComponent} from './master/master-dashboard/partials/autoload-fatturato/autoload-fatturato.component';
import {AutoloadOfferteComponent} from './master/master-dashboard/partials/autoload-offerte/autoload-offerte.component';
import {MasterCustomerBaseComponent} from './master/master-customer-base/master-customer-base.component';
import {MasterHeaderComponent} from './master/master-dashboard/partials/master-header/master-header.component';
import {MasterSubheaderComponent} from './master/master-dashboard/partials/master-subheader/master-subheader.component';
import {AutoloadPieChartComponent} from './master/master-dashboard/partials/autoload-pie-chart/autoload-pie-chart.component';
import {AutoloadHorizontalBarChartComponent} from './master/master-dashboard/partials/autoload-horizontal-bar-chart/autoload-horizontal-bar-chart.component';
import {AutoloadAreaChartComponent} from './master/master-dashboard/partials/autoload-area-chart/autoload-area-chart.component';
import {MasterProduzionePersaComponent} from './master/master-produzione-persa/master-produzione-persa.component';
import {AutoloadTableComponent} from './master/master-dashboard/partials/autoload-table/autoload-table.component';
import {MasterAnalisiFasceComponent} from './master/master-analisi-fasce/master-analisi-fasce.component';
import {MasterAnalisiPlafondComponent} from './master/master-analisi-plafond/master-analisi-plafond.component';
import {MasterAnalisiSimComponent} from './master/master-analisi-sim/master-analisi-sim.component';
import {MasterClassificaComponent} from './master/master-classifica/master-classifica.component';
import {MasterConfrontoComponent} from './master/master-confronto/master-confronto.component';
import {MasterNuoveAffiliazioniComponent} from './master/master-nuove-affiliazioni/master-nuove-affiliazioni.component';
import {MasterNegozioComponent} from './master/master-negozio/master-negozio.component';
import {MasterMappaComponent} from './master/master-mappa/master-mappa.component';
import {MasterCentralinoComponent} from './master/master-centralino/master-centralino.component';
import {MasterCentralinoOperatoreComponent} from './master/master-centralino-operatore/master-centralino-operatore.component';
import {MasterOutboundComponent} from './master/master-outbound/master-outbound.component';
import {MasterPresidiComponent} from './master/master-presidi/master-presidi.component';
import {AgmCoreModule} from '@agm/core';
import {TimRateizzoComponent} from './pages/tim-rateizzo/tim-rateizzo.component';
import {MasterEmailComponent} from './master/master-email/master-email.component';
import {MasterEmailDettaglioComponent} from './master/master-email-dettaglio/master-email-dettaglio.component';
import {GestioneIncentivazioneComponent} from './operators/gestione-incentivazione/gestione-incentivazione.component';
import {HotTableModule} from '@handsontable/angular';
import {HotComponent} from '@widgets/hot/hot.component';
import {SegnalazioneCaldaiaComponent} from '@app/pages/contract/segnalazione-caldaia/segnalazione-caldaia.component';
import {ModalComponent} from '@widgets/modal/modal.component';
import {DealerComponent} from './operators/dealer/dealer.component';
import {GestioneFatturazioneComponent} from './operators/gestione-fatturazione/gestione-fatturazione.component';
import {MasterEconomicsComponent} from './master/master-economics/master-economics.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {MasterVisiteIndexComponent} from './master/master-visite/master-visite-index/master-visite-index.component';
import {MasterVisiteDetailComponent} from './master/master-visite/master-visite-detail/master-visite-detail.component';
import {AgentVisiteIndexComponent} from './agent/agent-visite/agent-visite-index/agent-visite-index.component';
import {MasterEconomicsFatturatoComponent} from './master/master-economics/master-economics-fatturato/master-economics-fatturato.component';
import {MasterEconomicsNegoziComponent} from './master/master-economics/master-economics-negozi/master-economics-negozi.component';
import {MasterEconomicsAgentiComponent} from './master/master-economics/master-economics-agenti/master-economics-agenti.component';
import {MasterEconomicsGlobalComponent} from './master/master-economics/master-economics-global/master-economics-global.component';
import {GestioneStandComponent} from './operators/gestione-stand/gestione-stand.component';
import {DealerFormComponent} from './operators/dealer/dealer-form/dealer-form.component';
import {MasterAgenteComponent} from './master/master-agente/master-agente.component';
import {PlafondTimIndexComponent} from './pages/plafond/tim/plafond-tim-index/plafond-tim-index.component';
import {SafeHtmlPipe} from '@helpers/safe-html.pipe';
import {GestioneVenditoriComponent} from './operators/gestione-stand/gestione-venditori/gestione-venditori.component';
import {GestioneVenditoriDettagliComponent} from './operators/gestione-stand/gestione-venditori/gestione-venditori-dettagli/gestione-venditori-dettagli.component';
import {GestioneStandDettagliComponent} from './operators/gestione-stand/gestione-stand-dettagli/gestione-stand-dettagli.component';
import {MasterDashboardTabComponent} from './master/master-dashboard/master-dashboard-tab/master-dashboard-tab.component';
import {GestioneStorniComponent} from './operators/gestione-incentivazione/gestione-storni/gestione-storni.component';
import {GestioneReportComponent} from './operators/gestione-incentivazione/gestione-report/gestione-report.component';
import {ModalQuestionsComponent} from './widgets/modal-questions/modal-questions.component';
import {DatepickerRangePopupComponent} from './widgets/datepicker-range.popup/datepicker-range.popup.component';
import {MasterDealerDettaglioComponent} from './master/master-dealer-dettaglio/master-dealer-dettaglio.component';
import {ChartsModule} from 'ng2-charts';
import {AutoloadChartjsComponent} from './widgets/autoload-chartjs/autoload-chartjs.component';
import {AutoloadTableRiepilogoComponent} from './widgets/autoload-table-riepilogo/autoload-table-riepilogo.component';
import {AutoloadKeypointsComponent} from './widgets/autoload-keypoints/autoload-keypoints.component';
import {ShowDirective} from './directives/show.directive';
import {DontShowDirective} from './directives/dont-show.directive';
import {IsVendorEnabledDirective} from './directives/is-vendor-enabled.directive';
import {LaddaModule} from 'angular2-ladda';
import {TimRateizzoListComponent} from './pages/tim-rateizzo-list/tim-rateizzo-list.component';
import {MasterPlanningComponent} from './master/master-planning/master-planning.component';
import {MasterDealerWebComponent} from './master/master-dealer-web/master-dealer-web.component';
import {MasterTicketsComponent} from './master/master-tickets/master-tickets.component';
import {MasterKpiComponent} from './master/master-kpi/master-kpi.component';
import {SpinnerComponent} from './widgets/spinner/spinner.component';
import {SpinnerDirective} from './directives/spinner.directive';
import {MasterFacebookComponent} from './master/master-facebook/master-facebook.component';
import {MasterFacebookDetailComponent} from './master/master-facebook-detail/master-facebook-detail.component';
import {NegozioSelectComponent} from './widgets/negozio-select/negozio-select.component';
import {GestioneNegozioComponent} from './operators/gestione-negozio/gestione-negozio.component';
import {EstrattiContoTimComponent} from './pages/invoices/estratti-conto-tim/estratti-conto-tim.component';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {AgenteSelectComponent} from './widgets/agente-select/agente-select.component';
import {CalendarioVisiteComponent} from './agent/calendario-visite/calendario-visite.component';
import {Daterangepicker} from 'ng2-daterangepicker';
import {AssetsPipe} from './helpers/assets.pipe';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {DragDropListModule} from 'ng-drag-drop-list';
import {DisableControlDirective} from './directives/disable-input.directive';
import {CbiGeneratorComponent} from './operators/cbi-generator/cbi-generator.component';
import {AffilationsComponent} from './pages/affiliations/affilations.component';
import {InvoiceManagementComponent} from './operators/invoice-management/invoice-management.component';
import {TicketManagementComponent} from './operators/ticket-management/ticket-management.component';
import {RetailerTypeaheadComponent} from './widgets/retailer-typeahead/retailer-typeahead.component';
import {AffilationModalComponent} from './pages/affiliations/affilation-modal/affilation-modal.component';
import {RetailerManagementComponent} from './operators/retailer-management/retailer-management.component';
import {StateSelectComponent} from './widgets/state-select/state-select.component';
import {ProvinceSelectComponent} from './widgets/province-select/province-select.component';
import {CitySelectComponent} from './widgets/city-select/city-select.component';
import {VendorSelectComponent} from './widgets/vendor-select/vendor-select.component';
import {AgentSelectComponent} from './widgets/agent-select/agent-select.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {StaffSelectComponent} from './widgets/staff-select/staff-select.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {IsVendorDisabledDirective} from './directives/is-vendor-disabled.directive';
import {NgxCsvParserModule} from 'ngx-csv-parser';
import {PlanPercentageComponent} from './master/master-planning/plan-percentage/plan-percentage.component';
import {RealComponent} from '@app/master/master-planning/real/real.component';
import {AgentsPerformanceComponent} from '@app/master/master-planning/agents-performance/agents-performance.component';
import {KenaRecapAffiliationsComponent} from '@app/kena/kena-recap/kena-recap-affiliations/kena-recap-affiliations.component';
import {KenaRecapSalesComponent} from '@app/kena/kena-recap/kena-recap-sales/kena-recap-sales.component';
import {LoyaltyCardComponent} from './pages/loyalty-card/loyalty-card.component';
import {CampaignsComponent} from './pages/campaigns/campaigns.component';
import {CampaignModalComponent} from './pages/campaigns/campaign-modal/campaign-modal.component';
import {DataBuilderTableComponent} from './widgets/data-builder-table/data-builder-table.component';
import {ShopOrderDashboardComponent} from './pages/shop-order-dashboard/shop-order-dashboard.component';
import {PbxCampaignModalComponent} from './operators/retailer-management/pbx-campaign-modal/pbx-campaign-modal.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import {KenaRecapSalesMnpComponent} from './kena/kena-recap/kena-recap-sales-mnp/kena-recap-sales-mnp.component';
import {MasterWhatsappComponent} from './master/master-whatsapp/master-whatsapp.component';
import {PbxCampaignComponent} from '@app/pages/pbx/pbx-campaign/pbx-campaign.component';
import {PbxLeadsComponent} from './pages/pbx/pbx-leads/pbx-leads.component';
import {PbxCampaignDetailsComponent} from './pages/pbx/pbx-campaign-details/pbx-campaign-details.component';
import {PbxCallsComponent} from './pages/pbx/pbx-calls/pbx-calls.component';
import {PbxScheduleCallModalComponent} from './pages/pbx/pbx-schedule-call-modal/pbx-schedule-call-modal.component';
import {PbxCallModalComponent} from './pages/pbx/pbx-call-modal/pbx-call-modal.component';
import {NgxMaskModule} from 'ngx-mask';
import {TimeToTextPipe} from '@helpers/time-to-text.pipe';
import {PbxQuestionsComponent} from './pages/pbx/pbx-questions/pbx-questions.component';
import {PbxAnswersModalComponent} from '@app/pages/pbx/pbx-answers-modal/pbx-answers-modal.component';
import {TicketDashboardComponent} from './master/ticket-dashboard/ticket-dashboard.component';
import {HideDashboardDirective} from '@app/directives/hide-dashboard.directive';
import {ShowDashboardDirective} from '@app/directives/show-dashboard.directive';
import {RichTextTruncateComponent} from './widgets/rich-text-truncate/rich-text-truncate.component';
import {EPayShopComponent} from '@app/pages/e-pay/epay-shop/e-pay-shop.component';
import {ShopModalComponent} from './pages/e-pay/epay-shop/shop-modal/shop-modal.component';
import {CheckoutComponent} from './pages/e-pay/epay-shop/checkout/checkout.component';
import {ProductsComponent} from '@app/pages/e-pay/products/products.component';
import {ProductModalComponent} from '@app/pages/e-pay/products/product-modal/product-modal.component';
import {BrandsComponent} from './pages/e-pay/brands/brands.component';
import {BrandModalComponent} from './pages/e-pay/brand-modal/brand-modal.component';
import {ProductDetailComponent} from './pages/e-pay/products/product-detail/product-detail.component';
import {MasterEmailCardComponent} from './master/master-email/master-email-card/master-email-card.component';
import {OrdersComponent} from './pages/e-pay/orders/orders.component';
import {ReceiptComponent} from './pages/e-pay/receipt/receipt.component';
import {SubjectsComponent} from './pages/invoice/subjects/subjects.component';
import {DocumentsComponent} from './pages/invoice/documents/documents.component';
import {InvoiceDashboardComponent} from './pages/invoice/invoice-dashboard/invoice-dashboard.component';
import {SubjectModalComponent} from './pages/invoice/subject-modal/subject-modal.component';
import {DocumentModalComponent} from './pages/invoice/document-modal/document-modal.component';
import {HighchartsChartModule} from 'highcharts-angular';
import {InvoiceReportComponent} from './pages/invoice/invoice-report/invoice-report.component';
import {DataBuilderModalComponent} from './widgets/data-builder-modal/data-builder-modal.component';
import {BankTransactionComponent} from './pages/invoice/bank-transaction/bank-transaction.component';
import {DocumentViewComponent} from './pages/invoice/document-view/document-view.component';
import {SubjectViewComponent} from './pages/invoice/subject-view/subject-view.component';
import {BankAccountComponent} from './pages/invoice/bank-account/bank-account.component';
import {AgentGpsTrackingComponent} from './pages/agent-gps-tracking/agent-gps-tracking.component';
import {CategoryComponent} from './pages/shop/category/category.component';
import {CategoryModalComponent} from './pages/shop/category/category-modal/category-modal.component';
import {OperatorComponent} from './pages/shop/operator/operator.component';
import {OperatorModalComponent} from './pages/shop/operator/operator-modal/operator-modal.component';
import {ShopProductComponent} from './pages/shop/shop-product/shop-product.component';
import {ShopProductModalComponent} from './pages/shop/shop-product/shop-product-modal/shop-product-modal.component';
import {ShopProductDetailComponent} from './pages/shop/shop-product/shop-product-detail/shop-product-detail.component';
import {StandSelectComponent} from '@widgets/stand-select/stand-select.component';
import {AgmDirectionModule} from 'agm-direction';
import {EpaySalesComponent} from './pages/e-pay/epay-sales/epay-sales.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {GuidedTourModule, GuidedTourService} from 'ngx-guided-tour';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {MasterWappCardComponent} from '@app/master/master-email/master-wapp-card/master-wapp-card.component';
import {CalendarComponent} from './pages/invoice/calendar/calendar.component';
import {SubjectReportComponent} from './pages/invoice/subject-report/subject-report.component';
import {DealerMapComponent} from './pages/dealer-map/dealer-map.component';
import {MasterAgentSelectComponent} from './widgets/master-agent-select/master-agent-select.component';
import {EMailCampaignComponent} from './pages/e-mail-campaign/e-mail-campaign.component';
import {EMailCampaignModalComponent} from './pages/e-mail-campaign/e-mail-campaign-modal/e-mail-campaign-modal.component';
import {PaymentModalComponent} from './pages/invoice/payment-modal/payment-modal.component';
import {PaymentViewComponent} from './pages/invoice/payment-view/payment-view.component';
import {PaymentComponent} from './pages/invoice/payment/payment.component';
import {ShopProductTemplateComponent} from './pages/shop/shop-product/shop-product-template/shop-product-template.component';
import {ProductVariantDetailComponent} from './pages/shop/shop-product/product-variant-detail/product-variant-detail.component';
import {SmsSalesComponent} from './pages/sms-sales/sms-sales.component';
import {ReportTimelineComponent} from './master/master-dealer-dettaglio/report-timeline/report-timeline.component';
import {ShopProductInstallmentsComponent} from './pages/shop/shop-product-installments/shop-product-installments.component';
import {ShopSalesComponent} from './pages/shop/shop-sales/shop-sales.component';
import {ChangePasswordComponent} from './pages/main/change-password/change-password.component';
import {PopupComponent} from './pages/popup/popup.component';
import {ShopOrderHistoryComponent} from './pages/shop-order-history/shop-order-history.component';
import {ShopOrderDetailComponent} from './pages/shop-order-history/shop-order-detail/shop-order-detail.component';
import {EditPopupComponent} from './pages/shop-order-history/edit-popup/edit-popup.component';
import {PrintingIndexComponent} from '@app/pages/printing/printing-index.component';
import {CountdownModule} from 'ngx-countdown';
import {ShopCreditComponent} from './pages/shop-credit/shop-credit.component';
import {CreditModalComponent} from './pages/shop-credit/credit-modal/credit-modal.component';
import {TicketShopModalComponent} from './pages/ticket-service/ticket-shop/ticket-shop-modal/ticket-shop-modal.component';
import {TicketShopComponent} from '@app/pages/ticket-service/ticket-shop/ticket-shop.component';
import {TicketOrderListComponent} from './pages/ticket-service/ticket-order-list/ticket-order-list.component';
import { PrintingOrderListComponent } from './pages/printing/printing-order-list/printing-order-list.component';
import { EventListComponent } from './pages/eventi/event-list/event-list.component';
import { EventViewComponent } from './pages/eventi/event-list/event-view/event-view.component';
import { EventEditFormTemplateComponent } from './pages/eventi/event-list/event-edit-form-template/event-edit-form-template.component';
import {ShippingShopComponent} from './pages/shipping-service/shipping-shop/shipping-shop.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
    dayGridPlugin,
    interactionPlugin
]);

registerLocaleData(localeIT, 'it');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        ProfileComponent,
        DashboardComponent,
        MessagesDropdownMenuComponent,
        NotificationsDropdownMenuComponent,
        TicketListComponent,
        TicketDetailComponent,
        InvoiceListComponent,
        FaqListComponent,
        DocumentsListComponent,
        ContractListComponent,
        ContractNewComponent,
        ToastsContainerComponent,
        ShopIndexComponent,
        FilledPdfComponent,
        SafePipe,
        SafeHtmlPipe,
        TimeToTextPipe,
        FirstWordPipe,
        ContractDetailComponent,
        TicketModalComponent,
        DocumentsModalComponent,
        PbxListComponent,
        SlotMachineIndexComponent,
        NotFoundComponent,
        ResetPasswordComponent,
        ComparatorIndexComponent,
        DealerPlusIndexComponent,
        CustomerFormComponent,
        PlafondTiscaliIndexComponent,
        PlafondKenaIndexComponent,
        CreditoIndexComponent,
        AssetsKenaIndexComponent,
        AssetsTiscaliIndexComponent,
        ProductComponent,
        CartComponent,
        ShopMenuComponent,
        ShopSubProductsPickerComponent,
        ShopBuyComponent,
        AddressComponent,
        ShopDetailComponent,
        DocumentsCreateModalComponent,
        ShopListComponent,
        VideoIndexComponent,
        VisiteAgenteIndexComponent,
        DashboardRiepilogoComponent,
        EventiIndexComponent,
        EventiNewComponent,
        EventiDetailComponent,
        OcrModalComponent,
        CommentsModalComponent,
        InvoiceDetailComponent,
        PeriodSelectorComponent,
        DataRangeComponent,
        TableComponent,
        FormComponent,
        DialogFieldEditComponent,
        ContractNewOffertaComponent,
        ContractNewPagamentoComponent,
        RicaricheIndexComponent,
        ShopBlockedComponent,
        ShopPosComponent,
        CustomerBaseIndexComponent,
        CustomerBaseListComponent,
        CustomerBaseWidgetComponent,
        CustomerBaseSendComponent,
        AutocompleteAddressComponent,
        ClassificaStandComponent,
        ClassificaVenditoriComponent,
        CustomerBaseImportComponent,
        CustomerBaseManualComponent,
        CustomerBaseCampaignComponent,
        CustomerBaseDisabledComponent,
        ShopPrintingProductComponent,
        ContractSimulatoreEniComponent,
        AvanzamentoComponent,
        SegnalazioneCaldaiaComponent,
        MasterDashboardComponent,
        MasterCardComponent,
        AutoloadChartComponent,
        AutoloadRiepilogoComponent,
        AutoloadPuntiVenditaComponent,
        AutoloadTargetComponent,
        AutoloadFatturatoComponent,
        AutoloadOfferteComponent,
        MasterCustomerBaseComponent,
        MasterHeaderComponent,
        MasterSubheaderComponent,
        AutoloadPieChartComponent,
        AutoloadHorizontalBarChartComponent,
        AutoloadAreaChartComponent,
        MasterProduzionePersaComponent,
        AutoloadTableComponent,
        MasterAnalisiFasceComponent,
        MasterAnalisiPlafondComponent,
        MasterAnalisiSimComponent,
        MasterClassificaComponent,
        MasterConfrontoComponent,
        MasterNuoveAffiliazioniComponent,
        MasterNegozioComponent,
        MasterMappaComponent,
        MasterCentralinoComponent,
        MasterCentralinoOperatoreComponent,
        MasterOutboundComponent,
        MasterPresidiComponent,
        TimRateizzoComponent,
        MasterEmailComponent,
        MasterEmailDettaglioComponent,
        GestioneIncentivazioneComponent,
        HotComponent,
        ModalComponent,
        DealerComponent,
        GestioneFatturazioneComponent,
        MasterEconomicsComponent,
        MasterVisiteIndexComponent,
        MasterVisiteDetailComponent,
        AgentVisiteIndexComponent,
        MasterEconomicsFatturatoComponent,
        MasterEconomicsNegoziComponent,
        MasterEconomicsAgentiComponent,
        MasterEconomicsGlobalComponent,
        GestioneStandComponent,
        DealerFormComponent,
        MasterAgenteComponent,
        PlafondTimIndexComponent,
        GestioneVenditoriComponent,
        GestioneVenditoriDettagliComponent,
        GestioneStandDettagliComponent,
        MasterDashboardTabComponent,
        GestioneStorniComponent,
        GestioneReportComponent,
        ModalQuestionsComponent,
        DatepickerRangePopupComponent,
        MasterDealerDettaglioComponent,
        AutoloadChartjsComponent,
        AutoloadTableRiepilogoComponent,
        AutoloadKeypointsComponent,
        HideDashboardDirective,
        ShowDashboardDirective,
        ShowDirective,
        DontShowDirective,
        SpinnerDirective,
        DisableControlDirective,
        TimRateizzoListComponent,
        MasterPlanningComponent,
        MasterDealerWebComponent,
        MasterTicketsComponent,
        MasterKpiComponent,
        SpinnerComponent,
        MasterFacebookComponent,
        MasterFacebookDetailComponent,
        NegozioSelectComponent,
        GestioneNegozioComponent,
        EstrattiContoTimComponent,
        AgenteSelectComponent,
        CalendarioVisiteComponent,
        AssetsPipe,
        AffilationsComponent,
        IsVendorEnabledDirective,
        IsVendorDisabledDirective,
        CbiGeneratorComponent,
        InvoiceManagementComponent,
        TicketManagementComponent,
        RetailerTypeaheadComponent,
        AffilationModalComponent,
        StateSelectComponent,
        ProvinceSelectComponent,
        CitySelectComponent,
        VendorSelectComponent,
        AgentSelectComponent,
        StaffSelectComponent,
        RetailerManagementComponent,
        MasterAnalyticsComponent,
        MonthRangePickerComponent,
        CodeManagementComponent,
        KenaRecapComponent,
        PlanPercentageComponent,
        AgentsPerformanceComponent,
        WhatsappComponent,
        KenaVisiteComponent,
        RealComponent,
        CostsManagementComponent,
        KenaRecapSalesComponent,
        KenaRecapSalesMnpComponent,
        KenaRecapAffiliationsComponent,
        LoyaltyCardComponent,
        CampaignsComponent,
        CampaignModalComponent,
        DataBuilderTableComponent,
        ShopOrderDashboardComponent,
        MasterWhatsappComponent,
        PbxCampaignModalComponent,
        PbxCampaignComponent,
        PbxLeadsComponent,
        PbxCampaignDetailsComponent,
        PbxCallsComponent,
        PbxScheduleCallModalComponent,
        PbxCallModalComponent,
        PbxAnswersModalComponent,
        PbxQuestionsComponent,
        RichTextTruncateComponent,
        TicketDashboardComponent,
        EPayShopComponent,
        ShopModalComponent,
        CheckoutComponent,
        ProductsComponent,
        ProductModalComponent,
        BrandsComponent,
        BrandModalComponent,
        ProductDetailComponent,
        MasterEmailCardComponent,
        DataBuilderModalComponent,
        ProductDetailComponent,
        OrdersComponent,
        ReceiptComponent,
        SubjectsComponent,
        DocumentsComponent,
        InvoiceDashboardComponent,
        SubjectModalComponent,
        DocumentModalComponent,
        InvoiceReportComponent,
        BankTransactionComponent,
        DocumentViewComponent,
        SubjectViewComponent,
        BankAccountComponent,
        AgentGpsTrackingComponent,
        CategoryComponent,
        CategoryModalComponent,
        OperatorComponent,
        OperatorModalComponent,
        ShopProductComponent,
        ShopProductModalComponent,
        ShopProductDetailComponent,
        StandSelectComponent,
        EpaySalesComponent,
        MasterWappCardComponent,
        CalendarComponent,
        SubjectReportComponent,
        DealerMapComponent,
        MasterAgentSelectComponent,
        EMailCampaignComponent,
        EMailCampaignModalComponent,
        PaymentModalComponent,
        PaymentViewComponent,
        PaymentComponent,
        ShopProductTemplateComponent,
        ProductVariantDetailComponent,
        SmsSalesComponent,
        ReportTimelineComponent,
        ShopProductInstallmentsComponent,
        ShopSalesComponent,
        ChangePasswordComponent,
        PopupComponent,
        PopupComponent,
        ShopOrderHistoryComponent,
        ShopOrderDetailComponent,
        EditPopupComponent,
        PrintingIndexComponent,
        ShopCreditComponent,
        CreditModalComponent,
        TicketShopComponent,
        TicketShopModalComponent,
        TicketOrderListComponent,
        PrintingOrderListComponent,
        EventListComponent,
        EventViewComponent,
        EventEditFormTemplateComponent,
        ShippingShopComponent
    ],
    imports: [
        GuidedTourModule,
        BrowserModule,
        NgxWebstorageModule.forRoot(),
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true
        }),
        OrderModule,
        NgbModule,
        NgArrayPipesModule,
        NgObjectPipesModule,
        FormsModule,
        NgPipesModule,
        ValdemortModule,
        FullCalendarModule,
        NgxChartsModule,
        MomentModule,
        CreditCardDirectivesModule,
        NgxSpinnerModule,
        NgSelectModule,
        NgxFileDropModule,
        NgBusyModule,
        CommonModule,
        CKEditorModule,
        PlyrModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAU1bDjb98aRhTpRq6EVDHkuK1Y-r7jxA0'
            /* apiKey is required, unless you are a
            premium customer, in which case you can
            use clientId
            */
        }),
        AgmDirectionModule,
        HotTableModule,
        NgCircleProgressModule.forRoot(),
        ChartsModule,
        LaddaModule,
        GooglePlaceModule,
        SweetAlert2Module.forRoot(),
        Daterangepicker,
        DragDropListModule,
        // NgBusyModule.forRoot(new BusyConfig({
        //     message: 'Caricamento...',
        //     backdrop: true,
        //     minDuration: 600
        // }))
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            }
        }),
        NgxCsvParserModule,
        EditorModule,
        NgxMaskModule.forRoot(),
        HighchartsChartModule,
        LazyLoadImageModule,
        CountdownModule
    ],
    entryComponents: [
        SpinnerComponent
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'it'},
        {provide: NgbDateParserFormatter, useClass: MomentDateFormatter},
        {provide: NgbDateAdapter, useClass: DatepickerAdapter},
        {provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        GlobalErrorHandler,
        ErrorLogService,
        GuidedTourService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
