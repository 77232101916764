/* tslint:disable:variable-name */
export class TransactionKena {
  azienda: string;
  venditore: string;
  created: string;
  note: string;
  id: string;
  importo: string;

  get valoreNumerico() {
    return parseFloat(this.importo);
  }

  get isAccredito() {
    return this.valoreNumerico > 0;
  }

  get isAddebbito() {
    return this.valoreNumerico < 0;
  }

  fromArray(result: []) {
    const array: TransactionKena[] = [];
    for (const item of result) {
      const tempItem: TransactionKena = new TransactionKena();
      Object.assign(tempItem, item);
      array.push(tempItem);
    }
    return array;
  }
}
