<div class="contract-identifier d-none d-lg-block">
    <p>Stai attivando con</p>
    <p>Codice Master Group: <strong>MAST-{{me.profile.id}}</strong></p>
</div>
<div [formGroup]="form" class="form-step">
    <div class="form-group">
        <label><i class="fa fa-chevron-circle-right"></i>Metodo di pagamento</label>
        <div class="btn-group btn-group-toggle" ngbRadioGroup formControlName="id_pagamento">
            <label ngbButtonLabel *ngFor="let pagamento of this.skeletons">
                <input ngbButton type="radio" [value]="pagamento.id_pagamento">{{pagamento.pagamento}}
            </label>
        </div>
        <val-errors controlName="id_pagamento"></val-errors>
    </div>

    <div class="row" formArrayName="campi">
        <div class="col-xl-4 col-lg-6 col-md-6 col-12" *ngFor="let campo of getCampi(); let i=index;">
            <div class="form-group" [formGroupName]="i">
                <label><i class="fa fa-chevron-circle-right"></i>{{campo.controls.nome.value}}</label>
                <input type="text" class="form-control" formControlName="valore"/>
                <val-errors controlName="valore"></val-errors>
            </div>
        </div>
    </div>
</div>
