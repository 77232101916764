import {Component, Input, OnInit} from '@angular/core';
import {DashboardService} from "@services/dashboard.service";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-autoload-horizontal-bar-chart',
  templateUrl: './autoload-horizontal-bar-chart.component.html',
  styleUrls: ['./autoload-horizontal-bar-chart.component.css']
})
export class AutoloadHorizontalBarChartComponent implements OnInit {
    uniqueID;
    _url: string;
    chartData = [];
    colors: string[];
    @Input() gradient = true;
    @Input() height = 250;
    @Input() legend = false;

    error = false;
    loading = true;

    @Input() set url(value: string) {
        this._url = value;

        if (this._url) {
            this.load();
        }
    }

    constructor(
        private dashboardService: DashboardService,
        private spinner: NgxSpinnerService
    ) {
        this.uniqueID = Math.random().toString(36).substring(7);
    }

    ngOnInit() {

    }

    load() {
        this.error = false;
        this.loading = true;
        this.spinner.show('loader-horizontal-bar-chart-'+ this.uniqueID);
        this.dashboardService.get(this._url)
            .subscribe((data) => {
                this.generateColors(data.result);
                this.chartData = data.result;
                this.spinner.hide('loader-horizontal-bar-chart-'+ this.uniqueID);
                this.loading = false;
            }, (error) => {
                this.spinner.hide('loader-horizontal-bar-chart-'+ this.uniqueID);
                this.error = true;
                this.loading = false;
            });;
    }

    generateColors(array) {
        this.colors = array.map(x => x.color);
    }

}
