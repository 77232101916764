import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InvoiceDocumentService} from '@services/invoice-document.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from '@models/invoice/subject';
import {TinyMCE} from '@models/tinyMCE';

@Component({
    selector: 'app-subject-modal',
    templateUrl: './subject-modal.component.html',
    styleUrls: ['./subject-modal.component.css']
})
export class SubjectModalComponent implements OnInit {
    @Input() subjects: Subject[] = [];
    @Input() selectedSubject: Subject;
    @Output() refresh = new EventEmitter<any>();
    defaultConfig = new TinyMCE();
    busy: any;
    subjectForm = new FormGroup({
        company_name: new FormControl('', Validators.required),
        type: new FormControl(''),
        referral_person: new FormControl(''),
        email: new FormControl('', Validators.email),
        pec_email: new FormControl('', Validators.email),
        phone_number: new FormControl(''),
        sdi_code: new FormControl('', [Validators.minLength(6), Validators.maxLength(7)]),
        vat: new FormControl('', [Validators.required, Validators.pattern('.{11}')]),
        iban: new FormControl(''),
        fiscal_code: new FormControl('', Validators.pattern('.{16}')),
        address: new FormControl(''),
        city: new FormControl(''),
        state: new FormControl(''),
        zip_code: new FormControl(''),
        country: new FormControl(''),
        note: new FormControl(''),
    });
    subjectTypes = InvoiceDocumentService.subjectTypes;

    constructor(private modalService: NgbModal, private invoiceDocumentService: InvoiceDocumentService,) {
    }

    ngOnInit(): void {
        if (this.selectedSubject) {
            this.loadData();
        }
    }

    dismissModal() {
        this.modalService.dismissAll();
    }

    saveSubject() {
        const subject = new Subject();
        subject.company_name = this.subjectForm.value.company_name;
        subject.type = this.subjectForm.value.type;
        subject.referral_person = this.subjectForm.value.referral_person;
        subject.email = this.subjectForm.value.email;
        subject.pec_email = this.subjectForm.value.pec_email;
        subject.phone_number = this.subjectForm.value.phone_number;
        subject.sdi_code = this.subjectForm.value.sdi_code;
        subject.vat = this.subjectForm.value.vat;
        subject.iban = this.subjectForm.value.iban;
        subject.fiscal_code = this.subjectForm.value.fiscal_code;
        subject.address = this.subjectForm.value.address;
        subject.city = this.subjectForm.value.city;
        subject.state = this.subjectForm.value.state;
        subject.zip_code = this.subjectForm.value.zip_code;
        subject.country = this.subjectForm.value.country;
        subject.note = this.subjectForm.value.note;

        if (this.selectedSubject) {
            return this.changeSubject(subject);
        }
        return this.addSubject(subject);
    }

    addSubject(subject: Subject) {
        this.busy = this.invoiceDocumentService.postSubject(subject).subscribe((response: Subject) => {
            this.subjects.unshift(response);
            this.invoiceDocumentService.successAlert('Created Subject!');
            this.refresh.emit(true);
            this.dismissModal();
        }, err => {
            this.invoiceDocumentService.errorAlert(err);
            this.dismissModal();
        });
    }

    changeSubject(subject: Subject) {
        this.busy = this.invoiceDocumentService.putSubject(this.selectedSubject.id, subject).subscribe((response: Subject) => {
            this.invoiceDocumentService.successAlert('Changed Subject!');
            this.refresh.emit(true);
            this.dismissModal();
        }, err => {
            this.invoiceDocumentService.errorAlert(err);
            this.refresh.emit(true);
            this.dismissModal();
        });
    }

    loadData() {
        this.subjectForm.setValue({
            company_name: this.selectedSubject.company_name,
            type: this.selectedSubject.type,
            referral_person: this.selectedSubject.referral_person,
            email: this.selectedSubject.email,
            pec_email: this.selectedSubject.pec_email,
            phone_number: this.selectedSubject.phone_number,
            sdi_code: this.selectedSubject.sdi_code,
            vat: this.selectedSubject.vat,
            iban: this.selectedSubject.iban,
            fiscal_code: this.selectedSubject.fiscal_code,
            address: this.selectedSubject.address,
            city: this.selectedSubject.city,
            state: this.selectedSubject.state,
            zip_code: this.selectedSubject.zip_code,
            country: this.selectedSubject.country,
            note: this.selectedSubject.note
        });
    }
}
