<div class="modal-header">
    <h4 class="modal-title clamp-title" translate>Product variants</h4>
    <button (click)="modalService.dismissAll()"
            type="button"
            class="close"
            aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="media d-flex align-items-center">
        <div class="logo-img">
            <img class="mr-3"
                 [src]="product.image_path"
                 alt="">
        </div>
        <div class="media-body ml-2">
            <h4 class="mt-0 mb-0"><strong>{{product.name}}</strong></h4>
            <p *ngIf="product?.price"><strong>{{product.price|currency:currency}}</strong></p>
        </div>
    </div>
    <div class="mt-2">
        <div class="variation-container">
            <ul class="list-group list-group-flush">
                <li class="list-group-item px-0" *ngFor="let variant of product.variants;index as i">
                    {{variant.name}}
                    <span class="float-right">
                          <strong class="mr-4">{{variant.price|currency:currency}}</strong>
                        </span>
                    <p>{{variant?.vat_type}}</p>
                    <p class="text-muted" [innerHTML]="variant.description"></p>
                </li>
                <li class="text-muted list-group-item px-0" *ngIf="product.variants.length<=0" translate>No variants
                    added
                </li>
            </ul>
        </div>
    </div>
</div>
