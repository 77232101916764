<ng-template #content let-modal class="bg-gradient-light">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Scegli i prodotti</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ul class="list-group list-unstyled sub-product-list">
      <li *ngFor="let sub_product of subProducts">
        <div class="row align-items-center" >
          <div class="col-8">
            <p class="lead">{{ sub_product.product.productname }}</p>
          </div>
          <div class="col-4">
            <select class="form-control" [(ngModel)]="sub_product.quantity">
              <option selected>0</option>
              <option *ngFor="let option of productSizes(sub_product)">{{option}}</option>
            </select>
          </div>
        </div>
      </li>
    </ul>
    <p class="text-muted">Devi assegnare ancora: <b>{{ availableQuantity}} {{ this.product.usageunit }}</b></p>
  </div>
  <div class="modal-footer text-right">
    <button class="btn btn-success" [disabled]="availableQuantity > 0" (click)="addToCart()">Aggiungi al carrello</button>
  </div>
</ng-template>
