import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {animate, query, stagger, style, transition, trigger} from '@angular/animations';
import {NgxSpinnerService} from 'ngx-spinner';
import {HttpClient} from '@angular/common/http';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';

@Component({
  selector: 'app-ocr-modal',
  templateUrl: './ocr-modal.component.html',
  styleUrls: ['./ocr-modal.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('1s', style({opacity: 0}))
          ])
        ], {optional: true}),
        query(':enter', [
          style({opacity: 0}),
          stagger(100, [
            animate('1s', style({opacity: 1}))
          ])
        ], {optional: true})
      ])
    ])
  ]
})
export class OcrModalComponent implements OnInit {
  @ViewChild('content') private content;

  scansioneFronte = null;
  scansioneRetro = null;

  ocr = [
    {label: 'Nome', key: 'nome', value: 'Luciano'},
    {label: 'Cognome', key: 'cognome', value: 'Veneziani'},
    {label: 'Codice Fiscale', key: 'codicefiscale', value: 'VNZLCN95T23D643X'},
    {label: 'Sesso', key: 'sesso', value: 'Maschile'},
    {label: '[Documento] Tipo', key: 'documento_scadenza', value: 'Carta d\'identità'},
    {label: '[Documento] Numero', key: 'documento_numero', value: 'CA18625DH'},
    {label: '[Documento] Data di scadenza', key: 'sesso', value: '23-12-2029'},
    // { label: '[Nascita] Paese', key: 'nascita_paese', value: 'Italia'},
    {label: '[Nascita] Provincia', key: 'nascita_provincia', value: 'FG'},
    {label: '[Nascita] Comune', key: 'nascita_comune', value: 'Foggia'},
    {label: '[Nascita] Data', key: 'nascita_data', value: '23-12-1995'},
    {label: '[Residenza] Provincia', key: 'residenza_provincia', value: 'FG'},
    {label: '[Residenza] Comune', key: 'residenza_comune', value: 'Orta nova'},
    {label: '[Residenza] CAP', key: 'residenza_cap', value: '71045'},
    {label: '[Residenza] Indirizzo', key: 'residenza_indirizzo', value: 'Via Vittorio Alfieri'},
    {label: '[Residenza] Civico', key: 'residenza_civico', value: '61'},
  ]

  items = [];
  loading = true;
  ocrState: any;

  @Output()
  done = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    private httpClient: HttpClient,
    public ngxSpinner: NgxSpinnerService
  ) {
  }

  ngOnInit() {
    // this.ngxSpinner.show();
  }

  open() {
    this.modalService.open(this.content, {
      size: 'lg'
    });

    return this.ocr;
  }

  showItems() {
    this.ocr.map((item) => {
      this.items.push(`${item.label}: ${item.value}`);
    });
  }

  hideItems() {
    this.items = [];
  }

  toggle() {
    this.items.length ? this.hideItems() : this.showItems();
  }

  setFiles(type: string, file: any) {
    if (type === 'scansione_fronte') {
      this.scansioneFronte = file;
    } else {
      this.scansioneRetro = file;
    }

    if (this.scansioneFronte && this.scansioneRetro) {
      this.startOcr();
    }
  }

  startOcr() {
    this.modalService.open(this.content, {
      size: 'lg'
    });

    this.ngxSpinner.show();
    this.ocrState = 'Carico i documenti...';

    const formData = new FormData();
    formData.append('id_dealer', '256');
    formData.append('scansione_fronte', this.scansioneFronte, this.scansioneFronte.name);
    formData.append('scansione_retro', this.scansioneRetro, this.scansioneRetro.name);
    //
    this.httpClient.post('http://masterattivazioni.it:8082/ocr', formData)
      .subscribe((data: any) => {
        if (data.success) {
          this.ocr = data.detected;
          this.ngxSpinner.hide();
          this.toggle();
        }
      });
  }

  dropped($event: NgxFileDropEntry[]) {
    console.log('test');
    this.ngxSpinner.show();
    this.ocrState = 'Carico i documenti...';
    console.log($event);
    let scansione;
    for (const droppedFile of $event) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          scansione = file;
        });
      }
    }

    const formData = new FormData();
    formData.append('id_dealer', '256');
    formData.append('scansione_fronte', scansione, scansione.name);
    formData.append('scansione_retro', scansione, scansione.name);
    //
    this.httpClient.post('http://masterattivazioni.it:8082/ocr', formData)
      .subscribe((data: any) => {
        if (data.success) {
          this.ocr = data.detected;
          this.ngxSpinner.hide();
          this.toggle();
        }
      });

    // setTimeout(() => {
    //   this.ngxSpinner.hide();
    //   this.toggle()
    // }, 5000);
    // this.toggle();
  }

  fileOver($event: any) {

  }

  fileLeave($event: any) {

  }

  close() {
    const result = {};
    this.ocr.map((item) => {
      result[item.key] = item.value;
    });
    console.log(result);
    this.done.emit(result);
    this.modalService.dismissAll();
  }
}
