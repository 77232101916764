<ng-template #rt let-r="result" let-t="term">
  <ngb-highlight [result]="r.ragsociale" [term]="t"></ngb-highlight>
</ng-template>

<input
        class="form-control"
        [ngClass]="{ 'form-control-sm': small }"
        type="search"
        placeholder="{{'Cerca un negozio'|translate}}"
        aria-label="Search"
        [(ngModel)]="dealer"
        [ngbTypeahead]="search"
        [inputFormatter]="formatter"
        [resultTemplate]="rt"
        [ngModelOptions]="{ standalone: true }" (ngModelChange)="dismissPopup($event)"
/>
