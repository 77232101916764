<ng-select
  [ngClass]="{'form-control-sm': small}"
  [items]="data"
  bindValue="id"
  bindLabel="comune"
  [(ngModel)]="city"
  (ngModelChange)="onChange.emit($event)"
  [loading]="loading"
  loadingText="Caricamento..."
  [disabled]="this.onDemand && !this.province"
></ng-select>
