import {Injectable} from '@angular/core';
import {RequestService} from '@services/request.service';
import * as XLSX from 'xlsx';

const recapSalesDealers = 'recap-sales-dealers';
const recapSalesDealersMnp = 'recap-sales-dealers-mnp';
const recapOpeningsDealers = 'recap-openings-dealers';

@Injectable({
    providedIn: 'root'
})


export class KenaService {
    ENDPOINT = 'kena';
    period;

    constructor(public request: RequestService) {

    }

    recapSales(period) {
        this.period = period;
        return this.request.get(`${this.ENDPOINT}/recap-sales`, {
            period: period
        });
    }

    recapSalesMnp(period) {
        this.period = period;
        return this.request.get(`${this.ENDPOINT}/recap-sales-mnp`, {
            period: period
        });
    }

    recapOpenings(period) {
        this.period = period;
        return this.request.get(`${this.ENDPOINT}/recap-openings`, {
            period: period
        });
    }

    recapSalesDealers(agentId, columnName) {
        return this.recapDealers(recapSalesDealers, agentId, columnName);
    }

    recapSalesDealersMnp(agentId, columnName) {
        return this.recapDealers(recapSalesDealersMnp, agentId, columnName);
    }

    recapOpeningsDealers(agentId, columnName) {
        return this.recapDealers(recapOpeningsDealers, agentId, columnName);
    }

    recapDealers(endpoint, agentId, columnName) {
        const params = {
            period: this.period,
            column: columnName
        };
        if (agentId) {
            params['agent_id'] = agentId;
        }
        return this.request.get(`${this.ENDPOINT}/` + endpoint, params);
    }

    visits(period, agent) {
        const params = {
            period: period
        };
        if (agent) {
            params['agent'] = agent;
        }

        return this.request.get(`${this.ENDPOINT}/visits`, params);
    }

    recapOfMonth(period, agent = null) {
        let params = {period: period};

        if (agent) {
            params['agent'] = agent;
        }

        return this.request.get(`${this.ENDPOINT}/recap-of-month`, params);
    }

    public exportDocument(headers, item, title) {
        const rows = [...item.rows];
        rows.push(item.total_row);

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(headers);
        XLSX.utils.sheet_add_json(ws, rows, {skipHeader: true, origin: 'A2'});
        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, 'Recap ' + title);
        XLSX.writeFile(wb, 'recap_' + title + '.xlsx');
    }

    public exportRows(rows, title) {

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(rows);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, 'Recap ' + title);
        XLSX.writeFile(wb, 'recap_' + title + '.xlsx');
    }
}
