import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {InvoiceDocumentService} from '@services/invoice-document.service';
import {DocumentPayment} from '@models/invoice/document';
import * as moment from 'moment';
import {TinyMCE} from '@models/tinyMCE';

@Component({
    selector: 'app-payment-modal',
    templateUrl: './payment-modal.component.html',
    styleUrls: ['./payment-modal.component.css']
})
export class PaymentModalComponent implements OnInit {
    @Input() selectedPayment: DocumentPayment;
    @Input() totalToPay = 0;
    busy: any;
    pickerOptions = {
        singleDatePicker: true,
        ranges: []
    };
    paymentForm = new FormGroup({
        due_date: new FormControl('', Validators.required),
        amount: new FormControl('', [Validators.required,
            Validators.pattern('([0-9]*[.])?[0-9]+')
        ]),
        due_method: new FormControl('', Validators.required),
        welded: new FormControl(''),
        note: new FormControl(''),
    });
    dueDate = '';
    methods = InvoiceDocumentService.dueMethods;
    @Output() paymentChange = new EventEmitter();
    defaultConfig = new TinyMCE();
    currency = '€';

    constructor(private modalService: NgbModal,
                private dateRangePickerOptions: DaterangepickerConfig) {
    }

    ngOnInit(): void {
        this.paymentForm.controls['amount'].setValidators(
            [Validators.required, Validators.max(this.totalToPay), Validators.min(0.001),
                Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]);
        this.paymentForm.get('amount').updateValueAndValidity();
        if (this.selectedPayment) {
            this.paymentForm.setValue({
                due_date: this.selectedPayment.due_date ? moment(this.selectedPayment.due_date)
                    .format(this.dateRangePickerOptions.settings.locale.format) : '',
                amount: this.selectedPayment.amount,
                due_method: this.selectedPayment.due_method,
                welded: this.selectedPayment.welded === 1 || this.selectedPayment.welded === '1',
                note: this.selectedPayment?.note,
            });
            this.dueDate = this.selectedPayment.due_date;
        }
    }

    dismissModal() {
        this.modalService.dismissAll();
    }

    selectedDate(date: Moment) {
        const selectedDate = date.format(this.dateRangePickerOptions.settings.locale.format);
        this.dueDate = date.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.paymentForm.get('due_date').setValue(selectedDate);
    }

    savePayment() {
        const payment = new DocumentPayment();
        payment.due_date = this.dueDate;
        payment.amount = this.paymentForm.value.amount;
        payment.due_method = this.paymentForm.value.due_method;
        payment.welded = this.paymentForm.value.welded ? '1' : '0';
        payment.note = this.paymentForm.value.note;
        this.paymentChange.emit(payment);
    }
}
