import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Dealer } from '@app/models/dealer';
import { AgenteService } from '@app/services/agente.service';

@Component({
  selector: 'app-agent-select',
  templateUrl: './agent-select.component.html',
  styleUrls: ['./agent-select.component.scss']
})
export class AgentSelectComponent implements OnInit {
  @Input()
  small = false;
  @Input()
  disabled = false;

  @Input()
  agent: any;

  @Input()
  vendors: [];

  data: Dealer[] = [];

  @Output() onChange = new EventEmitter();

  loading = false;

  constructor(
    private service: AgenteService
  ) {
  }

  ngOnInit(): void {
    if(this.agent) {
      this.agent = parseInt(this.agent);
    }
    let params = {fields: 'id,ragsociale', 'per-page': 0};

    if(this.vendors && this.vendors.length > 0) {
      params['vendors'] = this.vendors.join(',');
    }

    this.loading = true;
    this.service.list(params).subscribe((data) => {
      this.data = new Dealer().fromArray(data.rows);
      this.loading = false;
    });
  }

}
