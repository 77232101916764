import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DataService} from '@services/data.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {TicketCategoria} from '@models/ticket';

interface TicketTemplate {
  titolo: string;
  categoria: string;
  priorita: string;
  problemLabel?: string;
}

@Component({
  selector: 'app-ticket-modal',
  templateUrl: './ticket-modal.component.html',
  styleUrls: ['./ticket-modal.component.scss']
})
export class TicketModalComponent implements OnInit {
  @ViewChild('content') private content;
  private ticketForm: FormGroup;
  private categorie: TicketCategoria[];
  private busy: Subscription;

  private initialConfigProvided: boolean;
  private problemLabel = 'Problema';

  constructor(
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private dataService: DataService,
    private router: Router,
  ) {
  }

  open(config?: TicketTemplate) {
    this.modalService.open(this.content, {
      size: 'lg'
    });

    this.busy = this.dataService
      .get(`/get-ticket-categorie`)
      .subscribe((data: any) => {
        this.categorie = data.result;
      });

    // If a config is provided set values
    if (config) {}
  }

  ngOnInit(): void {
    this.ticketForm = this.formBuilder.group({
      testo: [null, [Validators.required]],
      id_categoria: [null, [Validators.required]],
    });
  }

  f() {
    return this.ticketForm.controls;
  }

  createTicket() {
    if (this.ticketForm.valid) {
      this.busy = this.dataService.post(`/inserisci-ticket`,
        this.ticketForm.value)
        .subscribe((ticket: any) => {
          this.modalService.dismissAll();
          this.router.navigate(['/supporto/ticket', ticket.result.id]);
        });
    }
  }
}
