import {Component, Input, OnInit} from '@angular/core';
import {DashboardService} from "@services/dashboard.service";
import {NgxSpinnerService} from "ngx-spinner";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-autoload-offerte',
  templateUrl: './autoload-offerte.component.html',
  styleUrls: ['./autoload-offerte.component.css']
})
export class AutoloadOfferteComponent implements OnInit {
    _urlOfferte: string;
    autoloadTableUrl: string;
    data: any;

    @Input() set urlOfferte(value: string) {
        this._urlOfferte = value;

        if (this._urlOfferte !== undefined) {
            this.loadOfferte();
        }
    }

    constructor(
        private dashboardService: DashboardService,
        private spinner: NgxSpinnerService,
        private modalService: NgbModal
    ) {
    }

    ngOnInit() {

    }

    private loadOfferte() {
        this.spinner.show('loader-offerte');
        this.dashboardService.get(this._urlOfferte)
            .subscribe((data) => {
                this.data = data.result;
                this.spinner.hide('loader-offerte');
            });
    }

    openAutoloadTable(modal, col) {
        if (col.hasOwnProperty('url')) {
            this.autoloadTableUrl = `${col.url}`;

            this.modalService.open(modal, {size: 'xl'});
        }
    }
}
