import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';
import {ShopProduct} from '@models/e-pay/shop-product';
import {ShopBrand} from '@models/e-pay/shop-brand';

export class ShopProductCategory extends BaseModel {
    id: number;
    name: string;
    description: string;
    background: string;
    image: string;
    order: number;
    products?: ShopProduct[];

    protected _brands: ShopBrand[];
    protected _brandRows;

    set brands(value) {
        this._brands = value;
        this.calcBrandRows();
    }

    get brands() {
        return this._brands;
    }

    get brandRows() {
        if (!this._brandRows) {
            this.calcBrandRows();
        }

        return this._brandRows;
    }

    private calcBrandRows() {
        let myArray = [];
        for (let i = 0; i < this.brands.length; i += 9) {
            myArray.push(this.brands.slice(i, i + 9));
        }
        this._brandRows = myArray;
    }

    fromArray(result: []) {
        const array: ShopProductCategory[] = [];
        for (const item of result) {
            array.push(new ShopProductCategory(item));
        }
        return array;
    }
}

export class ShopProductCategoryPagination extends BaseModel {
    data: ShopProductCategory[];
    _meta: MetaPagination;
    _links: Array<any>;


    constructor(data) {
        super();
        this.data = new ShopProductCategory().fromArray(data.data);
    }
}
