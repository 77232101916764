import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PbxCampaignService} from '@services/pbx-campaign.service';
import {PbxLead} from '@models/pbx/pbx-lead';
import {MetaPagination} from '@models/meta-pagination';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {User} from '@models/user';
import {AuthenticationService} from '@services/authentication.service';

@Component({
    selector: 'app-pbx-leads',
    templateUrl: './pbx-leads.component.html',
    styleUrls: ['./pbx-leads.component.css']
})
export class PbxLeadsComponent implements OnInit {

    @Input() leads: PbxLead[] = [];
    @Input() leadsPagination: MetaPagination;
    @Input() isActiveCampaign = false;
    @Output() loadLeads = new EventEmitter<any>();
    operator: User;
    @ViewChild('scheduleFormTemplate') private scheduleFormTemplate;
    @ViewChild('callFormTemplate') private callFormTemplate;
    scheduleModal: NgbModalRef;
    callModal: NgbModalRef;
    selectedLead: PbxLead;
    selectedPhoneNumber: string;
    isScheduleCall = false;
    leadFilter = '';
    filter = {
        province_id: '',
        region_id: '',
        city_id: '',
        company_name: ''
    };

    constructor(private pbxCampaignService: PbxCampaignService,
                public authenticationService: AuthenticationService,
                private modalService: NgbModal) {
        this.authenticationService.currentUser.subscribe((user: User) => {
                this.operator = user;
            }
        );
    }

    localFormat = PbxCampaignService.datetimeLocalFormat;

    ngOnInit(): void {
        this.pbxCampaignService.changedCampaign.subscribe(data => {
            if (this.leadsPagination && data) {
                this.leadFilter = '';
                this.loadLeadsData();
            }
        });
    }

    loadLeadsData() {
        this.loadLeads.emit(this.filter);
    }

    showScheduleCallModal(lead: PbxLead) {
        this.selectedLead = lead;
        this.scheduleModal = this.modalService.open(this.scheduleFormTemplate, {size: 'md'});
        this.scheduleModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    showCallModal(lead: PbxLead, phone: string, isScheduleCall = false) {
        this.isScheduleCall = isScheduleCall;
        this.selectedLead = lead;
        this.selectedPhoneNumber = phone;
        this.callModal = this.modalService.open(this.callFormTemplate, {size: 'xl'});
        this.callModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    feedbackStatus(key: string) {
        return key ? PbxCampaignService.feedbackStatuses.find(status => status.key === key) : '';
    }

    callStatus(key: string) {
        return key ? PbxCampaignService.callStatuses.find(status => status.key === key) : '';
    }
}
