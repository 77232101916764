import {BaseModel} from '@models/base-model';
import {Validators} from '@angular/forms';

export class AgentCustomVisit extends BaseModel {
    negozio: string;
    date: string;
    note_visita: string;
    affiliation: boolean;
    attivita_chiusa: null;
    provincia: string;
    citta: string;
    documents: AgentVisitDocument[];
    current_lat: any;
    current_lng: any;
    actual_plafond: number;
    warehouse_sim: number;

    get postPayload() {
        const payload = new FormData();
        this.addToPayload(payload, this.negozio, 'negozio');
        this.addToPayload(payload, this.date, 'date');
        this.addToPayload(payload, this.note_visita, 'note_visita');
        this.addToPayload(payload, this.affiliation, 'affiliation');
        this.addToPayload(payload, this.attivita_chiusa, 'attivita_chiusa');
        this.addToPayload(payload, this.provincia, 'provincia');
        this.addToPayload(payload, this.citta, 'citta');
        this.addToPayload(payload, this.documents, 'files');
        this.addToPayload(payload, this.current_lat, 'current_lat');
        this.addToPayload(payload, this.current_lng, 'current_lng');
        this.addToPayload(payload, this.actual_plafond, 'actual_plafond');
        this.addToPayload(payload, this.warehouse_sim, 'warehouse_sim');
        return payload;
    }
}

export class AgentVisitDocument extends BaseModel {
    file: File;
    file_name: string;
}

export class AgentFormVisit extends BaseModel {
    id_dealer: string;
    date: string;
    note_visita: string;
    affiliation: boolean;
    attivita_chiusa: null;
    documents: AgentVisitDocument[];
    current_lat: any;
    current_lng: any;
    actual_plafond: number;
    warehouse_sim: number;

    get postPayload() {
        const payload = new FormData();
        this.addToPayload(payload, this.id_dealer, 'id_dealer');
        this.addToPayload(payload, this.date, 'date');
        this.addToPayload(payload, this.note_visita, 'note_visita');
        this.addToPayload(payload, this.affiliation, 'affiliation');
        this.addToPayload(payload, this.attivita_chiusa, 'attivita_chiusa');
        this.addToPayload(payload, this.documents, 'files');
        this.addToPayload(payload, this.current_lat, 'current_lat');
        this.addToPayload(payload, this.current_lng, 'current_lng');
        this.addToPayload(payload, this.actual_plafond, 'actual_plafond');
        this.addToPayload(payload, this.warehouse_sim, 'warehouse_sim');
        return payload;
    }
}

export class AgentConfirmVisit extends BaseModel {
    id_visita_programmata: any;
    date: string;
    note_visita: string;
    affiliation: boolean;
    attivita_chiusa: null;
    documents: AgentVisitDocument[];
    current_lat: any;
    current_lng: any;
    actual_plafond: number;
    warehouse_sim: number;

    get postPayload() {
        const payload = new FormData();
        this.addToPayload(payload, this.id_visita_programmata, 'id_visita_programmata');
        this.addToPayload(payload, this.date, 'date');
        this.addToPayload(payload, this.note_visita, 'note_visita');
        this.addToPayload(payload, this.affiliation, 'affiliation');
        this.addToPayload(payload, this.attivita_chiusa, 'attivita_chiusa');
        this.addToPayload(payload, this.documents, 'files');
        this.addToPayload(payload, this.current_lat, 'current_lat');
        this.addToPayload(payload, this.current_lng, 'current_lng');
        this.addToPayload(payload, this.actual_plafond, 'actual_plafond');
        this.addToPayload(payload, this.warehouse_sim, 'warehouse_sim');
        return payload;
    }
}
