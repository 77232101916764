import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {MetaPagination} from '@models/meta-pagination';
import {Subject, SubjectPagination} from '@app/models/invoice/subject';
import {InvoiceDocumentService} from '@services/invoice-document.service';
import {ConfirmOptionsService} from '@services/confirm-options.service';


@Component({
  selector: 'app-subjects',
  templateUrl: './subjects.component.html',
  styleUrls: ['./subjects.component.css']
})
export class SubjectsComponent implements OnInit {
  @ViewChild('subjectFormTemplate') private subjectFormTemplate;
  @ViewChild('subjectDetailTemplate') private subjectDetailTemplate;
  filter = {
    company_name: null,
    type: null,
    state: null,
    city: null,
    phone_number: null,
    sdi_code: null,
    vat: null,
    fiscal_code: null
  };
  subjects: Subject[] = [];
  subjectsPagination: MetaPagination;
  selectedSubject: Subject;
  deleteSubjectOptions: ConfirmOptionsService;
  subjectTypes = InvoiceDocumentService.subjectTypes;
  constructor(
      private modalService: NgbModal,
      private spinner: NgxSpinnerService,
      private invoiceDocumentService: InvoiceDocumentService) {
    this.deleteSubjectOptions = new ConfirmOptionsService({
      title: 'Vuoi cancellarla?',
      text: 'Non potrai tornare indietro!',
      confirmButtonText: 'Si',
      cancelButtonText: 'Annulla'
    });
  }

  ngOnInit(): void {
    this.loadSubject();
  }

  showSubjectModal(subject?: Subject) {
    this.selectedSubject = subject;
    this.modalService.open(this.subjectFormTemplate, {size: 'xl'});
  }
  showSubjectDetailModal(subject?: Subject) {
    this.selectedSubject = subject;
    this.modalService.open(this.subjectDetailTemplate, {size: 'xl'});
  }
  resetFilter() {
    this.filter = {
      company_name: null,
      type: null,
      state: null,
      city: null,
      phone_number: null,
      sdi_code: null,
      vat: null,
      fiscal_code: null
    };
  }

  loadSubject() {
    this.spinner.show('table-subjects');
    const params = {};
    if (this.subjectsPagination?.currentPage) {
      params['page'] = this.subjectsPagination?.currentPage;
    }
    if (this.filter.company_name) {
      params['company_name'] = this.filter.company_name;
    }
    if (this.filter.type) {
      params['type'] = this.filter.type;
    }
    if (this.filter.state) {
      params['state'] = this.filter.state;
    }
    if (this.filter.city) {
      params['city'] = this.filter.city;
    }
    if (this.filter.phone_number) {
      params['phone_number'] = this.filter.phone_number;
    }
    if (this.filter.sdi_code) {
      params['sdi_code'] = this.filter.sdi_code;
    }
    if (this.filter.vat) {
      params['vat'] = this.filter.vat;
    }
    if (this.filter.fiscal_code) {
      params['fiscal_code'] = this.filter.fiscal_code;
    }
    this.invoiceDocumentService.getSubject(params).subscribe((response: SubjectPagination) => {
      this.subjects = response.data;
      this.subjectsPagination = response._meta;
      this.spinner.hide('table-subjects');
    }, err => {
      this.spinner.hide('table-subjects');
    });
  }

  deleteSubject(id: number) {
    this.invoiceDocumentService.deleteSubject(id).subscribe(() => {
      const index = this.subjects.findIndex(brand => brand.id === id);
      if (index > -1) {
        this.subjects.splice(index, 1);
        this.invoiceDocumentService.successAlert('Deleted Subject!');
      }
    }, (err) => {
      this.invoiceDocumentService.errorAlert(err.message);
    });
  }

  findType(type) {
    const subjectType = this.subjectTypes.find(item => item.key === type);
    return subjectType ? subjectType.name : type;
  }
}
