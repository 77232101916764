import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';


export class PbxQuestion extends BaseModel {
    id: number;
    title: string;
    category: string;
    text: string;
    question_type: string;
}

export class PbxQuestionListPagination extends BaseModel {
    data: PbxQuestion[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class PbxCampaignQuestionListPagination extends BaseModel {
    data: PbxCampaignQuestion[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class PbxCampaignQuestion extends BaseModel {
    id: number;
    campaign_id: string;
    question_id: string;
    info: PbxQuestion;
    answer?: PbxQuestionAnswer;
}

export class PbxQuestionAnswer extends BaseModel {
    id: number;
    campaign_question_id: number;
    text: string;
    status: string;
}

export class PbxQuestionType {
    name: string;
    key: string;
    answers?: Array<any>;
}

export class PbxQuestionCategory {
    name: string;
    key: string;
}
