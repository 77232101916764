<div class="row" [ngBusy]="busy">
    <div class="col-12">
        <div class="card card-detail bg-gradient-light elevation-1">
            <div class="card-header">
                <h1 class="display-7 mb-0">
                    Ordine {{ order.salesorder_no }}
                    <span class="float-right text-muted"> {{ order.createdtime | amDateFormat: 'hh:mm DD/MM/YYYY' }}</span>
                </h1>

            </div>

            <app-documents-modal #showDocument></app-documents-modal>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <p class="lead text-normal">Indirizzo di spedizione</p>
                        <p class="lead">{{ order.ship_city }}</p>
                        <p class="lead">{{ order.ship_state }}, {{ order.ship_code }}</p>
                        <p class="lead">{{ order.ship_street }}, {{ order.ship_pobox }}</p>
                    </div>
                    <div class="col-12 col-md-4">
                        <p class="lead text-normal">Contatti</p>
                        <p class="lead">{{ me.profile.nome }} {{ me.profile.cognome }}</p>
                        <p class="lead">{{ me.profile.telefono }}</p>
                        <p class="lead">{{ me.profile.email }}</p>
                    </div>
                </div>
                <div class="row pt-5">
                    <div class="col-12 p-0">
                        <table class="table table-hover lead mb-0">
                            <tr>
                                <th></th>
                                <th>Prodotto</th>
                                <th></th>
                                <th class="text-right">Discount</th>
                                <th class="text-right">Quantità</th>
                                <th class="text-right">Prezzo Unit.</th>
                                <th class="text-right">Prezzo Totale</th>
                            </tr>
                            <tr *ngFor="let item of order.LineItems">
                                <td class="text-center">
                                    <div class="template-img">
                                        <img src="/assets/img/svgs/undraw_collecting_fjjl.svg" *ngIf="!item?.template"
                                             style="height: 40px; max-width: 100%;"/>
                                        <img [src]="item?.template.path" alt="" *ngIf="item?.template">
                                    </div>
                                </td>
                                <td>
                                    {{ item.product_name }}
                                    <p *ngIf="item.id_prodotto_padre" class="font-italic font-weight-bold text-md">
                                        - {{item.id_prodotto_padre}}</p>
                                </td>
                                <td>
                                    <span class="text-muted font-italic"
                                          *ngIf="item.comment">parte di: </span>{{ item.comment }}
                                </td>
                                <td class="text-right">{{ item?.template?.discount ? item?.template?.discount : 0 }}%
                                </td>
                                <td class="text-right">{{ item.qty }}</td>
                                <td class="text-right">{{ item.price | currency: '€ ' }}</td>
                                <td class="text-right">{{ item.totalprice | currency: '€ ' }}</td>
                            </tr>
                            <tr>
                                <td class="text-right font-weight-normal" colspan="6">Totale Imponibile</td>
                                <td class="text-right font-weight-normal">{{ order.totale_ordine - order.totale_tasse - order.spese_di_spedizione | currency: '€ ' }}</td>
                            </tr>
                            <tr>
                                <td class="text-right font-weight-normal" colspan="6">Spese di spedizione</td>
                                <td class="text-right font-weight-normal">{{ order.spese_di_spedizione | currency: '€ ' }}</td>
                            </tr>
                            <tr>
                                <td class="text-right font-weight-normal" colspan="6">Tasse</td>
                                <td class="text-right font-weight-normal">{{ order.totale_tasse | currency: '€ ' }}</td>
                            </tr>
                            <tr *ngIf="order.compensazione_parziale">
                                <td class="text-right font-weight-normal" colspan="6">Compensazione Parziale</td>
                                <td class="text-right font-weight-normal">
                                    -{{ order.valore_parziale | currency: '€ ' }}</td>
                            </tr>
                            <tr>
                                <td class="text-right font-weight-normal" colspan="6">Totale a pagare</td>
                                <td class="text-right font-weight-normal">{{ order.totale_ordine - order.valore_parziale | currency: '€ ' }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-detail bg-gradient-light elevation-1">
            <div class="card-header">
                <h1 class="display-7 mb-0">
                    {{ order.so_pagamento }}
                    <span class="float-right"><span
                            class="text-muted">totale ordine:</span> {{ order.grandtotal | currency: '€ ' }}</span>
                </h1>

            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-6 col-md-3 col-lg-2">
                        <p class="lead text-normal">Stato</p>
                        <p class="lead">{{ order.sostatus }}</p>
                    </div>
                    <div class="col-6 col-md-3 col-lg-2">
                        <p class="lead text-normal">Pagamento</p>
                        <p class="lead">{{ order.so_pagamento }}</p>
                    </div>
                    <div class="col-6 col-md-3 col-lg-2" *ngIf="order.so_pagamento === 'Carta di credito'">
                        <p class="lead text-normal">Pagamento Online</p>
                        <ng-container *show="['dealer']">
                            <p (click)="pagaOnline()" class="lead btn-link"
                               *ngIf="order.pagato === 0 && order.sostatus !== 'Annullato'">Paga Online</p>
                        </ng-container>
                        <p class="lead" *ngIf="order.pagato === 1">Pagato</p>
                    </div>
                    <div class="col-6 col-md-3 col-lg-2"
                         *ngIf="order.so_pagamento === 'Bonifico Bancario' && order.sostatus !== 'Annullato'">
                        <p class="lead text-normal">Contabile</p>
                        <app-documents-create-modal #contabileModal (done)="this.refreshOrder()">
                            <h1 class="display-6 text-info"><i class="fa fa-info-circle mr-2"></i>Istruzioni per il
                                bonifico</h1>
                            <p class="lead">IBAN: <b>{{order?.config_name}}</b></p>
                            <p class="lead">Intestatario: <b>{{order?.config_iban}}</b></p>
                            <p class="lead">Importo:
                                <b>{{order.grandtotal - order.valore_parziale | currency: '€ '}}</b></p>
                            <p class="lead">Causale: <b>#{{order.salesorder_no}}</b></p>
                            <p class="text-muted">
                                La contabile per essere valida deve contenere i seguenti dati:<br>
                                - Data operazione<br>
                                - Nome dell’ordinante<br>
                                - Nome del beneficiario<br>
                                - IBAN del beneficiario<br>
                                - Importo bonifico<br>
                                - CRO/TRN bonifico
                            </p>
                            <hr>
                            <h1 class="display-6 text-info"><i class="fa fa-file mr-2"></i> Contabile</h1>
                        </app-documents-create-modal>
                        <ng-container *show="['dealer', 'agent']">
                            <button class="btn btn-sm bg-gradient-info" *ngIf="!order.contabile"
                                    (click)="contabileModal.showModal(order.salesorder_no, 'contabile')">Istruzioni
                            </button>
                        </ng-container>
                        <p class="lead btn-link" *ngIf="order.contabile"
                           (click)="showDocument.showDocument(order.contabile)">Mostra contabile</p>
                    </div>
                    <div class="col-6 col-md-3 col-lg-2">
                        <p class="lead text-normal">Totale da saldare</p>
                        <p class="lead">{{ order.grandtotal - order.valore_parziale | currency: '€ '}}</p>
                    </div>
                    <div class="col-6 col-md-3 col-lg-2">
                        <p class="lead text-normal">Codice Tracking</p>
                        <p class="lead">{{ order.cf_1203 }}</p>
                    </div>
                    <div class="col-6 col-md-3 col-lg-2" *ngIf="order.estratto_conto">
                        <p class="lead text-normal">Estratto Conto</p>
                        <p class="lead btn-link" *ngIf="order.estratto_conto"
                           (click)="showDocument.showDocument(order.estratto_conto)">Mostra Estratto Conto</p>
                    </div>
                    <div class="col-6 col-md-3 col-lg-2" *ngIf="order.ricevuta">
                        <p class="lead text-normal">Ricevuta</p>
                        <a class="lead btn-link" *ngIf="order.ricevuta" [href]="order.ricevuta" target="_blank">Mostra
                            Ricevuta</a>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button class="btn bg-gradient-secondary" [routerLink]="['/negozio/lista-ordini']">Torna alla lista
                </button>
            </div>
        </div>
    </div>
</div>
