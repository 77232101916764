import {BaseModel} from '@models/base-model';

export class Presidi extends BaseModel {
    id: number;
    nome: string;

    fromArray(result: []) {
        const array: Presidi[] = [];
        for (const item of result) {
            array.push(new Presidi(item));
        }
        return array;
    }
}
