<div [ngBusy]="busy">
  <div class="row">
    <div class="col-12">
      <div class="card bg-gradient-dark">
        <div class="card-body row align-items-center">
          <div class="col-2 text-center">
            <img src="/assets/img/svgs/undraw_file_searching_duff.svg" class="header-logo"/>
          </div>
          <div class="col-8">
            <h1 class="header mb-0">Storico plafond Kena Mobile</h1>
            <p class="subtitle mb-4 text-white">Qui puoi trovare lo storico dei tuoi addebiti e accrediti del plafond Kena Mobile.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-12">
      <div class="info-box">
        <span class="info-box-icon bg-info"><i class="fa fa-euro-sign"></i></span>

        <div class="info-box-content">
          <span class="info-box-text">Plafond attuale</span>
          <span class="info-box-number">{{ this.authenticationService.currentUserValue.profile.plafond_kena | currency: '€ '}}</span>
        </div>
        <!-- /.info-box-content -->
      </div>
      <!-- /.info-box -->
    </div>
    <!-- /.col -->
    <div class="col-md-3 col-sm-6 col-12">
      <div class="info-box">
        <span class="info-box-icon bg-success"><i class="fa fa-cart-plus"></i></span>

        <div class="info-box-content">
          <span class="info-box-text">Plafond acquistato totale</span>
          <span class="info-box-number">{{ totalePlafondAcquistato | currency: '€ ' }}</span>
        </div>
        <!-- /.info-box-content -->
      </div>
      <!-- /.info-box -->
    </div>
    <!-- /.col -->
    <div class="col-md-3 col-sm-6 col-12">
      <div class="info-box">
        <span class="info-box-icon bg-warning"><i class="far fa-copy"></i></span>

        <div class="info-box-content">
          <span class="info-box-text">Totale ricariche fatte</span>
          <span class="info-box-number">{{ totaleRicaricheFatte | currency: '€ ' }}</span>
        </div>
        <!-- /.info-box-content -->
      </div>
      <!-- /.info-box -->
    </div>
    <!-- /.col -->
    <div class="col-md-3 col-sm-6 col-12">
      <div class="info-box">
        <span class="info-box-icon bg-danger"><i class="fa fa-list-ul"></i></span>

        <div class="info-box-content">
          <span class="info-box-text">Totale transazioni</span>
          <span class="info-box-number">{{ this.rows.length }}</span>
        </div>
        <!-- /.info-box-content -->
      </div>
      <!-- /.info-box -->
    </div>
    <!-- /.col -->
  </div>
  <div class="row">
    <div class="col-12">
      <app-table [rows]="rows" [columns]="columns"></app-table>
    </div>
  </div>
</div>
