<h4 translate>Filters</h4>
<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
    <div class="row justify-content-between">
        <div class="col-md-6 col-sm-12 mt-auto mb-0">
            <div class="form-group">
                <label translate>Date</label>
                <input id="documentDate" class="form-control form-control-sm" type="text" daterangepicker
                       [(ngModel)]="filter.date" name="date"
                       (selected)="generateDateRangeString($event.start, $event.end)"
                />
            </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12 mt-auto mb-0">
            <div class="form-group">
                <button class="btn btn-info btn-block" (click)="loadPopups()" translate>Filter
                </button>
            </div>
        </div>
    </div>
</div>
<div class="card mt-3">
    <div class="card-header">
        <div class="row">
            <div class="col-lg-10 col-12">
                <h4 class="mb-0" translate>Popups</h4>
            </div>
            <div class="col-lg-2 col-12">
                <button class="btn btn-success btn-sm float-right" type="button" translate
                        (click)="showModal()">
                    Add Popup
                </button>
            </div>
        </div>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th translate>Title</th>
                <th translate>Description</th>
                <th translate>Start date</th>
                <th translate>End date</th>
                <th translate class="text-center">Status</th>
                <th class="text-right"></th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="table-popup"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let popup of popups">
                <td>{{popup?.title}}</td>
                <td>
                    <app-rich-text-truncate [charLimit]="100" [text]="popup?.description"></app-rich-text-truncate>
                </td>
                <td>{{popup?.start_date|amDateFormat:localFormat}}</td>
                <td>{{popup?.end_date|amDateFormat:localFormat}}</td>
                <td class="text-center">
                    <span class="text-success" *ngIf="popup?.status == 'active'" translate>Active</span>
                    <span class="text-danger" *ngIf="popup?.status == 'expired'" translate>Expired</span>
                    <span class="text-info" *ngIf="popup?.status == 'on_hold'" translate>On hold</span>
                </td>
                <td class="text-right">
                    <button class="btn btn-link" (click)="showModal(popup)">
                        <i class=" fa fa-edit text-muted"></i>
                    </button>
                    <button class="btn btn-link" [swal]="deleteOptions"
                            (confirm)="deletePopup(popup?.id)">
                        <i class="fa fa-trash text-danger"></i>
                    </button>
                </td>
            </tr>
            <tr *ngIf="popups?.length<=0">
                <td class="text-muted" colspan="14" translate>List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="popups?.length>0">
        <span><span translate>There are</span> <b> {{ this.popupsPagination.totalCount }} </b>
            <span translate>{{this.popupsPagination.totalCount <= 1 ? 'popup' : 'popups'}}</span>.</span>
        <ngb-pagination *ngIf="popupsPagination.pageCount>1"
                        size="sm"
                        [pageSize]="popupsPagination.perPage"
                        [maxSize]="10"
                        [(page)]="popupsPagination.currentPage"
                        [collectionSize]="popupsPagination.totalCount"
                        (pageChange)="loadPopups()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>

<ng-template #popupFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>{{selectedPopup ? 'Change Popup' : 'Add Popup'}}</h4>
        <button (click)="modalService.dismissAll()"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busy">
        <form [formGroup]="popupForm" (ngSubmit)="savePopup()">
            <div class="form-row">
                <div class="col-12 form-group">
                    <label for="title" translate>Title</label>
                    <input type="text" class="form-control" id="title" formControlName="title">
                </div>
                <div class="col-12 col-lg-6 form-group">
                    <label for="startDate" translate>Start date</label>
                    <input type="text" class="form-control" id="startDate" daterangepicker [options]="pickerOptions"
                           (selected)="selectedDate('start_date',$event.start)"
                           formControlName="start_date">
                </div>
                <div class="col-12 col-lg-6 form-group">
                    <label for="endDate" translate>End date</label>
                    <input type="text" class="form-control" id="endDate" daterangepicker [options]="pickerOptions"
                           (selected)="selectedDate('end_date',$event.start)"
                           formControlName="end_date">
                </div>
                <div class="col-12 form-group">
                    <label for="description" translate>Description</label>
                    <editor apiKey="1nilu7q8vcp0ntuviwq5nhcskmj17c6awfhpbnr0hhv4smdw" id="description" [init]="defaultConfig" formControlName="description"></editor>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="popupForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
