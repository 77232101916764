import {Component, Input, OnInit} from '@angular/core';
import {DashboardService} from '@services/dashboard.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DataBuilderModalService} from '@services/data-builder-modal.service';

@Component({
    selector: 'app-autoload-punti-vendita',
    templateUrl: './autoload-punti-vendita.component.html',
    styleUrls: ['./autoload-punti-vendita.component.css']
})
export class AutoloadPuntiVenditaComponent implements OnInit {
    _urlPuntiVendita: string;
    autoloadTableUrl: string;
    data = {
        giorno_paragone: '$giornoParagone',
        mese: '',
        prevMonth: 0,
        pdv_totali: 0,
        pdv_totali_perc: 0,
        produttivi: 0,
        improduttivi: 0,
        produttivi_perc: 0,
        improduttivi_perc: 0,
        produttivi_persi_prev: 0,
        produttivi_persi_paragone: 0,
        paragone: '',
        urls: {
            pdvTotali: null,
            pdvProduttivi: null,
            pdvImproduttivi: null,
            pdvMenoProduttiviParagone: null,
            pdvMenoProduttiviMese: null,
        }
    };

    @Input() set urlPuntiVendita(value: string) {
        this._urlPuntiVendita = value;

        if (this._urlPuntiVendita !== undefined) {
            this.loadPuntiVendita();
        }
    }

    constructor(
        private dashboardService: DashboardService,
        public dbm: DataBuilderModalService,
        private spinner: NgxSpinnerService,
        private modalService: NgbModal
    ) {
    }

    ngOnInit() {

    }

    private loadPuntiVendita() {
        this.spinner.show('loader-punti-vendita');
        this.dashboardService.get(this._urlPuntiVendita)
            .subscribe((data) => {
                this.data = data.result;
                this.spinner.hide('loader-punti-vendita');
            });
    }
}
