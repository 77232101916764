import {Component, OnInit} from '@angular/core';
import {DataService} from '@services/data.service';
import {Subscription} from 'rxjs';
import {TableColumn} from '@app/pages/partials/table/table.component';
import {ActivatedRoute} from '@angular/router';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-avanzamento',
    templateUrl: './avanzamento.component.html',
    styleUrls: ['./avanzamento.component.css']
})
export class AvanzamentoComponent implements OnInit {
    rows: [] = [];
    columns: TableColumn[];
    busy: Subscription;

    brand: string;
    url: string;

    constructor(
        private dataService: DataService,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        switch (this.activatedRoute.snapshot.url[0].path) {
            case 'kena':
                this.brand = 'kena';
                this.url = '/avanzamento-kena';
                break;
            case 'tiscali-mobile':
                this.brand = 'tiscali';
                this.url = '/avanzamento-tiscali-mobile';
                break;
            case 'tiscali-fisso':
                this.brand = 'tiscali';
                this.url = '/avanzamento-tiscali-adsl';
                break;
            case 'tiscali-lte':
                this.brand = 'tiscali';
                this.url = '/avanzamento-tiscali-lte';
                break;
            case 'sky':
                this.brand = 'sky';
                this.url = '/avanzamento-sky';
                break;
            case 'eni':
                this.brand = 'eni';
                this.url = '/avanzamento-eni';
                break;
        }

        this.busy = this.dataService
            .get(this.url)
            .subscribe((data) => {
                this.columns = data.result.columns;
                this.rows = data.result.rows;
            });
    }
}
