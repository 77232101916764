import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Province } from '@app/models/geo';
import { ProvinceService } from '@app/services/province.service';

@Component({
  selector: 'app-province-select',
  templateUrl: './province-select.component.html',
  styleUrls: ['./province-select.component.scss']
})
export class ProvinceSelectComponent implements OnInit {
  @Input()
  small = false;
  
  _state = '';

  @Input()
  set state(value: string) {
    this._state = value;
    this.province = null;

    if(this.onDemand) {
      this.refresh();
    }
  }

  get state() {
    return this._state;
  }

  @Input()
  onDemand = false;

  @Input()
  province: Province;
  data: Province[] = [];
  loading = false;

  @Output() onChange = new EventEmitter();

  constructor(
    private service: ProvinceService
  ) {}

  ngOnInit(): void {
    if(!this.onDemand) {
      this.refresh();
    }

    if(this.province) {
      this.onChange.emit(this.province);
    }
  }

  refresh() {
    this.loading = true;
    this.service.list({
      fields: 'id,provincia',
      state: this.state
    }).subscribe((data) => {
      this.data = new Province().fromArray(data);
      this.loading = false;
    });
  }
}
