import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {RequestService} from '@services/request.service';
import {AlertService} from '@services/alert.service';
import {Prize, PrizeListPagination} from '@models/prize';
import {Observable} from 'rxjs';
import {Rule} from '@models/rule';
import {Campaign, CampaignListPagination, UsedPrize, UsedPrizesListPagination} from '@models/campaign';
import {CampaignSegment, CampaignSegmentListPagination} from '@models/campaign-segment';
import {LoyaltyCard} from '@models/loyalty-card';
import {ShopBrand} from '@models/e-pay/shop-brand';

const campaigns = 'campaigns';
const rules = 'rules';
const prizes = 'prizes';

@Injectable({
    providedIn: 'root'
})
export class CampaignService {

    constructor(private request: RequestService, private alertService: AlertService) {
    }

    public getVendorSegments() {
        return this.request.get('vendor/segments').pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getCampaigns(pageNumber: number = 1): Observable<CampaignListPagination> {
        const params = {page: pageNumber};
        return this.request.get(campaigns, params).pipe(
            map((response: any) => {
                return new CampaignListPagination(response);
            })
        );
    }

    public getLoyaltyCard(): Observable<LoyaltyCard> {
        return this.request.get(campaigns + '/active').pipe(
            map((response: any) => {
                return new LoyaltyCard(response);
            })
        );
    }

    public getPrizes(campaignId: number, pageSize?): Observable<PrizeListPagination> {
        const params = {campaign_id: campaignId};
        if (pageSize) {
            params['per-page'] = pageSize;
        }
        return this.request.get(prizes, params).pipe(
            map((response: any) => {
                return new PrizeListPagination(response);
            })
        );
    }

    public getSegmentsWithRules(campaignId: number, pageSize?): Observable<CampaignSegmentListPagination> {
        const params = {campaign_id: campaignId};
        if (pageSize) {
            params['per-page'] = pageSize;
        }
        return this.request.get(rules, params).pipe(
            map((response: any) => {
                return new CampaignSegmentListPagination(response);
            })
        );
    }

    public getSalesTimeline(loyaltyCard: LoyaltyCard): Observable<any[]> {
        const params = {
            campaign_id: loyaltyCard.id,
            from: loyaltyCard.start_date,
            to: loyaltyCard.end_date
        };
        return this.request.get(campaigns + '/sales-timeline', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getOffers(vendorSegmentId: number, startDate: string, endDate: string, campaignId: number) {
        const params = {
            vendor_segment_id: vendorSegmentId,
            from: startDate,
            to: endDate,
            campaign_id: campaignId
        };
        return this.request.get('vendor/offers', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public postCampaign(body) {
        return this.request.post(campaigns, body).pipe(
            map((response: any) => {
                return new Campaign(response);
            })
        );
    }

    public putCampaign(id, body) {
        return this.request.put(campaigns + '/' + id, body).pipe(
            map((response: any) => {
                return new Campaign(response);
            })
        );
    }

    public postRule(body: Rule): Observable<Rule> {
        return this.request.post(rules, body).pipe(
            map((response: any) => {
                return new Rule(response);
            })
        );
    }

    public putRule(id, body: Rule): Observable<Rule> {
        return this.request.put(rules + '/' + id, body).pipe(
            map((response: any) => {
                return new Rule(response);
            })
        );
    }

    public postPrize(body: Prize): Observable<Prize> {
        return this.request.post(prizes, body.postPayloadPrize).pipe(
            map((response: any) => {
                return new Prize(response);
            })
        );
    }
    public updatePrize(id, body: Prize): Observable<Prize> {
        return this.request.post(prizes + '/' + id, body.postPayloadPrize).pipe(
            map((response: any) => {
                return new Prize(response);
            })
        );
    }

    public deleteCampaign(id) {
        return this.delete(campaigns + '/' + id);
    }

    public deleteRule(id) {
        return this.delete(rules + '/' + id);
    }

    public deletePrize(id: number) {
        return this.delete(prizes + '/' + id);
    }

    private delete(path: string) {
        return this.request.delete(path).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public withdrawPrize(dealerId, campaignId, prizeId: number): Observable<UsedPrize> {
        const body = {
            dealer_id: dealerId,
            prize_id: prizeId,
            campaign_id: campaignId
        };
        return this.request.post('used-prizes', body).pipe(
            map((response: any) => {
                return new UsedPrize(response);
            })
        );
    }

    public getUsedPrize(id: number, pageNumber: number = 1): Observable<UsedPrizesListPagination> {
        const params = {campaign_id: id, page: pageNumber};
        return this.request.get('used-prizes', params).pipe(
            map((response: any) => {
                return new UsedPrizesListPagination(response);
            })
        );
    }

    public successAlert(text) {
        this.alertService.show(
            '',
            'Successfully ' + text,
            {classname: 'bg-success text-light', delay: 2000}
        );
    }

    public errorAlert(message?) {
        this.alertService.show(
            'An error occurred!',
            message ? message : 'Try again!',
            {classname: 'bg-danger text-light', delay: 2000}
        );
    }

    public removeItemFromList(selectedId: number, list: any[] = []) {
        for (let i = 0; i < list.length; i++) {
            if (selectedId === list[i].id) {
                list.splice(i, 1);
            }
        }
        return list;
    }
}
