<div class="modal-header">
    <h4 class="modal-title clamp-title" translate>Questions and Answers</h4>
    <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="dismissModal()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h4>
        <i class="fas fa-store text-info mr-2"></i>{{call.lead.company_owner}}
    </h4>
    <h4>
        <i class="fas fa-headset text-info mr-2"></i>{{call.caller_name}}
    </h4>
    <h4>
        <i class="fas fa-phone text-success mr-2"></i> {{call.destination}}
    </h4>
    <div class="question-container table-responsive">
        <table class="table table-sm">
            <thead>
            <tr>
                <th scope="col" translate>Question</th>
                <th scope="col" translate>Answer</th>
            </tr>
            </thead>
            <tbody>
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="table-questions"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let question of questionAnswers">
                <td> {{question.info.title}}
                    <p class="text-muted" [innerHTML]="question.info.text"></p>
                </td>
                <td>
                    <strong *ngIf="question.answer && questionTypes[0]?.key==question.info.question_type" class = "text-capitalize"
                            translate>{{question.answer.text}}</strong>
                    <app-rich-text-truncate
                            *ngIf="question.answer && questionTypes[1]?.key==question.info.question_type"
                            [text]="question.answer.text"></app-rich-text-truncate>
                    <p *ngIf="!question.answer" class="text-nowrap" translate>Not Answered</p>
                </td>
            </tr>
            <tr *ngIf="questionAnswers?.length<=0">
                <td class="text-muted" translate colspan="2">List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
