import {KenaService} from './../../services/kena.service';
import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-kena-recap',
    templateUrl: './kena-recap.component.html',
    styleUrls: ['./kena-recap.component.css'],
})
export class KenaRecapComponent implements OnInit {
    busy: any;
    sales: any;
    salesMnp: any;
    openings: any;
    monthly: any;

    defaultMonth;
    period;
    apiFormat = 'YYYY-MM';

    loadingSales = false;
    loadingSalesMnp = false;
    loadingOpenings = false;
    loadingMonthly = false;

    urlRecapSales = `sales/recap-production-sales`;
    urlRecapProductivesMnp = `sales/recap-productives-mnp`;
    urlRecapMnp = `sales/recap-production-mnp`;
    urlRecapSoccer = `sales/recap-production-soccer`;
    urlRecapOpenings = `affiliations/recap-openings`;

    urlRecapSalesAM = `sales/recap-production-sales?areamanager=true`;
    urlRecapProductivesMnpAM = `sales/recap-productives-mnp?areamanager=true`;
    urlRecapMnpAM = `sales/recap-production-mnp?areamanager=true`;
    urlRecapSoccerAM = `sales/recap-production-soccer?areamanager=true`;
    urlRecapOpeningsAM = `affiliations/recap-openings?areamanager=true`;

    constructor(
        private service: KenaService
    ) {
        this.defaultMonth = moment().startOf('month');
    }

    ngOnInit() {
    }

    setSelectedDateRange(date: any) {
        this.period = date;
        this.refresh();
    }

    setAgent($event) {
        this.refreshMonthlyRecap($event);
    }

    refresh() {
        this.loadingSales = true;
        this.busy = this.service.recapSales(this.period).subscribe((data) => {
            this.sales = data;
            this.loadingSales = false;
        }, err => {
            this.sales = null;
        });

        this.loadingSalesMnp = true;
        this.busy = this.service.recapSalesMnp(this.period).subscribe((data) => {
            this.salesMnp = data;
            this.loadingSalesMnp = false;
        }, err => {
            this.salesMnp = null;
        });

        this.loadingOpenings = true;
        this.busy = this.service.recapOpenings(this.period).subscribe((data) => {
            this.openings = data;
            this.loadingOpenings = false;
        }, err => {
            this.openings = null;
        });

        this.refreshMonthlyRecap();
    }

    refreshMonthlyRecap(agent = null) {
        this.loadingMonthly = true;
        this.busy = this.service.recapOfMonth(this.period, agent).subscribe((data) => {
            this.monthly = data;
            this.loadingMonthly = false;
        });
    }
}
