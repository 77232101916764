import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Pagination} from '@app/models/pagination';
import {Ticket, TicketAttachment, TicketCategoria, TicketRisposte,} from '@app/models/ticket';
import {AuthenticationService} from '@app/services/authentication.service';
import {ConfirmOptionsService} from '@app/services/confirm-options.service';
import {TicketService} from '@app/services/ticket.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-ticket-management',
    templateUrl: './ticket-management.component.html',
    styleUrls: ['./ticket-management.component.scss'],
})
export class TicketManagementComponent implements OnInit {
    busy: Subscription;

    filter = {
        id_operatore: '',
        id_dealer: '',
        category: '',
        subcategory: '',
        status: 0,
    };

    categories: TicketCategoria[] = [];
    toHandleList: Ticket[] = [];
    handlingList: Ticket[] = [];
    handlingPagination = new Pagination({page: 1, pageSize: 20});
    toHandlePagination = new Pagination({page: 1, pageSize: 20});

    @ViewChild('categoryFormTemplate') private categoryFormTemplate;
    @ViewChild('ticketTemplate') private ticketModalTemplate;

    categoryForm: FormGroup;
    categoryModalTitle: string;
    ticketModal: NgbModalRef;
    categoryFormModal: NgbModalRef;
    selectedCategory: TicketCategoria;
    selectedTicket: Ticket;
    selectedReply: TicketRisposte;

    selectedAttachment: TicketAttachment;
    attachmentForm: FormGroup;
    fileName: string;
    showUpload = false;
    takingInHandle = false;

    category(id: any) {
        if (this.categories) {
            return this.categories.find(
                (item) => item.id.toString() === id.toString()
            ).subcategories;
        }
        return null;
    }

    get categoryConfirmDeleteOptions() {
        return new ConfirmOptionsService({
            title: 'Elimina questa categoria',
            text: 'Sei sicuro di voler rimuovere questo categoria?',
        });
    }

    constructor(
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private modalService: NgbModal,
        private spinner: NgxSpinnerService,
        private service: TicketService,
        public auth: AuthenticationService
    ) {
    }

    ngOnInit() {
        this.busy = this.service
            .getCategories()
            .subscribe((categories: TicketCategoria[]) => {
                this.categories = categories;
            });

        this.refreshToHandle();
        this.refreshHandling();

        this.categoryForm = this.formBuilder.group({
            id_padre: [null, []],
            id_vendor: [null, []],
            nome: [null, [Validators.required]],
        });

        this.attachmentForm = this.formBuilder.group({
            ticketfile: [null, [Validators.required]],
        });
    }

    refreshToHandle() {
        this.spinner.show('table-to-handle');
        this.service
            .toHandleList({
                sort: 'id',
                page: this.toHandlePagination.page,
            })
            .subscribe((data: any) => {
                this.toHandleList = data.rows;
                this.toHandlePagination = new Pagination(data.pagination);
                this.spinner.hide('table-to-handle');
            });
    }

    refreshHandling() {
        this.spinner.show('table-handling');
        this.service
            .handlingList({
                staff: this.filter.id_operatore,
                retailer: this.filter.id_dealer,
                category: this.filter.category,
                subcategory: this.filter.subcategory,
                status: this.filter.status,
                sort: '-id',
                page: this.handlingPagination.page,
            })
            .subscribe((data: any) => {
                this.handlingList = data.rows;
                this.handlingPagination = new Pagination(data.pagination);
                this.spinner.hide('table-handling');
            });
    }

    showCreateCategoryModal(id_padre = null) {
        this.selectedCategory = new TicketCategoria({id_padre: id_padre});
        this.categoryModalTitle = 'Nuova categoria';
        this.categoryFormModal = this.modalService.open(this.categoryFormTemplate, {
            size: 'lg',
        });
        this.categoryFormModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    showUpdateCategoryModal(category: TicketCategoria) {
        this.selectedCategory = new TicketCategoria(category);
        this.categoryModalTitle = `Aggiorna la categoria ${category.nome}`;
        this.categoryFormModal = this.modalService.open(this.categoryFormTemplate, {
            size: 'lg',
        });
        this.categoryFormModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    showTicketModal(ticket: Ticket) {
        this.selectedTicket = new Ticket(ticket);
        this.selectedReply = new TicketRisposte({
            id_ticket: ticket.id,
        });
        this.ticketModal = this.modalService.open(this.ticketModalTemplate, {
            size: 'xl',
        });
        this.ticketModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    saveReply(reply: TicketRisposte) {
        this.spinner.show('ticket-modal');
        const params = {};
        const properties = ['id_ticket', 'risposta'];
        properties.forEach((prop) => {
            if (reply && reply[prop]) {
                params[prop] = reply[prop];
            }
        });

        let isNew = reply.id ? false : true;
        let request = isNew
            ? this.service.saveReply(params)
            : this.service.updateReply(reply.id, params);

        request.subscribe(
            (data: any) => {
                this.selectedReply = new TicketRisposte({id_ticket: reply.id_ticket});
                let savedReply = new TicketRisposte(data);
                savedReply.isNew = isNew;

                this.service
                    .getTicket(reply.id_ticket, {
                        expand: 'replies,retailer,category,subcategory,attachments',
                    })
                    .subscribe((data) => {
                        let ticket = new Ticket(data);
                        this.selectedTicket = ticket;
                        this.updateLocalStateTicket(ticket);
                        this.spinner.hide('ticket-modal');
                    });

                this.toastr.success(
                    `Risposta ${isNew ? 'aggiunta' : 'aggiornata'} con successo`,
                    'Notifica'
                );
            },
            (err: any) =>
                this.toastr.error(
                    `Si è verificato un errore durante la ${
                        isNew ? 'creazione' : 'aggiornamento'
                    } della risposta`,
                    'Errore'
                )
        );
    }

    saveCategory(category: TicketCategoria) {
        const params = {};
        const properties = ['nome', 'id_vendor', 'id_padre'];
        properties.forEach((prop) => {
            if (category && category[prop]) {
                params[prop] = category[prop];
            }
        });

        let isNew = category.id ? false : true;
        let request = isNew
            ? this.service.saveCategory(params)
            : this.service.updateCategory(category.id, params);

        this.busy = request.subscribe(
            (data: any) => {
                let savedCategory = new TicketCategoria(data);
                savedCategory.isNew = isNew;

                this.updateLocalStateCategory(savedCategory);
                this.categoryFormModal.close();
                this.toastr.success(
                    `Categoria ${isNew ? 'creata' : 'aggiornata'} con successo`,
                    'Notifica'
                );
            },
            (err: any) =>
                this.toastr.error(
                    `Si è verificato un errore durante la ${
                        isNew ? 'creazione' : 'aggiornamento'
                    } della categoria`,
                    'Errore'
                )
        );
    }

    deleteCategory(category: TicketCategoria) {
        let request = this.service.deleteCategory(category.id);

        this.busy = request.subscribe(
            (data: any) => {
                if (data === null) {
                    this.deleteLocalStateCategory(category);
                    this.toastr.success('Categoria eliminata con successo', 'Notifica');
                }
            },
            (err: any) =>
                this.toastr.error(
                    'Si è verificato un errore durante l\'eliminazione della categoria',
                    'Errore'
                )
        );
    }

    deleteSubCategory(category: TicketCategoria) {
        let request = this.service.deleteCategory(category.id);

        this.busy = request.subscribe(
            (data: any) => {
                if (data === null) {
                    this.deleteLocalStateSubCategory(category);
                    this.toastr.success('Categoria eliminata con successo', 'Notifica');
                }
            },
            (err: any) =>
                this.toastr.error(
                    'Si è verificato un errore durante l\'eliminazione della categoria',
                    'Errore'
                )
        );
    }

    updateLocalStateTicket(ticket: Ticket) {
        for (let i in this.handlingList) {
            if (this.handlingList[i].id == ticket.id) {
                this.handlingList[i] = ticket;
                return;
            }
        }
    }

    updateLocalStateCategory(category: TicketCategoria) {
        let categories = this.categories;
        if (category.id_padre) {
            let parentCategory = this.categories.find((c) => {
                return c.id === category.id_padre;
            });

            if (parentCategory) {
                categories = parentCategory.subcategories;
            }
        }

        for (let i in categories) {
            if (categories[i].id == category.id) {
                categories[i] = category;
                return;
            }
        }
        let newCategory = categories.find((c) => {
            return c.id !== category.id;
        });

        if (newCategory || categories.length === 0) {
            categories.push(category);
        }
    }

    deleteLocalStateSubCategory(category: TicketCategoria) {
        let index = this.categories.findIndex(function(o) {
            return o.id.toString() === category.id_padre.toString();
        });
        if (index !== -1) {
            let subIndex = this.categories[index].subcategories.findIndex(function(
                o
            ) {
                return o.id.toString() === category.id_padre.toString();
            });
            this.categories[index].subcategories.splice(subIndex, 1);
        }
    }

    deleteLocalStateCategory(category: TicketCategoria) {
        let index = this.categories.findIndex(function(o) {
            return o.id === category.id;
        });
        if (index !== -1) {
            this.categories.splice(index, 1);
        }
    }

    setStaffOperatorOnTicket(ticket: Ticket, id) {
        this.spinner.show('ticket-modal');
        this.service
            .updateTicket(ticket.id, {
                id_operatore: id,
            })
            .subscribe(
                (data: any) => {
                    this.spinner.hide('ticked-modal');
                    this.ticketModal.close();
                    this.refreshHandling();
                    this.toastr.success(`Operatore cambiato con successo.`, 'Notifica');
                },
                (err: any) => {
                    this.spinner.hide('ticked-modal');
                    this.toastr.error(
                        `Si è verificato un'errore nel cambio operatore.`,
                        'Errore'
                    );
                }
            );
    }

    setStatusOnTicket(ticket: Ticket) {
        ticket.stato = parseInt(ticket.stato.toString(), 10);
        this.spinner.show('ticket-modal');
        this.service
            .updateTicket(ticket.id, {
                stato: ticket.stato,
            })
            .subscribe(
                (data: any) => {
                    this.spinner.hide('ticket-modal');
                    this.updateLocalStateTicket(ticket);
                    this.toastr.success(`Stato cambiato con successo.`, 'Notifica');
                },
                (err: any) => {
                    this.spinner.hide('ticked-modal');
                    this.toastr.error(
                        `Si è verificato un'errore nel cambio stato.`,
                        'Errore'
                    );
                }
            );
    }

    newAttachment(ticket: Ticket) {
        this.selectedAttachment = new TicketAttachment({id_ticket: ticket.id});
        this.showUpload = true;
    }

    handleFileInput(files: FileList) {
        if (files && files.item(0)) {
            const logoFile = files.item(0);

            this.fileName = logoFile.name;
            this.selectedAttachment.ticketfile = logoFile;

            this.saveAttachment(this.selectedAttachment);
        }
    }

    saveAttachment(attachment: TicketAttachment) {
        const formData = new FormData();
        const properties = ['id_ticket', 'ticketfile'];
        properties.forEach((prop) => {
            if (attachment && attachment[prop]) {
                formData.append(prop, attachment[prop]);
            }
        });

        this.busy = this.service.saveAttachment(formData).subscribe(
            (data: any) => {
                this.showUpload = false;
                this.attachmentForm.reset();
                this.service
                    .getTicket(attachment.id_ticket, {
                        expand: 'replies,retailer,category,subcategory,attachments',
                    })
                    .subscribe((data) => {
                        let ticket = new Ticket(data);
                        this.selectedTicket = ticket;
                        this.updateLocalStateTicket(ticket);
                    });

                this.toastr.success(`Allegato caricato con successo`, 'Notifica');
            },
            (err: any) =>
                this.toastr.error(
                    `Si è verificato un errore durante il caricamento dell'allegato`,
                    'Errore'
                )
        );
    }

    takeInHandle(ticket: Ticket) {
        this.spinner.show('table-to-handle');
        this.service.takeInHandle(ticket.id).subscribe(
            (data: any) => {
                this.deleteLocalStateTicketsToHandle(ticket);
                this.spinner.hide('table-to-handle');
                this.toastr.success(
                    'Ticket preso in gestione con successo',
                    'Notifica'
                );

                this.refreshHandling();
            },
            (err: any) =>
                this.toastr.error(
                    'Si è verificato un errore nel prendere in gestione il ticket',
                    'Errore'
                )
        );
    }

    deleteLocalStateTicketsToHandle(ticket: Ticket) {
        let index = this.toHandleList.findIndex(function(o) {
            return o.id === ticket.id;
        });
        if (index !== -1) {
            this.toHandleList.splice(index, 1);
        }
    }
}
