<app-master-header [ngBusy]="busy"
                   cardClass="bg-gradient-dark"
                   image="assets/img/flats/shopping-cart.png"
                   title="Negozio"
                   [periodoSingle]="true"
                   (onChange)="this.updateUrls($event.periodo)"
>
</app-master-header>

<app-master-subheader cardClass="bg-gradient-light" [url]="urlSubheader"></app-master-subheader>

<div class="row">
    <div class="col-12 col-xl-4">
        <app-master-card
                bodyClass="p-0"
                title="Prodotti più venduti"
                image="assets/img/flats/box.png"
        >
            <app-autoload-table
                    [url]="urlProdottiPiuVenduti"
                    [small]="true"
                    [hideFooterDescription]="true"
            ></app-autoload-table>
        </app-master-card>
        <app-master-card
                *show="['master']"
                bodyClass="p-0"
                title="Insoluti per Key Account"
                image="assets/img/flats/complain.png"
        >
            <app-autoload-table
                    [url]="urlInsoluti"
                    [small]="true"
                    [hideFooterDescription]="true"
            ></app-autoload-table>
        </app-master-card>
        <app-master-card
                bodyClass="p-0"
                title="Rimessa a Key Account"
                image="assets/img/flats/handcash.png"
        >
            <app-autoload-table
                    [url]="urlRimessaAgente"
                    [small]="true"
                    [hideFooterDescription]="true"
            ></app-autoload-table>
        </app-master-card>
    </div>
    <div class="col-12 col-xl-4">
        <app-master-card
                *show="['master']"
                title="Vendite per Key Account"
                image="assets/img/flats/employee.png"
                bodyClass="px-0"
        >
            <app-autoload-horizontal-bar-chart [height]="400" [url]="urlVenditeAgente" [gradient]="false"></app-autoload-horizontal-bar-chart>
        </app-master-card>
        <app-master-card
                title="Metodi di pagamento"
                image="assets/img/flats/shopping-cart.png"
        >
            <app-autoload-horizontal-bar-chart [url]="urlMetodoPagamento" [gradient]="false"></app-autoload-horizontal-bar-chart>
        </app-master-card>
    </div>
    <div class="col-12 col-xl-4">
        <app-master-card
                title="Vendite Plafond"
                image="assets/img/flats/payment.png"
                bodyClass="px-0"
        >
            <app-autoload-area-chart [url]="urlVenditePlafond"></app-autoload-area-chart>
        </app-master-card>
        <app-master-card
                title="Vendita Sim"
                image="assets/img/flats/sim-card.png"
        >
            <app-autoload-area-chart [url]="urlVenditeSim"></app-autoload-area-chart>
        </app-master-card>
    </div>
</div>
