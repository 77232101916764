import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-master-negozio',
    templateUrl: './master-negozio.component.html',
    styleUrls: ['./master-negozio.component.css']
})
export class MasterNegozioComponent implements OnInit {
    busy: Subscription;

    _periodo: string;

    urlSubheader: string;
    urlProdottiPiuVenduti: string;
    urlInsoluti: string;
    urlRimessaAgente: string;
    urlVenditeAgente: string;
    urlMetodoPagamento: string;
    urlVenditePlafond: string;
    urlVenditeSim: string;

    constructor() {
    }

    ngOnInit() {
    }

    updateUrls(periodo) {
            this.urlSubheader = `/negozio-header?periodo=${periodo}`;

            this.urlProdottiPiuVenduti = `/negozio-prodotti-piu-venduti?periodo=${periodo}`;
            this.urlInsoluti = `/negozio-insoluti-agente?periodo=${periodo}`;
            this.urlRimessaAgente = `/negozio-rimessa-agente?periodo=${periodo}`;
            this.urlVenditeAgente = `/negozio-vendite-agente?periodo=${periodo}`;
            this.urlMetodoPagamento = `/negozio-metodi-di-pagamento?periodo=${periodo}`;
            this.urlVenditePlafond = `/negozio-vendite-plafond?periodo=${periodo}`;
            this.urlVenditeSim = `/negozio-vendite-sim?periodo=${periodo}`;
    }

}
