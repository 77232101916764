import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-dialog-field-edit',
  templateUrl: './dialog-field-edit.component.html',
  styleUrls: ['./dialog-field-edit.component.scss']
})
export class DialogFieldEditComponent implements OnInit {

  @Output() private onSave = new EventEmitter<object>();

  @ViewChild('content') private content;

  form: FormGroup;

  labels: string[];

  selectOptions: any[] | any[][] = [];

  constructor(
      private modalService: NgbModal,
      public formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
  }

  f() {
    return this.form.controls;
  }

  open(label: string | string[], key: string | string[], value: string | string[], validator: any[] | any[][],
       selectValues: any[] | any[][] = []) {

    // Save labels
    this.labels = Array.isArray(label) ? label : [label];

    this.selectOptions = selectValues;

    this.modalService.open(this.content, {
      size: 'sm'
    });

    // Set config
    const config = {};

    // Generate controls used to generate form
    if (Array.isArray(label) && Array.isArray(key) && Array.isArray(value)) {
      console.log('Multi field detected');
      for (let i = 0; i < label.length; i++) {
        config[key[i]] = [value[i], validator[i]];
      }
    } else if (!Array.isArray(label) && !Array.isArray(key) && !Array.isArray(value)) {
      console.log('Single field detected');
      config[key] = [value, validator];
    } else {
      console.log('An error occurred');
    }

    // Generate form
    this.form = this.formBuilder.group(config);
  }

  save() {
    if (this.form.valid) {
      console.log('Saving...');
      this.onSave.emit(this.form.value);
      this.modalService.dismissAll();
    }
  }

  // Ottiene il nome della chiave dal control
  private getName(control: AbstractControl): string | null {
    const group = control.parent as FormGroup;

    if (!group) {
      return null;
    }

    let name: string;

    Object.keys(group.controls).forEach(key => {
      const childControl = group.get(key);

      if (childControl !== control) {
        return;
      }

      name = key;
    });

    return name;
  }

}
