import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Product} from '@models/product';
import {CartService} from '@services/cart.service';
import {ShopSubProductsPickerComponent} from '@app/pages/shop/partials/sub-products/shop-sub-products-picker.component';
import {CartSubProduct} from '@models/shop/cart-sub-product';
import {ShopPrintingProductComponent} from '@app/pages/shop/partials/printing-product/shop-printing-product.component';
import {NegozioService} from '@services/negozio.service';
import {ModalComponent} from '@widgets/modal/modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
    @ViewChild('picker')
    private picker: ShopSubProductsPickerComponent;
    @ViewChild('prenotazione')
    private prenotazione: ModalComponent;
    @ViewChild('printing')
    private printing: ShopPrintingProductComponent;
    @Input()
    product: Product;

    tabId = 1;

    offerta = {nome: '', descrizione: ''};
    loadingPrenotazione = false;
    seriale = null;

    constructor(
        public cartService: CartService,
        public modalService: NgbModal,
        public negozioService: NegozioService,
        private router: Router
    ) {
    }

    ngOnInit() {
    }

     addToCart() {
        if (this.product.personalizzabile === 1) {
            this.printing.setProduct(this.product);
            this.printing.open();
        } else {
            if (this.product.sub_products.length === 0) {
                this.cartService.addToCart(this.product);
            } else if (this.product.sub_products.length === 1) {
                const subProduct = new CartSubProduct();
                subProduct.product = this.product.sub_products[0];
                subProduct.quantity = this.product.qty_per_unit_n;
                subProduct.id = subProduct.product.productid;
                this.cartService.addToCart(this.product, [subProduct]);
            } else {
                this.picker.setProduct(this.product);
                this.picker.open();
            }
        }
    }

    stillAvalaible(product: Product) {
        const found = this.cartService.cart.items.find((item) => product.id === item.id);

        if (!found) {
            return true;
        }
        return found.quantity < product.stock;
    }


    prenota(id) {
        this.loadingPrenotazione = true;
        this.negozioService.creaRateizzazione(id).subscribe((data) => {
            this.loadingPrenotazione = false;
            this.seriale = data.result.seriale;
            this.modalService.dismissAll();
            this.modalService.open(this.prenotazione, {size: 'xl'})
        });
    }

    goToPrenotazioni() {
        this.modalService.dismissAll();
        this.router.navigate(['/negozio/rateizzazioni']);
    }
}
