<h4 translate>Filters</h4>
<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
    <form (ngSubmit)="loadProduct()">
        <div class="form-row">
            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Brand</label>
                    <ng-select id="brand" [(ngModel)]="filter.brand" bindValue="id" bindLabel="name"
                               [items]="brands" (search)="loadBrands($event?.term)" name="brand"
                               [loading]="brandsLoading"></ng-select>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate>Category</label>
                    <ng-select id="category" [(ngModel)]="filter.category" bindValue="id" bindLabel="name"
                               [items]="categories" (search)="loadCategories($event?.term)" name="category"
                               [loading]="categoriesLoading">
                    </ng-select>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="name">Name</label>
                    <input type="text" class="form-control" id="name" [(ngModel)]="filter.name" name="name">
                </div>
            </div>
            <div class="col-xl-2 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <button class="btn btn-info btn-block" translate type="submit">Filter
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="card">
    <div class="card-header">
        <div class="row">
            <div class="col-lg-8 col-12">
                <h4 class="mb-0" translate>Products</h4>
            </div>
            <div class="col-lg col-12 d-flex justify-content-lg-end">
                <button class="btn btn-secondary btn-sm mr-2" translate (click)="showFileModal()">
                    Upload File
                </button>
                <button class="btn btn-success btn-sm" translate (click)="showModal()">
                    Add Product
                </button>
            </div>
        </div>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th translate>Product</th>
                <th translate>Category</th>
                <th translate>VAT type</th>
                <th translate class="text-center">Active</th>
                <th translate class="text-right">Price</th>
                <th translate class="text-right">Dealer price</th>
                <th translate class="text-right">Our price</th>
                <th></th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="table-products"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let product of products">
                <td>
                    <div class="media d-flex align-items-center">
                        <div class="logo-img">
                            <img
                                    [src]="product.image_path"
                                    alt="">
                        </div>
                        <div class="media-body ml-2">
                            <h5 class="mt-0 mb-0"><strong>{{product?.name}}</strong></h5>
                            <small><strong translate>Brand: </strong> {{product?.brand?.name}}</small>
                            <p class="text-muted small">
                                <app-rich-text-truncate [text]="product.description"></app-rich-text-truncate>
                            </p>
                        </div>
                    </div>
                </td>
                <td>{{product.category.name}}</td>
                <td>{{product.vat_type}}</td>
                <td class="text-center">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" [id]="'switch'+product.id"
                               [(ngModel)]="product.is_active" (ngModelChange)="changeStatus(product)">
                        <label class="custom-control-label" [for]="'switch'+product.id"></label>
                    </div>
                </td>
                <td class="text-right">
                    <ul class="nav nav-pills nav-stacked d-block" *ngIf="product.variants?.length>0">
                        <li class="nav-item text-right"
                            *ngFor="let variation of product.variants">{{variation.price|currency:currency}}</li>
                    </ul>
                    <span *ngIf="product.variants?.length<=0">{{product.price|currency:currency}}</span>
                </td>
                <td class="text-right">
                    <ul class="nav nav-pills nav-stacked d-block" *ngIf="product.variants?.length>0">
                        <li class="nav-item text-right"
                            *ngFor="let variation of product.variants">{{variation.dealer_price|currency:currency}}</li>
                    </ul>
                    <span *ngIf="product.variants?.length<=0">{{product.dealer_price|currency:currency}}</span>
                </td>
                <td class="text-right">
                    <ul class="nav nav-pills nav-stacked d-block" *ngIf="product.variants?.length>0">
                        <li class="nav-item text-right"
                            *ngFor="let variation of product.variants">{{variation.our_price|currency:currency}}</li>
                    </ul>
                    <span *ngIf="product.variants?.length<=0">{{product.our_price|currency:currency}}</span>
                </td>
                <td class="text-right">
                    <button class="btn btn-link text-info" (click)="showDetailModal(product)"><i class="fas fa-eye"></i>
                    </button>
                    <button class="btn btn-link" (click)="showModal(product)">
                        <i class="fa fa-edit text-muted"></i>
                    </button>
                    <button class="btn btn-link" [swal]="deleteProductOptions"
                            (confirm)="deleteProduct(product.id)">
                        <i class="fa fa-trash text-danger"></i>
                    </button>
                </td>
            </tr>
            <tr *ngIf="products?.length<=0">
                <td class="text-muted" colspan="7" translate>List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="products?.length>0">
    <span><span translate>There are</span><b> {{ this.productsPagination.totalCount }}</b>
        {{this.productsPagination.totalCount <= 1 ? 'product' : 'products'|translate}}.</span>
        <ngb-pagination *ngIf="productsPagination.pageCount>1"
                        size="sm"
                        [pageSize]="productsPagination.perPage"
                        [maxSize]="10"
                        [(page)]="productsPagination.currentPage"
                        [collectionSize]="productsPagination.totalCount"
                        (pageChange)="loadProduct()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>
<ng-template #productFormTemplate let-modal>
    <app-product-modal [products]="products" [selectedProduct]="selectedProduct"
                       (changedProduct)="refreshList($event)"></app-product-modal>
</ng-template>
<ng-template #productDetailTemplate let-modal>
    <app-product-detail [product]="selectedProduct"></app-product-detail>
</ng-template>

<ng-template #fileFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate *ngIf="!isUploadedFile">Upload File</h4>
        <h4 class="modal-title clamp-title" translate *ngIf="isUploadedFile">Unmatched Product</h4>
        <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="dismissModal()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="fileBusy" *ngIf="!isUploadedFile">
        <form [formGroup]="fileForm" (ngSubmit)="saveFile()">
            <div class="form-row">
                <div class="col-12 form-group">
                    <label for="pdfFile" translate>File</label>
                    <div class="custom-file" *ngIf="!file">
                        <input id="pdfFile" type="file" class="custom-file-input"
                               (change)="handleFileInput($event?.target?.files[0])">
                        <label class="custom-file-label" for="pdfFile" translate>
                            Upload file...
                        </label>
                    </div>
                    <div *ngIf="file">
                        <span class="text-break">{{file}}</span>
                        <button class="btn btn-xs btn-warning float-right" type="button"
                                (click)="handleFileRemoveModal()">
                            <i class="fa fa-times m-0 px-1"></i>
                        </button>
                    </div>
                    <val-errors controlName="file">
                        <ng-template valError="required">
                            <span translate>File is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 mt-4">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="fileForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-body" *ngIf="isUploadedFile">
        <table class="table table-sm">
            <thead>
            <tr>
                <th translate class="border-top-0">Name</th>
                <th translate class="border-top-0">EAN</th>
                <th translate class="border-top-0">Type</th>
                <th translate class="text-right border-top-0">Price</th>
                <th translate class="text-right border-top-0">Our price</th>
                <th translate class="text-right border-top-0">Dealer price</th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <tr *ngFor="let unmatchedProduct of unmatchedProducts">
                <td>{{unmatchedProduct?.product_name}}</td>
                <td>{{unmatchedProduct?.ean}}</td>
                <td>{{unmatchedProduct?.type}}</td>
                <td class="text-right">{{unmatchedProduct?.price|currency:currency}}</td>
                <td class="text-right">{{unmatchedProduct?.our_price|currency:currency}}</td>
                <td class="text-right">{{unmatchedProduct?.dealer_price|currency:currency}}</td>
            </tr>
            <tr *ngIf="unmatchedProducts?.length<=0">
                <td class="text-muted" colspan="7" translate>List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
</ng-template>
