<div class="card bg-gradient-1">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                 width="160px"
                 src="assets/img/flats/user.png">
            <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>On Going Campaigns</h1>
                <hr>
                <div class="row align-items-end justify-content-between">
                    <div class="col-lg-8 col-12"><label translate>Select Campaign</label>
                        <ng-select [items]="campaigns" bindValue="id" bindLabel="name" (change)="loadPage($event)"
                                   [(ngModel)]="campaignId"
                                   [searchable]="true" (search)="loadCampaigns($event?.term)"
                                   [loading]="questionsLoading"
                        ></ng-select>
                    </div>
                    <div class="col-12 col-lg-4 d-flex justify-content-lg-end mt-2">
                        <ul class="nav nav-pills">
                            <li class="nav-item"><a class="nav-link active text-dark border-1" href="#campaignDetails"
                                                    data-toggle="tab" id="detailsTab"
                                                    aria-expanded="true" translate>Details</a></li>
                            <li class="nav-item"><a class="nav-link text-dark" href="#campaignLeads" data-toggle="tab"
                                                    id="leads"
                                                    aria-expanded="false" (click)="loadLeads()"
                                                    [class.disabled]="!selectedCampaign" translate>Leads</a></li>
                            <li class="nav-item"><a class="nav-link text-dark" href="#campaignCalls" id="calls"
                                                    data-toggle="tab"
                                                    [class.disabled]="!selectedCampaign" (click)="loadCalls()"
                                                    aria-expanded="false" translate>Calls</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="tab-content" [ngBusy]="busy">
    <div class="tab-pane active" id="campaignDetails">
        <p class="text-muted" *ngIf="!selectedCampaign" translate>There is no active campaign.</p>
        <app-pbx-campaign-details *ngIf="selectedCampaign"
                                  (deletedCampaign)="emptyCampaign($event)"></app-pbx-campaign-details>
    </div>
    <div class="tab-pane" id="campaignLeads">
        <app-pbx-leads [leads]="leads" [leadsPagination]="leadsPagination"
                       (loadLeads)="loadLeads($event)" [isActiveCampaign]="isActiveCampaign"></app-pbx-leads>
    </div>
    <div class="tab-pane" id="campaignCalls">
        <app-pbx-calls [calls]="calls" [callsPagination]="callsPagination" (changePage)="loadCalls()"></app-pbx-calls>
    </div>
</div>
