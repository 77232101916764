<h4 translate>Filters</h4>
<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
    <form (ngSubmit)="loadOrders()">
        <div class="form-row">
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0" *show="['master','operator']">
                <div class="form-group">
                    <label translate for="companyName">Company name</label>
                    <app-retailer-typeahead (onChange)="filter.dealer_id = $event"
                                            id="companyName"></app-retailer-typeahead>
                </div>
            </div>
            <div class="col-sm-12 mt-auto mb-0" [ngClass]="isDealer ? 'col-md-4' : 'col-md-4'">
                <div class="form-group">
                    <label translate for="product">Product</label>
                    <input type="text" class="form-control" [(ngModel)]="filter.product" id="product"
                           name="product">
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="brand">Brand</label>
                    <ng-select id="brand" [(ngModel)]="filter.brand" bindValue="id" bindLabel="name" name="brand"
                               [items]="brands" (search)="loadBrands($event?.term)"
                               [loading]="brandsLoading"></ng-select>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="phoneNumber">Phone number</label>
                    <input type="text" class="form-control" [(ngModel)]="filter.phone_number" id="phoneNumber"
                           name="phoneNumber">
                </div>
            </div>
            <div class="col-md-6 col-sm-12 mt-auto mb-0" [ngClass]="isDealer ? 'col-xl-4' : 'col-xl-2'">
                <div class="form-group">
                    <label translate for="status">Status</label>
                    <ng-select name="status" [(ngModel)]="filter.status" id="status">
                        <ng-option *ngFor="let status of statuses" [value]="status.key"
                                   translate>{{status.name}}</ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="col-md-6 col-sm-12 mt-auto mb-0" [ngClass]="isDealer ? 'col-xl-4' : 'col-xl-2'">
                <div class="form-group">
                    <label translate for="type">Type</label>
                    <ng-select name="type" [(ngModel)]="filter.type" id="type">
                        <ng-option *ngFor="let type of types" [value]="type.key"
                                   translate>{{type.name}}</ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="col-xl col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <label translate for="orderId">Order ID</label>
                    <input type="text" class="form-control" id="orderId" [(ngModel)]="filter.order_id"
                           name="orderId">
                </div>
            </div>
            <div class="col-xl col-md-6 col-sm-12 mt-auto mb-0">
                <div class="form-group">
                    <button class="btn btn-info btn-block" translate type="submit">Filter
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="card">
    <div class="card-header">
        <h4 class="mb-0" translate>Orders</h4>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <ngx-spinner
                bdColor="rgba(0, 0, 0, 0.8)"
                size="default"
                color="#fff"
                type="square-jelly-box"
                name="table-orders"
                [fullScreen]="false"
        >
            <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th translate *show="['master','operator']">Company name</th>
                <th translate *show="['master','operator']">Company owner</th>
                <th translate *show="['master','operator']">Transaction ID</th>
                <th translate *show="['master','operator']">Order ID</th>
                <th translate>Product</th>
                <th translate>Brand</th>
                <th translate>Type</th>
                <th translate class="text-right">Phone number</th>
                <th translate class="text-right">Size</th>
                <th translate class="text-right">Margin</th>
                <th translate class="text-right">Date</th>
                <th translate class="text-right">Status</th>
                <th *show="['dealer']"></th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="orders"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let order of orders">
                <td *show="['master','operator']">
                    <a [routerLink]="['/dealer', order.dealer_id]" target="_blank">
                        {{order.company_name}}</a>
                </td>
                <td *show="['master','operator']">{{order.company_owner}}</td>
                <td *show="['master','operator']">
                    <span *ngFor="let payment of order.payments">{{payment.transaction_id}}</span>
                </td>
                <td *show="['master','operator']">{{order?.id}}
                </td>
                <td>
                    <span *ngFor="let orderItem of order.order_items">{{orderItem.product_name}}</span>
                </td>
                <td>
                    <span *ngFor="let orderItem of order.order_items">{{orderItem.brand_name}}</span>
                </td>
                <td>
                    <span *ngFor="let orderItem of order.order_items" translate>{{orderItem.type}}</span>
                </td>
                <td class="text-right">
                    <strong *ngIf="order.phone_number">
                        {{order.phone_number }}</strong>
                </td>
                <td class="text-right">
                    <span *ngFor="let orderItem of order.order_items"
                          translate>{{orderItem.price | currency: ' €' }}</span>
                </td>
                <td class="text-right">
                    <span *ngFor="let orderItem of order.order_items"
                          translate>{{ (orderItem.price - orderItem.dealer_price) | currency: ' €' }}</span>
                </td>
                <td class="text-right">
                    {{order.created_at | date:'dd/MM/yyyy HH:MM:ss'}}
                </td>
                <td class="text-right">
                       <span class="badge badge-success"
                             *ngIf="order.status==statuses[0].key" translate>{{statuses[0].name}}</span>
                    <span class="badge badge-danger" *ngIf="order.status==statuses[1].key"
                          translate>{{statuses[1].name}}</span>
                    <span class="badge badge-info" *ngIf="order.status==statuses[2].key"
                          translate>{{statuses[2].name}}</span>
                </td>
                <td class="text-right" *show="['dealer']">
                    <button class="btn btn-link text-info" type="button" (click)="showReceiptModal(order)"
                            *ngIf="statuses[0].key==order.status"><i class="fas fa-eye"></i>
                    </button>
                </td>
            </tr>
            <tr *ngIf="orders?.length<=0">
                <td class="text-muted" colspan="10" translate>List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="orders?.length>0">
    <span><span translate>There are</span><b> {{ this.ordersPagination.totalCount }}</b>
        {{this.ordersPagination.totalCount <= 1 ? 'order' : 'orders'|translate}}.
        <a class="link" (click)="exportList()"
        >Esporta la lista completa</a
        >
    </span>
        <ngb-pagination *ngIf="ordersPagination.pageCount>1"
                        size="sm"
                        [pageSize]="ordersPagination.perPage"
                        [maxSize]="10"
                        [(page)]="ordersPagination.currentPage"
                        [collectionSize]="ordersPagination.totalCount"
                        (pageChange)="loadOrders()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>
<ng-template #receiptFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Receipt</h4>
        <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-receipt [order]="selectedOrder"></app-receipt>
</ng-template>
