<ngx-spinner
        bdColor="rgba(51,51,51,0.76)"
        size="medium"
        color="#fff"
        type="timer"
        [fullScreen]="true"
>
    <p style="color: white"> Caricamento... </p>
</ngx-spinner>


<div class="card bg-gradient-light">
    <div class="card-body">
        <div class="row align-items-center">
            <div class="col-8">
                <h1 class="display-4 mb-0">Situazione Economica e Andamento</h1>
            </div>
        </div>
        <hr>
        <div class="row align-items-end">
            <div class="col-xl-12">
                <label>Seleziona periodo</label>
                <div class="form-group form-group-icon form-group-dark mb-0">
                    <i class="far fa-calendar-alt"></i>
                    <select class="form-control" [(ngModel)]="this.periodo">
                        <option *ngFor="let per of this.periodi" [value]="per.value">{{per.label}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="card bg-gradient-2">
            <div class="card-body pb-0">
                <h1 class="display-6" [style.color]="'#006b18'">
                    Utile della Master Group
                    <i class="fa fa-money float-right"></i>
                </h1>
                <hr>
            </div>
            <div class="card-body">
                <app-autoload-chart [url]="urlUtile"></app-autoload-chart>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="card bg-gradient-1">
            <div class="card-body pb-0">
                <h1 class="display-6" [style.color]="'#d6ecff'">
                    Fatturato della Master Group
                    <i class="fa fa-chart-area float-right"></i>
                </h1>
                <hr>
            </div>
            <div class="card-body">
                <app-autoload-chart [url]="urlFatturato"></app-autoload-chart>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="card bg-gradient-danger">
            <div class="card-body pb-0">
                <h1 class="display-6" [style.color]="'#6d000b'">
                    Provvigioni dei negozi
                    <i class="fa fa-store-alt float-right"></i>
                </h1>
                <hr>
            </div>
            <div class="card-body">
                <app-autoload-chart [url]="urlCostiDealer"></app-autoload-chart>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="card bg-gradient-orange">
            <div class="card-body pb-0">
                <h1 class="display-6" [style.color]="'#6d1f00'">
                    Provvigioni degli agenti
                    <i class="fa fa-user-tie float-right"></i>
                </h1>
                <hr>
            </div>
            <div class="card-body">
                <app-autoload-chart [url]="urlCostiAgenti"></app-autoload-chart>
            </div>
        </div>
    </div>
</div>
