import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthenticationService } from "@services/authentication.service";
import { Profile } from "@models/user";
import { TicketModalComponent } from "@app/pages/tickets/modal/ticket-modal.component";
import { DialogFieldEditComponent } from "@app/pages/partials/dialog-field-edit/dialog-field-edit.component";
import { Validators } from "@angular/forms";
import { DataService } from "@services/data.service";
import { Subscription } from "rxjs";
import { DealerService } from "@app/services/dealer.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  @ViewChild(DialogFieldEditComponent)
  private fieldEditModal: DialogFieldEditComponent;

  @ViewChild(TicketModalComponent)
  private ticketModal: TicketModalComponent;

  profile: Profile;
  busy: Subscription;

  options = {
    causaleRitenuta: [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "Y",
      "ZO",
    ],
    regimeFiscale: [
      "RF01 - Ordinario",
      "RF01 - Ordinario 20%",
      "RF02 - Contribuenti minimi",
      "RF03 - Nuove iniziative produttive",
      "RF04 - Agricoltura e attività connesse e pesca",
      "RF05 - Vendita sali e tabacchi",
      "RF06 - Commercio fiammiferi",
      "RF07 - Editoria",
      "RF08 - Gestione servizi telefonia pubblica",
      "RF09 - Rivendita documenti di trasporto pubblico e di sosta",
      "RF10 - Intrattenimenti, giochi e altre attività",
      "RF11 - Agenzie viaggi e turismo",
      "RF12 - Agriturismo",
      "RF13 - Vendite a domicilio",
      "RF14 - Rivendita beni usati e oggetti d’arte",
      "RF15 - Agenzie di vendite all’asta di oggetti d’arte",
      "RF16 - IVA per cassa P.A.",
      "RF17 - IVA per cassa",
      "RF18 - Altro",
      "RF19 - Regime forfettario",
    ],
    tipoRitenuta: [
      "RT01 - Persone Fisiche",
      "RT02 - Persone Giuridiche",
      "Nessuna Ritenuta",
    ],
    tipoSocieta: [
      "Societa-Associazione",
      "Ditta Individuale",
      "Libero Professionista",
    ],
  };

  fileName = {
    inside_pic_file: "",
  };

  get me() {
    return this.authenticationService.currentUserValue.profile;
  }

  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private service: DealerService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.authenticationService.currentUser.subscribe(
      (user) => (this.profile = user.profile)
    );
  }

  handleFileInput(files: FileList, field: string) {
    if (files && files.item(0)) {
      const file = files.item(0);
      const isImage = file.type.includes("image") ? true : false;
      if (!isImage) {
        this.toastr.error("Tipo di file non supportato", "Errore");
        return;
      } else {
        this.fileName[field] = file.name;

        const formData = new FormData();
        formData.append(field, file);

        this.busy = this.service
          .updateDealer(this.me.id, formData)
          .subscribe((data) => {});
      }
    }
  }

  requestBrandCode(brand: string) {
    console.log("Hai richiesto un nuovo codice " + brand);

    const config = {
      titolo: "Richesta affiliazione ",
      categoria: "",
      priorita: "Alta",
      problemLabel: "Note",
    };

    switch (brand) {
      case "tiscali":
        config.titolo += "Tiscali";
        config.categoria = "Tiscali";
        break;
      case "kena":
        config.titolo += "Kena";
        config.categoria = "Kena Mobile";
        break;
      case "eni":
        config.titolo += "Eni";
        config.categoria = "Eni gas e luce";
        break;
      case "sky":
        config.titolo += "Sky";
        config.categoria = "Sky";
        break;
      case "tim":
        config.titolo += "Tim";
        config.categoria = "Tim";
        break;
    }

    // Open modal
    this.ticketModal.open(config);
  }

  editProfileField(
    label: string | string[],
    key: string | string[],
    value: string | string[],
    validator: any[] | any[][],
    selectValues: any[] | any[][] = []
  ) {
    this.fieldEditModal.open(label, key, value, validator, selectValues);
  }

  onFieldChanged(changedData: object) {
    // TODO @luciano @potito, salvare nel crm i campi del profilo aggiornati
    console.log(changedData);
    this.busy = this.dataService
      .post("/edit-profilo-dealer", changedData)
      .subscribe((data) => {
      });
  }

  requiredValidator() {
    return Validators.required;
  }

  pivaValidator() {
    return Validators.required;
    // return Validators.pattern(AppConfig.patternPIVA);
  }

  emailValidator() {
    return Validators.email;
  }

  phoneValidator() {
    return Validators.required;
    // return Validators.pattern(AppConfig.patternPHONE);
  }

  ibanValidator() {
    return Validators.required;
    // return Validators.pattern(AppConfig.patternIBAN);
  }

  capValidator() {
    return Validators.required;
    // return Validators.pattern(AppConfig.patternCAP);
  }
}
