import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Profile} from '@models/user';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import * as moment from 'moment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CampaignService} from '@services/campaign.service';
import {Moment} from 'moment';
import {Rule} from '@models/rule';
import {Prize} from '@models/prize';
import {Campaign} from '@models/campaign';
import {Affiliation} from '@models/affiliation';

@Component({
    selector: 'app-campaign-modal',
    templateUrl: './campaign-modal.component.html',
    styleUrls: ['./campaign-modal.component.css']
})
export class CampaignModalComponent implements OnInit {
    @Input() profile: Profile;
    @Input() modalType: string;
    @Input() campaigns = [];
    @Input() selectedIndex: number;
    @Input() selectedItem: any;
    busy: any;
    campaignForm = new FormGroup({
        title: new FormControl('', Validators.required),
        date_range: new FormControl('', Validators.required),
    });
    rulesForm = new FormGroup({
        vendor_segment_id: new FormControl('', Validators.required),
        offer: new FormControl('', Validators.required),
        count: new FormControl('', Validators.required),
        points: new FormControl('', Validators.required),
    });
    prizesForm = new FormGroup({
        title: new FormControl('', Validators.required),
        required_points: new FormControl('', Validators.required),
        image_name: new FormControl(''),
        description: new FormControl(''),
    });
    vendorSegments = [];
    offers = [];
    modalTypes = ['campaign', 'rules', 'prizes'];
    startDate: Moment;
    endDate: Moment;
    submittedFile: File;
    submittedFileName: string;
    constructor(private dateRangePickerConfig: DaterangepickerConfig,
                private modalService: NgbModal,
                private campaignService: CampaignService) {
    }

    ngOnInit(): void {
        this.loadVendorSegments();
        if (this.selectedItem) {
            this.loadData();
        }
    }
    loadData() {
        if (this.modalType === this.modalTypes[0]) {
            this.campaignForm.get('title').setValue(this.selectedItem.title);
            this.setDateRange(this.selectedItem.start_date, this.selectedItem.end_date);
        } else if (this.modalType === this.modalTypes[1]) {
            this.rulesForm.setValue({
                'vendor_segment_id': this.selectedItem.vendor_segment_id,
                'offer': this.selectedItem.offer,
                'count': this.selectedItem.count,
                'points': this.selectedItem.points,
            });
        } else if (this.modalType === this.modalTypes[2]) {
            this.prizesForm.setValue({
                'title': this.selectedItem.title,
                'required_points': this.selectedItem.required_points,
                'image_name': this.selectedItem.image_name,
                'description': this.selectedItem.description,
            });
        }
    }

    saveCampaign() {
        if (this.campaignForm.invalid) {
            return;
        } else {
            const apiFormat = this.dateRangePickerConfig.settings.locale.apiFormat;
            const body = {
                title: this.campaignForm.value.title,
            };
            body['start_date'] = moment(this.startDate).format(apiFormat);
            body['end_date'] = moment(this.endDate).format(apiFormat);
            if (this.selectedItem) {
                return this.editCampaign(body);
            }
            return this.addCampaign(body);
        }
    }

    editCampaign(body) {
        this.busy = this.campaignService.putCampaign(this.selectedItem.id, body).subscribe((response) => {
                this.campaigns[this.selectedIndex] = response;
                this.handleSuccess('Changed Campaign!');
            },
            err => {
                this.handleError('Campaign with that validity period already exists.');
            });
    }

    addCampaign(body) {
        body['operator_id'] = this.profile.id;
        this.busy = this.campaignService.postCampaign(body).subscribe((response) => {
                this.campaigns.unshift(response);
                this.handleSuccess('Created Campaign!');
            },
            err => {
                this.handleError('Campaign with that validity period already exists.');
            });
    }

    saveRule() {
        if (this.rulesForm.invalid) {
            return;
        } else {
            if (this.selectedItem) {
                return this.editRule();
            }
            return this.addRule();
        }
    }

    editRule() {
        this.busy = this.campaignService.putRule(this.selectedItem.id, this.rulesForm.value).subscribe((response: Rule) => {
            this.loadNewRule(response);
            this.handleSuccess('Changed Rule!');
        }, err => {
            this.handleError(err.message);
        });
    }

    addRule() {
        this.rulesForm.value['campaign_id'] = this.campaigns[this.selectedIndex].id;
        this.busy = this.campaignService.postRule(this.rulesForm.value).subscribe((response: Rule) => {
            this.loadNewRule(response);
            this.handleSuccess('Created Rule!');
        }, err => {
            this.handleError(err.message);
        });
    }

    loadNewRule(response: Rule) {
        if (this.campaigns[this.selectedIndex]?.segments) {
            const segment = this.campaigns[this.selectedIndex]?.segments.data.find(data => data.id === response.vendor_segment_id);
            if (segment) {
                if (this.selectedItem) {
                    const index = segment.rules.findIndex(rule => rule.id === response.id);
                    segment.rules[index] = response;
                } else {
                    segment.rules.unshift(response);
                }
            } else {
                const vendorSegment = this.vendorSegments.find(data => data.id === response.vendor_segment_id);
                this.campaigns[this.selectedIndex].segments.data.unshift({
                    id: vendorSegment.id,
                    title: vendorSegment.title,
                    slug: vendorSegment.slug,
                    rules: [response]
                });
            }
        } else {
            const vendorSegment = this.vendorSegments.find(data => data.id === response.vendor_segment_id);
            this.campaigns[this.selectedIndex].segments.data = [{
                id: vendorSegment.id,
                title: vendorSegment.title,
                slug: vendorSegment.slug,
                rules: [response]
            }];
        }
    }

    savePrize() {
        if (this.prizesForm.invalid) {
            return;
        } else {
            const prize = new Prize();
            prize.title = this.prizesForm.value['title'];
            prize.required_points = this.prizesForm.value['required_points'];
            prize.image_name = this.prizesForm.value['image_name'];
            prize.file = this.submittedFile;
            prize.description = this.prizesForm.value['description'];
            prize.campaign_id = this.campaigns[this.selectedIndex].id;
            if (this.selectedItem) {
                return this.editPrize(prize);
            }
            return this.addPrize(prize);
        }
    }

    addPrize(prize) {
        this.busy = this.campaignService.postPrize(prize).subscribe((response: Prize) => {
            this.campaigns[this.selectedIndex].prizes.data.unshift(response);
            this.handleSuccess('Created Prize!');
        }, err => {
            this.handleError(err.message);
        });
    }

    editPrize(prize) {
        this.busy = this.campaignService.updatePrize(this.selectedItem.id, prize).subscribe((response: Prize) => {
            const index = this.campaigns[this.selectedIndex].prizes.data.findIndex(prize => prize.id === response.id);
            this.campaigns[this.selectedIndex].prizes.data[index] = response;
            this.handleSuccess('Changed Prize!');
        }, err => {
            this.handleError(err.message);
        });
    }

    setDateRange(start: any, end: any) {
        this.startDate = start;
        this.endDate = end;
        const from = moment(start).format(this.dateRangePickerConfig.settings.locale.format);
        const to = moment(end).format(this.dateRangePickerConfig.settings.locale.format);
        const dateRange = from + this.dateRangePickerConfig.settings.locale.separator + to;
        this.campaignForm.get('date_range').setValue(dateRange);
    }

    loadVendorSegments() {
        if (this.modalType === this.modalTypes[1] && this.vendorSegments?.length <= 0) {
            this.campaignService.getVendorSegments().subscribe((response) => {
                this.vendorSegments = response;
            });
        }
    }

    loadOffers(id) {
        if (id) {
            this.rulesForm.get('offer').setValue('');
            const startDate = this.campaigns[this.selectedIndex].start_date;
            const endDate = this.campaigns[this.selectedIndex].end_date;
            const campaignId = this.campaigns[this.selectedIndex].id;
            return this.campaignService.getOffers(id, startDate, endDate, campaignId).subscribe((response) => {
                this.offers = response;
            }, err => {
                this.offers = [];
            });
        }
    }

    handleFileInput(file: File) {
        this.submittedFile = file;
        this.submittedFileName = file.name;
        this.prizesForm.get('image_name').setValue(file.name);
    }

    handleFileRemoveModal() {
        this.prizesForm.get('image_name').setValue('');
        this.submittedFile = null;
        this.submittedFileName = '';
    }

    get imageName() {
        return this.prizesForm.get('image_name').value;
    }

    handleError(error) {
        this.campaignService.errorAlert(error);
        this.modalService.dismissAll();
    }

    handleSuccess(message) {
        this.campaignService.successAlert(message);
        this.selectedIndex = null;
        this.modalService.dismissAll();
    }
}
