import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MailService} from '@services/mail.service';
import * as moment from 'moment';
import {MandrillMail} from '@models/mandrill-mail';
import {MetaPagination} from '@models/meta-pagination';
import {WassengerMessage} from '@models/wassenger-message';

@Component({
    selector: 'app-master-wapp-card',
    templateUrl: './master-wapp-card.component.html',
    styleUrls: ['./master-wapp-card.component.css']
})
export class MasterWappCardComponent implements OnInit {
    @Input()
    title: string;
    @Input()
    description: string;
    @Input()
    image: string;
    @Input()
    details = {
        today: 0,
        week: 0,
        month: 0
    };
    @Input()
    loading = true;
    @Input()
    slug: string;
    @Input()
    date: string;

    mails: WassengerMessage[];
    pagination: MetaPagination = new MetaPagination({currentPage: 1, perPage: 10});

    loadingModal = true;
    previewReady = false;
    message: WassengerMessage;
    mode: any;

    constructor(private modalService: NgbModal,
                private service: MailService) {
    }

    ngOnInit(): void {

    }

    open(content, mode) {
        this.mode = mode;
        this.modalService.open(content, {size: 'xl'});
        this.refresh();
    }

    refresh() {
        let from = moment(this.date, 'DD-MM-YYYY');
        let to = moment(this.date, 'DD-MM-YYYY');

        switch (this.mode) {
            case 'week':
                from = from.startOf('week');
                to = to.endOf('week');
                break;
            case 'month':
                from = from.startOf('month');
                to = to.endOf('month');
                break;
        }

        let fromDate = from.format('YYYY-MM-DD');
        let toDate = to.format('YYYY-MM-DD');

        this.loadingModal = true;
        this.service.getWassengerDetail(this.slug, fromDate, toDate, this.pagination.currentPage)
            .subscribe(data => {
                this.loadingModal = false;
                this.mails = data.data;
                this.pagination = data._meta;
            })
    }

    viewMail(modal, message: WassengerMessage) {
        this.modalService.open(modal);
        this.message = message;
    }
}
