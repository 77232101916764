import { Component, OnInit, ViewChild } from "@angular/core";
import { DataService } from "@services/data.service";
import { Subscription } from "rxjs";
import {
  TableColumn,
  TableColumnType,
} from "@app/pages/partials/table/table.component";
import { Ricarica } from "@models/ricarica";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "@services/alert.service";
import { AuthenticationService } from "@app/services/authentication.service";
import { RechargeService } from "@app/services/recharge.service";
import { Pagination } from "@app/models/pagination";
import { Recharge } from "@app/models/recharge";
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-ricariche-index",
  templateUrl: "./ricariche-index.component.html",
  styleUrls: ["./ricariche-index.component.scss"],
})
export class RicaricheIndexComponent implements OnInit {
  pagination = new Pagination({ page: 1, pageSize: 20 });
  recharges: Recharge[];

  filter = {
    id_dealer: "",
    vendor: "",
    status: "In Attesa",
    number: "",
  };

  @ViewChild("rechargeFormTemplate") private rechargeFormTemplate;
  selectedRecharge: Recharge;
  rechargeForm: FormGroup;
  rechargeModalTitle: string;
  rechargeFormModal: NgbModalRef;

  fileName: string;

  form: FormGroup;
  tagliTim = [];

  get me() {
    return this.auth.currentUserValue.profile;
  }

  constructor(
    private auth: AuthenticationService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private service: RechargeService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {
    for (let i = 4; i <= 100; i++) {
      this.tagliTim.push(i);
    }
  }

  ngOnInit() {
    this.refresh();

    this.rechargeForm = this.formBuilder.group({
      stato: [null, [Validators.required]],
      receipt: [null, []],
    });

    this.form = this.formBuilder.group({
      id_operatore: [null, [Validators.required]],
      importo: [null, [Validators.required]],
      numero: [
        null,
        [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(10),
        ],
      ],
    });
  }

  refresh() {
    this.spinner.show("table-recharges");

    let params = {
      page: this.pagination.page,
      expand: "retailer,vendor",
    };

    if (this.me.role === "operator") {
      Object.keys(this.filter).forEach((k) => {
        if (
          this.filter[k] &&
          this.filter[k] !== null &&
          this.filter[k] !== "" &&
          this.filter[k] !== undefined
        ) {
          if (Array.isArray(this.filter[k])) {
            if (this.filter[k].length > 0) {
              params[k] = this.filter[k].join(",");
            }
          } else {
            params[k] = this.filter[k];
          }
        }
      });
    }

    this.service.list(params).subscribe((data) => {
      this.recharges = new Recharge().fromArray(data.rows);
      this.pagination = new Pagination(data.pagination);
      this.spinner.hide("table-recharges");
    });
  }

  handleFileInput(files: FileList) {
    if (files && files.item(0)) {
      const logoFile = files.item(0);

      this.fileName = logoFile.name;
      this.selectedRecharge.receipt = logoFile;
    }
  }

  showUpdateRechargeModal(recharge: Recharge) {
    this.selectedRecharge = new Recharge(recharge);
    this.rechargeModalTitle = `Gestisci la ricarica del num. ${recharge.numero}`;
    this.rechargeFormModal = this.modalService.open(this.rechargeFormTemplate, {
      size: "lg",
    });
    this.rechargeFormModal.result.then((res) => {}).catch((res) => {});
  }

  saveRecharge(recharge: Recharge = null) {
    this.spinner.show("form-recharge");
    const formData = new FormData();
    const properties = [
      "stato",
      "receipt",
      "id_operatore",
      "numero",
      "importo",
    ];
    properties.forEach((prop) => {
      if (recharge && recharge[prop]) {
        formData.append(prop, recharge[prop]);
      } else {
        if (this.form.controls[prop]) {
          formData.append(prop, this.form.controls[prop].value);
        }
      }
    });

    let isNew = recharge ? false : true;
    let request = isNew
      ? this.service.saveRecharge(formData)
      : this.service.updateRecharge(recharge.id, formData);

    request.subscribe(
      (data: any) => {
        this.spinner.hide("form-recharge");
        if (!recharge) {
          this.auth.ping();
        }
        else {
          this.rechargeFormModal.close();
        }
        this.refresh();
        this.toastr.success(
          `Ricarica ${isNew ? "aggiunta" : "aggiornata"} con successo`,
          "Notifica"
        );
      },
      (err: any) => {
        this.spinner.hide("form-recharge");
        this.toastr.error(
          `Si è verificato un errore durante la ${
            isNew ? "creazione" : "aggiornamento"
          } della ricarica: ${err}`,
          "Errore"
        );
      }
    );
  }
}
