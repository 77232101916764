import {AgenteService} from '@services/agente.service';
import {Router} from '@angular/router';
import {Component, OnInit, ViewChild} from '@angular/core';
import {
    AbstractControl,
    AsyncValidatorFn,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import {Dealer, ShopAccess} from '@app/models/dealer';
import {Pagination} from '@app/models/pagination';
import {Vendor} from '@app/models/vendor';
import {AuthenticationService} from '@app/services/authentication.service';
import {ConfirmOptionsService} from '@app/services/confirm-options.service';
import {DealerService} from '@app/services/dealer.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {CustomValidators} from 'ngx-custom-validators';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import * as XLSX from 'xlsx';
import {NgxCsvParser} from 'ngx-csv-parser';
import {MasterAgentService} from '@services/master-agent.service';
import {OperatorUserService} from '@services/operator-user.service';

@Component({
    selector: 'app-retailer-management',
    templateUrl: './retailer-management.component.html',
    styleUrls: ['./retailer-management.component.scss'],
})
export class RetailerManagementComponent implements OnInit {
    busy: Subscription;
    pagination = new Pagination({page: 1, pageSize: 10});
    dealers: Dealer[];
    selectedDealersForCampaign = [];
    mode = 'retailer';

    enabledVendors: Vendor[];

    collapse = {
        rappresentante: true,
        sede: true,
        altro: true,
        fatturazione: true,
        operators: [],
        azienda: true,
        kdricariche: true
    };

    filter = {
        deleted: 0,
        name: '',
        owner_name: '',
        owner_surname: '',
        vat: '',
        city: '',
        province: '',
        state: '',
        phone: '',
        mobile: '',
        created_range: '',
        created_from: '',
        created_to: '',
        vendors: [],
        roles: 'dealer'
    };

    fields = [
        {
            key: 'id',
            label: 'ID',
            checked: true,
        },
        {
            key: 'company_name',
            label: 'Ragione Sociale',
            checked: true,
        },
        {
            key: 'piva',
            label: 'Partita Iva',
            checked: true,
        },
        {
            key: 'codfiscale',
            label: 'Cod. Fiscale',
            checked: false,
        },
        {
            key: 'company_owner',
            label: 'Rappresentante',
            checked: true,
        },
        {
            key: 'cellulare',
            label: 'Cellulare',
            checked: true,
        },
        {
            key: 'telefono',
            label: 'Telefono',
            checked: false,
        },
        {
            key: 'email',
            label: 'E-Mail',
            checked: false,
        },
        {
            key: 'pec',
            label: 'PEC',
            checked: false,
        },
        {
            key: 'iban',
            label: 'IBAN',
            checked: false,
        },
        {
            key: 'created',
            label: 'Data Inserimento',
            checked: true,
        },
        {
            key: 'username',
            label: 'Username',
            checked: false,
        },
        {
            key: 'indirizzo',
            label: 'Indirizzo',
            checked: false,
        },
    ];

    expand = [
        {
            key: 'city',
            label: 'Comune',
            checked: true,
        },
        {
            key: 'province',
            label: 'Provincia',
            checked: true,
        },
        {
            key: 'state',
            label: 'Regione',
            checked: true,
        },
        {
            key: 'enabledVendors',
            label: 'Operatori',
            checked: true,
        },
        {
            key: 'vendors',
            label: 'Codici Diretti',
            checked: false,
        },
        {
            key: 'agents',
            label: 'Agenti',
            checked: false,
        },
    ];

    options = {
        causaleRitenuta: [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'Y',
            'ZO',
        ],
        regimeFiscale: [
            'RF01 - Ordinario',
            'RF01 - Ordinario 20%',
            'RF02 - Contribuenti minimi',
            'RF03 - Nuove iniziative produttive',
            'RF04 - Agricoltura e attività connesse e pesca',
            'RF05 - Vendita sali e tabacchi',
            'RF06 - Commercio fiammiferi',
            'RF07 - Editoria',
            'RF08 - Gestione servizi telefonia pubblica',
            'RF09 - Rivendita documenti di trasporto pubblico e di sosta',
            'RF10 - Intrattenimenti, giochi e altre attività',
            'RF11 - Agenzie viaggi e turismo',
            'RF12 - Agriturismo',
            'RF13 - Vendite a domicilio',
            'RF14 - Rivendita beni usati e oggetti d’arte',
            'RF15 - Agenzie di vendite all’asta di oggetti d’arte',
            'RF16 - IVA per cassa P.A.',
            'RF17 - IVA per cassa',
            'RF18 - Altro',
            'RF19 - Regime forfettario',
        ],
        tipoRitenuta: [
            'RT01 - Persone Fisiche',
            'RT02 - Persone Giuridiche',
            'Nessuna Ritenuta',
        ],
        tipoSocieta: [
            'Societa-Associazione',
            'Ditta Individuale',
            'Libero Professionista',
        ],
    };

    @ViewChild('dealerFormTemplate') private dealerFormTemplate;
    @ViewChild('dealerImportTemplate') private dealerImportTemplate;
    @ViewChild('campaignFormTemplate') private campaignFormTemplate;
    dealerForm: FormGroup;
    dealerModalTitle: string;
    dealerFormModal: NgbModalRef;
    dealerImportModal: NgbModalRef;
    campaignModal: NgbModalRef;
    selectedDealer: Dealer = new Dealer();
    shopAccess: ShopAccess = new ShopAccess();
    loadingImport = false;
    selectAll: boolean;
    regexCodFiscale = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;

    dealerFormInvalids = [];
    dealerFormSectionErrors = {
        rappresentante: false,
        sede: false,
        altro: false,
        fatturazione: false,
        operators: [],
        azienda: false,
        kdricariche: false
    };
    dealerFormSubscription: Subscription;

    get activeFields() {
        return this.fields.reduce(function(map, obj) {
            map[obj.key] = obj.checked;
            return map;
        }, {});
    }

    get activeExpand() {
        return this.expand.reduce(function(map, obj) {
            map[obj.key] = obj.checked;
            return map;
        }, {});
    }

    get dealerConfirmDeleteOptions() {
        return new ConfirmOptionsService({
            title: 'Disabilita questo negozio',
            text: 'Sei sicuro di voler disabilitare questo negozio?',
        });
    }

    get dealerConfirmRestoreOptions() {
        return new ConfirmOptionsService({
            title: 'Riabilita questo negozio',
            text: 'Sei sicuro di voler riabilitare questo negozio?',
        });
    }

    service: any;
    isDealersList = false;
    selectedFilters;
    dealerRole = 'dealer';
    agentRole = 'agent';
    masterAgentRole = 'masteragent';
    operatorRole = 'operator';
    submasterRole = 'submaster';

    constructor(
        private router: Router,
        private dealerService: DealerService,
        private agentService: AgenteService,
        private operatorService: OperatorUserService,
        private masterAgentService: MasterAgentService,
        private auth: AuthenticationService,
        private ngxCsvParser: NgxCsvParser,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private modalService: NgbModal,
        private dateRangePickerOptions: DaterangepickerConfig,
        private spinner: NgxSpinnerService
    ) {
        if (this.router.url === '/area-managers/list') {
            this.service = this.agentService;
            this.isDealersList = false;
            this.filter.roles = this.agentRole;
        } else {
            this.service = this.dealerService;
            this.isDealersList = true;
        }
    }

    resetFilters() {
        this.filter = {
            deleted: 0,
            name: '',
            owner_name: '',
            owner_surname: '',
            vat: '',
            city: '',
            province: '',
            state: '',
            phone: '',
            mobile: '',
            created_range: '',
            created_from: '',
            created_to: '',
            vendors: [],
            roles: this.dealerRole
        };
    }

    getCodeFromRetailer(dealer: Dealer, vendor: Vendor) {
        if (dealer.vendors) {
            let agent = dealer.vendors.find(
                (op) => op.id_operator.toString() === vendor.id.toString()
            );

            if (agent) {
                return agent.code;
            }
        }

        return '-';
    }

    getAgentFromRetailer(dealer: Dealer, vendor: Vendor) {
        if (dealer.agents) {
            let agent = dealer.agents.find(
                (op) => op.id_operator.toString() === vendor.id.toString()
            );

            if (agent) {
                return agent.agent_name;
            }
        }

        return '-';
    }

    usernameUnique(service: DealerService): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            return service.isUsernameAvailable(control.value).pipe(
                debounceTime(300),
                distinctUntilChanged(),
                map((result: boolean) => (result ? null : {invalidAsync: true}))
            );
        };
    }

    vatUnique(service: DealerService): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            return service.isVatAvalaible(control.value).pipe(
                debounceTime(300),
                distinctUntilChanged(),
                map((result: boolean) => (result ? null : {invalidAsync: true}))
            );
        };
    }

    ngOnInit() {
        this.refreshList();

        this.prepareDealerForm();

        this.getEnabledVendors().subscribe((data) => (this.enabledVendors = data));
    }

    getEnabledVendors() {
        return this.service
            .getDealer(`${this.auth.currentUserValue.profile.id}`, {
                expand: 'enabledVendors',
            })
            .pipe(
                map((response: any) => {
                    return new Vendor().fromArray(response.enabledVendors);
                })
            );
    }

    refreshList(isFilterBtn: boolean = false) {
        this.spinner.show('table-dealers');
        let fields = this.fields
            .filter((item) => item.checked)
            .map((item) => item.key);

        let expand = this.expand
            .filter((item) => item.checked)
            .map((item) => item.key);

        fields.push('deleted');

        let params = {
            fields: fields.join(','),
            expand: expand.join(','),
            sort: '-id',
            page: this.pagination.page,
        };

        Object.keys(this.filter).forEach((k) => {
            if (
                this.filter[k] &&
                this.filter[k] !== null &&
                this.filter[k] !== '' &&
                this.filter[k] !== undefined
            ) {
                if (Array.isArray(this.filter[k])) {
                    if (this.filter[k].length > 0) {
                        params[k] = this.filter[k].join(',');
                    }
                } else {
                    params[k] = this.filter[k];
                }
            }
        });
        if (this.filter.roles === this.dealerRole) {
            this.service = this.dealerService;
            this.isDealersList = true;
        }
        if (this.filter.roles === this.operatorRole) {
            this.service = this.operatorService;
            this.isDealersList = false;
        }
        if (this.filter.roles === this.agentRole) {
            this.service = this.agentService;
            this.isDealersList = false;
        }
        if (this.filter.roles === this.masterAgentRole) {
            this.service = this.masterAgentService;
            this.isDealersList = false;
        }
        this.service.list(params).subscribe((data) => {
            this.dealers = new Dealer().fromArray(data.rows);
            this.pagination = new Pagination(data.pagination);
            this.dealerFilters(params, data.pagination.totalCount);
            this.loadSelectedDealers(isFilterBtn);
            this.spinner.hide('table-dealers');
        });
    }

    dealerFilters(params, totalCount: number) {
        this.selectedFilters = params;
        delete this.selectedFilters?.expand;
        delete this.selectedFilters?.fields;
        delete this.selectedFilters?.page;
        delete this.selectedFilters?.sort;
        this.selectedFilters.totalCount = totalCount;
    }

    loadSelectedDealers(isFilterBtn) {
        if (this.selectedDealersForCampaign?.length >= 0 && this.selectedDealersForCampaign[0] === 'all') {
            if (isFilterBtn) {
                this.selectAll = false;
                this.selectedDealersForCampaign = [];
                this.checkAllDealers(false);
            } else {
                this.checkAllDealers();
            }
        }
    }

    setRangeCreated(from: Moment, to: Moment) {
        const selectedDateRange = this.getDateRangeString(from, to);
        this.filter.created_from = moment(from).format('YYYY-MM-DD');
        this.filter.created_to = moment(to).format('YYYY-MM-DD');
        this.filter.created_range =
            selectedDateRange.from + ' - ' + selectedDateRange.to;
    }

    private getDateRangeString(startDate: any, endDate: any) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        const start = moment(startDate);
        const end = moment(endDate);

        return {
            from: start.format(localDateFormat),
            to: end.format(localDateFormat),
        };
    }

    showCreateDealerModal() {
        if (this.dealerFormSubscription) {
            this.dealerFormSubscription.unsubscribe();
        }
        this.selectedDealer = new Dealer();
        this.prepareDealerForm();
        this.dealerModalTitle = 'Nuovo Negozio';
        this.dealerFormModal = this.modalService.open(this.dealerFormTemplate, {
            size: 'xl',
        });
        this.dealerFormModal.result.then((res) => {
        }).catch((res) => {
        });

        this.dealerForm.patchValue(this.selectedDealer);
        this.dealerFormSubscription = this.dealerForm.valueChanges.subscribe((data) => {
            this.prepareDealerFormErrors();
        });
    }

    showUpdateDealerModal(dealer: Dealer) {
        if (this.dealerFormSubscription) {
            this.dealerFormSubscription.unsubscribe();
        }
        this.spinner.show('table-dealers');
        let fields = Object.keys(this.dealerForm.controls);
        fields.push('id');
        fields.push('tipo_ritenuta');
        fields.push('stato_freeinvoice');
        fields.push('pec');
        fields.push('causale_ritenuta');
        fields.push('regime_fiscale');
        fields.push('tipologia_societa');
        fields.push('role');
        fields.push('car_code');
        this.service
            .getDealer(dealer.id, {
                fields: fields.join(','),
                expand: 'vendors,fi_api_key,shopAccesses',
            })
            .subscribe((data) => {
                this.selectedDealer = new Dealer(data);
                this.selectedDealer.isNew = false;

                for (let i = 0; i < this.selectedDealer.vendors.length; i++) {
                    this.collapse.operators[i] = true;
                }

                this.prepareDealerForm();
                this.dealerModalTitle = `Aggiorna negozio ${dealer.company_name}`;
                this.spinner.hide('table-dealers');
                this.dealerFormModal = this.modalService.open(this.dealerFormTemplate, {
                    size: 'xl',
                });

                this.dealerForm.patchValue(this.selectedDealer);
                this.dealerFormSubscription = this.dealerForm.valueChanges.subscribe((data) => {
                    this.prepareDealerFormErrors();
                });

            });
    }

    prepareDealerFormErrors() {
        this.dealerFormInvalids = [];
        this.dealerForm.markAllAsTouched();

        const controls = this.dealerForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                this.dealerFormInvalids.push(name);
            }
        }

        this.dealerFormSectionErrors.azienda = ['ragsociale', 'piva', 'iban', 'codice_sdi'].some(r => this.dealerFormInvalids.includes(r));
        this.dealerFormSectionErrors.rappresentante = ['nome', 'cognome', 'codfiscale'].some(r => this.dealerFormInvalids.includes(r));
        this.dealerFormSectionErrors.sede = ['provincia', 'localita', 'indirizzo', 'cap'].some(r => this.dealerFormInvalids.includes(r));
        this.dealerFormSectionErrors.altro = ['cellulare', 'telefono', 'email'].some(r => this.dealerFormInvalids.includes(r));
    }

    saveDealer(dealer: Dealer) {
        let isNew = dealer.id ? false : true;
        let request = isNew
            ? this.service.saveDealer(this.dealerForm.value)
            : this.service.updateDealer(dealer.id, this.dealerForm.value);

        this.busy = request.subscribe(
            (data: any) => {
                let savedDealer = new Dealer(data);
                savedDealer.isNew = isNew;

                this.refreshList();
                // this.updateLocalStateDealer(savedDealer);
                this.dealerFormModal.close();
                this.toastr.success(
                    `Negozio ${isNew ? 'creato' : 'aggiornato'} con successo`,
                    'Notifica'
                );
            },
            (err: any) =>
                this.toastr.error(
                    `Si è verificato un errore durante la ${
                        isNew ? 'creazione' : 'aggiornamento'
                    } del negozio`,
                    'Errore'
                )
        );
    }

    deleteDealer(dealer: Dealer) {
        this.busy = this.service.deleteDealer(dealer.id).subscribe(
            (data: any) => {
                this.refreshList();
                this.toastr.success('Negozio eliminato con successo', 'Notifica');
            },
            (err: any) =>
                this.toastr.error(
                    'Si è verificato un errore durante l\'eliminazione del negozio',
                    'Errore'
                )
        );
    }

    restoreDealer(dealer: Dealer) {
        this.busy = this.service.restoreDealer(dealer.id).subscribe(
            (data: any) => {
                this.refreshList();
                this.toastr.success('Negozio riabilitato con successo', 'Notifica');
            },
            (err: any) =>
                this.toastr.error(
                    'Si è verificato un errore durante la riabilitazione del negozio',
                    'Errore'
                )
        );
    }

    updateLocalStateDealer(dealer: Dealer) {
        for (let i in this.dealers) {
            if (this.dealers[i].id == dealer.id) {
                this.dealers[i] = dealer;
                return;
            }
        }
        let newDealer = this.dealers.find((c) => {
            return c.id !== dealer.id;
        });
        if (newDealer || this.dealers.length === 0) {
            this.dealers.push(dealer);
        }
    }

    prepareDealerForm() {
        if (this.selectedDealer.isNew) {
            this.dealerForm = this.formBuilder.group({
                username: [
                    null,
                    [
                        Validators.required,
                        Validators.pattern(/^\S*$/)
                    ],
                    this.usernameUnique(this.service),
                ],
                ragsociale: [null, [Validators.required]],
                provincia: [null, [Validators.required]],
                localita: [null, [Validators.required]],
                nome: [null, [Validators.required]],
                cognome: [null, [Validators.required]],
                piva: [
                    null,
                    [
                        Validators.required,
                        Validators.minLength(11),
                        Validators.maxLength(11),
                    ],
                    this.vatUnique(this.service),
                ],
                codfiscale: [
                    null,
                    [
                        Validators.required,
                        Validators.minLength(16),
                        Validators.maxLength(16),
                        Validators.pattern(this.regexCodFiscale),
                    ],
                ],
                indirizzo: [null, [Validators.required]],
                cap: [
                    null,
                    [
                        Validators.required,
                        CustomValidators.number,
                        Validators.minLength(5),
                        Validators.maxLength(5),
                    ],
                ],
                email: [null, [Validators.required, Validators.email]],
                iban: [null, [Validators.required]],
                codice_sdi: [
                    null,
                    [
                        Validators.required,
                        Validators.minLength(7),
                        Validators.maxLength(7),
                    ],
                ],
                note: [null, []],
                telefono: [
                    null,
                    [
                        Validators.required,
                        CustomValidators.number,
                        Validators.minLength(9),
                        Validators.maxLength(11),
                    ],
                ],
                cellulare: [
                    null,
                    [
                        Validators.required,
                        CustomValidators.number,
                        Validators.minLength(9),
                        Validators.maxLength(11),
                    ],
                ],
            });
        } else {
            this.dealerForm = this.formBuilder.group({
                vendors: this.formBuilder.array([]),
                shopAccess: this.formBuilder.group({
                    from: [null, []],
                    to: [null, []],
                }),
                role: [null, [Validators.required]],
                car_code: [null, []],
                ragsociale: [null, [Validators.required]],
                provincia: [null, [Validators.required]],
                localita: [null, [Validators.required]],
                nome: [null, [Validators.required]],
                cognome: [null, [Validators.required]],
                piva: [
                    null,
                    [
                        Validators.required,
                        Validators.minLength(11),
                        Validators.maxLength(11),
                    ],
                ],
                codfiscale: [
                    null,
                    [
                        Validators.required,
                        Validators.minLength(16),
                        Validators.maxLength(16),
                        Validators.pattern(this.regexCodFiscale),
                    ],
                ],
                indirizzo: [null, [Validators.required]],
                cap: [
                    null,
                    [
                        Validators.required,
                        CustomValidators.number,
                        Validators.minLength(5),
                        Validators.maxLength(5),
                    ],
                ],
                email: [null, [Validators.required, Validators.email]],
                iban: [null, [Validators.required]],
                codice_sdi: [
                    null,
                    [
                        Validators.required,
                        Validators.minLength(7),
                        Validators.maxLength(7),
                    ],
                ],
                note: [null, []],
                tipo_ritenuta: [null, []],
                causale_ritenuta: [null, []],
                regime_fiscale: [null, []],
                tipologia_societa: [null, []],
                pec: [null, []],
                stato_freeinvoice: [null, []],
                fi_api_key: [null, []],
                telefono: [
                    null,
                    [
                        Validators.required,
                        CustomValidators.number,
                        Validators.minLength(9),
                        Validators.maxLength(11),
                    ],
                ],
                cellulare: [
                    null,
                    [
                        Validators.required,
                        CustomValidators.number,
                        Validators.minLength(9),
                        Validators.maxLength(11),
                    ],
                ],
            });

            let vendors = this.dealerForm.get('vendors') as FormArray;

            for (let i = 0; i < this.selectedDealer.vendors.length; i++) {
                let _vendor = this.selectedDealer.vendors[i];

                vendors.push(
                    this.formBuilder.group({
                        id_dealer: [_vendor.id_dealer, []],
                        id_operator: [_vendor.id_operator, []],
                        id_agente: [_vendor.id_agente, []],
                        code: [_vendor.code, []],
                    })
                );
            }
        }
    }

    exportDealers() {
        this.spinner.show('table-dealers');

        let fields = this.fields
            .filter((item) => item.checked)
            .map((item) => item.key);

        let expand = this.expand
            .filter((item) => item.checked)
            .map((item) => item.key);

        fields.push('deleted');
        this.service
            .list({
                fields: fields.join(','),
                expand: expand.join(','),
                sort: '-id',
                page: this.pagination.page,
                name: this.filter.name,
                deleted: this.filter.deleted,
                owner_name: this.filter.owner_name,
                owner_surname: this.filter.owner_surname,
                vat: this.filter.vat,
                city: this.filter.city,
                province: this.filter.province,
                state: this.filter.state,
                phone: this.filter.phone,
                mobile: this.filter.mobile,
                created_from: this.filter.created_from,
                created_to: this.filter.created_to,
                vendors: this.filter.vendors.join(','),
                'per-page': 0,
            })
            .subscribe((data) => {
                this.spinner.hide('table-dealers');

                let rows = data.rows;

                rows = rows.map((row) => {
                    if (row.hasOwnProperty('vendors')) {
                        for (let i = 0; i < row.vendors.length; i++) {
                            row[`Codice ${row.vendors[i].vendor_name}`] = row.vendors[i].code;
                        }

                        delete row['vendors'];
                    }


                    if (row.hasOwnProperty('agents')) {
                        for (let i = 0; i < row.agents.length; i++) {
                            row[`Agente ${row.agents[i].vendor_name}`] = row.agents[i].agent_name;
                        }

                        delete row['agents'];
                    }

                    return row;
                });
                const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);
                const wb: XLSX.WorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
                XLSX.writeFile(wb, 'export.xlsx');
            });
    }

    setVendorAgentOnFormArray(i, id) {
        let group = this.vendorsForm.controls[i] as FormGroup;
        group.controls.id_agente.setValue(id);
    }

    get vendorsForm(): FormArray {
        return this.dealerForm.controls.vendors as FormArray;
    }

    selectDealer(event, dealer?: Dealer) {
        if (dealer?.id) {
            if (event.currentTarget.checked) {
                dealer.isSelected = true;
                this.selectedDealersForCampaign.push(dealer.id);
            } else {
                const index = this.selectedDealersForCampaign.findIndex(data => data === dealer.id);
                if (index > -1) {
                    dealer.isSelected = false;
                    this.selectedDealersForCampaign.splice(index, 1);
                }
            }
        } else {
            if (event.currentTarget.checked) {
                this.selectedDealersForCampaign = ['all'];
                this.checkAllDealers();
            } else {
                this.checkAllDealers(false);
                this.selectedDealersForCampaign = [];
            }
        }
    }

    checkAllDealers(selected: boolean = true) {
        this.dealers.forEach(item => {
            item.isSelected = selected;
        });
    }

    showCreateCampaignModal() {
        this.campaignModal = this.modalService.open(this.campaignFormTemplate, {size: 'xl'});
        this.campaignModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    handleSavedCampaign(event: boolean) {
        if (event) {
            this.selectedDealersForCampaign = [];
            this.dealers.forEach(data => {
                data.isSelected = false;
            });
            this.selectAll = false;
        }
    }

    hideAllDealerModalCollapse() {
        this.collapse.sede = true;
        this.collapse.altro = true;
        this.collapse.rappresentante = true;
        this.collapse.azienda = true;
        this.collapse.fatturazione = true;
        for (let i = 0; i < this.collapse.operators.length; i++) {
            this.collapse.operators[i] = true;
        }
    }

    showAllDealerModalCollapse() {
        this.collapse.sede = false;
        this.collapse.altro = false;
        this.collapse.rappresentante = false;
        this.collapse.azienda = false;
        this.collapse.fatturazione = false;
        for (let i = 0; i < this.collapse.operators.length; i++) {
            this.collapse.operators[i] = false;
        }
    }

    saveShopAccess() {
        this.shopAccess.id_dealer = this.selectedDealer.id;
        this.shopAccess.from = moment(this.shopAccess.from, 'DD-MM-YYYY').format('YYYY-MM-DD');
        this.shopAccess.to = moment(this.shopAccess.to, 'DD-MM-YYYY').format('YYYY-MM-DD');

        this.busy = this.dealerService
            .saveShopAccess(this.shopAccess)
            .subscribe((data: ShopAccess) => {
                this.dealerForm.get('shopAccess').reset();
                this.selectedDealer.shopAccesses.push(new ShopAccess(data));
                this.shopAccess = new ShopAccess();
            });
    }

    deleteShopAccess(id: number) {
        this.busy = this.dealerService
            .deleteShopAccess(id)
            .subscribe(data => {
                let item = this.selectedDealer.shopAccesses.findIndex(x => x.id === id);
                this.selectedDealer.shopAccesses.splice(item, 1);
            });
    }
}
