<div class="row" [ngBusy]="busy">
    <div class="col-12 mb-4">
        <h4 translate>Campaigns
            <button class="btn btn-info float-right" (click)="openModal(campaignTemplate, modalTypes[0])" translate>
                Create Campaign
            </button>
        </h4>
        <p *ngIf="campaigns && campaigns?.length<=0" class="text-muted" translate>List is empty</p>
    </div>
    <div class="col-12" *ngFor="let campaign of campaigns; index as i">
        <div class="card">
            <div class="card-header">
                <div class="row d-flex justify-content-between align-items-center">
                    <div class="col-12 col-lg-4"><h5 class="my-auto">{{campaign.title}}</h5></div>
                    <div class="col-12 col-lg-4">
                        <span translate>Validity period:</span> {{campaign.start_date | date:format}}
                        - {{campaign.end_date | date:format}}</div>
                    <div class="col-12 col-lg-2">
                        <span class="badge badge-success"
                              *ngIf="isActive(campaign.start_date,campaign.end_date)" translate>Active</span>
                        <span class="badge badge-warning" *ngIf="isExpired(campaign.end_date)" translate>Expired</span>
                        <span class="badge badge-info" *ngIf="isPending(campaign.start_date)" translate>Pending</span>
                    </div>
                    <div class="col-12 col-lg-2 d-flex justify-content-end">
                        <span>
                               <button class="btn btn-xs btn-light mr-2"
                                       (click)="openModal(campaignTemplate, modalTypes[0],i,campaign)" translate>
                            Change
                        </button>
                              <button class="btn btn-xs btn-danger mr-2"
                                      [swal]="deleteCampaignOptions"
                                      (confirm)="deleteCampaign(campaign.id)" translate>
                            Delete
                        </button>
                        <button class="btn btn-link pr-0" data-toggle="collapse" [attr.data-target]="'#collapse' + i"
                                (click)="loadRulesAndPrizes(campaign)"
                                aria-expanded="false">
                            <i class="fa fa-plus text-muted"></i>
                            <i class="fa fa-minus text-muted"></i>
                        </button>
                        </span>
                    </div>
                </div>
            </div>
            <div class="card-body collapse" [id]="'collapse'+i">
                <div class="row justify-content-between">
                    <div class="col-12 col-lg-6">
                        <h5 class="pb-2"><span translate>Rules:</span>
                            <button class="btn btn-info float-right"
                                    (click)="openModal(campaignTemplate, modalTypes[1], i)" translate>
                                Add Rule
                            </button>
                        </h5>
                        <ngx-spinner
                                bdColor="rgba(0, 0, 0, 0.8)"
                                size="default"
                                color="#fff"
                                type="square-jelly-box"
                                name='rules{{campaign?.id}}'
                                [fullScreen]="false"
                        >
                        </ngx-spinner>
                        <p *ngIf="campaign?.segments && campaign?.segments?.data?.length<=0" translate class = "text-muted">List is
                            empty</p>
                        <table class="table mt-3" *ngFor="let segment of campaign?.segments?.data">
                            <tr>
                                <td colspan="3"><img
                                        [src]="'/assets/img/brands/'+segment.slug+'.png'"
                                        [alt]="segment.title"
                                        height="30px"></td>
                            </tr>
                            <tr *ngFor="let  rule of segment.rules">
                                <td>{{rule.count}} {{rule.offer}}</td>
                                <td class="text-right">{{rule.points}} <span translate>points</span>
                                </td>
                                <td class="text-right">
                                    <button class="btn btn-link"
                                            (click)="openModal(campaignTemplate, modalTypes[1],i,rule)">
                                        <i class="fa fa-edit text-muted"></i>
                                    </button>
                                    <button class="btn btn-link"
                                            [swal]="deleteCampaignOptions"
                                            (confirm)="deleteRule(rule.id, segment)">
                                        <i class="fa fa-trash text-danger"></i>
                                    </button>
                                </td>
                            </tr>
                        </table>
                        <button class="btn btn-link float-right" (click)="loadAllRules(campaign)"
                                translate *ngIf="campaign?.segments && campaign?.segments?.data.length>0">
                            <span translate *ngIf="!isShownAll(campaign?.segments)">Show All</span>
                            <span translate *ngIf="isShownAll(campaign?.segments)">Show Less</span>
                        </button>
                    </div>
                    <div class="col-12 col-lg-6">
                        <h5 class="pb-2">
                            <span translate>Prizes:</span>
                            <button class="btn btn-secondary float-right ml-2"
                                    (click)="openUsesPrizesModal(usedPrizesTemplate, campaign)" translate>
                                Show Retired Prizes
                            </button>
                            <button class="btn btn-info float-right"
                                    (click)="openModal(campaignTemplate, modalTypes[2], i)" translate>
                                Add Prize
                            </button>
                        </h5>
                        <ngx-spinner
                                bdColor="rgba(0, 0, 0, 0.8)"
                                size="default"
                                color="#fff"
                                type="square-jelly-box"
                                name='prizes{{campaign?.id}}'
                                [fullScreen]="false"
                        >
                        </ngx-spinner>
                        <p *ngIf="campaign?.prizes && campaign?.prizes?.data?.length<=0" translate class = "text-muted">List is
                            empty</p>
                        <div class="card mt-3" *ngFor="let prize of campaign?.prizes?.data">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-lg-3 col-12">
                                        <img [src]="prize.image_path" [alt]="prize.image_name" class="img-fluid">
                                    </div>
                                    <div class="col-lg-7 col-12 text-center">
                                        <h1 class="display-6 mb-0">{{prize.title}}</h1>
                                        <p class="lead mb-0"><b>{{prize.required_points}} </b> <span translate>required points</span>
                                        </p>
                                    </div>
                                    <div class="col-lg-2 col-12 justify-content-end d-flex">
                                        <button class="btn btn-link"
                                                (click)="openModal(campaignTemplate, modalTypes[2],i,prize)" translate>
                                            <i class="fa fa-edit text-muted"></i>
                                        </button>
                                        <button class="btn btn-link"
                                                [swal]="deleteCampaignOptions"
                                                (confirm)="deletePrize(prize.id,campaign)" translate>
                                            <i class="fa fa-trash text-danger"></i>
                                        </button>
                                    </div>
                                    <div class="col-12" *ngIf="prize.description">
                                        <p class="text-muted mt-2">{{prize.description}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-link float-right" (click)="loadAllPrizes(campaign)" translate
                                *ngIf="campaign?.prizes && campaign?.prizes?.data.length>0">
                            <span translate *ngIf="!isShownAll(campaign?.prizes)">Show All</span>
                            <span translate *ngIf="isShownAll(campaign?.prizes)">Show Less</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <ngb-pagination
                *ngIf="pagination"
                [(page)]="pagination.currentPage"
                [pageSize]="pagination.perPage"
                [boundaryLinks]="true" size="sm"
                [collectionSize]="pagination.pageCount*pagination.perPage"
                [maxSize]="5"
                (pageChange)="loadCampaigns($event)"
                [ellipses]="false"
                [hidden]="pagination.pageCount<=1"
        >
            <ng-template ngbPaginationFirst>««</ng-template>
            <ng-template ngbPaginationPrevious>«</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
            <ng-template ngbPaginationNext>»</ng-template>
            <ng-template ngbPaginationLast>»»</ng-template>
        </ngb-pagination>
    </div>
</div>
<ng-template #campaignTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title text-capitalize" translate>Create {{selectedModal}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-campaign-modal [profile]="user?.profile" [modalType]="selectedModal" [selectedIndex]="selectedIndex"
                        [campaigns]="campaigns" [selectedItem]="selectedItem"></app-campaign-modal>
</ng-template>

<ng-template #usedPrizesTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title text-capitalize" translate>List of used prizes</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="usedPrizesBusy">
        <span>
            <label translate>Validity period:</label> {{selectedCampaign.start_date | date:format}}
            - {{selectedCampaign.end_date | date:format}}</span>
        <p *ngIf="usedPrizes?.length<=0" class="text-muted" translate>List is empty</p>
        <table class="table table-bordered my-3" *ngIf="usedPrizes?.length>0">
            <tr>
                <th>Prize</th>
                <th>Dealer</th>
                <th>Date</th>
            </tr>
            <tr *ngFor="let usedPrize of usedPrizes">
                <td>{{usedPrize.prize.title}}</td>
                <td>{{usedPrize.dealer.first_name}} {{usedPrize.dealer.last_name}}</td>
                <td>{{usedPrize.created_at | date:format}}</td>
            </tr>
        </table>
        <ngb-pagination
                *ngIf="usedPrizesPagination"
                [(page)]="usedPrizesPagination.currentPage"
                [pageSize]="usedPrizesPagination.perPage"
                [boundaryLinks]="true" size="sm"
                [collectionSize]="usedPrizesPagination.pageCount*usedPrizesPagination.perPage"
                [maxSize]="5"
                (pageChange)="loadUsedPrizes(selectedCampaign,$event)"
                [ellipses]="false"
                [hidden]="usedPrizesPagination.pageCount<=1"
        >
            <ng-template ngbPaginationFirst>««</ng-template>
            <ng-template ngbPaginationPrevious>«</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
            <ng-template ngbPaginationNext>»</ng-template>
            <ng-template ngbPaginationLast>»»</ng-template>
        </ngb-pagination>
    </div>
</ng-template>
