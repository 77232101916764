import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {DashboardService} from '@services/dashboard.service';

@Component({
  selector: 'app-master-confronto',
  templateUrl: './master-confronto.component.html',
  styleUrls: ['./master-confronto.component.css']
})
export class MasterConfrontoComponent implements OnInit {
  busy: Subscription;

  regioni: any;
  urlConfrontoItalia: string;

  constructor(private dashboardService: DashboardService) {
  }

  ngOnInit() {
  }

  updateUrls(operatore, periodo) {
    this.busy = this.dashboardService.get(`/confronto-italia?operatore=${operatore}&periodo=${periodo}`)
        .subscribe((data) => {
          this.regioni = data.result;
        });
  }

}
