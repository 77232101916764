import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {DashboardService} from "@services/dashboard.service";
import * as shape from 'd3-shape';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AuthenticationService} from '@services/authentication.service';

@Component({
    selector: 'app-master-agente',
    templateUrl: './master-agente.component.html',
    styleUrls: ['./master-agente.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MasterAgenteComponent implements OnInit {
    data = null;
    busy: Subscription;
    startup: any;

    _periodo: string;
    urlSubheader: string;
    id: string;

    curve: any = shape.curveCardinal; // or some other function from d3-shape
    autoloadTableUrl: any;

    set periodo(value: string) {
        this._periodo = value;
        this.updateUrls();
    }

    get periodo() {
        return this._periodo;
    }

    constructor(
        public route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private modalService: NgbModal,
        private dashboardService: DashboardService,
        private authService: AuthenticationService,
    ) {
        this.id = this.route.snapshot.paramMap.get('id');

        if (!this.id) {
            this.id = this.authService.currentUserValue.profile.id;
        }

        this.periodo = null;
    }

    updateUrls() {
        this.spinner.show();

        this.dashboardService.get(`/agente-dettaglio?id=${this.id}&periodo=${this.periodo}`)
            .subscribe(data => {
                this.data = data.result;
                this.spinner.hide();
            });
    }

    ngOnInit(): void {
    }

    openAutoloadTable(modal, url) {
        if (url) {
            this.autoloadTableUrl = url;

            this.modalService.open(modal, {size: 'xl'});
        }
    }
}
