<div [ngBusy]="busy.global" ngbRadioGroup
     class="btn-group btn-group-toggle border-bottom rounded bg-gradient-light elevation-1 d-flex flex-wrap text-center"
     *ngIf="segments?.length>0">
    <label class="segment-container btn" ngbButtonLabel [ngClass]="{'active btn-light': activeTab === i}"
           *ngFor="let segment of segments; index as i">
        <img [src]="'assets/img/brands/'+segment.slug+'.png'" height="30px" [alt]="segment?.title">
        <input ngbButton type="radio" [value]="segment.id" [checked]="activeTab === i"
               (change)="changeSegment(segment,i)">
    </label>
</div>
<div *ngIf="segments?.length>0">
    <div class="card bg-gradient-light mt-3">
        <div class="card-body">
            <div class="row align-items-end">
                <div class="col-lg-4 col-12">
                    <label translate>From month - To month</label>
                    <app-month-range-picker [localFormat]="localMonthFormat"
                                            (selected)="setSelectedDateRange($event.start, $event.end)"
                                            [defaultMonthRange]="defaultMonthRange"></app-month-range-picker>
                </div>
                <div class="col-lg-3 col-12">
                    <label translate>Planning Center</label>
                    <ng-select [(ngModel)]="filterForm.scope" bindValue="key"
                               [searchable]="false" [markFirst]="true"
                               bindLabel="label" (ngModelChange)="filterForm.scopeId=''">
                        <ng-container *isVendorEnabled="['Kena Mobile Stands']">
                            <ng-option value="general_stand" translate *show="['operator','master', 'masteragent']">General
                            </ng-option>
                            <ng-option value="stand" translate *show="['operator','master', 'masteragent']">Stand
                            </ng-option>
                        </ng-container>
                        <ng-container *isVendorDisabled="'Kena Mobile Stands'">
                            <ng-option value="general" translate *show="['operator','master', 'masteragent']">General
                            </ng-option>
                            <ng-option value="agent" translate *show="['operator','master', 'masteragent']">Agent
                            </ng-option>
                            <ng-option value="region" translate *show="['operator','master', 'masteragent']">Region
                            </ng-option>
                            <ng-option value="province" translate>Province</ng-option>
                        </ng-container>
                    </ng-select>
                </div>
                <div class="col-lg-3 col-12">
                    <div *ngIf="filterForm.scope=='agent'">
                        <label>Agent</label>
                        <app-agent-select (onChange)="setScopeId($event)">
                        </app-agent-select>
                    </div>
                    <div *ngIf="filterForm.scope=='stand'">
                        <label>Presidio</label>
                        <app-stand-select (onChange)="setScopeId($event)">
                        </app-stand-select>
                    </div>
                    <div *ngIf="filterForm.scope=='region'">
                        <label>Region</label>
                        <app-state-select
                                (onChange)="setScopeId($event)"
                        ></app-state-select>
                    </div>
                    <div *ngIf="filterForm.scope=='province'">
                        <label>Province</label>
                        <app-province-select
                                (onChange)="setScopeId($event)"
                        ></app-province-select>
                    </div>
                </div>
                <div class="col-lg-2 mt-2 mt-lg-auto mb-0 col-12">
                    <button class="btn btn-info btn-block" translate (click)="getPlans()"
                            [disabled]="isValidFilterForm()">Filter
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="card bg-gradient-1" *ngIf="!this.filterForm.scopeId">
        <div class="card-header">
            <h1 class="mb-0 display-6" translate>{{ stand ? 'Aperture' : 'Affiliations' }}</h1>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-xl-9">
                    <div class="card bg-gradient-light elevation-2">
                        <div class="card-body overflow-auto" [ngBusy]="busy.affiliations">
                            <table class="table mb-0">
                                <thead>
                                <tr>
                                    <th><img [src]="'assets/img/brands/'+activeSegment.slug+'.png'" height="28px"
                                             *ngIf="activeSegment">
                                        <button class="btn btn-link float-lg-right"
                                                (click)="backDrillDown(targetTypes[0])"
                                                *ngIf="drillDown.affiliations!=drillDowns[0]">
                                            <i class="fas fa-angle-left fa-lg"></i>
                                        </button>
                                    </th>
                                    <th class="text-capitalize text-center"
                                        *ngFor="let date of datesList.affiliations">
                                        <div class="d-inline-block">
                                            <button type="button"
                                                    class="btn btn-link text-dark my-0 p-0 text-capitalize"
                                                    (click)="getPlansDrillDown(date, targetTypes[0])"
                                                    [disabled]="isDisabled(targetTypes[0])">
                                                <strong>{{formatDate(date, targetTypes[0])}}</strong>
                                            </button>
                                            <div class="text-muted" *ngIf="isDateRangeVisible(targetTypes[0])">
                                                <small>{{setStartDate(date)}}
                                                    - {{setEndDate(date)}}</small></div>
                                            <div class="text-muted" *ngIf="isDateVisible(targetTypes[0])">
                                                <small>{{setStartDate(date)}}</small></div>
                                        </div>
                                    </th>
                                    <th class="text-right" translate>Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngIf="showPlans(drillDown.affiliations)">
                                    <td><strong translate>{{ stand ? 'Presidi da aprire' : 'PDV da codificare' }}</strong></td>
                                    <td *ngFor="let date of datesList.affiliations;index as i"
                                        class="edit-row text-center">
                                        <span *show="['operator','agent','masteragent']">{{masterPlans?.affiliations[date]?.plan ? formatNumber(masterPlans?.affiliations[date]?.plan?.value) : '--'}}</span>
                                        <button type="button" class="btn btn-link p-0 d-block w-100 h-100"
                                                *show="['master']"
                                                (click)="openEditTable(editPopover,masterPlans?.affiliations[date],date,targetTypes[0])"
                                                [ngbPopover]="popContent" popoverTitle="Change value"
                                                (shown)="focusInput()"
                                                autoClose="outside" triggers="manual"
                                                #editPopover="ngbPopover">
                                            <span>{{masterPlans?.affiliations[date]?.plan ? formatNumber(masterPlans?.affiliations[date]?.plan?.value) : '--'}}</span>
                                        </button>
                                    </td>
                                    <td class="text-right">{{formatNumber(openingsTotal.plan)}} <span
                                            translate>{{ stand ? 'presidi' : 'shops' }}</span></td>
                                </tr>
                                <tr *ngFor="let type of affiliationBreakDownTypes" class="collapse affiliation-plan">
                                    <td class="text-capitalize pl-3" translate>{{encodeName(type)}}</td>
                                    <td class="text-center edit-row"
                                        *ngFor="let date of datesList.affiliations">
                                        <span *show="['operator','agent','masteragent']">{{masterPlans?.affiliations[date]?.breakdown[type].plan ? formatNumber(masterPlans?.affiliations[date]?.breakdown[type]?.plan?.value) : '--' }}</span>
                                        <button type="button" class="btn btn-link p-0 d-block w-100 h-100"
                                                *show="['master']"
                                                (click)="openEditTable(editPopover,masterPlans?.affiliations[date]?.breakdown[type],date,targetTypes[0],type)"
                                                [ngbPopover]="popContent" popoverTitle="Change value" autofocus
                                                (shown)="focusInput()"
                                                autoClose="outside" triggers="manual"
                                                #editPopover="ngbPopover">
                                            <span> {{masterPlans?.affiliations[date]?.breakdown[type].plan ? formatNumber(masterPlans?.affiliations[date]?.breakdown[type]?.plan?.value) : '--' }}</span>
                                        </button>
                                    </td>
                                    <td class="text-right">{{calculateBreakDownTotal(datesList.affiliations, masterPlans?.affiliations, type)}}
                                        <span
                                                translate>{{ stand ? 'presidi' : 'shops' }}</span></td>
                                </tr>
                                <tr *ngIf="affiliationBreakDownTypes?.length>0 && showPlans(drillDown.affiliations)">
                                    <td class="position-absolute w-100 text-center p-0 border-0">
                                        <button class="btn btn-link py-0 mt-n4 mb-1"
                                                type="button" data-toggle="collapse"
                                                data-target=".affiliation-plan"
                                                aria-expanded="false" aria-controls="affiliation-plan">
                                            <i class="fas fa-sort-up fa-lg"></i>
                                            <i class="fas fa-sort-down fa-lg"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong translate>{{ stand ? 'Presidi aperti' : 'PDV codificati' }}</strong></td>
                                    <td class="text-center"
                                        *ngFor="let date of datesList.affiliations">{{formatNumber(masterPlans?.affiliations[date]?.real)}}
                                        <app-plan-percentage [isCurrentMonth]="isCurrentMonth(date)"
                                                             [item]="masterPlans?.affiliations[date]"></app-plan-percentage>
                                    </td>
                                    <td class="text-right">{{formatNumber(openingsTotal.real)}} <span
                                            translate>{{ stand ? 'presidi' : 'shops' }}</span></td>
                                </tr>
                                <tr *ngFor="let type of affiliationBreakDownTypes" class="collapse affiliation-real">
                                    <td class="text-capitalize pl-3" translate>{{encodeName(type)}}</td>
                                    <td class="text-center"
                                        *ngFor="let date of datesList.affiliations">
                                        {{masterPlans?.affiliations[date]?.breakdown[type].real}}
                                        <app-plan-percentage
                                                [item]="masterPlans?.affiliations[date]?.breakdown[type]"></app-plan-percentage>
                                    </td>
                                    <td class="text-right">{{calculateBreakDownTotal(datesList.affiliations, masterPlans?.affiliations, type, 'real')}}
                                        <span
                                                translate>{{ stand ? 'presidi' : 'shops' }}</span></td>
                                </tr>
                                <tr *ngIf="affiliationBreakDownTypes?.length>0">
                                    <td class="position-absolute w-100 text-center p-0 border-0">
                                        <button class="btn btn-link py-0 mt-n4 mb-1" type="button"
                                                data-toggle="collapse"
                                                data-target=".affiliation-real"
                                                aria-expanded="false" aria-controls="affiliation-real">
                                            <i class="fas fa-sort-up fa-lg"></i>
                                            <i class="fas fa-sort-down fa-lg"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong translate>{{ stand ? 'Presidi Totali' : 'Negozi Totali' }}</strong></td>
                                    <td class="text-center"
                                        *ngFor="let date of datesList.affiliations">{{formatNumber(masterPlans?.affiliations[date]?.shops)}}</td>
                                    <td class=" text-right">{{formatNumber(openingsTotal.shops)}} <span
                                            translate>{{ stand ? 'presidi' : 'shops' }}</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row mt-lg-3 pt-lg-4">
                        <div class="col-xl-2 text-center pl-3"><img class="p-1 img-circle bg-gradient-light"
                                                                    src="/assets/img/flats/target.png" height="64px">
                        </div>
                        <div class="col pl-lg-4 text-center text-lg-left">
                            <p class="lead mb-0">{{formatNumber(openingsTotal.plan)}} <span translate>{{ stand ? 'presidi' : 'shops' }}</span>
                            </p>
                            <p class="lead mb-0"><span translate>Target</span> {{targetYear}}</p>
                        </div>
                        <div class="col-xl-12">
                            <hr>
                        </div>
                        <div class="col-xl-12 text-center text-lg-left">
                            <app-agents-performance [isInSchedule]=" openingsTotal.isInSchedule" [filter]="filterForm"
                                                    [breakDownTypes]="affiliationBreakDownTypes"
                                                    [monthsList]="monthsList"
                                                    [targetType]="targetTypes[0]"></app-agents-performance>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center w-100 mb-3"><i class="fas fa-arrow-down" style="font-size: 28px;"></i></div>
    <div class="card bg-gradient-1">
        <div class="card-header">
            <h1 class="mb-0 display-6" translate>Sales</h1>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-xl-9">
                    <div class="card bg-gradient-light elevation-2">
                        <div class="card-body overflow-auto" [ngBusy]="busy.sales">
                            <table class="table mb-0">
                                <thead>
                                <tr>
                                    <th><img [src]="'assets/img/brands/'+activeSegment.slug+'.png'" height="28px"
                                             *ngIf="activeSegment">
                                        <button class="btn btn-link float-lg-right"
                                                (click)="backDrillDown(targetTypes[1])"
                                                *ngIf="drillDown.sales!=drillDowns[0]">
                                            <i class="fas fa-angle-left fa-lg"></i>
                                        </button>
                                    </th>
                                    <th class="text-capitalize text-center"
                                        *ngFor="let date of datesList.sales">
                                        <div class="d-inline-block">
                                            <button type="button" class="btn btn-link text-dark my-0 p-0 "
                                                    (click)="getPlansDrillDown(date, targetTypes[1])"
                                                    [disabled]="isDisabled(targetTypes[1])">
                                                <strong>{{formatDate(date, targetTypes[1])}}</strong>
                                            </button>
                                            <div class="text-muted" *ngIf="isDateRangeVisible(targetTypes[1])">
                                                <small>{{setStartDate(date)}}
                                                    - {{setEndDate(date)}}</small></div>
                                            <div class="text-muted" *ngIf="isDateVisible(targetTypes[1])">
                                                <small>{{setStartDate(date)}}</small></div>
                                        </div>
                                    </th>
                                    <th class="text-right" translate>Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngIf="showPlans(drillDown.sales)">
                                    <td><strong translate>Target Sim</strong></td>
                                    <td *ngFor="let date of datesList.sales"
                                        class="edit-row text-center">
                                        <span *show="['operator','agent','masteragent']">{{masterPlans?.sales[date]?.plan ? formatNumber(masterPlans?.sales[date]?.plan?.value) : '--'}}</span>
                                        <button type="button" class="btn btn-link p-0 d-block w-100 h-100"
                                                *show="['master']"
                                                (click)="openEditTable(editPopover,masterPlans?.sales[date],date,targetTypes[1])"
                                                [ngbPopover]="popContent" popoverTitle="Change value" autofocus
                                                (shown)="focusInput()"
                                                autoClose="outside" triggers="manual"
                                                #editPopover="ngbPopover">
                                            <span>{{masterPlans?.sales[date]?.plan ? formatNumber(masterPlans?.sales[date]?.plan?.value) : '--'}}</span>
                                        </button>
                                    </td>
                                    <td class="text-right">{{formatNumber(productionTotal.plan)}} <span
                                            translate>sales</span></td>
                                </tr>
                                <tr *ngFor="let type of breakDownTypes" class="collapse sales-plan">
                                    <td>
                                        <div class="text-uppercase pl-3">{{type}}</div>
                                    </td>
                                    <td class="text-center edit-row"
                                        *ngFor="let date of datesList.sales">
                                        <span *show="['operator','agent','masteragent']">{{masterPlans?.sales[date]?.breakdown[type].plan ? formatNumber(masterPlans?.sales[date]?.breakdown[type]?.plan?.value) : '--' }}</span>
                                        <button type="button" class="btn btn-link d-block w-100 h-100"
                                                *show="['master']"
                                                (click)="openEditTable(editPopover,masterPlans?.sales[date]?.breakdown[type],date,targetTypes[1],type)"
                                                [ngbPopover]="popContent" popoverTitle="Change value" autofocus
                                                (shown)="focusInput()"
                                                autoClose="outside" triggers="manual"
                                                #editPopover="ngbPopover">
                                            <span> {{masterPlans?.sales[date]?.breakdown[type].plan ? formatNumber(masterPlans?.sales[date]?.breakdown[type]?.plan?.value) : '--' }}</span>
                                        </button>
                                    </td>
                                    <td class="text-right">{{calculateBreakDownTotal(datesList.sales, masterPlans?.sales, type)}}
                                        <span
                                                translate>sales</span></td>
                                </tr>
                                <tr *ngIf="breakDownTypes?.length>0 && showPlans(drillDown.sales)">
                                    <td class="position-absolute w-100 text-center p-0 border-0">
                                        <button class="btn btn-link py-0 mt-n4 mb-1"
                                                type="button" data-toggle="collapse"
                                                data-target=".sales-plan"
                                                aria-expanded="false" aria-controls="sales-plan">
                                            <i class="fas fa-sort-up fa-lg"></i>
                                            <i class="fas fa-sort-down fa-lg"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong translate>Sim attivate</strong>
                                    </td>
                                    <td class="text-center"
                                        *ngFor="let date of datesList.sales">
                                        <app-real [item]="masterPlans.sales[date]"
                                                  [isCurrentMonth]="isCurrentMonth(date)"></app-real>
                                    </td>
                                    <td class="text-right">{{productionTotal.real}} <span
                                            translate>sales</span></td>
                                </tr>
                                <tr *ngFor="let type of breakDownTypes" class="collapse sales-real">
                                    <td>
                                        <div class="text-uppercase pl-3">{{type}}</div>
                                    </td>
                                    <td class="text-center"
                                        *ngFor="let date of datesList.sales">
                                        <app-real [item]="masterPlans?.sales[date]?.breakdown[type]"
                                                  [isCurrentMonth]="isCurrentMonth(date)"></app-real>
                                    </td>
                                    <td class="text-right">{{calculateBreakDownTotal(datesList.sales, masterPlans?.sales, type, 'real')}}
                                        <span
                                                translate>sales</span></td>
                                </tr>
                                <tr *ngIf="breakDownTypes?.length>0">
                                    <td class="position-absolute w-100 text-center p-0 border-0">
                                        <button class="btn btn-link py-0 mt-n4 mb-1" type="button"
                                                data-toggle="collapse"
                                                data-target=".sales-real"
                                                aria-expanded="false" aria-controls="sales-real">
                                            <i class="fas fa-sort-up fa-lg"></i>
                                            <i class="fas fa-sort-down fa-lg"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong translate>{{ stand ? 'Presidi Produttivi' : 'Negozi Produttivi' }}</strong></td>
                                    <td class="text-center"
                                        *ngFor="let date of datesList.sales">{{formatNumber(masterPlans?.sales[date]?.shops)}}</td>
                                    <td class="text-right"></td>
                                </tr>
                                <tr>
                                    <td><strong translate>Media Sim / {{ stand ? 'Presidio' : 'Negozio' }}</strong></td>
                                    <td class="text-center"
                                        *ngFor="let date of datesList.sales">
                                        <button class="btn btn-link text-dark text-decoration-none"
                                                popoverTitle="Altre informazioni"
                                                [ngbPopover]="ctrShopContent">
                                            {{masterPlans?.sales[date]?.contract_per_shop ? masterPlans?.sales[date]?.contract_per_shop : '--'}}
                                        </button>
                                        <ng-template #ctrShopContent>
                                            <p>La media di contratti per i negozi produttivi
                                                è: {{masterPlans?.sales[date]?.contract_per_shop ? masterPlans?.sales[date]?.contract_per_shop : '--'}}</p>
                                            <p>Il calcolo è dato dal totale dei contratti <span
                                                    class="text-muted">({{masterPlans?.sales[date].real}})</span> / i
                                                negozi
                                                produttivi <span class="text-muted">({{masterPlans?.sales[date].shops}}
                                                    )</span></p>
                                            <hr>
                                            <p>La media di contratti per i negozi totali
                                                è: {{showContractsPerTotalShop(date)}}</p>
                                            <p>Il calcolo è dato dal totale dei contratti <span
                                                    class="text-muted">({{masterPlans?.sales[date].real}})</span> /
                                                totale
                                                negozi <span
                                                        class="text-muted">({{masterPlans?.affiliations[date].shops}}
                                                    )</span></p>
                                        </ng-template>
                                    </td>
                                    <td class="text-right">{{formatNumber(productionTotal.ctrPerShopAverage)}}
                                        <span translate>sales</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row mt-lg-5 pt-lg-5">
                        <div class="col-xl-2 text-center pl-3"><img class="p-1 img-circle bg-gradient-light"
                                                                    src="/assets/img/flats/target.png" height="64px">
                        </div>
                        <div class="col pl-lg-4 text-center text-lg-left">
                            <p class="lead mb-0">{{formatNumber(productionTotal.plan)}} <span
                                    translate>contracts</span></p>
                            <p class="lead mb-0"><span translate>Target</span> {{targetYear}}</p>
                        </div>
                        <div class="col-xl-12">
                            <hr>
                        </div>
                        <div class="col-xl-12 text-center text-lg-left">
                            <app-agents-performance [isInSchedule]="productionTotal.isInSchedule" [filter]="filterForm"
                                                    [monthsList]="monthsList" [breakDownTypes]="breakDownTypes"
                                                    [targetType]="targetTypes[1]"></app-agents-performance>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row align-items-lg-center">
                <div class="col">
                    <div class="row align-items-center">
                        <div class="col-xl-2 text-center pl-3"><img class="p-1 img-circle bg-gradient-light"
                                                                    src="/assets/img/flats/settings.png" height="64px">
                        </div>
                        <div class="col pl-lg-4 text-center text-lg-left">
                            <p class="lead mb-0">{{salesCalculation.salesIncreaseValue > 0 ? '+' : ''}}{{salesCalculation.salesIncreaseValue}}
                                <span
                                        translate class="pl-1">per month</span>
                            </p>
                            <p class="lead mb-0" translate>Standard increase of the average ctr / shop</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row align-items-center">
                        <div class="col-xl-2 text-center pl-3"><img class="p-1 img-circle bg-gradient-light"
                                                                    src="/assets/img/flats/percentage.png"
                                                                    height="64px">
                        </div>
                        <div class="col pl-lg-4 text-center text-lg-left">
                            <p class="lead mb-0">{{salesCalculation.involvementBreakDown}}</p>
                            <p class="lead mb-0" translate>Standard division consumer / business contracts</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row align-items-center">
                        <div class="col-xl-2 text-center pl-3"><img class="p-1 img-circle bg-gradient-light"
                                                                    src="/assets/img/flats/help.png" height="64px">
                        </div>
                        <div class="col pl-lg-4 text-center text-lg-left">
                            <p class="lead mb-0">0 <span translate>interventions</span></p>
                            <p class="lead mb-0" translate>No intervention to increase production</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *show="['master']" class="text-center w-100 mb-3"><i class="fas fa-arrow-down" style="font-size: 28px;"></i>
    </div>
    <div class="card bg-gradient-1" *show="['master']">
        <div class="card-header">
            <h1 class="mb-0 display-6" translate>Revenue</h1>
        </div>
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col-xl-9">
                    <div class="card bg-gradient-light elevation-2">
                        <div class="card-body overflow-auto">
                            <table class="table mb-0">
                                <thead>
                                <tr>
                                    <th><img [src]="'assets/img/brands/'+activeSegment.slug+'.png'" height="28px"
                                             *ngIf="activeSegment"></th>
                                    <th class="text-capitalize text-right"
                                        *ngFor="let month of monthsList">{{formatMonth(month)}}
                                    </th>
                                    <th class="text-right">Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td><strong translate>Plan</strong></td>
                                    <td *ngFor="let month of monthsList;index as i"
                                        class="edit-row text-right">
                                        <span *show="['operator','agent','masteragent']">{{ masterPlans?.revenue[month]?.plan ? currencyFormat(masterPlans?.revenue[month]?.plan?.value) : '--' }}</span>
                                        <button type="button" class="btn btn-link p-0 w-auto" *show="['master']"
                                                (click)="openEditTable(editPopover,masterPlans?.revenue[month],month,targetTypes[2])"
                                                [ngbPopover]="popContent" popoverTitle="Change value"
                                                (shown)="focusInput()"
                                                autoClose="outside" triggers="manual"
                                                #editPopover="ngbPopover">
                                            <span>{{ masterPlans?.revenue[month]?.plan ? currencyFormat(masterPlans?.revenue[month]?.plan?.value) : '--' }}</span>
                                        </button>
                                    </td>
                                    <td class="text-right"></td>
                                </tr>
                                <tr>
                                    <td><strong translate>Revenue per Contract</strong></td>
                                    <td *ngFor="let month of monthsList"
                                        class="edit-row text-right"
                                        [ngClass]="{'text-warning': !masterPlans?.revenue[month]?.revenue_per_contract_final}">
                                        {{masterPlans?.revenue[month]?.revenue_per_contract ? currencyFormat(masterPlans?.revenue[month]?.revenue_per_contract) : '--'}}
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong translate>Shops Commissions per Contract</strong></td>
                                    <td *ngFor="let month of monthsList"
                                        class="edit-row text-right">{{masterPlans?.revenue[month]?.shop_per_contract ? currencyFormat(masterPlans?.revenue[month]?.shop_per_contract) : '--'}}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong translate>Company Commissions</strong></td>
                                    <td *ngFor="let month of monthsList;index as i"
                                        class="edit-row text-right"
                                        [ngClass]="{'text-warning': !masterPlans?.revenue[month]?.revenue_per_contract_final}">{{currencyFormat(masterPlans?.revenue[month]?.real)}}
                                    </td>
                                    <td class="text-right">{{currencyFormat(revenueTotal.real)}}</td>
                                </tr>
                                <tr>
                                    <td><strong translate>Shops Commissions</strong></td>
                                    <td *ngFor="let month of monthsList;index as i"
                                        class="edit-row text-right">
                                        <span>{{currencyFormat(masterPlans?.revenue[month]?.shop_commission)}}</span>
                                    </td>
                                    <td class="text-right">{{currencyFormat(revenueTotal.shopCommission)}}</td>
                                </tr>
                                <tr>
                                    <td><strong translate>Agent Commissions</strong></td>
                                    <td *ngFor="let month of monthsList;index as i"
                                        class="edit-row text-right">
                                        <span>{{currencyFormat(masterPlans?.revenue[month]?.cost)}}</span>
                                    </td>
                                    <td class="text-right">{{currencyFormat(revenueTotal.cost)}}</td>
                                </tr>
                                <tr>
                                    <td><strong translate>Net Margin</strong></td>
                                    <td *ngFor="let month of monthsList;index as i"
                                        class="edit-row text-right">
                                        <span>{{currencyFormat(masterPlans?.revenue[month]?.net_margin)}}</span>
                                    </td>
                                    <td class="text-right">{{currencyFormat(revenueTotal.netMargin)}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row align-items-center">
                        <div class="col-xl-2 text-center pl-3"><img class="p-1 img-circle bg-gradient-light"
                                                                    src="/assets/img/flats/target.png" height="64px">
                        </div>
                        <div class="col pl-lg-4 text-center text-lg-left">
                            <p class="lead mb-0">{{currencyFormat(revenueTotal.average)}} / <span translate>month</span>
                            </p>
                            <p class="lead mb-0"><span translate>Target</span> {{targetYear}}</p>
                        </div>
                        <div class="col-xl-12">
                            <hr>
                        </div>
                        <div class="col-xl-12 text-center text-lg-left">
                            <app-agents-performance [isInSchedule]="revenueTotal.isInSchedule" [filter]="filterForm"
                                                    [monthsList]="monthsList"
                                                    [targetType]="targetTypes[2]"></app-agents-performance>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row align-items-lg-center">
                <div class="col">
                    <div class="row align-items-center">
                        <div class="col-xl-2 text-center pl-3"><img class="p-1 img-circle bg-gradient-light"
                                                                    src="/assets/img/flats/online-store.png"
                                                                    height="64px"></div>
                        <div class="col pl-lg-4 text-center text-lg-left">
                            <p class="lead mb-0">{{revenueAverage.master}}</p>
                            <p class="lead mb-0" translate>Average consumer / business master token</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row align-items-center">
                        <div class="col-xl-2 text-center pl-3"><img class="p-1 img-circle bg-gradient-light"
                                                                    src="./assets/img/flats/online-store.png"
                                                                    height="64px"></div>
                        <div class="col pl-lg-4 text-center text-lg-left">
                            <p class="lead mb-0">{{revenueAverage.dealer}}</p>
                            <p class="lead mb-0" translate>Average consumer / business shop token</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row align-items-center">
                        <div class="col-xl-2 text-center pl-3"><img class="p-1 img-circle bg-gradient-light"
                                                                    src="/assets/img/flats/employee.png" height="64px">
                        </div>
                        <div class="col pl-lg-4 text-center text-lg-left">
                            <p class="lead mb-0">{{revenueAverage.agent}}</p>
                            <p class="lead mb-0" translate>Average consumer / business agent token</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #popContent>
    <ngx-spinner [fullScreen]="false" bdColor="rgba(255, 255, 255, .7)"
                 color="#333333" name="planSpinner"
                 class="h-100 w-100"
                 type="ball-clip-rotate-multiple"
                 size="small">
    </ngx-spinner>
    <p class="text-muted" translate>{{showPopupNote()}}</p>
    <form class="d-flex align-items-center my-2" (ngSubmit)="changePlan()" [formGroup]="editPlan">
        <div class="form-group mr-1  my-auto ">
            <input type="number" min="0" max="10000000"
                   class="pl-2 form-control" id="input"
                   oninput="validity.valid|| (value=value.slice(0, -1))"
                   formControlName="planValue">
        </div>
        <button class="btn btn-info mr-1" type="submit">
            <i class="fa fa-check m-0"></i></button>
        <button class="btn btn-light"
                (click)="closeEditTable()"><i
                class="fa fa-times m-0"></i>
        </button>
    </form>
</ng-template>
<ng-template #scheduleTitle>Schedule
</ng-template>


