import {BaseModel} from '@models/base-model';
import {DealerView} from '../dealer-view';
import {MetaPagination} from '../meta-pagination';

export class EventiPresidiati extends BaseModel {
    id: number;
    id_dealer: number;
    dealer: DealerView;
    nome: string;
    indirizzo: string;
    comune: string;
    provincia: string;
    from_date: string;
    to_date: string;
    evento_tipologia: string;
    evento_allestimenti: string;
    note_allestimento: string;
    costo_totale: number;
    stima_contratti: number;
    motivo_no_contratto: string;
    stato: number;
    file_contratto: string;
    file_url: string;
}

export class EventsPagination extends BaseModel {
    data: EventiPresidiati[];
    _meta: MetaPagination;
    _links: Array<any>;
}
