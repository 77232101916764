<ngx-spinner
        bdColor="rgba(51,51,51,0.76)"
        size="medium"
        color="#fff"
        type="timer"
        [fullScreen]="true"
>
    <p style="color: white"> Caricamento... </p>
</ngx-spinner>

<div class="row" *ngIf="this.data">
    <div class="col-12">
        <div class="card" [ngClass]="this.data.header.bg">
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col-8">
                        <h1 class="display-4 mb-0">Sintesi Economica per {{this.data.header.tipo}}</h1>
                    </div>
                    <div class="col-4 text-right">
                        <h1 class="d-inline-block display-6"><em>{{this.startup.periodoLabel}}</em></h1>
                    </div>
                </div>
                <hr>
                <div class="row align-items-end">
                    <div class="col-xl-12">
                        <label>Seleziona periodo</label>
                        <div class="form-group form-group-icon mb-0">
                            <i class="far fa-calendar-alt"></i>
                            <select class="form-control" [(ngModel)]="this.periodo">
                                <ng-container *ngIf="this.startup">
                                    <option *ngFor="let per of this.startup.periodi" [value]="per.value">{{per.label}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mt-4">
                    <div class="col">
                        <div class="card bg-gradient-light elevation-1 zoom">
                            <div class="card-body">
                                <h1 class="text-center display-5"><i class="fas fa-file-invoice-dollar mr-2"></i>Fatturato</h1>
                                <hr>
                                <h1 class="text-center display-5">{{this.data.header.master | currency: '€ '}}</h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-1 text-center">
                        <h1 class="text-center">-</h1>
                    </div>
                    <div class="col">
                        <div class="card bg-gradient-light elevation-1 zoom">
                            <div class="card-body">
                                <h1 class="text-center display-5"><i class="fas fa-hand-holding-usd mr-2"></i>Costi</h1>
                                <hr>
                                <h1 class="text-center display-5">{{this.data.header.agente+this.data.header.dealer | currency: '€ '}}</h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-1">
                        <h1 class="text-center">=</h1>
                    </div>
                    <div class="col">
                        <div class="card bg-gradient-light elevation-1 zoom">
                            <div class="card-body">
                                <h1 class="text-center display-5"><i class="fas fa-coins mr-2"></i>Utile</h1>
                                <hr>
                                <h1 class="text-center display-5">{{this.data.header.utile | currency: '€ '}}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <label>Colonne disponibili</label>
<!--                        <tag-input [(ngModel)]="allColumns"-->
<!--                                   [modelAsStrings]="false"-->
<!--                                   [theme]="'bootstrap'"-->
<!--                                   [removable]="false"-->
<!--                                   [hideForm]="true"-->
<!--                                   [identifyBy]="'key'" [displayBy]="'text'"-->
<!--                                   [dragZone]="'zone1'">-->
<!--                        </tag-input>-->
                    </div>
                    <div class="col-6">
                        <label>Colonne selezionate</label>
<!--                        <tag-input [(ngModel)]="selectedColumns"-->
<!--                                   [theme]="'bootstrap'"-->
<!--                                   [modelAsStrings]="false"-->
<!--                                   [identifyBy]="'key'" [displayBy]="'text'"-->
<!--                                   [hideForm]="true"-->
<!--                                   [removable]="false"-->
<!--                                   (ngModelChange)="refresh()"-->
<!--                                   [dragZone]="'zone1'">-->
<!--                        </tag-input>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-6" >
        <app-master-card
                *ngFor="let table of this.data.tables"
                bodyClass="p-0"
                [title]="table.title"
                [height]="false"
        >
            <app-table
                    [rows]="table.table.rows" [columns]="table.table.columns" [small]="true" [hideFooter]="true">
            </app-table>
        </app-master-card>
    </div><div class="col-6" >
        <app-master-card
                bodyClass="p-0"
                title="Agenti"
                [height]="false"
        >
            <app-table
                    [rows]="this.data.agenti.rows" [columns]="this.data.agenti.columns" [small]="true" [hideFooterDescription]="true">
            </app-table>
        </app-master-card>
        <app-master-card
                bodyClass="p-0"
                title="Negozi"
                [height]="false"
        >
            <app-table
                    [rows]="this.data.negozi.rows" [columns]="this.data.negozi.columns" [small]="true" [hideFooterDescription]="true">
            </app-table>
        </app-master-card>
    </div>
</div>
