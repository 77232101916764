// @ts-ignore
import {version} from '../../package.json';

export const environment = {
        production: true,
        title: 'Master Attivazioni Develop',
        assetsUrl: 'https://ewr1.vultrobjects.com/mgroup/static/',
        baseAwsUrl: 'https://ewr1.vultrobjects.com',
        baseUrl: 'https://develop.api.master-attivazioni.it/',
        apiUrl: 'https://develop.api.master-attivazioni.it/v1',
        dashboardUrl: 'https://develop.api.master-attivazioni.it/dashboard',
        operatorUrl: 'https://develop.api.master-attivazioni.it/operator',
        language: 'it',
        startDate: '2019-01',
        whatsAppUrl: 'https://wa.me/393925027344',
        telegramUrl: 'https://t.me/mastergrouptlc',
        company: {
            url: 'https://master-dealer.it',
            name: 'Master group'
        },
        version
    }
;
