import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Helpers } from "@helpers/helpers";
import * as XLSX from "xlsx";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DaterangepickerConfig } from "ng2-daterangepicker";
import * as moment from "moment";
import { Moment } from "moment";

export interface TableColumn {
  key: string;
  text: string;
  icon?: string;
  buttonText?: string;
  alignment?: TableColumnAlignment;
  type?: TableColumnType;
  modal?: string;
  url?: string;
  params?: string;
}

// Usato per riconoscere il tipo di dato nella colonna ed applicare trasformazioni se necessario
export enum TableColumnType {
  CHECKBOX,
  DOCUMENT,
  IMAGE,
  DATE,
  CURRENCY,
  BADGE,
  BUTTON,
  EMAIL = 7,
  WHATSAPP = 8,
  ICON = 9,
  AUDIO = 10,
  TRIGGER,
  PERCENT = 12,
}

export enum TableColumnAlignment {
  LEFT,
  CENTER,
  RIGHT,
}

// Interfaccia che abilita all'ordinamento per colonna
interface TableColumnWithOrdering extends TableColumn {
  order?: TableColumnOrdering;
}

enum TableColumnOrdering {
  ASC,
  DESC,
}

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent<T> implements OnInit {
  page = 1;

  searchFilter = "";
  datePipe = new DatePipe("it");

  autoloadTableUrl: string = null;

  @Input() tableTitle = "";
  @Input() baseUrl = "";
  @Input() export = false;
  @Input() small = false;
  @Input() hideFooterDescription = false;
  @Input() hideFooter = false;
  @Input() firstColumnClass = false;
  @Input() headerClass = false;
  @Input() striped = true;
  @Input() hover = false;
  @Input() hideHeader = false;
  @Input() hideTable = false;
  @Input() pageSize = 10;

  @Input() pagination = 10;

  @Input() filters: { from; to };
  @Input() additionalData: any[] = [];

  dateRange;
  selectedDateRange = {
    from: "",
    to: "",
  };

  @Input() rows: T[];

  @Input() set columns(value: TableColumn[]) {
    this._columns = value;
    this.generateColumnsWithOrdering();
  }

  get columns() {
    if (!this._columns) {
      return [];
    }
    return this._columns;
  }

  @Output() onSelect = new EventEmitter<any>();
  @Output() onTrigger = new EventEmitter();
  @Output() onAdditionalData = new EventEmitter();
  @Output() onFilters = new EventEmitter();

  // Ordine righe originale per ripristinarle in caso di ordinamento mancante
  originalRows: T[] = [];
  columnsWithOrdering: TableColumnWithOrdering[] = [];

  // tslint:disable-next-line:variable-name
  _filteredRows: T[] = [];
  // tslint:disable-next-line:variable-name
  _filteredPaginatedRows: T[] = [];

  // tslint:disable-next-line:variable-name
  _columns: TableColumn[] = [];

  constructor(
    private modalService: NgbModal,
    private dateRangePickerOptions: DaterangepickerConfig
  ) {
    this.dateRangePickerOptions.settings.locale.format = "DD/MM/YYYY";
    this.dateRangePickerOptions.settings.ranges = {
      "Ultimi 7 giorni": [moment().subtract(6, "days"), moment()],
      "Ultimi 30 giorni": [moment().subtract(29, "days"), moment()],
      "Questo mese": [moment().startOf("month"), moment().endOf("month")],
      "Questo anno": [moment().startOf("year"), moment().endOf("month")],
      "Mese scorso": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
      "Anno scorso": [
        moment().subtract(1, "year").startOf("year"),
        moment().subtract(1, "year").endOf("year"),
      ],
    };
  }

  ngOnInit(): void {
    // this.generateColumnsWithOrdering();
    if (
      this.filters &&
      this.filters.hasOwnProperty("from") &&
      this.filters.hasOwnProperty("to")
    ) {
      this.dateRange = this.getDateString(this.filters.from, this.filters.to);
    }
  }

  setSelectedDateRange(from: Moment, to: Moment) {
    const selectedDateRange = this.getDateString(from, to);
    this.dateRange = selectedDateRange;
    this.onFilters.emit({
      from: this.selectedDateRange.from,
      to: this.selectedDateRange.to,
    });
  }

  private getDateString(startDate: any, endDate: any) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    const start = moment(startDate);
    const end = moment(endDate);
    this.selectedDateRange.from = start.format("YYYY-MM-DD");
    this.selectedDateRange.to = end.format("YYYY-MM-DD");
    return (
      start.format(localDateFormat) +
      this.dateRangePickerOptions.settings.locale.separator +
      end.format(localDateFormat)
    );
  }

  generateColumnsWithOrdering() {
    this.columnsWithOrdering = this.columns.map((column) =>
      Object.assign({}, column, { order: null })
    );
  }

  filterTable(event: Event) {
    const input = event.target as HTMLInputElement;
    let filter: string = input.value;
    this.searchFilter = filter.toLowerCase();
  }

  filteredRows(): T[] {
    // Make a backup of rows if required
    if (
      (!this.originalRows || this.originalRows.length === 0) &&
      typeof this.rows !== "undefined"
    ) {
      this.originalRows = this.rows.slice(0);
    }

    if (this.searchFilter === "") {
      return this.rows;
    }

    // Filtra tutte le righe...
    return this.rows?.filter((row) =>
      // ...se almeno in una colonna esistente trovo un valore trasformato (es. in caso di una data) che soddisfa il filtro applicato
      this.columnsWithOrdering.some(
        (column) =>
          row[column.key] &&
          this.transformData(row[column.key], column.type)
            .toLowerCase()
            .indexOf(this.searchFilter) >= 0
      )
    );
  }

  filteredPaginatedRows(): T[] {
    const rows = this.filteredRows();
    if (this.pagination) {
      const startIndex = (this.page - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize - 1;
      this._filteredRows = rows;
      this._filteredPaginatedRows = rows?.filter((row) => {
        const index = rows.indexOf(row);
        return index >= startIndex && index <= endIndex;
      });
    } else {
      this._filteredPaginatedRows = rows;
    }
    return this._filteredPaginatedRows;
  }

  // Usa questa funzione di trasformazione nel momento in cui vuoi cercare i dati nel formato corretto
  transformData(data: any, columnType: TableColumnType): string {
    switch (columnType) {
      case TableColumnType.DATE:
        return this.datePipe.transform(data, "d MMM y");
      case TableColumnType.CURRENCY:
        return (Math.round(parseFloat(data) * 100) / 100).toString();
      default:
        return data.toString();
    }
  }

  getTotalPages() {
    return Math.max(Math.ceil(this._filteredRows?.length / this.pageSize), 1);
  }

  clearOtherOrderingRules(keepColumn: TableColumnWithOrdering) {
    this.columnsWithOrdering.forEach((column) =>
      column !== keepColumn ? (column.order = null) : null
    );
  }

  switchColumnOrdering(column: TableColumnWithOrdering) {
    if (column.type === TableColumnType.BUTTON) {
      return;
    }

    this.clearOtherOrderingRules(column);

    switch (column.order) {
      case TableColumnOrdering.ASC:
        column.order = TableColumnOrdering.DESC;
        break;
      case TableColumnOrdering.DESC:
        column.order = null;
        break;
      case null:
        column.order = TableColumnOrdering.ASC;
        break;
    }

    this.orderByKey(column.key, column.order);
  }

  orderByKey(key: string, tableColumnOrdering: TableColumnOrdering) {
    switch (tableColumnOrdering) {
      case TableColumnOrdering.ASC:
        this.rows = this.rows.sort((a, b) => {
          if (
            !Object.values(b).includes("TOTALE") &&
            !Object.values(a).includes("TOTALE")
          ) {
            return Helpers.compare(a, b, key);
          }
          return 0;
        });
        break;
      case TableColumnOrdering.DESC:
        this.rows = this.rows.sort((a, b) => {
          if (
            !Object.values(b).includes("TOTALE") &&
            !Object.values(a).includes("TOTALE")
          ) {
            return Helpers.compare(b, a, key);
          }
          return 0;
        });
        break;
      case null:
        this.rows = this.originalRows.slice(0);
        break;
    }
  }

  isInteractable() {
    return this.onSelect.observers.length > 0;
  }

  getColumnTypes() {
    return TableColumnType;
  }

  getColumnOrdering() {
    return TableColumnOrdering;
  }

  getTableColumnAlignment(alignment: TableColumnAlignment) {
    switch (alignment) {
      case TableColumnAlignment.LEFT:
        return "left";
      case TableColumnAlignment.CENTER:
        return "center";
      case TableColumnAlignment.RIGHT:
        return "right";
    }
  }

  isNumber(rowElement: any) {
    return !isNaN(rowElement);
  }

  exportTable() {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rows);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "export.xlsx");
  }

  openAutoloadTable(modal, url, params, data) {
    for (let i = 0; i < params.length; i++) {
      url = url.replace(`{${params[i]}}`, data[params[i]]);
    }
    this.autoloadTableUrl = url;

    this.modalService.open(modal, { size: "xl" });
  }

  onButtonTrigger(key: string, rowElement: any) {
    this.onTrigger.emit({
      trigger: key,
      value: rowElement,
    });
  }

  getLink(url, params, data) {
    for (let i = 0; i < params.length; i++) {
      url = url.replace(`{${params[i]}}`, data[params[i]]);
    }

    return url;
  }

  getRowClass(row: T, key: string) {
    if ("class" in row) {
      if (key in row["class"]) {
        return row["class"][key];
      }
    }

    return null;
  }

  getQuestion(row: T, column: TableColumnWithOrdering) {
    if ("questions" in row) {
      if (column.key in row["questions"]) {
        return row["questions"][column.key];
      }
    }
    return false;
  }

  updateAdditionalData() {
    this.onAdditionalData.emit(this.additionalData);
  }

  updateFilters($event: any) {
    this.onFilters.emit($event);
  }

  getIcon(column: TableColumnWithOrdering) {
    if ("icon" in column) {
      return column["icon"];
    }

    return "fa fa-check";
  }
}
