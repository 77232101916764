import {Pipe, PipeTransform} from '@angular/core';
import {AverageTime} from '@models/shop-order';

@Pipe({ name: 'timeToText' })
export class TimeToTextPipe implements PipeTransform {
  constructor() {
  }

  transform(value: AverageTime): string {
      let averageTime = '';
      if (value) {
          if (value.years) {
              averageTime = averageTime + value.years + ' y ';
          }
          if (value.months) {
              averageTime = averageTime + value.months + ' m ';
          }
          if (value.days) {
              averageTime = averageTime + value.days + ' d ';
          }
          if (value.hours) {
              averageTime = averageTime + value.hours + ' hr ';
          }
          if (value.minuts) {
              averageTime = averageTime + value.minuts + ' min ';
          }
          if (value.seconds) {
              averageTime = averageTime + value.seconds + ' sec ';
          }
      }
      return averageTime;
  }
}
