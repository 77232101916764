import {Component, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {
  uniqueID;

  constructor(private spinner: NgxSpinnerService) {
    this.uniqueID = Math.random().toString(36).substring(7);
  }

  message: string;

  ngOnInit() {
    this.spinner.show('loader-table-'+ this.uniqueID);
  }
}
