import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataBuilderModalModel, DataBuilderModalService} from '@services/data-builder-modal.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'data-builder-modal',
    templateUrl: './data-builder-modal.component.html',
    styleUrls: ['./data-builder-modal.component.css']
})
export class DataBuilderModalComponent implements OnInit, OnDestroy {
    @ViewChild('modal') template;
    model: DataBuilderModalModel;
    subscription: Subscription;

    constructor(public service: DataBuilderModalService, private modalService: NgbModal) {
    }

    ngOnInit(): void {
        this.subscription = this.service.modal.subscribe((modal) => {
            this.model = modal;
            this.modalService.open(this.template, { size: 'xl' });
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
