<div class="card bg-gradient-white">
  <div class="card-body mb-0">
    <div class="row">
      <div class="col-12 col-md-4">
        <table
          class="table table-bordered table-sm w-auto bg-gradient-white mb-0"
        >
          <thead *ngIf="date_info">
            <tr>
              <th width="50%">Giorni Utili</th>
              <td class="text-right">{{ date_info.all_working_days }}</td>
            </tr>
            <tr>
              <th>Giorni Lavorati</th>
              <td class="text-right">{{ date_info.past_working_days }}</td>
            </tr>
            <tr>
              <th>Giorni Rimanenti</th>
              <td class="text-right">
                {{ date_info.remaining_working_days }}
              </td>
            </tr>
          </thead>
        </table>
      </div>

      <div class="col-12 col-md-4">
        <div class="form-group">
          <label>Seleziona un key account</label>
          <app-agent-select (onChange)="setAgent($event)"></app-agent-select>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label>Scegli il periodo</label>
          <app-month-range-picker
            [singleMonthPicker]="true"
            (selected)="setSelectedDateRange($event)"
            [defaultMonthRange]="defaultMonth"
          ></app-month-range-picker>
        </div>
      </div>
    </div>
  </div>
</div>

<ngb-alert [dismissible]="false" type="info" *ngIf="!agent">
  Devi selezionare un key account per mostrare il piano visite.
</ngb-alert>

<div class="card">
  <div class="card-body p-0 table-responsive">
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      size="default"
      color="#fff"
      type="square-jelly-box"
      [fullScreen]="false"
    >
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <table class="table table-bordered table-striped table-hover table-sm mb-0">
      <thead>
        <tr *ngIf="date">
          <th colspan="5"></th>
          <th colspan="2" class="text-center bg-success">{{ date.year }}</th>
          <th colspan="2" class="text-center table-dark">
            {{ date.prev_month }}/{{ date.prev_year }}
          </th>
          <th colspan="2" class="text-center bg-success">
            {{ date.month }}/{{ date.year }}
          </th>
          <th [colSpan]="days.length" class="text-center table-dark">
            Piano attività visite di {{ date.full_month }}
          </th>
        </tr>
        <tr>
          <th>Codice</th>
          <th>Punto Vendita</th>
          <th>Città</th>
          <th>Provincia</th>
          <th>Data Affiliazione</th>
          <th class="text-center">Sim</th>
          <th class="text-center">% Peso</th>
          <th class="text-center">Sim</th>
          <th class="text-center">% Peso</th>
          <th class="text-center">Sim</th>
          <th class="text-center">% Peso</th>
          <th *ngFor="let day of days" class="text-center">
            {{ day | amDateFormat: "DD" }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="table-info" *ngIf="total">
          <th colspan="5">TOTALE</th>
          <th class="text-right">{{ total.ytd }}</th>
          <th></th>
          <th class="text-right">{{ total.mtd_prev }}</th>
          <th></th>
          <th class="text-right">{{ total.mtd }}</th>
          <th></th>
          <th *ngFor="let visits of visits_by_day" class="text-center">
            {{ visits }}
          </th>
        </tr>
        <tr *ngFor="let pdv of retailers">
          <td>{{ pdv.code }}</td>
          <td>{{ pdv.ragsociale }}</td>
          <td>{{ pdv.city }}</td>
          <td>{{ pdv.province }}</td>
          <td>{{ pdv.affiliation_date | amDateFormat: "DD-MM-YY" }}</td>
          <td class="text-right">{{ pdv.ytd }}</td>
          <td class="text-right">{{ pdv.ytd_weight | percent }}</td>
          <td class="text-right">{{ pdv.mtd_prev }}</td>
          <td class="text-right">{{ pdv.mtd_prev_weight | percent }}</td>
          <td class="text-right">{{ pdv.mtd }}</td>
          <td class="text-right">{{ pdv.mtd_weight | percent }}</td>
          <td
            *ngFor="let day of days"
            class="text-center"
            [ngClass]="{ 'bg-success cursor-hand': visitWithResults(day, pdv) }"
            [ngbPopover]="popContent"
            [popoverTitle]="popTitle"
            triggers="manual"
            #p1="ngbPopover"
            container="body"
            (click)="showVisitPopover(p1, pdv, day)"
          >
            <i
              class="fas"
              [ngClass]="{
                'fa-check': isVisitedInDay(day, pdv),
                'text-success': !visitWithResults(day, pdv),
                'text-white': visitWithResults(day, pdv)
              }"
            ></i>
          </td>
        </tr>
        <tr class="table-info" *ngIf="total">
          <th colspan="5">TOTALE</th>
          <th class="text-right">{{ total.ytd }}</th>
          <th></th>
          <th class="text-right">{{ total.mtd_prev }}</th>
          <th></th>
          <th class="text-right">{{ total.mtd }}</th>
          <th></th>
          <th *ngFor="let visits of visits_by_day" class="text-center">
            {{ visits }}
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #popTitle>Visita</ng-template>
<ng-template #popContent let-visit="visit">
  <ng-container *ngFor="let result of visit.results; let i = index;"> 
    <p *ngIf="result.evento === 'started-to-be-productive'">Diventato produttivo</p>
    <p *ngIf="result.evento === 'increased-sales'">Ha aumentato le vendite</p>
    <p *ngIf="result.evento === 'bought-plafond'">Ha comprato il plafond</p>
    <p *ngIf="result.evento === 'bought-sim'">Ha comprato le sim</p>
    <hr *ngIf="i < visit.results.length-1">
  </ng-container>
  
</ng-template>