import {Component, Input, OnInit} from '@angular/core';
import {DataService} from '@services/data.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-dashboard-riepilogo',
  templateUrl: './dashboard-riepilogo.component.html',
  styleUrls: ['./dashboard-riepilogo.component.scss']
})
export class DashboardRiepilogoComponent implements OnInit {
  @Input()
  logo: string;
  @Input()
  title: string;
  @Input()
  dataUrl: string;
  @Input()
  bgClass: any;

  chartData: any;
  summaryTitle: any;
  summaries: any;
  footerIcons: any;

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit() {
    this.spinner.show();
    this.dataService.get(this.dataUrl)
      .subscribe((response: any) => {
        this.summaryTitle = response.result.summaryTitle;
        this.chartData = response.result.chartData;
        this.footerIcons = response.result.footerIcons;
        this.summaries = response.result.summaries;
        this.spinner.hide();
      });
  }

}
