<div class="modal-header">
    <h4 class="modal-title clamp-title" translate>Add Call Report</h4>
    <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="dismissModal()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [ngBusy]="modalBusy">
    <div class="row mb-2">
        <div class="col-4">
            <h4>
                <i class="fas fa-store text-info mr-2"></i>{{lead ? lead?.company_owner : call?.lead.company_owner}}
            </h4>
        </div>
        <div class="col-4 text-center">
            <h4>
                <i class="fas fa-headset text-info mr-2"></i>{{operator ? operator?.profile?.ragione_sociale : call?.caller_name}}
            </h4>
        </div>
        <div class="col-4 d-flex justify-content-end">
            <h4>
                <i class="fas fa-phone text-success mr-2"></i> {{phoneNumber}}
            </h4>
        </div>
    </div>
    <form [formGroup]="callForm" (ngSubmit)="save()">
        <div class="form-row">
            <div class="col-4 form-group">
                <label for="startDate" translate>Start datetime</label>
                <input class="form-control" id="startDate" type="datetime-local" formControlName="start_time">
                <val-errors controlName="start_time">
                    <ng-template valError="required">
                        <span translate>Start datetime is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-4 form-group">
                <label for="duration" translate>Duration</label>
                <input class="form-control" id="duration" type="text" formControlName="duration"
                       placeholder="00 : 00 : 00" mask="00:00:00">
                <val-errors controlName="duration">
                    <ng-template valError="required">
                        <span translate>Duration is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-4 form-group">
                <label for="phone" translate>Phone</label>
                <ng-select id="phone" formControlName="destination" (change)="changePhoneNumber($event)"
                           [(ngModel)]="phoneNumber">
                    <ng-option [value]="lead?.cell_phone">{{lead?.cell_phone}}</ng-option>
                    <ng-option [value]="lead?.phone">{{lead?.phone}}</ng-option>
                </ng-select>
                <val-errors controlName="destination">
                    <ng-template valError="required">
                        <span translate>Phone number is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-8 form-group">
                <label translate>Call Status</label>
                <div class="form-check form-check-inline mr-4" *ngFor="let status of callStatuses; index as i">
                    <input class="form-check-input" type="radio" name="call_status" [id]="'call-'+status.key"
                           [value]="status.key" formControlName="call_status">
                    <label class="form-check-label" [for]="'call-'+status.key" translate>
                        {{status.name}}
                    </label>
                </div>
            </div>
            <div class="col-4 form-group">
                <label translate>Feedback Status</label>
                <div class="form-check form-check-inline mr-4" *ngFor="let status of feedbackStatuses; last as isLast">
                    <input class="form-check-input" type="radio" name="feedback_status" [id]="status.name"
                           [value]="status.key" formControlName="feedback_status">
                    <label class="form-check-label" [for]="status.name" translate>
                        {{status.name}}
                    </label>
                </div>
            </div>
            <div class="col-12" *ngIf="lead.table_url">
                <p>
                    <button type="button" class="btn btn-outline-primary" (click)="showTable = !showTable"
                            aria-controls="collapseExample">
                        Mostra Dati
                    </button>
                </p>
                <div #collapse="ngbCollapse" class="mt-2 border" [ngbCollapse]="showTable">
                    <data-builder-table [url]="lead.table_url"></data-builder-table>
                </div>
            </div>
            <div class="col-12 form-group">
                <label for="note" translate>Note</label>
                <editor apiKey="1nilu7q8vcp0ntuviwq5nhcskmj17c6awfhpbnr0hhv4smdw" id="note" [init]="defaultConfig" formControlName="response"></editor>
            </div>
            <div class="col-12" *ngIf="questions?.length>0">
                <h5 translate>Questions:</h5>
                <div class="question-container">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item px-0" formArrayName="answers"
                            *ngFor="let answer of callForm.get('answers').controls; index as i">
                            {{questions[i].info.title}}
                            <div [formGroupName]="i">
                                <div class="float-right"
                                     *ngIf="questions[i].info.question_type == questionTypes[0].key">
                                    <div class="form-check form-check-inline"
                                         *ngFor="let answer of questionTypes[0].answers">
                                        <input class="form-check-input" type="radio" [name]="'text'+questions[i].id"
                                               [id]="answer.key+i" [value]="answer.key"
                                               [formControlName]="'text'+questions[i].id">
                                        <label class="form-check-label" [for]="answer.key" translate>
                                            {{answer.name}}
                                        </label>
                                    </div>
                                </div>
                                <p class="text-muted" [innerHTML]="questions[i].info.text"></p>
                                <div class="mt-2" *ngIf="questions[i].info.question_type == questionTypes[1].key">
                                    <editor apiKey="1nilu7q8vcp0ntuviwq5nhcskmj17c6awfhpbnr0hhv4smdw" [init]="defaultConfig" [formControlName]="'text'+questions[i].id"></editor>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 mt-3">
                <button type="submit" class="btn btn-success btn-block" [disabled]="callForm.invalid">
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                </button>
            </div>
        </div>
    </form>
</div>
