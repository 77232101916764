<app-master-header [ngBusy]="busy"
                   cardClass="bg-gradient-light"
                   image="assets/img/flats/statistics.png"
                   title="Produzione Persa"
                   [operatori]="true"
                   [periodoFrom]="true"
                   [periodoTo]="true"
                   (onChange)="this.updateUrls($event.operatore, $event.from, $event.to)"
>
</app-master-header>

<app-master-subheader [free]="true" cardClass="bg-tiscali-gradient" [url]="urlSubheader"></app-master-subheader>

<div class="row">
    <div class="col-12 col-xl-6">
        <app-master-card
                bodyClass="p-0"
                [title]="(stand ? 'Venditori' : 'Negozi') + ' che producono meno'"
                image="assets/img/flats/settings.png"
        >
            <data-builder-table [url]="urlNegoziMenoProduttivi"></data-builder-table>
        </app-master-card>
    </div>
    <div class="col-12 col-xl-6">
        <app-master-card
                bodyClass="p-0"
                [title]="(stand ? 'Venditori' : 'Negozi') + ' che non producono più'"
                image="assets/img/flats/loss.png"
        >
            <data-builder-table [url]="urlNegoziImproduttivi"></data-builder-table>
        </app-master-card>
    </div>
    <div class="col-4" *ngIf="false">
        <app-master-card
                title="Possibili motivazioni"
                image="assets/img/flats/help.png"
        >
        </app-master-card>
    </div>
</div>
