<form [formGroup]="form" (ngSubmit)="submit()" [ngBusy]="busy">
  <div class="row mb-2">
    <div class="col-12">
      <button class="btn btn-secondary">Indietro</button>
      <button class="btn btn-success float-right" [disabled]="this.form.invalid">Inserisci</button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card bg-gradient-light">
        <div class="card-header">
          <h1 class="mb-0 display-6"><i class="fa fa-calendar-check"></i> Nuovo Evento</h1>
        </div>
        <div class="card-body">
          <h1 class="display-7 text-muted"><i class="fa fa-list-ul"></i> Evento</h1>
          <hr>
          <div class="row">
            <div class="col-12 col-md-4 col-lg-3">
              <div class="form-group">
                <label>Nome</label>
                <input type="text" class="form-control" formControlName="nome" placeholder="Fiera del Levante ..."/>
                <val-errors controlName="nome">
                  <ng-template valError="required">
                    Il nome è obbligatorio
                  </ng-template>
                </val-errors>
              </div>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
              <div class="form-group">
                <label>Provincia</label>
                <input type="text" class="form-control" formControlName="provincia"/>
                <val-errors controlName="provincia">
                  <ng-template valError="required">
                    La provincia è obbligatoria
                  </ng-template>
                </val-errors>
              </div>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
              <div class="form-group">
                <label>Città</label>
                <input type="text" class="form-control" formControlName="comune"/>
                <val-errors controlName="comune">
                  <ng-template valError="required">
                    Il comune è obbligatorio
                  </ng-template>
                </val-errors>
              </div>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
              <div class="form-group">
                <label>Indirizzo</label>
                <input type="text" class="form-control" formControlName="indirizzo"/>
                <val-errors controlName="indirizzo">
                  <ng-template valError="required">
                    L'indirizzo è obbligatorio
                  </ng-template>
                </val-errors>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Descrizione</label>
                <textarea class="form-control" rows="6"
                          formControlName="descrizione"
                          placeholder="Descrivere l'organizzazione dell'evento e indicare eventuali azioni speciali per utilizzo della contribuzione richiesta (valore aggiunto, presenza di hostess etc)..."></textarea>

                <val-errors controlName="descrizione">
                  <ng-template valError="required">
                    La descrizione è obbligatorio
                  </ng-template>
                </val-errors>
              </div>
            </div>
          </div>

          <h1 class="display-7 text-muted"><i class="fa fa-calendar-alt"></i> Programmazione</h1>
          <hr>

          <!-- Periodo globale -->
          <div class="row">
            <div class="col-12 col-md-4 col-lg-3">
              <div class="form-group">
                <label>Periodo globale</label>
                <app-data-range formControlName="periodo_globale"></app-data-range>
                <div *ngIf="c().periodo_globale.invalid && (c().periodo_globale.dirty || c().periodo_globale.touched)" class="ng-invalid val-errors">
                  <div *ngIf="c().periodo_globale.errors.required">
                    Il periodo è obbligatorio
                  </div>
                  <div *ngIf="c().periodo_globale.errors.period">
                    Il periodo inserito non è valido
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Periodi -->
          <div formArrayName="periodi">
            <div *ngFor="let row of form.get('periodi')['controls']; index as i" [formGroupName]="i" class="row">
              <div class="col-12 col-md-4 col-lg-4">
                <div class="form-group">
                  <label>Periodo</label>
                  <app-data-range formControlName="periodo"></app-data-range>
                  <div *ngIf="row.controls.periodo.invalid && (row.controls.periodo.dirty || row.controls.periodo.touched) && row.controls.periodo.errors" class="ng-invalid val-errors">
                    <div *ngIf="row.controls.periodo.errors.required">
                      Il periodo è obbligatorio
                    </div>
                    <div *ngIf="row.controls.periodo.errors.period">
                      Il periodo inserito non è valido o non rientra nell'intervallo
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="form-group">
                  <label>Mattina</label>
                  <input formControlName="mattina" type="text" class="form-control" placeholder="09:00 - 13:00" mask="00:00 - 00:00"/>
                  <div *ngIf="row.controls.mattina.invalid && (row.controls.mattina.dirty || row.controls.mattina.touched) && row.controls.mattina.errors" class="ng-invalid val-errors">
                    <div *ngIf="row.controls.mattina.errors.required">
                      L'ora è obbligatoria
                    </div>
                    <div *ngIf="row.controls.mattina.errors.pattern">
                      L'ora inserita non è valida
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="form-group">
                  <label>Pomeriggio</label>
                  <input formControlName="pomeriggio" type="text" class="form-control" placeholder="14:00 - 21:00" mask="00:00 - 00:00"/>
                  <div *ngIf="row.controls.pomeriggio.invalid && (row.controls.pomeriggio.dirty || row.controls.pomeriggio.touched) && row.controls.pomeriggio.errors" class="ng-invalid val-errors">
                    <div *ngIf="row.controls.pomeriggio.errors.required">
                      L'ora è obbligatoria
                    </div>
                    <div *ngIf="row.controls.pomeriggio.errors.pattern">
                      L'ora inserita non è valida
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-2 align-items-end">
                <label>Rimuovi</label>
                <button class="btn btn-danger btn-block" (click)="removePeriodo(i)"><i class="fa fa-times"></i></button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button class="btn btn-info btn-block" (click)="addPeriodo()"><i class="fa fa-plus"></i></button>
            </div>
          </div>

          <h1 class="display-7 text-muted"><i class="fa fa-bullseye"></i> Finanza</h1>
          <hr>
          <div class="row">
            <div class="col-12 col-md-4 col-lg-3">
              <div class="form-group">
                <label>Costo totale</label>
                <input type="number" formControlName="costo_totale" min="0" class="form-control" placeholder="€"/>
                <val-errors controlName="costo_totale">
                  <ng-template valError="required">
                    Il costo totale è obbligatorio
                  </ng-template>
                </val-errors>
              </div>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
              <div class="form-group">
                <label>Stima numero contratti</label>
                <input type="number" formControlName="stima_contratti" min="0" class="form-control" placeholder="N°"/>

                <val-errors controlName="stima_contratti">
                  <ng-template valError="required">
                    La stima dei contratti è obbligatoria
                  </ng-template>
                </val-errors>
              </div>
            </div>
          </div>

          <h1 class="display-7 text-muted"><i class="fa fa-info-circle"></i> Dettagli</h1>
          <hr>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Tipologia Evento</label>

                <ng-select [items]="['Fiera', 'Sagra', 'Evento di piazza', 'Festa patronale']"  formControlName="evento_tipologia"></ng-select>
                <val-errors controlName="evento_tipologia">
                  <ng-template valError="required">
                    La tipologia dell'evento è obbligatoria
                  </ng-template>
                </val-errors>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Allestimenti richiesti</label>

                <ng-select [items]="['Porta Leaflet', 'Porta Locandina', 'Porta TV', 'Tavolo Tondo', 'Manifesti', 'Volantini', 'Hostess', 'Generatore di corrente']" [multiple]="true" formControlName="evento_allestimenti"></ng-select>

                <val-errors controlName="evento_allestimenti">
                  <ng-template valError="required">
                    Gli allestimenti richiesti sono obbligatori
                  </ng-template>
                </val-errors>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Note allestimento</label>
                <textarea class="form-control" rows="6" formControlName="note_allestimento"></textarea>
                <val-errors controlName="note_allestimento">
                  <ng-template valError="required">
                    Le note  dell'allestimento sono obbgliatorie
                  </ng-template>
                </val-errors>
              </div>
            </div>
          </div>
          <h1 class="display-7 text-muted"><i class="fa fa-file-alt"></i> Documenti</h1>
          <hr>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Contratto location</label>
                  <div class="custom-file" *ngIf="!file">
                    <input id="file" type="file" class="custom-file-input"
                           (change)="handleFileInput($event?.target?.files[0])">
                    <label class="custom-file-label" for="file" translate>
                        Upload file...
                    </label>
                </div>
                <div *ngIf="file">
                    <span>{{file}}</span>
                    <button class="btn btn-xs btn-warning float-right" type="button" (click)="handleFileRemoveModal()">
                        <i class="fa fa-times m-0 px-1"></i>
                    </button>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Motivo mancanza contratto location</label>
                <textarea class="form-control" rows="6" formControlName="motivo_no_contratto"></textarea>
                <val-errors controlName="motivo_no_contratto">
                  <ng-template valError="required">
                    Il motivo è obbligatorio
                  </ng-template>
                </val-errors>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <button class="btn btn-secondary">Indietro</button>
      <button class="btn btn-success float-right" [disabled]="this.form.invalid">Inserisci</button>
    </div>
  </div>
</form>
