import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import Handsontable from "handsontable";
import * as XLSX from "xlsx";

@Component({
    selector: 'app-hot',
    templateUrl: './hot.component.html',
    styleUrls: ['./hot.component.css']
})
export class HotComponent implements OnInit {
    @Input() title: string = null;
    @Input() image: string = null;
    @Input() height: number = null;
    @Input() showFilters = false;
    @Input() buttons = [];
    @Input() rows: any = [];
    @Input() cols: [{
        dateFormat: any;
        source: any;
        id: string;
        readonly: boolean;e
        label: string;
        type: string;
        numericFormat: any;
    }] = null;

    hotSettings: Handsontable.GridSettings = {
        afterChange: (changes) => this.update(changes)
    };

    column: Handsontable.ColumnSettings = {
        renderer: (instance, td, row, col, prop, value, cellProperties) => this.renderer(instance, td, row, col, prop, value, cellProperties)
    };

    @Output() triggerAction: EventEmitter<any> = new EventEmitter<any>();

    get columns() {
        return this.cols.map(i => {
            let col =  {
                data: i['id'],
                title: i['label'],
                type: i['type'],
                readOnly: i['readonly'],
                className: i['className'],
                dateFormat: i['dateFormat'],
                source: i['source'],
                numericFormat: null,
                handsontable: null
            };

            if (i.hasOwnProperty('numericFormat')) {
                col.numericFormat = {
                    pattern: i['numericFormat']['pattern'],
                    culture: i['numericFormat']['culture'],
                };
            }
            if (i.hasOwnProperty('handsontable')) {
                col.handsontable = {
                    colHeaders: i['handsontable']['colHeaders'],
                    autoColumnSize: i['handsontable']['autoColumnSize'],
                    data: i['handsontable']['data'],
                    getValue: function() {
                        var selection = this.getSelectedLast();

                        // Get always manufacture name of clicked row and ignore header
                        // coordinates (negative values)
                        return this.getSourceDataAtRow(Math.max(selection[0], 0)).id;
                    },
                };
            }
            return col;
        })
    }

    constructor() {
        console.log(this.buttons);
    }

    ngOnInit() { }

    update(changes) { }

    private renderer(instance: any, td: any, row: any, col: any, prop: any, value: any, cellProperties: any) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        return td;
    }

    btnAction(url: any) {
        this.triggerAction.emit(url);
    }

    exportTable() {
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rows);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'export.xlsx');
    }
}


