import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {RequestService} from '@services/request.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(public request: RequestService) {
    }

    list(endpoint, params = {}) {
        return this.request
            .get(`${endpoint}/list`, params, {observe: 'response'})
            .pipe(
                map((data: HttpResponse<any>) => {
                    return {
                        rows: data.body,
                        pagination: {
                            totalCount: parseInt(data.headers.get('X-Pagination-Total-Count')),
                            page: parseInt(data.headers.get('X-Pagination-Current-Page')),
                            pageSize: parseInt(data.headers.get('X-Pagination-Per-Page')),
                            pages: parseInt(data.headers.get('X-Pagination-Page-Count')),
                        },
                    };
                })
            );
    }

    isUsernameAvailable(endpoint, username) {
        return this.request.get(`${endpoint}/username-available/${username}`);
    }

    isVatAvalaible(endpoint, vat) {
        return this.request.get(`${endpoint}/vat-available/${vat}`);
    }

    saveDealer(endpoint, data) {
        return this.request.post(`${endpoint}`, data);
    }

    updateDealer(endpoint, id, data) {
        return this.request.post(`${endpoint}/${id}`, data);
    }

    deleteDealer(endpoint, id) {
        return this.request.delete(`${endpoint}/${id}`);
    }

    restoreDealer(endpoint, id) {
        return this.request.put(`${endpoint}/restore/${id}`);
    }

    changePassword(endpoint, id, password) {
        return this.request.post(`${endpoint}/change-password/${id}`, {password: password});
    }

    getDealer(endpoint, id, params = {}) {
        return this.request.get(`${endpoint}/${id}`, params);
    }
}
