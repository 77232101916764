<div class="row">
    <div class="col-12">
        <div class="card bg-gradient-info">
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <div class="info-text">
                            <p><i class="fa fa-user-circle"></i> Ragione Sociale</p>
                            <span class="lead">{{dealer.ragsociale}}</span>
                        </div>
                        <div class="info-text">
                            <p><i class="fa fa-stream"></i> Partita IVA</p>
                            <span class="lead">{{dealer.piva}}</span>
                        </div>
                        <div class="info-text">
                            <p><i class="fa fa-address-card"></i> Titolare</p>
                            <span class="lead">{{dealer.nome}} {{dealer.cognome}}</span>
                        </div>
                        <div class="info-text">
                            <p><i class="fa fa-clock"></i> Data Creazione</p>
                            <span class="lead">{{dealer.created | amDateFormat: 'DD/MM/YYYY' }}</span>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <div class="info-text">
                            <p><i class="fa fa-mobile"></i> Cellulare</p>
                            <span class="lead">{{dealer.cellulare}}</span>
                        </div>
                        <div class="info-text">
                            <p><i class="fa fa-phone-alt"></i> Telefono</p>
                            <span class="lead">{{dealer.telefono}}</span>
                        </div>
                        <div class="info-text">
                            <p><i class="fa fa-envelope"></i> E-Mail</p>
                            <span class="lead">{{dealer.email}}</span>
                        </div>
                        <div class="info-text">
                            <p><i class="fa fa-envelope"></i> PEC</p>
                            <span class="lead">{{dealer.pec}}</span>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <div class="info-text" *ngIf="affiliazione(6)">
                            <p><i class="fa fa-arrow-circle-right"></i> Kena Mobile con {{affiliazione(6).agente}}</p>
                            <span class="lead">{{affiliazione(6).code}} <span
                                    class="float-right">{{affiliazione(6).created | amDateFormat: 'DD/MM/YYYY'}}</span></span>
                        </div>
                        <div class="info-text" *ngIf="affiliazione(11)">
                            <p><i class="fa fa-arrow-circle-right"></i> Tim con {{affiliazione(11).agente}}</p>
                            <span class="lead">{{affiliazione(11).code}} <span
                                    class="float-right">{{affiliazione(11).created | amDateFormat: 'DD/MM/YYYY'}}</span></span>
                        </div>
                        <div class="info-text" *ngIf="affiliazione(4)">
                            <p><i class="fa fa-arrow-circle-right"></i> Tiscali con {{affiliazione(4).agente}}</p>
                            <span class="lead">{{affiliazione(4).code}} <span
                                    class="float-right">{{affiliazione(4).created | amDateFormat: 'DD/MM/YYYY'}}</span></span>
                        </div>
                        <div class="info-text" *ngIf="affiliazione(9)">
                            <p><i class="fa fa-arrow-circle-right"></i> Eni gas e luce con {{affiliazione(9).agente}}
                            </p>
                            <span class="lead">{{affiliazione(9).code}} <span
                                    class="float-right">{{affiliazione(9).created | amDateFormat: 'DD/MM/YYYY'}}</span></span>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <div class="card bg-gradient-light">
                            <div class="card-body p-1">
                                <agm-map
                                        *ngIf="data"
                                        [style.height.px]="240"
                                        [latitude]='dealer.lat'
                                        [longitude]='dealer.lon'
                                        [zoom]="13"
                                        mapTypeId='roadmap'>
                                    <agm-marker
                                            [latitude]='dealer.lat'
                                            [longitude]='dealer.lon'
                                    >
                                    </agm-marker>
                                </agm-map>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer p-0">
                <ul ngbNav #nav="ngbNav" class="nav nav-pills nav-fill nav-mg" [activeId]="1">
                    <li ngbNavItem [ngbNavItem]="1" *isVendorEnabled="['Kena Mobile']">
                        <a ngbNavLink><i class="fa fa-chart-pie"></i> Kena Mobile</a>
                        <ng-template ngbNavContent>
                            <div class="card bg-gradient-light">
                                <div class="card-header">
                                    <p class="lead d-inline-block">Andamento Vendite</p>
                                    <app-datepicker-range-popup
                                            [from]="chartPeriod.from"
                                            [to]="chartPeriod.to"
                                            (onSelect)="kenaChart.setUrl('-detail/negozio-chart-kena?id='+this.id+'&from='+ $event.from + '&to=' + $event.to)"
                                            class="float-right"
                                    ></app-datepicker-range-popup>
                                </div>
                                <div class="card-body p-1">
                                    <app-autoload-chartjs #kenaChart type="line"
                                                          url="-detail/negozio-chart-kena?id={{this.id}}"></app-autoload-chartjs>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-3">
                                    <app-autoload-keypoints
                                            url="-detail/negozio-keypoints-kena?id={{this.id}}"
                                    ></app-autoload-keypoints>
                                </div>
                                <div class="col-12 col-lg-3">
                                    <div class="card bg-gradient-light">
                                        <div class="card-header px-1">
                                            <app-datepicker-range-popup
                                                    [from]="riepilogoPeriod.from"
                                                    [to]="riepilogoPeriod.to"
                                                    (onSelect)="kenaRiepilogo.setUrl('-detail/negozio-riepilogo-kena?id='+this.id+'&from='+ $event.from + '&to=' + $event.to)"
                                            ></app-datepicker-range-popup>
                                        </div>
                                        <div class="card-body p-0">
                                            <app-autoload-table-riepilogo
                                                #kenaRiepilogo
                                                url="-detail/negozio-riepilogo-kena?id={{this.id}}"
                                            ></app-autoload-table-riepilogo>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <app-autoload-table
                                            url="-detail/negozio-dettaglio-kena?id={{this.id}}"
                                            [small]="true"
                                            [hideFooterDescription]="true"
                                    ></app-autoload-table>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem [ngbNavItem]="2" *isVendorEnabled="['Tiscali Mobile']">
                        <a ngbNavLink><i class="fa fa-chart-pie"></i> Tiscali Mobile</a>
                        <ng-template ngbNavContent>
                            <div class="card bg-gradient-light">
                                <div class="card-header">
                                    <p class="lead d-inline-block">Andamento Vendite</p>
                                    <app-datepicker-range-popup
                                            [from]="chartPeriod.from"
                                            [to]="chartPeriod.to"
                                            (onSelect)="tiscaliMobileChart.setUrl('-detail/negozio-chart-tiscali-mobile?id='+this.id+'&from='+ $event.from + '&to=' + $event.to)"
                                            class="float-right"
                                    ></app-datepicker-range-popup>
                                </div>
                                <div class="card-body p-1">
                                    <app-autoload-chartjs #tiscaliMobileChart type="line"
                                                          url="-detail/negozio-chart-tiscali-mobile?id={{this.id}}"></app-autoload-chartjs>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-3">
                                    <app-autoload-keypoints
                                            url="-detail/negozio-keypoints-tiscali-mobile?id={{this.id}}"
                                    ></app-autoload-keypoints>
                                </div>
                                <div class="col-12 col-lg-3">
                                    <div class="card bg-gradient-light">
                                        <div class="card-header px-1">
                                            <app-datepicker-range-popup
                                                    [from]="riepilogoPeriod.from"
                                                    [to]="riepilogoPeriod.to"
                                                    (onSelect)="tiscaliMobileRiepilogo.setUrl('-detail/negozio-riepilogo-tiscali-mobile?id='+this.id+'&from='+ $event.from + '&to=' + $event.to)"
                                            ></app-datepicker-range-popup>
                                        </div>
                                        <div class="card-body p-0">
                                            <app-autoload-table-riepilogo
                                                #tiscaliMobileRiepilogo
                                                url="-detail/negozio-riepilogo-tiscali-mobile?id={{this.id}}"
                                            ></app-autoload-table-riepilogo>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-3">
                                    <app-autoload-table
                                            url="-detail/negozio-dettaglio-tiscali-mobile?id={{this.id}}"
                                            [small]="true"
                                            [hideFooterDescription]="true"
                                    ></app-autoload-table>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem [ngbNavItem]="3" *isVendorEnabled="['Tiscali Fisso']">
                        <a ngbNavLink><i class="fa fa-chart-pie"></i> Tiscali Fisso</a>
                        <ng-template ngbNavContent>
                            <div class="card bg-gradient-light">
                                <div class="card-header">
                                    <p class="lead d-inline-block">Andamento Vendite</p>
                                    <app-datepicker-range-popup
                                            [from]="chartPeriod.from"
                                            [to]="chartPeriod.to"
                                            (onSelect)="tiscaliFissoChart.setUrl('-detail/negozio-chart-tiscali-fisso?id='+this.id+'&from='+ $event.from + '&to=' + $event.to)"
                                            class="float-right"
                                    ></app-datepicker-range-popup>
                                </div>
                                <div class="card-body p-1">
                                    <app-autoload-chartjs #tiscaliFissoChart type="line"
                                                          url="-detail/negozio-chart-tiscali-fisso?id={{this.id}}"></app-autoload-chartjs>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-3">
                                    <app-autoload-keypoints
                                            url="-detail/negozio-keypoints-tiscali-fisso?id={{this.id}}"
                                    ></app-autoload-keypoints>
                                </div>
                                <div class="col-12 col-lg-3">
                                    <div class="card bg-gradient-light">
                                        <div class="card-header px-1">
                                            <app-datepicker-range-popup
                                                    [from]="riepilogoPeriod.from"
                                                    [to]="riepilogoPeriod.to"
                                                    (onSelect)="tiscaliFissoRiepilogo.setUrl('-detail/negozio-riepilogo-tiscali-fisso?id='+this.id+'&from='+ $event.from + '&to=' + $event.to)"
                                            ></app-datepicker-range-popup>
                                        </div>
                                        <div class="card-body p-0">
                                            <app-autoload-table-riepilogo
                                                #tiscaliFissoRiepilogo
                                                url="-detail/negozio-riepilogo-tiscali-fisso?id={{this.id}}"
                                            ></app-autoload-table-riepilogo>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-3">
                                    <app-autoload-table
                                            url="-detail/negozio-dettaglio-tiscali-fisso?id={{this.id}}"
                                            [small]="true"
                                            [hideFooterDescription]="true"
                                    ></app-autoload-table>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem [ngbNavItem]="4" *isVendorEnabled="['Tiscali LTE']">
                        <a ngbNavLink><i class="fa fa-chart-pie"></i> Tiscali LTE</a>
                        <ng-template ngbNavContent>
                            <div class="card bg-gradient-light">
                                <div class="card-header">
                                    <p class="lead d-inline-block">Andamento Vendite</p>
                                    <app-datepicker-range-popup
                                            [from]="chartPeriod.from"
                                            [to]="chartPeriod.to"
                                            (onSelect)="tiscaliLteChart.setUrl('-detail/negozio-chart-tiscali-lte?id='+this.id+'&from='+ $event.from + '&to=' + $event.to)"
                                            class="float-right"
                                    ></app-datepicker-range-popup>
                                </div>
                                <div class="card-body p-1">
                                    <app-autoload-chartjs #tiscaliLteChart type="line"
                                                          url="-detail/negozio-chart-tiscali-lte?id={{this.id}}"></app-autoload-chartjs>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-3">
                                    <app-autoload-keypoints
                                            url="-detail/negozio-keypoints-tiscali-lte?id={{this.id}}"
                                    ></app-autoload-keypoints>
                                </div>
                                <div class="col-12 col-lg-3">
                                    <div class="card bg-gradient-light">
                                        <div class="card-header px-1">
                                            <app-datepicker-range-popup
                                                    [from]="riepilogoPeriod.from"
                                                    [to]="riepilogoPeriod.to"
                                                    (onSelect)="tiscaliLteRiepilogo.setUrl('-detail/negozio-riepilogo-tiscali-lte?id='+this.id+'&from='+ $event.from + '&to=' + $event.to)"
                                            ></app-datepicker-range-popup>
                                        </div>
                                        <div class="card-body p-0">
                                            <app-autoload-table-riepilogo
                                                #tiscaliLteRiepilogo
                                                url="-detail/negozio-riepilogo-tiscali-lte?id={{this.id}}"
                                            ></app-autoload-table-riepilogo>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-3">
                                    <app-autoload-table
                                            url="-detail/negozio-dettaglio-tiscali-lte?id={{this.id}}"
                                            [small]="true"
                                            [hideFooterDescription]="true"
                                    ></app-autoload-table>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem *isVendorEnabled="['Tim']">
                        <a ngbNavLink><i class="fa fa-chart-pie"></i> Tim</a>
                        <ng-template ngbNavContent></ng-template>
                    </li>
                    <li ngbNavItem [ngbNavItem]="6" *isVendorEnabled="['Eni gas e luce']">
                        <a ngbNavLink><i class="fa fa-chart-pie"></i> Eni gas e luce</a>
                        <ng-template ngbNavContent>
                            <div class="card bg-gradient-light">
                                <div class="card-header">
                                    <p class="lead d-inline-block">Andamento Vendite</p>
                                    <app-datepicker-range-popup
                                            [from]="chartPeriod.from"
                                            [to]="chartPeriod.to"
                                            (onSelect)="eniChart.setUrl('-detail/negozio-chart-eni?id='+this.id+'&from='+ $event.from + '&to=' + $event.to)"
                                            class="float-right"
                                    ></app-datepicker-range-popup>
                                </div>
                                <div class="card-body p-1">
                                    <app-autoload-chartjs #eniChart type="line"
                                                          url="-detail/negozio-chart-eni?id={{this.id}}"></app-autoload-chartjs>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-3">
                                    <app-autoload-keypoints
                                            url="-detail/negozio-keypoints-eni?id={{this.id}}"
                                    ></app-autoload-keypoints>
                                </div>
                                <div class="col-12 col-lg-3">
                                    <div class="card bg-gradient-light">
                                        <div class="card-header px-1">
                                            <app-datepicker-range-popup
                                                    [from]="riepilogoPeriod.from"
                                                    [to]="riepilogoPeriod.to"
                                                    (onSelect)="eniRiepilogo.setUrl('-detail/negozio-riepilogo-eni?id='+this.id+'&from='+ $event.from + '&to=' + $event.to)"
                                            ></app-datepicker-range-popup>
                                        </div>
                                        <div class="card-body p-0">
                                            <app-autoload-table-riepilogo
                                                #eniRiepilogo
                                                url="-detail/negozio-riepilogo-eni?id={{this.id}}"
                                            ></app-autoload-table-riepilogo>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-3">
                                    <app-autoload-table
                                            url="-detail/negozio-dettaglio-eni?id={{this.id}}"
                                            [small]="true"
                                            [hideFooterDescription]="true"
                                    ></app-autoload-table>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem [ngbNavItem]="8">
                        <a ngbNavLink><i class="fas fa-coins"></i> Fatturato</a>
                        <ng-template ngbNavContent>
                            <div class="card bg-gradient-light">
                                <div class="card-header">
                                    <p class="lead d-inline-block">Fatturato</p>
                                    <app-datepicker-range-popup
                                            [from]="chartPeriod.from"
                                            [to]="chartPeriod.to"
                                            (onSelect)="fatturatoChart.setUrl('-detail/negozio-chart-fatturato?id='+this.id+'&from='+ $event.from + '&to=' + $event.to)"
                                            class="float-right"
                                    ></app-datepicker-range-popup>
                                </div>
                                <div class="card-body p-1">
                                    <app-autoload-chartjs #fatturatoChart type="line"
                                                          url="-detail/negozio-chart-fatturato?id={{this.id}}"></app-autoload-chartjs>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="card bg-gradient-light">
                                        <div class="card-header px-1">
                                            <app-datepicker-range-popup
                                                    [from]="riepilogoPeriod.from"
                                                    [to]="riepilogoPeriod.to"
                                                    (onSelect)="
                                                        fatturatoRiepilogo.setUrl('-detail/negozio-riepilogo-fatturato?id='+this.id+'&from='+ $event.from + '&to=' + $event.to);
                                                        fatturatoDettaglioRiepilogo.setUrl('-detail/negozio-riepilogo-fatturato-media?id='+this.id+'&from='+ $event.from + '&to=' + $event.to);
                                                    "
                                            ></app-datepicker-range-popup>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="row no-gutters">
                                                <div class="col-12 col-lg-6">
                                                    <app-autoload-table-riepilogo
                                                        #fatturatoRiepilogo
                                                        url="-detail/negozio-riepilogo-fatturato?id={{this.id}}"
                                                    ></app-autoload-table-riepilogo>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <app-autoload-table-riepilogo
                                                        #fatturatoDettaglioRiepilogo
                                                        url="-detail/negozio-riepilogo-fatturato-media?id={{this.id}}"
                                                    ></app-autoload-table-riepilogo>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <app-autoload-table
                                            url="-detail/negozio-dettaglio-fatturato?id={{this.id}}"
                                            [small]="true"
                                            [hideFooterDescription]="true"
                                    ></app-autoload-table>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem [ngbNavItem]="9">
                        <a ngbNavLink><i class="fas fa-shopping-cart"></i> Negozio</a>
                        <ng-template ngbNavContent>
                            <div class="card bg-gradient-light">
                                <div class="card-header">
                                    <p class="lead d-inline-block">Totale Speso</p>
                                    <app-datepicker-range-popup
                                            [from]="chartPeriod.from"
                                            [to]="chartPeriod.to"
                                            (onSelect)="negozioChart.setUrl('-detail/negozio-chart-ordini?id='+this.id+'&from='+ $event.from + '&to=' + $event.to)"
                                            class="float-right"
                                    ></app-datepicker-range-popup>
                                </div>
                                <div class="card-body p-1">
                                    <app-autoload-chartjs #negozioChart type="line"
                                                          url="-detail/negozio-chart-ordini?id={{this.id}}"></app-autoload-chartjs>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-lg-3">
                                    <app-autoload-keypoints
                                            url="-detail/negozio-keypoints-ordini?id={{this.id}}"
                                    ></app-autoload-keypoints>
                                </div>
                                <div class="col-12 col-lg-3">
                                    <div class="card bg-gradient-light">
                                        <div class="card-header px-1">
                                            <app-datepicker-range-popup
                                                    [from]="riepilogoPeriod.from"
                                                    [to]="riepilogoPeriod.to"
                                                    (onSelect)="ordiniRiepilogo.setUrl('-detail/negozio-riepilogo-ordini?id='+this.id+'&from='+ $event.from + '&to=' + $event.to)"
                                            ></app-datepicker-range-popup>
                                        </div>
                                        <div class="card-body p-0">
                                            <app-autoload-table-riepilogo
                                                #ordiniRiepilogo
                                                url="-detail/negozio-riepilogo-ordini?id={{this.id}}"
                                            ></app-autoload-table-riepilogo>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <app-autoload-table
                                            url="-detail/negozio-dettaglio-ordini?id={{this.id}}"
                                            [small]="true"
                                            [hideFooterDescription]="true"
                                    ></app-autoload-table>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem [ngbNavItem]="10">
                        <a ngbNavLink><i class="fas fa-sms"></i> SMS</a>
                        <ng-template ngbNavContent></ng-template>
                    </li>
                    <li ngbNavItem [ngbNavItem]="11">
                        <a ngbNavLink><i class="fas fa-question-circle"></i> Tickets</a>
                        <ng-template ngbNavContent>
                            <div class="card bg-gradient-light">
                                <div class="card-header">
                                    <p class="lead d-inline-block">Tickets</p>
                                    <app-datepicker-range-popup
                                            [from]="chartPeriod.from"
                                            [to]="chartPeriod.to"
                                            (onSelect)="ticketChart.setUrl('-detail/negozio-chart-tickets?id='+this.id+'&from='+ $event.from + '&to=' + $event.to)"
                                            class="float-right"
                                    ></app-datepicker-range-popup>
                                </div>
                                <div class="card-body p-1">
                                    <app-autoload-chartjs #ticketChart type="line"
                                                          url="-detail/negozio-chart-tickets?id={{this.id}}"></app-autoload-chartjs>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-lg-4">
                                    <div class="card bg-gradient-light">
                                        <div class="card-header px-1">
                                            <app-datepicker-range-popup
                                                    [from]="riepilogoPeriod.from"
                                                    [to]="riepilogoPeriod.to"
                                                    (onSelect)="ticketsRiepilogo.setUrl('-detail/negozio-riepilogo-tickets?id='+this.id+'&from='+ $event.from + '&to=' + $event.to)"
                                            ></app-datepicker-range-popup>
                                        </div>
                                        <div class="card-body p-0">
                                            <app-autoload-table-riepilogo
                                                #ticketsRiepilogo
                                                url="-detail/negozio-riepilogo-tickets?id={{this.id}}"
                                            ></app-autoload-table-riepilogo>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-8">
                                    <app-autoload-table
                                            url="-detail/negozio-dettaglio-tickets?id={{this.id}}"
                                            [small]="true"
                                            [hideFooterDescription]="true"
                                    ></app-autoload-table>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem [ngbNavItem]="12">
                        <a ngbNavLink><i class="fas fa-map-marker-alt"></i> Visite</a>
                        <ng-template ngbNavContent>
                            <div class="card bg-gradient-light">
                                <div class="card-header">
                                    <p class="lead d-inline-block">Visite ricevute</p>
                                    <app-datepicker-range-popup
                                            [from]="chartPeriod.from"
                                            [to]="chartPeriod.to"
                                            (onSelect)="ticketChart.setUrl('-detail/negozio-chart-visite?id='+this.id+'&from='+ $event.from + '&to=' + $event.to)"
                                            class="float-right"
                                    ></app-datepicker-range-popup>
                                </div>
                                <div class="card-body p-1">
                                    <app-autoload-chartjs #ticketChart type="line"
                                                          url="-detail/negozio-chart-visite?id={{this.id}}"></app-autoload-chartjs>
                                </div>
                            </div>
                            <div style="display:inline-block;width:100%;overflow-y:auto;">
                                <div class="timeline timeline-horizontal">
                                    <div *ngFor="let item of timeline"
                                         class="timeline-item">
                                        <div class="timeline-badge bg-gradient-success"><i
                                                class="fas fa-map-marked-alt"></i></div>
                                        <div class="timeline-panel">
                                            <div class="timeline-heading">
                                                <h4 class="timeline-title">{{item.agente}}</h4>
                                                <p><small class="text-muted"><i class="fas fa-clock"></i> {{ item.ora }}
                                                </small></p>
                                            </div>
                                            <div class="timeline-body">
                                                <p>{{item.note}}</p>

                                                <ng-container *ngFor="let reply of item.replies">
                                                    <hr>
                                                    <div [ngClass]="{'text-right': reply.direction === 'right'}">
                                                        <p class="lead font-italic">{{reply.nome_autore}}</p>
                                                        <p>{{reply.testo}}</p>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="item.showReply">
                                                    <hr>
                                                    <textarea class="form-control" [(ngModel)]="item.reply"></textarea>
                                                    <button class="btn btn-sm btn-success mt-2"
                                                            (click)="sendReply(item)">Invia
                                                    </button>
                                                </ng-container>
                                            </div>
                                            <div class="timeline-footer">
                                                <hr>
                                                <a class="float-right font-italic link"
                                                   (click)="item.showReply = !item.showReply"><i
                                                        class="fa fa-reply"></i> Rispondi</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem [ngbNavItem]="14" *ngIf="false">
                        <a ngbNavLink><i class="fas fa-envelope"></i> E-Mail</a>
                        <ng-template ngbNavContent>
                            <div class="card bg-gradient-light">
                                <div class="card-header">
                                    <p class="lead d-inline-block">E-Mail</p>
                                    <app-datepicker-range-popup
                                            [from]="chartPeriod.from"
                                            [to]="chartPeriod.to"
                                            (onSelect)="emailChart.setUrl('-detail/negozio-chart-email?id='+this.id+'&from='+ $event.from + '&to=' + $event.to)"
                                            class="float-right"
                                    ></app-datepicker-range-popup>
                                </div>
                                <div class="card-body p-1">
                                    <app-autoload-chartjs #emailChart type="line"
                                                          url="-detail/negozio-chart-email?id={{this.id}}"></app-autoload-chartjs>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <app-autoload-table
                                            url="-detail/negozio-dettaglio-email?id={{this.id}}"
                                            [small]="true"
                                            [hideFooterDescription]="true"
                                    ></app-autoload-table>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem [ngbNavItem]="15">
                        <a ngbNavLink><i class="far fa-comments"></i> <span translate> Reports</span></a>
                        <ng-template ngbNavContent>
                            <app-report-timeline [dealerId]="id"></app-report-timeline>
                        </ng-template>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-12">
    </div>
    <div class="col-12">
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>
