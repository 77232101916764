import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';

export class Subject extends BaseModel {
    id: number;
    company_name: string;
    type: string;
    referral_person: string;
    email: string;
    pec_email: string;
    phone_number: string;
    sdi_code: string;
    vat: string;
    iban?: string;
    fiscal_code: string;
    address: string;
    city: string;
    state: string;
    zip_code: string;
    country: string;
    note: string;
    report?: {
        total_debit_credit: any;
        credit_total: any;
        debit_total: any;
    };

    fromArray(result: []) {
        const array: Subject[] = [];
        for (const item of result) {
            array.push(new Subject(item));
        }
        return array;
    }
}

export class SubjectPagination extends BaseModel {
    data: Subject[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class SubjectClientPagination extends BaseModel {
    data: SubjectClient[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class SubjectClient {
    id: number;
    company_name: string;
    revenue: string;
    total_revenue: string;
    percentage?: any;
}

export class SubjectSupplierPagination extends BaseModel {
    data: SubjectSupplier[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class SubjectSupplier {
    id: number;
    company_name: string;
    cost: string;
    total_cost: string;
    percentage?: any;
}
