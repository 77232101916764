<app-master-header
        [ngBusy]="busy"
        cardClass="bg-gradient-info"
        image="assets/img/flats/agent.png"
        [title]="'Dettaglio Visite di ' + sales_agent"
>
    <div class="row align-items-end">
        <div class="col-lg-4 col-12">
            <label translate>Seleziona periodo</label>
            <input
                    class="form-control"
                    type="text"
                    [(ngModel)]="filterForm.dateRange"
                    daterangepicker
                    (selected)="setSelectedDateRange($event.start, $event.end)"
            />
        </div>
    </div>
</app-master-header>

<div class="card">
    <div class="card-body">
        <div class="row" *ngIf="global">
            <div class="col-12 col-md-4 col-xl-2" *ngIf="defaultView">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/online-store.png"/>
                    <span class="title">{{ global.today_visits }}</span>
                    <span class="description"> Visite di oggi </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/calendar_2.png"/>
                    <span class="title">{{ global.period_visits }}</span>
                    <span class="description"> Visite nel periodo </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/percentage.png"/>
                    <span class="title">{{ global.average_period_visits }}</span>
                    <span class="description"> Media visite </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/settings_2.png"/>
                    <span class="title">{{ global.period_our_retailers }}</span>
                    <span class="description"> Visite uniche MG </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/new.png"/>
                    <span class="title">{{ global.period_other_retailers }}</span>
                    <span class="description"> Visite uniche altri pdv </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/internet.png"/>
                    <span class="title">{{ global.total_shops }}</span>
                    <span class="description"> Negozi totali </span>
                </div>
            </div>
        </div>

        <div class="row mt-4" *ngIf="global">
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/map.png"/>
                    <span class="title">{{ global.period_cities }}</span>
                    <span class="description"> Comuni visitati </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/placeholder.png"/>
                    <span class="title">{{ global.cities_with_shops }}</span>
                    <span class="description"> Comuni con negozi MG </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/global-network.png"/>
                    <span class="title">{{ global.covered_cities }}</span>
                    <span class="description">Comuni totali</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12 col-xl-4">
        <app-master-card
                cardClass="bg-gradient-1"
                bodyClass="p-0"
                title="Analisi su provincia"
                image="assets/img/flats/map.png"
        >
            <table class="table mb-0">
                <thead>
                <tr>
                    <th>Provincia</th>
                    <th class="text-right">PDV Totali</th>
                    <th class="text-right">PDV Visitati</th>
                    <th class="text-right">%</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let row of provinces">
                    <td>{{ row.name }}</td>
                    <td class="text-right">{{ row.total_retailers }}</td>
                    <td class="text-right">{{ row.our_retailer_visits }}</td>
                    <td class="text-right">{{ row.percentage | percent }}</td>
                </tr>
                </tbody>
            </table>
        </app-master-card>

        <app-master-card
                bodyClass="p-0"
                title="Visite sul calendario"
                image="assets/img/flats/pie-chart.png"
        >
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="calendar"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <full-calendar [options]="calendarOptions"></full-calendar>
        </app-master-card>
    </div>
    <div class="col-12 col-xl-4">
        <app-master-card
                bodyClass="py-0 px-3"
                title="Riepilogo situazione"
                image="assets/img/flats/gear.png"
        >
            <ul class="list-group list-group-unbordered">
                <li
                        class="list-group-item border-0"
                        *ngIf="kpi && kpi.retailers_never_visited > 0"
                >
                    <img
                            class="mr-2"
                            src="assets/img/flats/online-store.png"
                            height="24px"
                    />
                    <a
                            class="float-right link"
                            (click)="showRetailersNeverVisited(modalNeverVisited)"
                    ><i class="far fa-folder-open mr-1"></i><em>Visualizza</em></a
                    >
                    Ci sono <strong>{{ kpi.retailers_never_visited }}</strong> negozi mai
                    visitati
                </li>
                <li
                        class="list-group-item"
                        *ngIf="global && global.retailers_started_to_be_productive > 0"
                >
                    <img
                            class="mr-2"
                            src="assets/img/flats/settings_2.png"
                            height="24px"
                    />
                    <a
                            class="float-right link"
                            (click)="
              showResultsDetail(modalResults, 'started-to-be-productive')
            "
                    ><i class="far fa-folder-open mr-1"></i><em>Visualizza</em></a
                    >
                    <strong>{{ global.retailers_started_to_be_productive }}</strong>
                    negozi sono diventati produttivi
                </li>
                <li
                        class="list-group-item"
                        *ngIf="global && global.retailers_increased_sales > 0"
                >
                    <a
                            class="float-right link"
                            (click)="showResultsDetail(modalResults, 'increased-sales')"
                    ><i class="far fa-folder-open mr-1"></i><em>Visualizza</em></a
                    >
                    <img class="mr-2" src="assets/img/flats/diagram.png" height="24px"/>
                    <strong>{{ global.retailers_increased_sales }}</strong> negozi hanno
                    aumentato la produttività
                </li>
                <li
                        class="list-group-item"
                        *ngIf="global && global.retailers_bought_plafond > 0"
                >
                    <a
                            class="float-right link"
                            (click)="showResultsDetail(modalResults, 'bought-plafond')"
                    ><i class="far fa-folder-open mr-1"></i><em>Visualizza</em></a
                    >
                    <img class="mr-2" src="assets/img/flats/dollar.png" height="24px"/>
                    <strong>{{ global.retailers_bought_plafond }}</strong> negozi hanno
                    comprato il plafond
                </li>
                <li
                        class="list-group-item"
                        *ngIf="global && global.retailers_bought_sim > 0"
                >
                    <a
                            class="float-right link"
                            (click)="showResultsDetail(modalResults, 'bought-sim')"
                    ><i class="far fa-folder-open mr-1"></i><em>Visualizza</em></a
                    >
                    <img class="mr-2" src="assets/img/flats/sim-card.png" height="24px"/>
                    <strong>{{ global.retailers_bought_sim }}</strong> negozi hanno
                    comprato le sim
                </li>
            </ul>
        </app-master-card>

        <app-master-card
                bodyClass="p-0"
                title="Comuni più visitati"
                image="assets/img/flats/internet.png"
        >
            <table class="table mb-0">
                <thead>
                <tr>
                    <th>Provincia</th>
                    <th>Comune</th>
                    <th class="text-right">PDV Totali</th>
                    <th class="text-right">Visite Totali</th>
                    <th class="text-right">%</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let row of cities_with_most_visits">
                    <td>{{ row.provincia }}</td>
                    <td>{{ row._id }}</td>
                    <td class="text-right">{{ row.retailers }}</td>
                    <td class="text-right">{{ row.count }}</td>
                    <td class="text-right">{{ row.percentage_on_total | percent }}</td>
                </tr>
                </tbody>
            </table>
        </app-master-card>
    </div>
    <div class="col-12 col-xl-4">
        <h1 class="display-6 mb-0 mb-2">Timeline</h1>
        <div class="timeline" style="max-height: 700px; overflow-y: scroll">
            <ng-container *ngFor="let visits of timeline">
                <div class="time-label lead">
                    <span class="bg-gradient-success">{{ visits.periodo }}</span>
                </div>
                <div *ngFor="let item of visits.visite">
                    <i class="fas fa-sim-card bg-gradient-info"></i>
                    <div class="timeline-item bg-gradient-light elevation-1">
            <span class="time"
            ><i class="far fa-clock mr-2"></i>{{ item.ora }}</span
            >
                        <h3 class="timeline-header">
                            <a
                                    *ngIf="item.id_dealer"
                                    [routerLink]="['/dealer', item.id_dealer]"
                            >{{ item.negozio }}</a
                            >
                            <span *ngIf="!item.id_dealer">{{ item.negozio }}</span>
                        </h3>
                        <div class="timeline-body">
                            <p class="lead font-italic"></p>
                            <p>{{ item.note_visita }}</p>

                            <p *ngIf="item.affiliato" class="mt-2">
                                <i class="fas fa-check-circle text-success"></i> Affiliato
                            </p>
                            <p *ngIf="item.attivita_chiusa" class="mt-2">
                                <i class="fas fa-check-circle text-danger"></i> Attivita chiusa
                            </p>

                            <ng-container *ngFor="let result of item.results">
                                <p *ngIf="result.evento === 'started-to-be-productive'">
                                    <i class="fas fa-cogs text-primary"></i> Ha iniziato a
                                    produrre
                                </p>
                                <p *ngIf="result.evento === 'increased-sales'">
                                    <i class="fas fa-chart-line text-primary"></i> Ha aumentato la
                                    produzione
                                </p>
                                <p *ngIf="result.evento === 'bought-plafond'">
                                    <i class="fas fa-coins text-primary"></i> Ha comprato il
                                    plafond
                                </p>
                                <p *ngIf="result.evento === 'bought-sim'">
                                    <i class="fas fa-sim-card text-primary"></i> Ha comprato le
                                    sim
                                </p>
                            </ng-container>

                            <hr>
                            <ul>
                                <li>Plafond segnalato: {{item?.actual_plafond | currency: '€ '}} | Reale: {{item?.real_actual_plafond | currency: '€ '}}</li>
                                <li>Sin in magazzino: {{item?.warehouse_sim}} | Reale: {{item?.real_warehouse_sim}}</li>
                            </ul>

                            <ng-container *ngIf="item?.documents.length > 0">
                                <hr>
                                <p class="text-italic">Foto del negozio:</p>
                                <ul>
                                    <li *ngFor="let document of item?.documents; let i = index;">
                                        <a [href]="baseAwsUrl+'/'+document?.file_path"
                                           target="_blank">Foto {{i + 1}}</a>
                                    </li>
                                </ul>
                            </ng-container>

                            <ng-container *ngFor="let reply of item.replies">
                                <hr/>
                                <div [ngClass]="{ 'text-right': reply.direction === 'right' }">
                                    <p class="lead font-italic">{{ reply.nome_autore }}</p>
                                    <p>{{ reply.testo }}</p>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="item.showReply">
                                <hr/>
                                <textarea
                                        class="form-control"
                                        [(ngModel)]="item.reply"
                                ></textarea>
                                <button
                                        class="btn btn-sm btn-success mt-2"
                                        (click)="sendReply(item)"
                                >
                                    Invia
                                </button>
                            </ng-container>
                        </div>
                        <div class="timeline-footer">
                            <em>{{ item.citta }}, {{ item.provincia }}</em>

                            <a
                                    class="float-right font-italic link"
                                    (click)="item.showReply = !item.showReply"
                            ><i class="fa fa-reply"></i> Rispondi</a
                            >
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #modalNeverVisited let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Negozi mai visitati</h4>
    </div>
    <div class="modal-body p-0">
        <table class="table mb-0">
            <thead>
            <tr>
                <th>Negozio</th>
                <th>Provincia</th>
                <th>Città</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let row of retailersNeverVisited">
                <td>{{ row.ragsociale }}</td>
                <td>{{ row.province }}</td>
                <td>{{ row.city }}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">
            Close
        </button>
    </div>
</ng-template>

<ng-template #modalResults let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Negozi mai visitati</h4>
    </div>
    <div class="modal-body p-0">
        <table class="table mb-0">
            <thead>
            <tr>
                <th>Negozio</th>
                <th>Provincia</th>
                <th>Città</th>
                <th *ngIf="currentEvent === 'bought-plafond'">Plafond Acquistato</th>
                <th *ngIf="currentEvent === 'bought-sim'">Sim Acquistate</th>
                <th *ngIf="currentEvent === 'started-to-be-productive'">Giorni dopo la visita in cui ha iniziato a
                    produrre
                </th>
                <th *ngIf="currentEvent === 'increased-sales'">Media Vendite Prima</th>
                <th *ngIf="currentEvent === 'increased-sales'">Media Vendite Dopo</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let row of resultsDetail">
                <td>{{ row.retailer.company }}</td>
                <td>{{ row.retailer.province }}</td>
                <td>{{ row.retailer.city }}</td>
                <td *ngIf="currentEvent === 'bought-plafond'">{{ row.dati.qty }}</td>
                <td *ngIf="currentEvent === 'bought-sim'">{{ row.dati.qty }}</td>
                <td *ngIf="currentEvent === 'started-to-be-productive'">{{ row.dati.days_since_visit }} giorni</td>
                <td *ngIf="currentEvent === 'increased-sales'">{{ row.dati.avg_sales_before }} sim/gg</td>
                <td *ngIf="currentEvent === 'increased-sales'">{{ row.dati.avg_sales_after }} sim/gg</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">
            Close
        </button>
    </div>
</ng-template>
