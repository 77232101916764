import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Product} from '@models/product';

@Component({
  selector: 'app-shop-menu',
  templateUrl: './shop-menu.component.html',
  styleUrls: ['./shop-menu.component.scss']
})
export class ShopMenuComponent implements OnInit {
  @Input() products: Product[];

  @Output() productsChange = new EventEmitter();

  @Input() originProducts: Product[];

  filter: string = null;

  categoryFilter = null;
  brandFilter = null;

  constructor() {
  }

  ngOnInit() {
  }

  get categories() {
    const category = this.originProducts.map(item => item.productcategory).filter((value, index, self) => self.indexOf(value) === index);
    return category;
  }

  get brands() {
    return this.originProducts.map(item => item.vendor_id_meta).filter((value, index, self) => self.indexOf(value) === index);
  }

  productsInCategory(category: string) {
    return this.originProducts.filter((item) => item.productcategory === category).length;
  }

  productsOfBrand(brand: string) {
    return this.originProducts.filter((item) => item.vendor_id_meta === brand).length;
  }

  filterProducts() {
    this.products = this.originProducts;

    if (this.filter) {
      const filterValue = this.filter.toLowerCase();

      this.products = this.originProducts.filter(item => {
        return item.productname.toLowerCase().indexOf(filterValue) !== -1 || item.description.toLowerCase().indexOf(filterValue) !== -1;
      });
    }

    if (this.categoryFilter) {
      this.products = this.products.filter((item) => item.productcategory === this.categoryFilter);
    }
    if (this.brandFilter) {
      this.products = this.products.filter((item) => item.vendor_id_meta === this.brandFilter);
    }

    this.productsChange.emit(this.products);
  }

  addCategoryFilter(category: string) {
    this.categoryFilter = category;
    this.filterProducts();
  }

  addBrandFilter(brand: string) {
    this.brandFilter = brand;
    this.filterProducts();
  }

  get hasFilters() {
    return this.categoryFilter || this.brandFilter;
  }
}
