import {Injectable} from '@angular/core';
import {RequestService} from '@services/request.service';
import {AlertService} from '@services/alert.service';
import {map} from 'rxjs/operators';
import {ShopBrand, ShopBrandPagination} from '@models/e-pay/shop-brand';
import {Observable} from 'rxjs';
import {ShopProduct, ShopProductPagination} from '@models/e-pay/shop-product';
import {ShopProductCategory, ShopProductCategoryPagination} from '@models/e-pay/shop-product-category';
import {ShopOrder, ShopOrderPagination} from '@models/e-pay/shop-order';
import {
    ShopCredit,
    ShopCreditPagination,
    ShopCreditReport,
    ShopDealerCredit,
    ShopDealerCreditPagination
} from '@models/e-pay/shop-dealer-credit';

const eShop = 'e-shop';
const brands = 'brands';
const products = 'products';
const categories = 'categories';
const orders = 'orders';
const variants = 'variants';
const credits = 'credits';

@Injectable({
    providedIn: 'root'
})
export class EPayService {
    public static currency = '€';
    static datetimeLocalFormat = 'DD/MM/YYYY HH:MM';
    public static orderStatuses = [
        {
            name: 'Completed',
            key: 'completed'
        },
        {
            name: 'Failed',
            key: 'failed'
        },
        {
            name: 'Pending',
            key: 'pending'
        },
    ];
    public static productTypes = [
        {
            name: 'Recharge Online',
            key: 'recharge-online'
        },
        {
            name: 'PIN',
            key: 'pin'
        },
        {
            name: 'Gift card',
            key: 'gift-card'
        },
    ];
    public static creditStatuses = [
        {
            name: 'Approved',
            key: 'approved'
        },
        {
            name: 'Rejected',
            key: 'rejected'
        },
    ];

    constructor(private request: RequestService, public alertService: AlertService) {
    }

    public getBrands(params?): Observable<ShopBrandPagination> {
        return this.request.get(eShop + '/' + brands, params).pipe(
            map((response: any) => {
                return new ShopBrandPagination(response);
            })
        );
    }

    public getProducts(params?): Observable<ShopProductPagination> {
        return this.request.get(eShop + '/' + products, params).pipe(
            map((response: any) => {
                return new ShopProductPagination(response);
            })
        );
    }

    public getProductCategories(params?): Observable<ShopProductCategoryPagination> {
        return this.request.get(eShop + '/' + categories, params).pipe(
            map((response: any) => {
                return new ShopProductCategoryPagination(response);
            })
        );
    }

    public getCategoriesAndProducts(params?): Observable<ShopProductCategoryPagination> {
        return this.request.get(eShop + '/' + categories + '/' + 'products', params).pipe(
            map((response: any) => {
                return new ShopProductCategoryPagination(response);
            })
        );
    }

    public getDealersWithCredit(params?): Observable<ShopDealerCreditPagination> {
        return this.request.get(credits, params).pipe(
            map((response: any) => {
                return new ShopDealerCreditPagination(response);
            })
        );
    }

    public getCredits(params?): Observable<ShopCreditPagination> {
        return this.request.get(credits + '/transaction', params).pipe(
            map((response: any) => {
                return new ShopCreditPagination(response);
            })
        );
    }

    public postDealerCredits(body: ShopCredit): Observable<ShopCredit> {
        return this.request.post(credits, body).pipe(
            map((response: any) => {
                return new ShopCredit(response);
            })
        );
    }

    public putDealerCredits(id, body: ShopCredit): Observable<ShopCredit> {
        return this.request.put(credits + '/' + id, body).pipe(
            map((response: any) => {
                return new ShopCredit(response);
            })
        );
    }

    public getCreditReport(params?): Observable<ShopCreditReport> {
        return this.request.get(credits + '/report', params).pipe(
            map((response: any) => {
                return new ShopCreditReport(response);
            })
        );
    }

    public checkAccess(params?): Observable<boolean> {
        return this.request.get(eShop + '/' + orders + '/check-access', params);
    }

    public getOrders(params?): Observable<ShopOrderPagination> {
        return this.request.get(eShop + '/' + orders, params).pipe(
            map((response: any) => {
                return new ShopOrderPagination(response);
            })
        );
    }

    public postBrand(body: ShopBrand): Observable<ShopBrand> {
        return this.request.post(eShop + '/' + brands, body.postPayloadBrand).pipe(
            map((response: any) => {
                return new ShopBrand(response);
            })
        );
    }

    public putBrand(id: number, body): Observable<ShopBrand> {
        return this.request.post(eShop + '/' + brands + '/' + id, body.postPayloadBrand).pipe(
            map((response: any) => {
                return new ShopBrand(response);
            })
        );
    }

    public postProduct(body: ShopProduct): Observable<ShopProduct> {
        return this.request.post(eShop + '/' + products, body.postPayloadProduct).pipe(
            map((response: any) => {
                return new ShopProduct(response);
            })
        );
    }

    public putProduct(id: number, body: ShopProduct): Observable<ShopProduct> {
        return this.request.post(eShop + '/' + products + '/' + id, body.postPayloadProduct).pipe(
            map((response: any) => {
                return new ShopProduct(response);
            })
        );
    }

    public postProductsFile(body) {
        return this.request.post(eShop + '/' + products + '/upload-file', body.postPayloadProductFile).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    changeProductStatus(id: number, body) {
        return this.request.post(eShop + '/' + products + '/' + id, body).pipe(
            map((response: any) => {
                return new ShopProduct(response);
            })
        );
    }

    changeBrandStatus(id: number, body) {
        return this.request.post(eShop + '/' + brands + '/' + id, body).pipe(
            map((response: any) => {
                return new ShopBrand(response);
            })
        );
    }

    public postProductCategory(body: ShopProductCategory): Observable<ShopProductCategory> {
        return this.request.post(eShop + '/' + categories, body).pipe(
            map((response: any) => {
                return new ShopProductCategory(response);
            })
        );
    }

    public postOrder(body: ShopOrder): Observable<ShopOrder> {
        return this.request.post(eShop + '/' + orders, body)
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    public authenticateOrder(body: ShopOrder) {
        return this.request.post(eShop + '/' + orders + '/authorize', body)
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    public deleteBrand(id: number) {
        return this.request.delete(eShop + '/' + brands + '/' + id);
    }

    public deleteProduct(id: number) {
        return this.request.delete(eShop + '/' + products + '/' + id);
    }

    public deleteProductVariant(id: number) {
        return this.request.delete(eShop + '/' + 'product' + '/' + variants + '/' + id);
    }

    public deleteCredit(id: number) {
        return this.request.delete('credits' + '/' + id);
    }

    public successAlert(text) {
        this.alertService.show(
            '',
            'Successfully ' + text,
            {classname: 'bg-success text-light', delay: 2000}
        );
    }

    public errorAlert(message?) {
        this.alertService.show(
            'An error occurred!',
            message ? message : 'Try again!',
            {classname: 'bg-danger text-light', delay: 2000}
        );
    }

    public getFreeAccess() {
        return this.request.get(eShop + '/' + orders + '/free-period');
    }
}
