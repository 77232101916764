import {Component, Input, OnInit} from '@angular/core';
import {DashboardService} from '@services/dashboard.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DataBuilderModalService} from '@services/data-builder-modal.service';

@Component({
  selector: 'app-autoload-riepilogo',
  templateUrl: './autoload-riepilogo.component.html',
  styleUrls: ['./autoload-riepilogo.component.css']
})
export class AutoloadRiepilogoComponent implements OnInit {
  _urlRiepilogo: string;
  data: any;
  autoloadTableUrl: string;

  @Input() set urlRiepilogo(value: string) {
    this._urlRiepilogo = value;

    if (this._urlRiepilogo !== undefined) {
      this.loadRiepilogo();
    }
  }

  constructor(
      private dashboardService: DashboardService,
      public dbm: DataBuilderModalService,
      private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit() {

  }

  private loadRiepilogo() {
    this.spinner.show('loader-riepilogo');
    this.dashboardService.get(this._urlRiepilogo)
        .subscribe((data) => {
          this.data = data.result;
          this.spinner.hide('loader-riepilogo');
        });
  }
}
