<ngx-spinner
        bdColor="rgba(51,51,51,0.76)"
        size="medium"
        color="#fff"
        type="timer"
        [fullScreen]="true"
>
    <p style="color: white"> Caricamento... </p>
</ngx-spinner>

<div class="card bg-gradient-light" *ngIf="data">
    <div class="card-body px-xl-5 pb-0">
        <div class="row align-items-center">
            <div class="col-12 col-xl-8 pl-xl-5">
                <div class="px-xl-3">
                    <h1 class="display-4">{{data.agente}}</h1>
                    <h1 class="lead">La sua area di competenza è {{data.zona}}</h1>
<!--                    <h1 class="lead">Ha maturato {{data.provvigioni | currency: '€ '}} di provvigioni</h1>-->
                </div>
            </div>
            <div class="col-12 col-xl-4">
                <div class="form-group form-group-icon form-group-dark mb-0">
                    <label>Seleziona il periodo</label>
                    <i class="far fa-calendar-alt"></i>
                    <select class="form-control" [(ngModel)]="this.periodo">
                        <ng-container>
                            <option *ngFor="let per of this.data.periodi" [value]="per.value">{{per.label}}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body px-xl-5 pb-5 pt-2">
        <div class="row" *ngFor="let row of data.body; let r = index;" [ngClass]="{'border-bottom': r < data.body.length-1}">
            <div *ngFor="let col of row" [ngClass]="col.class">
                <div class="p-2">
                    <img [src]="col.logo" style="max-width: 100%; "/>
                </div>
                <div class="mt-1">
                    <p  *ngFor="let sentence of col.sentences" (click)="openAutoloadTable(autoloadTable, sentence.modal)" class="lead py-1 px-2 box-hover-dark rounded" [innerHTML]="sentence.text | safeHtml"></p>
                </div>
                <div class="mt-2 mx-2 card box-dark" *ngIf="col.chart">
                    <div class="card-body px-0">
                        <div style="height: 70px;">
                            <ngx-charts-line-chart
                                    [autoScale]="true"
                                    [showRefLabels]="false"
                                    [roundDomains]="false"
                                    [xAxis]="false"
                                    [yAxis]="false"
                                    [showGridLines]="false"
                                    [legend]="false"
                                    [results]="col.chart"
                                    [showXAxisLabel]="false"
                                    [showYAxisLabel]="false"
                                    [curve]="curve"
                            >
                        </ngx-charts-line-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #autoloadTable let-modal size="xl">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Dettaglio</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <app-autoload-table [url]="autoloadTableUrl"></app-autoload-table>
    </div>
</ng-template>
