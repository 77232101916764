<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header bg-gradient-light">
                <h1 class="card-title">Filtri</h1>
                <div class="card-tools">
                    <button class="btn-sm btn btn-dark" (click)="resetFilters(); refresh();">Reset Filtri</button>
                </div>
            </div>
            <div class="card-body bg-gradient-white">
                <div class="row">
                    <div class="form-group col">
                        <label>ID</label>
                        <input
                                class="form-control"
                                (keyup.enter)="refresh()"
                                [(ngModel)]="filter.id"
                        />
                    </div>
                    <div class="form-group col">
                        <label>N° Fattura</label>
                        <input
                                class="form-control"
                                (keyup.enter)="refresh()"
                                [(ngModel)]="filter.numero_fattura"
                        />
                    </div>
                    <div class="form-group col">
                        <label>Mostra Documenti Uniti</label>
                        <select
                                class="form-control"
                                (change)="refresh()"
                                [(ngModel)]="filter.merged"
                        >
                            <option value="0">No</option>
                            <option value="1">Si</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col">
                        <label>Soggetto</label>
                        <app-retailer-typeahead
                                (onChange)="setSubject($event)"
                        ></app-retailer-typeahead>
                    </div>
                    <div class="form-group col">
                        <label>Tipo</label>
                        <select
                                class="form-control"
                                (change)="refresh()"
                                [(ngModel)]="filter.is_old"
                        >
                            <option value="">Tutte</option>
                            <option value="0">Nuova</option>
                            <option value="1">Vecchia</option>
                        </select>
                    </div>
                    <div class="form-group col">
                        <label>Tipo Documento</label>
                        <ng-select
                                [items]="document_types"
                                [multiple]="true"
                                bindValue="value"
                                bindLabel="label"
                                [(ngModel)]="filter.document"
                                (change)="refresh()"
                        ></ng-select>
                    </div>
                    <div class="form-group col">
                        <label>Stato Proforma</label>
                        <select
                                class="form-control"
                                (change)="refresh()"
                                [(ngModel)]="filter.stato"
                        >
                            <option value="">Tutte</option>
                            <option value="-1">Nascosto</option>
                            <option value="0">In attesa</option>
                            <option value="1">Approvata</option>
                            <option value="2">Rifiutata</option>
                        </select>
                    </div>
                    <div class="form-group col">
                        <label>Stato SDI</label>
                        <ng-select
                                [items]="sdi_statuses"
                                [multiple]="true"
                                bindValue="value"
                                bindLabel="label"
                                [(ngModel)]="filter.stato_invio_sdi"
                                (change)="refresh()"
                        ></ng-select>
                    </div>
                    <div class="form-group col">
                        <label>Stato Free Invoice</label>
                        <select
                                class="form-control"
                                (change)="refresh()"
                                [(ngModel)]="filter.stato_freeinvoice"
                        >
                            <option value="">Tutte</option>
                            <option value="0">Da inviare</option>
                            <option value="1">Inviata</option>
                            <option value="2">Errore</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col">
                        <p class="lead mb-2">
                            Fatture da inviare
                            <span class="float-right text-bold">
                <i
                        class="fas fa-circle-notch fa-spin"
                        *ngIf="!totals.ready_to_be_sent"
                ></i>
                                {{ totals.ready_to_be_sent }}</span
                            >
                        </p>
                        <button
                                class="btn btn-block btn-info btn-sm"
                                (click)="showInvoicesReadyToBeSent()"
                        >
                            Visualizza
                        </button>
                    </div>
                    <div class="col">
                        <p class="lead mb-2">
                            Fatture da aggiornare
                            <span class="float-right text-bold">
                <i
                        class="fas fa-circle-notch fa-spin"
                        *ngIf="!totals.ready_to_be_updated"
                ></i>
                                {{ totals.ready_to_be_updated }}</span
                            >
                        </p>
                        <button
                                class="btn btn-block btn-success btn-sm"
                                (click)="showInvoicesToBeUpdated()"
                        >
                            Visualizza
                        </button>
                    </div>
                    <div class="col">
                        <p class="lead mb-2">
                            Fatture scartate
                            <span class="float-right text-bold"
                            ><i
                                    class="fas fa-circle-notch fa-spin"
                                    *ngIf="!totals.discarded"
                            ></i
                            >{{ totals.discarded }}</span
                            >
                        </p>
                        <button
                                class="btn btn-block btn-danger btn-sm"
                                (click)="showInvoicesRejected()"
                        >
                            Visualizza
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <button class="btn btn-success mb-3" *ngIf="selectedInvoices.length > 1"
                [swal]="invoiceMergeOptions"
                (confirm)="mergeDocuments(selectedInvoices)">Unisci documenti in uno</button>

        <div class="card bg-gradient-light">
            <div class="card-body p-0 rounded-bottom">
                <table class="table table-sm table-striped">
                    <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>ID</th>
                        <th>Soggetto</th>
                        <th></th>
                        <th>Brand</th>
                        <th>Periodo</th>
                        <th>Ricevuta il</th>
                        <th class="text-center">Tipo</th>
                        <th class="text-center">Stato</th>
                        <th>Stato SDI</th>
                        <th class="text-center"></th>
                        <th class="text-right">N° Fattura</th>
                        <th>Data Fattura</th>
                        <th class="text-right">Totale</th>
                        <th class="text-right">Iva</th>
                        <th class="text-right">Ritenuta</th>
                        <th class="text-center">N° Accrediti</th>
                        <th class="text-right"></th>
                    </tr>
                    </thead>
                    <tbody class="bg-gradient-white">
                    <ngx-spinner
                            bdColor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="square-jelly-box"
                            name="table-invoices"
                            [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <tr *ngFor="let invoice of invoices">
                        <td>
                            <div class="custom-control custom-checkbox"
                                 *ngIf="invoice.is_old === 0 && !invoice.id_merge && ((invoice.stato_freeinvoice === 0 && !firstInvoiceSelected) ||  (firstInvoiceSelected && firstInvoiceSelected.destinatario === invoice.destinatario && invoice.stato_freeinvoice === 0))">
                                <input class="custom-control-input" type="checkbox" [id]="invoice.id"
                                       [(ngModel)]="invoice.checked" (change)="selectInvoice($event, invoice)">
                                <label [for]="invoice.id" class="custom-control-label" translate></label>
                            </div>
                        </td>
                        <td class="text-center">
                            <i class="fas fa-eye link" (click)="showInvoice(invoice)"></i>
                            <i
                                    class="fas fa-sync-alt link text-success ml-2"
                                    (click)="regenInvoice(invoice)"
                                    *ngIf="(invoice.send || invoice.resend) && (!invoice.id_link ) || ( invoice.id_link && invoice.totale > 0) && !invoice.id_merge"
                            ></i>
                        </td>
                        <td>{{ invoice.id }}</td>
                        <td>{{ invoice.subject }}
                            <span *ngIf="invoice.id_link" class="text-sm text-muted pull-right">({{invoice.id_link}}
                                )</span>

                            <span *ngIf="invoice.id_merge" class="text-sm text-muted pull-right">({{invoice.id_merge}}
                                )</span>
                        </td>
                        <td>
                            <i
                                    class="text-danger cursor-hand"
                                    [ngClass]="invoice.profileProblem.icon"
                                    placement="top"
                                    [ngbPopover]="invoice.profileProblem.text"
                                    popoverTitle="Problema"
                                    *ngIf="invoice.profile_status < 4"
                            ></i>
                        </td>
                        <td>{{ invoice.vendors }}</td>
                        <td>{{ invoice.periods }}</td>
                        <td>{{ invoice.created | amDateFormat: "DD/MM/YYYY" }}</td>
                        <td class="text-center">
                            <a
                                    class="link"
                                    [swal]="invoiceTypeOptions"
                                    (confirm)="changeInvoiceType($event, invoice)"
                            >
                                <span *ngIf="invoice.is_old === 0">Nuova</span>
                                <span *ngIf="invoice.is_old === 1">Vecchia</span>
                            </a>
                        </td>
                        <td class="text-center">
                            <i
                                    class="fa link"
                                    [swal]="invoiceStatusOptions"
                                    (confirm)="changeInvoiceStatus($event, invoice)"
                                    [ngClass]="{
                    'fa-gavel text-warning': invoice.stato === -1,
                    'far fa-clock text-info': invoice.stato === 0,
                    'fa-check-circle text-success': invoice.stato === 1,
                    'fa-times-circle text-danger': invoice.stato === 2
                  }"
                            ></i>
                        </td>
                        <td>
                <span *ngIf="invoice.is_old === 0 && !invoice.id_merge">{{
                    invoice.sdiStatus
                    }}</span>
                        </td>
                        <td class="text-center">
                            <i
                                    [swal]="invoiceRedoOptions"
                                    (confirm)="redoInvoiceType($event, invoice)"
                                    class="fas fa-redo float-left text-warning link"
                                    *ngIf="invoice.stato_invio_sdi === 8">
                            </i>
                        </td>
                        <td class="text-right">
                            {{ invoice.numero_fattura }}
                        </td>
                        <td>{{ invoice.data_fattura | amDateFormat: "DD/MM/YYYY" }}</td>
                        <td class="text-right">{{ invoice.totale | currency: "€" }}</td>
                        <td class="text-right">{{ invoice.iva | currency: "€" }}</td>
                        <td class="text-right">
                            {{ invoice.importo_ritenuta | currency: "€" }}
                        </td>
                        <td class="text-center">
                            <a
                                    [routerLink]="['/gestione-credito', invoice.destinatario]"
                                    target="_blank"
                            >{{ invoice.transactions_count }}</a
                            >
                        </td>
                        <td class="text-center">
                            <i
                                    class="fas fa-exclamation-triangle text-danger cursor-hand"
                                    placement="top"
                                    ngbPopover="Il partner ha un'altra fattura in attesa di consegna\scarto allo SDI. Aggiorna lo stato di quella fattura o attendi per poter inviare questa."
                                    popoverTitle="Problema"
                                    *ngIf="invoice.sendProblem && (invoice.send || invoice.resend)"
                            ></i>
                            <button
                                    class="btn btn-sm btn-success"
                                    *ngIf="invoice.send && !invoice.sendProblem"
                                    (click)="sendInvoice(invoice)"
                                    [ladda]="loadingSDI[invoice.id]"
                            >
                                Invia allo SDI
                            </button>
                            <button
                                    class="btn btn-sm btn-danger"
                                    *ngIf="invoice.resend && !invoice.sendProblem"
                                    (click)="sendInvoice(invoice)"
                                    [ladda]="loadingSDI[invoice.id]"
                            >
                                Re-Invia
                            </button>
                            <button
                                    class="btn btn-sm btn-primary"
                                    *ngIf="invoice.update"
                                    (click)="updateInvoice(invoice)"
                                    [ladda]="loadingSDI[invoice.id]"
                            >
                                Aggiorna Stato
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
        <span
        >Ci sono <b>{{ this.pagination.totalCount }}</b> fatture.</span
        >
                <ngb-pagination
                        size="sm"
                        [pageSize]="pagination.pageSize"
                        [maxSize]="10"
                        [(page)]="pagination.page"
                        [collectionSize]="pagination.totalCount"
                        (pageChange)="refresh()"
                ></ngb-pagination>
            </div>
        </div>
    </div>
</div>

<ng-template #invoice let-modal>
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="currentInvoice">Proforma {{currentInvoice.id}}
            di {{currentInvoice.company.name}}</h4>
        <button type="button" class="close" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div style="height: 400px" *ngIf="!currentInvoice">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="invoice-detail"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
        </div>
        <ngx-spinner *ngIf="currentInvoice"
                     bdColor="rgba(0, 0, 0, 0.8)"
                     size="default"
                     color="#fff"
                     type="square-jelly-box"
                     name="invoice-detail"
                     [fullScreen]="false"
        >
            <p style="color: white">Caricamento...</p>
        </ngx-spinner>

        <div class="row invoice-info p-4" *ngIf="currentInvoice">
            <div class="col-sm-4 invoice-col">
                <div>
                    <p class="text-sm text-muted">Prestatore</p>
                    <p>{{ currentInvoice.company.name }}</p>
                </div>
                <div>
                    <p class="text-sm text-muted">Indirizzo</p>
                    <p>
                        {{ currentInvoice.company.indirizzo }},
                        {{ currentInvoice.company.civico }}
                    </p>
                </div>
                <div>
                    <p class="text-sm text-muted">Sede</p>
                    <p>
                        {{ currentInvoice.company.city }},
                        {{ currentInvoice.company.province }} -
                        {{ currentInvoice.company.cap }}
                    </p>
                </div>
                <div>
                    <p class="text-sm text-muted">Codice SDI</p>
                    <p>{{ currentInvoice.company.codice_sdi }}</p>
                </div>
                <div>
                    <p class="text-sm text-muted">PEC</p>
                    <p>{{ currentInvoice.company.pec }}</p>
                </div>
            </div>
            <!-- /.col -->
            <div class="col-sm-4 invoice-col">
                <div>
                    <p class="text-sm text-muted">Committente</p>
                    <p>Master Group</p>
                </div>
                <div>
                    <p class="text-sm text-muted">Indirizzo</p>
                    <p>Via Palmiro Togliatti, 11</p>
                </div>
                <div>
                    <p class="text-sm text-muted">Sede</p>
                    <p>Orta Nova, FG - 71045</p>
                </div>
                <div>
                    <p class="text-sm text-muted">Codice SDI</p>
                    <p>M5UXCR1</p>
                </div>
                <div>
                    <p class="text-sm text-muted">PEC</p>
                    <p>mastergroupsrl@arubapec.it</p>
                </div>
            </div>
            <!-- /.col -->
            <div class="col-sm-4 invoice-col">
                <div>
                    <p class="text-sm text-muted">Stato Free Invoice</p>
                    <p>{{ currentInvoice.company.stato_freeinvoice }}</p>
                </div>
                <div>
                    <p class="text-sm text-muted">Causale Ritenuta</p>
                    <p>{{ currentInvoice.company.causale_ritenuta }}</p>
                </div>
                <div>
                    <p class="text-sm text-muted">Regime Fiscale</p>
                    <p>{{ currentInvoice.company.regime_fiscale }}</p>
                </div>
                <div>
                    <p class="text-sm text-muted">Tipo Ritenuta</p>
                    <p>{{ currentInvoice.company.tipo_ritenuta }}</p>
                </div>
                <div>
                    <p class="text-sm text-muted">Tipo Società</p>
                    <p>{{ currentInvoice.company.tipologia_societa }}</p>
                </div>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->

        <table class="table table-striped table-sm mb-0" *ngIf="currentInvoice">
            <thead>
            <tr>
                <th class="text-center">Q.tà</th>
                <th>Nome</th>
                <th>Descrizione</th>
                <!--              <th class="text-center">Iva</th>-->
                <th class="text-right">Importo</th>
                <ng-container *ngIf="currentInvoice.is_old === 0">
                    <th class="text-right">IVA</th>
                    <th class="text-right">Ritenuta</th>
                    <th class="text-right">Totale</th>
                </ng-container>
            </tr>
            </thead>
            <tbody>
            <ng-container
                    *ngFor="let lineItem of currentInvoice.lines; let i = index"
            >
                <tr>
                    <td class="text-center">
                        <i *ngIf="!lineItem.editMode" class="fas fa-pen link ml-2"
                           (click)="lineItem.editMode = true;"></i>
                        <i *ngIf="lineItem.editMode" class="fas fa-check link ml-2"
                           (click)="saveInvoiceLine(lineItem)"></i>
                    </td>
                    <td class="text-bold" *ngIf="!lineItem.editMode">
                        <span *ngIf="!lineItem.voce_fattura">{{ lineItem.descrizione }}</span>
                        <span class="text-info" *ngIf="lineItem.voce_fattura">{{ lineItem.voce_fattura }}</span>
                    </td>
                    <td class="text-bold" *ngIf="lineItem.editMode"><input type="text"
                                                                           class="form-control-sm form-control"
                                                                           [(ngModel)]="lineItem.voce_fattura"
                                                                           placeholder="Scrivi la voce che dovrà uscire in fattura"/>
                    </td>
                    <td class="text-bold">
                        Periodo {{ lineItem.monthName }} {{ lineItem.anno }}
                    </td>
                    <!--              <td class="text-center">{{ lineItem.tax1 | number }}%</td>-->
                    <td class="text-right text-bold">
                        {{ lineItem.totale | currency: "€ " }}
                    </td>
                    <ng-container *ngIf="currentInvoice.is_old === 0">
                        <td class="text-right">{{ lineItem.iva | currency: "€ " }}</td>
                        <td class="text-right">{{ lineItem.ritenuta | currency: "€ " }}</td>
                        <td class="text-right text-bold">
                            {{ lineItem.netto | currency: "€ " }}
                        </td>
                    </ng-container>
                </tr>
                <tr *ngFor="let dettaglio of lineItem.sublines">
                    <td class="text-center">
                        <i *ngIf="!dettaglio.editMode" class="fas fa-pen link ml-2"
                           (click)="dettaglio.editMode = true;"></i>
                        <i *ngIf="dettaglio.editMode" class="fas fa-check link ml-2"
                           (click)="saveInvoiceDetailLine(dettaglio)"></i>
                    </td>
                    <td>{{ dettaglio.offerta }}</td>
                    <td>{{ dettaglio.info }} - {{ dettaglio.tipo }}</td>
                    <td class="text-right">{{ dettaglio.compenso | currency: "€ " }}</td>
                    <ng-container *ngIf="currentInvoice.is_old === 0">
                        <td class="text-right"></td>
                        <td class="text-center">
                            <ng-container *ngIf="dettaglio.editMode">
                                <i *ngIf="dettaglio.soggetto_a_ritenuta === 0" class="fas fa-check-circle text-success"
                                   (click)="dettaglio.soggetto_a_ritenuta = 1;"></i>
                                <i *ngIf="dettaglio.soggetto_a_ritenuta === 1" class="fas fa-times-circle text-danger"
                                   (click)="dettaglio.soggetto_a_ritenuta = 0;"></i>
                            </ng-container>
                        </td>
                        <td class="text-right text-bold"></td>
                    </ng-container>
                </tr>
            </ng-container>
            </tbody>
        </table>
    </div>
</ng-template>
