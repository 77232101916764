import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-rich-text-truncate',
    templateUrl: './rich-text-truncate.component.html',
    styleUrls: ['./rich-text-truncate.component.css']
})
export class RichTextTruncateComponent implements OnInit {
    @Input() text: any;
    @Input() charLimit = 100;

    constructor() {
    }

    ngOnInit(): void {
    }

    truncateChar(text: string): string {
        if (!text || text.length <= this.charLimit) {
            return text;
        }
        const withoutHtml = text.replace(/<(?:.|\n)*?>/gm, '');
        return withoutHtml.substring(0, this.charLimit) + '...';
    }

    isDisabled() {
        return this.text.length < this.charLimit;
    }
}
