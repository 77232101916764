import {Injectable} from '@angular/core';
import {RequestService} from '@services/request.service';
import {UserService} from '@services/user.service';
const endpoint = 'master-agents';

@Injectable({
    providedIn: 'root'
})
export class MasterAgentService {

    constructor(public request: RequestService, public userService: UserService) {
    }

    list(params = {}) {
        return this.userService.list(endpoint, params);
    }

    isUsernameAvailable(username) {
        return this.userService.isUsernameAvailable(endpoint, username);
    }

    isVatAvalaible(vat) {
        return this.userService.isVatAvalaible(endpoint, vat);
    }

    saveDealer(data) {
        return this.userService.saveDealer(endpoint, data);
    }

    updateDealer(id, data) {
        return this.userService.updateDealer(endpoint, id, data);
    }

    deleteDealer(id) {
        return this.userService.deleteDealer(endpoint, id);
    }

    restoreDealer(id) {
        return this.userService.restoreDealer(endpoint, id);
    }

    changePassword(id, password) {
        return this.userService.changePassword(endpoint, id, password);
    }

    getDealer(id, params = {}) {
        return this.userService.getDealer(endpoint, id, params);
    }
}
