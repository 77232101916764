<ngx-spinner
        bdColor="rgba(51,51,51,0.76)"
        size="medium"
        color="#fff"
        type="timer"
        [fullScreen]="true"
>
    <p style="color: white"> Caricamento... </p>
</ngx-spinner>


<div class="card bg-gradient-1">
    <div class="card-body">
        <div class="row align-items-center">
            <div class="col">
                <h1 class="display-4 mb-0">Costi Negozi</h1>
            </div>
        </div>
        <hr>
        <div class="row align-items-end">
            <div class="col-xl-12">
                <label>Seleziona periodo</label>
                <div class="form-group form-group-icon mb-0">
                    <i class="far fa-calendar-alt"></i>
                    <select class="form-control" [(ngModel)]="this.periodo">
                        <option *ngFor="let per of this.periodi" [value]="per.value">{{per.label}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>

<app-master-subheader cardClass="bg-gradient-light" [url]="urlSubheader"></app-master-subheader>

<app-master-card
        *ngIf="this.data"
        bodyClass="p-0"
        title="Negozi"
        [height]="false"
>
    <app-table
            [striped]="true"
            [rows]="this.data.rows" [columns]="this.data.columns">
    </app-table>
</app-master-card>

<div *ngIf="false" class="row align-items-center" style="overflow-y: scroll" [style.height.px]="750">
    <ng-container *ngFor="let item of data; let i=index;">
        <div class="col-12">
            <div class="card bg-gradient-white">
                <div class="card-header">
                    <p class="lead d-inline-block">{{item.negozio}}</p>
                    <p class="font-italic lead float-right">{{item.agente}}</p>
                </div>
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-1 text-center">
                            <h1 class="display-3">{{ i + 1}}</h1>
                        </div>
                        <div class="col-7">
                            <div class="row">
                                <div class="col text-center" *ngFor="let brand of item.detail">
                                    <img [src]="brand.img" height="40px" />
                                    <p class="lead">Provvigioni</p>
                                    <p>{{brand.fatturato_dealer | currency: '€ '}}</p>
                                    <div class="progress progress-xs">
                                        <div class="progress-bar bg-gradient-info" role="progressbar" [style.width.%]="brand.perc">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 border-left text-center">
                            <img src="/assets/img/flats/coins.png" height="40px">
                            <p class="lead">Provvigioni</p>
                            <p>{{item.provvigioni | currency: '€ '}}</p>
                        </div>
                        <div class="col-2 border-left text-center">
                            <circle-progress
                                    [space]="-10"
                                    [radius]="60"
                                    [toFixed]="0"
                                    [maxPercent]="100"
                                    [outerStrokeGradient]="true"
                                    [outerStrokeWidth]="10"
                                    [innerStrokeWidth]="10"
                                    [animation]="false"
                                    [animationDuration]="0"
                                    outerStrokeColor="#389af4"
                                    outerStrokeGradientStopColor="#389af4"
                                    innerStrokeColor="#e7e8ea"
                                    [title]="item.fatturato_perc + '%'"
                                    [showUnits]="false"
                                    [showSubtitle]="false"
                                    [percent]="item.fatturato_perc"
                            >

                            </circle-progress>
                        </div>
                    </div>
                </div>
                <div class="card-body text-right" [ngbCollapse]="item.collapsed">

                </div>
                <div class="card-body text-right">
                    <a class="link" (click)="item.collapsed = !item.collapsed"><i class="fa fa-plus-circle"></i> mostra di più</a>
                </div>
            </div>
        </div>
    </ng-container>
</div>
