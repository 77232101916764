import {Component, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {ShopOrder, ShopOrderReport} from '@models/shop-order';
import {MetaPagination} from '@models/meta-pagination';
import {ShopOrderService} from '@services/shop-order.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Moment} from 'moment';
import {Subscription} from 'rxjs/internal/Subscription';
import {timer} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {TicketReport} from '@models/ticket';

@Component({
    selector: 'app-shop-order-dashboard',
    templateUrl: './shop-order-dashboard.component.html',
    styleUrls: ['./shop-order-dashboard.component.css']
})
export class ShopOrderDashboardComponent implements OnInit, OnDestroy {
    dateOptions = {
        maxSpan: {month: 1}
    };
    shopOrders: ShopOrder[];
    shopOrdersPagination: MetaPagination;
    selectedDate: string;
    apiDate: {
        from: any,
        to: any
    };
    shopOrdersReport: ShopOrderReport;
    subscription: Subscription;

    constructor(private shopOrderService: ShopOrderService,
                private spinner: NgxSpinnerService,
                private dateRangePickerConfig: DaterangepickerConfig) {
        this.selectedDate = moment().format(this.dateRangePickerConfig.settings.locale.format)
            + this.dateRangePickerConfig.settings.locale.separator + moment().format(this.dateRangePickerConfig.settings.locale.format);
        this.apiDate = {
            from: moment().format(this.dateRangePickerConfig.settings.locale.apiFormat),
            to: moment().format(this.dateRangePickerConfig.settings.locale.apiFormat)
        };
        this.loadShopOrdersReport();
    }

    ngOnInit(): void {
    }


    loadShopOrdersReport() {
        this.spinner.show('report');
        this.subscription = timer(0, 2 * 60 * 1000)
            .pipe(
                mergeMap(() => this.shopOrderService.getShopOrdersReport(this.apiDate))
            )
            .subscribe((response: ShopOrderReport) => {
                this.shopOrdersReport = response;
                this.spinner.hide('report');
            }, err => {
                this.spinner.hide('report');
            });
    }

    loadNewDate(start: Moment, end: Moment) {
        if (start && end) {
            this.selectedDate = start.format(this.dateRangePickerConfig.settings.locale.format)
                + this.dateRangePickerConfig.settings.locale.separator +
                end.format(this.dateRangePickerConfig.settings.locale.format);
            this.apiDate.from = start.format(this.dateRangePickerConfig.settings.locale.apiFormat);
            this.apiDate.to = end.format(this.dateRangePickerConfig.settings.locale.apiFormat);
            this.loadShopOrdersReport();
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
