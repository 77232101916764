<div [ngBusy]="busy">

  <app-period-selector icon="/assets/img/flats/writing.png" title="I tuoi contratti" (onFilterChange)="filterTable($event)" gradient="bg-gradient-1"></app-period-selector>

  <div class="row">
    <div class="col-12">
      <app-table [rows]="filteredRows" [columns]="columns" (onSelect)="onSelect($event)"></app-table>
    </div>
  </div>
</div>
