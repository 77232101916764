import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InvoiceDocumentService} from '@services/invoice-document.service';
import {MetaPagination} from '@models/meta-pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {Document, DocumentCategory, DocumentPagination} from '@models/invoice/document';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {ConfirmOptionsService} from '@services/confirm-options.service';


@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {
    rangeOptions = {
        singleDatePicker: true,
        ranges: []
    };
    textLimit = 40;
    documents: Document[] = [];
    documentsPagination: MetaPagination;
    selectedDocument: Document;
    @ViewChild('documentFormTemplate') private documentFormTemplate;
    @ViewChild('documentDetailTemplate') private documentDetailTemplate;
    filter = {
        company_name: null,
        category: null,
        type: null,
        number: null,
        welded: null,
        date: null,
        vat_date: null,
        is_credit: null,
        vat_type: null
    };
    apiDates = {
        date: {
            from: '',
            to: ''
        },
        vat_date: {
            from: '',
            to: ''
        },
    };
    currency = '€';
    localFormat = 'DD/MM/YYYY';
    deleteDocumentOptions: ConfirmOptionsService;
    types = InvoiceDocumentService.types;
    weldedFilters = [
        {
            label: 'Yes',
            key: '1'
        },
        {
            label: 'No',
            key: '0'
        },
    ];
    categories: DocumentCategory[] = [];
    categoryLoading = false;
    vatTypes = InvoiceDocumentService.vatTypes;

    constructor(
        private modalService: NgbModal,
        private invoiceDocumentService: InvoiceDocumentService,
        private spinner: NgxSpinnerService,
        private dateRangePickerOptions: DaterangepickerConfig) {
        this.deleteDocumentOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro!',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });
    }

    ngOnInit(): void {
        this.loadDocument();
        this.loadCategories();
    }

    showDocumentModal(document?: Document) {
        this.selectedDocument = document;
        this.modalService.open(this.documentFormTemplate, {size: 'xl'});
    }

    showDetailModal(document?: Document) {
        this.selectedDocument = document;
        this.modalService.open(this.documentDetailTemplate, {size: 'xl', scrollable: true});
    }

    loadDocument() {
        this.spinner.show('table-documents');
        const params = {};
        if (this.documentsPagination?.currentPage) {
            params['page'] = this.documentsPagination?.currentPage;
        }
        if (this.filter.company_name) {
            params['company_name'] = this.filter.company_name;
        }
        if (this.filter.category) {
            params['category'] = this.filter.category;
        }
        if (this.filter.type) {
            params['type'] = this.filter.type;
        }
        if (this.filter.number) {
            params['number'] = this.filter.number;
        }
        if (this.filter?.welded) {
            params['welded'] = this.filter?.welded;
        }
        if (this.filter.date) {
            params['date_from'] = this.apiDates.date.from;
            params['date_to'] = this.apiDates.date.to;
        }
        if (this.filter.vat_date) {
            params['vat_from'] = this.apiDates.vat_date.from;
            params['vat_to'] = this.apiDates.vat_date.to;
        }
        if (this.filter.is_credit) {
            params['is_credit'] = this.filter.is_credit;
        }
        if (this.filter.vat_type) {
            params['vat_type'] = this.filter.vat_type;
        }
        this.invoiceDocumentService.getDocuments(params).subscribe((response: DocumentPagination) => {
            this.documents = response.data;
            this.documentsPagination = response._meta;
            this.spinner.hide('table-documents');
        }, err => {
            this.spinner.hide('table-documents');
        });
    }

    resetFilter() {
        this.filter = {
            company_name: null,
            category: null,
            type: null,
            number: null,
            welded: null,
            date: null,
            vat_date: null,
            is_credit: null,
            vat_type: null
        };
        this.apiDates = {
            date: {
                from: null,
                to: null
            },
            vat_date: {
                from: null,
                to: null
            },
        };
    }

    selectedDate(value, date: Moment) {
        this.filter[value] = date.format(this.dateRangePickerOptions.settings.locale.format);
        this.apiDates[value] = date.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    }

    deleteDocument(id: number) {
        this.invoiceDocumentService.deleteDocument(id).subscribe(() => {
            const index = this.documents.findIndex(brand => brand.id === id);
            if (index > -1) {
                this.documents.splice(index, 1);
                this.invoiceDocumentService.successAlert('Deleted Document!');
            }
        }, (err) => {
            this.invoiceDocumentService.errorAlert(err.message);
        });
    }

    calculateNet(document: Document) {
        const net = Number(document.amount) - Number(document.vat_amount) - Number(document.withholding_tax);
        return net.toFixed(2);
    }

    findType(type) {
        const documentType = this.types.find(item => item.key === type);
        return documentType ? documentType.name : type;
    }

    loadCategories(term?) {
        this.categoryLoading = true;
        const params = {};
        if (term) {
            params['name'] = term;
        }
        this.invoiceDocumentService.getDocumentCategories(params).subscribe((response: DocumentCategory[]) => {
            this.categories = response;
            this.categoryLoading = false;
        }, err => {
            this.categoryLoading = false;
        });
    }

    public generateDateRangeString(startDate: Moment, endDate: Moment, key) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        this.apiDates[key].from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.apiDates[key].to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.filter[key] = startDate.format(localDateFormat)
            + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
    }
}
