<h4>Payments</h4>
<table class="table table-sm">
    <thead>
    <tr>
        <th translate>Due date</th>
        <th translate>Due method</th>
        <th class="text-right" translate>Amount</th>
        <th class="text-center" translate>Welded</th>
        <th translate class="text-center">Matched</th>
    </tr>
    </thead>
        <tbody>
        <tr *ngFor="let payment of document?.payments;index as i">
            <td>{{payment.due_date|amDateFormat:localFormat}}</td>
            <td>{{findMethod(payment.due_method)}}</td>
            <td class="text-right">{{payment.amount|currency:currency}}</td>
            <td class="text-center">
                <i class="text-success fas fa-check" *ngIf="payment.welded==1"></i>
                <i class="text-danger fas fa-times" *ngIf="payment.welded==0"></i>
            </td>
            <td class="text-center">
                <i class="text-success fas fa-check" *ngIf="payment.transaction_id"></i>
                <i class="text-danger fas fa-times" *ngIf="!payment.transaction_id"></i>
            </td>
        </tr>
        <tr *ngIf="document?.payments?.length<=0">
            <td translate colspan="5">List is empty</td>
        </tr>
        </tbody>
    </table>
