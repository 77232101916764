<div class="card bg-gradient-1">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                 width="160px"
                 src="assets/img/flats/calendar.png">
            <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>Calendar</h1>
                <hr>
                <form (ngSubmit)="loadCalendarReport()">
                    <div class="row align-items-end justify-content-between">
                        <div class="col-12 col-lg">
                            <label for="subjectId" translate>Subject</label>
                            <ng-select [items]="subjects" bindLabel="company_name" id="subjectId"
                                       [loading]="subjectLoading" [(ngModel)]="filter.subject_id"
                                       name="subject_id"
                                       bindValue="id" [searchable]="true"
                                       (search)="loadSubject($event?.term)"></ng-select>
                        </div>
                        <div class="col-12 col-lg">
                            <label for="documentId" translate>Document</label>
                            <ng-select [loading]="documentLoading" [(ngModel)]="filter.document_id"
                                       name="document_id"
                                       [searchable]="true" (search)="loadDocuments($event?.term)" id="documentId">
                                <ng-option *ngFor="let document of documents" [value]="document?.id">
                                    {{document?.number}} [{{document?.amount|currency:currency}}]
                                </ng-option>
                            </ng-select>
                        </div>
                        <div class="col-12 col-lg">
                            <label for="documentId" translate>Payment type</label>
                                <div ngbDropdown [autoClose]="false">
                                    <button class="btn btn-light btn-block text-right" type="button" ngbDropdownToggle>
                                    </button>
                                    <div ngbDropdownMenu id = "methodId">
                                        <ul style="max-height: 300px; overflow-y: scroll; padding: 0;">
                                            <li ngbDropdownItem *ngFor="let dueMethod of dueMethods">
                                                <label style="min-width: 100%" class="mb-0">
                                                    <input type="checkbox" [(ngModel)]="dueMethod.checked" [name] = "dueMethod.key"/>
                                                    {{ dueMethod.name }}
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-12">
                            <button class="btn btn-info btn-block" type="submit" translate>Filter
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-7 col-12">
        <div class="card bg-gradient-light">
            <div class="card-body p-0">
                <ngx-spinner
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="default"
                        color="#fff"
                        type="square-jelly-box"
                        name="calendar"
                        [fullScreen]="false"
                >
                    <p style="color: white">Caricamento...</p>
                </ngx-spinner>
                <full-calendar [options]="calendarOptions" #calendar></full-calendar>
            </div>
        </div>
    </div>
    <div class="col-lg col-12">
        <div class="card bg-gradient-light">
            <div class="card-header">
                <h4 class="card-title w-100" translate>Payments
                    <span class="float-right">{{selectedDay|amDateFormat:localFormat}}</span>
                </h4>
            </div>
            <div class="card-body p-0 table-responsive">
                <ngx-spinner
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="default"
                        color="#fff"
                        type="square-jelly-box"
                        name="paymentDetail"
                        [fullScreen]="false"
                >
                    <p style="color: white">Caricamento...</p>
                </ngx-spinner>
                <table class="table table-sm">
                    <thead *ngIf="payments?.length>0">
                    <tr>
                        <th translate>Company name</th>
                        <th translate>Document No</th>
                        <th translate>Type</th>
                        <th translate class="text-right">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let payment of payments">
                        <td>
                            <a href="javascript:void(0)"
                               (click)="showSubjectModal(payment?.document?.subject_id)">{{payment?.document?.company_name}}</a>
                        </td>
                        <td>
                            <a href="javascript:void(0)"
                               (click)="showDocumentModal(payment?.document_id)">
                                {{payment?.document?.number}}</a></td>
                        <td>{{payment?.type}}</td>
                        <td translate class="text-right">
                            <span class="text-danger" *ngIf="payment?.document?.is_credit==0">
                                {{payment.amount|currency:currency}}</span>
                            <span class="text-success" *ngIf="payment?.document?.is_credit==1">
                                {{payment.amount|currency:currency}}</span>
                        </td>
                    </tr>
                    <tr *ngIf="payments?.length<=0">
                        <td translate colspan="4" class="text-muted">Select a day</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer" *ngIf="payments?.length>0">
    <span><span translate>There are</span><b> {{ this.paymentsPagination.totalCount }}</b>
        {{this.paymentsPagination.totalCount <= 1 ? 'payment' : 'payments'|translate}}.</span>
                <ngb-pagination *ngIf="paymentsPagination.pageCount>1"
                                size="sm"
                                [pageSize]="paymentsPagination.perPage"
                                [maxSize]="10"
                                [(page)]="paymentsPagination.currentPage"
                                [collectionSize]="paymentsPagination.totalCount"
                                (pageChange)="loadPayments()"
                                [boundaryLinks]="true"
                ></ngb-pagination>
            </div>
        </div>
    </div>
</div>
<ng-template #subjectDetailTemplate let-modal>
    <app-subject-view [subjectId]="selectedSubjectId"></app-subject-view>
</ng-template>
<ng-template #documentDetailTemplate let-modal>
    <app-document-view [documentId]="selectedDocumentId"></app-document-view>
</ng-template>
