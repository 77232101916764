<div class="contract-identifier d-none d-lg-block">
    <p>Stai attivando con</p>
    <p>Codice Master Group: <strong>MAST-{{me.profile.id}}</strong></p>
</div>
<div [formGroup]="form" class="form-step">
    <div class="form-group" *ngIf="this.venditori.length > 0">
        <label><i class="fa fa-chevron-circle-right"></i>Venditore</label>
        <div class="btn-group btn-group-toggle" ngbRadioGroup formControlName="id_dealer">
            <label ngbButtonLabel *ngFor="let venditore of venditori" class="btn btn-light">
                <input ngbButton type="radio" [value]="venditore.id">{{venditore.ragsociale}}
            </label>
        </div>
        <val-errors controlName="id_operatore"></val-errors>
    </div>

    <div class="form-group">
        <label><i class="fa fa-chevron-circle-right"></i>Brand</label>
        <div class="btn-group btn-group-toggle" ngbRadioGroup formControlName="id_operatore">
            <label ngbButtonLabel *ngFor="let brand of skeletons" class="btn btn-light">
                <input ngbButton type="radio" [value]="brand.id"><img [src]="brand.image" height="35px"/>
            </label>
        </div>
        <val-errors controlName="id_operatore"></val-errors>
    </div>

    <div class="form-group" *ngIf="piste.length > 0">
        <label><i class="fa fa-chevron-circle-right"></i>Servizio</label>
        <div class="btn-group btn-group-toggle" ngbRadioGroup formControlName="id_pista">
            <label ngbButtonLabel *ngFor="let pista of piste" class="btn btn-light">
                <input ngbButton type="radio" [value]="pista.id">{{pista.nome}}
            </label>
        </div>
        <val-errors controlName="id_pista"></val-errors>
    </div>

    <div class="form-group" *ngIf="famiglie.length > 0">
        <label><i class="fas fa-users"></i>Tipo Cliente</label>
        <div class="btn-group btn-group-toggle" ngbRadioGroup formControlName="id_famiglia">
            <label ngbButtonLabel *ngFor="let famiglia of famiglie" class="btn btn-light">
                <input ngbButton type="radio" [value]="famiglia.id">{{famiglia.nome}}
            </label>
        </div>
        <val-errors controlName="id_famiglia"></val-errors>
    </div>

    <div class="form-group" *ngIf="sottofamiglie.length > 0">
        <label><i class="fa fa-chevron-circle-right"></i>Tipo Attivazione</label>
        <div class="btn-group btn-group-toggle" ngbRadioGroup formControlName="id_sottofamiglia">
            <label ngbButtonLabel *ngFor="let sottofamiglia of sottofamiglie" class="btn btn-light">
                <input ngbButton type="radio" [value]="sottofamiglia.id">{{sottofamiglia.nome}}
            </label>
        </div>
        <val-errors controlName="id_sottofamiglia"></val-errors>
    </div>

    <div class="form-group" *ngIf="offerte && offerte.length > 0">
        <label><i class="fa fa-chevron-circle-right"></i>Offerta</label>
        <div class="btn-group btn-group-toggle" ngbRadioGroup formControlName="id_offerta">
            <label ngbButtonLabel *ngFor="let offerta of offerte" class="btn btn-light">
                <input ngbButton type="radio" [value]="offerta.id">{{offerta.nome}}
            </label>
        </div>
        <val-errors controlName="id_offerta"></val-errors>
    </div>

    <div class="row" *ngIf="this.hasRicaricaContestuale()">
        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
            <div class="form-group">
                <label><i class="fa fa-chevron-circle-right"></i>Ricarica Contestuale</label>
                <input type="text" class="form-control" formControlName="ricarica_contestuale" disabled="disabled"/>
            </div>
        </div>
    </div>
    <div class="row" formArrayName="campi_personalizzati">
        <div class="col-xl-4 col-lg-6 col-md-6 col-12" *ngFor="let campo of getCampi(); let i=index;">
            <div class="form-group" [formGroupName]="campo.controls.index.value">
                <label><i class="fa fa-chevron-circle-right"></i>{{campo.controls.nome.value}}</label>
                <ng-container [ngSwitch]="campo.controls.tipo_campo.value">
                    <input *ngSwitchCase="null" type="text" class="form-control" formControlName="valore"/>

                    <input *ngSwitchCase="'date'" class="form-control" placeholder="gg-mm-aaaa"
                           formControlName="valore" ngbDatepicker #valore="ngbDatepicker"
                           (click)="valore.toggle()">
                </ng-container>
                <val-errors controlName="valore"></val-errors>
            </div>
        </div>
        <ng-container *ngFor="let gruppo of this.gruppiCampi">
            <div class="col-12">
                <h1 class="display-6">{{gruppo.nome}}</h1>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-12"
                 *ngFor="let campo of getCampiGruppo(gruppo.id); let i=index;">
                <div class="form-group" [formGroupName]="campo.controls.index.value">
                    <label><i class="fa fa-chevron-circle-right"></i>{{campo.controls.nome.value}}</label>
                    <ng-container [ngSwitch]="campo.controls.tipo_campo.value">
                        <input *ngSwitchCase="null" type="text" class="form-control" formControlName="valore"/>

                        <input *ngSwitchCase="'date'" class="form-control" placeholder="gg-mm-aaaa"
                               formControlName="valore" ngbDatepicker #valore="ngbDatepicker"
                               (click)="valore.toggle()">

                        <ng-select *ngSwitchCase="'select'"
                                   formControlName="valore"
                                   [items]="getOptions(campo.controls.id_tipo.value)"
                        >
                        </ng-select>
                        <ng-select *ngSwitchCase="'multiselect'"
                                   formControlName="valore"
                                   [multiple]="true"
                                   [items]="getOptions(campo.controls.id_tipo.value)"
                        >
                        </ng-select>

                        <div class="btn-group btn-group-toggle w-100" ngbRadioGroup formControlName="valore"
                             *ngSwitchCase="'radio'">
                            <label ngbButtonLabel class="btn-success">
                                <input ngbButton type="radio" [value]="true"> Si
                            </label>
                            <label ngbButtonLabel class="btn-danger">
                                <input ngbButton type="radio" [value]="false"> No
                            </label>
                        </div>

                        <ng-select *ngSwitchCase="'provincia'"
                                   bindLabel="provincia"
                                   bindValue="id"
                                   formControlName="valore"
                                   (change)="this.setComuni($event, campo.controls.id_gruppo.value)"
                                   [items]="this.province"
                        ></ng-select>

                        <ng-select *ngSwitchCase="'comune'"
                                   [items]="this.comuni[campo.controls.id_gruppo.value]"
                                   bindLabel="comune"
                                   bindValue="id"
                                   formControlName="valore"
                        ></ng-select>
                    </ng-container>
                    <val-errors controlName="valore"></val-errors>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="row" formArrayName="seriali">
        <div class="col-xl-4 col-lg-6 col-md-6 col-12" *ngFor="let seriale of getSeriali(); let i=index;">
            <div class="form-group" [formGroupName]="i">
                <label><i class="fa fa-chevron-circle-right"></i>{{seriale.controls.nome.value}}</label>

                <ng-select [items]="this.seriali | where:['id_tipo', seriale.controls.id_tipo.value]"
                           bindLabel="seriale"
                           bindValue="id"
                           formControlName="valore">
                </ng-select>
                <val-errors controlName="valore"></val-errors>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="this.getPortabilita()" formGroupName="portabilita">
        <div class="col-12">
            <h1 class="display-6">Dati Portabilità</h1>
        </div>
        <ng-container *ngIf="this.getPortabilita() === 1">
            <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                <div class="form-group">
                    <label><i class="fa fa-chevron-circle-right"></i>Operatore</label>
                    <ng-select [items]="this.operatoriMnp"
                               bindLabel="operator_name"
                               bindValue="operator_name"
                               formControlName="operatore">
                    </ng-select>
                    <val-errors controlName="operatore"></val-errors>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                <div class="form-group">
                    <label><i class="fa fa-chevron-circle-right"></i>Seriale</label>
                    <input type="text" class="form-control" formControlName="seriale"/>
                    <val-errors controlName="seriale"></val-errors>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="this.getPortabilita() === 2">
            <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                <div class="form-group">
                    <label><i class="fa fa-chevron-circle-right"></i>Operatore</label>
                    <input type="text" class="form-control" formControlName="operatore"/>
                    <val-errors controlName="operatore"></val-errors>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                <div class="form-group">
                    <label><i class="fa fa-chevron-circle-right"></i>Codice di migrazione</label>
                    <input type="text" class="form-control" formControlName="seriale"/>
                    <val-errors controlName="seriale"></val-errors>
                </div>
            </div>
        </ng-container>

        <div class="col-xl-4 col-lg-6 col-md-6 col-12">
            <div class="form-group">
                <label><i class="fa fa-chevron-circle-right"></i>Telefono</label>
                <input type="text" class="form-control" formControlName="numero"/>
                <val-errors controlName="numero"></val-errors>
            </div>
        </div>
    </div>
</div>
