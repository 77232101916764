/* tslint:disable:variable-name */

import {BaseModel} from './base-model';
import {number} from 'ngx-custom-validators/src/app/number/validator';
import {AverageTime} from '@models/shop-order';

export class TicketReport extends BaseModel {
    today: {
        total: number,
        handled: number,
        to_handle: number,
    };

    total: {
        in_handle: number,
        waiting_our_reply: number,
        waiting_their_reply: number,
    };

    ranking: [
        {
            operatore: string,
            today_handled: number,
            in_handle: number,
            waiting_our_reply: number,
            handling_time: AverageTime
        }
    ];

    dealer_ranking: [
        {
            dealer: string,
            totale: number
        }
    ];
}

export class TicketCategoria extends BaseModel {
    id: number;
    nome: string;
    id_padre: number;
    id_vendor: number;
    subcategories: TicketCategoria[];

    get childModels() {
        return {
            subcategories: TicketCategoria
        };
    }

    fromArray(result: []) {
        const array: TicketCategoria[] = [];
        for (const item of result) {
            array.push(new TicketCategoria(item));
        }
        return array;
    }
}

export class TicketRisposte extends BaseModel {
    id: number;
    id_ticket: number;
    id_utente: number;
    risposta: string;
    utente: string;
    visto: number;
    created: string;
    creator: string;

    fromArray(result: []) {
        const array: TicketRisposte[] = [];
        for (const item of result) {
            array.push(new TicketRisposte(item));
        }
        return array;
    }
}

export class TicketAttachment extends BaseModel {
    id: number;
    id_ticket: number;
    doc_path: string;
    ticketfile: File;

    get filename() {
        return this.doc_path.split('/').pop();
    }

    fromArray(result: []) {
        const array: TicketAttachment[] = [];
        for (const item of result) {
            array.push(new TicketAttachment(item));
        }
        return array;
    }
}

export class Ticket extends BaseModel {
    id: number;
    id_dealer: number;
    testo: string;
    id_categoria: number;
    categoria: string;
    id_operatore: number;
    stato: number;
    operatore: string;
    num_risposte: number;
    new: boolean;
    risposte: TicketRisposte[];
    created: string;
    dealer: string;

    retailer: string;
    category: string;
    subcategory: string;
    replies: TicketRisposte[];
    attachments: TicketAttachment[];

    allegati: [
        {
            doc_path: string;
            id: number;
            sequence: number;
        }
    ];
    handled_date: string;


    get childModels() {
        return {
            replies: TicketRisposte,
            attachments: TicketAttachment,
        };
    }

    fromArray(result: []) {
        const array: Ticket[] = [];
        for (const item of result) {
            array.push(new Ticket(item));
        }
        return array;
    }
}
