import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from '@services/authentication.service';
import {map} from 'rxjs/operators';
import {environment} from '@environments/environment';
import {AlertService} from '@services/alert.service';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    constructor(
        public http: HttpClient,
        public autenthicationService: AuthenticationService,
        public alertService: AlertService
    ) {
    }


    post(url, data) {
        return this.http
            .post(`${environment.apiUrl}${url}`, data)
            .pipe(map((response: any) => {
                if (response.hasOwnProperty('profile')) {
                    this.autenthicationService.updateAccountData(response.profile);
                }
                return response;
            }));
    }

    get(url, data = {}) {
        let baseUrl = environment.apiUrl;
        return this.http.get(`${baseUrl}${url}`, {
            params: data
        })
            .pipe(map((response: any) => {
                if (response.hasOwnProperty('profile')) {
                    this.autenthicationService.updateAccountData(response.profile);
                }
                return response;
            }));
    }

    public successAlert() {
        this.alertService.show(
            '',
            'Successfully Changed!',
            {classname: 'bg-success text-light', delay: 2000}
        );
    }

    public errorAlert(message?) {
        this.alertService.show(
            'An error occurred!',
            message ? message : 'Try again!',
            {classname: 'bg-danger text-light', delay: 2000}
        );
    }
}
