<div class="row" [ngBusy]="busy">
    <div class="col-12">
        <div class="card card-main-header bg-gradient-1">
            <div class="card-body">
                <img class="bg-gradient-light" src="/assets/img/flats/payment.png">
                <div class="card-content row align-items-center">
                    <div class="col">
                        <h1>I tuoi estratti conto</h1>
                        <hr>
                        <h2 class="mb-0">Lista degli estratti conto che ti abbiamo emesso.</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-master-card title="Lista Estratti Conto" bodyClass="p-0">
    <app-table [columns]="data.columns" [rows]="data.rows" (onTrigger)="onTrigger($event)"></app-table>
</app-master-card>

<app-documents-modal #showDocument></app-documents-modal>

<app-modal title="Dettaglio Estratto Conto" bodyClass="p-0" #dettaglioModal>
    <app-table [columns]="voci.columns" [rows]="voci.rows" [hideHeader]="true"></app-table>
</app-modal>
