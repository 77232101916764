import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '@app/services/authentication.service';
import {ROLE_AGENT, ROLE_DEALER, ROLE_MASTER, ROLE_OPERATOR} from '@models/user';

@Injectable({providedIn: 'root'})
export class VendorGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue.profile;

        if (route.data.vendor && currentUser?.operatori?.some(e => route.data.vendor.includes(e))) {
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/'], {queryParams: {returnUrl: state.url}});
        return false;
    }
}
