<app-master-header cardClass="bg-gradient-1"
                   title="Lista Venditori del presidio {{data.presidio}}"
                   image="assets/img/flats/employee.png"
                   [ngBusy]="busy"
>
    <p class="lead">Da qui puoi gestire le risorse attualmente attive sul presidio {{data.presidio}}</p>
</app-master-header>

<div class="mb-3">
    <button class="btn btn-dark" routerLink="/gestione-stand">Torna alla lista dei presidi</button>
    <button class="btn btn-primary ml-3" routerLink="/gestione-stand/{{this.id}}/venditori/2">Mostra tutti i venditori</button>
    <button class="btn btn-success ml-3" routerLink="/gestione-stand/{{this.id}}/venditori/0">Mostra solo attivi</button>
    <button class="btn btn-danger ml-3" routerLink="/gestione-stand/{{this.id}}/venditori/1">Mostra solo cancellati</button>

    <button class="btn btn-warning float-right" routerLink="/gestione-stand/{{this.id}}/nuovo/venditore">Aggiungi Venditore</button>
</div>
<app-master-card
        title="Lista Venditori"
        bodyClass="p-0"
>
    <app-table [rows]="data.table.rows"
               [columns]="data.table.columns"
               [small]="true"
               [pageSize]="20"
               [hideFooterDescription]="true"
    >
    </app-table>
</app-master-card>

