import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { City } from "@app/models/geo";
import { CityService } from "@app/services/city.service";

@Component({
  selector: "app-city-select",
  templateUrl: "./city-select.component.html",
  styleUrls: ["./city-select.component.scss"],
})
export class CitySelectComponent implements OnInit {
  @Input()
  small = false;
  
  _province = "";

  @Input()
  set province(value: string) {
    this._province = value;
    this.city = null;

    if (this.onDemand) {
      this.refresh();
    }
  }

  get province() {
    return this._province;
  }

  @Input()
  onDemand = false;

  @Input()
  city: City;
  data: City[] = [];
  loading = false;

  @Output() onChange = new EventEmitter();

  constructor(private service: CityService) {}

  ngOnInit(): void {
    if(!this.onDemand || this.province) {
      this.refresh();
    }

    if(this.city) {
      this.onChange.emit(this.city);
    }
  }

  refresh() {
    this.loading = true;
    this.service
      .list({
        fields: "id,comune",
        province: this.province,
      })
      .subscribe((data) => {
        this.data = new City().fromArray(data);
        this.loading = false;
      });
  }
}
