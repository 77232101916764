
/* tslint:disable:variable-name */

import { BaseModel } from "./base-model";

export class VideoCategory extends BaseModel {
    id: number;
    logo: string;
    logo_file: File;
    title: string;
    description: string;
    order: number;
    video_count: number;
    videos: Video[] = [];
    created_at: string;
    is_deleted: number;

    get childModels() {
        return {
            videos: Video
        }
    }

    fromArray(result: []) {
        const array: VideoCategory[] = [];
        for (const item of result) {
            array.push(new VideoCategory(item));
        }
        return array;
    }
}

export class Video extends BaseModel {
    id: number;
    id_category: number;
    created_by: VideoCreator;
    video_path: string;
    title: string;
    description: string;
    available_from: string;
    available_to: string;
    availability_range: string = '';
    duration: number;
    created_at: string;

    get childModels() {
        return {
            created_by: VideoCreator
        }
    }

    fromArray(result: []) {
        const array: Video[] = [];
        for (const item of result) {
            array.push(new Video(item));
        }
        return array;
    }

}

export class VideoCreator extends BaseModel {
    id: number;
    first_name: string;
    last_name: string;
}
