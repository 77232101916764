import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-contract-simulatore-eni',
    templateUrl: './contract-simulatore-eni.component.html',
    styleUrls: ['./contract-simulatore-eni.component.css']
})
export class ContractSimulatoreEniComponent implements OnInit {
    tipoVendita = 'residenziale';
    tipoPagamento = 'rid';
    cadenzaBolletta = 'bimestrale';
    kwh = 845;
    spesaEnergia = 77.74;
    smc = 200;
    spesaGas = 70;

    kwhPrimaFascia = 200;
    kwhSecondaFascia = 300;
    kwhTerzaFascia = 345;

    constructor() {
    }

    ngOnInit(): void {
    }

    get bestResidenziale() {
        if (this.scontoCerto.energia.risparmioSecondoAnno > this.flexi.energia.risparmio) {
            return 'scontocerto';
        }
        return 'flexi';
    }

    get bestBusiness() {
        let migliorRisparmio = this.raddoppioSicuro.energia.migliorRisparmio;
        let migliorOfferta = 'raddoppio_sicuro';
        if (this.scontoSuMisura.energia.migliorRisparmio > migliorRisparmio) {
            migliorRisparmio = this.scontoSuMisura.energia.migliorRisparmio;
            migliorOfferta = 'sconto_su_misura';
        }
        if (this.suMisuraMyEnergy.energia.migliorRisparmio > migliorRisparmio) {
            migliorRisparmio = this.scontoSuMisura.energia.migliorRisparmio;
            migliorOfferta = 'su_misura_my_energy';
        }
        return migliorOfferta;
    }

    get scontoCerto() {
        const energia = {
            mensilePrimoAnno: 0,
            mensileSecondoAnno: 0,
            risparmioPrimoAnno: 0,
            risparmioSecondoAnno: 0,
            spesaEnergiaPrimoAnno: 0.0845,
            spesaEnergiaSecondoAnno: 0.08,
            scontoDomiciliazione: 5,
            commercializzazione: 10
        };

        energia.mensilePrimoAnno = this.kwh * energia.spesaEnergiaPrimoAnno;
        energia.mensileSecondoAnno = this.kwh * energia.spesaEnergiaSecondoAnno;

        energia.mensilePrimoAnno += energia.commercializzazione;
        energia.mensileSecondoAnno += energia.commercializzazione;

        if (this.cadenzaBolletta === 'bimestrale') {
            energia.mensilePrimoAnno += energia.commercializzazione;
            energia.mensileSecondoAnno += energia.commercializzazione;
        }

        energia.risparmioPrimoAnno = this.spesaEnergia - energia.mensilePrimoAnno;
        energia.risparmioSecondoAnno = this.spesaEnergia - energia.mensileSecondoAnno;

        const gas = {
            mensilePrimoAnno: 0,
            mensileSecondoAnno: 0,
            risparmioPrimoAnno: 0,
            risparmioSecondoAnno: 0,
            spesaGasPrimoAnno: 0.2746,
            spesaGasSecondoAnno: 0.2601,
            scontoDomiciliazione: 5,
            commercializzazione: 10
        };

        gas.mensilePrimoAnno = this.smc * gas.spesaGasPrimoAnno;
        gas.mensileSecondoAnno = this.smc * gas.spesaGasSecondoAnno;

        gas.mensilePrimoAnno += gas.commercializzazione;
        gas.mensileSecondoAnno += gas.commercializzazione;

        if (this.cadenzaBolletta === 'bimestrale') {
            gas.mensilePrimoAnno += gas.commercializzazione;
            gas.mensileSecondoAnno += gas.commercializzazione;
        }

        gas.risparmioPrimoAnno = this.spesaGas - gas.mensilePrimoAnno;
        gas.risparmioSecondoAnno = this.spesaGas - gas.mensileSecondoAnno;

        return {
            energia,
            gas
        };
    }

    get flexi() {
        const energia = {
            mensile: 0,
            risparmio: 0,
            spesaEnergia: 0.0879,
            scontoDomiciliazione: 5,
            commercializzazione: 9
        };

        if (this.tipoPagamento === 'rid') {
            energia.spesaEnergia *= (100 - energia.scontoDomiciliazione) / 100;
        }

        energia.mensile = this.kwh * energia.spesaEnergia;

        energia.mensile += energia.commercializzazione;

        if (this.cadenzaBolletta === 'bimestrale') {
            energia.mensile += energia.commercializzazione;
        }

        energia.risparmio = this.spesaEnergia - energia.mensile;

        const gas = {
            mensile: 0,
            risparmio: 0,
            spesaGas: 0.279,
            scontoDomiciliazione: 5,
            commercializzazione: 9
        };

        if (this.tipoPagamento === 'rid') {
            gas.spesaGas *= (100 - gas.scontoDomiciliazione) / 100;
        }


        gas.mensile = this.smc * gas.spesaGas;

        gas.mensile += gas.commercializzazione;

        if (this.cadenzaBolletta === 'bimestrale') {
            gas.mensile += gas.commercializzazione;
        }

        gas.risparmio = this.spesaGas - gas.mensile;

        return {
            energia,
            gas
        };
    }

    get raddoppioSicuro() {
        const energia = {
            mensileMonoraria: 0,
            mensileMonoraria4_6_mese: 0,
            mensileMonoraria7_mese: 0,
            mensileBioraria: 0,
            mensileBioraria4_6_mese: 0,
            mensileBioraria7_mese: 0,
            spesaMonoraria: 0.0926,
            spesaBiorariaF1: 0.086,
            spesaBiorariaF2: 0.085,
            spesaBiorariaF3: 0.075,
            risparmioMonoraria: 0,
            risparmioMonoraria4_6_mese: 0,
            risparmioMonoraria7_mese: 0,
            risparmioBioraria: 0,
            risparmioBioraria4_6_mese: 0,
            risparmioBioraria7_mese: 0,
            commercializzazione: 16.5,
            migliorRisparmio: 0,
            scontoDomiciliazione: 5
        };

        energia.mensileMonoraria = this.kwh * energia.spesaMonoraria;
        energia.mensileMonoraria4_6_mese = this.kwh * (energia.spesaMonoraria * 95 / 100);
        energia.mensileMonoraria7_mese = this.kwh * (energia.spesaMonoraria * 90 / 100);

        energia.mensileBioraria = (this.kwhPrimaFascia * energia.spesaBiorariaF1);
        energia.mensileBioraria += (this.kwhSecondaFascia * energia.spesaBiorariaF2);
        energia.mensileBioraria += (this.kwhTerzaFascia * energia.spesaBiorariaF3);


        energia.mensileBioraria4_6_mese = (this.kwhPrimaFascia * (energia.spesaBiorariaF1 * 95 / 100));
        energia.mensileBioraria4_6_mese += (this.kwhSecondaFascia * (energia.spesaBiorariaF2 * 95 / 100));
        energia.mensileBioraria4_6_mese += (this.kwhTerzaFascia * (energia.spesaBiorariaF3 * 95 / 100));


        energia.mensileBioraria7_mese = (this.kwhPrimaFascia * (energia.spesaBiorariaF1 * 90 / 100));
        energia.mensileBioraria7_mese += (this.kwhSecondaFascia * (energia.spesaBiorariaF2 * 90 / 100));
        energia.mensileBioraria7_mese += (this.kwhTerzaFascia * (energia.spesaBiorariaF3 * 90 / 100));

        energia.mensileMonoraria += energia.commercializzazione;
        energia.mensileMonoraria4_6_mese += energia.commercializzazione;
        energia.mensileMonoraria7_mese += energia.commercializzazione;

        energia.mensileBioraria += energia.commercializzazione;
        energia.mensileBioraria4_6_mese += energia.commercializzazione;
        energia.mensileBioraria7_mese += energia.commercializzazione;

        if (this.cadenzaBolletta === 'bimestrale') {
            energia.mensileMonoraria += energia.commercializzazione;
            energia.mensileMonoraria4_6_mese += energia.commercializzazione;
            energia.mensileMonoraria7_mese += energia.commercializzazione;

            energia.mensileBioraria += energia.commercializzazione;
            energia.mensileBioraria4_6_mese += energia.commercializzazione;
            energia.mensileBioraria7_mese += energia.commercializzazione;
        }

        energia.risparmioMonoraria = this.spesaEnergia - energia.mensileMonoraria;
        energia.risparmioMonoraria4_6_mese = this.spesaEnergia - energia.mensileMonoraria4_6_mese;
        energia.risparmioMonoraria7_mese = this.spesaEnergia - energia.mensileMonoraria7_mese;

        energia.risparmioBioraria = this.spesaEnergia - energia.mensileBioraria;
        energia.risparmioBioraria4_6_mese = this.spesaEnergia - energia.mensileBioraria4_6_mese;
        energia.risparmioBioraria7_mese = this.spesaEnergia - energia.mensileBioraria7_mese;

        energia.migliorRisparmio = energia.risparmioMonoraria7_mese;

        if (energia.risparmioBioraria7_mese > energia.risparmioMonoraria7_mese) {
            energia.migliorRisparmio = energia.risparmioBioraria7_mese;
        }

        const gas = {
            mensile: 0,
            mensile4_6_mese: 0,
            mensile7_mese: 0,
            risparmio: 0,
            risparmio4_6_mese: 0,
            risparmio7_mese: 0,
            spesaGas: 0.299,
            scontoDomiciliazione: 5,
            commercializzazione: 13
        };

        gas.mensile = this.smc * gas.spesaGas;
        gas.mensile4_6_mese = this.smc * (gas.spesaGas * 95 / 100);
        gas.mensile7_mese = this.smc * (gas.spesaGas * 90 / 100);

        gas.mensile += gas.commercializzazione;
        gas.mensile4_6_mese += gas.commercializzazione;
        gas.mensile7_mese += gas.commercializzazione;

        if (this.cadenzaBolletta === 'bimestrale') {
            gas.mensile += gas.commercializzazione;
            gas.mensile4_6_mese += gas.commercializzazione;
            gas.mensile7_mese += gas.commercializzazione;
        }

        gas.risparmio = this.spesaGas - gas.mensile;
        gas.risparmio4_6_mese = this.spesaGas - gas.mensile4_6_mese;
        gas.risparmio7_mese = this.spesaGas - gas.mensile7_mese;

        return {
            energia,
            gas
        };
    }

    get scontoSuMisura() {
        const energia = {
            mensileDay: 0,
            mensileH24: 0,
            mensileNight: 0,
            spesaDayF1F2: 0.084,
            spesaDayF3: 0.087,
            spesaH24: 0.0849,
            spesaNightF1F2: 0.077,
            spesaNightF3: 0.07,
            risparmioDay: 0,
            risparmioH24: 0,
            risparmioNight: 0,
            migliorRisparmio: 0,
            scontoDomiciliazione: 5,
            commercializzazione: 16.5
        };

        energia.mensileDay = ((this.kwhPrimaFascia + this.kwhSecondaFascia) * energia.spesaDayF1F2);
        energia.mensileDay += (this.kwhTerzaFascia * energia.spesaDayF3);

        energia.mensileH24 = ((this.kwhPrimaFascia + this.kwhSecondaFascia + this.kwhTerzaFascia) * energia.spesaH24);

        energia.mensileNight = ((this.kwhPrimaFascia + this.kwhSecondaFascia) * energia.spesaNightF1F2);
        energia.mensileNight += (this.kwhTerzaFascia * energia.spesaNightF3);

        energia.mensileDay += energia.commercializzazione;
        energia.mensileH24 += energia.commercializzazione;
        energia.mensileNight += energia.commercializzazione;

        if (this.cadenzaBolletta === 'bimestrale') {
            energia.mensileDay += energia.commercializzazione;
            energia.mensileH24 += energia.commercializzazione;
            energia.mensileNight += energia.commercializzazione;
        }

        energia.risparmioDay = this.spesaEnergia - energia.mensileDay;
        energia.risparmioH24 = this.spesaEnergia - energia.mensileH24;
        energia.risparmioNight = this.spesaEnergia - energia.mensileNight;

        energia.migliorRisparmio = energia.risparmioDay;

        if (energia.risparmioH24 > energia.migliorRisparmio) {
            energia.migliorRisparmio = energia.risparmioH24;
        }
        if (energia.risparmioNight > energia.migliorRisparmio) {
            energia.migliorRisparmio = energia.risparmioNight;
        }

        const gas = {
            mensile: 0,
            risparmio: 0,
            spesaGas: 0.265,
            scontoDomiciliazione: 5,
            commercializzazione: 13
        };

        gas.mensile = this.smc * gas.spesaGas;

        gas.mensile += gas.commercializzazione;

        if (this.cadenzaBolletta === 'bimestrale') {
            gas.mensile += gas.commercializzazione;
        }

        gas.risparmio = this.spesaGas - gas.mensile;

        return {
            energia,
            gas
        };
    }

    get suMisuraMyEnergy() {
        const energia = {
            mensileDay: 0,
            mensileH24: 0,
            mensileNight: 0,
            spesaDayF1F2: 0.07,
            spesaDayF3: 0.077,
            spesaH24: 0.0719,
            spesaNightF1F2: 0.077,
            spesaNightF3: 0.07,
            risparmioDay: 0,
            risparmioH24: 0,
            risparmioNight: 0,
            migliorRisparmio: 0,
            commercializzazione: 17.5
        };

        energia.mensileDay = ((this.kwhPrimaFascia + this.kwhSecondaFascia) * energia.spesaDayF1F2);
        energia.mensileDay += (this.kwhTerzaFascia * energia.spesaDayF3);

        energia.mensileH24 = ((this.kwhPrimaFascia + this.kwhSecondaFascia + this.kwhTerzaFascia) * energia.spesaH24);

        energia.mensileNight = ((this.kwhPrimaFascia + this.kwhSecondaFascia) * energia.spesaNightF1F2);
        energia.mensileNight += (this.kwhTerzaFascia * energia.spesaNightF3);

        energia.mensileDay += energia.commercializzazione;
        energia.mensileH24 += energia.commercializzazione;
        energia.mensileNight += energia.commercializzazione;

        if (this.cadenzaBolletta === 'bimestrale') {
            energia.mensileDay += energia.commercializzazione;
            energia.mensileH24 += energia.commercializzazione;
            energia.mensileNight += energia.commercializzazione;
        }

        energia.risparmioDay = this.spesaEnergia - energia.mensileDay;
        energia.risparmioH24 = this.spesaEnergia - energia.mensileH24;
        energia.risparmioNight = this.spesaEnergia - energia.mensileNight;

        energia.migliorRisparmio = energia.risparmioDay;

        if (energia.risparmioH24 > energia.migliorRisparmio) {
            energia.migliorRisparmio = energia.risparmioH24;
        }
        if (energia.risparmioNight > energia.migliorRisparmio) {
            energia.migliorRisparmio = energia.risparmioNight;
        }

        return {
            energia
        };
    }

    checkBusiness() {
        if (this.tipoVendita === 'business') {
            this.tipoPagamento = 'rid';
        }
    }
}
