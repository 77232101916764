import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthenticationService} from '@services/authentication.service';
import {Helpers} from '@helpers/helpers';
import {User} from '@models/user';

@Component({
    selector: 'app-master-header',
    templateUrl: './master-header.component.html',
    styleUrls: ['./master-header.component.css']
})
export class MasterHeaderComponent implements OnInit {
    @Input() cardClass: string;
    @Input() image: string = null;
    @Input() headerBackground: string = null;
    @Input() title: string;
    @Input() background = true;
    @Input() rounded = true;
    @Input() smallTitle = false;

    _periodo: string;
    _from: string;
    _to: string;
    _operatore: string;

    listaPeriodi = Helpers.getPeriods();
    listaOperatori = this.authService.operatori;

    private user: User;
    stand = false;

    @Input() agenti = false;
    @Input() master = false;
    @Input() operatori = false;
    @Input() periodoSingle = false;
    @Input() periodoFrom = false;
    @Input() periodoTo = false;

    _agente = null;

    @Output() onChange = new EventEmitter();

    set from(value: string) {
        this._from = value;
        this.updateValues();
    }

    get from() {
        return this._from;
    }

    set to(value: string) {
        this._to = value;
        this.updateValues();
    }

    get to() {
        return this._to;
    }

    set periodo(value: string) {
        this._periodo = value;
        this.updateValues();
    }

    get periodo() {
        return this._periodo;
    }

    set operatore(value: string) {
        this._operatore = value;
        this.updateValues();
    }

    get operatore() {
        return this._operatore;
    }

    get bigTitle() {
        return !this.smallTitle;
    }

    constructor(private authService: AuthenticationService) {
        this.authService.currentUser.subscribe((user) => (this.user = user));
        this.stand = this.user && this.user.profile.operatori.includes('Kena Mobile Stands');
    }

    ngOnInit() {
        this._operatore = this.listaOperatori[0];
        this._from = this.listaPeriodi[1]['value'];
        this._to = this.listaPeriodi[0]['value'];
        this._periodo = this.listaPeriodi[0]['value'];
        this.updateValues();
    }

    updateValues() {
        this.onChange.emit({
            periodo: this.periodo,
            from: this.from,
            to: this.to,
            operatore: this.operatore,
            agente: this._agente,
        });
    }

    onAgenteSelect($event: any) {
        this._agente = $event.id;
        this.updateValues();
    }

    onPresidioSelect($event: any) {
        this._agente = $event;
        this.updateValues();
    }
}
