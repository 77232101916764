<div class="card bg-gradient-dark">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                 width="160px"
                 src="assets/img/flats/shopping-cart.png">
            <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>Shop Orders</h1>
                <hr>
                <div class="row align-items-end">
                    <div class="col-lg-4 col-12"><label for="validityPeriod" translate>Select date</label>
                        <input id="validityPeriod" class="form-control" type="text" daterangepicker
                               [(ngModel)]="selectedDate"
                               [options]="dateOptions" (selected)="loadNewDate($event?.start, $event?.end)"/>
                    </div>
                    <div class="col-lg-4 col-12" *show="['agent', 'operator']">
                        <label for="dealer" class="" translate>Dealer</label>
                        <app-retailer-typeahead (onChange)="handleDealerSelectedModal($event)"></app-retailer-typeahead>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name='report'
            [fullScreen]="false"
    >
    </ngx-spinner>

    <div class="col-12">
        <div class="card mt-3">
            <div class="card-header">
                <h4 class="mb-0" translate>List of orders</h4>
            </div>
            <div class="card-body p-0 rounded-bottom table-responsive">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th></th>
                        <th translate>ID Ordine</th>
                        <th translate>Negozio</th>
                        <th translate>Pagamento</th>
                        <th translate class="text-right">Spedizione</th>
                        <th translate class="text-right">Totale Ordine</th>
                        <th translate class="text-center">Stato</th>
                        <th translate>Data Creazione</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ngx-spinner
                            bdColor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="square-jelly-box"
                            name="table-calls"
                            [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <tr *ngFor="let order of shopOrdersList">
                        <td>
                            <button class="btn btn-sm btn-info" [routerLink]="['/negozio/dettaglio', order.id]"><i
                                    class="fas fa-eye mr-0"></i></button>
                        </td>
                        <td>{{order.id}}</td>
                        <td>{{order.dealer.company_name}}</td>
                        <td translate>{{order.payment}}</td>
                        <td class="text-right">{{order.shipping_total | currency: '€ '}}</td>
                        <td class="text-right">{{order.order_total | currency: '€ '}}</td>
                        <td class="text-center">
                            <span class="badge"
                                  [ngClass]="{'badge-warning': order.status_name === 'In revisione',
                                   'badge-danger': order.status_name === 'Annullato',
                                    'badge-success': order.status_name === 'Gestito'}">{{order.status_name}}</span>
                        </td>
                        <td>{{order.created | amDateFormat: 'H:m DD/MM/YYYY'}}</td>
                    </tr>

                    <tr *ngIf="shopOrdersList?.length<=0">
                        <td class="text-muted" colspan="11" translate>List is empty</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer" *ngIf="shopOrdersList?.length>0">
        <span><span translate>There are</span> <b> {{ this.pagination.totalCount }}</b>
            {{this.pagination.totalCount <= 1 ? 'order' : 'orders'|translate}}.</span>
                <ngb-pagination *ngIf="pagination.pageCount>1"
                                size="sm"
                                [pageSize]="pagination.perPage"
                                [maxSize]="5"
                                [(page)]="pagination.currentPage"
                                [collectionSize]="pagination.totalCount"
                                (pageChange)="loadShopOrdersList()"
                                [boundaryLinks]="true"
                ></ngb-pagination>
            </div>
        </div>
    </div>
</div>
