import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DashboardService} from '@services/dashboard.service';

@Component({
    selector: 'app-master-presidi',
    templateUrl: './master-presidi.component.html',
    styleUrls: ['./master-presidi.component.css']
})
export class MasterPresidiComponent implements OnInit {

    busy: Subscription;
    startup: any;

    _periodo: string;
    _proiezione = 'No';

    urlSubheader: string;
    urlDettaglio: string;
    urlOfferte: string;
    urlOperatori: string;

    set periodo(value: string) {
        this._periodo = value;
        this.updateUrls();
    }

    get periodo() {
        return this._periodo;
    }

    set proiezione(value: string) {
        this._proiezione = value;
        this.updateUrls();
    }

    get proiezione() {
        return this._proiezione;
    }

    constructor(private dashboardService: DashboardService) {
    }

    ngOnInit() {
        this.busy = this.dashboardService.get('/presidi')
            .subscribe((data) => {
                this.startup = data.result;
                this.periodo = this.startup.periodo;
                this.updateUrls();
            }, (error) => {
                this.updateUrls();
            });
    }

    updateUrls(specific = null) {
        if (!specific || specific === 'subheader') {
            this.urlSubheader = `/presidi-header?periodo=${this.periodo}&proiezione=${this.proiezione}`;
        }
        if (!specific || specific === 'subheader') {
            this.urlDettaglio = `/presidi-dettaglio?periodo=${this.periodo}&proiezione=${this.proiezione}`;
        }
        if (!specific || specific === 'subheader') {
            this.urlOfferte = `/presidi-offerte?periodo=${this.periodo}&proiezione=${this.proiezione}`;
        }
        if (!specific || specific === 'subheader') {
            this.urlOperatori = `/presidi-operatori?periodo=${this.periodo}&proiezione=${this.proiezione}`;
        }
    }

}
