<div class="text-dark">
    <p class="text-white lead" translate *ngIf="isInSchedule">{{inScheduleText}}</p>
    <p class="text-white lead" translate *ngIf="!isInSchedule">{{outOfScheduleText}}</p>
    <button type="button" class="btn btn-light mt-3" (click)="openModel()" *show="['master','operator']" translate> Show
        agents performance
    </button>
</div>
<ng-template #scheduleTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title w-100 d-block"
            translate>
            <span translate class="text-capitalize pr-1">{{targetType}}</span><span
                translate>per agents</span>
        </h4>
        <button type="button" class="close" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busy">
        <div class="row mb-3">
            <div class="col-lg-4 col-12">
                <label>Filter</label>
                <ng-select bindValue="value" [items]="filtersList" [(ngModel)]="filter.performance"
                           (ngModelChange)="loadFilter()"
                           [searchable]="false" [markFirst]="true"
                           bindLabel="title">
                </ng-select>
            </div>
            <div class="col-lg-4 col-12">
                <label>Agent</label>
                <app-agent-select (onChange)="filterByName($event)">
                </app-agent-select>
            </div>
        </div>
        <div class="agents-section overflow-auto">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th scope="col">Agent</th>
                    <th scope="col" class="text-right text-capitalize"
                        *ngFor="let month of monthsList">{{formatMonth(month)}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let agent of agentsFilter">
                    <td class="text-break bg-white">{{agent.full_name}}</td>
                    <td *ngFor="let month of monthsList" class="text-right">
                        <button type="button" class="btn btn-link text-dark text-decoration-none p-0" placement="left"
                                [ngbPopover]="additionalValueContent"
                                popoverTitle="{{agent.full_name}} - {{formatMonth(month)}}"
                                autoClose="outside" triggers="mouseenter:mouseleave">
                            {{ agent.dates[month].plan ? setFormat(agent.dates[month].plan.value) : '--' }}
                            / {{setFormat(agent.dates[month].real)}}
                            <app-plan-percentage [item]="agent.dates[month]"
                                                 [isCurrentMonth]="isCurrentMonth(month)"></app-plan-percentage>
                        </button>
                        <ng-template #additionalValueContent>
                            <p>Plan / Real / Increase</p>
                            <p *ngIf="isCurrentMonth(month)">
                                Projection value: <strong>{{setProjectionValue(agent.dates[month].real)}}</strong></p>
                            <div class="d-block" *ngIf="breakDownTypes.length>0">
                                <p>Breakdown values:</p>
                                <p class="text-capitalize" *ngFor="let type of breakDownTypes">
                                    {{encodeName(type)}}
                                    <span class="float-right">
                                        <strong>
                                        {{ agent.dates[month]['breakdown'][type].plan ? setFormat(agent.dates[month]['breakdown'][type].plan.value) : '--'}}
                                            / {{setFormat(agent.dates[month]['breakdown'][type].real)}}
                                            <app-plan-percentage [item]="agent.dates[month]['breakdown'][type]"
                                                                 [isCurrentMonth]="isCurrentMonth(month)"></app-plan-percentage></strong>
                                    </span>
                                </p>
                            </div>
                        </ng-template>
                    </td>
                </tr>
                <tr *ngIf="agentsFilter.length<=0">
                    <td [attr.colspan]="monthsList.length+1">No data provide</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>
