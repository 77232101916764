<div class="row">
    <div class="col-12">
        <app-master-header
                cardClass="bg-gradient-light"
                image="assets/img/flats/support.png"
                title="Tickets"
                [periodoSingle]="true"
                (onChange)="refresh($event.periodo)"
        >
        </app-master-header>

        <div>
            <app-master-subheader cardClass="bg-gradient-dark" *spinner="!subheaderLoading.closed" [data]="subheader"></app-master-subheader>
        </div>
    </div>

    <div class="col-12 col-xl-5">
        <app-master-card title="Classifica Tickets" bodyClass="p-0">
            <app-table *spinner="!classificaLoading.closed" [rows]="classifica?.rows" [columns]="classifica?.columns"></app-table>
        </app-master-card>
    </div>
    <div class="col-12 col-xl-7">
        <app-master-card title="Lista Tickets" bodyClass="p-0">
            <app-table *spinner="!listaLoading.closed" [rows]="lista?.rows" [columns]="lista?.columns"></app-table>
        </app-master-card>
    </div>
</div>
