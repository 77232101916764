import {Injectable} from '@angular/core';
import {RequestService} from '@services/request.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ShopOrderFile, ShopOrderListPagination, ShopOrderPagination, ShopOrderReport} from '@models/shop-order';
import {AlertService} from '@services/alert.service';

const shopOrders = 'shop-orders';

@Injectable({
    providedIn: 'root'
})
export class ShopOrderService {

    constructor(private request: RequestService, private alertService: AlertService) {
    }

    public static statuses = [
        {
            label: 'In attesa di gestione',
            key: -2,
            checked: false
        },
        {
            label: 'Da preparare',
            key: -1,
            checked: false
        },
        {
            label: 'In revisione',
            key: 1,
            checked: false
        },
        {
            label: 'In attesa',
            key: 2,
            checked: false
        },
        {
            label: 'Gestito',
            key: 3,
            checked: false
        },
        {
            label: 'Tornata in sede',
            key: 5,
            checked: false
        },
        {
            label: 'Annullato',
            key: 6,
            checked: false
        },
    ];
    public static handledStatuses = [
        {
            label: 'Gestito',
            key: 0,
            checked: true
        },
        {
            label: 'In attesa di gestione',
            key: 1,
            checked: false
        },
    ];
    public static payments = [
        {
            label: 'Bonifico Bancario',
            key: 0,
            checked: false
        },
        {
            label: 'Contanti al corriere',
            key: 1,
            checked: false
        },
        {
            label: 'Carta di credito',
            key: 2,
            checked: false
        },
        {
            label: 'Compensazione',
            key: 3,
            checked: false
        },
        {
            label: 'Rimessa agente',
            key: 4,
            checked: false
        },
        {
            label: 'Assegno a vista al corriere',
            key: 5,
            checked: false
        },
        {
            label: 'Omaggio',
            key: 8,
            checked: false
        },
        {
            label: 'Compensazione anticipata',
            key: 9,
            checked: false
        }
    ];

    public getShopOrders(selectedDate, pageNumber: number = 1, dealer: number = null): Observable<ShopOrderPagination> {
        const params = {from: selectedDate.from, to: selectedDate.to, page: pageNumber};
        if (dealer) {
            params['id_dealer'] = dealer;
        }
        return this.request.get(shopOrders, params).pipe(
            map((response: any) => {
                return new ShopOrderPagination(response);
            })
        );
    }

    public getShopOrdersReport(selectedDate): Observable<ShopOrderReport> {
        return this.request.get(shopOrders + '/report', selectedDate).pipe(
            map((response: any) => {
                return new ShopOrderReport(response);
            })
        );
    }

    public getShopOrderList(params?): Observable<ShopOrderListPagination> {
        return this.request.get(shopOrders + '/list', params).pipe(
            map((response: any) => {
                return new ShopOrderListPagination(response);
            })
        );
    }

    public putShopOrder(id, body) {
        return this.request.put(shopOrders + '/' + id, body).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public uploadFile(id, body: ShopOrderFile) {
        return this.request.post(shopOrders + '/update/' + id, body.postPayload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public putShopOrderProduct(id, body) {
        return this.request.put(shopOrders + '/product/' + id, body).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public postShopOrderProduct(body) {
        return this.request.post(shopOrders + '/product', body).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getShopOrderProducts(params?) {
        return this.request.get(shopOrders + '/product-list', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getShopOrderDetail(params?) {
        return this.request.get(shopOrders + '/view', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getShopOrderPackages(id, params?) {
        return this.request.get(shopOrders + '/' + id + '/packages', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteShopOrderProduct(id) {
        return this.request.delete(shopOrders + '/product/' + id).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public successAlert() {
        this.alertService.show(
            '',
            'Successfully Changed!',
            {classname: 'bg-success text-light', delay: 2000}
        );
    }

    public errorAlert(message?) {
        this.alertService.show(
            'An error occurred!',
            message ? message : 'Try again!',
            {classname: 'bg-danger text-light', delay: 2000}
        );
    }
}
