<ng-template #content let-modal class="bg-gradient-light">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Configura il prodotto</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>


    <div class="modal-body">
        <form>
            <div class="row">
                <div class="col-12 col-lg-4">
                    <img [src]="product.image" class="m-3" width="100%"/>
                    <h1 class="display-6">{{product.productname}}</h1>
                    <p class="text-muted font-italic" [innerHTML]="product.description | safeHtml"></p>
                </div>
                <div class="col-12 col-lg-4">
                    <div [ngClass]="{'section-focus': sectionFocus === 'quantity'}">
                        <p class="lead" [ngClass]="{'text-primary': sectionFocus === 'quantity'}">1) Scegli la quantità</p>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item d-flex justify-content-between align-items-center px-0"
                                *ngFor="let variant of product?.variants;index as i">
                                <div>
                                    <p>{{variant?.quantity}} pezzi</p>
                                    <small class="text-muted">Prezzo
                                        Unitario {{variant?.price|currency:currency}}</small>
                                </div>
                                <div class="text-center">
                                    <div class="mr-2 variation-btn">
                                        <input type="radio" class="btn-check"
                                               [id]="i" autocomplete="off" name="selectedVariantId"
                                               (change)="changeVariant(variant)"
                                               [value]="variant.id" [(ngModel)]="selectedVariantId">
                                        <label class="btn btn-outline-primary btn-sm rounded-pill" [for]="i">
                                            {{variant?.total_price|currency:currency}}</label>
                                    </div>
                                    <!--                                        <p><small class="text-muted">totale (iva escl.)</small></p>-->
                                </div>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center px-0"
                                *ngIf="product?.variants?.length<=0" translate>
                                List is empty
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div [ngClass]="{'section-focus': sectionFocus === 'graphics'}">
                        <p class="lead" [ngClass]="{'text-primary': sectionFocus === 'graphics'}">2) Carica la tua grafica o scegli tra quelle Master Group</p>
                        <div class="row align-items-start">
                            <div class="col-6 col-lg-3 text-center">
                                <div class="m-2 border text-center template-container btn-group-toggle">
                                    <label class="h-100 w-100" *ngIf="!submittedFile">
                                        <img src="/assets/img/flats/file_upload.png" class="mt-2" height="60px"
                                             alt=""/>
                                        <input type="file" style="visibility: hidden"
                                               (change)="handleFileInput($event?.target?.files[0])">
                                    </label>
                                    <div class="position-relative h-100 w-100 d-flex align-items-center justify-content-center"
                                         *ngIf="submittedFile">
                                        <img [src]="submittedFilePath" class="" height="60px" width="60px"
                                             alt=""/>
                                        <div class="position-absolute" style="top:-8px;right: -5px;">
                                            <button type="button" class="close" aria-label="Close"
                                                    (click)="handleFileRemoveModal()">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <p class="text-muted">Carica il tuo template fronte/retro</p>
                            </div>
                            <div class="col-6 col-lg-3 text-center" *ngFor="let template of product?.templates">
                                <div class="m-2 border text-center template-container btn-group-toggle"
                                     ngbRadioGroup>
                                    <label ngbButtonLabel
                                           class="h-100 w-100  d-flex align-items-center justify-content-center"
                                           [ngClass]="{'bg-shadow-pink':selectedTemplate?.id == template?.id}">
                                        <img [src]="template?.template" class="" height="60px" width="60px"
                                             alt=""/>
                                        <input ngbButton type="radio" [value]="template.id"
                                               [checked]="selectedTemplate?.id == template?.id"
                                               (change)="changeTemplate(template)">
                                    </label>
                                </div>
                                <p class="text-muted">{{template?.nome}}</p>
                                <p class="text-pink font-italic">{{template?.sconto}}% sconto</p>
                            </div>
                        </div>
                        <div id="layouttemplates"><h6>Modelli di layout da scaricare</h6>
                            <ul class="pl-0">
                                <li><a class="indd"
                                       href="/layoutcdn/flyer_a4_mass/indd_6869ab6a-80d0-4b36-9c7d-7ce0343586fa.zip"
                                       target="_blank"></a></li>
                                <li><a class="idml"
                                       href="/layoutcdn/flyer_a4_mass/idml_130f6e1d-847f-4135-b8c9-29c13c1aed98.zip"
                                       target="_blank"></a></li>
                                <li><a class="jpg"
                                       href="/layoutcdn/flyer_a4_mass/jpg_726b9882-4c2a-401c-9b52-1bcc15a9fa29.zip"
                                       target="_blank"></a></li>
                                <li><a class="pdf"
                                       href="/layoutcdn/flyer_a4_mass/pdf_ab036436-60b0-4447-b032-2eaa9a7e73f4.zip"
                                       target="_blank"></a></li>
                                <li><a class="psd"
                                       href="/layoutcdn/flyer_a4_mass/psd_da787e27-0c43-49ec-a942-065e2398a4b8.zip"
                                       target="_blank"></a></li>
                                <li><a class="eps"
                                       href="/layoutcdn/flyer_a4_mass/eps_e8fa0c04-76a3-4a2a-a861-5716b962c4e1.zip"
                                       target="_blank"></a></li>
                                <li><a class="ai"
                                       href="/layoutcdn/flyer_a4_mass/ai_b530f551-d97e-4720-bca9-96b7f5fe4b10.zip"
                                       target="_blank"></a></li>
                            </ul>
                        </div>
                    </div>

                    <table class="table">
                        <tr>
                            <td>
                                Prezzo di listino
                            </td>
                            <td class="text-right"><p
                                    class="product-price-small">{{variantPrice|currency:currency}}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Sconto del {{selectedTemplate?.discount}}%
                            </td>
                            <td class="text-right"><p
                                    class="product-price-small">{{discountPrice|currency:currency}}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Prezzo Finale
                            </td>
                            <td class="text-right">
                                <p class="product-price">{{totalPrice|currency:currency}}</p>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer text-right">
        <button class="btn btn-success" (click)="addToCart()"
                [disabled]="disabledBtn">Aggiungi al carrello
        </button>
    </div>
</ng-template>
<app-shop-sub-products-picker #picker></app-shop-sub-products-picker>

