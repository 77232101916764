<div class="row" [ngBusy]="busy">
    <div class="col-12 col-lg-9 order-2 order-lg-1">
        <form [formGroup]="orderForm">
            <div class="card bg-gradient-light">
                <div class="card-body">
                    <ng-container *show="['agent','operator']">
                        <legend>Intestazione ordine</legend>
                        <app-negozio-select (isValid)="this.isDealerValid = $event"
                                            (onChange)="assignment($event)"></app-negozio-select>
                    </ng-container>

                    <ng-container>
                        <legend>Indirizzo di spedizione</legend>
                        <app-address
                                [startData]="this.shippingData"
                                (isValid)="this.isShippingValid = $event"
                                (addressData)="changeAddress($event)"
                        ></app-address>
                    </ng-container>
                    <legend>Metodo di pagamento</legend>
                    <div class="btn-group btn-group-toggle flex-wrap elevation-1 rounded w-100 mb-2" ngbRadioGroup
                         formControlName="so_pagamento">
                        <label ngbButtonLabel class="bg-gradient-info" *ngFor="let paymentType of paymentTypes">
                            <input ngbButton type="radio" [value]="paymentType?.value"
                                   [disabled]="paymentType?.disabled">
                            <i [class]="paymentType?.icon"></i>
                            <span class="pl-2" translate>{{paymentType?.name}}</span>
                        </label>
                    </div>

                    <div class="alert alert-warning alert-dismissible"
                         *ngIf="false && this.cartService.onlyVirtualProducts">
                        <p>
                            <i class="icon fas fa-exclamation-triangle"></i> Il pagamento con contrassegno è
                            disabilitato per acquisti contenenti solo plafond.
                        </p>
                    </div>
                    <ng-container *show="['dealer']">
                        <div *ngIf="this.cartService.cartGrandTotal > me.credito && this.cartService.cartGrandTotal > 0 && me.credito >= 0.1">
                            <hr>
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="checkCompensazioneParziale"
                                       formControlName="compensazione_parziale">
                                <label class="custom-control-label" for="checkCompensazioneParziale">
                                    Voglio azzerare il mio credito di <b>{{me.credito | currency: '€ '}}</b> per ridurre
                                    il
                                    totale ordine da pagare a
                                    <b>{{ this.cartService.cartGrandTotal - me.credito | currency: '€ '}}</b>
                                </label>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container [ngSwitch]="this.orderForm.controls.so_pagamento.value">
                        <ng-container *ngSwitchCase="'0'">
                            <div class="alert alert-info alert-dismissible">
                                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                                <p><i class="icon fas fa-info"></i> Dopo aver completato l'ordine ti daremo le
                                    istruzioni per effettuare il bonifico</p>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'3'">
                            <div class="alert alert-info alert-dismissible">
                                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                                <h5><i class="icon fas fa-info"></i> Attenzione</h5>
                                <p>Il tuo credito verso Master Group ammonta a <b>{{ me.credito | currency: '€ ' }}</b>
                                </p>
                                <p>Procedendo con l'acquisto il tuo credito diventerà di
                                    <b>{{ me.credito - this.cartService.cartGrandTotal | currency: '€ ' }}</b></p>
                            </div>
                        </ng-container>
                    </ng-container>

                    <legend>Note</legend>
                    <textarea class="form-control" formControlName="description"></textarea>

                    <ng-container *ngIf="hasSmsProducts()">
                        <legend class="mt-2">Termini & Condizioni</legend>

                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="terminicondizioni"
                                   [(ngModel)]="accettaTermini" [ngModelOptions]="{standalone: true}">
                            <label class="custom-control-label" for="terminicondizioni">
                                Accetto i <a
                                    href="https://kdistribution.it/wp-content/uploads/2021/11/Contratto_Master-SMS.pdf"
                                    target="_blank">termini & condizioni</a> del servizio master-sms.it
                            </label>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="hasKDActivation()">
                        <legend class="mt-2">Termini & Condizioni per KDRicarica</legend>

                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="terminicondizionikd"
                                   [(ngModel)]="accettaTerminiKD" [ngModelOptions]="{standalone: true}">
                            <label class="custom-control-label" for="terminicondizionikd">
                                Accetto i <a href="https://kdistribution.it/wp-content/uploads/2021/11/termini_kd.pdf"
                                             target="_blank">termini & condizioni</a> del servizio KDRicarica
                            </label>
                        </div>
                    </ng-container>
                </div>
                <div class="card-footer">
                    <button class="btn bg-gradient-secondary"
                            [routerLink]="['/negozio/acquista']">Indietro
                    </button>
                    <button class="btn bg-gradient-success float-right"
                            [ladda]="cartService.isBusy"
                            (click)="buy()"
                            [disabled]="cantBuy()">Concludi ordine
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class="col-12 col-lg-3 order-1 order-lg-2">
        <app-cart [hideFooter]="true"></app-cart>
    </div>
</div>
<div class="row" *ngIf="availablePayments?.length">
    <div class="col-12 col-lg-9 order-2 order-lg-1" *ngFor="let paymentType of paymentTypes">
        <div class="alert alert-default-warning" role="alert" *ngIf="paymentType?.disabled">
            <span translate>{{paymentType?.name}}</span>
            <span class="ml-1" translate>is disabled because of products:</span>
            <p *ngIf="disabledCompensation(paymentType?.key)">
                <span><small translate class="text-muted">Not enough credit to order!</small></span></p>
            <div *ngIf="!disabledCompensation(paymentType?.key)">
                <p *ngFor="let item of filterProducts(paymentType?.key)" class="text-muted">
                    <small>{{item}}</small>
                </p>
            </div>
        </div>
    </div>
</div>
