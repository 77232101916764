import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Dealer} from '@models/dealer';
import {MasterAgentService} from '@services/master-agent.service';

@Component({
    selector: 'app-master-agent-select',
    templateUrl: './master-agent-select.component.html',
    styleUrls: ['./master-agent-select.component.css']
})
export class MasterAgentSelectComponent implements OnInit {

    @Input()
    small = false;
    @Input()
    disabled = false;

    @Input()
    masterAgent: any;

    @Input()
    vendors: [];

    data: Dealer[] = [];

    @Output() onChange = new EventEmitter();

    loading = false;

    constructor(
        private service: MasterAgentService
    ) {
    }

    ngOnInit(): void {
        if (this.masterAgent) {
            this.masterAgent = parseInt(this.masterAgent);
        }
        let params = {fields: 'id,ragsociale', 'per-page': 0};

        if (this.vendors && this.vendors.length > 0) {
            params['vendors'] = this.vendors.join(',');
        }

        this.loading = true;
        this.service.list(params).subscribe((data) => {
            this.data = new Dealer().fromArray(data.rows);
            this.loading = false;
        });
    }
}
