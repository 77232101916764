import {Injectable} from '@angular/core';
import {RequestService} from '@services/request.service';
import {AlertService} from '@services/alert.service';
import {Document, DocumentPaymentPagination} from '@models/invoice/document';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Subject} from '@models/invoice/subject';
import {Report, ReportCategory, ReportCategoryPagination, ReportPagination} from '@models/report';

const report = 'report';
const category = 'category';

@Injectable({
    providedIn: 'root'
})
export class ReportService {

    constructor(public request: RequestService, private alertService: AlertService) {
    }

    public getReports(params?): Observable<ReportPagination> {
        return this.request.get(report, params).pipe(
            map((response: any) => {
                return new ReportPagination(response);
            })
        );
    }

    public getCategories(params?): Observable<ReportCategoryPagination> {
        return this.request.get(report + '/' + category, params).pipe(
            map((response: any) => {
                return new ReportCategoryPagination(response);
            })
        );
    }

    public postReport(body: Report): Observable<Report> {
        return this.request.post(report, body).pipe(
            map((response: any) => {
                return new Report(response);
            })
        );
    }

    public putReport(id: number, body: Report): Observable<Report> {
        return this.request.put(report + '/' + id, body).pipe(
            map((response: any) => {
                return new Report(response);
            })
        );
    }

    public postReportCategory(body: ReportCategory): Observable<ReportCategory> {
        return this.request.post(report + '/' + category, body).pipe(
            map((response: any) => {
                return new ReportCategory(response);
            })
        );
    }

    public deleteReport(id: number) {
        return this.request.delete(report + '/' + id);
    }

    public successAlert(text) {
        this.alertService.show(
            '',
            'Successfully ' + text,
            {classname: 'bg-success text-light', delay: 2000}
        );
    }

    public errorAlert(message?) {
        this.alertService.show(
            'An error occurred!',
            message ? message : 'Try again!',
            {classname: 'bg-danger text-light', delay: 2000}
        );
    }
}
