import {Component, OnInit} from '@angular/core';
import {DataService} from '@services/data.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {TableColumn, TableColumnAlignment, TableColumnType} from '@app/pages/partials/table/table.component';
import {AuthenticationService} from '@services/authentication.service';
import {Contract} from '@models/contract/contract';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss']
})
export class ContractListComponent implements OnInit {
  busy: Subscription;
  rows: Contract[] = [];
  columns: TableColumn[] = [
    {key: 'id', text: 'ID Contratto', alignment: TableColumnAlignment.CENTER},
    {key: 'logo', text: 'Brand', type: TableColumnType.IMAGE, alignment: TableColumnAlignment.CENTER},
    {key: 'ragsociale', text: 'Venditore'},
    {key: 'cliente', text: 'Cliente'},
    {key: 'pista', text: 'Servizio'},
    {key: 'famiglia', text: 'Tipo Cliente'},
    {key: 'sottofamiglia', text: 'Tipo Contratto'},
    {key: 'offerta', text: 'Offerta'},
    {key: 'stato', text: 'Stato', type: TableColumnType.BADGE},
    {key: 'created', text: 'Data', type: TableColumnType.DATE},
  ];

  filteredRows = [];

  constructor(
      public dataService: DataService,
      public authenticationService: AuthenticationService,
      public router: Router
  ) { }

  ngOnInit() {
    this.busy = this.dataService.get('/lista-contratti')
        .subscribe((data: any) => {
          this.rows = new Contract().fromArray(data.result);
          this.filteredRows = this.rows.slice();
        });
  }

  onSelect(event: any) {
    this.router.navigate(['/contratti/dettaglio', event]);
  }

  filterTable(filter: string) {

    this.filteredRows = this.rows.filter(contract => {
      if (!filter || filter === '') {
        return true;
      }
      const creationDate = new Date(contract.created);
      const filterParts = filter.split('-');
      return creationDate.getMonth() === parseInt(filterParts[0], 10) && creationDate.getFullYear() === parseInt(filterParts[1], 10);
    });

  }
}
