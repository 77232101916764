<div class="row" [ngBusy]="busy">
  <div class="col-12">
    <div class="card card-main-header bg-gradient-dark">
      <div class="card-body">
        <img class="bg-gradient-light" src="/assets/img/flats/calendar.png">
        <div class="card-content row align-items-center">
          <div class="col">
            <h1>I tuoi eventi</h1>
            <hr>
            <h2 class="mb-0">Lista degli eventi da te attesi</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 mb-3">
    <button class="btn btn-success" [routerLink]="['/presidia-evento/nuovo']"> Nuovo Evento</button>
  </div>
  <div class="col-12">
    <app-table [rows]="rows" [columns]="columns" (onSelect)="onSelect($event)"></app-table>
  </div>
</div>
