<div class="card bg-gradient-1">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                 width="160px"
                 src="assets/img/flats/payment.png">
            <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>Report</h1>
                <hr>
                <form (ngSubmit)="loadData()">
                    <div class="form-row align-items-end">
                        <div class="col-lg-5 col-12"><label for="date" translate>Date</label>
                            <input id="date" class="form-control" type="text" daterangepicker
                                   [(ngModel)]="filter.date" name="date"
                                   (selected)="setSelectedDateRange($event.start, $event.end)"/>
                        </div>
                        <div class="col-lg-5 col-12"><label for="date" translate>Competence</label>
                            <ng-select [(ngModel)]="filter.competence" name="competence">
                                <ng-option *ngFor="let category of categories" translate
                                           [value]="category.key">{{category.name}}</ng-option>
                            </ng-select>
                        </div>
                        <div class="col-lg-2 col-12">
                            <button class="btn btn-info btn-block" type="submit" translate>Filter
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row mt-4">
    <div class="col-12 col-lg-6">
        <div class="card">
            <div class="card-header bg-gradient-1">
                <div class="row">
                    <div class="col-lg-8 col-12">
                        <h4 class="mb-0" translate>Clients</h4>
                    </div>
                    <div class="col-lg-4 col-12">
                        <form (ngSubmit)="loadClients()">
                            <div class="input-group input-group-sm w-100">
                                <input class="form-control float-right" placeholder="Cerca ..."
                                       type="text" name="clientName" [(ngModel)]="clientName">
                                <div class="input-group-append">
                                    <button class="btn btn-default" type="submit"><i class="fas fa-search"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="card-body p-0 table-responsive">
                <table class="table table-bordered table-striped table-sm">
                    <thead>
                    <tr>
                        <th translate>Client</th>
                        <th translate>Percentage of the total</th>
                        <th class="text-right" translate>Revenue</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ngx-spinner
                            bdColor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="square-jelly-box"
                            name="table-clients"
                            [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <tr *ngFor="let client of clients">
                        <td>{{client.company_name}}
                        </td>
                        <td>{{client.percentage}}%
                            <div class="progress" style="height: 5px;">
                                <div class="progress-bar bg-info" role="progressbar"
                                     [ngStyle]="{'width': client.percentage ? client.percentage+'%' : '0%' }"
                                     aria-valuenow="25"
                                     aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </td>
                        <td class="text-right">{{client.revenue|currency:currency}}</td>
                    </tr>
                    <tr *ngIf="clients?.length<=0">
                        <td class="text-muted" colspan="3">List is empty</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer" *ngIf="clients?.length>0">
             <span><span translate>There are</span><b> {{ this.clientsPagination?.totalCount }}</b>
                 {{this.clientsPagination?.totalCount <= 1 ? 'client' : 'clients'|translate}}.</span>
                <ngb-pagination *ngIf="clientsPagination?.pageCount>1"
                                size="sm"
                                [pageSize]="clientsPagination.perPage"
                                [maxSize]="5"
                                [(page)]="clientsPagination.currentPage"
                                [collectionSize]="clientsPagination.totalCount"
                                (pageChange)="loadClients()"
                                [boundaryLinks]="true"
                ></ngb-pagination>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6">
        <div class="card">
            <div class="card-header bg-gradient-1">
                <div class="row">
                    <div class="col-lg-8 col-12">
                        <h4 class="mb-0" translate>Suppliers</h4>
                    </div>
                    <div class="col-lg-4 col-12">
                        <form (ngSubmit)="loadSuppliers()">
                            <div class="input-group input-group-sm w-100">
                                <input class="form-control float-right" placeholder="Cerca ..."
                                       type="text" name="supplierName" [(ngModel)]="supplierName">
                                <div class="input-group-append">
                                    <button class="btn btn-default" type="submit"><i class="fas fa-search"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="card-body p-0 table-responsive">
                <table class="table table-bordered table-striped table-sm">
                    <thead>
                    <tr>
                        <th translate>Supplier</th>
                        <th translate>Percentage of the total</th>
                        <th class="text-right" translate>Cost</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ngx-spinner
                            bdColor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="square-jelly-box"
                            name="table-suppliers"
                            [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <tr *ngFor="let supplier of suppliers">
                        <td>{{supplier.company_name}}
                        </td>
                        <td>{{supplier.percentage}}%
                            <div class="progress" style="height: 5px;">
                                <div class="progress-bar bg-danger" role="progressbar"
                                     [ngStyle]="{'width': supplier.percentage ? supplier.percentage+'%' : '0%' }"
                                     aria-valuenow="25"
                                     aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </td>
                        <td class="text-right">{{supplier.cost|currency:currency}}</td>
                    </tr>
                    <tr *ngIf="suppliers?.length<=0">
                        <td class="text-muted" colspan="3">List is empty</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer" *ngIf="suppliers?.length>0">
             <span><span translate>There are</span><b> {{ this.suppliersPagination?.totalCount }}</b>
                 {{this.suppliersPagination?.totalCount <= 1 ? 'supplier' : 'suppliers'|translate}}.</span>
                <ngb-pagination *ngIf="suppliersPagination?.pageCount>1"
                                size="sm"
                                [pageSize]="suppliersPagination.perPage"
                                [maxSize]="5"
                                [(page)]="suppliersPagination.currentPage"
                                [collectionSize]="suppliersPagination.totalCount"
                                (pageChange)="loadSuppliers()"
                                [boundaryLinks]="true"
                ></ngb-pagination>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="map"
            [fullScreen]="false"
    >
        <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <agm-map
            [latitude]='42.2894929'
            [longitude]='11.7101332'
            [zoom]="7">

        <agm-circle *ngFor="let marker of markers"
                    [latitude]="parseFloat(marker.lat)" [longitude]="parseFloat(marker.lng)"
                    [radius]="calculateRadius(marker.percentage)"
                    [fillColor]="'red'"
                    [fillOpacity]="0.5">
        </agm-circle>
    </agm-map>
</div>
