<div class="modal-header">
    <h4 class="modal-title clamp-title" translate>{{subject?.company_name}}</h4>
    <button (click)="dismissModal()"
            type="button"
            class="close"
            aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [ngBusy]="busy">
    <ul class="list-group list-group-flush">
        <li class="list-group-item px-0" *ngIf="subject?.type">
            <strong translate>Type</strong>
            <span class="float-right">{{subject?.type}}</span></li>
        <li class="list-group-item px-0" *ngIf="subject?.referral_person">
            <strong translate>Referral person</strong>
            <span class="float-right">{{subject?.referral_person}}</span></li>
        <li class="list-group-item px-0" *ngIf="subject?.email">
            <strong translate>Email</strong>
            <span class="float-right">{{subject?.email}}</span></li>
        <li class="list-group-item px-0" *ngIf="subject?.pec_email">
            <strong>Pec email</strong>
            <span class="float-right">{{subject?.pec_email}}</span></li>
        <li class="list-group-item px-0" *ngIf="subject?.phone_number">
            <strong translate>Phone number</strong>
            <span class="float-right">{{subject?.phone_number}}</span></li>
        <li class="list-group-item px-0" *ngIf="subject?.sdi_code">
            <strong translate>SDI code</strong>
            <span class="float-right">{{subject?.sdi_code}}</span></li>
        <li class="list-group-item px-0" *ngIf="subject?.vat">
            <strong translate>VAT</strong>
            <span class="float-right">{{subject?.vat}}</span></li>
        <li class="list-group-item px-0" *ngIf="subject?.iban">
            <strong translate>IBAN</strong>
            <span class="float-right">{{subject?.iban}}</span></li>
        <li class="list-group-item px-0" *ngIf="subject?.fiscal_code">
            <strong translate>Fiscal code</strong>
            <span class="float-right">{{subject?.fiscal_code}}</span></li>
        <li class="list-group-item px-0" *ngIf="subject?.address">
            <strong translate>Address</strong>
            <span class="float-right">{{subject?.address}}</span></li>
        <li class="list-group-item px-0" *ngIf="subject?.city">
            <strong translate>City</strong>
            <span class="float-right">{{subject?.city}}</span></li>
        <li class="list-group-item px-0" *ngIf="subject?.state">
            <strong translate>State</strong>
            <span class="float-right">{{subject?.state}}</span></li>
        <li class="list-group-item px-0" *ngIf="subject?.zip_code">
            <strong translate>Zip code</strong>
            <span class="float-right">{{subject?.zip_code}}</span></li>
        <li class="list-group-item px-0" *ngIf="subject?.note"><strong translate>Note</strong>
            <span class="d-block" [innerHTML]="subject?.note"></span></li>
    </ul>
</div>
