<div class="modal-header">
    <h4 class="modal-title clamp-title" translate>Add Subject</h4>
    <button (click)="dismissModal()"
            type="button"
            class="close"
            aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [ngBusy]="busy">
    <form [formGroup]="subjectForm" (ngSubmit)="saveSubject()">
        <div class="form-row">
            <div class="col-12 col-md-6 col-lg-8 form-group">
                <label for="companyName" translate>Company name</label>
                <input type="text" class="form-control" id="companyName" formControlName="company_name">
                <val-errors controlName="company_name">
                    <ng-template valError="required">
                        <span translate>Company name is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-md-6 col-lg-4 form-group">
                <label for="type" translate>Type</label>
                <ng-select formControlName="type" id="type">
                    <ng-option *ngFor="let type of subjectTypes" translate [value]="type.key">{{type.name}}</ng-option>
                </ng-select>
                <val-errors controlName="type">
                    <ng-template valError="required">
                        <span translate>Type is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-md-6 col-lg-4 form-group">
                <label for="referralPerson" translate>Referral person</label>
                <input type="text" class="form-control" id="referralPerson" formControlName="referral_person">
                <val-errors controlName="referral_person">
                    <ng-template valError="required">
                        <span translate>Referral person is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-md-6 col-lg-4 form-group">
                <label for="email" translate>Email</label>
                <input type="text" class="form-control" id="email" formControlName="email">
                <val-errors controlName="email">
                    <ng-template valError="required">
                        <span translate>Email is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-md-6 col-lg-4 form-group">
                <label for="pecEmail" translate>Pec email</label>
                <input type="text" class="form-control" id="pecEmail" formControlName="pec_email">
                <val-errors controlName="pec_email">
                    <ng-template valError="email">
                        <span translate>Email is not valid</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-md-6 col-lg-2 form-group">
                <label for="sdiCode" translate>SDI code</label>
                <input type="text" class="form-control" id="sdiCode" placeholder="M52L3423" formControlName="sdi_code">
                <val-errors controlName="sdi_code">
                    <ng-template valError="required">
                        <span translate>SDI code is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-md-6 col-lg-2 form-group">
                <label for="vat" translate>VAT</label>
                <input type="text" class="form-control" id="vat" placeholder="U12345678" formControlName="vat">
                <val-errors controlName="vat">
                    <ng-template valError="required">
                        <span translate>VAT is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-md-6 col-lg-2 form-group">
                <label for="iban" translate>IBAN</label>
                <input type="text" class="form-control" id="iban" formControlName="iban">
            </div>
            <div class="col-12 col-md-6 col-lg-2 form-group">
                <label for="fiscalCode" translate>Fiscal code</label>
                <input type="text" class="form-control" id="fiscalCode" placeholder="MLLSNT82P65Z404U"
                       formControlName="fiscal_code">
                <val-errors controlName="fiscal_code">
                    <ng-template valError="required">
                        <span translate>Fiscal code is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-md-6 col-lg-4 form-group">
                <label for="phone" translate>Phone number</label>
                <input type="text" class="form-control" id="phone"
                       formControlName="phone_number">
                <val-errors controlName="phone_number">
                    <ng-template valError="required">
                        <span translate>Phone number is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-md-6 col-lg-8 form-group">
                <label for="address" translate>Address</label>
                <input type="text" class="form-control" id="address" formControlName="address">
                <val-errors controlName="address">
                    <ng-template valError="required">
                        <span translate>Address is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-md-6 col-lg-4 form-group">
                <label for="city" translate>City</label>
                <input type="text" class="form-control" id="city" formControlName="city">
                <val-errors controlName="city">
                    <ng-template valError="required">
                        <span translate>City is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-md-6 col-lg-4 form-group">
                <label for="state" translate>State</label>
                <input type="text" class="form-control" id="state" formControlName="state">
                <val-errors controlName="state">
                    <ng-template valError="required">
                        <span translate>State is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-md-6 col-lg-4 form-group">
                <label for="zip" translate>Zip code</label>
                <input type="text" class="form-control" id="zip" formControlName="zip_code">
                <val-errors controlName="zip_code">
                    <ng-template valError="required">
                        <span translate>Zip code is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-md-6 col-lg-4 form-group">
                <label for="country" translate>Country</label>
                <input type="text" class="form-control" id="country" formControlName="country">
                <val-errors controlName="country">
                    <ng-template valError="required">
                        <span translate>Country is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 form-group">
                <label for="note" translate>Note</label>
                <editor apiKey="1nilu7q8vcp0ntuviwq5nhcskmj17c6awfhpbnr0hhv4smdw" [init]="defaultConfig" id="note" formControlName="note"></editor>
            </div>
            <div class="col-12">
                <button type="submit" class="btn btn-success btn-block" [disabled]="subjectForm.invalid">
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                </button>
            </div>
        </div>
    </form>
</div>

