import {Component, OnInit, ViewChild} from '@angular/core';
import {DealerInvoice, DealerInvoiceLine, DealerInvoiceLineDetail} from '@app/models/dealer-invoice';
import {Pagination} from '@app/models/pagination';
import {InvoiceService} from '@app/services/invoice.service';
import {ModalComponent} from '@app/widgets/modal/modal.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Dealer} from '@models/dealer';

@Component({
    selector: 'app-invoice-management',
    templateUrl: './invoice-management.component.html',
    styleUrls: ['./invoice-management.component.scss'],
})
export class InvoiceManagementComponent implements OnInit {
    @ViewChild('invoice') invoiceModal;

    pagination = new Pagination({page: 1, pageSize: 20});
    invoices: DealerInvoice[];
    currentInvoice: DealerInvoice;
    selectedInvoices = [];
    firstInvoiceSelected: DealerInvoice;

    totals = {
        ready_to_be_sent: null,
        ready_to_be_updated: null,
        discarded: null,
    };

    loadingSDI: {};

    sdi_statuses = [
        {value: 0, label: 'Da inviare'},
        {value: 4, label: 'Scartate'},
        {value: 5, label: 'Elaborazione'},
        {value: 6, label: 'Non inviate'},
        {value: 7, label: 'Inviate'},
        {value: 8, label: 'Consegnate'},
        {value: 9, label: 'Non Consegnate'},
    ];

    document_types = [
        {value: 0, label: 'Fatture'},
        {value: 1, label: 'Documenti a 0€'},
        {value: 2, label: 'Note credito'},
    ];

    filter;

    get invoiceTypeOptions() {
        return {
            title: 'Cambia il tipo della fattura',
            input: 'select',
            inputOptions: {
                0: 'Nuova',
                1: 'Vecchia',
            },
            inputPlaceholder: 'Seleziona il tipo di fattura',
            showCancelButton: true,
            confirmButtonText: 'Sì',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-default',
            },
        };
    }

    get invoiceRedoOptions() {
        return {
            title: 'Storna questa fattura e ricreala corretta',
            input: 'text',
            inputLabel: 'Seleziona la voce da specificare nella nota credito',
            inputPlaceholder: 'Es: Nota di credito per ritenuta non applicata...',
            showCancelButton: true,
            confirmButtonText: 'Vai frrr',
            cancelButtonText: 'Annulla',
            footer: 'Grazie Lucio per semplificarci sempre la vita <3',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-default',
            },
        };
    }

    get invoiceMergeOptions() {
        return {
            title: 'Unisci i documenti',
            text: 'Sei sicuro di voler unire i documenti?',
            showCancelButton: true,
            confirmButtonText: 'Sicuro al 100%',
            cancelButtonText: 'Annulla',
            footer: 'Grazie Lucio per semplificarci sempre la vita <3',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-default',
            },
        };
    }

    get invoiceStatusOptions() {
        return {
            title: 'Cambia lo stato della fattura',
            input: 'select',
            inputOptions: {
                '-1': 'Nascosta',
                0: 'Da approvare',
                1: 'Approvata',
                2: 'Rifiutata',
            },
            inputPlaceholder: 'Seleziona lo stato della fattura',
            showCancelButton: true,
            confirmButtonText: 'Sì',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-default',
            },
        };
    }

    constructor(
        private service: InvoiceService,
        private toastr: ToastrService,
        private modal: NgbModal,
        private spinner: NgxSpinnerService
    ) {
        this.resetFilters();
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.spinner.show('table-invoices');
        this.service
            .list({
                expand:'subject,send,update,resend,transactions_count,sendProblem,profile_status,vendors,periods',
                page: this.pagination.page,
                id: this.filter.id,
                number: this.filter.numero_fattura,
                subject: this.filter.fatturante,
                type: this.filter.is_old,
                status: this.filter.stato,
                merged: this.filter.merged,
                sdi_status: this.filter.stato_invio_sdi.join(','),
                document: this.filter.document.join(','),
                fi_status: this.filter.stato_freeinvoice,
            })
            .subscribe((data) => {
                this.invoices = new DealerInvoice().fromArray(data.rows);

                this.loadingSDI = this.invoices.reduce(function(map, obj) {
                    map[obj.id] = false;
                    return map;
                }, {});

                this.pagination = new Pagination(data.pagination);
                this.spinner.hide('table-invoices');
            });

        this.totals = {
            ready_to_be_sent: null,
            ready_to_be_updated: null,
            discarded: null,
        };

        this.service.overview().subscribe((data) => {
            this.totals = data;
        });
    }

    resetFilters() {
        this.filter = {
            id: '',
            numero_fattura: '',
            fatturante: '',
            merged: '0',
            is_old: '',
            stato: '',
            stato_invio_sdi: [0, 4, 5, 6, 7, 8, 9],
            stato_freeinvoice: '',
            id_vendor: '',
            document: [0, 1, 2],
        };
    }

    showInvoicesReadyToBeSent() {
        this.filter.is_old = '0';
        this.filter.stato = '1';
        this.filter.stato_freeinvoice = '0';
        this.filter.stato_invio_sdi = [0];
        this.filter.document = [0];
        this.refresh();
    }

    showInvoicesToBeUpdated() {
        this.filter.is_old = '0';
        this.filter.stato = '1';
        this.filter.stato_freeinvoice = '1';
        this.filter.stato_invio_sdi = [5, 7];
        this.filter.document = [0];
        this.refresh();
    }

    showInvoicesRejected() {
        this.filter.is_old = '0';
        this.filter.stato = '1';
        this.filter.stato_freeinvoice = '1';
        this.filter.stato_invio_sdi = [4];
        this.filter.document = [0];
        this.refresh();
    }

    showInvoice(invoice: DealerInvoice) {
        this.spinner.show('invoice-detail');

        if (this.currentInvoice && this.currentInvoice.id !== invoice.id) {
            this.currentInvoice = null;
        }

        this.modal.open(this.invoiceModal, {'size': 'xl'});

        this.service.get(invoice.id).subscribe((data) => {
            this.currentInvoice = new DealerInvoice(data);
            this.spinner.hide('invoice-detail');
        });
    }

    regenInvoice(invoice: DealerInvoice) {
        this.spinner.show('table-invoices');
        this.service.regenInvoice(invoice.id).subscribe((data) => {
            this.refresh();
        });
    }

    sendInvoice(invoice: DealerInvoice) {
        this.loadingSDI[invoice.id] = true;
        this.service.sendToSDI(invoice.id).subscribe((response: any) => {
            this.loadingSDI[invoice.id] = false;

            if (response.hasOwnProperty('data') && response.data.length === 1) {
                let result = response.data[0];

                if (result.invoiceStatus === 5) {
                    this.toastr.success(
                        `L'esito dell'invio è: ${result.invoiceStatusName} con numero ${result.invoiceBody.number} e data: ${result.invoiceBody.date}`,
                        'Fattura inviata'
                    );
                }

                this.refresh();
            }

            if (response.hasOwnProperty('errors') && response.errors.length === 1) {
                let result = response.errors[0];

                this.toastr.error(`${result.message}`, 'Errore');
            }
        });
    }

    updateInvoice(invoice: DealerInvoice) {
        this.loadingSDI[invoice.id] = true;
        this.service.updateSdiStatus(invoice.id).subscribe((response: any) => {
            this.loadingSDI[invoice.id] = false;

            if (response.hasOwnProperty('data')) {
                let result = response.data;

                if (result.invoiceStatus === 4) {
                    this.toastr.error(`La fattura è stata scartata`, 'Aggiornamento');
                }

                if (result.invoiceStatus === 8) {
                    this.toastr.success(
                        `La fattura è stata consegnata allo SDI correttamente.`,
                        'Ottimo'
                    );
                }

                if (result.invoiceStatus === 7 || result.invoiceStatus === 5) {
                    this.toastr.info(
                        `La fattura è ancora in attesa di essere consegnata.`,
                        'Attendi'
                    );
                }

                this.refresh();
            }
        });
    }

    changeInvoiceType($event: any, invoice: DealerInvoice) {
        if ($event.toString() === invoice.is_old.toString()) {
            this.toastr.info(
                `La fattura è già di tipo ${invoice.type}.`,
                'Attenzione'
            );
        } else {
            this.spinner.show('table-invoices');
            this.service
                .updateInvoice(invoice.id, {
                    is_old: parseInt($event),
                })
                .subscribe(
                    (data: any) => {
                        invoice.is_old = parseInt(data.is_old);
                        this.toastr.success(
                            `La fattura è cambiata in ${invoice.type}.`,
                            'Ottimo'
                        );
                        this.spinner.hide('table-invoices');
                    },
                    (err) => {
                        this.toastr.error(
                            `C'è stato un'errore col cambio di tipo.`,
                            'Errore'
                        );
                        this.spinner.hide('table-invoices');
                    }
                );
        }
    }

    changeInvoiceStatus($event: any, invoice: DealerInvoice) {
        if ($event.toString() === invoice.stato.toString()) {
            this.toastr.info(`La fattura è già ${invoice.status}.`, 'Attenzione');
        } else {
            this.spinner.show('table-invoices');
            this.service
                .updateInvoice(invoice.id, {
                    stato: parseInt($event),
                })
                .subscribe(
                    (data: any) => {
                        invoice.stato = parseInt(data.stato);
                        this.toastr.success(
                            `La fattura è cambiata in ${invoice.status}.`,
                            'Ottimo'
                        );
                        this.spinner.hide('table-invoices');
                    },
                    (err) => {
                        this.toastr.error(
                            `C'è stato un'errore col cambio di stato.`,
                            'Errore'
                        );
                        this.spinner.hide('table-invoices');
                    }
                );
        }
    }

    setSubject($event: any) {
        this.filter.fatturante = $event ? $event : '';
        this.refresh();
    }

    saveInvoiceLine(lineItem: DealerInvoiceLine) {
        this.spinner.show('invoice-detail');
        this.service.updateInvoiceLine(lineItem.id, lineItem)
            .subscribe((data) => {
                this.spinner.hide('invoice-detail');
                lineItem.editMode = false;
            });
    }

    saveInvoiceDetailLine(dettaglio: DealerInvoiceLineDetail) {
        this.spinner.show('invoice-detail');
        this.service.updateInvoiceDetailLine(dettaglio.id, dettaglio)
            .subscribe((data) => {
                dettaglio.editMode = false;
                this.showInvoice(this.currentInvoice);
            });
    }

    redoInvoiceType($event: any, invoice: DealerInvoice) {
        this.spinner.show('table-invoices');
        this.service.redoInvoice(invoice.id, $event)
            .subscribe((data) => {
                this.refresh();
            });
    }

    selectInvoice(event: any, invoice: DealerInvoice) {
        if (invoice?.id) {
            if (event.currentTarget.checked) {
                invoice.checked = true;

                if (this.selectedInvoices.length === 0) {
                    this.firstInvoiceSelected = invoice;
                }

                this.selectedInvoices.push(invoice.id);
            } else {
                const index = this.selectedInvoices.findIndex(data => data === invoice.id);
                if (index > -1) {
                    invoice.checked = false;
                    this.selectedInvoices.splice(index, 1);
                }

                if(this.selectedInvoices.length === 0) {
                    this.firstInvoiceSelected = null;
                }
            }
        }
    }


    mergeDocuments(selectedInvoices: any[]) {
        this.spinner.show('table-invoices');
        this.service.mergeDocuments(selectedInvoices)
            .subscribe((data) => {
                this.firstInvoiceSelected = null;
                this.selectedInvoices = [];
                this.refresh();
            });
    }
}
