import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';

export class ShopBrand extends BaseModel {
    id: number;
    name: string;
    image_name: string;
    image_path: string;
    is_active: any;
    description: string;
    file: File;

    fromArray(result: []) {
        const array: ShopBrand[] = [];
        for (const item of result) {
            array.push(new ShopBrand(item));
        }
        return array;
    }

    get postPayloadBrand() {
        const payload = new FormData();
        this.addToPayload(payload, this.name, 'name');
        this.addToPayload(payload, this.image_name, 'image_name');
        this.addToPayload(payload, this.is_active, 'is_active');
        this.addToPayload(payload, this.description, 'description');
        this.addToPayload(payload, this.file, 'file');
        return payload;
    }
}

export class ShopBrandPagination extends BaseModel {
    data: ShopBrand[];
    _meta: MetaPagination;
    _links: Array<any>;
}
