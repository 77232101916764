<div class="card bg-gradient-light">
    <form (ngSubmit)="loadSubject()">
        <div class="card-header">
            <h1 class="card-title" translate>Filters</h1>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="form-group col-12 col-md-4 col-lg-3">
                    <label for="name" translate>Company name</label>
                    <input
                            type="text" id="name" name="company_name" [(ngModel)]="filter.company_name"
                            class="form-control form-control-sm"
                    />
                </div>
                <div class="form-group col-12 col-md-4 col-lg-3">
                    <label for="type" translate>Type</label>
                    <ng-select [(ngModel)]="filter.type" id="type" name="type" class="form-control-sm">
                        <ng-option *ngFor="let type of subjectTypes" translate
                                   [value]="type.key">{{type.name}}</ng-option>
                    </ng-select>
                </div>
                <div class="form-group col-12 col-md-4 col-lg-3">
                    <label for="state" translate>Province</label>
                    <input
                            type="text" id="state" name="state" [(ngModel)]="filter.state"
                            class="form-control form-control-sm"
                    />
                </div>
                <div class="form-group col-12 col-md-4 col-lg-3">
                    <label for="city" translate>City</label>
                    <input
                            type="text" id="city" name="city" [(ngModel)]="filter.city"
                            class="form-control form-control-sm"
                    />
                </div>
                <div class="form-group col-12 col-md-4 col-lg-3">
                    <label for="phoneNumber" translate>Phone number</label>
                    <input
                            type="text" id="phoneNumber" name="phone_number" [(ngModel)]="filter.phone_number"
                            class="form-control form-control-sm"
                    />
                </div>
                <div class="form-group col-12 col-md-4 col-lg-3">
                    <label for="sdi" translate>SDI</label>
                    <input
                            type="text" id="sdi" name="sdi_code" [(ngModel)]="filter.sdi_code"
                            class="form-control form-control-sm"
                    />
                </div>
                <div class="form-group col-12 col-md-4 col-lg-3">
                    <label for="vat" translate>Vat</label>
                    <input
                            type="text" id="vat" name="vat" [(ngModel)]="filter.vat"
                            class="form-control form-control-sm"
                    />
                </div>
                <div class="form-group col-12 col-md-4 col-lg-3">
                    <label for="fiscalCode" translate>Fiscal code</label>
                    <input
                            type="text" id="fiscalCode" name="fiscal_code" [(ngModel)]="filter.fiscal_code"
                            class="form-control form-control-sm"
                    />
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button class="btn btn-dark btn-sm mr-2" type="button" (click)="resetFilter()" translate>
                Reset
            </button>
            <button
                    class="btn btn-primary btn-sm"
                    type="submit" translate
            >
                Filter
            </button>
        </div>
    </form>
</div>

<div class="card">
    <div class="card-header">
        <div class="row">
            <div class="col-lg-10 col-12">
                <h4 class="mb-0" translate>Subjects</h4>
            </div>
            <div class="col-lg-2 col-12">
                <button class="btn btn-success btn-sm float-right" type="button" translate
                        (click)="showSubjectModal()">
                    Add Subject
                </button>
            </div>
        </div>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th translate>Company name</th>
                <th translate>Type</th>
                <th translate>SDI</th>
                <th translate>Vat</th>
                <th translate>Fiscal code</th>
                <th translate>State</th>
                <th translate>City</th>
                <th translate>Address</th>
                <th translate>Email</th>
                <th class="text-right"></th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="table-subjects"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let subject of subjects">
                <td>{{subject.company_name}}</td>
                <td translate>{{findType(subject.type)}}</td>
                <td>{{subject.sdi_code}}</td>
                <td>{{subject.vat}}</td>
                <td>{{subject.fiscal_code}}</td>
                <td>{{subject.state}}</td>
                <td>{{subject.city}}</td>
                <td>{{subject.address}}</td>
                <td>{{subject.email}}</td>
                <td class="text-right">
                    <button class="btn btn-link" (click)="showSubjectDetailModal(subject)">
                        <i class=" fa fa-eye text-info"></i>
                    </button>
                    <button class="btn btn-link" (click)="showSubjectModal(subject)">
                        <i class=" fa fa-edit text-muted"></i>
                    </button>
                    <button class="btn btn-link" [swal]="deleteSubjectOptions"
                            (confirm)="deleteSubject(subject.id)">
                        <i class="fa fa-trash text-danger"></i>
                    </button>
                </td>
            </tr>
            <tr *ngIf="subjects?.length<=0">
                <td class="text-muted" colspan="12" translate>List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="subjects?.length>0">
    <span><span translate>There are</span><b> {{ this.subjectsPagination.totalCount }}</b>
        {{this.subjectsPagination.totalCount <= 1 ? 'subject' : 'subjects'|translate}}.</span>
        <ngb-pagination *ngIf="subjectsPagination.pageCount>1"
                        size="sm"
                        [pageSize]="subjectsPagination.perPage"
                        [maxSize]="10"
                        [(page)]="subjectsPagination.currentPage"
                        [collectionSize]="subjectsPagination.totalCount"
                        (pageChange)="loadSubject()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>
<ng-template #subjectFormTemplate let-modal>
    <app-subject-modal [subjects]="subjects" [selectedSubject]="selectedSubject" (refresh)="loadSubject()"></app-subject-modal>
</ng-template>
<ng-template #subjectDetailTemplate let-modal>
    <app-subject-view [subject]="selectedSubject"></app-subject-view>
</ng-template>
