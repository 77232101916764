import {Injectable} from '@angular/core';
import {RequestService} from '@services/request.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
    EMailCampaignPagination, EMailClickDetailPagination,
    EMailClickMember, EMailClickMemberPagination,
    EMailOpenMember, EMailOpenMemberPagination,
    EMailRecipientPagination
} from '@models/e-mail-campaign';


@Injectable({
    providedIn: 'root'
})
export class EMailCampaignService {

    constructor(public request: RequestService) {
    }

    public getCampaigns(params?): Observable<EMailCampaignPagination> {
        return this.request.get('mail-chimp/campaign', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getRecipients(params?): Observable<EMailRecipientPagination> {
        return this.request.get('mail-chimp/recipients', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getOpensReport(params?): Observable<EMailOpenMemberPagination> {
        return this.request.get('mail-chimp/open-details', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getClicksReport(params?): Observable<EMailClickDetailPagination> {
        return this.request.get('mail-chimp/click-details', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getClickMembers(params?): Observable<EMailClickMemberPagination> {
        return this.request.get('mail-chimp/click-members', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getCampaignContent(params?) {
        return this.request.get('mail-chimp/campaign-content', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
