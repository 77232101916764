<div class="card bg-gradient-1">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                 width="160px"
                 src="assets/img/flats/placeholder.png">
            <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>GPS tracking</h1>
                <hr>
                <form (ngSubmit)="loadData()">
                    <div class="form-row align-items-end">
                        <div class="col-lg-6 col-12"><label for="date" translate>Agent</label>
                            <app-agent-select (onChange)="filter.agent_id = $event"></app-agent-select>
                        </div>
                        <div class="col-lg-6 col-12"><label for="shop" translate>Shop</label>
                            <input class="form-control" type="text" [(ngModel)]="filter.shop"
                                   id="shop" name="shop"
                            />
                        </div>
                        <div class="col-lg-4 col-12"><label for="date" translate>Date</label>
                            <input id="date"
                                   type="text" daterangepicker [options]="rangeOptions" [(ngModel)]="filter.date"
                                   name="date"
                                   class="form-control form-control-sm" (selected)="selectedDate($event.start)"
                            />
                        </div>
                        <div class="col-lg-2 col-12"><label for="region" translate>Region</label>
                            <app-state-select id="region"
                                              (onChange)="changeRegion($event)"
                            ></app-state-select>
                        </div>
                        <div class="col-lg-2 col-12"><label for="province" translate>Province</label>
                            <app-province-select id="province"
                                                 [state]="this.filter.region_id"
                                                 [onDemand]="true"
                                                 (onChange)="changeProvince($event)"
                            ></app-province-select>
                        </div>
                        <div class="col-lg-2 col-12"><label for="city" translate>City</label>
                            <app-city-select id="city"
                                             [province]="this.filter.province_id"
                                             [onDemand]="true"
                                             (onChange)="this.filter.city_id = $event"
                            ></app-city-select>
                        </div>
                        <div class="col-lg-2 col-12 mt-2 mt-lg-auto">
                            <button class="btn btn-info btn-block" type="submit" translate
                                    [disabled]="filter.region_id && !filter.province_id">Filter
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="dashboard"
            [fullScreen]="false"
    >
        <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <div class="card-body">
        <div class="row d-flex">
            <div class="col-lg-3 col-12 dashboard-card">
                <div class="d-flex align-items-center">
                    <img alt="" class="d-inline-flex" width="80px"
                         src="assets/img/flats/deal.png">
                    <div class="d-block w-100 pl-3">
                        <p class="lead" translate>Visits</p>
                        <h1 class="display-6">{{report ? report.total_visits : 0}}</h1>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 dashboard-card">
                <div class="d-flex align-items-center">
                    <img class="d-inline-flex" width="80px"
                         src="assets/img/flats/destination.png">
                    <div class="d-block w-100 pl-3">
                        <p class="lead" translate>Stops</p>
                        <h1 class="display-6">{{report ? report.total_stops : 0}}</h1>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 dashboard-card">
                <div class="d-flex align-items-center"><img
                        class="d-inline-flex" width="80px"
                        src="assets/img/flats/time.png">
                    <div class="d-block w-100 pl-3">
                        <p class="lead" translate>Average visits duration</p>
                        <h1 class="display-6">{{report ? report.average_visits_duration : null}}</h1>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-12 dashboard-card">
                <div class="d-flex align-items-center"><img
                        class="d-inline-flex" width="80px"
                        src="assets/img/flats/time.png">
                    <div class="d-block w-100 pl-3">
                        <p class="lead" translate>Average stops duration</p>
                        <h1 class="display-6">{{report ? report.average_stops_duration : null}}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 col-lg-6">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-lg-9 col-12">
                        <h4 class="mb-0" translate>Map</h4>
                    </div>
                    <div class="col-lg-3 col-12 d-flex justify-content-end">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                                   value="option1" (change)="showVisits=true" checked>
                            <label class="form-check-label" for="inlineRadio1">Visits</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                                   value="option2" (change)="showVisits=false">
                            <label class="form-check-label" for="inlineRadio2">Dealers</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <ngx-spinner
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="default"
                        color="#fff"
                        type="square-jelly-box"
                        name="map"
                        [fullScreen]="false"
                >
                    <p style="color: white">Caricamento...</p>
                </ngx-spinner>
                <agm-map #gm
                         [latitude]='42.2894929'
                         [longitude]='11.7101332'
                         [zoom]="6"
                         [showDefaultInfoWindow]="false"
                         [clickableIcons]="false"
                         [styles]="defaultStyles">
                         <span *ngIf="showVisits">
                    <agm-marker
                            *ngFor="let key of keys"
                            [latitude]="markers[key].lat"
                            [longitude]="markers[key].lng"
                            [openInfoWindow]="true"
                            [iconUrl]="mapPinOptions"
                            (markerClick)="gm.lastOpen?.close(); gm.lastOpen = infoWindow">
                        >
                        <agm-info-window #infoWindow>
                            <p class="w-100">
                                <span class="pr-2" translate>Shop</span> <span
                                    class="float-right"><strong>{{markers[key].shop}}</strong></span></p>
                            <p class="w-100">
                                <span class="pr-2" translate>This day</span> <span
                                    class="float-right"><strong>{{markers[key].daily_count}}</strong></span></p>
                            <p class="w-100"><span class="pr-2" translate>This week</span> <span
                                    class="float-right"><strong>{{markers[key].weekly_count}}</strong></span></p>
                            <p class="w-100"><span class="pr-2" translate>This month</span> <span
                                    class="float-right"><strong>{{markers[key].monthly_count}}</strong></span></p>
                        </agm-info-window>
                    </agm-marker>
                         </span>
                    <span *ngIf="!showVisits">
                              <agm-marker *ngFor="let shop of shops" [latitude]="shop.lat" [iconUrl]="mapPinOptions"
                                          [longitude]="shop.lng"
                                          (markerClick)="gm.lastOpen?.close(); gm.lastOpen = shopWindow">
                                            <agm-info-window #shopWindow>
                            <p class="w-100">
                                <span class="pr-2" translate>Shop</span> <span
                                    class="float-right"><strong>{{shop.company_name}}</strong></span></p>
                            <p class="w-100">
                                <span class="pr-2" translate>Owner</span> <span
                                    class="float-right"><strong>{{shop.company_owner}}</strong></span></p>
                            <p class="w-100"><span class="pr-2" translate>Address</span> <span
                                    class="float-right"><strong>{{shop.address}}</strong></span></p>
                        </agm-info-window>
                              </agm-marker>
                    </span>
                    <agm-direction *ngIf="direction"
                                   [renderOptions]="renderOptions"
                                   [markerOptions]="markerOptions"
                                   [origin]="direction?.origin"
                                   [destination]="direction?.destination"
                                   [waypoints]="direction.waypoints">
                    </agm-direction>
                </agm-map>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6">
        <div class="card">
            <div class="card-header">
                <h4 class="mb-0" translate>Visits</h4>
            </div>
            <div class="card-body  table-responsive p-0">
                <table class="table table-sm mb-0 table-striped">
                    <thead>
                    <tr>
                        <th scope="col" *ngIf="!isSelectedAgent" translate>Agent</th>
                        <th scope="col" translate>Shop</th>
                        <th scope="col" translate>City</th>
                        <th scope="col" translate>Duration</th>
                        <th scope="col" translate>Last visit</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <ngx-spinner
                            bdColor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="square-jelly-box"
                            name="table-visits"
                            [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <tr *ngFor="let agentVisit of agentVisits">
                        <td *ngIf="!isSelectedAgent">{{agentVisit.agent_name}}</td>
                        <td>{{agentVisit.shop}}</td>
                        <td>{{agentVisit.city_name}}</td>
                        <td>{{agentVisit.duration}}</td>
                        <td>{{agentVisit.last_visit_date|amDateFormat:localFormat}}</td>
                        <td class="text-right">
                            <button class="btn btn-link" (click)="showFileModal(agentVisit)">
                                <i class="fa fa-file text-success"></i>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="agentVisits?.length<=0">
                        <td class="text-muted" colspan="5" translate>List is empty</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer" *ngIf="agentVisits?.length>0">
        <span><span translate>There are</span><b> {{ this.agentVisitsPagination.totalCount }}</b>
            {{this.agentVisitsPagination.totalCount <= 1 ? 'visit' : 'visits'|translate}}.</span>
                <ngb-pagination *ngIf="agentVisitsPagination.pageCount>1"
                                size="sm"
                                [pageSize]="agentVisitsPagination.perPage"
                                [maxSize]="5"
                                [(page)]="agentVisitsPagination.currentPage"
                                [collectionSize]="agentVisitsPagination.totalCount"
                                (pageChange)="loadAgentVisits()"
                                [boundaryLinks]="true"
                ></ngb-pagination>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="mb-0" translate>Stops</h4>
            </div>
            <div class="card-body  table-responsive p-0">
                <table class="table table-sm mb-0 table-striped">
                    <thead>
                    <tr>
                        <th scope="col" *ngIf="!isSelectedAgent" translate>Agent</th>
                        <th scope="col" translate>Start stop</th>
                        <th scope="col" translate>End stop</th>
                        <th scope="col" translate>Duration</th>
                        <th scope="col" class="text-center" translate>Work time</th>
                        <th scope="col" translate>Period</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ngx-spinner
                            bdColor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="square-jelly-box"
                            name="table-stops"
                            [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <tr *ngFor="let agentStop of agentStops">
                        <td *ngIf="!isSelectedAgent">{{agentStop.car.owner}}</td>
                        <td>{{agentStop.start_datetime|amDateFormat:localFormat}}</td>
                        <td>{{agentStop.end_datetime|amDateFormat:localFormat}}</td>
                        <td>{{agentStop.duration}}</td>
                        <td class="text-center  w-20">
                            <i class="text-success fas fa-check" *ngIf="agentStop.is_work_time==1"></i>
                            <i class="text-danger fas fa-times" *ngIf="agentStop.is_work_time===0"></i>
                        </td>
                        <td>
                            <span translate *ngIf="agentStop.period==lunchTime">Lunch time</span>
                            <span translate *ngIf="agentStop.period==nightTime">Night time</span>
                        </td>
                    </tr>
                    <tr *ngIf="agentStops?.length<=0">
                        <td class="text-muted" colspan="6" translate>List is empty</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer" *ngIf="agentStops?.length>0">
        <span><span translate>There are</span><b> {{ this.agentStopsPagination.totalCount }}</b>
            {{this.agentStopsPagination.totalCount <= 1 ? 'stop' : 'stops'|translate}}.</span>
                <ngb-pagination *ngIf="agentStopsPagination.pageCount>1"
                                size="sm"
                                [pageSize]="agentStopsPagination.perPage"
                                [maxSize]="5"
                                [(page)]="agentStopsPagination.currentPage"
                                [collectionSize]="agentStopsPagination.totalCount"
                                (pageChange)="loadAgentStops()"
                                [boundaryLinks]="true"
                ></ngb-pagination>
            </div>
        </div>
    </div>
</div>

<ng-template #fileTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>{{selectedVisit?.agent_name}}</h4>
        <button (click)="modalService.dismissAll()"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ul class="list-group list-group-flush">
            <li class="list-group-item px-0" *ngFor="let document of selectedVisit?.documents">
                <a [href]="baseAwsUrl+'/'+document?.file_path"
                   target="_blank">{{document?.file_name}}</a></li>
            <li class="list-group-item px-0" *ngIf="selectedVisit?.documents?.length<=0" translate>No files added</li>
        </ul>
    </div>
</ng-template>
