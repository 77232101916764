import { Injectable } from '@angular/core';
import {RequestService} from '@services/request.service';

@Injectable({
  providedIn: 'root'
})
export class WhatsappService {
  ENDPOINT = 'whatsapp';

  constructor(public request: RequestService) {
  }

  getChatsWaitingReply() {
    return this.request.get(`${this.ENDPOINT}/waiting-reply`);
  }

  getOverview() {
    return this.request.get(`${this.ENDPOINT}/overview`);
  }

  getAgentRanking() {
    return this.request.get(`${this.ENDPOINT}/agent-ranking`);
  }

  getMessages(id) {
    return this.request.get(`${this.ENDPOINT}/chat-messages`, {
      id: id
    });
  }
}
