import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { State } from '@app/models/geo';
import { StateService } from '@app/services/state.service';

@Component({
  selector: 'app-state-select',
  templateUrl: './state-select.component.html',
  styleUrls: ['./state-select.component.scss']
})
export class StateSelectComponent implements OnInit {
  @Input()
  small = false;

  state: State;

  data: State[] = [];

  @Output() onChange = new EventEmitter();

  constructor(
    private service: StateService
  ) {}

  ngOnInit(): void {
    this.service.list({fields: 'id,regione'}).subscribe((data) => {
      this.data = new State().fromArray(data);
    });
  }

}
