import {BaseModel} from '@models/base-model';
import {Prize} from '@models/prize';

export class LoyaltyCard extends BaseModel {
    id: number;
    start_date: string;
    end_date: string;
    points: Points;
}

export class Points extends BaseModel {
    total: number;
}

export class Timeline extends BaseModel {
    date: string;
    sales: TimelineSale[];
    used_prizes: Prize[];
}

export class TimelineSale extends BaseModel {
    offer: string;
    points: number;
    logo: string;
}
