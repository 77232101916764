import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {DashboardService} from "@services/dashboard.service";
import { Helpers } from '@app/helpers/helpers';

@Component({
  selector: 'app-master-economics-agenti',
  templateUrl: './master-economics-agenti.component.html',
  styleUrls: ['./master-economics-agenti.component.css']
})
export class MasterEconomicsAgentiComponent implements OnInit {

    data = null;
    busy: Subscription;
    
    periodi = Helpers.getPeriods();

    _periodo: string;
    urlSubheader: string;

    set periodo(value: string) {
        this._periodo = value;
        this.updateUrls();
    }

    get periodo() {
        return this._periodo;
    }

    constructor(
        public route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private dashboardService: DashboardService
    ) {
        this.periodo = this.route.snapshot.paramMap.get('periodo');
    }

    ngOnInit() {
    }

    updateUrls() {
        this.spinner.show();

        this.urlSubheader = `/economics-costi-negozi-header?periodo=${this.periodo}`;

        this.dashboardService.get(`/economics-costi-agenti?periodo=${this.periodo}`)
            .subscribe(data => {
                this.data = data.result;
                this.spinner.hide();
            });
    }

}
