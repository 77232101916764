<div class="row" [ngBusy]="busy">
    <div class="col-12">
        <div class="card bg-gradient-dark">
            <div class="card-body row align-items-center">
                <div class="col-2 col-xs-12 text-center">
                    <img src="/assets/img/svgs/undraw_file_searching_duff.svg" class="header-logo"/>
                </div>
                <div class="col-8 col-xs-12">
                    <h1 class="header mb-0">Comparatore di offerte</h1>
                    <p class="subtitle mb-4 text-white">Tramite il comparatore ti guidiamo alla ricerca dell'offerta
                        migliore per
                        il tuo cliente.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="card bg-gradient-light">
            <div class="card-body">
                <ngb-tabset class="nav-wizard" #tabs>
                    <ngb-tab id="servizio">
                        <ng-template ngbTabTitle><i class="fa fa-project-diagram"></i> Servizio</ng-template>
                        <ng-template ngbTabContent>
                            <div class="row">
                                <div *ngFor="let servizio of servizi" [class]="servizio.class"
                                     (click)="setServizio(servizio)">
                                    <div href="" class="btn bg-gradient-dark w-100 elevation-1">
                                        <div class="icon-white">
                                            <img [src]="servizio.image" class="mt-3 p-1 img-circle bg-gradient-white"
                                                 height="100"/>
                                        </div>

                                        <h4 class="mt-3">{{ servizio.title }}</h4>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab id="caratteristica" [disabled]="!servizioSelezionato">
                        <ng-template ngbTabTitle><i class="fa fa-bullseye"></i> Caratteristica</ng-template>
                        <ng-template ngbTabContent>
                            <div class="row" *ngIf="servizioSelezionato">
                                <div *ngFor="let caratteristica of servizioSelezionato.caratteristiche"
                                     class="col-3 mb-3" (click)="setCaratteristica(caratteristica)">
                                    <div href="" class="btn bg-gradient-dark w-100 elevation-1">
                                        <div class="icon-white">
                                            <img [src]="caratteristica.immagine"
                                                 class="mt-3 p-1 img-circle bg-gradient-white" height="100"/>
                                        </div>

                                        <h4 class="mt-3">{{ caratteristica.titolo }}</h4>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab id="offerta" [disabled]="!caratteristicaSelezionata">
                        <ng-template ngbTabTitle><i class="fa fa-list-ol"></i> Offerte</ng-template>
                        <ng-template ngbTabContent>
                            <div class="row">
                                <div class="col-3">
                                    <div class="card">
                                        <div class="card-header">
                                            <h1 class="display-7 mb-0">Filtro di ricerca</h1>
                                        </div>
                                        <div class="card-body">
                                            <form>
                                                <div class="form-group">
                                                    <label class="lead">Filtro per brand</label>
                                                    <div class="form-check"
                                                         *ngFor="let brand of caratteristicaSelezionata.offerte | pluck: 'operatore' | uniq">
                                                        <input class="form-check-input" type="radio" name="filtroBrand" [(ngModel)]="filtroBrand" [value]="brand" (change)="setFiltri()">
                                                        <label class="form-check-label">
                                                            {{ brand }}
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <label class="lead">Filtro per segmento</label>
                                                    <div class="form-check"
                                                         *ngFor="let segmento of caratteristicaSelezionata.offerte | pluck: 'famiglia' | uniq">
                                                        <input class="form-check-input" type="radio" name="filtroSegmento" [(ngModel)]="filtroSegmento" [value]="segmento" (change)="setFiltri()">
                                                        <label class="form-check-label">
                                                            {{ segmento }}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label class="lead">Filtro per tipo</label>
                                                    <div class="form-check"
                                                         *ngFor="let tipo of caratteristicaSelezionata.offerte | pluck: 'sottofamiglia' | uniq">
                                                        <input class="form-check-input" type="radio" name="filtroTipo" [(ngModel)]="filtroTipo" [value]="tipo" (change)="setFiltri()">
                                                        <label class="form-check-label">
                                                            {{ tipo }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="card-footer">
                                            <button class="btn btn-success btn-block" (click)="resetFiltri()">Reset
                                                Filtri
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-9">
                                    <div class="card"
                                         *ngFor="let offerta of getOfferte()"
                                    >
                                        <div class="card-body">
                                            <div class="row align-items-center">
                                                <div class="col-3 text-center">
                                                    <img [src]="offerta.logo" height="60">
                                                    <h1 class="display-7 mt-2">{{offerta.nome}}</h1>
                                                </div>
                                                <div class="col-7">
                                                    <div class="row h-100 align-items-center">
                                                        <div class="col-6">
                                                            <div class="card">
                                                                <div class="card-body p-0">
                                                                    <table class="table">
                                                                        <tr>
                                                                            <th>Operatore</th>
                                                                            <td>{{ offerta.operatore }}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Servizio</th>
                                                                            <td>{{ offerta.pista }}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Segmento Cliente</th>
                                                                            <td>{{ offerta.famiglia }}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Tipo Attivazione</th>
                                                                            <td>{{ offerta.sottofamiglia }}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="card">
                                                                <div class="card-body p-0">
                                                                    <table class="table">
                                                                        <tr>
                                                                            <th>Prezzo/mese</th>
                                                                            <td class="price">{{this.offerta.prezzo | currency: '€ '}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>La tua provvigione</th>
                                                                            <td class="price"> -</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-2 text-center">
                                                    <button class="elevation-1 btn btn-info btn-lg" type="submit"
                                                            (click)="attiva(offerta)">
                                                        ATTIVA <i class="ml-2 fa fa-arrow-alt-circle-right"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>
            <div class="card-footer d-flex">
                <button class="btn btn-secondary" (click)="goBack()">Indietro</button>
            </div>
        </div>
    </div>
</div>

