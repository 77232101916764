<ng-template #content let-modal class="bg-gradient-light">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Nuovo Ticket</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busy">
        <form [formGroup]="ticketForm" *ngIf="this.categorie">
            <div class="row">
                <div class="col-12">
                    <label class="lead">Categoria</label>
                    <ng-select [items]="this.categorie"
                               bindLabel="nome"
                               bindValue="id"
                               groupBy="categoriaPadre"
                               formControlName="id_categoria">
                    </ng-select>
                    <val-errors controlName="id_categoria"></val-errors>
                </div>
                <div class="col-12">
                    <label class="lead">{{problemLabel}}</label>
                    <textarea formControlName="testo" class="form-control w-100" rows="6"></textarea>
                    <val-errors controlName="description"></val-errors>
                </div>
                <div class="col-12 mt-3">
                    <button class="btn btn-success btn-block" [disabled]="!this.ticketForm.valid"
                            (click)="createTicket()"><i class="fa fa-check-circle mr-2"></i>Inserisci
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
