import {Injectable} from '@angular/core';
import {RequestService} from '@services/request.service';
import {AlertService} from '@services/alert.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {StoreCategory, StoreCategoryPagination} from '@models/store/store-category';
import {StoreOperator, StoreOperatorPagination} from '@models/store/store-operator';
import {
    ProductSerialTypePagination,
    StoreBrand,
    StoreBrandPagination, StorePaymentType,
    StoreProduct,
    StoreProductPagination, StoreProductTemplate
} from '@models/store/store-product';

const shop = 'shop';
const categories = 'categories';
const operators = 'operators';
const products = 'products';
const brands = 'brands';

@Injectable({
    providedIn: 'root'
})
export class StoreService {

    constructor(private request: RequestService, private alertService: AlertService) {
    }

    public static productTypes = [
        {
            key: '0',
            name: 'Standard'
        },
        {
            key: '1',
            name: 'Marketing'
        },
        {
            key: '2',
            name: 'Split payment'
        },
    ];
    public static operators = [
        {
            key: '0',
            name: 'Tiscali'
        },
        {
            key: '1',
            name: 'Kena'
        },
        {
            key: '2',
            name: 'Sky'
        },
        {
            key: '3',
            name: 'Eni'
        },
        {
            key: '4',
            name: 'Tim'
        },
        {
            key: '5',
            name: 'Stands'
        },
        {
            key: '6',
            name: 'Pacchetti sms attivazione'
        },
        {
            key: '7',
            name: 'Pacchetti sms'
        },
        {
            key: '8',
            name: 'Flyeralarm'
        },
    ];
    public static statementTypes = [
        {
            key: '0',
            name: 'Prodotto Standard'
        },
        {
            key: '1',
            name: 'Ricariche'
        },
        {
            key: '2',
            name: 'Sim'
        }
    ];
    public static paymentTypes = [
        {
            key: 'bank-transfer',
            name: 'Bank transfer',
            value: 0,
            disabled: true,
            icon: 'fa fa-university'
        },
        {
            key: 'credit-card',
            name: 'Credit card',
            value: 2,
            disabled: true,
            icon: 'fa fa-credit-card'
        },
        {
            key: 'compensation',
            name: 'Compensation',
            value: 3,
            disabled: true,
            icon: 'fa fa-euro-sign'
        },
        {
            key: 'cash-on-delivery',
            name: 'Cash on delivery',
            value: 1,
            disabled: true,
            icon: 'fa fa-truck'
        },
        {
            key: 'check-on-delivery',
            name: 'Check on delivery',
            value: 5,
            disabled: true,
            icon: 'fa fa-truck'
        }
    ];

    public getCategories(params): Observable<StoreCategoryPagination> {
        return this.request.get(shop + '/' + categories, params).pipe(
            map((response: any) => {
                return new StoreCategoryPagination(response);
            })
        );
    }

    public postCategory(body: StoreCategory): Observable<StoreCategory> {
        return this.request.post(shop + '/' + categories, body.postPayloadCategory).pipe(
            map((response: any) => {
                return new StoreCategory(response);
            })
        );
    }

    public putCategory(id: number, body: StoreCategory): Observable<StoreCategory> {
        return this.request.post(shop + '/' + categories + '/' + id, body.postPayloadCategory).pipe(
            map((response: any) => {
                return new StoreCategory(response);
            })
        );
    }

    public getOperators(params): Observable<StoreOperatorPagination> {
        return this.request.get(shop + '/' + operators, params).pipe(
            map((response: any) => {
                return new StoreOperatorPagination(response);
            })
        );
    }

    public postOperator(body: StoreOperator): Observable<StoreOperator> {
        return this.request.post(shop + '/' + operators, body).pipe(
            map((response: any) => {
                return new StoreOperator(response);
            })
        );
    }

    public putOperator(id: number, body: StoreOperator): Observable<StoreOperator> {
        return this.request.put(shop + '/' + operators + '/' + id, body).pipe(
            map((response: any) => {
                return new StoreOperator(response);
            })
        );
    }

    public getProducts(params): Observable<StoreProductPagination> {
        return this.request.get(shop + '/' + products, params).pipe(
            map((response: any) => {
                return new StoreProductPagination(response);
            })
        );
    }

    public postProducts(body: StoreProduct): Observable<StoreProduct> {
        return this.request.post(shop + '/' + products, body.postPayloadProduct).pipe(
            map((response: any) => {
                return new StoreProduct(response);
            })
        );
    }

    public putProducts(id: number, body: StoreProduct): Observable<StoreProduct> {
        return this.request.post(shop + '/' + products + '/' + id, body.postPayloadProduct).pipe(
            map((response: any) => {
                return new StoreProduct(response);
            })
        );
    }

    public postProductTemplate(body: StoreProductTemplate): Observable<StoreProductTemplate> {
        return this.request.post(shop + '/product-template', body.postPayloadTemplate).pipe(
            map((response: any) => {
                return new StoreProductTemplate(response);
            })
        );
    }

    public putProductTemplate(id, body: StoreProductTemplate): Observable<StoreProductTemplate> {
        return this.request.post(shop + '/product-template/' + id, body.postPayloadTemplate).pipe(
            map((response: any) => {
                return new StoreProductTemplate(response);
            })
        );
    }

    public getSerialTypes(params?): Observable<ProductSerialTypePagination> {
        return this.request.get(shop + '/' + products + '/serial-type', params).pipe(
            map((response: any) => {
                return new ProductSerialTypePagination(response);
            })
        );
    }

    public getBrands(params?): Observable<StoreBrandPagination> {
        return this.request.get(shop + '/' + brands, params).pipe(
            map((response: any) => {
                return new StoreBrandPagination(response);
            })
        );
    }

    public getAvailablePayments(params?): Observable<StorePaymentType[]> {
        return this.request.get(shop + '/' + products + '/available-payments', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public postBrand(body: StoreBrand): Observable<StoreBrand> {
        return this.request.post(shop + '/' + brands, body).pipe(
            map((response: any) => {
                return new StoreBrand(response);
            })
        );
    }

    public putBrand(id: number, body: StoreBrand): Observable<StoreBrand> {
        return this.request.put(shop + '/' + brands + '/' + id, body).pipe(
            map((response: any) => {
                return new StoreBrand(response);
            })
        );
    }

    public deleteCategory(id: number) {
        return this.request.delete(shop + '/' + categories + '/' + id).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteOperator(id: number) {
        return this.request.delete(shop + '/' + operators + '/' + id).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteProduct(id: number) {
        return this.request.delete(shop + '/' + products + '/' + id).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteProductTemplate(id: number) {
        return this.request.delete(shop + '/product-template/' + id).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteBrand(id: number) {
        return this.request.delete(shop + '/' + brands + '/' + id).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public successAlert(text) {
        this.alertService.show(
            '',
            'Successfully ' + text,
            {classname: 'bg-success text-light', delay: 2000}
        );
    }

    public errorAlert(message?) {
        this.alertService.show(
            'An error occurred!',
            message ? message : 'Try again!',
            {classname: 'bg-danger text-light', delay: 2000}
        );
    }
}
