import {CostsManagementComponent} from './operators/gestione-incentivazione/costs-management/costs-management.component';
import {KenaVisiteComponent} from './kena/kena-visite/kena-visite.component';
import {KenaRecapComponent} from './kena/kena-recap/kena-recap.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from './pages/main/main.component';
import {LoginComponent} from './pages/login/login.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {ContractListComponent} from '@app/pages/contract/list/contract-list.component';
import {ContractNewComponent} from '@app/pages/contract/new/contract-new.component';
import {ShopIndexComponent} from '@app/pages/shop/index/shop-index.component';
import {FilledPdfComponent} from '@app/pages/contract/pda/filled-pdf.component';
import {ContractDetailComponent} from '@app/pages/contract/detail/contract-detail.component';
import {FaqListComponent} from '@app/pages/faq/list/faq-list.component';
import {TicketListComponent} from '@app/pages/tickets/list/ticket-list.component';
import {TicketDetailComponent} from '@app/pages/tickets/detail/ticket-detail.component';
import {NotFoundComponent} from '@app/pages/main/not-found/not-found.component';
import {ResetPasswordComponent} from '@app/pages/main/reset-password/reset-password.component';
import {ComparatorIndexComponent} from '@app/pages/comparator/index/comparator-index.component';
import {PlafondTiscaliIndexComponent} from '@app/pages/plafond/tiscali/index/plafond-tiscali-index.component';
import {PlafondKenaIndexComponent} from '@app/pages/plafond/kena/index/plafond-kena-index.component';
import {CreditoIndexComponent} from '@app/pages/credito/index/credito-index.component';
import {AssetsTiscaliIndexComponent} from '@app/pages/assets/tiscali/index/assets-tiscali-index.component';
import {AssetsKenaIndexComponent} from '@app/pages/assets/kena/index/assets-kena-index.component';
import {ShopBuyComponent} from '@app/pages/shop/buy/shop-buy.component';
import {ShopDetailComponent} from '@app/pages/shop/detail/shop-detail.component';
import {ShopListComponent} from '@app/pages/shop/list/shop-list.component';
import {VideoIndexComponent} from '@app/pages/video/index/video-index.component';
import {DocumentsListComponent} from '@app/pages/documents/list/documents-list.component';
import {EventiIndexComponent} from '@app/pages/eventi/eventi-index/eventi-index.component';
import {EventiNewComponent} from '@app/pages/eventi/eventi-new/eventi-new.component';
import {EventiDetailComponent} from '@app/pages/eventi/eventi-detail/eventi-detail.component';
import {InvoiceListComponent} from '@app/pages/invoices/list/invoice-list.component';
import {InvoiceDetailComponent} from '@app/pages/invoices/detail/invoice-detail.component';
import {RicaricheIndexComponent} from '@app/pages/ricariche/index/ricariche-index.component';
import {environment} from '@environments/environment';
import {ShopBlockedComponent} from '@app/pages/shop/blocked/shop-blocked.component';
import {ShopPosComponent} from '@app/pages/shop/pos/shop-pos.component';
import {RoleGuard} from '@guards/role.guard';
import {ROLE_AGENT, ROLE_DEALER, ROLE_MASTER, ROLE_MASTERAGENT, ROLE_OPERATOR, ROLE_SUBMASTER, ROLE_VENDITORE} from '@models/user';
import {AvanzamentoComponent} from '@app/pages/contract/avanzamento/avanzamento.component';
import {MasterDashboardComponent} from '@app/master/master-dashboard/master-dashboard.component';
import {MasterProduzionePersaComponent} from '@app/master/master-produzione-persa/master-produzione-persa.component';
import {MasterAnalisiFasceComponent} from '@app/master/master-analisi-fasce/master-analisi-fasce.component';
import {MasterAnalisiPlafondComponent} from '@app/master/master-analisi-plafond/master-analisi-plafond.component';
import {MasterAnalisiSimComponent} from '@app/master/master-analisi-sim/master-analisi-sim.component';
import {MasterClassificaComponent} from '@app/master/master-classifica/master-classifica.component';
import {MasterConfrontoComponent} from '@app/master/master-confronto/master-confronto.component';
import {MasterNuoveAffiliazioniComponent} from '@app/master/master-nuove-affiliazioni/master-nuove-affiliazioni.component';
import {MasterNegozioComponent} from '@app/master/master-negozio/master-negozio.component';
import {MasterMappaComponent} from '@app/master/master-mappa/master-mappa.component';
import {MasterCentralinoComponent} from '@app/master/master-centralino/master-centralino.component';
import {MasterCentralinoOperatoreComponent} from '@app/master/master-centralino-operatore/master-centralino-operatore.component';
import {MasterPresidiComponent} from '@app/master/master-presidi/master-presidi.component';
import {MasterEmailComponent} from '@app/master/master-email/master-email.component';
import {CustomerBaseDisabledComponent} from '@app/pages/customer-base/disabled/customer-base-disabled.component';
import {GestioneIncentivazioneComponent} from '@app/operators/gestione-incentivazione/gestione-incentivazione.component';
import {SegnalazioneCaldaiaComponent} from '@app/pages/contract/segnalazione-caldaia/segnalazione-caldaia.component';
import {GestioneFatturazioneComponent} from '@app/operators/gestione-fatturazione/gestione-fatturazione.component';
import {MasterEconomicsComponent} from '@app/master/master-economics/master-economics.component';
import {GestioneStandComponent} from '@app/operators/gestione-stand/gestione-stand.component';
import {GestioneStandDettagliComponent} from '@app/operators/gestione-stand/gestione-stand-dettagli/gestione-stand-dettagli.component';
import {GestioneVenditoriComponent} from '@app/operators/gestione-stand/gestione-venditori/gestione-venditori.component';
import {GestioneVenditoriDettagliComponent} from '@app/operators/gestione-stand/gestione-venditori/gestione-venditori-dettagli/gestione-venditori-dettagli.component';
import {GestioneStorniComponent} from '@app/operators/gestione-incentivazione/gestione-storni/gestione-storni.component';
import {GestioneReportComponent} from '@app/operators/gestione-incentivazione/gestione-report/gestione-report.component';
import {MasterDealerDettaglioComponent} from '@app/master/master-dealer-dettaglio/master-dealer-dettaglio.component';
import {MasterAgenteComponent} from '@app/master/master-agente/master-agente.component';
import {MasterEconomicsGlobalComponent} from '@app/master/master-economics/master-economics-global/master-economics-global.component';
import {MasterEconomicsAgentiComponent} from '@app/master/master-economics/master-economics-agenti/master-economics-agenti.component';
import {MasterEconomicsNegoziComponent} from '@app/master/master-economics/master-economics-negozi/master-economics-negozi.component';
import {MasterEconomicsFatturatoComponent} from '@app/master/master-economics/master-economics-fatturato/master-economics-fatturato.component';
import {AgentVisiteIndexComponent} from '@app/agent/agent-visite/agent-visite-index/agent-visite-index.component';
import {MasterVisiteIndexComponent} from '@app/master/master-visite/master-visite-index/master-visite-index.component';
import {MasterVisiteDetailComponent} from '@app/master/master-visite/master-visite-detail/master-visite-detail.component';
import {MasterTicketsComponent} from '@app/master/master-tickets/master-tickets.component';
import {MasterKpiComponent} from '@app/master/master-kpi/master-kpi.component';
import {PlafondTimIndexComponent} from '@app/pages/plafond/tim/plafond-tim-index/plafond-tim-index.component';
import {EstrattiContoTimComponent} from '@app/pages/invoices/estratti-conto-tim/estratti-conto-tim.component';
import {CalendarioVisiteComponent} from '@app/agent/calendario-visite/calendario-visite.component';
import {MasterOutboundComponent} from '@app/master/master-outbound/master-outbound.component';
import {AffilationsComponent} from './pages/affiliations/affilations.component';
import {CbiGeneratorComponent} from './operators/cbi-generator/cbi-generator.component';
import {InvoiceManagementComponent} from './operators/invoice-management/invoice-management.component';
import {TicketManagementComponent} from './operators/ticket-management/ticket-management.component';
import {RetailerManagementComponent} from './operators/retailer-management/retailer-management.component';
import {MasterPlanningComponent} from './master/master-planning/master-planning.component';
import {MENU_RECHARGE, MENU_TICKET} from '@models/menu';
import {VendorGuard} from '@guards/vendor.guard';
import {ENI_GAS_E_LUCE, KENA_MOBILE, KENA_MOBILE_STANDS, SKY, TIM, TISCALI_MOBILE} from '@models/vendor';
import {ContractSimulatoreEniComponent} from './pages/contract/simulatore-eni/contract-simulatore-eni.component';
import {LoyaltyCardComponent} from '@app/pages/loyalty-card/loyalty-card.component';
import {CampaignsComponent} from '@app/pages/campaigns/campaigns.component';
import {ShopOrderDashboardComponent} from '@app/pages/shop-order-dashboard/shop-order-dashboard.component';
import {MasterWhatsappComponent} from './master/master-whatsapp/master-whatsapp.component';
import {PbxCampaignComponent} from '@app/pages/pbx/pbx-campaign/pbx-campaign.component';
import {PbxQuestionsComponent} from '@app/pages/pbx/pbx-questions/pbx-questions.component';
import {TicketDashboardComponent} from '@app/master/ticket-dashboard/ticket-dashboard.component';
import {EPayShopComponent} from '@app/pages/e-pay/epay-shop/e-pay-shop.component';
import {ProductsComponent} from '@app/pages/e-pay/products/products.component';
import {BrandsComponent} from '@app/pages/e-pay/brands/brands.component';
import {OrdersComponent} from '@app/pages/e-pay/orders/orders.component';
import {SubjectsComponent} from '@app/pages/invoice/subjects/subjects.component';
import {DocumentsComponent} from '@app/pages/invoice/documents/documents.component';
import {InvoiceDashboardComponent} from '@app/pages/invoice/invoice-dashboard/invoice-dashboard.component';
import {InvoiceReportComponent} from '@app/pages/invoice/invoice-report/invoice-report.component';
import {BankTransactionComponent} from '@app/pages/invoice/bank-transaction/bank-transaction.component';
import {BankAccountComponent} from '@app/pages/invoice/bank-account/bank-account.component';
import {AgentGpsTrackingComponent} from '@app/pages/agent-gps-tracking/agent-gps-tracking.component';
import {CategoryComponent} from '@app/pages/shop/category/category.component';
import {OperatorComponent} from '@app/pages/shop/operator/operator.component';
import {ShopProductComponent} from '@app/pages/shop/shop-product/shop-product.component';
import {EpaySalesComponent} from '@app/pages/e-pay/epay-sales/epay-sales.component';
import {CalendarComponent} from '@app/pages/invoice/calendar/calendar.component';
import {SubjectReportComponent} from '@app/pages/invoice/subject-report/subject-report.component';
import {DealerMapComponent} from '@app/pages/dealer-map/dealer-map.component';
import {EMailCampaignComponent} from '@app/pages/e-mail-campaign/e-mail-campaign.component';
import {PaymentComponent} from '@app/pages/invoice/payment/payment.component';
import {SmsGuard} from '@guards/sms.guard';
import {SmsSalesComponent} from '@app/pages/sms-sales/sms-sales.component';
import {ShopProductInstallmentsComponent} from '@app/pages/shop/shop-product-installments/shop-product-installments.component';
import {ShopSalesComponent} from '@app/pages/shop/shop-sales/shop-sales.component';
import {ChangePasswordComponent} from '@app/pages/main/change-password/change-password.component';
import {PasswordGuard} from '@guards/password.guard';
import {NotFoundGuard} from '@guards/not-found.guard';
import {PopupComponent} from '@app/pages/popup/popup.component';
import {ShopOrderHistoryComponent} from '@app/pages/shop-order-history/shop-order-history.component';
import {PrintingIndexComponent} from '@app/pages/printing/printing-index.component';
import {ShopCreditComponent} from '@app/pages/shop-credit/shop-credit.component';
// import {TicketShopComponent} from '@app/pages/ticket-service/ticket-shop/ticket-shop.component';
// import {TicketOrderListComponent} from '@app/pages/ticket-service/ticket-order-list/ticket-order-list.component';
import {PrintingOrderListComponent} from '@app/pages/printing/printing-order-list/printing-order-list.component';
import {EventListComponent} from './pages/eventi/event-list/event-list.component';
import {ShippingShopComponent} from '@app/pages/shipping-service/shipping-shop/shipping-shop.component';


const ROUTES: Routes = [
    {
        path: '',
        component: DashboardComponent,
        data: {
            label: 'Home',
            menu: environment.production,
            icon: 'fa fa-tachometer-alt',
        },
    },
    {
        path: 'retailers/list',
        component: RetailerManagementComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Gestione Negozi',
            menu: environment.production,
            icon: 'fa fa-user-cog',
            roles: [ROLE_OPERATOR, ROLE_AGENT, ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    {
        path: 'ticket-management',
        component: TicketManagementComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Gestione Ticket',
            menu: environment.production,
            icon: 'fa fa-grip-horizontal',
            roles: [ROLE_OPERATOR],
            badges: MENU_TICKET,
        },
    },
    {
        path: 'shop-order-history',
        component: ShopOrderHistoryComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Orders Management',
            menu: environment.production,
            icon: 'fa fa-cogs',
            roles: [ROLE_OPERATOR],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    {
        path: 'e-commerce',
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'KDRicarica',
            menu: environment.production,
            icon: 'fa fa-wallet',
            roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_DEALER],
            vendor: [KENA_MOBILE],
        },
        children: [
            {
                path: 'sales',
                component: EpaySalesComponent,
                data: {
                    label: 'Sales Dashboard',
                    menu: environment.production,
                    icon: 'fa fa-chart-bar',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'purchase',
                component: EPayShopComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Purchase',
                    menu: environment.production,
                    icon: 'fa fa-shopping-cart',
                    roles: [ROLE_DEALER, ROLE_MASTER],
                },
            },
            {
                path: 'products',
                component: ProductsComponent,
                data: {
                    label: 'Products',
                    menu: environment.production,
                    icon: 'fa fa-table',
                    roles: [ROLE_MASTER, ROLE_OPERATOR],
                },
            },
            {
                path: 'brands',
                component: BrandsComponent,
                data: {
                    label: 'Brands',
                    menu: environment.production,
                    icon: 'fa fa-table',
                    roles: [ROLE_MASTER, ROLE_OPERATOR],
                },
            },
            {
                path: 'orders',
                component: OrdersComponent,
                data: {
                    label: 'Orders',
                    menu: environment.production,
                    icon: 'fa fa-table',
                    roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_DEALER],
                },
            },
        ]
    },
    // {
    //     path: 'ticket',
    //     canActivate: [RoleGuard, VendorGuard],
    //     data: {
    //         label: 'KDTicket',
    //         menu: environment.production,
    //         icon: 'fas fa-ticket-alt',
    //         roles: [ROLE_OPERATOR, ROLE_DEALER],
    //         vendor: [KENA_MOBILE],
    //     },
    //     children: [
    //         {
    //             path: 'purchase',
    //             component: TicketShopComponent,
    //             canActivate: [RoleGuard],
    //             data: {
    //                 label: 'Purchase',
    //                 menu: environment.production,
    //                 icon: 'fa fa-shopping-cart',
    //                 roles: [ROLE_DEALER],
    //             },
    //         },
    //         {
    //             path: 'orders',
    //             component: TicketOrderListComponent,
    //             canActivate: [RoleGuard],
    //             data: {
    //                 label: 'Orders',
    //                 menu: environment.production,
    //                 icon: 'fa fa-table',
    //                 roles: [ROLE_DEALER, ROLE_OPERATOR],
    //             },
    //         }
    //     ]
    // },
    // {
    //     path: 'printing',
    //     canActivate: [RoleGuard, VendorGuard],
    //     data: {
    //         label: 'Stampa',
    //         menu: environment.production,
    //         icon: 'fa fa-print',
    //         roles: [ROLE_DEALER, ROLE_OPERATOR],
    //         vendor: [KENA_MOBILE],
    //     },
    //     children: [
    //         {
    //             path: 'purchase',
    //             component: PrintingIndexComponent,
    //             canActivate: [RoleGuard],
    //             data: {
    //                 label: 'Purchase',
    //                 menu: environment.production,
    //                 icon: 'fa fa-shopping-cart',
    //                 roles: [ROLE_DEALER],
    //             },
    //         },
    //         {
    //             path: 'orders',
    //             component: PrintingOrderListComponent,
    //             canActivate: [RoleGuard],
    //             data: {
    //                 label: 'Orders',
    //                 menu: environment.production,
    //                 icon: 'fa fa-table',
    //                 roles: [ROLE_DEALER, ROLE_OPERATOR],
    //             },
    //         }
    //     ]
    // },
    // {
    //     path: 'shipping',
    //     canActivate: [RoleGuard, VendorGuard],
    //     data: {
    //         label: 'Spedizione',
    //         menu: environment.production,
    //         icon: 'fas fa-shipping-fast',
    //         roles: [ROLE_DEALER, ROLE_OPERATOR],
    //         vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
    //     },
    //     children: [
    //         {
    //             path: 'purchase',
    //             component: ShippingShopComponent,
    //             canActivate: [RoleGuard],
    //             data: {
    //                 label: 'Purchase',
    //                 menu: environment.production,
    //                 icon: 'fa fa-shopping-cart',
    //                 roles: [ROLE_DEALER],
    //             },
    //         },
    //     ]
    // },
    {
        path: 'invoices',
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Invoices',
            menu: environment.production,
            icon: 'fa fa-file-invoice',
            roles: [ROLE_MASTER, ROLE_OPERATOR],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
        children: [
            {
                path: 'proforma',
                component: InvoiceManagementComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Lista Proforma',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER,],
                },
            },
            {
                path: 'subjects',
                component: SubjectsComponent,
                data: {
                    label: 'Subjects',
                    menu: environment.production,
                    icon: 'fa fa-file-invoice-dollar',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'documents',
                component: DocumentsComponent,
                data: {
                    label: 'Documents',
                    menu: environment.production,
                    icon: 'fa fa-file-invoice-dollar',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'payments',
                component: PaymentComponent,
                data: {
                    label: 'Payments',
                    menu: environment.production,
                    icon: 'fa fa-file-invoice-dollar',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'bank-transaction',
                component: BankTransactionComponent,
                data: {
                    label: 'Bank Incomes/Outcomes',
                    menu: environment.production,
                    icon: 'fa fa-file-invoice-dollar',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'bank-account',
                component: BankAccountComponent,
                data: {
                    label: 'Bank Accounts',
                    menu: environment.production,
                    icon: 'fas fa-university',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'dashboard',
                component: InvoiceDashboardComponent,
                data: {
                    label: 'Dashboard',
                    menu: environment.production,
                    icon: 'fa fa-tachometer-alt',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'report',
                component: InvoiceReportComponent,
                data: {
                    label: 'Report',
                    menu: environment.production,
                    icon: 'fas fa-chart-pie',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'calendar',
                component: CalendarComponent,
                data: {
                    label: 'Calendar',
                    menu: environment.production,
                    icon: 'fas fa-calendar',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'subject-report',
                component: SubjectReportComponent,
                data: {
                    label: 'Subject Report',
                    menu: environment.production,
                    icon: 'fas fa-chart-pie',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            }
        ]
    },
    {
        path: 'ricariche',
        component: RicaricheIndexComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Ricariche',
            menu: environment.production,
            icon: 'fa fa-mobile-alt',
            roles: [ROLE_DEALER, ROLE_OPERATOR],
            vendor: [TISCALI_MOBILE, TIM],
            badges: MENU_RECHARGE,
        },
    },
    {
        path: 'affiliazioni',
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Affiliazioni',
            menu: environment.production,
            icon: 'fa fa-user-plus',
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_OPERATOR, ROLE_MASTERAGENT, ROLE_SUBMASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
        children: [
            {
                path: 'dashboard',
                component: MasterNuoveAffiliazioniComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Dashboard',
                    menu: environment.production,
                    icon: 'fa fa-chart-line',
                    roles: [ROLE_MASTER, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
                },
            },
            {
                path: 'lista',
                component: AffilationsComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Gestione',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_MASTER, ROLE_AGENT, ROLE_OPERATOR],
                },
            },
        ],
    },
    {
        path: 'negozio',
        canActivate: [RoleGuard],
        data: {
            label: 'Negozio',
            menu: environment.production,
            icon: 'fa fa-shopping-basket',
            roles: [ROLE_DEALER, ROLE_AGENT, ROLE_OPERATOR, ROLE_MASTER],
        },
        children: [
            {
                path: 'acquista',
                component: ShopIndexComponent,
                canActivate: [VendorGuard],
                data: {
                    label: 'Acquista',
                    menu: environment.production,
                    icon: 'fa fa-shopping-cart',
                    vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
                },
            },
            {
                path: 'carrello',
                component: ShopBuyComponent,
                canActivate: [VendorGuard],
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                    vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
                },
            },
            {
                path: 'bloccato',
                component: ShopBlockedComponent,
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                },
            },
            {
                path: 'pos/:id',
                component: ShopPosComponent,
                canActivate: [VendorGuard],
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                    vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
                },
            },
            {
                path: 'dettaglio/:id',
                component: ShopDetailComponent,
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                },
            },
            {
                path: 'lista-ordini',
                component: ShopListComponent,
                data: {
                    label: 'Lista Ordini',
                    menu: environment.production,
                    icon: 'fa fa-list-ul',
                },
            },
            {
                path: 'installments',
                canActivate: [RoleGuard, VendorGuard],
                component: ShopProductInstallmentsComponent,
                data: {
                    label: 'Listino Finanziamenti',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    roles: [ROLE_OPERATOR, ROLE_MASTER, ROLE_DEALER],
                    vendor: [TIM],
                },
            },
            {
                path: 'sales',
                canActivate: [RoleGuard],
                component: ShopSalesComponent,
                data: {
                    label: 'Shop Sales',
                    menu: environment.production,
                    icon: 'fa fa-chart-bar',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'category',
                component: CategoryComponent,
                data: {
                    label: 'Categories',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                    menu: environment.production,
                    icon: 'fa fa-list',
                },
            },
            {
                path: 'brand',
                component: OperatorComponent,
                data: {
                    label: 'Brands',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                    menu: environment.production,
                    icon: 'fa fa-list',
                },
            },
            {
                path: 'product',
                component: ShopProductComponent,
                data: {
                    label: 'Products',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                    menu: environment.production,
                    icon: 'fa fa-list',
                },
            },
        ],
    },
    {
        path: 'dealer-map',
        component: DealerMapComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Map analytics',
            menu: environment.production,
            icon: 'fa fa-map-marked-alt',
            roles: [ROLE_OPERATOR, ROLE_MASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    {
        path: 'e-mail-campaign',
        component: EMailCampaignComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Mailchimp',
            menu: environment.production,
            icon: 'far fa-envelope',
            roles: [ROLE_MASTER, ROLE_OPERATOR],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    // {
    //     path: 'area-managers/list',
    //     component: RetailerManagementComponent,
    //     canActivate: [RoleGuard, VendorGuard],
    //     data: {
    //         label: 'Gestione Agenti',
    //         menu: environment.production,
    //         icon: 'fa fa-user-cog',
    //         roles: [ROLE_OPERATOR, ROLE_MASTER],
    //         vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
    //     },
    // },
    {
        path: 'gestione-credito',
        component: CreditoIndexComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Gestione Credito',
            menu: environment.production,
            icon: 'fa fa-wallet',
            roles: [ROLE_OPERATOR],
        },
    },
    {
        path: 'gestione-credito/:id',
        component: CreditoIndexComponent,
        canActivate: [RoleGuard],
        data: {
            label: '',
            menu: false,
            icon: '',
            roles: [ROLE_OPERATOR],
        },
    },
    {
        path: 'shop-credit',
        component: ShopCreditComponent,
        data: {
            label: 'Shop Credit',
            menu: environment.production,
            icon: 'fa fa-euro-sign',
            roles: [ROLE_OPERATOR],
        },
    },
    {
        path: 'shop-credit/:id',
        component: ShopCreditComponent,
        data: {
            label: '',
            menu: false,
            icon: '',
            roles: [ROLE_OPERATOR],
        },
    },
    {
        path: 'cbi',
        component: CbiGeneratorComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Gestione Pagamenti',
            menu: environment.production,
            icon: 'fa fa-cash-register',
            roles: [ROLE_OPERATOR],
        },
    },
    // {
    //     path: 'gestione-negozio',
    //     component: GestioneNegozioComponent,
    //     canActivate: [RoleGuard],
    //     data: {
    //         label: 'Gestione Negozio',
    //         menu: environment.production,
    //         icon: 'fa fa-shopping-cart',
    //         roles: [ROLE_OPERATOR],
    //     },
    // },
    {
        path: 'gestione-stand',
        component: GestioneStandComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Gestione Stand',
            menu: environment.production,
            icon: 'fa fa-store',
            roles: [ROLE_OPERATOR, ROLE_MASTER],
            vendor: [KENA_MOBILE_STANDS]
        },
    },
    {
        path: 'gestione-stand/:deleted',
        component: GestioneStandComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: null,
            menu: false,
            icon: null,
            roles: [ROLE_OPERATOR, ROLE_MASTER],
            vendor: [KENA_MOBILE_STANDS]
        },
    },
    {
        path: 'gestione-stand/stand/:id',
        component: GestioneStandDettagliComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: null,
            menu: false,
            icon: null,
            roles: [ROLE_OPERATOR, ROLE_MASTER],
            vendor: [KENA_MOBILE_STANDS]
        },
    },

    {
        path: 'gestione-stand/nuovo/stand',
        component: GestioneStandDettagliComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: null,
            menu: false,
            icon: null,
            roles: [ROLE_OPERATOR, ROLE_MASTER],
            vendor: [KENA_MOBILE_STANDS]
        },
    },
    {
        path: 'gestione-stand/:id/venditori',
        component: GestioneVenditoriComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: null,
            menu: false,
            icon: null,
            roles: [ROLE_OPERATOR, ROLE_MASTER],
            vendor: [KENA_MOBILE_STANDS]
        },
    },
    {
        path: 'gestione-stand/:id/venditori/:deleted',
        component: GestioneVenditoriComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: null,
            menu: false,
            icon: null,
            roles: [ROLE_OPERATOR, ROLE_MASTER],
            vendor: [KENA_MOBILE_STANDS]
        },
    },
    {
        path: 'gestione-stand/:id/:venditore',
        component: GestioneVenditoriDettagliComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: null,
            menu: false,
            icon: null,
            roles: [ROLE_OPERATOR, ROLE_MASTER],
            vendor: [KENA_MOBILE_STANDS]
        },
    },
    {
        path: 'gestione-stand/:id/nuovo/venditore',
        component: GestioneVenditoriDettagliComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: null,
            menu: false,
            icon: null,
            roles: [ROLE_OPERATOR, ROLE_MASTER],
            vendor: [KENA_MOBILE_STANDS]
        },
    },
    {
        path: 'piano-incentivi',
        canActivate: [RoleGuard],
        data: {
            label: 'Incentivazione',
            menu: environment.production,
            icon: 'fa fa-list',
            roles: [ROLE_OPERATOR, ROLE_MASTER],
        },
        children: [
            {
                path: 'settings',
                component: GestioneIncentivazioneComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Imposta Gare',
                    menu: environment.production,
                    icon: 'fa fa-wrench',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'storni',
                component: GestioneStorniComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Storni',
                    menu: environment.production,
                    icon: 'fa fa-search-dollar',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'report',
                component: GestioneReportComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Report',
                    menu: environment.production,
                    icon: 'fa fa-list-alt',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
            {
                path: 'costi',
                component: CostsManagementComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Costi',
                    menu: environment.production,
                    icon: 'fa fa-list-alt',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
        ],
    },
    {
        path: 'gestione-fatturazione',
        component: GestioneFatturazioneComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Fatturazione',
            menu: environment.production,
            icon: 'fa fa-file-invoice',
            roles: [ROLE_OPERATOR, ROLE_MASTER],
        },
    },
    {
        path: 'dashboard',
        component: MasterDashboardComponent,
        canActivate: [RoleGuard],
        data: {
            label: '',
            menu: false,
            icon: '',
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
        },
    },
    {
        path: 'kena',
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Kena Mobile',
            menu: environment.production,
            icon: 'fa fa-table',
            roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
            vendor: [KENA_MOBILE],
        },
        children: [
            {
                path: 'recap',
                component: KenaRecapComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Recap',
                    menu: environment.production,
                    icon: 'fa fa-border-all',
                    roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
                },
            },
            {
                path: 'visite',
                component: KenaVisiteComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Visite',
                    menu: environment.production,
                    icon: 'fa fa-map-signs',
                    roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
                },
            },
        ],
    },
    {
        path: 'planning',
        component: MasterPlanningComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Planning',
            menu: environment.production,
            icon: 'fa fa-user',
            roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_AGENT],
        },
    },
    // {
    //     path: "analytics",
    //     component: MasterAnalyticsComponent,
    //     canActivate: [RoleGuard],
    //     data: {
    //         label: "Analytics",
    //         menu: environment.production,
    //         icon: "fa fa-table",
    //         roles: [ROLE_MASTER],
    //     },
    // },
    // {
    //     path: 'agente',
    //     component: MasterAgenteComponent,
    //     canActivate: [RoleGuard],
    //     data: {
    //         label: 'Panoramica',
    //         menu: environment.production,
    //         icon: 'fa fa-user',
    //         roles: [ROLE_AGENT]
    //     }
    // },
    {
        path: 'kpi',
        component: MasterKpiComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'KPI & Target',
            menu: environment.production,
            icon: 'fa fa-bullseye',
            roles: [ROLE_AGENT, ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE, KENA_MOBILE_STANDS],
        },
    },
    {
        path: 'whatsapp',
        component: MasterWhatsappComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Whatsapp',
            menu: environment.production,
            icon: 'fab fa-whatsapp',
            roles: [ROLE_MASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    {
        path: 'campagne-outbound',
        component: MasterOutboundComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Campagne Outbound',
            menu: environment.production,
            icon: 'fa fa-phone-volume',
            roles: [ROLE_MASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    // {
    //     path: 'facebook',
    //     component: MasterFacebookComponent,
    //     canActivate: [RoleGuard, VendorGuard],
    //     data: {
    //         label: 'Facebook',
    //         menu: environment.production,
    //         icon: 'fab fa-facebook',
    //         roles: [ROLE_MASTER, ROLE_OPERATOR],
    //         vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
    //     },
    // },
    // {
    //     path: 'facebook/:id/:date',
    //     component: MasterFacebookDetailComponent,
    //     canActivate: [RoleGuard],
    //     data: {
    //         label: '',
    //         menu: false,
    //         icon: '',
    //         roles: [ROLE_MASTER, ROLE_OPERATOR],
    //     },
    // },
    {
        path: 'visite',
        canActivate: [RoleGuard],
        data: {
            label: 'Visite',
            menu: environment.production,
            icon: 'fa fa-route',
            roles: [ROLE_AGENT],
        },
        children: [
            {
                path: 'gestione',
                component: AgentVisiteIndexComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Gestione Visite',
                    menu: environment.production,
                    icon: 'fa fa-map-marker-alt',
                    roles: [ROLE_AGENT],
                },
            },
            {
                path: 'panoramica',
                component: MasterVisiteDetailComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Panoramica Visite',
                    menu: environment.production,
                    icon: 'fa fa-wrench',
                    roles: [ROLE_AGENT],
                },
            },
            {
                path: 'calendario',
                component: CalendarioVisiteComponent,
                canActivate: [RoleGuard],
                data: {
                    label: 'Calendario Visite',
                    menu: environment.production,
                    icon: 'fa fa-calendar',
                    roles: [ROLE_AGENT],
                },
            },
        ],
    },
    {
        path: 'gps-tracking',
        component: AgentGpsTrackingComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'GPS tracking',
            menu: environment.production,
            icon: 'fa fa-map-marked-alt',
            roles: [ROLE_MASTER, ROLE_OPERATOR],
            vendor: [KENA_MOBILE],
        }
    },
    {
        path: 'visite-riepilogo',
        component: MasterVisiteIndexComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Visite',
            menu: environment.production,
            icon: 'fa fa-route',
            roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    {
        path: 'visite-riepilogo/:id',
        component: MasterVisiteDetailComponent,
        canActivate: [RoleGuard],
        data: {
            label: '',
            menu: false,
            icon: '',
            roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
        },
    },
    {
        path: 'brain',
        component: MasterEmailComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Automatismi',
            menu: environment.production,
            icon: 'fa fa-brain',
            roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
            vendor: [KENA_MOBILE],
        },
    },
    {
        path: 'produzione-persa',
        component: MasterProduzionePersaComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Produzione Persa',
            menu: environment.production,
            icon: 'fa fa-search-minus',
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
        },
    },
    {
        path: 'analisi-fasce',
        component: MasterAnalisiFasceComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Analisi Fasce',
            menu: environment.production,
            icon: 'fa fa-list-ol',
            roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
        },
    },
    {
        path: 'analisi-plafond',
        component: MasterAnalisiPlafondComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Analisi Plafond',
            menu: environment.production,
            icon: 'fa fa-wallet',
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER],
            vendor: [TISCALI_MOBILE, KENA_MOBILE],
        },
    },
    {
        path: 'analisi-sim',
        component: MasterAnalisiSimComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Analisi Sim',
            menu: environment.production,
            icon: 'fa fa-sim-card',
            vendor: [TISCALI_MOBILE, KENA_MOBILE],
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER],
        },
    },
    {
        path: 'classifica',
        component: MasterClassificaComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Analisi Produzione',
            menu: environment.production,
            icon: 'fa fa-sitemap',
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
        },
    },
    {
        path: 'confronto',
        component: MasterConfrontoComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Confronto',
            menu: environment.production,
            icon: 'fa fa-random',
            roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
        },
    },
    {
        path: 'analisi-negozio',
        component: MasterNegozioComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Analisi Negozio',
            menu: environment.production,
            icon: 'fa fa-shopping-cart',
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    {
        path: 'mappa',
        component: MasterMappaComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Mappa',
            menu: environment.production,
            icon: 'fa fa-map-marked-alt',
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
        },
    },
    {
        path: 'centralino',
        component: MasterCentralinoComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Centralino',
            menu: environment.production,
            icon: 'fa fa-headset',
            roles: [ROLE_MASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    {
        path: 'centralino/operatore/:operatore',
        component: MasterCentralinoOperatoreComponent,
        canActivate: [RoleGuard],
        data: {
            label: null,
            menu: false,
            icon: null,
            roles: [ROLE_MASTER],
        },
    },
    {
        path: 'economics',
        component: MasterEconomicsComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Economics',
            menu: environment.production,
            icon: 'fas fa-chart-pie',
            roles: [ROLE_MASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    {
        path: 'economics/fatturato/:tipo/:periodo',
        component: MasterEconomicsFatturatoComponent,
        canActivate: [RoleGuard],
        data: {
            label: null,
            menu: false,
            icon: null,
            roles: [ROLE_MASTER],
        },
    },
    {
        path: 'economics/negozi/:periodo',
        component: MasterEconomicsNegoziComponent,
        canActivate: [RoleGuard],
        data: {
            label: null,
            menu: false,
            icon: null,
            roles: [ROLE_MASTER],
        },
    },
    {
        path: 'economics/agenti/:periodo',
        component: MasterEconomicsAgentiComponent,
        canActivate: [RoleGuard],
        data: {
            label: null,
            menu: false,
            icon: null,
            roles: [ROLE_MASTER],
        },
    },
    {
        path: 'economics/global/:periodo',
        component: MasterEconomicsGlobalComponent,
        canActivate: [RoleGuard],
        data: {
            label: null,
            menu: false,
            icon: null,
            roles: [ROLE_MASTER],
        },
    },
    {
        path: 'presidi',
        component: MasterPresidiComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Presidi',
            menu: environment.production,
            icon: 'fa fa-store ',
            roles: [ROLE_MASTER],
            vendor: [KENA_MOBILE_STANDS],
        },
    },
    {
        path: 'dealer/:id',
        component: MasterDealerDettaglioComponent,
        canActivate: [RoleGuard],
        data: {
            label: null,
            menu: false,
            icon: null,
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_OPERATOR, ROLE_MASTERAGENT, ROLE_SUBMASTER],
        },
    },
    {
        path: 'agente/:id',
        component: MasterAgenteComponent,
        canActivate: [RoleGuard],
        data: {
            label: null,
            menu: false,
            icon: null,
            roles: [ROLE_MASTER, ROLE_AGENT],
        },
    },
    {
        path: 'statistiche-ticket',
        component: MasterTicketsComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Statistiche Tickets',
            menu: environment.production,
            icon: 'fa fa-life-ring',
            roles: [ROLE_MASTER, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    {
        path: 'comparatore',
        component: ComparatorIndexComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Comparatore',
            menu: environment.production,
            icon: 'fa fa-search',
            roles: [ROLE_DEALER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM],
        },
    },
    // {
    //     path: 'push-marketing',
    //     canActivate: [RoleGuard, VendorGuard],
    //     data: {
    //         label: 'Master SMS',
    //         menu: environment.production,
    //         icon: 'fa fa-comments-dollar',
    //         roles: [ROLE_DEALER],
    //         vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM],
    //     },
    //     children: [
    //         {
    //             path: '',
    //             canActivate: [RoleGuard],
    //             component: CustomerBaseIndexComponent,
    //             data: {
    //                 label: 'La tua Customer Base',
    //                 menu: environment.production,
    //                 icon: 'fa fa-paper-plane',
    //                 roles: [ROLE_DEALER],
    //             },
    //         },
    //         {
    //             path: 'campagne',
    //             component: CustomerBaseCampaignComponent,
    //             canActivate: [RoleGuard],
    //             data: {
    //                 label: 'Lista Campagne',
    //                 menu: environment.production,
    //                 icon: 'fa fa-list',
    //                 roles: [ROLE_DEALER],
    //             },
    //         },
    //         {
    //             path: 'list/:operatore',
    //             component: CustomerBaseListComponent,
    //             canActivate: [RoleGuard],
    //             data: {
    //                 label: '',
    //                 menu: false,
    //                 icon: '',
    //                 roles: [ROLE_DEALER],
    //             },
    //         },
    //         {
    //             path: 'send',
    //             component: CustomerBaseSendComponent,
    //             canActivate: [RoleGuard],
    //             data: {
    //                 label: '',
    //                 menu: false,
    //                 icon: '',
    //                 roles: [ROLE_DEALER],
    //             },
    //         },
    //     ],
    // },
    // {
    {
        path: 'sms-external',
        canActivate: [RoleGuard, VendorGuard, SmsGuard],
        component: CustomerBaseDisabledComponent,
        data: {
            label: 'Master SMS',
            menu: environment.production,
            icon: 'fa fa-sms',
            roles: [ROLE_DEALER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
            sms: true,
            new: true
        },
    },
    {
        path: 'sms',
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Master SMS',
            menu: environment.production,
            icon: 'fa fa-sms',
            roles: [ROLE_MASTER, ROLE_OPERATOR],
            vendor: [KENA_MOBILE],
        },
        children: [
            {
                path: 'sales',
                component: SmsSalesComponent,
                data: {
                    label: 'Sales Dashboard',
                    menu: environment.production,
                    icon: 'fa fa-chart-bar',
                    roles: [ROLE_OPERATOR, ROLE_MASTER],
                },
            },
        ]
    },
    {
        path: 'master-sms',
        canActivate: [RoleGuard],
        component: CustomerBaseDisabledComponent,
        data: {
            label: '',
            menu: false,
            icon: '',
            roles: [ROLE_DEALER],
        },
    },
    {
        path: 'contratti',
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Contratti',
            menu: environment.production,
            icon: 'fa fa-file-alt',
            roles: [ROLE_DEALER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM],
        },
        children: [
            {
                path: 'nuovo',
                component: ContractNewComponent,
                data: {
                    label: 'Nuova Attivazione',
                    menu: environment.production,
                    icon: 'fa fa-plus-circle',
                },
            },
            {
                path: 'pda/:id',
                component: FilledPdfComponent,
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                },
            },
            {
                path: 'dettaglio/:id',
                component: ContractDetailComponent,
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                },
            },
            {
                path: 'lista',
                component: ContractListComponent,
                data: {
                    label: 'Lista Contratti',
                    menu: environment.production,
                    icon: 'fa fa-list-ul',
                },
            },
            {
                path: 'simulatore/eni',
                canActivate: [VendorGuard],
                component: ContractSimulatoreEniComponent,
                data: {
                    label: 'Simulatore Eni',
                    menu: environment.production,
                    icon: 'fa fa-file-invoice-dollar',
                    vendor: [ENI_GAS_E_LUCE],
                },
            },
            {
                path: 'eni/segnalazione',
                canActivate: [VendorGuard],
                component: SegnalazioneCaldaiaComponent,
                data: {
                    label: 'Io segnalo',
                    menu: environment.production,
                    icon: 'fa fa-file-invoice-dollar',
                    vendor: [ENI_GAS_E_LUCE],
                },
            },
        ],
    },
    {
        path: 'avanzamento',
        canActivate: [RoleGuard],
        data: {
            label: 'Avanzamenti',
            menu: environment.production,
            icon: 'fa fa-chart-line',
            roles: [ROLE_DEALER],
        },
        children: [
            {
                path: 'eni',
                canActivate: [VendorGuard],
                component: AvanzamentoComponent,
                data: {
                    label: 'Eni gas e luce',
                    menu: environment.production,
                    icon: 'fa fa-list-ul',
                    vendor: [ENI_GAS_E_LUCE],
                },
            },
            {
                path: 'tiscali-mobile',
                canActivate: [VendorGuard],
                component: AvanzamentoComponent,
                data: {
                    label: 'Tiscali Mobile',
                    menu: environment.production,
                    icon: 'fa fa-list-ul',
                    vendor: [TISCALI_MOBILE],
                },
            },
            {
                path: 'tiscali-lte',
                canActivate: [VendorGuard],
                component: AvanzamentoComponent,
                data: {
                    label: 'Tiscali LTE',
                    menu: environment.production,
                    icon: 'fa fa-list-ul',
                    vendor: [TISCALI_MOBILE],
                },
            },
            {
                path: 'tiscali-fisso',
                canActivate: [VendorGuard],
                component: AvanzamentoComponent,
                data: {
                    label: 'Tiscali Fisso',
                    menu: environment.production,
                    icon: 'fa fa-list-ul',
                    vendor: [TISCALI_MOBILE],
                },
            },
            {
                path: 'kena',
                canActivate: [VendorGuard],
                component: AvanzamentoComponent,
                data: {
                    label: 'Kena Mobile',
                    menu: environment.production,
                    icon: 'fa fa-list-ul',
                    vendor: [KENA_MOBILE],
                },
            },
            {
                path: 'sky',
                canActivate: [VendorGuard],
                component: AvanzamentoComponent,
                data: {
                    label: 'Sky',
                    menu: environment.production,
                    icon: 'fa fa-list-ul',
                    vendor: [SKY],
                },
            },
        ],
    },
    {
        path: 'supporto',
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Supporto',
            menu: environment.production,
            icon: 'fa fa-hands-helping',
            roles: [ROLE_DEALER, ROLE_AGENT, ROLE_OPERATOR, ROLE_MASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
        children: [
            {
                path: 'ticket',
                component: TicketListComponent,
                data: {
                    label: 'Lista Ticket',
                    menu: environment.production,
                    icon: 'fa fa-list-ul',
                },
            },
            {
                path: 'ticket/:id',
                component: TicketDetailComponent,
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                },
            },
            {
                path: 'domande',
                component: FaqListComponent,
                data: {
                    label: 'Domande Frequenti',
                    menu: environment.production,
                    icon: 'fa fa-question-circle',
                },
            },
            {
                path: 'domande/:category',
                component: FaqListComponent,
                data: {
                    label: '',
                    menu: false,
                    icon: '',
                },
            },
            {
                path: 'video',
                component: VideoIndexComponent,
                data: {
                    label: 'Video',
                    menu: environment.production,
                    icon: 'fa fa-graduation-cap',
                },
            },
            {
                path: 'documenti',
                component: DocumentsListComponent,
                data: {
                    label: 'Documenti',
                    menu: environment.production,
                    icon: 'fa fa-folder-open',
                },
            },
        ],
    },
    {
        path: 'loyalty-card',
        component: LoyaltyCardComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Fidelity Card',
            menu: environment.production,
            icon: 'fa fa-medal',
            roles: [ROLE_DEALER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    {
        path: 'campaigns',
        component: CampaignsComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Campaigns',
            menu: environment.production,
            icon: 'fa fa-medal',
            roles: [ROLE_OPERATOR],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        }
    },
    {
        path: 'pbx',
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'PBX',
            menu: environment.production,
            icon: 'fa fa-list',
            roles: [ROLE_OPERATOR, ROLE_MASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
        children: [
            {
                path: 'campaign',
                component: PbxCampaignComponent,
                data: {
                    label: 'Campaigns',
                    menu: environment.production,
                    icon: 'fa fa-headset '
                },
            },
            {
                path: 'questions',
                component: PbxQuestionsComponent,
                data: {
                    label: 'Questions',
                    menu: environment.production,
                    icon: 'far fa-question-circle',
                },
            }
        ]
    },
    {
        path: 'popup',
        component: PopupComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Popup',
            menu: environment.production,
            icon: 'fas fa-exclamation-circle',
            roles: [ROLE_OPERATOR],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        }
    },
    {
        path: 'shop-order-dashboard',
        component: ShopOrderDashboardComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Shop Orders Dashboard',
            menu: environment.production,
            icon: 'fa fa-tachometer-alt',
            roles: [ROLE_MASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    {
        path: 'ticket-dashboard',
        component: TicketDashboardComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Ticket Dashboard',
            menu: environment.production,
            icon: 'fa fa-tachometer-alt',
            roles: [ROLE_MASTER],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    {
        path: 'fatturazione',
        canActivate: [RoleGuard],
        data: {
            label: 'Fatturazione',
            menu: environment.production,
            icon: 'fa fa-euro-sign',
            roles: [ROLE_DEALER, ROLE_VENDITORE],
        },
        children: [
            {
                path: 'lista',
                component: InvoiceListComponent,
                data: {
                    label: 'Proforma Compensi',
                    menu: environment.production,
                    icon: 'fa fa-list',
                },
            },
            {
                path: 'ec-tim',
                canActivate: [VendorGuard],
                component: EstrattiContoTimComponent,
                data: {
                    label: 'Estratti Conto Incassi',
                    menu: environment.production,
                    icon: 'fa fa-list',
                    vendor: [TIM],
                },
            },
        ],
    },
    {
        path: 'fatturazione/fattura/:id',
        component: InvoiceDetailComponent,
        canActivate: [RoleGuard],
        data: {
            label: '',
            menu: false,
            icon: '',
            roles: [ROLE_DEALER, ROLE_VENDITORE],
        },
    },
    {
        path: 'presidia-evento',
        component: EventiIndexComponent,
        canActivate: [RoleGuard],
        data: {
            label: 'Presidia un evento',
            menu: environment.production,
            icon: 'fa fa-store',
            roles: [ROLE_DEALER],
        },
    },
    {
        path: 'presidia-evento/nuovo',
        component: EventiNewComponent,
        canActivate: [RoleGuard],
        data: {
            label: '',
            menu: false,
            icon: '',
            roles: [ROLE_DEALER],
        },
    },
    {
        path: 'presidia-evento/dettaglio/:id',
        component: EventiDetailComponent,
        canActivate: [RoleGuard],
        data: {
            label: '',
            menu: false,
            icon: '',
            roles: [ROLE_DEALER],
        },
    },
    {
        path: 'presidia-evento/list',
        component: EventListComponent,
        canActivate: [RoleGuard, VendorGuard],
        data: {
            label: 'Event list',
            menu: true,
            icon: 'fa fa-table',
            roles: [ROLE_OPERATOR],
            vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
        },
    },
    // {
    //     path: 'chiamate',
    //     component: PbxListComponent,
    //     canActivate: [RoleGuard, VendorGuard],
    //     data: {
    //         label: 'Le mie chiamate',
    //         menu: environment.production,
    //         icon: 'fa fa-headphones',
    //         roles: [ROLE_DEALER, ROLE_MASTER],
    //         vendor: [TISCALI_MOBILE, ENI_GAS_E_LUCE, SKY, TIM, KENA_MOBILE],
    //     },
    // },
    // {
    //     path: 'slot-machine',
    //     component: SlotMachineIndexComponent,
    //     canActivate: [RoleGuard],
    //     data: {
    //         label: 'Slot Machine',
    //         menu: environment.production,
    //         icon: 'fa fa-gamepad',
    //         roles: [ROLE_DEALER]
    //     }
    // },
    {
        path: 'plafond/tim',
        component: PlafondTimIndexComponent,
        data: {
            label: '',
            menu: false,
            icon: '',
        },
    },
    {
        path: 'plafond/tiscali',
        component: PlafondTiscaliIndexComponent,
        data: {
            label: '',
            menu: false,
            icon: '',
        },
    },
    {
        path: 'plafond/kena',
        component: PlafondKenaIndexComponent,
        data: {
            label: '',
            menu: 'false',
            icon: '',
        },
    },
    {
        path: 'sim/tiscali',
        component: AssetsTiscaliIndexComponent,
        data: {
            label: '',
            menu: false,
            icon: '',
        },
    },
    {
        path: 'sim/kena',
        component: AssetsKenaIndexComponent,
        data: {
            label: 'false',
            menu: false,
            icon: '',
        },
    },
    {
        path: 'credito',
        component: CreditoIndexComponent,
        canActivate: [RoleGuard],
        data: {
            label: '',
            menu: false,
            icon: '',
            roles: [ROLE_DEALER],
        },
    },
    {
        path: 'profile',
        component: ProfileComponent,
        data: {
            label: '',
            menu: false,
            icon: '',
        },
    },
    {
        path: 'profile/:id',
        component: ProfileComponent,
        data: {
            label: '',
            menu: false,
            icon: '',
        },
    },
    {
        path: 'pagina-non-trovata',
        component: NotFoundComponent,
        canActivate: [NotFoundGuard],
        data: {
            label: '',
            menu: false,
            icon: '',
        },
    },

];

const APP_ROUTES: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        children: ROUTES,
    },
    {
        path: 'login',
        component: LoginComponent,
        canLoad: [
            {
                menu: false,
                icon: '',
            },
        ],
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
        canLoad: [
            {
                menu: false,
                icon: '',
            },
        ],
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [PasswordGuard],
        canLoad: [
            {
                menu: false,
                icon: '',
            },
        ],
    },
    {path: '**', redirectTo: '/pagina-non-trovata'},
];

@NgModule({
    imports: [RouterModule.forRoot(APP_ROUTES, {useHash: false})],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
