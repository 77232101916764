/* tslint:disable:variable-name */
import {Customer} from '@models/customer';

export class Contract {
    id: number;
    operatore: string;
    ragsociale: string;
    agente: string;
    offerta: string;
    cliente: string;
    sottofamiglia: string;
    famiglia: string;
    pista: string;
    tipopista: string;
    created: string;
    codicecliente: string;
    contratto_stato: string;
    pda_path: string;
    customer: Customer;
    portabilita: {
        operatore: string;
        numero: string;
        seriale: string;
    };
    documents: [{
        nome: string;
        url: string;
    }];
    seriali: [{
        campo: string;
        valore: string;
    }];
    campi: [{
        campo: string;
        valore: string;
    }];
    note_operatore: string;

    fromJSON(value: Contract) {
        const contract = new Contract();
        const customer = new Customer();
        Object.assign(contract, value);
        Object.assign(customer, value.customer);
        contract.customer = customer;
        return contract;
    }

    fromArray(result: []) {
        const array: Contract[] = [];
        for (const item of result) {
            const tempItem: Contract = new Contract();
            const customer = new Customer();
            Object.assign(tempItem, item);
            Object.assign(customer, (item as any).customer);
            tempItem.customer = customer;
            array.push(tempItem);
        }
        return array;
    }
}
