import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {AuthenticationService} from '@app/services/authentication.service';
import {User} from '@models/user';
import {Subscription} from 'rxjs';
import {DataService} from '@services/data.service';
import {
    CampiPagamentoSkeleton,
    ContractSkeleton,
    CostoOfferteSkeleton,
    InfoOfferteSkeleton,
    OfferteSkeleton,
    PagamentiSkeleton, Venditori
} from '@models/contract/contract-skeleton';
import {Customer} from '@models/customer';
import {objectToFormData} from 'object-to-formdata';
import {Router} from '@angular/router';

@Component({
    selector: 'app-contract-new',
    templateUrl: './contract-new.component.html',
    styleUrls: ['./contract-new.component.scss']
})
export class ContractNewComponent implements OnInit {
    public skeletons: ContractSkeleton[];
    public customers: Customer[];
    public venditori: Venditori[] = [];
    public pagamenti: PagamentiSkeleton[];
    public campiPagamento: CampiPagamentoSkeleton[];
    public infoOfferta: InfoOfferteSkeleton = null;
    public costiOfferta: CostoOfferteSkeleton[];
    public offertaSelezionata: OfferteSkeleton = null;
    public bypassRicariche = false;
    public business = false;
    // TODO anche se l'operatore non c'è far smanettare il dealer e poi fargli richiedere il codice
    public contractForm: {
        offerta: {
            id_operatore: number;
            id_pista: number;
            id_famiglia: number;
            id_sottofamiglia: number;
            id_offerta: number;
        };
        pagamento: {};
        cliente: {};
    };

    public validSteps = {
        offerta: false,
        pagamento: false,
        cliente: false
    };

    public me: User;
    busy: Subscription;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private authenticationService: AuthenticationService,
        private dataService: DataService,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.authenticationService.currentUser.subscribe(x => this.me = x);
        this.busy = this.dataService.get('/get-skeletons')
            .subscribe((data: any) => {
                this.skeletons = new ContractSkeleton().fromArray(data.result.skeletons);
                this.customers = new Customer().fromArray(data.result.customers);
                this.venditori = data.result.venditori;
                this.bypassRicariche = data.result.bypassRicariche;
            });

        this.contractForm = {
            offerta: {
                id_operatore: null,
                id_pista: null,
                id_famiglia: null,
                id_sottofamiglia: null,
                id_offerta: null,
            },
            pagamento: {},
            cliente: {}
        };
    }

    syncOfferta(form) {
        this.contractForm.offerta = form;
        this.setupData();
    }

    syncPagamento(form: any) {
        this.contractForm.pagamento = form;
    }

    syncCliente(form: any) {
        // form.documento_path =
        this.contractForm.cliente = form;
    }

    setupData() {
        const offerta = this.contractForm.offerta;
        if (offerta.id_operatore) {
            this.campiPagamento = this.skeletons.find(skeleton => skeleton.id === offerta.id_operatore)
                .pagamenti;
            if (offerta.id_pista) {
                if (offerta.id_famiglia) {
                    this.business = this.skeletons.find(skeleton => skeleton.id === offerta.id_operatore)
                        .piste.find(skeleton => skeleton.id === offerta.id_pista)
                        .famiglie.find(skeleton => skeleton.id === offerta.id_famiglia).business === 1;

                    if (offerta.id_sottofamiglia) {
                        if (offerta.id_offerta) {
                            this.offertaSelezionata = this.skeletons.find(skeleton => skeleton.id === offerta.id_operatore)
                                .piste.find(skeleton => skeleton.id === offerta.id_pista)
                                .famiglie.find(skeleton => skeleton.id === offerta.id_famiglia)
                                .sottofamiglie.find(skeleton => skeleton.id === offerta.id_sottofamiglia)
                                .offerte.find(skeleton => skeleton.id === offerta.id_offerta);

                            this.pagamenti = this.offertaSelezionata.pagamenti;
                            this.infoOfferta = this.offertaSelezionata.info_offerta;
                            this.costiOfferta = this.offertaSelezionata.costi_offerta;

                            return;
                        }
                    }
                }
            }
        }
        this.offertaSelezionata = null;
    }

    getOfferta() {
        return this.offertaSelezionata;
    }

    save() {
        const formData = objectToFormData(
            this.contractForm,
            {indices: true}
        );
        this.busy = this.dataService.post('/inserisci-contratto', formData)
            .subscribe((data: any) => {
                if (data.success) {
                    this.router.navigate(['/contratti/pda', data.result.id]);
                }
            });
    }
}
