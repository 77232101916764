<div class="modal-header">
    <h4 class="modal-title clamp-title" translate>{{product?.nome}}</h4>
    <button (click)="dismissAll()"
            type="button"
            class="close"
            aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <span>
        <button class="btn btn-success btn-sm float-right mb-2" translate (click)="showTemplateModal()">
            Add Template
        </button>
    </span>
    <div class="table-responsive mt-2">
        <table class="table table-sm">
            <thead>
            <tr>
                <th translate>Template</th>
                <th class="text-right" translate>Discount</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let template of product?.templates;index as i">
                <td>
                    <div class="media d-flex align-items-center">
                        <div class="logo-img">
                            <img class="mr-3"
                                 [src]="template?.template"
                                 alt="">
                        </div>
                        <div class="media-body ml-2">
                            <h5 class="mt-0 mb-0"><strong>{{template?.nome}}</strong></h5>
                            <small>
                                <app-rich-text-truncate [text]="template?.info"></app-rich-text-truncate>
                            </small>
                        </div>
                    </div>
                </td>
                <td class="text-right">{{template?.sconto}}%</td>
                <td class="text-right">
                    <button class="btn btn-link">
                        <i class="fa fa-edit text-muted" (click)="showTemplateModal(template)"></i>
                    </button>
                    <button class="btn btn-link" [swal]="deleteProductOptions"
                            (confirm)="deleteTemplate(template.id)">
                        <i class="fa fa-trash text-danger"></i>
                    </button>
                </td>
            </tr>
            <tr *ngIf="product?.templates?.length<=0">
                <td translate class="text-muted" colspan="3">List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #productTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Add template</h4>
        <button (click)="templateModal.dismiss()"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busy">
        <form [formGroup]="productTemplateForm" (ngSubmit)="saveTemplate()">
            <div class="form-row">
                <div class="col-12 col-lg-6 form-group">
                    <label for="name" translate>Name</label>
                    <input type="text" class="form-control" formControlName="name" id="name">
                    <val-errors controlName="name">
                        <ng-template valError="required">
                            <span translate>Name is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-lg-6 form-group">
                    <label for="productImage" translate>Template</label>
                    <div class="custom-file" *ngIf="!templateName">
                        <input id="productImage" accept="image/*" type="file" class="custom-file-input"
                               (change)="handleFileInput($event?.target?.files[0])">
                        <label class="custom-file-label" for="productImage" translate>
                            Upload image...
                        </label>
                    </div>
                    <div *ngIf="templateName">
                        <span class="text-break">{{templateName}}</span>
                        <button class="btn btn-xs btn-warning float-right" type="button"
                                (click)="handleFileRemoveModal()">
                            <i class="fa fa-times m-0 px-1"></i>
                        </button>
                    </div>
                    <val-errors controlName="template">
                        <ng-template valError="required">
                            <span translate>Template is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-lg-6 form-group">
                    <label for="discount" translate>Discount</label>
                    <input type="text" class="form-control" formControlName="discount" id="discount">
                    <val-errors controlName="name">
                        <ng-template valError="required">
                            <span translate>Discount is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-lg-6 form-group">
                    <label for="info" translate>Info</label>
                    <textarea type="text" class="form-control" formControlName="info" id="info"></textarea>
                </div>
                <div class="col-12 mt-2">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="productTemplateForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span></button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
