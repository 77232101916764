<ngb-toast
  *ngFor="let toast of alertService.toasts"
  [class]="toast.classname"
  [autohide]="true"
  [delay]="toast.delay || 5000"
  (hide)="alertService.remove(toast)"
  [header]="toast.header"
>
  {{ toast.text }}
</ngb-toast>
