<ng-select
  [ngClass]="{ 'form-control-sm': small }"
  [items]="data"
  [disabled]="disabled"
  bindValue="id"
  [bindLabel]="bindLabel"
  [(ngModel)]="model"
  (ngModelChange)="onChange.emit($event)"
  [disableControl]="disabled"
  [loading]="loading"
  loadingText="Caricamento..."
></ng-select>
