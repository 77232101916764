import { BaseModel } from "./base-model";

/* tslint:disable:variable-name */
export class State extends BaseModel {
  id: number;
  regione: string;

  fromArray(result: []) {
    const array: State[] = [];
    for (const item of result) {
      array.push(new State(item));
    }
    return array;
  }
}

export class Province extends BaseModel {
    id: number;
    id_regione: number;
    provincia: string;
  
    fromArray(result: []) {
      const array: Province[] = [];
      for (const item of result) {
        array.push(new Province(item));
      }
      return array;
    }
  }

  export class City extends BaseModel {
    id: number;
    id_provincia: number;
    id_regione: number;
    comune: string;
  
    fromArray(result: []) {
      const array: City[] = [];
      for (const item of result) {
        array.push(new City(item));
      }
      return array;
    }
  }
  