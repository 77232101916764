<div [style.min-heihgt.px]="200">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)"
                 size="default"
                 color="#fff"
                 type="square-jelly-box"
                 name="loader-riepilogo"
                 [fullScreen]="false">
        <p style="color: white"> Caricamento... </p>
    </ngx-spinner>

    <table class="table table-sm table-striped mb-0">
        <tbody>
            <tr *ngFor="let item of data">
                <th class="text-right">{{ item.key | uppercase }}</th>
                <td class="text-right">{{ item.value | uppercase }}</td>
            </tr>
        </tbody>
    </table>
</div>
