import {Component, OnInit, ViewChild} from '@angular/core';
import {DataService} from '@services/data.service';
import {Subscription} from 'rxjs';
import {DocumentsModalComponent} from '@app/pages/documents/modal/documents-modal.component';
import {ModalComponent} from '@widgets/modal/modal.component';
import {TableColumnType} from '@app/pages/partials/table/table.component';

@Component({
    selector: 'app-estratti-conto-tim',
    templateUrl: './estratti-conto-tim.component.html',
    styleUrls: ['./estratti-conto-tim.component.css']
})
export class EstrattiContoTimComponent implements OnInit {
    @ViewChild('showDocument') documentModal: DocumentsModalComponent;
    @ViewChild('dettaglioModal') dettaglioModal: ModalComponent;

    busy: Subscription;
    data = {
        columns: [],
        rows: [],
    };
    voci = {
        columns: [
            { key: 'utenza', text: 'Utenza'},
            { key: 'info', text: 'Offerta'},
            { key: 'data_vendita', text: 'Data Vendita', type: TableColumnType.DATE},
            // { key: 'importo', text: 'Compenso', type: TableColumnType.CURRENCY, alignment: 2},
            { key: 'importo', text: 'Totale a pagare', type: TableColumnType.CURRENCY, alignment: 2},
        ],
        rows: [],
    };

    constructor(
        public dataService: DataService) {
    }

    ngOnInit(): void {
        this.busy = this.dataService.get('/estratti-conto-tim')
            .subscribe((data: any) => {
                this.data = data.result;
            });
    }

    onTrigger($event: any) {
        switch ($event.trigger) {
            case 'anteprima':
                this.documentModal.showDocument($event.value);
                break;
            case 'voci':
                this.voci.rows = $event.value;
                this.dettaglioModal.open('xl');
                break;
        }
    }
}
