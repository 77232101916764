import {Component, OnInit} from '@angular/core';
import {DataService} from '@services/data.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {EventoPresidiato} from '@models/evento-presidiato';

@Component({
    selector: 'app-eventi-detail',
    templateUrl: './eventi-detail.component.html',
    styleUrls: ['./eventi-detail.component.scss']
})
export class EventiDetailComponent implements OnInit {
    event: EventoPresidiato;
    busy: Subscription;

    constructor(
        public dataService: DataService,
        public route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.busy = this.dataService.get(`/get-dettaglio-evento?id=${this.route.snapshot.paramMap.get('id')}`)
            .subscribe((data: any) => {
                this.event = data.result;
            });
    }
}
