<div class="card">
    <div class="card-header">
        <h1 class="text-lg">{{title}}</h1>
        <p class="text-sm text-muted">{{description}}</p>
    </div>
    <div class="card-body">
        <img class="img-fluid" [src]="image"/>
    </div>
    <div class="card-footer p-1">
        <svg *ngIf="loading" xmlns="http://www.w3.org/2000/svg" style="margin: auto; display: block;" width="80"
             height="80"
             viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <g transform="translate(20 50)">
                <circle cx="0" cy="0" r="6" fill="#93DBE9">
                    <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline"
                                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s"
                                      repeatCount="indefinite" fill="#93DBE9"></animateTransform>
                </circle>
            </g>
            <g transform="translate(40 50)">
                <circle cx="0" cy="0" r="6" fill="#689CC5">
                    <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline"
                                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s"
                                      repeatCount="indefinite" fill="#689CC5"></animateTransform>
                </circle>
            </g>
            <g transform="translate(60 50)">
                <circle cx="0" cy="0" r="6" fill="#5E6FA3">
                    <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline"
                                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s"
                                      repeatCount="indefinite" fill="#5E6FA3"></animateTransform>
                </circle>
            </g>
            <g transform="translate(80 50)">
                <circle cx="0" cy="0" r="6" fill="#3B4368">
                    <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline"
                                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s"
                                      repeatCount="indefinite" fill="#3B4368"></animateTransform>
                </circle>
            </g>
        </svg>
        <div *ngIf="!loading" class="d-flex">
            <div class="box-hover-dark rounded px-2 py-1 flex-even" (click)="open(modal, 'today')">
                <span class="description-text">oggi</span>
                <h5 class="description-header link"><b>{{details.today}}</b> whatsapp</h5>
            </div>
            <!-- /.col -->
            <div class="box-hover-dark rounded px-2 py-1 flex-even" (click)="open(modal, 'week')">
                <span class="description-text">questa settimana</span>
                <h5 class="description-header link"><b>{{details.week}}</b> whatsapp</h5>
            </div>
            <!-- /.col -->
            <div class="box-hover-dark rounded px-2 py-1 flex-even" (click)="open(modal, 'month')">
                <span class="description-text">questo mese</span>
                <h5 class="description-header link"><b>{{details.month}}</b> whatsapp</h5>
            </div>
            <!-- /.col -->
        </div>
    </div>
</div>

<ng-template #modal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Dettaglio</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="text-center text-lg p-3" *ngIf="loadingModal">
            <svg xmlns="http://www.w3.org/2000/svg" class="my-4" style="margin: 1rem auto 1rem auto; display: block;"
                 width="100" height="100" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <g transform="translate(50 50)">
                    <g transform="translate(-17 -17) scale(0.5)">
                        <g>
                            <animateTransform attributeName="transform" type="rotate" values="0;45" keyTimes="0;1"
                                              dur="0.2s" begin="0s" repeatCount="indefinite"></animateTransform>
                            <path d="M37.3496987939662 -7 L47.3496987939662 -7 L47.3496987939662 7 L37.3496987939662 7 A38 38 0 0 1 31.359972760794346 21.46047782418268 L31.359972760794346 21.46047782418268 L38.431040572659825 28.531545636048154 L28.531545636048154 38.431040572659825 L21.46047782418268 31.359972760794346 A38 38 0 0 1 7.0000000000000036 37.3496987939662 L7.0000000000000036 37.3496987939662 L7.000000000000004 47.3496987939662 L-6.999999999999999 47.3496987939662 L-7 37.3496987939662 A38 38 0 0 1 -21.46047782418268 31.35997276079435 L-21.46047782418268 31.35997276079435 L-28.531545636048154 38.431040572659825 L-38.43104057265982 28.531545636048158 L-31.359972760794346 21.460477824182682 A38 38 0 0 1 -37.3496987939662 7.000000000000007 L-37.3496987939662 7.000000000000007 L-47.3496987939662 7.000000000000008 L-47.3496987939662 -6.9999999999999964 L-37.3496987939662 -6.999999999999997 A38 38 0 0 1 -31.35997276079435 -21.460477824182675 L-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                                  fill="#F6B303"></path>
                        </g>
                    </g>
                    <g transform="translate(0 22) scale(0.4)">
                        <g>
                            <animateTransform attributeName="transform" type="rotate" values="45;0" keyTimes="0;1"
                                              dur="0.2s" begin="-0.1s" repeatCount="indefinite"></animateTransform>
                            <path d="M37.3496987939662 -7 L47.3496987939662 -7 L47.3496987939662 7 L37.3496987939662 7 A38 38 0 0 1 31.359972760794346 21.46047782418268 L31.359972760794346 21.46047782418268 L38.431040572659825 28.531545636048154 L28.531545636048154 38.431040572659825 L21.46047782418268 31.359972760794346 A38 38 0 0 1 7.0000000000000036 37.3496987939662 L7.0000000000000036 37.3496987939662 L7.000000000000004 47.3496987939662 L-6.999999999999999 47.3496987939662 L-7 37.3496987939662 A38 38 0 0 1 -21.46047782418268 31.35997276079435 L-21.46047782418268 31.35997276079435 L-28.531545636048154 38.431040572659825 L-38.43104057265982 28.531545636048158 L-31.359972760794346 21.460477824182682 A38 38 0 0 1 -37.3496987939662 7.000000000000007 L-37.3496987939662 7.000000000000007 L-47.3496987939662 7.000000000000008 L-47.3496987939662 -6.9999999999999964 L-37.3496987939662 -6.999999999999997 A38 38 0 0 1 -31.35997276079435 -21.460477824182675 L-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                                  fill="#656564"></path>
                        </g>
                    </g>
                    <g transform="translate(28 4) scale(0.3)">
                        <g>
                            <animateTransform attributeName="transform" type="rotate" values="0;45" keyTimes="0;1"
                                              dur="0.2s" begin="-0.1s" repeatCount="indefinite"></animateTransform>
                            <path d="M37.3496987939662 -7 L47.3496987939662 -7 L47.3496987939662 7 L37.3496987939662 7 A38 38 0 0 1 31.359972760794346 21.46047782418268 L31.359972760794346 21.46047782418268 L38.431040572659825 28.531545636048154 L28.531545636048154 38.431040572659825 L21.46047782418268 31.359972760794346 A38 38 0 0 1 7.0000000000000036 37.3496987939662 L7.0000000000000036 37.3496987939662 L7.000000000000004 47.3496987939662 L-6.999999999999999 47.3496987939662 L-7 37.3496987939662 A38 38 0 0 1 -21.46047782418268 31.35997276079435 L-21.46047782418268 31.35997276079435 L-28.531545636048154 38.431040572659825 L-38.43104057265982 28.531545636048158 L-31.359972760794346 21.460477824182682 A38 38 0 0 1 -37.3496987939662 7.000000000000007 L-37.3496987939662 7.000000000000007 L-47.3496987939662 7.000000000000008 L-47.3496987939662 -6.9999999999999964 L-37.3496987939662 -6.999999999999997 A38 38 0 0 1 -31.35997276079435 -21.460477824182675 L-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
                                  fill="#FF9824"></path>
                        </g>
                    </g>
                </g>
            </svg>
            <p>Caricamento...</p>
        </div>
        <table *ngIf="!loadingModal" class="table table-sm table-striped">
            <thead>
            <tr>
                <th>Destinatario</th>
                <th>Stato Invio</th>
                <th>Data</th>
                <th>Anteprima</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let mail of mails">
                <td>{{mail.dealer}}</td>
                <td>{{mail.wassenger.deliveryStatus}}</td>
                <td>{{mail.sent | amDateFormat: 'kk:mm DD/MM/YYYY' }}</td>
                <td><a *ngIf="mail.wassenger" href="javascript:void(0)" (click)="viewMail(preview, mail)">Visualizza</a>
                </td>
            </tr>
            <tr *ngIf="mails?.length<=0">
                <td class="text-muted" translate>List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer" *ngIf="mails?.length>0">
        <span><span translate>There are</span> <b> {{ this.pagination.totalCount }}</b>
            {{this.pagination.totalCount <= 1 ? 'messaggio' : 'messaggi'|translate}}.</span>
        <ngb-pagination *ngIf="pagination.pageCount>1"
                        size="sm"
                        [pageSize]="pagination.perPage"
                        [maxSize]="5"
                        [(page)]="pagination.currentPage"
                        [collectionSize]="pagination.totalCount"
                        (pageChange)="refresh()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</ng-template>

<ng-template #preview let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Anteprima</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="wa-device-wrapper">
            <div class="wa-device wa-device-phone" style="margin: 0 auto;">
                <div class="time" style="top: 61px">{{message.sent | amDateFormat: 'kk:mm'}}</div>
                <div class="chat-name">{{message.dealer}}</div>
                <div class="chat-content">
                    <div class="chat-message-box notranslate">
                        <div class="message-box">
                            <ng-container *ngIf="message.wassenger.hasOwnProperty('media')">
                                <div class="image">
                                    <a class="href" [href]="message.wassenger.media.file.url" target="_blank">
                                        <img [src]="message.wassenger.media.file.url" alt="Apri immagine">
                                    </a>
                                </div>
                            </ng-container>
                            <div class="text" [innerHTML]="message.wassenger.message">

                            </div>
                            <div class="check"><img src="https://ewr1.vultrobjects.com/mgroup/images/msg-check.svg"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
