import {Component, OnInit} from '@angular/core';
import {DashboardService} from "@services/dashboard.service";
import {OperatorService} from "@services/operator.service";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-gestione-stand',
    templateUrl: './gestione-stand.component.html',
    styleUrls: ['./gestione-stand.component.css']
})
export class GestioneStandComponent implements OnInit {
    data = {
        columns: [],
        rows: [],
    };
    busy: Subscription;
    deleted = '0';

    constructor(
        public route: ActivatedRoute,
        private operatorService: OperatorService
    ) {
        this.deleted = this.route.snapshot.paramMap.get('deleted');
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.deleted = params['deleted'];
            this.refresh();
        });
    }

    refresh() {
        this.busy = this.operatorService.get(`/lista-presidi?deleted=${this.deleted}`)
            .subscribe((data) => {
                this.data = data.result;
            });
    }

}
