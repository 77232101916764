<app-master-header [ngBusy]="busy"
                   cardClass="bg-gradient-dark"
                   image="assets/img/flats/dollar.png"
                   title="Gestione fatturazione"
>
    <div class="row align-items-end">
        <div class="col-4">
            <label>Seleziona il segmento</label>
            <div class="form-group form-group-icon form-group-dark mb-0">
                <i class="fas fa-list-alt"></i>
                <select class="form-control" [(ngModel)]="this.segmento">
                    <ng-container *ngIf="this.startup">
                        <option *ngFor="let seg of this.startup.segmenti" [value]="seg.value">{{seg.label}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
        <div class="col-4">
            <label>Seleziona l'operatore</label>
            <div class="form-group form-group-icon form-group-dark mb-0">
                <i class="fas fa-list-alt"></i>
                <select class="form-control" [(ngModel)]="this.operatore">
                    <ng-container *ngIf="this.startup">
                        <option *ngFor="let seg of this.startup.operatori" [value]="seg.value">{{seg.label}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
        <div class="col-4">
            <label>Seleziona periodo</label>
            <div class="form-group form-group-icon form-group-dark mb-0">
                <i class="far fa-calendar-alt"></i>
                <select class="form-control" [(ngModel)]="this.periodo">
                    <ng-container *ngIf="this.startup">
                        <option *ngFor="let per of this.startup.periodi" [value]="per.value">{{per.label}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
    </div>
</app-master-header>

<div class="row">
    <div class="col-4">
        <div class="card bg-gradient-light">
            <div class="card-body row">
                <p class="col-6 lead font-weight-bold">Hai selezionato</p>
                <p class="col-6 lead">{{ selectedVoices }} voci</p>
            </div>
        </div>
    </div>
    <div class="col-4">
        <div class="card bg-gradient-light">
            <div class="card-body row">
                <p class="col-6 lead font-weight-bold">Per un totale di</p>
                <p class="col-6 lead">{{ totalToInvoice | currency: '€ '}} </p>
            </div>
        </div>
    </div>
    <div class="col-4">
        <div class="card bg-gradient-light">
            <div class="card-body py-3">
                <button class="btn btn-block btn-success" [disabled]="selectedVoices === 0" (click)="creaProforma()">Crea Proforma</button>
            </div>
        </div>
    </div>
</div>

<app-modal [title]="modal.title" #messageModal>
    <div class="text-center">
        <i class="far mb-2" style="font-size: 3rem;" [ngClass]="modal.icon"></i>
        <h1 class="display-6">{{modal.message}}</h1>
        <p class="lead">{{modal.description}}</p>
    </div>
</app-modal>

<div class="row">
    <div class="col-12">
        <app-master-card
                title="Agenti"
                bodyClass="p-0"
                image="assets/img/flats/employee.png"
        >
            <hot-table
                    hotId="hot"
                    [data]="rows"
                    licenseKey="non-commercial-and-evaluation"
                    stretchH="all"
                    [colHeaders]="cols"
                    [rowHeaders]="true"
                    [height]="500"
                    [dropdownMenu]="true"
                    [filters]="true"
                    [settings]="settiings"
                    [contextMenu]="contextMenuFirstLevel">
                <!--                <hot-column *ngFor="let col of cols"-->
                <!--                            [data]="col.id"-->
                <!--                            [title]="col.label"-->
                <!--                            [readOnly]="col.readonly"-->
                <!--                            [className]="col.className"-->
                <!--                            [type]="col.type"-->
                <!--                ></hot-column>-->
            </hot-table>
        </app-master-card>
    </div>
</div>
