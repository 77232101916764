import {BaseModel} from '@models/base-model';
import {Prize, PrizeListPagination} from '@models/prize';
import {CampaignSegmentListPagination} from '@models/campaign-segment';
import {MetaPagination} from '@models/meta-pagination';
import {DealerView} from '@models/dealer-view';

export class Campaign extends BaseModel {
    id: number;
    operator_id: number;
    title: string;
    start_date: string;
    end_date: string;
    segments: CampaignSegmentListPagination;
    prizes: PrizeListPagination;
    used_prizes: UsedPrizesListPagination;

    fromArray(result: []) {
        const array: Campaign[] = [];
        for (const item of result) {
            array.push(new Campaign(item));
        }
        return array;
    }
}

export class CampaignListPagination extends BaseModel {
    data: Campaign[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class UsedPrizesListPagination extends BaseModel {
    data: UsedPrize[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class UsedPrize extends BaseModel {
    id: number;
    campaign_id: number;
    prize_id: number;
    dealer_id: number;
    created_at: string;
    prize: Prize;
    dealer: DealerView;

    fromArray(result: []) {
        const array: UsedPrize[] = [];
        for (const item of result) {
            array.push(new UsedPrize(item));
        }
        return array;
    }
}
