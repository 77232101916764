<app-master-header [ngBusy]="busy"
                   cardClass="bg-gradient-1"
                   image="assets/img/flats/user.png"
                   title="Campagne Outbound"
>
    <div class="row align-items-end">
        <div class="col-12">
            <label>Seleziona la campagna da analizzare</label>
            <div class="form-group form-group-icon mb-0">
                <i class="fa fa-search"></i>
                <select class="form-control" [(ngModel)]="this.campagna">
                    <ng-container *ngIf="this.startup">
                        <option *ngFor="let per of this.startup" [value]="per.id">{{per.nome}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
    </div>
</app-master-header>

<app-master-subheader cardClass="bg-gradient-light" [url]="urlSubheader"></app-master-subheader>

<div class="row">
    <div class="col-6">
        <app-master-card
                bodyClass="p-0"
                title="Operatori"
                image="assets/img/flats/support.png"
        >
            <app-autoload-table
                    [small]="true"
                    [hideFooterDescription]="true"
                    [url]="urlOperatori"
            ></app-autoload-table>
        </app-master-card>
        <app-master-card
                bodyClass="p-0"
                title="Negozi chiamati"
                image="assets/img/flats/phone-receiver.png"
        >
            <app-autoload-table
                    [small]="true"
                    [hideFooterDescription]="true"
                    [url]="urlNegozi"
            ></app-autoload-table>
        </app-master-card>
    </div>
    <div class="col-6">
        <app-master-card
                bodyClass="p-0"
                title="Dettaglio Campagna"
                image="assets/img/flats/research.png"
        >
            <app-autoload-table
                    [url]="urlDettaglio"
                    [hideFooter]="true"
                    [hideHeader]="true"
                    [pageSize]="100"
            ></app-autoload-table>
        </app-master-card>
    </div>
</div>
