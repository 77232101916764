
<!-- Table -->
<div [ngBusy]="busy">
    <div class="row">
        <div class="col-12">
            <app-table tableTitle="Classifica Stand Kena"
                    [rows]="classifica" [columns]="columns"
            >
            </app-table>
        </div>
    </div>
</div>