<div class="card bg-gradient-light">
    <div class="card-header">
        <div class="row">
            <div class="col-lg-auto col-12">
                <h1 class="display-6 mb-0 mb-2" translate>
                    Timeline
                </h1>
            </div>
            <div class="col-lg-3 col-12">
                <input id="date" class="form-control form-control-sm" type="text" daterangepicker
                       [(ngModel)]="selectedDate" (selected)="loadNewDate($event?.start, $event?.end)"/>
            </div>
            <div class="col-lg-8 col-12 justify-content-lg-end" *show="['operator', 'agent']">
                <button class="btn btn-success btn-sm float-right" type="button" translate
                        (click)="showReportModal()">
                    Add Report
                </button>
            </div>
        </div>
    </div>
    <div class="card-body">
        <ngx-spinner
                bdColor="rgba(0, 0, 0, 0.8)"
                size="default"
                color="#fff"
                type="square-jelly-box"
                name='timeline'
                [fullScreen]="false"
        >
        </ngx-spinner>
        <span class="text-muted" *ngIf="reports?.length<=0">No reports</span>
        <div class="timeline" *ngIf="reports?.length>0">
            <ng-container *ngFor="let date of dates">
                <div class="time-label lead"><span
                        class="bg-gradient-success">{{date|amDateFormat:localDateFormat}}</span>
                </div>
                <div *ngFor="let report of reports" [ngClass]="{'d-none':report.date != date}">
                    <i class="fas fa-comment bg-gradient-info" *ngIf="report.date == date"></i>
                    <div class="elevation-1 timeline-item" *ngIf="report.date == date">
                        <h3 class="timeline-header pb-4 pb-lg-0">
                            <strong><em>{{report?.user_name}}</em></strong>
                            <span class="float-right">{{report?.category?.name}}</span>
                        </h3>
                        <div class="timeline-body">
                            <span class="text-muted">
                               <app-rich-text-truncate [text]="report?.note"
                                                       [charLimit]="10000"></app-rich-text-truncate>
                            </span>
                        </div>
                        <div class="timeline-footer border-top">
                            <div class="row align-items-center justify-content-between">
                                <div class="col">
                                    <p class="mb-0 text-muted mr-2" style="font-size: 14px;">
                                        <i class="far fa-clock mr-2 text-info"></i>
                                        <span>{{report?.created_at|amDateFormat:localTimeFormat}}</span></p>
                                </div>
                                <div class="col text-right" *show="['operator', 'agent']">
                                    <button class="btn btn-link btn-sm" (click)="showReportModal(report)"
                                            *ngIf="currentUser == report?.user_id"
                                    >
                                        <i class=" fa fa-edit text-muted"></i>
                                    </button>
                                    <button class="btn btn-link btn-sm" [swal]="deleteDocumentOptions"
                                            *ngIf="currentUser == report?.user_id"
                                            (confirm)="deleteReport(report.id)">
                                        <i class="fa fa-trash text-danger"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="card-footer" *ngIf="reports?.length>0">
    <span><span translate>There are</span><b> {{ this.reportPagination?.totalCount }}</b>
        {{this.reportPagination.totalCount <= 1 ? 'report' : 'reports'|translate}}.</span>
        <ngb-pagination *ngIf="reportPagination.pageCount>1"
                        size="sm"
                        [pageSize]="reportPagination.perPage"
                        [maxSize]="10"
                        [(page)]="reportPagination.currentPage"
                        [collectionSize]="reportPagination.totalCount"
                        (pageChange)="loadReports()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>

<ng-template #reportFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Add Report</h4>
        <button (click)="modal.close('Ok click')"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busy">
        <form (ngSubmit)="saveReport()" [formGroup]="reportForm">
            <div class="form-row">
                <div class="col-12">
                    <label for="category" translate>Category</label>
                </div>
                <div class="col-12 col-lg form-group">
                    <ng-select formControlName="category_id" [items]="categories" bindLabel="name" id="category"
                               bindValue="id" [searchable]="true" (search)="loadCategories($event?.term)"
                               [loading]="categoriesLoading"></ng-select>
                    <val-errors controlName="category_id">
                        <ng-template valError="required">
                            <span translate>Category is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-lg form-group">
                    <button type="button" class="btn btn-secondary btn-block" translate (click)="showCategoryModal()">
                        Add New Category
                    </button>
                </div>
                <div class="col-12 form-group">
                    <label for="note" translate>Note</label>
                    <editor apiKey="1nilu7q8vcp0ntuviwq5nhcskmj17c6awfhpbnr0hhv4smdw" id="note" [init]="defaultConfig" formControlName="note"></editor>
                    <val-errors controlName="note">
                        <ng-template valError="required">
                            <span translate>Note is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="reportForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #categoryFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Add Category</h4>
        <button (click)="modal.close('Ok click')"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busyCategory">
        <form (ngSubmit)="saveReportCategory()" [formGroup]="categoryForm">
            <div class="form-row">
                <div class="col-12  form-group">
                    <label for="name" translate>Name</label>
                    <input formControlName="name" id="name" type="text" class="form-control">
                    <val-errors controlName="name">
                        <ng-template valError="required">
                            <span translate>Name is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="categoryForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
