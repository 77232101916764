<div class="card bg-gradient-light">
    <form (ngSubmit)="loadBankAccounts()">
        <div class="card-header">
            <h1 class="card-title" translate>Filters</h1>
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-12 col-lg-4">
                    <label for="name" translate>Bank name</label>
                    <input id="name" name="name" [(ngModel)]="filter.bank_name"
                           type="text"
                           class="form-control form-control-sm"
                    />
                </div>
                <div class="form-group col-12 col-lg-4">
                    <label for="number" translate>Account number</label>
                    <input id="number" name="number" [(ngModel)]="filter.account_number"
                           type="text"
                           class="form-control form-control-sm"
                    />
                </div>
                <div class="form-group col-12 col-lg-4">
                    <label for="type" translate>Type</label>
                    <ng-select [(ngModel)]="filter.file_type" [items]="types" bindLabel="name"
                               bindValue="key" class="form-control-sm" id="type" name="file_type"></ng-select>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button class="btn btn-dark btn-sm mr-2" type="button" (click)="resetFilter()" translate>
                Reset
            </button>
            <button
                    class="btn btn-primary btn-sm"
                    type="submit" translate
            >
                Filter
            </button>
        </div>
    </form>
</div>
<div class="card">
    <div class="card-header">
        <div class="row">
            <div class="col-lg-10 col-12">
                <h4 class="mb-0" translate>Bank accounts</h4>
            </div>
            <div class="col-lg-2 col-12">
                <button class="btn btn-success btn-sm float-right" type="button" translate (click)="showModal()">
                    Add Bank Account
                </button>
            </div>
        </div>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th translate>Bank name</th>
                <th translate>Account number</th>
                <th translate>Type</th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="table-bank-accounts"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let bankAccount of bankAccounts">
                <td>{{bankAccount?.bank_name}}</td>
                <td>{{bankAccount?.account_number}}</td>
                <td translate>{{bankAccount?.file_type_name}}</td>
            </tr>
            <tr *ngIf="bankAccounts?.length<=0">
                <td class="text-muted" colspan="3" translate>List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="bankAccounts?.length>0">
    <span><span translate>There are</span><b> {{ this.bankAccountsPagination.totalCount }}</b>
        {{this.bankAccountsPagination.totalCount <= 1 ? 'account' : 'accounts'|translate}}.</span>
        <ngb-pagination *ngIf="bankAccountsPagination.pageCount>1"
                        size="sm"
                        [pageSize]="bankAccountsPagination.perPage"
                        [maxSize]="10"
                        [(page)]="bankAccountsPagination.currentPage"
                        [collectionSize]="bankAccountsPagination.totalCount"
                        (pageChange)="loadBankAccounts()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>

<ng-template #bankAccountFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Bank Account</h4>
        <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="dismissModal()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busy">
        <form [formGroup]="bankAccountForm" (ngSubmit)="saveBankAccount()">
            <div class="form-row">
                <div class="col-12 form-group">
                    <label translate for="bankName">Bank name</label>
                    <input class="form-control" id="bankName" formControlName="bank_name">
                    <val-errors controlName="bank_name">
                        <ng-template valError="required">
                            <span translate>Bank name is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-xl-6 form-group">
                    <label translate for="accountNumber">Account number</label>
                    <input class="form-control" id="accountNumber" formControlName="account_number">
                    <val-errors controlName="account_number">
                        <ng-template valError="required">
                            <span translate>Account number is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-xl-6 form-group">
                    <label translate for="fileType">File type</label>
                    <ng-select [items]="types" bindValue="key" bindLabel="name" formControlName="file_type"
                               id="fileType"></ng-select>
                    <val-errors controlName="file_type">
                        <ng-template valError="required">
                            <span translate>File type is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 mt-4">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="bankAccountForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
