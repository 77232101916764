import {AfterViewInit, Component, OnInit, Renderer2, ViewChild} from '@angular/core';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit {
    public sidebarMenuOpened = true;
    @ViewChild('contentWrapper', {static: false}) contentWrapper;

    constructor(
        private renderer: Renderer2
    ) {
    }

    ngOnInit() {

    }

    mainSidebarHeight(height) {
        // this.renderer.setStyle(
        //   this.contentWrapper.nativeElement,
        //   'min-height',
        //   height - 114 + 'px'
        // );
    }

    toggleMenuSidebar() {
        if (this.sidebarMenuOpened) {
            this.renderer.removeClass(document.body, 'sidebar-open');
            this.renderer.addClass(document.body, 'sidebar-collapse');
            this.sidebarMenuOpened = false;
        } else {
            this.renderer.removeClass(document.body, 'sidebar-collapse');
            this.renderer.addClass(document.body, 'sidebar-open');
            this.sidebarMenuOpened = true;
        }
    }

    ngAfterViewInit(): void {

    }
}
