<div *ngIf="featuredVideo" class="card bg-g-violet-purple elevation-1 pt-2 h-100" style="border-radius: 10px;"
    [ngBusy]="busy">
    <div class="card-body text-white">
        <div class="row align-items-center">
            <div class="col-4 offset-2">
                <div class="card bg-gradient-dark">
                    <div class="card-body">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe allowFullScreen="allowFullScreen" class="embed-responsive-item"
                                [src]="featuredVideo?.video_path | safe" allowtransparency="true"
                                frameborder="0"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <h1 class="display-5 clamp-title">Ultimo video</h1>
                <h1 class="display-6 clamp-description">{{ featuredVideo.title }}</h1>
                <p class="lead">{{ featuredVideo.created_at | amDateFormat: 'DD/MM/YYYY' }}</p>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showActionsButtons; else withoutButtons" class="row">
    <div class="col-xl-4 col-md-6 col-12" *ngFor="let category of categories" [(dragDropList)]="categories"
        [trigger]="trigger" (dragDropListChange)="onDragChange($event)">
        <div class="card bg-gradient-white elevation-1 category zoom">
            <i #trigger class="fa fa-arrows-alt text-primary drag-element p-2"></i>
            <img [src]="category.logo" class="category-icon" />
            <div class="card-body row align-content-end" (click)="showList(category)">
                <div class="col-12">
                    <h1 class="display-5 clamp-title">{{ category.title }}</h1>
                    <p class="display-6 clamp-description">
                        {{ category.description }}
                    </p>
                    <div class="mt-3">
                        <button class="float-left btn btn-xs btn-success mr-2"
                            (click)="showCreateVideoModal(category); $event.stopPropagation();">
                            <i class="fa fa-plus d-inline-block d-lg-none"></i><span
                                class="d-none d-lg-inline-block">Aggiungi Video</span>
                        </button>
                        <button class="float-left btn btn-xs btn-default mr-2"
                            (click)="showUpdateCategoryModal(category); $event.stopPropagation();">
                            Aggiornare
                        </button>
                        <button class="float-left btn btn-xs btn-danger" [swal]="videoCategoryConfirmDeleteOptions"
                            (confirm)="deleteCategory(category)">
                            Elimina
                        </button>
                        <span class="float-right text-muted">
                            {{ category.video_count }} video
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-4 col-md-6 col-12 new-cat-card" (click)="showCreateCategoryModal()">
        <div class="card bg-gradient-white elevation-1 category zoom">
            <div class="card-body row align-content-center text-center">
                <div class="col-12 icon-add">
                    <i class="fa fa-plus fa-3x"></i>
                </div>
                <div class="col-12 text-add">
                    <span class="h4">Aggiungi Categoria</span>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #withoutButtons>
    <div class="row">
        <div class="col-xl-4 col-md-6 col-12" *ngFor="let category of categories">
            <div class="card bg-gradient-white elevation-1 category zoom">
                <img [src]="category.logo" class="category-icon" />
                <div class="card-body row align-content-end" (click)="showList(category)">
                    <div class="col-12">
                        <h1 class="display-5 clamp-title">{{ category.title }}</h1>
                        <p class="display-6 clamp-description">
                            {{ category.description }}
                        </p>
                        <div class="mt-3">
                            <span class="float-right text-muted">
                                {{ category.video_count }} video
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #videoListTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title">{{ categoryModalTitle }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busyVideoListModal">
        <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" [closeOthers]="true">
            <ngb-panel *ngFor="let video of selectedCategory.videos" [title]="video.title">
                <ng-template ngbPanelContent>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe allowFullScreen="allowFullScreen" class="embed-responsive-item"
                            [src]="video.video_path | safe" allowtransparency="true" frameborder="0"></iframe>
                    </div>
                    <p *ngIf="video.description" class="text-muted my-3">
                        {{ video.description}}
                    </p>
                    <div class="row">
                        <div class="media col-6">
                            <img src="/assets/img/user2-160x160.jpg" class="mr-2 img-circle img-fluid"
                                alt="{{ video.created_by.first_name + ' ' + video.created_by.last_name}}"
                                style="height:50px">
                            <div class="media-body">
                                <h5 class="mt-1 mb-0">
                                    {{ video.created_by.first_name + ' ' + video.created_by.last_name}}
                                </h5>
                                <p class="text-muted">
                                    Creato a {{ video.created_at | amDateFormat: 'Do MMMM YYYY, h:mm:ss'}}
                                </p>
                            </div>
                        </div>
                        <div class="col-6">
                            <div *show="['operator', 'master']" class="mt-3">
                                <button class="float-right btn btn-xs btn-white text-danger"
                                    [swal]="videoConfirmDeleteOptions" (confirm)="deleteVideo(video)">
                                    Elimina
                                </button>
                                <button class="float-right btn btn-xs btn-white" (click)="showUpdateVideoModal(video)">
                                    Aggiornare
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
        <div *ngIf="selectedCategory.video_count == 0" class="">
            <p class="text-center py-4">
                Nessun video in questa categoria. Puoi creare un nuovo video facendo clic su
                <button class="btn btn-xs btn-success" (click)="showCreateVideoModal(selectedCategory)">
                    <i class="fa fa-plus"></i><span class="d-none d-lg-inline-block">Aggiungi Video</span>
                </button>
            </p>
        </div>
    </div>
</ng-template>

<ng-template #videoFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title">{{ modalTitle }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busyVideoFormModal">
        <form [formGroup]="videoForm" *ngIf="selectedVideo">
            <div class="row">
                <div class="col-8">
                    <div class="form-group">
                        <label class="lead">Video Path</label>
                        <input [(ngModel)]="selectedVideo.video_path" formControlName="video_path" type="text"
                            class="form-control" />
                        <val-errors controlName="video_path">
                            <ng-template valError="required">
                                L'Video Path è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label class="lead">Disponibile Dal - Al</label>
                        <input #availabilityRangePicker [(ngModel)]="selectedVideo.availability_range"
                            formControlName="availability_range" type="text" class="form-control" daterangepicker
                            (selected)="setSelectedVideoAvailability($event.start, $event.end)" />
                        <val-errors controlName="availability_range">
                            <ng-template valError="required">
                                L'Video disponibile è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label class="lead">Titolo</label>
                        <input [(ngModel)]="selectedVideo.title" formControlName="title" type="text"
                            class="form-control" />
                        <val-errors controlName="title">
                            <ng-template valError="required">
                                L'Titolo è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label class="lead">Descrizione</label>
                        <textarea [(ngModel)]="selectedVideo.description" formControlName="description"
                            class="form-control" rows="3"></textarea>
                        <val-errors controlName="description">
                            <ng-template valError="required">
                                L'Descrizione è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <button class="btn btn-success btn-block" [disabled]="!this.videoForm.valid"
                        (click)="saveVideo(selectedVideo)">
                        <i class="fa fa-check-circle mr-2"></i>Save
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #categoryFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title">{{ categoryModalTitle }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busyCategoryFormModal">
        <form [formGroup]="videoCategoryForm">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="lead">Titolo</label>
                        <input [(ngModel)]="selectedCategory.title" formControlName="title" type="text"
                            class="form-control" />
                        <val-errors controlName="title">
                            <ng-template valError="required">
                                L'Titolo è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-5 pr-3 pr-lg-0">
                    <div class="form-group">
                        <label class="lead">Collegamento del logo</label>
                        <input [disableControl]="disabledLogoInput" [(ngModel)]="selectedCategory.logo"
                            formControlName="logo" type="text" class="form-control" />
                        <val-errors controlName="logo">
                            <ng-template valError="required">
                                L'Logo è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-2 text-left text-lg-center px-3 px-lg-0 my-auto">
                    <div class="pt-0 pt-lg-3">
                        <span>OR</span>
                    </div>
                </div>
                <div class="col-12 col-lg-5 pl-3 pl-lg-0">
                    <div class="form-group">
                        <label class="lead">Carica il logo</label>
                        <div class="custom-file">
                            <input [disableControl]="disabledLogoFileInput" formControlName="logo_file" type="file"
                                class="custom-file-input" id="validatedCustomFile"
                                (change)="handleFileInput($event.target.files)">
                            <label class="custom-file-label" for="validatedCustomFile">{{ fileName }}</label>
                        </div>
                        <val-errors controlName="logo_file">
                            <ng-template valError="required">
                                L'Logo è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label class="lead">Descrizione</label>
                        <textarea [(ngModel)]="selectedCategory.description" formControlName="description"
                            class="form-control" rows="3"></textarea>
                        <val-errors controlName="description">
                            <ng-template valError="required">
                                L'Descrizione è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <button class="btn btn-success btn-block" [disabled]="!this.videoCategoryForm.valid"
                        (click)="saveCategory(selectedCategory)">
                        <i class="fa fa-check-circle mr-2"></i>Save
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>