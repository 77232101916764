import {Component, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {OperatorService} from "@services/operator.service";
import 'handsontable/languages/de-DE';
import {HotTableRegisterer} from "@handsontable/angular";
import Handsontable from "handsontable";
import {ModalComponent} from "@widgets/modal/modal.component";

@Component({
    selector: 'app-gestione-fatturazione',
    templateUrl: './gestione-fatturazione.component.html',
    styleUrls: ['./gestione-fatturazione.component.css']
})
export class GestioneFatturazioneComponent implements OnInit {
    @ViewChild('messageModal') messageModal: ModalComponent;

    modal = {
        title: '',
        icon: '',
        message: '',
        description: '',
    };

    private hotRegisterer = new HotTableRegisterer();
    busy: Subscription;

    zoom = {
        agente: null,
        voce: null
    };

    _periodo = null;
    _segmento = null;
    _operatore = null;
    startup: any;

    rows: any[][];
    cols: [];

    data: any;
    contextMenuFirstLevel = {
        items: {
            'detail': {
                name: 'Visualizza Dettaglio',
                callback: (key, selection, clickEvent) => {
                    this.setAgente(selection[0].start.row);
                }
            }
        }
    }

    contextMenuSecondLevel = {
        items: {
            'detail': {
                name: 'Visualizza Dettaglio',
                callback: (key, selection, clickEvent) => {
                    this.setVoce(selection[0].start.row);
                }
            },
            'back': {
                name: 'Torna indietro',
                callback: (key, selection, clickEvent) => {
                    this.resetAgente();
                    this.hotRegisterer.getInstance('hot').updateSettings({contextMenu: this.contextMenuFirstLevel});
                }
            }
        }
    }

    contextMenuThirdLevel = {
        items: {
            'back': {
                name: 'Torna indietro',
                callback: (key, selection, clickEvent) => {
                    this.resetVoce();
                    this.hotRegisterer.getInstance('hot').updateSettings({contextMenu: this.contextMenuSecondLevel});
                }
            }
        }
    }
    settiings: Handsontable.GridSettings = {
        afterOnCellMouseDown: (event, coords, td) => this.dblClick(event, coords, td),
        afterChange: (changes) => this.update(changes),
        cells: (row, col) => this.cells(row, col)
    };

    get totalToInvoice() {
        if (!this.rows) {
            return 0;
        }
        return this.rows.filter(i => i[0]).reduce((a, b) => a + b[4], 0);
    };

    get selectedVoices() {
        if (!this.rows) {
            return 0;
        }
        return this.rows.filter(i => i[0]).length;
    };

    set segmento(value: string) {
        if (!this._segmento) {
            this._segmento = value;
        } else {
            this._segmento = value
            this.refresh();
        }
    }

    get segmento() {
        return this._segmento;
    }

    set operatore(value: string) {
        if (!this._operatore) {
            this._operatore = value;
        } else {
            this._operatore = value
            this.refresh();
        }
    }

    get operatore() {
        return this._operatore;
    }

    set periodo(value: string) {
        if (!this._periodo) {
            this._periodo = value;
        } else {
            this._periodo = value
            this.refresh();
        }
    }

    get periodo() {
        return this._periodo;
    }

    constructor(private operatorService: OperatorService) {
    }

    ngOnInit() {
        this.busy = this.operatorService.get('/invoice-period')
            .subscribe((data) => {
                this.startup = data.result;
                this.periodo = this.startup.periodo;
                this.segmento = this.startup.segmento;
                this.operatore = this.startup.operatore;

                this.refresh();
            });
    }

    refresh() {
        this.busy = this.operatorService.get(`/gestione-fatturazione?segmento=${this.segmento}&periodo=${this.periodo}&operatore=${this.operatore}`)
            .subscribe((data) => {
                this.data = data.result;

                this.rows = this.convertRowsToData(this.data.rows, this.data.cols);
                this.cols = this.convertColsToData(this.data.cols);
                this.hotRegisterer.getInstance('hot').updateSettings({
                    colHeaders: this.cols,
                    columns: this.convertColsToStructure(this.data.cols)
                });
            });
    }

    setAgente(i) {
        this.zoom.agente = this.data.rows[i];

        this.rows = this.convertRowsToData(this.zoom.agente.voci, this.zoom.agente.cols);
        this.cols = this.convertColsToData(this.zoom.agente.cols);

        this.hotRegisterer.getInstance('hot').loadData(this.rows);
        this.hotRegisterer.getInstance('hot').updateSettings({
            colHeaders: this.cols,
            columns: this.convertColsToStructure(this.zoom.agente.cols)
        });

        this.hotRegisterer.getInstance('hot').updateSettings({contextMenu: this.contextMenuSecondLevel});
    }

    setVoce(i) {
        this.zoom.voce = this.zoom.agente.voci[i];

        this.rows = this.convertRowsToData(this.zoom.voce.dettaglio, this.zoom.voce.cols);
        this.cols = this.convertColsToData(this.zoom.voce.cols);

        this.hotRegisterer.getInstance('hot').loadData(this.rows);
        this.hotRegisterer.getInstance('hot').updateSettings({
            colHeaders: this.cols,
            columns: this.convertColsToStructure(this.zoom.voce.cols)
        });
        this.hotRegisterer.getInstance('hot').updateSettings({contextMenu: this.contextMenuThirdLevel});
    }

    resetAgente() {
        this.zoom.agente = null;

        this.rows = this.convertRowsToData(this.data.rows, this.data.cols);
        this.cols = this.convertColsToData(this.data.cols);
        this.hotRegisterer.getInstance('hot').updateSettings({
            colHeaders: this.cols,
            columns: this.convertColsToStructure(this.data.cols)
        });
    }

    resetVoce() {
        this.zoom.voce = null;

        this.rows = this.convertRowsToData(this.zoom.agente.voci, this.zoom.agente.cols);
        this.cols = this.convertColsToData(this.zoom.agente.cols);

        this.hotRegisterer.getInstance('hot').loadData(this.rows);
        this.hotRegisterer.getInstance('hot').updateSettings({
            colHeaders: this.cols,
            columns: this.convertColsToStructure(this.zoom.agente.cols)
        });
    }

    convertRowsToData(rows: [], cols: []) {
        let keys = cols.map(i => i['id']);
        return rows.map(r => {
            let item = [];
            keys.forEach(c => item.push(r[c]));
            return item;
        });
    }

    convertColsToData(cols: any) {
        return cols.map(i => i['label']);
    }

    convertColsToStructure(cols: any) {
        return cols.map(i => {
            return {type: i['type'], readOnly: i['readonly'], className: i['className']}
        });
    }

    dblClick(event: any, coords: any, td: any) {
        var now = new Date().getTime();
        // check if dbl-clicked within 1/5th of a second. change 200 (milliseconds) to other value if you want
        if (!(td.lastClick && now - td.lastClick < 300)) {
            td.lastClick = now;
            return; // no double-click detected
        }

        if (!this.zoom.agente) {
            this.setAgente(coords.row);
        } else if (!this.zoom.voce) {
            this.setVoce(coords.row);
        }
    }

    update(changes: Handsontable.CellChange[]) {
    }

    firstRowRenderer(instance, td, row, col, prop, value, cellProperties) {
        if (typeof value === "boolean") {
            Handsontable.renderers.CheckboxRenderer.apply(this, arguments);
        } else {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
        }
        if (typeof instance.getDataAtCell(row, 0) === "boolean" && instance.getDataAtCell(row, 0)) {
            td.style.fontWeight = 'bold';
            td.style.color = 'green';
            td.style.background = '#CEC';
        }

        if (typeof instance.getDataAtCell(row, 1) === "boolean" && instance.getDataAtCell(row, 1)) {
            td.style.fontWeight = 'bold';
            td.style.color = 'red';
            td.style.background = '#ff9a9a';
        }
    }

    cells(row: number, col: number) {
        var cellProperties = {
            renderer: undefined
        };

        cellProperties.renderer = this.firstRowRenderer; // uses function directly

        return cellProperties;
    }

    creaProforma() {
        const formData = new FormData();
        formData.append('segmento', this.segmento);
        formData.append('periodo', this.periodo);
        formData.append('operatore', this.operatore);
        formData.append('ids', JSON.stringify(this.rows.filter(i => i[0]).map(i => i[2])));

        this.busy = this.operatorService.post(`/crea-proforma`, formData)
            .subscribe((data) => {
                if (data.success) {
                    this.showModal('Ottimo', 'fa-check-circle text-success',
                        'Operazione completata',
                        'I proforma che hai selezionato sono stati creati correttamente.');
                }
                else {
                    this.showModal('Peccato', 'fa-times-circle text-danger',
                        'Errore',
                        'I proforma che hai selezionato non sono stati creati...');
                }
            });
    }

    showModal(title, icon, message, description) {
        this.modal.title = title;
        this.modal.icon = icon;
        this.modal.message = message;
        this.modal.description = description;
        this.messageModal.open();
    }
}
