<div class="modal-header">
    <h4 class="modal-title clamp-title" translate>{{ event?.nome }}</h4>
    <button (click)="dismissModal()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [ngBusy]="busy">
    <ul class="list-group list-group-flush">
        <li class="list-group-item px-0">
            <strong translate>Company name</strong>
            <span class="float-right">{{ event?.dealer?.company_name }}</span>
        </li>
        <li class="list-group-item px-0">
            <strong translate>Address</strong>
            <span class="float-right">{{ event?.indirizzo }}</span>
        </li>
        <li class="list-group-item px-0">
            <strong translate>Common</strong>
            <span class="float-right">{{ event?.comune }}</span>
        </li>
        <li class="list-group-item px-0">
            <strong translate>Province</strong>
            <span class="float-right">{{ event?.provincia }}</span>
        </li>
        <li class="list-group-item px-0">
            <strong translate>From date</strong>
            <span class="float-right">{{ event?.from_date | amDateFormat: localFormat }}</span>
        </li>
        <li class="list-group-item px-0">
            <strong translate>To date</strong>
            <span class="float-right">{{ event?.to_date | amDateFormat: localFormat }}</span>
        </li>
        <li class="list-group-item px-0">
            <strong translate>Event type</strong>
            <span class="float-right">{{ event?.evento_tipologia }}</span>
        </li>
        <li class="list-group-item px-0">
            <strong translate>Event set-ups</strong>
            <span class="float-right">{{ event?.evento_allestimenti }}</span>
        </li>
        <li class="list-group-item px-0">
            <strong translate>Preparation notes</strong>
            <span class="float-right">{{ event?.note_allestimento }}</span>
        </li>
        <li class="list-group-item px-0">
            <strong translate>Total cost</strong>
            <span class="float-right">{{ event?.costo_totale | currency: currency }}</span>
        </li>
        <li class="list-group-item px-0">
            <strong translate>Contract estimate</strong>
            <span class="float-right">{{ event?.stima_contratti | currency: currency }}</span>
        </li>
        <li class="list-group-item px-0">
            <strong translate>Reason no contract</strong>
            <span class="float-right">{{ event?.motivo_no_contratto }}</span>
        </li>
        <li class="list-group-item px-0">
            <strong translate>Status</strong>
            <ng-container *ngFor="let status of eventStatuses">
                <ng-container *ngIf="event.stato == status?.key">
          <span [ngClass]="'badge ' + status.class + ' float-right'" translate>
            {{ status?.name }}
          </span>
                </ng-container>
            </ng-container>
        </li>
        <li class="list-group-item px-0">
            <strong translate>Contract file</strong>
            <span class="float-right">
                <a [href]="awsUrl+'/'+event?.file_url" target="_blank">{{event?.file_contratto}}</a>
            </span>
        </li>
    </ul>
</div>
