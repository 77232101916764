/* tslint:disable:variable-name */

import { BaseModel } from "./base-model";

export class Setting extends BaseModel {
  dominio: string;
  setting_key: string;
  setting_value: string;
  last_updated: string;
  id_user: number;

  checked = false;

  fromArray(result: []) {
    const array: Setting[] = [];
    for (const item of result) {
      array.push(new Setting(item));
    }
    return array;
  }
}
