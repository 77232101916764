<div class="card bg-gradient-1">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                 width="160px"
                 src="assets/img/flats/payment.png">
            <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>Credit/Debit Report</h1>
                <hr>
                <form (ngSubmit)="loadSubjects()">
                    <div class="form-row align-items-end justify-content-between">
                        <div class="col-lg-5 col-12"><label for="companyName" translate>Company name</label>
                            <input
                                    type="text" id="companyName"
                                    name="company_name" [(ngModel)]="filter.company_name"
                                    class="form-control form-control-sm"
                            />
                        </div>
                        <div class="col-lg-2 col-12">
                            <button class="btn btn-info btn-block" type="submit" translate>Filter
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-header">
        <h4 class="mb-0" translate>Subject</h4>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <table class="table table-sm table-striped">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="table-subjects"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <thead>
            <tr>
                <th translate>Company name</th>
                <th translate class="text-right">Credit/Debit</th>
                <th class="text-right"></th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <tr *ngFor="let subject of subjects">
                <td>{{subject.company_name}}</td>
                <td class="text-right">
                    <span class="text-success" *ngIf="isCredit(subject?.report?.total_debit_credit)">
                        {{subject?.report?.total_debit_credit|currency:currency}}
                    </span>
                    <span class="text-danger" *ngIf="!isCredit(subject?.report?.total_debit_credit)">
                        {{subject?.report?.total_debit_credit|currency:currency}}
                    </span>
                </td>
                <td class="text-right">
                    <button class="btn btn-link" (click)="showDocumentModal(subject)">
                        <i class=" fa fa-eye text-info"></i>
                    </button>
                </td>
            </tr>
            <tr *ngIf="subjects?.length<=0">
                <td class="text-muted" colspan="6" translate>List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="subjects?.length>0">
    <span><span translate>There are</span><b> {{ this.subjectsPagination.totalCount }}</b>
        {{this.subjectsPagination.totalCount <= 1 ? 'subject' : 'subjects'|translate}}.</span>
        <ngb-pagination *ngIf="subjectsPagination.pageCount>1"
                        size="sm"
                        [pageSize]="subjectsPagination.perPage"
                        [maxSize]="10"
                        [(page)]="subjectsPagination.currentPage"
                        [collectionSize]="subjectsPagination.totalCount"
                        (pageChange)="loadSubjects()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>
<ng-template #documentsTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>{{selectedSubject?.company_name}}</h4>
        <button (click)="modalService.dismissAll()"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="documentBusy">
        <div class="card">
            <div class="card-header">
                <h4 class="mb-0" translate>Documents</h4>
            </div>
            <div class="card-body p-0 rounded-bottom table-responsive">
                <table class="table table-sm table-striped">
                    <thead>
                    <tr>
                        <th translate>No</th>
                        <th translate>Company name</th>
                        <th translate class="text-center">Welded</th>
                        <th translate class="text-right">Credit</th>
                        <th translate class="text-right">Debit</th>
                        <th class="text-right"></th>
                    </tr>
                    </thead>
                    <tbody class="bg-gradient-white">
                    <tr *ngFor="let document of documents">
                        <td>{{document.number}}</td>
                        <td>{{document.company_name}}</td>
                        <td class="text-center w-20">
                            <i class="text-success fas fa-check" *ngIf="document.welded==1"></i>
                            <i class="text-danger fas fa-times" *ngIf="document.welded==0"></i>
                        </td>
                        <td class="text-right text-success"><span
                                *ngIf="document.is_credit==1">{{document.amount|currency:currency}}</span></td>
                        <td class="text-right text-danger"><span
                                *ngIf="document.is_credit==0">{{document.amount|currency:currency}}</span></td>
                        <td class="text-right">
                            <button class="btn btn-link" (click)="showPaymentModal(document)">
                                <i class=" fa fa-eye text-info"></i>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="documents?.length<=0">
                        <td class="text-muted" colspan="6" translate>List is empty</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colspan="3" translate class="pl-4"><strong>TOTAL</strong></td>
                        <td class="text-right text-success">
                            <strong>{{selectedSubject?.report?.credit_total|currency:currency}}</strong>
                        </td>
                        <td class="text-right text-danger">
                            <strong>{{selectedSubject?.report?.debit_total|currency:currency}}</strong>
                        </td>
                        <td class="pr-4 text-right text-{{isCredit(selectedSubject?.report?.total_debit_credit)?'success':'danger'}}"><span>
                    <strong>{{selectedSubject?.report?.total_debit_credit|currency:currency}}</strong></span></td>
                    </tr>
                    </tfoot>
                </table>
            </div>
            <div class="card-footer" *ngIf="documents?.length>0">
    <span><span translate>There are</span><b> {{ this.documentsPagination.totalCount }}</b>
        {{this.documentsPagination.totalCount <= 1 ? 'document' : 'documents'|translate}}.</span>
                <ngb-pagination *ngIf="documentsPagination.pageCount>1"
                                size="sm"
                                [pageSize]="documentsPagination.perPage"
                                [maxSize]="10"
                                [(page)]="documentsPagination.currentPage"
                                [collectionSize]="documentsPagination.totalCount"
                                (pageChange)="loadDocuments()"
                                [boundaryLinks]="true"
                ></ngb-pagination>
            </div>
        </div>

    </div>
</ng-template>
<ng-template #paymentDetailTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>{{selectedDocument?.company_name}}
            - {{selectedDocument?.number}}</h4>
        <button (click)="paymentModal.dismiss()"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-payment-view [document]="selectedDocument"></app-payment-view>
    </div>
</ng-template>
