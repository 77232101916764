/* tslint:disable:variable-name */
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {User} from '@models/user';
import {
    CampiSkeleton,
    ContractSkeleton,
    FamiglieSkeleton, GruppoCampo,
    OfferteSkeleton,
    OperatoriMnpSkeleton,
    PisteSkeleton,
    Seriali,
    SerialiSkeleton,
    SottofamiglieSkeleton, Venditori
} from '@models/contract/contract-skeleton';
import {AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '@services/data.service';

@Component({
    selector: 'app-contract-new-offerta',
    templateUrl: './contract-new-offerta.component.html',
    styleUrls: ['./contract-new-offerta.component.scss']
})
export class ContractNewOffertaComponent implements OnInit, OnChanges {
    @Input()
    public me: User;
    @Input()
    public skeletons: ContractSkeleton[];

    private _bypassRicariche: boolean;
    @Input() set bypassRicariche(value: boolean) {
        this._bypassRicariche = value;

        if (this.form) {
            this.form.controls.ricarica_contestuale.setValidators([this.ricaricaValidator()]);
            if (value) {
                this.form.controls.ricarica_contestuale.setValidators([]);
                this.form.controls.ricarica_contestuale.updateValueAndValidity();
            }
        }

    }
    get bypassRicariche(): boolean {
        return this._bypassRicariche;
    }

    private venditoriList: Venditori[];

    @Input() set venditori(value: Venditori[]) {
        this.venditoriList = value;

        if (this.form) {
            this.form.controls.id_dealer.setValidators([]);
            if (value.length > 0) {
                this.form.controls.id_dealer.setValidators([Validators.required]);
            }

            this.form.controls.id_dealer.updateValueAndValidity();
        }

    }
    get venditori(): Venditori[] {
        return this.venditoriList;
    }

    @Input()
    public initialForm: {
        id_offerta: number;
        id_sottofamiglia: number;
        id_famiglia: number;
        id_pista: number;
        id_operatore: number;
    };
    @Output()
    formChange = new EventEmitter();
    @Output()
    valid = new EventEmitter();

    public form: FormGroup;

    operatore: number;
    piste: PisteSkeleton[] = [];
    famiglie: FamiglieSkeleton[] = [];
    sottofamiglie: SottofamiglieSkeleton[] = [];
    offerte: OfferteSkeleton[] = [];
    campiPersonalizzati: CampiSkeleton[];
    gruppiCampi: GruppoCampo[];
    campiOpzioni: {};
    serialiTipo: SerialiSkeleton[];
    seriali: Seriali[];
    tipoPortabilita: number;
    ricaricaMinima: number;
    operatoriMnp: OperatoriMnpSkeleton[];
    province: any;
    comuni = {};

    constructor(
        public formBuilder: FormBuilder,
        public dataService: DataService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.dataService.get('/get-province').subscribe((data) => this.province = data.result);

        this.form = this.formBuilder.group({
            id_dealer: [null, []],
            id_operatore: [null, [Validators.required]],
            id_pista: [null, [Validators.required]],
            id_famiglia: [null, [Validators.required]],
            id_sottofamiglia: [null, [Validators.required]],
            id_offerta: [null, [Validators.required]],
            ricarica_contestuale: [null, [this.ricaricaValidator()]],
            campi_personalizzati: this.formBuilder.array([]),
            seriali: this.formBuilder.array([]),
            portabilita: this.formBuilder.group({
                numero: [null, [Validators.required]],
                seriale: [null, [Validators.required]],
                operatore: [null, [Validators.required]]
            })
        });

        this.c.id_operatore.valueChanges.subscribe((value) => this.setOperatore(value));
        this.c.id_pista.valueChanges.subscribe((value) => this.setPista(value));
        this.c.id_famiglia.valueChanges.subscribe((value) => this.setFamiglia(value));
        this.c.id_sottofamiglia.valueChanges.subscribe((value) => this.setSottofamiglia(value));
        this.c.id_offerta.valueChanges.subscribe((value) => this.setOfferta(value));

        if (this.skeletons) {
            this.form.patchValue(this.initialForm);
        }

        this.form.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe((value) => {
            this.formChange.emit(value);
        });

        this.form.statusChanges.subscribe((value) => {
            this.valid.emit(this.form.valid);
        });

        // this.form.patchValue(this.initialForm);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('skeletons') && changes.skeletons.currentValue) {
            if (!this.initialForm.id_operatore) {
                const params = this.route.snapshot.paramMap;
                if (params.has('id_operatore')) {
                    this.initialForm.id_operatore = parseInt(params.get('id_operatore'), 10);
                }
                if (params.has('id_pista')) {
                    this.initialForm.id_pista = parseInt(params.get('id_pista'), 10);
                }
                if (params.has('id_famiglia')) {
                    this.initialForm.id_famiglia = parseInt(params.get('id_famiglia'), 10);
                }
                if (params.has('id_sottofamiglia')) {
                    this.initialForm.id_sottofamiglia = parseInt(params.get('id_sottofamiglia'), 10);
                }
                if (params.has('id_offerta')) {
                    this.initialForm.id_offerta = parseInt(params.get('id_offerta'), 10);
                }

                if (this.form) {
                    this.form.patchValue(this.initialForm);
                }
            }
        }
    }

    get c() {
        return this.form.controls;
    }

    private setOperatore(value: any) {
        console.log(value);
        console.log(this.skeletons);
        this.operatore = value;
        if (this.skeletons) {
            const skeleton = this.skeletons.find((item: ContractSkeleton) => item.id === value);
            if (skeleton) {
                this.piste = skeleton.piste;
                this.seriali = skeleton.seriali;
                this.operatoriMnp = skeleton.operatori_mnp;
                this.famiglie = [];
                this.sottofamiglie = [];
                this.offerte = [];
                this.campiPersonalizzati = [];
                this.gruppiCampi = [];
                this.serialiTipo = [];
                this.c.id_famiglia.reset();
                this.c.id_sottofamiglia.reset();
                this.c.id_offerta.reset();
                this.setupCampi();
                this.setupSeriali();
            }
        }
    }

    private setPista(value: any) {
        if (value) {
            this.famiglie = this.piste.find((pista: PisteSkeleton) => pista.id === value).famiglie;
        }
        this.sottofamiglie = [];
        this.offerte = [];
        this.campiPersonalizzati = [];
        this.gruppiCampi = [];
        this.serialiTipo = [];
        this.c.id_sottofamiglia.reset();
        this.c.id_offerta.reset();
        this.setupCampi();
        this.setupSeriali();
    }

    private setFamiglia(value: any) {
        if (value) {
            this.sottofamiglie = this.famiglie.find((famiglia: FamiglieSkeleton) => famiglia.id === value).sottofamiglie;
        }
        this.offerte = [];
        this.campiPersonalizzati = [];
        this.gruppiCampi = [];
        this.serialiTipo = [];
        this.c.id_offerta.reset();
        this.setupCampi();
        this.setupSeriali();
    }

    private setSottofamiglia(value: any) {
        if (value) {
            this.offerte = this.sottofamiglie
                .find((sottofamiglia: SottofamiglieSkeleton) => sottofamiglia.id === value).offerte;
        }
        this.campiPersonalizzati = [];
        this.gruppiCampi = [];
        this.serialiTipo = [];
        this.setupCampi();
        this.setupSeriali();
    }

    private setOfferta(value: any) {
        if (value) {
            const offerta = this.offerte.find((item: OfferteSkeleton) => item.id === value);
            this.campiPersonalizzati = offerta.campi_personalizzati;
            this.gruppiCampi = offerta.gruppi;
            this.serialiTipo = offerta.seriali_offerte;
            this.tipoPortabilita = offerta.portabilita;
            this.c.ricarica_contestuale.setValue(offerta.ricarica_minima);
            this.setupPortabilita();
            this.setupCampi();
            this.setupSeriali();
        }
    }

    private clearFormArray(formArray: FormArray) {
        while (formArray.length !== 0) {
            formArray.removeAt(0);
        }
    }

    private setupCampi() {
        const campi = this.form.controls.campi_personalizzati as FormArray;
        this.clearFormArray(campi);

        this.campiOpzioni = {};

        let index = 0;

        for (const campo of this.campiPersonalizzati) {
            const validators = [];

            if (campo.obbligatorio === 1) {
                validators.push(Validators.required);
            }
            switch (campo.validatore) {
                case 'pattern':
                    validators.push(Validators.pattern(campo.pattern));
                    break;
            }

            if (campo.tipo_campo === 'select' || campo.tipo_campo === 'multiselect') {
                if (campo.json) {
                    this.campiOpzioni[campo.id_tipo] = JSON.parse(campo.json);
                }
            }

            let defaultValue = null;

            if (campo.default_value) {
                defaultValue = campo.default_value;

                console.log(campo);

                if (campo.tipo_campo === 'radio') {
                    defaultValue = defaultValue === '1';
                }
            }

            const controlsGroup: FormGroup = this.formBuilder.group({
                index: [index++, [Validators.required]],
                id_tipo: [campo.id_tipo, [Validators.required]],
                id_gruppo: [null, []],
                tipo_campo: [campo.tipo_campo, []],
                nome: [campo.nome, []],
                valore: [defaultValue, validators]
            });

            campi.push(controlsGroup);
        }

        for (const gruppo of this.gruppiCampi) {
            for (const campo of gruppo.campi) {
                const validators = [];

                if (campo.obbligatorio === 1) {
                    validators.push(Validators.required);
                }
                switch (campo.validatore) {
                    case 'pattern':
                        validators.push(Validators.pattern(campo.pattern));
                        break;
                }

                if (campo.tipo_campo === 'select' || campo.tipo_campo === 'multiselect') {
                    if (campo.json) {
                        this.campiOpzioni[campo.id_tipo] = JSON.parse(campo.json);
                    }
                }

                let defaultValue = null;

                if (campo.default_value) {
                    defaultValue = campo.default_value;


                    if (campo.tipo_campo === 'radio') {
                        defaultValue = defaultValue === '1';
                    }
                }

                const controlsGroup: FormGroup = this.formBuilder.group({
                    index: [index++, [Validators.required]],
                    id_tipo: [campo.id_tipo, [Validators.required]],
                    id_gruppo: [gruppo.id, []],
                    tipo_campo: [campo.tipo_campo, []],
                    nome: [campo.nome, []],
                    valore: [defaultValue, validators]
                });

                campi.push(controlsGroup);
            }
        }
    }

    private setupSeriali() {
        const seriali = this.form.controls.seriali as FormArray;
        this.clearFormArray(seriali);

        for (const seriale of this.serialiTipo) {
            const controlsGroup: FormGroup = this.formBuilder.group({
                id_tipo: [seriale.id_tipo, [Validators.required]],
                nome: [seriale.nome, [Validators.required]],
                valore: [null, [Validators.required]]
            });
            seriali.push(controlsGroup);
        }
    }

    private setupPortabilita() {
        if (this.tipoPortabilita === 0) {
            this.form.controls.portabilita.disable();
        } else {
            this.form.controls.portabilita.enable();
        }
    }

    getCampi(): FormGroup[] {
        const campi = this.form.controls.campi_personalizzati as FormArray;
        return campi.controls.filter((item: any) => !item.controls.id_gruppo.value) as FormGroup[];
    }

    getCampiGruppo(idGruppo: number): FormGroup[] {
        const campi = this.form.controls.campi_personalizzati as FormArray;
        return campi.controls.filter((item: any) => item.controls.id_gruppo.value === idGruppo) as FormGroup[];
    }

    getSeriali(): FormGroup[] {
        const seriali = this.form.controls.seriali as FormArray;
        return seriali.controls as FormGroup[];
    }

    getPortabilita() {
        if (this.c.id_offerta.value) {
            return this.tipoPortabilita;
        }
        return null;
    }

    hasRicaricaContestuale() {
        if (this.c.id_offerta.value) {
            return parseInt(this.c.ricarica_contestuale.value, 10) > 0;
        }
        return null;
    }

    ricaricaValidator(): ValidatorFn {
        const instance = this;
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (instance.operatore === 6) {
                if (control.value > instance.me.profile.plafond_kena) {
                    return {plafondInsufficiente: {value: control.value}};
                }
            } else if (instance.operatore === 4) {
                if (control.value > instance.me.profile.plafond_tiscali) {
                    return {plafondInsufficiente: {value: control.value}};
                }
            }
            return null;
        };
    }

    getOptions(id: any) {
        return this.campiOpzioni[id];
    }

    setComuni(value: any, gruppo: any) {
        this.dataService.post('/get-comuni', {id_provincia: value.id}).subscribe((data) => this.comuni[gruppo] = data.result);
    }
}
