/* tslint:disable:variable-name */
export class Customer {
    id: number;
    ragione_sociale: string;
    partita_iva: string;
    nome: string;
    cognome: string;
    codfiscale: string;
    nascita_nazione: string;
    nascita_provincia: string;
    nascita_localita: string;
    nascita_data: string;
    sesso: number;
    telefono: string;
    residenza_indirizzo: string;
    residenza_civico: string;
    residenza_provincia: string;
    residenza_localita: string;
    residenza_cap: string;
    sede_indirizzo: string;
    sede_civico: string;
    sede_provincia: string;
    sede_localita: string;
    sede_cap: string;
    documento_tipo: string;
    documento_nazione: string;
    documento_provincia: string;
    documento_localita: string;
    documento_numero: string;
    documento_rilascio: string;
    documento_scadenza: string;
    documento_path: string;
    altro_documento_path: string;
    email: string;
    business: number;
    isin_copertura: boolean;

    get fullName() {
        return `${this.nome} ${this.cognome}`;
    }

    fromArray(result: []) {
        const array: Customer[] = [];
        for (const item of result) {
            const tempItem: Customer = new Customer();
            Object.assign(tempItem, item);
            array.push(tempItem);
        }
        return array;
    }

    fromJSON(result: any) {
        const temp = new Customer();
        Object.assign(temp, result);
        return temp;
    }
}
