import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ShopBrand, ShopBrandPagination} from '@models/e-pay/shop-brand';
import {EPayService} from '@services/e-pay.service';
import {ShopProduct, ShopProductPrice, ShopProductVariant} from '@models/e-pay/shop-product';
import {ShopProductCategory, ShopProductCategoryPagination} from '@models/e-pay/shop-product-category';
import {TinyMCE} from '@models/tinyMCE';

@Component({
    selector: 'app-product-modal',
    templateUrl: './product-modal.component.html',
    styleUrls: ['./product-modal.component.css']
})
export class ProductModalComponent implements OnInit {
    @Input() products: ShopProduct[] = [];
    @Input() selectedProduct: ShopProduct;
    @Output() changedProduct = new EventEmitter<boolean>();
    categoryModal: NgbModalRef;
    variationModal: NgbModalRef;
    distributorModal: NgbModalRef;
    @ViewChild('categoryFormTemplate') private categoryFormTemplate;
    @ViewChild('variationFormTemplate') private variationFormTemplate;
    @ViewChild('distributorFormTemplate') private distributorFormTemplate;
    brandModal: NgbModalRef;
    @ViewChild('brandFormTemplate') private brandFormTemplate;
    defaultConfig = new TinyMCE();
    productForm = new FormGroup({
        name: new FormControl('', Validators.required),
        price: new FormControl('', Validators.required),
        dealer_price: new FormControl('', Validators.required),
        our_price: new FormControl('', Validators.required),
        type: new FormControl('', Validators.required),
        ean: new FormControl('', Validators.required),
        pan: new FormControl(''),
        brand_id: new FormControl('', Validators.required),
        category_id: new FormControl('', Validators.required),
        image_name: new FormControl('', Validators.required),
        is_active: new FormControl(true, Validators.required),
        description: new FormControl(''),
        variants: new FormControl([]),
        vat_type: new FormControl(''),
        product_prices: new FormControl([]),
    });
    variationForm = new FormGroup({
        name: new FormControl('', Validators.required),
        price: new FormControl('', Validators.required),
        dealer_price: new FormControl('', Validators.required),
        our_price: new FormControl('', Validators.required),
        ean: new FormControl('', Validators.required),
        pan: new FormControl(''),
        description: new FormControl(''),
        vat_type: new FormControl(''),
        product_prices: new FormControl([]),
    });
    categoryForm = new FormGroup({
        name: new FormControl('', Validators.required),
        description: new FormControl('')
    });
    distributorForm = new FormGroup({
        dealer_id: new FormControl('', Validators.required),
        amount: new FormControl('', Validators.required)
    });
    submittedFile: File;
    currency = EPayService.currency;
    categories: ShopProductCategory[] = [];
    brands: ShopBrand[] = [];
    busy: any;
    busyCategory: any;
    busyDistributor: any;
    brandsLoading = false;
    categoriesLoading = false;
    productTypes = EPayService.productTypes;
    isDistributorVariant = false;

    constructor(
        private modalService: NgbModal,
        private ePayService: EPayService) {
    }

    ngOnInit(): void {
        this.loadBrands();
        this.loadCategories();
        this.loadData();
        this.productForm.get('variants').valueChanges.subscribe(items => {
            if (items?.length > 0) {
                this.productForm.controls['price'].clearValidators();
                this.productForm.controls['dealer_price'].clearValidators();
                this.productForm.controls['our_price'].clearValidators();
                this.productForm.controls['ean'].clearValidators();
                this.productForm.controls['pan'].clearValidators();
            } else {
                this.productForm.controls['price'].setValidators(Validators.required);
                this.productForm.controls['dealer_price'].setValidators(Validators.required);
                this.productForm.controls['our_price'].setValidators(Validators.required);
                this.productForm.controls['ean'].setValidators(Validators.required);
            }
            this.productForm.get('price').updateValueAndValidity();
            this.productForm.get('dealer_price').updateValueAndValidity();
            this.productForm.get('our_price').updateValueAndValidity();
            this.productForm.get('ean').updateValueAndValidity();
            this.productForm.get('pan').updateValueAndValidity();
        });
        this.productForm.get('type').valueChanges.subscribe(type => {
            if (type === this.productTypes[2].key) {
                this.productForm.controls['pan'].setValidators(Validators.required);
            } else {
                this.productForm.controls['pan'].clearValidators();
            }
            this.productForm.get('pan').updateValueAndValidity();
        });
    }

    loadData() {
        if (this.selectedProduct) {
            this.brands.unshift(this.selectedProduct.brand);
            this.brands.slice(0);
            this.categories.unshift(this.selectedProduct.category);
            this.categories.slice(0);
            this.productForm.setValue({
                name: this.selectedProduct.name,
                price: this.selectedProduct.price,
                dealer_price: this.selectedProduct.dealer_price,
                our_price: this.selectedProduct.our_price,
                type: this.selectedProduct.type,
                ean: this.selectedProduct.ean,
                pan: this.selectedProduct.pan,
                brand_id: this.selectedProduct.brand_id,
                category_id: this.selectedProduct.category_id,
                image_name: this.selectedProduct.image_name,
                is_active: this.selectedProduct.is_active === 1 || this.selectedProduct.is_active === '1',
                description: this.selectedProduct.description,
                variants: this.selectedProduct.variants,
                vat_type: this.selectedProduct?.vat_type,
                product_prices: this.selectedProduct?.product_prices
            });
            if (this.selectedProduct.variants?.length > 0) {
                this.productForm.controls['ean'].clearValidators();
                this.productForm.controls['price'].clearValidators();
                this.productForm.controls['dealer_price'].clearValidators();
                this.productForm.controls['our_price'].clearValidators();
                this.productForm.get('ean').updateValueAndValidity();
                this.productForm.get('price').updateValueAndValidity();
                this.productForm.get('dealer_price').updateValueAndValidity();
                this.productForm.get('our_price').updateValueAndValidity();
            }
        }
    }

    dismissModal() {
        this.modalService.dismissAll();
    }

    showCategoryModal() {
        this.categoryForm.reset();
        this.categoryModal = this.modalService.open(this.categoryFormTemplate, {size: 'md'});
        this.categoryModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    showVariationModal() {
        this.variationForm.reset();
        this.variationModal = this.modalService.open(this.variationFormTemplate, {size: 'lg'});
        this.variationModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    showBrandModal() {
        this.brandModal = this.modalService.open(this.brandFormTemplate, {size: 'lg'});
        this.brandModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    showDistributorModal(isVariant = false) {
        this.isDistributorVariant = isVariant;
        this.distributorForm.reset();
        this.distributorModal = this.modalService.open(this.distributorFormTemplate, {size: 'md'});
        this.distributorModal.result.then((res) => {
        }).catch((res) => {
        });
    }


    handleFileInput(file: File) {
        this.submittedFile = file;
        this.productForm.get('image_name').setValue(file.name);
    }

    handleFileRemoveModal() {
        this.productForm.get('image_name').setValue('');
        this.submittedFile = null;
    }

    saveProduct() {
        const product = new ShopProduct();
        product.name = this.productForm.value.name;
        product.price = this.productForm.value.price;
        product.dealer_price = this.productForm.value.dealer_price;
        product.our_price = this.productForm.value.our_price;
        product.type = this.productForm.value.type;
        product.ean = this.productForm.value.ean;
        product.pan = this.productForm.value.pan;
        product.brand_id = this.productForm.value.brand_id;
        product.category_id = this.productForm.value.category_id;
        product.is_active = this.productForm.value.is_active ? '1' : '0';
        product.image_name = this.productForm.value.image_name;
        product.file = this.submittedFile;
        product.description = this.productForm.value.description;
        product.variants = this.productForm.value.variants;
        product.vat_type = this.productForm.value.vat_type;
        product.product_prices = this.productForm.value.product_prices;
        if (this.selectedProduct) {
            return this.changeProduct(product);
        }
        return this.addProduct(product);
    }

    addProduct(product: ShopProduct) {
        this.busy = this.ePayService.postProduct(product).subscribe((response: ShopProduct) => {
            this.products.unshift(response);
            this.ePayService.successAlert('Created Product!');
            this.changedProduct.emit(true);
            this.dismissModal();
        }, err => {
            this.ePayService.errorAlert(err);
            this.dismissModal();
        });
    }

    changeProduct(product: ShopProduct) {
        this.busy = this.ePayService.putProduct(this.selectedProduct.id, product).subscribe((response: ShopProduct) => {
            const index = this.products.findIndex(data => data.id === response.id);
            if (index >= 0) {
                this.products[index] = response;
                this.products[index].is_active = Number(response.is_active);
            }
            this.ePayService.successAlert('Changed Product!');
            this.changedProduct.emit(true);
            this.dismissModal();
        }, err => {
            this.ePayService.errorAlert(err);
            this.dismissModal();
        });
    }

    saveVariation() {
        const variant = new ShopProductVariant();
        variant.name = this.variationForm.value.name;
        variant.price = this.variationForm.value.price;
        variant.dealer_price = this.variationForm.value.dealer_price;
        variant.our_price = this.variationForm.value.our_price;
        variant.ean = this.variationForm.value.ean;
        variant.pan = this.variationForm.value.pan;
        variant.description = this.variationForm.value.description;
        variant.vat_type = this.variationForm.value.vat_type;
        variant.product_prices = this.variationForm.value.product_prices;
        const variations = this.variants;
        variations.unshift(variant);
        this.productForm.get('variants').setValue(variations);
        this.variationModal.dismiss();
        this.variationForm.reset();
    }

    saveCategory() {
        const category = new ShopProductCategory();
        category.name = this.categoryForm.value.name;
        category.description = this.categoryForm.value.description;
        this.busyCategory = this.ePayService.postProductCategory(category).subscribe((response: ShopProductCategory) => {
            this.categories.unshift(response);
            this.categories = this.categories.slice(0);
            this.productForm.get('category_id').setValue(response.id);
            this.refreshCategoryForm();
            this.ePayService.successAlert('Created Category!');
            this.categoryModal.dismiss();
        }, err => {
            this.ePayService.errorAlert(err);
            this.categoryModal.dismiss();
        });
    }

    saveDistributorPrice() {
        const productPrice = new ShopProductPrice();
        productPrice.dealer_id = this.distributorForm.value.dealer_id;
        productPrice.amount = this.distributorForm.value.amount;
        if (this.isDistributorVariant) {
            const prices = this.variantDistributors;
            prices.unshift(productPrice);
            this.variationForm.get('product_prices').setValue(prices);
        } else {
            const prices = this.distributors;
            prices.unshift(productPrice);
            this.productForm.get('product_prices').setValue(prices);
        }
        this.distributorModal.dismiss();
        this.distributorForm.reset();
    }

    changeDealer(event) {
        this.distributorForm.get('dealer_id').setValue(event);
    }

    refreshCategoryForm() {
        this.categoryForm.setValue({
            name: '',
            description: ''
        });
    }

    removeVariation(index: number, variant: ShopProductVariant) {
        const variants = this.variants;
        if (index >= 0) {
            variants.splice(index, 1);
            this.productForm.get('variants').setValue(variants);
        }
    }

    removeDistributor(index: number, isVariant = false) {
        if (index >= 0) {
            if (isVariant) {
                const variantPrices = this.variantDistributors;
                variantPrices.splice(index, 1);
                this.variationForm.get('product_prices').setValue(variantPrices);
            } else {
                const productPrices = this.distributors;
                productPrices.splice(index, 1);
                this.productForm.get('product_prices').setValue(productPrices);
            }
        }
    }

    addBrand(brand) {
        this.brands.unshift(brand);
        this.brands = this.brands.slice(0);
        this.productForm.get('brand_id').setValue(brand.id);
    }

    loadBrands(term?) {
        this.brandsLoading = true;
        const param = {};
        if (term) {
            param['name'] = term;
        }
        this.ePayService.getBrands(param).subscribe((response: ShopBrandPagination) => {
            this.brands = response.data;
            this.brandsLoading = false;
        }, err => {
            this.brandsLoading = false;
        });
    }

    loadCategories(term?) {
        this.categoriesLoading = true;
        const param = {};
        if (term) {
            param['name'] = term;
        }
        this.ePayService.getProductCategories().subscribe((response: ShopProductCategoryPagination) => {
            this.categories = response.data;
            this.categoriesLoading = false;
        }, err => {
            this.categoriesLoading = false;
        });
    }

    get imageName() {
        return this.productForm.get('image_name').value;
    }

    get variants() {
        return this.productForm.get('variants').value as ShopProductVariant[];
    }

    get distributors() {
        return this.productForm.get('product_prices').value as ShopProductPrice[];
    }

    get variantDistributors() {
        return this.variationForm.get('product_prices').value as ShopProductPrice[];
    }
}
