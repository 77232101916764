import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import * as moment from 'moment';
import {Moment} from 'moment';
import {TicketService} from '@services/ticket.service';
import {TicketReport} from '@models/ticket';
import {Subscription} from 'rxjs/internal/Subscription';
import {interval, timer} from 'rxjs';
import {mergeMap} from 'rxjs/operators';

@Component({
    selector: 'app-ticket-dashboard',
    templateUrl: './ticket-dashboard.component.html',
    styleUrls: ['./ticket-dashboard.component.css']
})
export class TicketDashboardComponent implements OnInit, OnDestroy {
    dateOptions = {
        maxSpan: {month: 1}
    };
    selectedDate: string;
    apiDate: {
        from: any,
        to: any
    };
    report: TicketReport;

    subscription: Subscription;

    constructor(private service: TicketService,
                private spinner: NgxSpinnerService,
                private dateRangePickerConfig: DaterangepickerConfig) {
        this.selectedDate = moment().format(this.dateRangePickerConfig.settings.locale.format)
            + this.dateRangePickerConfig.settings.locale.separator + moment().format(this.dateRangePickerConfig.settings.locale.format);
        this.apiDate = {
            from: moment().format(this.dateRangePickerConfig.settings.locale.apiFormat),
            to: moment().format(this.dateRangePickerConfig.settings.locale.apiFormat)
        };
        this.loadReport();
    }

    ngOnInit(): void {
    }


    loadReport() {
        this.spinner.show('report');
        this.subscription = timer(0, 5 * 60 * 1000)
            .pipe(
                mergeMap(() => this.service.getReport(this.apiDate))
            )
            .subscribe((response: TicketReport) => {
                this.report = response;
                this.spinner.hide('report');
            }, err => {
                this.spinner.hide('report');
            });

    }

    loadNewDate(start: Moment, end: Moment) {
        if (start && end) {
            this.selectedDate = start.format(this.dateRangePickerConfig.settings.locale.format)
                + this.dateRangePickerConfig.settings.locale.separator +
                end.format(this.dateRangePickerConfig.settings.locale.format);
            this.apiDate.from = start.format(this.dateRangePickerConfig.settings.locale.apiFormat);
            this.apiDate.to = end.format(this.dateRangePickerConfig.settings.locale.apiFormat);
            this.loadReport();
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
