import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbDate, NgbCalendar, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";

@Component({
    selector: 'app-datepicker-range-popup',
    templateUrl: './datepicker-range.popup.component.html',
    styles: [`
    .form-group.hidden {
      width: 0;
      margin: 0;
      border: none;
      padding: 0;
    }
    .custom-day {
      text-align: center;
      padding: 0.185rem 0.25rem;
      display: inline-block;
      height: 2rem;
      width: 2rem;
    }
    .custom-day.focused {
      background-color: #e6e6e6;
    }
    .custom-day.range, .custom-day:hover {
      background-color: rgb(2, 117, 216);
      color: white;
    }
    .custom-day.faded {
      background-color: rgba(2, 117, 216, 0.5);
    }
    .btn-outline-secondary:hover i {
        color: #FFF!important;
    }
  `]
})
export class DatepickerRangePopupComponent implements OnInit {

    hoveredDate: NgbDate | null = null;

    fromDate: NgbDate | null;
    toDate: NgbDate | null;

    @Input()
    from: string;
    @Input()
    to: string;
    @Output()
    onSelect = new EventEmitter();

    constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
            this.toDate = date;
        } else {
            this.toDate = null;
            this.fromDate = date;
        }

        if(this.fromDate && this.toDate) {
            this.update();
        }
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    }

    ngOnInit(): void {
        let from = this.from.split('-');
        let to = this.to.split('-');
        this.fromDate = new NgbDate(parseFloat(from[0]), parseFloat(from[1]), parseFloat(from[2]));
        this.toDate = new NgbDate(parseFloat(to[0]), parseFloat(to[1]), parseFloat(to[2]));
    }

    setDate(option) {
        let now = moment().add(1, 'months');
        switch (option) {
            case 'MTD':
                this.fromDate = new NgbDate(now.year(), now.month(), 1);
                this.toDate = new NgbDate(now.year(), now.month(), now.date());
                break;
            case 'YTD':
                this.fromDate = new NgbDate(now.year(), 1, 1);
                this.toDate = new NgbDate(now.year(), now.month(), now.date());
                break;
            case 'Trimestre 1':
                this.fromDate = new NgbDate(now.year(), 1, 1);
                this.toDate = new NgbDate(now.year(), 3, 31);
                break;
            case 'Trimestre 2':
                this.fromDate = new NgbDate(now.year(), 4, 1);
                this.toDate = new NgbDate(now.year(), 6, 30);
                break;
            case 'Trimestre 3':
                this.fromDate = new NgbDate(now.year(), 7, 1);
                this.toDate = new NgbDate(now.year(), 9, 30);
                break;
            case 'Trimestre 4':
                this.fromDate = new NgbDate(now.year(), 10, 1);
                this.toDate = new NgbDate(now.year(), 12, 31);
                break;
        }

        this.update();
    }

    update() {
        let from = moment({
            year: this.fromDate.year,
            month: this.fromDate.month - 1,
            date: this.fromDate.day,
            hour: 12
        }).format('YYYY-MM-DD');
        let to = moment({
            year: this.toDate.year,
            month: this.toDate.month - 1,
            date: this.toDate.day,
            hour: 12
        }).format('YYYY-MM-DD');

        this.onSelect.emit({
            from: from,
            to: to
        });
    }
}
