import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {User} from '@models/user';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Dealer} from '@models/dealer';
import {Company} from '@models/company';
import {Affiliation, AffiliationList, RequiredDocument, SubmittedDocument} from '@models/affiliation';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AuthenticationService} from '@services/authentication.service';
import {AffiliationService} from '@services/affiliation.service';

@Component({
    selector: 'app-affilation-modal',
    templateUrl: './affilation-modal.component.html',
    styleUrls: ['./affilation-modal.component.scss']
})
export class AffilationModalComponent implements OnInit {
    @ViewChild('affilationsTemplate') private affilationsTemplate;
    @Input() affiliationValues: Affiliation;
    @Input() affiliationItems: AffiliationList;
    private user: User;
    private modal: NgbModalRef;

    isDealerValid = false;
    isOperatorValid = false;

    filterDealers: Dealer[];
    formDealers: Dealer[];
    formOperators: Company[];
    formDocuments: RequiredDocument[] = [];
    formSelectedDealerId: number;
    formSelectedOperatorId: number;
    busyFormModal: Subscription;

    constructor(private modalService: NgbModal,
                private authService: AuthenticationService,
                private affiliationService: AffiliationService,
                public formBuilder: FormBuilder) {
        this.authService.currentUser.subscribe(user => this.user = user);
        this.affiliationService.getDealerList().subscribe((dealers: Dealer[]) => {
            this.formDealers = dealers;
            this.filterDealers = dealers;
        });
        this.affiliationService.getOperatorList().subscribe((operators: Company[]) => {
            this.formOperators = operators;
        });
    }


    ngOnInit(): void {
        this.setAffiliationValues();
    }

    setAffiliationValues() {
        if (this.affiliationValues) {
            this.formSelectedDealerId = this.affiliationValues.dealer.id;
            this.formSelectedOperatorId = this.affiliationValues.operator.id;
            this.formDocuments = this.showSubmittedFileName(this.affiliationValues.required_documents);
        }
    }

    saveAffiliation() {
        if (!this.validateSubmittedDocument()) {
            return;
        }
        const affiliation = new Affiliation();
        affiliation.dealer_id = this.formSelectedDealerId;
        affiliation.operator_id = this.formSelectedOperatorId;
        affiliation.submitted_documents = this.getSubmittedDocuments();
        let url = 'affiliations';
        let alertText = 'created';
        if (this.affiliationValues) {
            url = url + '/' + this.affiliationValues.id;
            alertText = 'updated';
        }
        return this.busyFormModal = this.affiliationService.saveAffiliation(url, affiliation, alertText)
            .subscribe((affiliation: Affiliation) => {
                if (this.affiliationValues) {
                    if (this.affiliationValues.status !== 0) {
                        this.affiliationItems.in_revision.data.unshift(affiliation);
                        this.affiliationItems.archived.data = this.removeItemFromList(affiliation, this.affiliationItems.archived.data);
                    } else {
                        this.replaceItemInList(affiliation);
                    }
                } else {
                    this.affiliationItems.in_revision.data.unshift(affiliation);
                }
                this.modalService.dismissAll();
            }, err => {
                this.affiliationService.errorAlert(err.message);
            });
    }

    removeItemFromList(affiliation, affiliationItems) {
        return this.affiliationService.removeItemFromList(affiliation, affiliationItems);
    }

    replaceItemInList(affiliation) {
        for (let i = 0; i < this.affiliationItems.in_revision.data.length; i++) {
            if (this.affiliationItems.in_revision.data[i].id === affiliation.id) {
                this.affiliationItems.in_revision.data[i] = affiliation;
            }
        }
    }

    handleDealerSelectedModal(dealerId) {
        this.formSelectedDealerId = null;

        if (dealerId) {
            this.formSelectedDealerId = dealerId;
        }
    }

    handleOperatorSelectedModal(operator?: Company) {
        this.formDocuments = [];
        this.formSelectedOperatorId = null;

        if (operator) {
            this.formSelectedOperatorId = operator.id;
            this.busyFormModal = this.affiliationService.getRequiredDocumentsList(operator.id)
                .subscribe((documents: RequiredDocument[]) => {
                    this.formDocuments = documents;
                });
        }
    }

    handleFileInputModal(file: File, document: RequiredDocument) {
        document.file = file;
        document.fileName = file.name;
        for (const requirement of this.formDocuments) {
            if (requirement.id !== document.id) {
                requirement.uploadedAsList = requirement.uploadedAsList.filter((obj) => {
                    return obj.id !== document.id;
                });

                requirement.uploadedAsList.push({
                    id: document.id,
                    value: document.name,
                    fileName: document.fileName
                });
            }
        }
    }

    handleFileRemoveModal(document: RequiredDocument) {
        document.file = null;
        document.fileName = null;
        document.uploadedInId = null;

        for (const requirement of this.formDocuments) {
            if (document.id === requirement.uploadedInId) {
                requirement.uploadedInId = null;
            }

            if (requirement.id !== document.id) {
                requirement.uploadedAsList = requirement.uploadedAsList.filter((obj) => {
                    return obj.id !== document.id;
                });
            }
        }
    }

    setUploadedIn(selection: any, document: RequiredDocument) {
        document.uploadedInId = null;
        if (selection) {
            document.uploadedInId = selection.id;
            for (const requirement of this.formDocuments) {
                if (requirement.id === selection.id) {
                    document.fileName = requirement.fileName;
                }
            }
        }
    }

    private getSubmittedDocuments() {
        const submittedDocuments: SubmittedDocument[] = [];
        for (const document of this.formDocuments) {
            if (document.file || document.uploadedInId) {
                submittedDocuments.push(new SubmittedDocument({
                    requirement_id: document.id,
                    uploaded_in_id: document.uploadedInId,
                    file: document.file
                }));
            }
        }
        return submittedDocuments;
    }

    showSubmittedFileName(documents) {
        for (const document of documents) {
            const file = this.affiliationValues.submitted_documents.find(data => data.requirement_id === document.id);
            if (file) {
                document.fileName = file.file_name;
            }
        }
        return documents;
    }

    validateSubmittedDocument() {
        const emptyFile = this.formDocuments.filter(data => !data.fileName);
        if (emptyFile && emptyFile.length > 0) {
            return false;
        }
        return true;
    }
}
