import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AgenteService} from '@services/agente.service';

@Component({
  selector: 'app-agente-select',
  templateUrl: './agente-select.component.html',
  styleUrls: ['./agente-select.component.css']
})
export class AgenteSelectComponent implements OnInit {
  data = [];


  @Output() isValid = new EventEmitter();
  @Output() onChange = new EventEmitter();

  @Input() master = false;

  form: FormGroup;

  constructor(private agenteService: AgenteService,
              private formBuilder: FormBuilder,) {
  }

  ngOnInit(): void {
    this.agenteService.listaAgenti(this.master).subscribe((data) => {
      this.data = data.result;
      this.emit();
    });

    this.form = this.formBuilder.group({
      id_dealer: [this.master ? 1 : 376, [Validators.required]],
    });

    this.form.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged()
    )
        .subscribe((data) => {
          this.emit();
        });
  }

  private emit() {
    this.isValid.emit(this.form.valid);

    let dealer = this.data.find(item => item.id.toString() === this.form.controls.id_dealer.value.toString());

    this.onChange.emit(dealer);
  }
}
