import {Component, Input, OnInit} from '@angular/core';
import {Document} from '@models/invoice/document';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from '@models/invoice/subject';
import {InvoiceDocumentService} from '@services/invoice-document.service';

@Component({
    selector: 'app-document-view',
    templateUrl: './document-view.component.html',
    styleUrls: ['./document-view.component.css']
})
export class DocumentViewComponent implements OnInit {
    @Input() document: Document;
    @Input() documentId;
    @Input() showPayments = false;
    currency = '€';
    localFormat = 'DD/MM/YYYY';
    busy: any;


    constructor(private modalService: NgbModal,
                private invoiceDocumentService: InvoiceDocumentService) {
    }

    ngOnInit(): void {
        if (!this.document && this.documentId) {
            this.busy = this.invoiceDocumentService.getDocumentDetail(this.documentId).subscribe((response: Document) => {
                this.document = response;
            });
        }
    }

    dismissModal() {
        this.modalService.dismissAll();
    }

    findVatType(type) {
        const vatType = InvoiceDocumentService.vatTypes.find(item => item.key === type);
        return vatType ? vatType.name : type;
    }

    findType(type) {
        const documentType = InvoiceDocumentService.types.find(item => item.key === type);
        return documentType ? documentType.name : type;
    }
}
