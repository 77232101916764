import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {RequestService} from './request.service';
import {Observable} from 'rxjs';
import {ShopOrderReport} from '@models/shop-order';
import {TicketReport} from '@models/ticket';

@Injectable({
    providedIn: 'root',
})
export class TicketService {
    ENDPOINT = 'tickets';

    constructor(public request: RequestService) {
    }

    public getReport(selectedDate): Observable<TicketReport> {
        return this.request.get(`${this.ENDPOINT}/report`, selectedDate).pipe(
            map((response: any) => {
                return new TicketReport(response);
            })
        );
    }

    getTicket(id, params = {}) {
        return this.request.get(`${this.ENDPOINT}/${id}`, params);
    }

    list(params = {}) {
        return this.request
            .get(`${this.ENDPOINT}/list`, params, {observe: 'response'})
            .pipe(
                map((data: HttpResponse<any>) => {
                    return {
                        rows: data.body,
                        pagination: {
                            totalCount: parseInt(
                                data.headers.get('X-Pagination-Total-Count')
                            ),
                            page: parseInt(data.headers.get('X-Pagination-Current-Page')),
                            pageSize: parseInt(data.headers.get('X-Pagination-Per-Page')),
                            pages: parseInt(data.headers.get('X-Pagination-Page-Count')),
                        },
                    };
                })
            );
    }

    toHandleList(params = {}) {
        return this.request
            .get(
                `${this.ENDPOINT}/to-handle?expand=retailer,category,subcategory,replies,replies.creator,attachments`,
                params,
                {observe: 'response'}
            )
            .pipe(
                map((data: HttpResponse<any>) => {
                    return {
                        rows: data.body,
                        pagination: {
                            totalCount: parseInt(
                                data.headers.get('X-Pagination-Total-Count')
                            ),
                            page: parseInt(data.headers.get('X-Pagination-Current-Page')),
                            pageSize: parseInt(data.headers.get('X-Pagination-Per-Page')),
                            pages: parseInt(data.headers.get('X-Pagination-Page-Count')),
                        },
                    };
                })
            );
    }

    handlingList(params = {}) {
        return this.request
            .get(
                `${this.ENDPOINT}/handling?expand=retailer,category,subcategory,replies,replies.creator,attachments,new`,
                params,
                {observe: 'response'}
            )
            .pipe(
                map((data: HttpResponse<any>) => {
                    return {
                        rows: data.body,
                        pagination: {
                            totalCount: parseInt(
                                data.headers.get('X-Pagination-Total-Count')
                            ),
                            page: parseInt(data.headers.get('X-Pagination-Current-Page')),
                            pageSize: parseInt(data.headers.get('X-Pagination-Per-Page')),
                            pages: parseInt(data.headers.get('X-Pagination-Page-Count')),
                        },
                    };
                })
            );
    }

    saveTicket(data) {
        return this.request.post(`${this.ENDPOINT}`, data);
    }

    updateTicket(id, data) {
        return this.request.put(`${this.ENDPOINT}/${id}`, data);
    }

    deleteTicket(id) {
        return this.request.delete(`${this.ENDPOINT}/${id}`);
    }

    getCategories() {
        return this.request.get(`${this.ENDPOINT}/category?expand=subcategories`);
    }

    saveCategory(data) {
        return this.request.post(
            `${this.ENDPOINT}/category?expand=subcategories`,
            data
        );
    }

    updateCategory(id, data) {
        return this.request.put(
            `${this.ENDPOINT}/category/${id}?expand=subcategories`,
            data
        );
    }

    takeInHandle(id) {
        return this.request.post(
            `${this.ENDPOINT}/take-in-handle/${id}`);
    }

    saveAttachment(data) {
        return this.request.post(
            `${this.ENDPOINT}/attachment`,
            data
        );
    }


    saveReply(data) {
        return this.request.post(
            `${this.ENDPOINT}/reply`,
            data
        );
    }

    updateReply(id, data) {
        return this.request.put(
            `${this.ENDPOINT}/reply/${id}`,
            data
        );
    }


    deleteCategory(id) {
        return this.request.delete(`${this.ENDPOINT}/category/${id}`);
    }
}
