<div [ngBusy]="busy">
    <div *ngIf="dettaglio">
        <div class="row">
            <div class="col-12 col-lg-6 col-xl-3">
                <div class="card card-icon bg-gradient-dark elevation-1 zoom" [routerLink]="['/contratti/lista']">
                    <div class="card-body">
                        <i class="fas fa-arrow-left bg-icon" style="color: #FFFFFF40"></i>
                        <p class="lead mb-0">Lista contratti</p>
                        <h1 class="display-6 mb-0">Torna indietro</h1>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-3">
                <div class="card card-icon bg-tiscali-gradient elevation-1">
                    <div class="card-body">
                        <i class="fas fa-wallet bg-icon" style="color: #33333340"></i>
                        <p class="lead mb-0">Questo contratto ti garantisce</p>
                        <h1 class="display-6 mb-0">-</h1>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-3">
                <div class="card card-icon bg-gradient-success elevation-1">
                    <div class="card-body">
                        <i class="far fa-check-circle bg-icon" style="color: #33333340"></i>
                        <p class="lead mb-0">Stato contratto</p>
                        <h1 class="display-6 mb-0">{{ dettaglio.contratto_stato }}</h1>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-3">
                <div class="card card-icon bg-gradient-info elevation-1">
                    <div class="card-body">
                        <i class="fas fa-info-circle bg-icon" style="color: #33333340"></i>
                        <p class="lead mb-0">Codice Cliente</p>
                        <h1 class="display-6 mb-0">{{ dettaglio.codicecliente }}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-4">
                <!--        <div class="card card-detail bg-gradient-light elevation-1 d-block d-lg-none">-->
                <!--          <div class="card-header">-->
                <!--            <h1 class="display-7 d-inline-block mb-0">Commenti</h1>-->
                <!--            <div class="card-tools"><i class="fa fa-comments text-1-5"></i></div>-->
                <!--          </div>-->
                <!--          <div class="card-body p-0">-->
                <!--            <div class="direct-chat-messages">-->
                <!--              &lt;!&ndash;              globals.apiUrl + '/users/'+employee.id+'/image'&ndash;&gt;-->
                <!--              <ng-container *ngFor="let comment of dettaglio.comments">-->
                <!--                <div class="direct-chat-msg" [ngClass]="{'right': comment.customer === ''}">-->
                <!--                  <div class="direct-chat-infos clearfix">-->
                <!--                    <span class="direct-chat-name float-left">-->
                <!--                    <ng-container *ngIf="comment.customer === ''">{{comment.creator_meta}}</ng-container>-->
                <!--                    <ng-container *ngIf="comment.customer !== ''">{{comment.customer_meta}}</ng-container>-->
                <!--                    </span>-->
                <!--                    <span-->
                <!--                      class="direct-chat-timestamp float-right">{{ comment.createdtime | date:'medium':'+0200' }}</span>-->
                <!--                  </div>-->
                <!--                  &lt;!&ndash; /.direct-chat-infos &ndash;&gt;-->
                <!--                  <img class="direct-chat-img" *ngIf="comment.customer !== ''" src="/assets/img/user2-160x160.jpg"-->
                <!--                       alt="Message User Image">-->
                <!--                  <img class="direct-chat-img" *ngIf="comment.customer === ''"-->
                <!--                       [src]="globals.apiUrl + '/users/'+comment.creator+'/image'" alt="Message User Image">-->
                <!--                  &lt;!&ndash; /.direct-chat-img &ndash;&gt;-->
                <!--                  <div class="direct-chat-text">-->
                <!--                    {{ comment.commentcontent }}-->
                <!--                  </div>-->
                <!--                  &lt;!&ndash; /.direct-chat-text &ndash;&gt;-->
                <!--                </div>-->
                <!--              </ng-container>-->
                <!--            </div>-->
                <!--            <div class="card-footer">-->
                <!--              <form [formGroup]="replyForm">-->
                <!--                <div class="input-group">-->
                <!--                  <input type="text" formControlName="reply" name="message" placeholder="Inserisci messaggio ..."-->
                <!--                         class="form-control">-->
                <!--                  <span class="input-group-append">-->
                <!--                      <button type="submit" (click)="addComment()" class="btn btn-primary">Aggiungi Commento</button>-->
                <!--                    </span>-->
                <!--                </div>-->
                <!--              </form>-->
                <!--            </div>-->
                <!--          </div>-->
                <!--        </div>-->
                <div class="card card-detail bg-gradient-light elevation-1">
                    <div class="card-header">
                        <h1 class="display-7 d-inline-block mb-0">Cliente</h1>
                        <div class="card-tools"><i class="fa fa-user text-1-5"></i></div>
                    </div>
                    <div class="card-body row">
                        <ng-container *ngIf="dettaglio.customer.business === 1">
                            <div class="col-12 col-xl-6 form-group">
                                <p class="lead">Ragione Sociale</p>
                                <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                       value="{{ dettaglio.ragsociale }}"/>
                            </div>
                            <div class="col-12 col-xl-6 form-group">
                                <p class="lead">Partita IVA</p>
                                <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                       [value]="dettaglio.customer.partita_iva"/>
                            </div>
                            <div class="col-12 col-xl-6 form-group">
                                <p class="lead">Sede Legale</p>
                                <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                       value="{{ dettaglio.customer.sede_localita }}, {{ dettaglio.customer.sede_provincia }}"/>
                            </div>
                            <div class="col-12 col-xl-6 form-group">
                                <p class="lead">All'indirizzo</p>
                                <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                       value="{{ dettaglio.customer.sede_indirizzo }}, {{ dettaglio.customer.sede_civico }}"/>
                            </div>
                        </ng-container>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Nome Completo</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   value="{{ dettaglio.customer.fullName }}"/>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Codice Fiscale</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="dettaglio.customer.codfiscale"/>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Sesso</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="dettaglio.customer.sesso"/>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Data di nascita</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="dettaglio.customer.nascita_data | amDateFormat: 'DD/MM/YYYY'"/>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Telefono</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="dettaglio.customer.telefono"/>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Nato a</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   value="{{ dettaglio.customer.nascita_localita }}, {{ dettaglio.customer.nascita_provincia }}"/>
                        </div>
                        <div class="col-12">
                            <hr>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Residente in</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   value="{{ dettaglio.customer.residenza_localita }}, {{ dettaglio.customer.residenza_provincia }}"/>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">All'indirizzo</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   value="{{ dettaglio.customer.residenza_indirizzo }}, {{ dettaglio.customer.residenza_civico }}"/>
                        </div>
                        <div class="col-12">
                            <hr>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Documento d'identità</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="dettaglio.customer.documento_tipo"/>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Numero</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="dettaglio.customer.documento_numero"/>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Data di rilascio</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="dettaglio.customer.documento_rilascio | amDateFormat: 'DD/MM/YYYY'"/>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Data di scadenza</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="dettaglio.customer.documento_scadenza | amDateFormat: 'DD/MM/YYYY'"/>
                        </div>
                        <div class="col-12">
                            <hr>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Cellulare</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="dettaglio.customer.telefono"/>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">E-Mail</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="dettaglio.customer.email"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="card card-detail bg-gradient-light elevation-1">
                    <div class="card-header">
                        <h1 class="display-7 d-inline-block mb-0">Contratto</h1>
                        <div class="card-tools"><i class="fa fa-file-signature text-1-5"></i></div>
                    </div>
                    <div class="card-body row">
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Brand</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="dettaglio.operatore"/>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Tipo Servizio</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="dettaglio.pista"/>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Cliente</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="dettaglio.famiglia"/>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Tipo Contratto</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="dettaglio.sottofamiglia"/>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Offerta</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="dettaglio.offerta"/>
                        </div>
                        <div class="col-12 col-xl-6 form-group" *ngFor="let seriale of dettaglio.seriali">
                            <p class="lead">{{seriale.campo}}</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="seriale.valore"/>
                        </div>
                        <div class="col-12 col-xl-6 form-group" *ngFor="let campo of dettaglio.campi">
                            <p class="lead">{{campo.campo}}</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="campo.valore"/>
                        </div>
                    </div>
                </div>

                <div class="card card-detail bg-gradient-light elevation-1" *ngIf="dettaglio.portabilita">
                    <div class="card-header">
                        <h1 class="display-7 d-inline-block mb-0">Portabilità</h1>
                        <div class="card-tools"><i class="fa fa-file-signature text-1-5"></i></div>
                    </div>
                    <div class="card-body row">
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Operatore di provenienza</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="dettaglio.portabilita.operatore"/>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Seriale</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="dettaglio.portabilita.seriale"/>
                        </div>
                        <div class="col-12 col-xl-6 form-group">
                            <p class="lead">Numero</p>
                            <input type="text" class="form-control form-control-sm" [attr.disabled]="true"
                                   [value]="dettaglio.portabilita.numero"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="alert alert-warning alert-dismissible" *ngIf="!dettaglio.pda_path">
                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                    <h5><i class="icon fas fa-exclamation-triangle"></i> Attenzione!</h5>
                    Non hai ancora caricato il Contratto Firmato.
                    Per completare la procedura <a class="text-dark text-bold"
                                                   [routerLink]="['/contratti/pda/' + dettaglio.id]">clicca
                    qui.</a>
                </div>

                <div class="card card-detail bg-gradient-light elevation-1">
                    <div class="card-header">
                        <h1 class="display-7 d-inline-block mb-0">Documenti</h1>
                        <div class="card-tools"><i class="fa fa-folder text-1-5"></i></div>
                    </div>
                    <div class="card-body p-0">
                        <app-documents-modal #showModal></app-documents-modal>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item d-flex align-items-center"
                                *ngFor="let document of dettaglio.documents">
                                <div class="flex-1">
                                    <p><i class="fa fa-file-download mr-2"></i>{{ document.nome }}</p>
                                    <p class="text-muted text-sm"></p>
                                </div>
                                <div class="flex-1 text-right">
                                    <!--                  <button-->
                                    <!--                    *ngIf="dettaglio.contratto_stato === 'Da Gestire' && document.tipologia === 'Contratto Firmato'"-->
                                    <!--                    class="btn btn-sm btn-success mr-2" [routerLink]="['/contratti/pda/' + dettaglio.id]">Ricarica-->
                                    <!--                  </button>-->
                                    <button class="btn btn-sm btn-info" (click)="showModal.showDocument(document.url)">
                                        Mostra
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="card card-detail bg-gradient-light elevation-1" *ngIf="dettaglio.note_operatore">
                    <div class="card-header">
                        <h1 class="display-7 d-inline-block mb-0">Note dall'operatore</h1>
                        <div class="card-tools"><i class="fas fa-list text-1-5"></i></div>
                    </div>
                    <div class="card-body" [innerText]="dettaglio.note_operatore">
                    </div>
                </div>
                <!--        <div class="card card-detail bg-gradient-light elevation-1 d-none d-lg-block">-->
                <!--          <div class="card-header">-->
                <!--            <h1 class="display-7 d-inline-block mb-0">Commenti</h1>-->
                <!--            <div class="card-tools"><i class="fa fa-comments text-1-5"></i></div>-->
                <!--          </div>-->
                <!--          <div class="card-body p-0">-->
                <!--            <div class="direct-chat-messages">-->
                <!--              &lt;!&ndash;              globals.apiUrl + '/users/'+employee.id+'/image'&ndash;&gt;-->
                <!--              <ng-container *ngFor="let comment of dettaglio.comments">-->
                <!--                <div class="direct-chat-msg" [ngClass]="{'right': comment.customer === ''}">-->
                <!--                  <div class="direct-chat-infos clearfix">-->
                <!--                    <span class="direct-chat-name float-left">-->
                <!--                    <ng-container *ngIf="comment.customer === ''">{{comment.creator_meta}}</ng-container>-->
                <!--                    <ng-container *ngIf="comment.customer !== ''">{{comment.customer_meta}}</ng-container>-->
                <!--                    </span>-->
                <!--                    <span-->
                <!--                      class="direct-chat-timestamp float-right">{{ comment.createdtime | date:'medium':'+0200' }}</span>-->
                <!--                  </div>-->
                <!--                  &lt;!&ndash; /.direct-chat-infos &ndash;&gt;-->
                <!--                  <img class="direct-chat-img" *ngIf="comment.customer !== ''" src="/assets/img/user2-160x160.jpg"-->
                <!--                       alt="Message User Image">-->
                <!--                  <img class="direct-chat-img" *ngIf="comment.customer === ''"-->
                <!--                       [src]="globals.apiUrl + '/users/'+comment.creator+'/image'" alt="Message User Image">-->
                <!--                  &lt;!&ndash; /.direct-chat-img &ndash;&gt;-->
                <!--                  <div class="direct-chat-text">-->
                <!--                    {{ comment.commentcontent }}-->
                <!--                  </div>-->
                <!--                  &lt;!&ndash; /.direct-chat-text &ndash;&gt;-->
                <!--                </div>-->
                <!--              </ng-container>-->
                <!--            </div>-->
                <!--            <div class="card-footer">-->
                <!--              <form [formGroup]="replyForm">-->
                <!--                <div class="input-group">-->
                <!--                  <input type="text" formControlName="reply" name="message" placeholder="Inserisci messaggio ..."-->
                <!--                         class="form-control">-->
                <!--                  <span class="input-group-append">-->
                <!--                      <button type="submit" (click)="addComment()" class="btn btn-primary">Aggiungi Commento</button>-->
                <!--                    </span>-->
                <!--                </div>-->
                <!--              </form>-->
                <!--            </div>-->
                <!--          </div>-->
                <!--        </div>-->
            </div>
        </div>
    </div>
</div>
