/* tslint:disable:variable-name */
import {Document} from '@models/document';
import {LineItemFinalDetail} from '@models/line-item-final-detail';
import {LineItem} from '@models/line-item';

export class SalesOrder {
    createdtime: string;
    contabile: string;
    description: string;
    documents: Document[] = [];
    hdnGrandTotal: string;
    id: string;
    invoicestatus: string;
    label: string;
    pre_tax_total: string;
    salesorder_no: string;
    ship_city: string;
    ship_code: string;
    ship_pobox: string;
    ship_state: string;
    ship_street: string;
    so_pagamento: string;
    sostatus: string;
    source: string;
    spese_di_spedizione: number;
    LineItems: LineItem[];
    cf_1203: string;
    estratto_conto: string;
    totale_ordine: number;
    totale_tasse: number;
    totale_senza_sped: number;
    valore_parziale: number;
    compensazione_parziale: number;
    pagato: number;
    ricevuta: string;
    config_name: string;
    config_iban: string;

    get grandtotal() {
        return parseFloat(this.hdnGrandTotal);
    }

    get pretaxtotal() {
        return parseFloat(this.pre_tax_total);
    }

    get totaltax() {
        return this.grandtotal - this.pretaxtotal;
    }

    fromJSON(result) {
        const temp = new SalesOrder();
        Object.assign(temp, result);
        temp.LineItems = new LineItem().fromArray(result.LineItems);
        // temp.LineItems_FinalDetails = new SalesOrderFinalDetail().fromArray(result.LineItems_FinalDetails);
        return temp;
    }

    fromArray(result: []) {
        const array: SalesOrder[] = [];
        for (const item of result) {
            const tempItem: SalesOrder = new SalesOrder();
            Object.assign(tempItem, item);
            array.push(tempItem);
        }
        return array;
    }
}
