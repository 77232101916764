import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DealerService } from "@services/dealer.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Dealer } from "@app/models/dealer";

@Component({
  selector: "app-negozio-select",
  templateUrl: "./negozio-select.component.html",
  styleUrls: ["./negozio-select.component.css"],
})
export class NegozioSelectComponent implements OnInit {
  data = [];

  @Input()
  model: any;
  @Input()
  small = false;

  @Input()
  bindLabel = 'company_name';

  @Input()
  disabled = false;
  
  @Output() isValid = new EventEmitter();
  @Output() onChange = new EventEmitter();

  form: FormGroup;

  loading = false;

  constructor(
    private dealerService: DealerService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.dealerService.list({fields: 'id,company_name', expand: 'city,province', 'per-page': 0}).subscribe((data) => {
      this.data = new Dealer().fromArray(data.rows);
      this.loading = false;
    });

    // this.isValid.emit();
  }
}
