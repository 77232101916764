import {Component, Input, OnInit} from '@angular/core';
import {DashboardService} from "@services/dashboard.service";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-autoload-target',
  templateUrl: './autoload-target.component.html',
  styleUrls: ['./autoload-target.component.css']
})
export class AutoloadTargetComponent implements OnInit {
    _urlTarget: string;
    data: any;

    @Input() set urlTarget(value: string) {
        this._urlTarget = value;

        if (this._urlTarget !== undefined) {
            this.loadTarget();
        }
    }

    constructor(
        private dashboardService: DashboardService,
        private spinner: NgxSpinnerService
    ) {
    }

    ngOnInit() {

    }

    private loadTarget() {
        this.spinner.show('loader-target');
        this.dashboardService.get(this._urlTarget)
            .subscribe((data) => {
                this.data = data.result;
                this.spinner.hide('loader-target');
            });
    }

}
