import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { AuthenticationService } from "@services/authentication.service";
import { User } from "@models/user";

@Directive({
  selector: "[isVendorEnabled]",
})
export class IsVendorEnabledDirective implements OnInit {
  private user: User;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthenticationService
  ) {
    this.authService.currentUser.subscribe((user) => (this.user = user));
  }

  @Input() set isVendorEnabled(operatori: [string]) {
    if (
      this.user &&
      operatori.every((op: string) => this.user.profile.operatori.includes(op))
    ) {
      // If condition is true add template to DOM
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      // Else remove template from DOM
      this.viewContainer.clear();
    }
  }

  ngOnInit(): void {}
}
