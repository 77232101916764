<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)"
             size="default"
             color="#fff"
             type="square-jelly-box"
             name="loader-riepilogo"
             [fullScreen]="false">
    <p style="color: white"> Caricamento... </p>
</ngx-spinner>
<div class="row" *ngIf="data">
    <div *ngFor="let col of data.firstRow; let i=index;" class="text-center {{col.size}}"
         [ngClass]="{'border-right': i < data.firstRow.length-1}">
        <div class="py-2" [ngClass]="{'box-hover-dark rounded': col.hasOwnProperty('url')}"
             (click)="dbm.show(col.url, 'Dettaglio')">
            <img [src]="col.image" height="70px"/>
            <p class="lead">{{col.title}}</p>
            <p>{{col.subtitle}}</p>
        </div>
    </div>
</div>
<hr>
<div class="row" *ngIf="data">
    <div *ngFor="let col of data.secondRow; let i=index;" class="{{col.size}}"
         [ngClass]="{'border-right': i < data.secondRow.length-1}">
        <div class="col-12">
            <div *ngFor="let row of col.rows; let j=index;"
                 [ngClass]="{'mb-3': j < col.rows.length-1}">
                <div class="py-2 d-flex align-items-center" [ngClass]="{'box-hover-dark rounded': row.hasOwnProperty('url')}"
                     (click)="dbm.show(row.url, 'Dettaglio')">
                    <img [src]="row.image" width="45px" class="d-inline-flex ml-2"/>
                    <div class="d-inline-block pl-2">
                        <p class="lead">{{row.title}}</p>
                        <p>{{row.subtitle}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
