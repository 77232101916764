import {Component, OnInit} from '@angular/core';
import {DataService} from '@services/data.service';
import {ActivatedRoute} from '@angular/router';
import {SalesOrder} from '@models/sales-order';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '@services/authentication.service';

@Component({
  selector: 'app-shop-detail',
  templateUrl: './shop-detail.component.html',
  styleUrls: ['./shop-detail.component.scss']
})
export class ShopDetailComponent implements OnInit {
  order: SalesOrder = new SalesOrder();
  busy: Subscription;

  constructor(
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute
  ) { }

  get me() {
    return this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    this.refreshOrder();
  }

  refreshOrder() {
    this.busy = this.dataService
      .get(`/dettaglio-ordine?id=${this.route.snapshot.paramMap.get('id')}`)
      .subscribe((data: any) => {
        this.order = new SalesOrder().fromJSON(data.result);
      });
  }

  pagaOnline() {
    window.location.href = 'https://api.master-attivazioni.it/shop-orders/pos/' + this.order.salesorder_no;
  }
}
