import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { RequestService } from "./request.service";

@Injectable({
  providedIn: "root",
})
export class RechargeService {
  ENDPOINT = "recharges";

  constructor(public request: RequestService) {}

  list(params = {}) {
    return this.request
      .get(`${this.ENDPOINT}/list`, params, { observe: "response" })
      .pipe(
        map((data: HttpResponse<any>) => {
          return {
            rows: data.body,
            pagination: {
              totalCount: parseInt(
                data.headers.get("X-Pagination-Total-Count")
              ),
              page: parseInt(data.headers.get("X-Pagination-Current-Page")),
              pageSize: parseInt(data.headers.get("X-Pagination-Per-Page")),
              pages: parseInt(data.headers.get("X-Pagination-Page-Count")),
            },
          };
        })
      );
  }

  saveRecharge(data) {
    return this.request.post(`${this.ENDPOINT}`, data);
  }

  updateRecharge(id, data) {
    return this.request.post(`${this.ENDPOINT}/update/${id}`, data);
  }
}
