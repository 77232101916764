<div class="row" [ngBusy]="busy" *ngIf="ticket">
    <div class="col-12 col-lg-4 col-xl-4">
        <div class="card card-outline card-info">
            <div class="card-body">
                <h1 class="display-7">
                    <i class="fa fa-info-circle mr-2"></i>
                    Informazioni Ticket
                </h1>
                <div>
                    <hr>
                    <p class="lead font-weight-normal">Categoria</p>
                    <p class="text-muted">{{ ticket.categoria }}</p>
                    <hr>
                    <p class="lead font-weight-normal">ID Ticket</p>
                    <p class="text-muted">{{ ticket.id }}</p>
                    <hr>
                    <p class="lead font-weight-normal">Stato</p>
                    <p class="text-muted">{{ ticket.stato }}</p>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex">
                    <a [routerLink]="'/supporto/ticket'" class="btn btn-info flex-1 mr-1">Indietro</a>

                    <button *ngIf="ticket.stato === 'Chiuso'" class="btn btn-danger flex-1 mr-1" [disabled]="true">
                        Chiuso
                    </button>
                    <button *ngIf="ticket.stato !== 'Chiuso'" class="btn btn-success flex-1 mr-1" [disabled]="true">
                        Aperto
                    </button>
                </div>
            </div>
        </div>
        <div class="card card-detail bg-gradient-light elevation-1">
            <div class="card-header">
                <h1 class="display-7 d-inline-block mb-0">Allegati</h1>
                <div class="card-tools"><i class="fa fa-folder text-1-5"></i></div>
            </div>
            <div class="card-body p-0">
                <app-documents-modal #showModal></app-documents-modal>
                <div class="text-center" *ngIf="ticket.allegati.length === 0">
                    <p class="text-muted py-3">Non ci sono allegati.</p>
                </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex align-items-center"
                        *ngFor="let document of ticket.allegati">
                        <div class="flex-1">
                            <p><i class="fa fa-file-download mr-2"></i> Allegato {{ document.sequence }}</p>
                            <p class="text-muted text-sm"></p>
                        </div>
                        <div class="flex-1 text-right">
                            <!--                  <button-->
                            <!--                    *ngIf="dettaglio.contratto_stato === 'Da Gestire' && document.tipologia === 'Contratto Firmato'"-->
                            <!--                    class="btn btn-sm btn-success mr-2" [routerLink]="['/contratti/pda/' + dettaglio.id]">Ricarica-->
                            <!--                  </button>-->
                            <button class="btn btn-sm btn-info" (click)="showModal.showDocument(document.doc_path)">
                                Mostra
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="card-footer">
                <app-documents-create-modal #newAttachment (done)="this.refreshTicket()"></app-documents-create-modal>
                <button class="btn btn-sm bg-gradient-info" (click)="newAttachment.showModal(ticket.id.toString(), 'ticket')">Aggiungi Allegato</button>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-8 col-xl-8">
        <div class="card card-outline card-success">
            <div class="card-body">
                <h1 class="display-7">
                    <i class="fa fa-align-justify mr-2"></i>
                    {{ ticket.dealer | titlecase}}
                </h1>
                <hr>
                <p [innerText]="ticket.testo"></p>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <h1 class="display-7">
                    <i class="fa fa-comment-dots text-primary mr-2"></i>
                    Risposte
                </h1>
                <ng-container *ngFor="let comment of ticket.risposte">
                    <hr>
                    <p class="lead font-weight-normal">
                        <ng-container>{{comment.utente}}</ng-container>
                        <span
                                class="text-muted text-sm float-right">Inviata il  {{ comment.created | date:'medium':'+0200' }}</span>
                    </p>
                    <p class="text-muted" [innerText]="comment.risposta"></p>
                </ng-container>
            </div>
        </div>

        <div class="card" *ngIf="ticket.stato !== 'Chiuso'">
            <div class="card-body">
                <h1 class="display-7">
                    <i class="fa fa-reply mr-2"></i>
                    Aggiungi una risposta
                </h1>
                <hr>
                <form [formGroup]="replyForm" (ngSubmit)="addComment()">
                    <div class="form-group">
                        <textarea formControlName="risposta" class="form-control" rows="5"></textarea>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-info btn btn-block">
                            Invia risposta
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
