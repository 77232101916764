import {SubProduct} from '@models/sub-product';

export class CartSubProduct {
    product: SubProduct;
    id: string;
    quantity: number;
    total_price: any;
    product_variant_id: any;

    get qty() {
        return parseInt(String(this.quantity), 10);
    }
}
