import { Component, OnInit } from '@angular/core';
import {AlertService} from '@services/alert.service';

@Component({
  selector: 'app-toasts-container',
  templateUrl: './toasts-container.component.html',
  styleUrls: ['./toasts-container.component.scss']
})
export class ToastsContainerComponent implements OnInit {

  constructor(public alertService: AlertService) { }

  ngOnInit() {
  }

}
