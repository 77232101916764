/* tslint:disable:variable-name */
export class SubProduct {
  productid: string;
  productname: string;
  unit_price: string;
  product_code: string;
  crmid: string;
  qty_per_unit: string;

  get qty_per_unit_n(): number {
    return parseInt(this.qty_per_unit, 10);
  }

  get price() {
    return parseFloat(this.unit_price);
  }

  fromArray(result: []) {
    const array: SubProduct[] = [];
    for (const item of result) {
      const tempItem: SubProduct = new SubProduct();
      Object.assign(tempItem, item);
      array.push(tempItem);
    }
    return array;
  }
}
