import {Component, OnInit} from '@angular/core';
import {DashboardService} from '@services/dashboard.service';
import {Subscription} from 'rxjs';
import {DashboardStartup, DashboardStartupDict} from '@models/master/dashboard';
import {AuthenticationService} from '@services/authentication.service';
import * as moment from 'moment';
import { Helpers } from '@app/helpers/helpers';

@Component({
    selector: 'app-master-dashboard',
    templateUrl: './master-dashboard.component.html',
    styleUrls: ['./master-dashboard.component.css']
})
export class MasterDashboardComponent implements OnInit {
    activeTab = null;
    busy: Subscription;

    startup: DashboardStartup;
    curStartup: DashboardStartupDict;

    defaultMonthRange;
    apiFormat = 'YYYY-MM';
    periodi = Helpers.getPeriods();

    _periodo: string;
    _canale: string;
    _proiezione: string;
    urlVendite: string;
    urlRiepilogo: string;
    urlPuntiVendita: string;
    urlTarget: string;
    urlFatturato: string;
    urlOfferte: string;

    ready = false;
    operatori = [];

    get background() {
        switch (this.activeTab) {
            case 'Tim':
            case 'Tim Fwa':
            case 'Tim Business NIP':
            case 'Tim Business ULL':
            case 'Tim Business Fisso':
                return 'bg-tim-gradient';
            case 'Kena Mobile':
            case 'Kena Mobile Stands':
                return 'bg-kena-gradient';
            case 'Tiscali Mobile':
            case 'Tiscali Fisso':
            case 'Tiscali LTE':
                return 'bg-tiscali-gradient';
            case 'Eni gas e luce':
                return 'bg-eni-gradient';
            case 'Sky':
                return 'bg-sky-gradient';
        }

        return 'bg-gradient-info';
    };

    set periodo(value: string) {
        this._periodo = value;
        this.updateUrls();
    }

    get periodo() {
        return this._periodo;
    }

    set proiezione(value: string) {
        this._proiezione = value;
        this.updateUrls();
    }

    get proiezione() {
        return this._proiezione;
    }

    set canale(value: string) {
        this._canale = value;
        this.updateUrls();
    }

    get canale() {
        return this._canale;
    }

    constructor(private dashboardService: DashboardService, private authService: AuthenticationService) {
        this.defaultMonthRange = [moment().startOf('month')];

        this.operatori = authService.operatori;
        this.activeTab = this.operatori[0];
    }

    ngOnInit() {
        this.busy = this.dashboardService.get('/home')
            .subscribe((data) => {
                this.startup = data.result;
                this.ready = true;
                this.changeBrand();
            });
    }

    setSelectedDateRange(from: any) {
        console.log(from);
        this.periodo = moment(from).format(this.apiFormat);
    }

    changeBrand() {
        // switch (this.activeTab) {
        //     case 'Sky':
        //         this.curStartup = this.startup.sky;
        //         this.brand = 'Sky';
        //         break;
        //     case 'Eni gas e luce':
        //         this.curStartup = this.startup.eni;
        //         this.brand = 'Eni gas e luce';
        //         break;
        //     case 3:
        //         this.curStartup = this.startup.tiscali;
        //         this.brand = 'Tiscali';
        //         break;
        //     case 2:
        //         this.curStartup = this.startup.kena;
        //         this.brand = 'Kena Mobile';
        //         break;
        //     case 1:
        //         this.curStartup = this.startup.tim;
        //         this.brand = 'Tim';
        //         break;
        // }

        this.curStartup = this.startup[this.activeTab];

        this.periodo = this.startup.periodi[0].value;
        this.canale = this.curStartup.canali[0];
        this.proiezione = 'No';

        this.updateUrls();
    }

    updateUrls(specific = null) {
        this.urlRiepilogo = `/home-riepilogo?operatore=${this.activeTab}&periodo=${this.periodo}&canale=${this.canale}&proiezione=${this.proiezione}`;
        this.urlVendite = `/home-vendite?operatore=${this.activeTab}&periodo=${this.periodo}&canale=${this.canale}`;
        this.urlFatturato = `/home-fatturato?operatore=${this.activeTab}&periodo=${this.periodo}&canale=${this.canale}`;
        this.urlPuntiVendita = `/home-punti-vendita?operatore=${this.activeTab}&periodo=${this.periodo}&canale=${this.canale}`;
        this.urlOfferte = `/home-offerte?operatore=${this.activeTab}&periodo=${this.periodo}&canale=${this.canale}`;
        this.urlTarget = `/home-target?operatore=${this.activeTab}&periodo=${this.periodo}&canale=${this.canale}&proiezione=${this.proiezione}`;
    }

    getImage(op) {
        switch (op) {
            case 'Tim':
                return 'assets/img/brands/tim_mobile.png';
            case 'Tim Fwa':
                return 'assets/img/brands/tim_fisso.png';
            case 'Tim Business Fisso':
                return 'assets/img/brands/tim_business.png';
            case 'Kena Mobile':
            case 'Kena Mobile Stands':
                return 'assets/img/brands/kena.png';
            case 'Tiscali Mobile':
                return 'assets/img/brands/tiscali_mobile.png';
            case 'Tiscali Fisso':
                return 'assets/img/brands/tiscali_fisso.png';
            case 'Tiscali LTE':
                return 'assets/img/brands/tiscali_lte.png';
            case 'Eni gas e luce':
                return 'assets/img/brands/eni.png';
            case 'Sky':
                return 'assets/img/brands/sky.png';
        }
    }
}
