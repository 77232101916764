<div class="card bg-gradient-light">
    <form (ngSubmit)="loadDocument()">
        <div class="card-header">
            <h1 class="card-title" translate>Filters</h1>
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-12 col-lg-3">
                    <label for="companyName" translate>Company name</label>
                    <input id="companyName"
                           type="text" name="company_name" [(ngModel)]="filter.company_name"
                           class="form-control form-control-sm"
                    />
                </div>
                <div class="form-group col-12 col-lg-3">
                    <label for="category" translate>Category</label>
                    <ng-select [(ngModel)]="filter.category" [items]="categories" bindLabel="name" id="category"
                               [loading]="categoryLoading" class="form-control-sm"
                               bindValue="name" [searchable]="true" name="category"
                               (search)="loadCategories($event?.term)"></ng-select>
                </div>
                <div class="form-group col-12 col-lg-3">
                    <label for="documentType" translate="">Document type</label>
                    <ng-select [(ngModel)]="filter.type" name="type" class="form-control-sm"
                               id="documentType">
                        <ng-option *ngFor="let type of types" translate
                                   [value]="type.key">{{type.name}}</ng-option>
                    </ng-select>
                </div>
                <div class="form-group col-12 col-lg-3">
                    <label for="documentNumber" translate>Document number</label>
                    <input
                            type="text" id="documentNumber"
                            name="number" [(ngModel)]="filter.number"
                            class="form-control form-control-sm"
                    />
                </div>
                <div class="form-group col-12 col-lg-3">
                    <label for="documentDate" translate>Document date</label>
                    <input id="documentDate" class="form-control form-control-sm" type="text" daterangepicker
                           [(ngModel)]="filter.date" name="date"
                           (selected)="generateDateRangeString($event.start, $event.end,'date')"
                    />
                </div>
                <div class="form-group col-12 col-lg-3">
                    <label for="vatDate" translate>VAT date</label>
                    <input id="vatDate"
                           type="text" daterangepicker [(ngModel)]="filter.vat_date"
                           name="vat_date" class="form-control form-control-sm"
                           (selected)="generateDateRangeString($event.start, $event.end,'vat_date')"
                    />
                </div>
                <div class="form-group col-12 col-lg-3">
                    <label for="vatType" translate>VAT type</label>
                    <ng-select id="vatType" name="vat_type" [(ngModel)]="filter.vat_type" class="form-control-sm">
                        <ng-option translate *ngFor="let vatType of vatTypes"
                                   [value]="vatType.key">{{vatType.name}}</ng-option>
                    </ng-select>
                </div>
                <div class="form-group col-12 col-lg">
                    <label for="credit" translate>Credit/Debit</label>
                    <ng-select id="credit" name="isCredit" [(ngModel)]="filter.is_credit" class="form-control-sm">
                        <ng-option translate value="1">Credit</ng-option>
                        <ng-option translate value="0">Debit</ng-option>
                    </ng-select>
                </div>
                <div class="form-group col-12 col-lg">
                    <label for="welded" translate>Welded</label>
                    <ng-select [(ngModel)]="filter.welded" class="form-control-sm" id="welded" name="welded">
                        <ng-option translate [value]="weldedFilter.key"
                                   *ngFor="let weldedFilter of weldedFilters">{{weldedFilter.label}}</ng-option>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button class="btn btn-dark btn-sm mr-2" type="button" (click)="resetFilter()" translate>
                Reset
            </button>
            <button
                    class="btn btn-primary btn-sm"
                    type="submit" translate
            >
                Filter
            </button>
        </div>
    </form>
</div>
<div class="card">
    <div class="card-header">
        <div class="row">
            <div class="col-lg-10 col-12">
                <h4 class="mb-0" translate>Documents</h4>
            </div>
            <div class="col-lg-2 col-12">
                <button class="btn btn-success btn-sm float-right" type="button" translate
                        (click)="showDocumentModal()">
                    Add Document
                </button>
            </div>
        </div>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th translate>No</th>
                <th translate>Company name</th>
                <th translate>Category</th>
                <th translate>Type</th>
                <th translate>Date</th>
                <th translate>VAT date</th>
                <th translate>Credit/Debit</th>
                <th translate class="text-right">Total to pay</th>
                <th translate class="text-right">VAT</th>
                <th translate class="text-right">WHT</th>
                <th translate class="text-right">Net</th>
                <th translate class="text-center">Draft</th>
                <th translate class="text-center">Welded</th>
                <th class="text-right"></th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="table-documents"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let document of documents">
                <td>{{document.number}}</td>
                <td>{{document.company_name}}</td>
                <td>{{document.category}}</td>
                <td translate>{{findType(document.type)}}</td>
                <td>{{document.date|amDateFormat:localFormat}}</td>
                <td>{{document.vat_date|amDateFormat:localFormat}}</td>
                <td>
                    <span class="text-success"
                          *ngIf="document.is_credit === 1 || document.is_credit === '1'">Credit</span>
                    <span class="text-danger" *ngIf="document.is_credit === 0|| document.is_credit === '0'">Debit</span>
                </td>
                <td class="text-right">{{document?.total_to_pay|currency:currency}}</td>
                <td class="text-right">{{document.vat_amount|currency:currency}}</td>
                <td class="text-right">{{document.withholding_tax|currency:currency}}</td>
                <td class="text-right">{{document.net_amount|currency:currency}}</td>
                <td class="text-center w-20">
                    <i class="text-success fas fa-check" *ngIf="document.draft==1"></i>
                    <i class="text-danger fas fa-times" *ngIf="document.draft==0"></i>
                </td>
                <td class="text-center  w-20">
                    <i class="text-success fas fa-check" *ngIf="document.welded==1"></i>
                    <i class="text-danger fas fa-times" *ngIf="document.welded==0"></i>
                </td>
                <td class="text-right">
                    <button class="btn btn-link" (click)="showDetailModal(document)">
                        <i class=" fa fa-eye text-info"></i>
                    </button>
                    <button class="btn btn-link" (click)="showDocumentModal(document)">
                        <i class=" fa fa-edit text-muted"></i>
                    </button>
                    <button class="btn btn-link" [swal]="deleteDocumentOptions"
                            (confirm)="deleteDocument(document.id)">
                        <i class="fa fa-trash text-danger"></i>
                    </button>
                </td>
            </tr>
            <tr *ngIf="documents?.length<=0">
                <td class="text-muted" colspan="15" translate>List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="documents?.length>0">
    <span><span translate>There are</span><b> {{ this.documentsPagination.totalCount }}</b>
        {{this.documentsPagination.totalCount <= 1 ? 'document' : 'documents'|translate}}.</span>
        <ngb-pagination *ngIf="documentsPagination.pageCount>1"
                        size="sm"
                        [pageSize]="documentsPagination.perPage"
                        [maxSize]="10"
                        [(page)]="documentsPagination.currentPage"
                        [collectionSize]="documentsPagination.totalCount"
                        (pageChange)="loadDocument()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>

<ng-template #documentFormTemplate let-modal>
    <app-document-modal [documents]="documents" [selectedDocument]="selectedDocument"
                        (refresh)="loadDocument()"></app-document-modal>
</ng-template>
<ng-template #documentDetailTemplate let-modal>
    <app-document-view [document]="selectedDocument" [showPayments]="true"></app-document-view>
</ng-template>
