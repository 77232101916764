import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

export interface DataRange {
  from: Date;
  to?: Date;
}

@Component({
  selector: "app-data-range",
  templateUrl: "./data-range.component.html",
  styleUrls: ["./data-range.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DataRangeComponent),
      multi: true,
    },
  ],
})
export class DataRangeComponent implements OnInit, ControlValueAccessor {
  private hoveredDate: NgbDate;
  private fromDate: NgbDate;
  private toDate: NgbDate;

  @Input()
  // tslint:disable-next-line:variable-name
  private _value: DataRange;

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.propagateChange(this._value);
  }

  propagateChange = (_: any) => {};

  constructor() {}

  ngOnInit(): void {}

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.saveDate(this.fromDate, this.toDate);
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.saveDate(this.fromDate, this.toDate);
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {}

  writeValue(value: DataRange): void {
    if (value) {
      this.fromDate = new NgbDate(value.from.getFullYear(), value.from.getMonth(), value.from.getDate());
      this.toDate = new NgbDate(value.to.getFullYear(), value.to.getMonth(), value.to.getDate());
      this.saveDate(this.fromDate, this.toDate);
    }
  }

  saveDate(fromDate: NgbDate, toDate: NgbDate) {
    toDate !== null
      ? (this.value = {
          from: new Date(fromDate.year + "-" + fromDate.month + "-" + fromDate.day),
          to: new Date(toDate.year + "-" + toDate.month + "-" + toDate.day),
        })
      : (this.value = {
          from: new Date(fromDate.year + "-" + fromDate.month + "-" + fromDate.day),
        });
  }

  getRangeToText() {
    if (this.value) {
      if (this.value.to) {
        return (
          "dal " +
          this.value.from.getDate() +
          "-" +
          (this.value.from.getMonth() + 1) +
          "-" +
          this.value.from.getFullYear() +
          " al " +
          this.value.to.getDate() +
          "-" +
          (this.value.to.getMonth() + 1) +
          "-" +
          this.value.to.getFullYear()
        );
      } else {
        return this.value.from.getDate() + "-" + (this.value.from.getMonth() + 1) + "-" + this.value.from.getFullYear();
      }
    } else {
      return "";
    }
  }
}
