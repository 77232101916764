import {Component, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {DataService} from '@services/data.service';
import {NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import {Caratteristica, OffertaCaratteristica, Servizio} from '@models/comparatore';
import {Router} from '@angular/router';

@Component({
    selector: 'app-comparator-index',
    templateUrl: './comparator-index.component.html',
    styleUrls: ['./comparator-index.component.scss']
})
export class ComparatorIndexComponent implements OnInit {
    busy: Subscription;
    private servizi: Servizio[] = [];

    @ViewChild('tabs', {static: false})
    tabs: NgbTabset;
    servizioSelezionato: Servizio = null;
    caratteristicaSelezionata: Caratteristica = null;
    offerte: OffertaCaratteristica[] = null;

    filtroBrand: any;
    filtroSegmento: any;
    filtroTipo: any;

    constructor(
        private dataService: DataService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        this.busy = this.dataService.get('/get-comparatore')
            .subscribe((data: any) => {
                this.servizi = data.result;
            });
    }

    goBack() {
        switch (this.tabs.activeId) {
            case 'caratteristica':
                this.tabs.select('servizio');
                break;
            case 'offerta':
                this.tabs.select('caratteristica');
                break;
        }
    }

    setServizio(servizio: Servizio) {
        this.servizioSelezionato = servizio;
        this.caratteristicaSelezionata = null;
        this.offerte = null;
        setTimeout(() => this.tabs.select('caratteristica'), 200);
    }

    setCaratteristica(caratteristica: Caratteristica) {
        this.caratteristicaSelezionata = caratteristica;
        this.offerte = null;
        setTimeout(() => this.tabs.select('offerta'), 200);
    }

    attiva(offerta: OffertaCaratteristica) {
        const payload = {
            id_operatore: offerta.id_operatore,
            id_pista: offerta.id_pista,
            id_famiglia: offerta.id_famiglia,
            id_sottofamiglia: offerta.id_sottofamiglia,
            id_offerta: offerta.id,
        };
        this.router.navigate(['/contratti/nuovo', payload],);
    }

    resetFiltri() {
        this.filtroBrand = null;
        this.filtroSegmento = null;
        this.filtroTipo = null;
        this.setFiltri();
    }

    setFiltri() {
        this.offerte = this.caratteristicaSelezionata.offerte.filter((item: OffertaCaratteristica) => {
            let show = this.filtroBrand ? item.operatore === this.filtroBrand : true;
            show = (this.filtroSegmento ? item.famiglia === this.filtroSegmento : true) && show;
            show = (this.filtroTipo  ? item.sottofamiglia === this.filtroTipo : true) && show;
            return show;
        });
    }

    getOfferte() {
        if (!this.offerte) {
            if (this.caratteristicaSelezionata) {
                this.offerte = this.caratteristicaSelezionata.offerte;
            }
        }

        return this.offerte;
    }
}
