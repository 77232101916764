import {Component, Input, OnInit} from '@angular/core';
import {ShopOrder} from '@models/e-pay/shop-order';
import {EPayService} from '@services/e-pay.service';

@Component({
    selector: 'app-receipt',
    templateUrl: './receipt.component.html',
    styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit {
    @Input() order: ShopOrder;
    currency = EPayService.currency;
    receipt: string;

    constructor() {
    }

    ngOnInit(): void {
        this.receipt = this.order.payments[0].receipt;
    }

    exportReceipt() {
        const content = document.getElementById('receipt');
        const receipt = content.innerHTML;
        // const file = new window.Blob([receipt]);
        //
        // const downloadAncher = document.createElement('a');
        // downloadAncher.style.display = 'none';
        //
        // const fileURL = URL.createObjectURL(file);
        // downloadAncher.href = fileURL;
        // downloadAncher.download = 'ricevuta';
        // downloadAncher.click();

        var mywindow = window.open('', 'PRINT', 'height=400,width=600');

        mywindow.document.write('<html><head><title>' + document.title  + '</title>');
        mywindow.document.write('</head><body>');
        mywindow.document.write(receipt);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();
    }
}
