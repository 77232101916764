<div class="card bg-gradient-light">
  <div class="card-header">
    <p class="lead">Menu</p>
  </div>
  <div class="card-body">
    <div class="input-group mb-3">
      <input type="text" placeholder="Cerca tra i prodotti..." class="form-control" [(ngModel)]="filter"
             (ngModelChange)="filterProducts()"/>
      <div class="input-group-append">
        <span class="input-group-text"><i class="fas fa-search"></i></span>
      </div>
    </div>

    <ng-container *ngIf="hasFilters">
      <p class="menu-header text-info">Filtri applicati</p>
      <ul class="list-group list-unstyled menu-list">
        <li *ngIf="categoryFilter" (click)="addCategoryFilter(null)"><i
          class="fa fa-times text-danger"></i>{{categoryFilter}}</li>
        <li *ngIf="brandFilter" (click)="addBrandFilter(null)"><i class="fa fa-times text-danger"></i>{{brandFilter}}
        </li>
      </ul>
    </ng-container>


    <div class="d-none d-lg-block">
      <p class="menu-header text-info">Categorie</p>
      <ul class="list-group list-unstyled menu-list">
        <li *ngFor="let category of categories" (click)="addCategoryFilter(category)"><i class="fa fa-caret-right"></i>
          {{ category }}
          <span class="float-right"><span
            class="badge badge-pill badge-success">{{ productsInCategory(category) }} </span></span>
        </li>
      </ul>
      <p class="menu-header text-info">Brands</p>
      <ul class="list-group list-unstyled menu-list">
        <li *ngFor="let brand of brands" (click)="addBrandFilter(brand)"><i class="fa fa-caret-right"></i>
          {{ brand }}
          <span class="float-right"><span
            class="badge badge-pill badge-success">{{ productsOfBrand(brand) }} </span></span>
        </li>
      </ul>
    </div>
  </div>
</div>
