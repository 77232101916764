import {Component, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Moment} from 'moment';
import * as moment from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {AuthenticationService} from '@app/services/authentication.service';
import {User} from '@app/models/user';
import {AffiliationService} from '@app/services/affiliation.service';
import {Dealer} from '@app/models/dealer';
import {Company} from '@app/models/company';
import {Affiliation, AffiliationList, RequiredDocument} from '@app/models/affiliation';
import {Subscription} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfirmOptionsService} from '@services/confirm-options.service';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-affilations',
    templateUrl: './affilations.component.html',
    styleUrls: ['./affilations.component.scss']
})
export class AffilationsComponent implements OnInit {
    @ViewChild('affilationsTemplate') private affilationsTemplate;

    private user: User;
    private modal: NgbModalRef;

    show = {
        any: false
    };
    isDealerValid = false;
    isOperatorValid = false;


    filterDealers: Dealer[];
    filterOperators: Company[];
    filterStatuses: Array<any>;
    formDealers: Dealer[];
    formOperators: Company[];
    formDocuments: RequiredDocument[] = [];
    formSelectedDealerId: number;
    formSelectedOperatorId: number;
    busyFormModal: Subscription;
    filterDaterangePickerOptions: any = {};
    affiliationItems: AffiliationList;
    selectedAffiliation = new Affiliation();
    isLoading = true;
    createDateFormat = 'H:mm DD/MM/YYYY';
    selectedDateRange = {
        from_date: '',
        to_date: ''
    };
    emptyAffiliationsListMessage;
    isFilterSelected = false;
    filterForm = {
        dealer: null,
        operator: null,
        state: null,
        date: null,
    };
    archivedSpinnerName = 'archived_spinner';
    inRevisionSpinnerName = 'in_revision_spinner';
    busy: any;

    constructor(
        private modalService: NgbModal,
        private authService: AuthenticationService,
        private affiliationService: AffiliationService,
        private dateRangePickerOptions: DaterangepickerConfig,
        public formBuilder: FormBuilder,
        private spinner: NgxSpinnerService
    ) {
        this.filterStatuses = this.affiliationService.getStatusList();
        this.authService.currentUser.subscribe(user => this.user = user);
        this.affiliationService.getDealerList().subscribe((dealers: Dealer[]) => {
            this.formDealers = dealers;
            this.filterDealers = dealers;
        });

        this.affiliationService.getOperatorList().subscribe((operators: Company[]) => {
            this.filterOperators = operators;
        });
        this.getAffiliationsList();
    }

    ngOnInit(): void {
    }

    toggleShow(val: boolean, index: any) {
        this.show[index] = val;
    }

    selectAffilation(affiliation?) {
        this.setSelectedAffiliation(affiliation);
        this.modal = this.modalService.open(this.affilationsTemplate, {size: 'xl'});
        this.modal.result.then((res) => {
        }).catch((res) => {
        });
    }

    handleDealerSelectedModal(dealerId) {
        this.formSelectedDealerId = null;
        this.filterForm.dealer = null;
        if (dealerId) {
            this.formSelectedDealerId = dealerId;
            this.filterForm.dealer = dealerId;
        }
    }

    setSelectedDateRange(from: Moment, to: Moment) {
        const selectedDateRange = this.getDateRangeString(from, to);
        this.filterForm.date = selectedDateRange;
    }

    private getDateRangeString(startDate: any, endDate: any) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        const start = moment(startDate);
        const end = moment(endDate);
        this.selectedDateRange.from_date = start.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.selectedDateRange.to_date = end.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        return start.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + end.format(localDateFormat);
    }

    showRequiredDocument(id, affiliation) {
        const document = affiliation.required_documents.find(item => item.id === id);
        if (document) {
            return document;
        }
        return {};
    }

    setAffiliationsInRevisionPaginationPage(currentPage, perPage) {
        return this.setAffiliationsPaginationPage('in_revision', currentPage, perPage);
    }

    setAffiliationsArchivedPaginationPage(currentPage, perPage) {
        return this.setAffiliationsPaginationPage('archived', currentPage, perPage);
    }

    setAffiliationsPaginationPage(objName, currentPage, perPage) {
        this.spinner.show(objName + '_spinner');
        this.isLoading = true;
        const param = this.affiliationService.setPaginationParam(objName, this.affiliationItems, currentPage, perPage, this.filterForm, this.selectedDateRange);
        return this.affiliationService.list(param).subscribe(affiliations => {
            // @ts-ignore
            this.affiliationItems[objName] = affiliations[objName];
            this.isLoading = false;
            this.spinner.hide(objName + '_spinner');
        }, err => {
            this.isLoading = false;
            this.spinner.hide(objName + '_spinner');
        });
    }

    setSelectedAffiliation(affiliation) {
        this.selectedAffiliation = affiliation;
    }

    filterAffiliations() {
        const params = this.affiliationService.getAffiliationsParam(this.affiliationItems, this.filterForm, this.selectedDateRange);
        this.isFilterSelected = true;
        this.getAffiliationsList(params ? params : {});
    }

    getAffiliationsList(params = {}) {
        this.isLoading = true;
        this.busy = this.affiliationService.list(params).subscribe(affiliations => {
            this.affiliationItems = affiliations;
            this.isLoading = false;
        }, err => {
            this.isLoading = false;
        });
    }

    get approveAffiliationOptions() {
        return new ConfirmOptionsService({
            title: 'Sei sicuro?',
            text: 'Non potrai tornare indietro!',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });
    }

    setSpinners(str) {
        this.spinner[str](this.inRevisionSpinnerName);
        this.spinner[str](this.archivedSpinnerName);
    }

    get deleteAffiliationOptions() {
        return new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro!',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });
    }

    get rejectAffiliationOptions() {
        return new ConfirmOptionsService({
            title: 'Inserisci il motivo del rifiuto',
            text: '',
            input: 'textarea',
            confirmButtonText: 'RejeRifiutact',
            inputAttributes: {
                autocapitalize: 'off'
            },
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-info',
                cancelButton: 'btn btn-default'
            },
            icon: 'question',
            inputValidator: (value) => {
                return !value && 'Rejection note is required!';
            }
        });
    }

    approveAffiliation(affiliation) {
        const url = 'affiliations/' + affiliation.id;
        const body = {status: 1};
        this.changeStatusOfAffiliation(url, body, 'approved');
    }

    rejectAffiliation(affiliation, rejectionNote) {
        if (rejectionNote) {
            const url = 'affiliations/' + affiliation.id;
            const body = {status: 2, note: rejectionNote};
            this.changeStatusOfAffiliation(url, body, 'rejected');
        }
    }

    changeStatusOfAffiliation(url, body, alertText) {
        this.affiliationService.changeStatusOfAffiliation(url, body, alertText).subscribe(res => {
            this.affiliationItems.archived.data.unshift(res);
            this.removeItemFromInRevisionList(res);
        }, err => {
            this.affiliationService.errorAlert(err.message);
        });
    }

    deleteAffiliation(affiliation) {
        const url = 'affiliations/' + affiliation.id;
        this.affiliationService.deleteAffiliation(url, 'deleted').subscribe(res => {
            if (affiliation.status === 0) {
                this.removeItemFromInRevisionList(affiliation);
            } else {
                this.removeItemFromArchivedList(affiliation);
            }
        }, err => {
            this.affiliationService.errorAlert();
        });
    }

    removeItemFromInRevisionList(affiliation) {
        this.affiliationItems.in_revision.data = this.removeItemFromList(affiliation, this.affiliationItems.in_revision.data);
    }

    removeItemFromArchivedList(affiliation) {
        this.affiliationItems.archived.data = this.removeItemFromList(affiliation, this.affiliationItems.archived.data);
    }

    removeItemFromList(affiliation, affiliationItems) {
        return this.affiliationService.removeItemFromList(affiliation, affiliationItems);
    }


    showAlertMessage(affiliationItems) {
        if (affiliationItems && affiliationItems.archived.data.length <= 0 && affiliationItems.in_revision.data.length <= 0) {
            this.emptyAffiliationsListMessage = 'Non ci sono affiliazioni.';
            return true;
        } else if (affiliationItems && affiliationItems.in_revision.data.length <= 0 && !this.isFilterSelected) {
            this.emptyAffiliationsListMessage = 'Non ci sono affiliazioni in revisione.';
            return true;
        }
        return false;
    }

    downloadFile(id: any) {
        this.busy = this.affiliationService.getAffiliationJson(id, 'downloaded').subscribe(res => {
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Affiliazioni');
            XLSX.writeFile(wb, 'affiliazioni.xlsx');
        }, err => {
            this.affiliationService.errorAlert();
        });
    }
}
