<ul ngbNav #nav="ngbNav" class="nav-tabs mg-tabs">
    <li ngbNavItem *show="['master', 'masteragent', 'operator', 'submaster']">
        <a ngbNavLink>Key Account</a>
        <ng-template ngbNavContent>
            <app-master-card
                    bodyClass="p-0"
                    title="Analisi Produttivi MNP"
                    image="assets/img/flats/employee.png"
            >
                <data-builder-table [url]="urlRecapProductivesMnp"></data-builder-table>
            </app-master-card>

            <app-master-card
                    bodyClass="p-0"
                    title="Analisi Produzione MNP"
                    image="assets/img/flats/employee.png"
            >
                <data-builder-table [url]="urlRecapMnp"></data-builder-table>
            </app-master-card>

            <app-master-card
                    bodyClass="p-0"
                    title="Analisi Produzione Sim"
                    image="assets/img/flats/employee.png"
            >
                <data-builder-table [url]="urlRecapSales"></data-builder-table>
            </app-master-card>

            <app-master-card
                    bodyClass="p-0"
                    title="Analisi Produzione Calcio"
                    image="assets/img/flats/employee.png"
            >
                <data-builder-table [url]="urlRecapSoccer"></data-builder-table>
            </app-master-card>

            <app-master-card
                    bodyClass="p-0"
                    title="Analisi Affiliazioni"
                    image="assets/img/flats/employee.png"
            >
                <data-builder-table [url]="urlRecapOpenings"></data-builder-table>
            </app-master-card>
        </ng-template>
    </li>
    <li ngbNavItem *show="['master', 'operator', 'submaster']">
        <a ngbNavLink>Area Manager</a>
        <ng-template ngbNavContent>
            <app-master-card
                    bodyClass="p-0"
                    title="Analisi Produttivi MNP"
                    image="assets/img/flats/employee.png"
            >
                <data-builder-table [url]="urlRecapProductivesMnpAM"></data-builder-table>
            </app-master-card>

            <app-master-card
                    bodyClass="p-0"
                    title="Analisi Produzione MNP"
                    image="assets/img/flats/employee.png"
            >
                <data-builder-table [url]="urlRecapMnpAM"></data-builder-table>
            </app-master-card>

            <app-master-card
                    bodyClass="p-0"
                    title="Analisi Produzione Sim"
                    image="assets/img/flats/employee.png"
            >
                <data-builder-table [url]="urlRecapSalesAM"></data-builder-table>
            </app-master-card>

            <app-master-card
                    bodyClass="p-0"
                    title="Analisi Produzione Calcio"
                    image="assets/img/flats/employee.png"
            >
                <data-builder-table [url]="urlRecapSoccerAM"></data-builder-table>
            </app-master-card>


            <app-master-card
                    bodyClass="p-0"
                    title="Analisi Affiliazioni"
                    image="assets/img/flats/employee.png"
            >
                <data-builder-table [url]="urlRecapOpeningsAM"></data-builder-table>
            </app-master-card>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav"></div>

<!--<div class="card bg-gradient-white" [ngBusy]="busy">-->
<!--  <div class="card-body mb-0">-->
<!--    <div class="row">-->
<!--      <div class="col-12 col-md-6">-->
<!--        <table-->
<!--                class="table table-bordered table-sm w-auto bg-gradient-white mb-0"-->
<!--        >-->
<!--          <thead *ngIf="sales">-->
<!--          <tr>-->
<!--            <th width="50%">Giorni Utili</th>-->
<!--            <td class="text-right">{{ sales.date.all_working_days }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th>Giorni Lavorati</th>-->
<!--              <td class="text-right">{{ sales.date.past_working_days }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th>Giorni Rimanenti</th>-->
<!--              <td class="text-right">-->
<!--                {{ sales.date.remaining_working_days }}-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th width="50%">Target Sim</th>-->
<!--              <td class="text-right">{{ sales.target }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th width="50%">Target Mnp</th>-->
<!--              <td class="text-right">{{ salesMnp.target }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th width="50%">Produzione aggiornata al</th>-->
<!--              <td class="text-right">{{ sales.date.last_day }}</td>-->
<!--            </tr>-->
<!--          </thead>-->
<!--        </table>-->
<!--      </div>-->
<!--      <div class="col-12 col-md-6">-->
<!--        <div class="form-group">-->
<!--          <label>Scegli il periodo</label>-->
<!--          <app-month-range-picker-->
<!--                  [singleMonthPicker]="true"-->
<!--                  (selected)="setSelectedDateRange($event)"-->
<!--                  [defaultSingleMonth]="defaultMonth"-->
<!--                  [apiFormat]="apiFormat"-->
<!--          ></app-month-range-picker>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--<app-kena-recap-sales-mnp [sales]="salesMnp" [selectedMonth]="period"></app-kena-recap-sales-mnp>-->
<!--<app-kena-recap-sales [sales]="sales" [selectedMonth]="period"></app-kena-recap-sales>-->
<!--<app-kena-recap-affiliations [openings]="openings"></app-kena-recap-affiliations>-->
<!--<div class="row">-->
<!--  <div class="col-12">-->
<!--    <div class="card">-->
<!--      <div class="card-body">-->
<!--        <label>Seleziona un key account</label>-->
<!--        <app-agent-select (onChange)="setAgent($event)"></app-agent-select>-->
<!--        <span class="text-muted"-->
<!--        >Premi la X se vuoi ritornare a vedere il recap per Master Group</span-->
<!--        >-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="col-12 col-md-4">-->
<!--    <div class="card">-->
<!--      <div class="card-header">-->
<!--        <div class="card-title">Mensile</div>-->
<!--        <div class="card-tools">-->
<!--          &lt;!&ndash; <button class="btn btn-sm btn-success" (click)="exportOpenings()"><i class="fas fa-download"></i> Esporta</button> &ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card-body p-0">-->
<!--        <table class="table table-bordered table-sm mb-0">-->
<!--          <thead>-->
<!--            <tr>-->
<!--              <th width="25%">Data</th>-->
<!--              <th width="25%">Giorno</th>-->
<!--              <th class="text-right" width="20%">SIM INS</th>-->
<!--              <th class="text-right" width="15%">MNP INS</th>-->
<!--              <th class="text-right" width="15%">PDV IN</th>-->
<!--            </tr>-->
<!--          </thead>-->
<!--          <tbody *ngIf="monthly">-->
<!--            <tr-->
<!--              *ngFor="let row of monthly.rows"-->
<!--              [ngClass]="{ 'table-danger': row.week_day === 'Domenica' }"-->
<!--            >-->
<!--              <td>{{ row.date }}</td>-->
<!--              <td>{{ row.week_day }}</td>-->
<!--              <td-->
<!--                class="text-right"-->
<!--                [ngClass]="{ 'table-warning': row.sim === null }"-->
<!--              >-->
<!--                {{ row.sim }}-->
<!--              </td>-->
<!--              <td-->
<!--                class="text-right"-->
<!--                [ngClass]="{ 'table-warning': row.mnp === null }"-->
<!--              >-->
<!--                {{ row.mnp }}-->
<!--              </td>-->
<!--              <td-->
<!--                class="text-right"-->
<!--                [ngClass]="{ 'table-warning': row.pdv === null }"-->
<!--              >-->
<!--                {{ row.pdv }}-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr class="text-bold table-info">-->
<!--              <td colspan="2">Totale</td>-->
<!--              <td class="text-right">{{ monthly.total_row.sim }}</td>-->
<!--              <td class="text-right">{{ monthly.total_row.mnp }}</td>-->
<!--              <td class="text-right">{{ monthly.total_row.pdv }}</td>-->
<!--            </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="col-12 col-md-4">-->
<!--    <div class="card">-->
<!--      <div class="card-header">-->
<!--        <div class="card-title">Target Mnp Mese</div>-->
<!--        <div class="card-tools">-->
<!--          &lt;!&ndash; <button class="btn btn-sm btn-success" (click)="exportOpenings()"><i class="fas fa-download"></i> Esporta</button> &ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card-body p-0 bg-gradient-gray text-dark">-->
<!--        <table class="table table-bordered table-sm bg-gradient-white mb-3">-->
<!--          <thead *ngIf="monthly">-->
<!--            <tr>-->
<!--              <th width="50%">Giorni Utili</th>-->
<!--              <td class="text-right">{{ monthly.date.all_working_days }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th>Giorni Lavorati</th>-->
<!--              <td class="text-right">{{ monthly.date.past_working_days }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th>Giorni Rimanenti</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.date.remaining_working_days }}-->
<!--              </td>-->
<!--            </tr>-->
<!--          </thead>-->
<!--        </table>-->

<!--        <table class="table table-bordered table-sm bg-gradient-white mb-3">-->
<!--          <thead *ngIf="monthly">-->
<!--            <tr>-->
<!--              <th width="50%">TGT MNP INS</th>-->
<!--              <td class="text-right">{{ monthly.target_mnp.target }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th width="50%">MEDIA TGT DAY</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.target_mnp.media_inserimenti_target }}-->
<!--              </td>-->
<!--            </tr>-->
<!--          </thead>-->
<!--        </table>-->

<!--        <table class="table table-bordered table-sm bg-gradient-white mb-3">-->
<!--          <thead *ngIf="monthly">-->
<!--            <tr>-->
<!--              <th width="50%">ACTUAL MNP</th>-->
<!--              <td class="text-right">{{ monthly.target_mnp.mtd }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th width="50%">MEDIA MNP INS DAY</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.target_mnp.media_inserimenti_mtd }}-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th width="50%">DELTA MNP DAY</th>-->
<!--              <td class="text-right">{{ monthly.target_mnp.delta_media }}</td>-->
<!--            </tr>-->
<!--          </thead>-->
<!--        </table>-->

<!--        <table class="table table-bordered table-sm bg-gradient-white mb-3">-->
<!--          <thead *ngIf="monthly">-->
<!--            <tr>-->
<!--              <th width="50%">FCT MNP INS TOT</th>-->
<!--              <td class="text-right">{{ monthly.target_mnp.forecast }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th width="50%">FCT DELTA MESE MNP (TOT)</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.target_mnp.delta_forecast }}-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th width="50%">FCT DELTA MESE MNP (%)</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.target_mnp.delta_proiezione | percent }}-->
<!--              </td>-->
<!--            </tr>-->
<!--          </thead>-->
<!--        </table>-->

<!--        <table class="table table-bordered table-sm bg-gradient-white mb-0">-->
<!--          <thead *ngIf="monthly">-->
<!--            <tr>-->
<!--              <th width="50%">NEW TGT DAY</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.target_mnp.nuova_media_inserimenti_target }}-->
<!--              </td>-->
<!--            </tr>-->
<!--          </thead>-->
<!--        </table>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="card">-->
<!--      <div class="card-header">-->
<!--        <div class="card-title">Target Sim Mese</div>-->
<!--        <div class="card-tools">-->
<!--          &lt;!&ndash; <button class="btn btn-sm btn-success" (click)="exportOpenings()"><i class="fas fa-download"></i> Esporta</button> &ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card-body p-0 bg-gradient-gray text-dark">-->
<!--        <table class="table table-bordered table-sm bg-gradient-white mb-3">-->
<!--          <thead *ngIf="monthly">-->
<!--            <tr>-->
<!--              <th width="50%">Giorni Utili</th>-->
<!--              <td class="text-right">{{ monthly.date.all_working_days }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th>Giorni Lavorati</th>-->
<!--              <td class="text-right">{{ monthly.date.past_working_days }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th>Giorni Rimanenti</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.date.remaining_working_days }}-->
<!--              </td>-->
<!--            </tr>-->
<!--          </thead>-->
<!--        </table>-->

<!--        <table class="table table-bordered table-sm bg-gradient-white mb-3">-->
<!--          <thead *ngIf="monthly">-->
<!--            <tr>-->
<!--              <th width="50%">TGT SIM INS</th>-->
<!--              <td class="text-right">{{ monthly.target_sim.target }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th width="50%">MEDIA TGT DAY</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.target_sim.media_inserimenti_target }}-->
<!--              </td>-->
<!--            </tr>-->
<!--          </thead>-->
<!--        </table>-->

<!--        <table class="table table-bordered table-sm bg-gradient-white mb-3">-->
<!--          <thead *ngIf="monthly">-->
<!--            <tr>-->
<!--              <th width="50%">ACTUAL SIM</th>-->
<!--              <td class="text-right">{{ monthly.target_sim.mtd }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th width="50%">MEDIA SIM INS DAY</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.target_sim.media_inserimenti_mtd }}-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th width="50%">DELTA SIM DAY</th>-->
<!--              <td class="text-right">{{ monthly.target_sim.delta_media }}</td>-->
<!--            </tr>-->
<!--          </thead>-->
<!--        </table>-->

<!--        <table class="table table-bordered table-sm bg-gradient-white mb-3">-->
<!--          <thead *ngIf="monthly">-->
<!--            <tr>-->
<!--              <th width="50%">FCT SIM INS TOT</th>-->
<!--              <td class="text-right">{{ monthly.target_sim.forecast }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th width="50%">FCT DELTA MESE SIM (TOT)</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.target_sim.delta_forecast }}-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th width="50%">FCT DELTA MESE SIM (%)</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.target_sim.delta_proiezione | percent }}-->
<!--              </td>-->
<!--            </tr>-->
<!--          </thead>-->
<!--        </table>-->

<!--        <table class="table table-bordered table-sm bg-gradient-white mb-0">-->
<!--          <thead *ngIf="monthly">-->
<!--            <tr>-->
<!--              <th width="50%">NEW TGT DAY</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.target_sim.nuova_media_inserimenti_target }}-->
<!--              </td>-->
<!--            </tr>-->
<!--          </thead>-->
<!--        </table>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="col-12 col-md-4">-->
<!--    <div class="card">-->
<!--      <div class="card-header">-->
<!--        <div class="card-title">Target Affiliazioni Mese</div>-->
<!--        <div class="card-tools">-->
<!--          &lt;!&ndash; <button class="btn btn-sm btn-success" (click)="exportOpenings()"><i class="fas fa-download"></i> Esporta</button> &ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="card-body p-0 bg-gradient-gray text-dark">-->
<!--        <table class="table table-bordered table-sm bg-gradient-white mb-3">-->
<!--          <thead *ngIf="monthly">-->
<!--            <tr>-->
<!--              <th width="50%">Giorni Utili</th>-->
<!--              <td class="text-right">{{ monthly.date.all_working_days }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th>Giorni Lavorati</th>-->
<!--              <td class="text-right">{{ monthly.date.past_working_days }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th>Giorni Rimanenti</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.date.remaining_working_days }}-->
<!--              </td>-->
<!--            </tr>-->
<!--          </thead>-->
<!--        </table>-->

<!--        <table class="table table-bordered table-sm bg-gradient-white mb-3">-->
<!--          <thead *ngIf="monthly">-->
<!--            <tr>-->
<!--              <th width="50%">TGT DLR INS</th>-->
<!--              <td class="text-right">{{ monthly.target_openings.target }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th width="50%">MEDIA TGT DAY</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.target_openings.media_inserimenti_target }}-->
<!--              </td>-->
<!--            </tr>-->
<!--          </thead>-->
<!--        </table>-->

<!--        <table class="table table-bordered table-sm bg-gradient-white mb-3">-->
<!--          <thead *ngIf="monthly">-->
<!--            <tr>-->
<!--              <th width="50%">ACTUAL DLR</th>-->
<!--              <td class="text-right">{{ monthly.target_openings.mtd }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th width="50%">MEDIA DLR INS DAY</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.target_openings.media_inserimenti_mtd }}-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th width="50%">DELTA DLR DAY</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.target_openings.delta_media }}-->
<!--              </td>-->
<!--            </tr>-->
<!--          </thead>-->
<!--        </table>-->

<!--        <table class="table table-bordered table-sm bg-gradient-white mb-3">-->
<!--          <thead *ngIf="monthly">-->
<!--            <tr>-->
<!--              <th width="50%">FCT DLR INS TOT</th>-->
<!--              <td class="text-right">{{ monthly.target_openings.forecast }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th width="50%">FCT DELTA MESE DLR (TOT)</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.target_openings.delta_forecast }}-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th width="50%">FCT DELTA MESE DLR (%)</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.target_openings.delta_proiezione | percent }}-->
<!--              </td>-->
<!--            </tr>-->
<!--          </thead>-->
<!--        </table>-->

<!--        <table class="table table-bordered table-sm bg-gradient-white mb-0">-->
<!--          <thead *ngIf="monthly">-->
<!--            <tr>-->
<!--              <th width="50%">NEW TGT DAY</th>-->
<!--              <td class="text-right">-->
<!--                {{ monthly.target_openings.nuova_media_inserimenti_target }}-->
<!--              </td>-->
<!--            </tr>-->
<!--          </thead>-->
<!--        </table>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
