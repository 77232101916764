<div class="modal-body"> <ul class="list-group list-group-flush">
    <li class="list-group-item px-0 w-100 d-flex justify-content-end">
        <button class="btn btn-link float-fight py-0 my-0" (click)="exportReceipt()">
            <i class="fas fa-file-download"></i><span translate>Export receipt</span>
        </button>
    </li>
    <li class="list-group-item px-0">
        <span [innerHTML]="receipt" id = "receipt"></span>
    </li>
    <li class="list-group-item d-flex justify-content-center px-0">
        <span class="text-muted" translate>Thank you</span>
    </li>
</ul>
</div>
