import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DashboardService} from '@services/dashboard.service';

@Component({
    selector: 'app-master-analisi-sim',
    templateUrl: './master-analisi-sim.component.html',
    styleUrls: ['./master-analisi-sim.component.css']
})
export class MasterAnalisiSimComponent implements OnInit {
    busy: Subscription;
    startup: any;

    urlAnalisiSimAgenti: string;
    urlAnalisiSimNegozi: string;

    constructor(private dashboardService: DashboardService) {
    }

    ngOnInit() {
        this.busy = this.dashboardService.get('/analisi-sim')
            .subscribe((data) => {
                this.startup = data.result;
            });
    }

    updateUrls(operatore) {
        this.urlAnalisiSimAgenti = `/analisi-sim-agenti?operatore=${operatore}`;
        this.urlAnalisiSimNegozi = `-detail/no-sim?param=&datasource=pdv_no_sim&operatore=${operatore}&output=lista_negozi`;
    }

}
