import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {OperatorService} from "@services/operator.service";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-gestione-venditori',
    templateUrl: './gestione-venditori.component.html',
    styleUrls: ['./gestione-venditori.component.css']
})
export class GestioneVenditoriComponent implements OnInit {
    data = {
        table: {
            columns: [],
            rows: [],
        },
        presidio: '',
        n_venditori: 0
    };
    busy: Subscription;
    id: string;
    deleted = '0';

    constructor(
        public route: ActivatedRoute,
        private operatorService: OperatorService
    ) {
        this.id = this.route.snapshot.paramMap.get('id');
        this.deleted = this.route.snapshot.paramMap.get('deleted');
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.deleted = params['deleted'];
            this.refresh();
        });
    }

    refresh() {
         this.busy = this.operatorService.get(`/lista-venditori?id=${this.id}&deleted=${this.deleted}`)
             .subscribe((data) => {
                 this.data = data.result;
             });
    }
}
