import {Component, Inject, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '@services/data.service';
import {Subscription} from 'rxjs';
import {Invoice} from '@models/invoice';
import {AuthenticationService} from '@services/authentication.service';
import {Profile, User} from '@models/user';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-invoice-detail',
    templateUrl: './invoice-detail.component.html',
    styleUrls: ['./invoice-detail.component.scss']
})
export class InvoiceDetailComponent implements OnInit {
    busy: Subscription;
    invoice: Invoice = new Invoice();
    user: User = new User();
    accountForm: FormGroup;
    picklists: any;
    addressValid: boolean;
    billingAddress: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private dataService: DataService,
        private authenticationService: AuthenticationService,
        private modalService: NgbModal,
        private formBuilder: FormBuilder,
    ) {
    }

    ngOnInit() {
        this.authenticationService.currentUser.subscribe((user) => this.user = user);

        this.accountForm = this.formBuilder.group({
            partita_iva: [this.profile.partita_iva, [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
            accountname: [this.profile.ragione_sociale, [Validators.required]],
            fe_regime_fiscale: [null, [Validators.required]],
            bill_street: [this.profile.sede_indirizzo, [Validators.required]],
            bill_code: [this.profile.sede_civico, [Validators.required]],
            bill_city: [this.profile.sede_comune, [Validators.required]],
            bill_state: [this.profile.sede_provincia, [Validators.required]],
            vattype: [null, [Validators.required]],
            fe_tipo_ritenuta: [null, [Validators.required]],
            fe_causale_ritenuta: [null, [Validators.required]],
            codice_sdi: [null, [Validators.required, Validators.minLength(7), Validators.maxLength(7)]],
            fecustomerpec: [null, [Validators.required, Validators.email]],
            iban: [this.profile.iban, [Validators.required, Validators.maxLength(27), Validators.minLength(27),
                // Validators.pattern(this.config.patternIBAN)
            ]],
        });

        this.billingAddress = {
            city: this.c().bill_city.value,
            province: this.c().bill_state.value,
            pcode: this.c().bill_code.value,
            address: this.c().bill_street.value,
        };

        this.refresh();
    }

    refresh() {
        this.busy = this.dataService
            .get(`/get-dettaglio-fattura?id=${this.route.snapshot.paramMap.get('id')}`)
            .subscribe((data: any) => {
                if (data.result.is_nascosta === 1) {
                    this.router.navigate(['/fatturazione/lista']);
                }
                this.invoice = new Invoice(data.result);
            });
    }

    c() {
        return this.accountForm.controls;
    }

    get profile(): Profile {
        return this.user.profile;
    }

    get isTim() {
        if (this.invoice && this.invoice.hasOwnProperty('voci') && this.invoice.voci !== undefined) {
            return this.invoice.voci.filter(x => x.tipo.includes('Tim')).length > 0;
        }

        return false;
    }

    showModal(confirmModal: TemplateRef<any>) {
        this.modalService.open(confirmModal);
    }

    confirm() {
        const formData = {
            id_fattura: this.invoice.id,
            stato: 1
        };
        this.busy = this.dataService
            .post(`/set-stato-fattura`, formData)
            .subscribe((data: any) => {
                this.modalService.dismissAll();
                this.refresh();
            });
    }

    cancel() {
        const formData = {
            id_fattura: this.invoice.id,
            stato: 2
        };
        this.busy = this.dataService
            .post(`/set-stato-fattura`, formData)
            .subscribe((data: any) => {
                this.modalService.dismissAll();
                this.refresh();
            });
    }
}
