/* tslint:disable:variable-name */

import {BaseModel} from '@models/base-model';
import {TicketAttachment, TicketRisposte} from '@models/ticket';
import {Helpers} from '@helpers/helpers';

export const INVOICE_TO_APPROVE = 'Da Approvare';
export const INVOICE_APPROVED = 'Approvata';
export const INVOICE_SENT = 'Inviata';

export class InvoiceLineDetail extends BaseModel {
    dealer: string;
    info: string;
    tipo: string;
    compenso: number;
    offerta: string;
    iva: number;
    ritenuta: number;
    netto: number;

    fromArray(result: []) {
        const array: InvoiceLineDetail[] = [];
        for (const item of result) {
            array.push(new InvoiceLineDetail(item));
        }
        return array;
    }
}

export class InvoiceLine extends BaseModel {
    tipo: string;
    mese: number;
    anno: number;
    totale: number;
    iva: number;
    ritenuta: number;
    netto: number;
    dettaglio: InvoiceLineDetail[];
    groups: {
        offerta: string;
        size: number;
        lista: InvoiceLineDetail[];
        totale: number;
        show: boolean;
    }[];

    constructor(attributes?: any) {
        super(attributes);

        const cols = [...new Set(this.dettaglio.map(x => x.offerta))];

        let groups = [];

        for (let i in cols) {
            const col = cols[i];

            const details = this.dettaglio.filter(x => x.offerta === col);
            const total = details.reduce((a, b) => a + (b.compenso || 0), 0);

            let group = {
                'offerta': col,
                'size': details.length,
                'lista': details,
                'totale': total,
                'show': false
            };

            groups.push(group);
        }

        groups.sort((a, b) => {
            if (a['totale'] < b['totale']) {
                return 1;
            }
            if (a['totale'] > b['totale']) {
                return -1;
            }
            return 0;
        });

        this.groups = groups;
    }


    get childModels() {
        return {
            dettaglio: InvoiceLineDetail,
        };
    }

    fromArray(result: []) {
        const array: InvoiceLine[] = [];
        for (const item of result) {
            array.push(new InvoiceLine(item));
        }
        return array;
    }
}

export class Invoice extends BaseModel {
    condizioni_pagamento: string;
    created: string;
    id: number;
    importo_iva: number;
    importo_netto: number;
    importo_ritenuta: number;
    is_old: number;
    metodo_pagamento: string;
    soggetto: string;
    stato: string;
    tipo_documento: string;
    tipo_fattura: number;
    totale: number;
    voci: InvoiceLine[];
    status_code: number;
    gara: string;
    config: {
        company_name: string;
        address: string;
        city: string;
        province: string;
        zipcode: string;
        iban_code: string;
        sdi_code: string;
        vat_number: string;
        email: string;
    };

    get childModels() {
        return {
            voci: InvoiceLine,
        };
    }

    fromArray(result: []) {
        const array: Invoice[] = [];
        for (const item of result) {
            array.push(new Invoice(item));
        }
        return array;
    }
}
