<app-master-header [ngBusy]="busy"
                   cardClass="bg-gradient-1"
                   image="assets/img/flats/coins.png"
                   title="Analisi Sim"
                   [operatori]="true"
                   (onChange)="this.updateUrls($event.operatore)"
>
</app-master-header>

<div class="row">
    <div class="col-12 col-xl-4">
        <div class="card">
            <div class="card-body">
                <div class="row text-center align-items-center">
                    <div class="col-xl-2"><img src="/assets/img/flats/payment.png" height="64px"></div>
                    <div class="col-xl-7 text-left">
                        <h1 class="text-left display-6 mb-0">Negozi</h1>
                        <p class="lead mb-0">che hanno esaurito le sim</p>
                    </div>
                    <div class="col">
                        <h1 class="text-right display-6 mb-0"><strong><em>{{startup.no_sim}}</em></strong></h1>
                        <p class="text-right mb-0"><em>il {{startup.no_sim_perc | percent}} delle rete</em></p>
                    </div>
                </div>
                <div class="row text-center align-items-center mt-3 mb-1">
                    <div class="col">
                        <p class="lead mb-0"><em>di cui</em></p>
                    </div>
                </div>
                <div class="row text-center align-items-center">
                    <div class="col-xl-2"><img src="/assets/img/flats/cash.png" height="64px"></div>
                    <div class="col-xl-7 text-left">
                        <h1 class="text-left display-6 mb-0">Negozi</h1>
                        <p class="lead mb-0">che hanno credito da compensare</p>
                    </div>
                    <div class="col">
                        <h1 class="text-right display-6 mb-0"><strong><em>{{startup.with_credit}}</em></strong></h1>
                        <p class="text-right mb-0"><em>il {{startup.with_credit_perc | percent}}% dei negozi senza sim</em></p>
                    </div>
                </div>
                <div></div>
                <p class="lead text-center mt-2">con un totale di</p>
                <h1 class="text-center display-6 mb-0"><strong><em>{{startup.credito | currency: ' €'}}</em></strong></h1>
                <p class="lead text-center mb-0"><em>da compensare</em></p>
            </div>
        </div>
        <div class="row">
            <div class="col text-center mb-2"><i class="fas fa-long-arrow-alt-down" style="font-size: 52px;"></i></div>
            <div class="col text-center"><i class="fas fa-long-arrow-alt-down" style="font-size: 52px;"></i></div>
        </div>
        <div class="row">
            <div class="col-12 col-xl-6 text-center">
                <div class="card bg-gradient-success">
                    <div class="card-body">
                        <div class="row text-center align-items-center">
                            <div class="col-xl-12 mb-2"><img class="bg-gradient-light img-circle p-1 elevation-1" src="/assets/img/flats/settings.png" height="64px"></div>
                            <div class="col-xl-12 text-left my-2">
                                <h1 class="text-left display-6 mb-0">Improduttivi</h1>
                                <p class="lead mb-0">perchè non hanno sim</p>
                            </div>
                            <div class="col">
                                <h1 class="text-right display-6 mb-0"><strong><em>{{startup.produttivi}} negozi</em></strong></h1>
                                <p class="text-right mb-0"><em>il {{startup.produttivi_perc | percent}} dei negozi</em></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-6 text-center">
                <div class="card bg-gradient-red">
                    <div class="card-body">
                        <div class="row text-center align-items-center">
                            <div class="col-xl-12 mb-2">
                                <img class="bg-gradient-light img-circle p-1 elevation-1" src="/assets/img/flats/dislike.png" height="64px"></div>
                            <div class="col-xl-12 text-left my-2">
                                <h1 class="text-left display-6 mb-0">Improduttivi</h1>
                                <p class="lead mb-0">da sempre senza sim</p>
                            </div>
                            <div class="col">
                                <h1 class="text-right display-6 mb-0"><strong><em>{{startup.improduttivi}} negozi</em></strong></h1>
                                <p class="text-right mb-0"><em>il {{startup.improduttivi_perc | percent}} dei negozi</em></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-xl-8">
        <app-master-card
                bodyClass="p-0"
                title="Analisi sim per key account"
                image="assets/img/flats/employee.png"
                *show="['master']"
        >
            <app-autoload-table [url]="urlAnalisiSimAgenti" [hideFooterDescription]="true" [small]="true"></app-autoload-table>
        </app-master-card>
        <app-master-card
                bodyClass="p-0"
                title="Negozi senza sim"
                image="assets/img/flats/online-store.png"
        >
            <app-autoload-table [url]="urlAnalisiSimNegozi"></app-autoload-table>
        </app-master-card>
    </div>
</div>
