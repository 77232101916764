import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {ShopOrderService} from '@services/shop-order.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfirmOptionsService} from '@services/confirm-options.service';
import {ShopOrderDetail, ShopOrderFile, ShopOrderList, ShopOrderPackage, ShopOrderProductList} from '@models/shop-order';
import {StoreProduct, StoreProductPagination, StoreProductVariant} from '@models/store/store-product';
import {StoreService} from '@services/store.service';

@Component({
    selector: 'app-shop-order-detail',
    templateUrl: './shop-order-detail.component.html',
    styleUrls: ['./shop-order-detail.component.css']
})
export class ShopOrderDetailComponent implements OnInit {
    currency = '€';
    localFormat = 'DD/MM/YYYY';
    @Input() order: ShopOrderList;
    selectedKey = null;
    popupConfig = {
        type: null,
        key: null,
        items: null,
        id: null,
        isSelect: false,
        popover: null,
        inputValue: null
    };
    @Output() refresh = new EventEmitter<any>();
    deleteDocumentOptions: ConfirmOptionsService;
    productForm = new FormGroup({
        product: new FormControl('', Validators.required),
        quantity: new FormControl(1, Validators.required),
        variant: new FormControl(''),
    });
    fileForm = new FormGroup({
        file: new FormControl('', Validators.required)
    });
    products: StoreProduct[] = [];
    productLoading = false;
    @ViewChild('productFormTemplate') private productFormTemplate;
    @ViewChild('fileFormTemplate') private fileFormTemplate;
    busyProduct: any;
    busyFile: any;
    submittedFile: File;
    noteForm = new FormGroup({
        note_op: new FormControl('', Validators.required),
    });
    busyNote: any;
    @ViewChild('noteFormTemplate') private noteFormTemplate;

    constructor(private modalService: NgbModal,
                private spinner: NgxSpinnerService,
                private shopOrderService: ShopOrderService,
                private storeService: StoreService) {
        this.deleteDocumentOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro!',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });
    }

    ngOnInit(): void {
    }

    openPopover(popOver: any, id, item, key, type) {
        this.popupConfig.inputValue = item;
        this.popupConfig.id = id;
        this.popupConfig.key = key;
        this.popupConfig.popover = popOver;
        this.popupConfig.type = type;
        popOver.open();
    }

    save(value) {
        const body = {};
        body[this.popupConfig.key] = value;
        this.spinner.show('popupSpinner');
        if (this.popupConfig.type === 'product') {
            this.shopOrderService.putShopOrderProduct(this.popupConfig?.id, body).subscribe((response) => {
                this.refresh.emit(true);
                this.spinner.hide('popupSpinner');
                this.popupConfig.popover.close();
            }, err => {
                this.spinner.hide('popupSpinner');
                this.popupConfig.popover.close();
            });
        } else {
            this.shopOrderService.putShopOrder(this.popupConfig?.id, body).subscribe((response) => {
                this.refresh.emit(true);
                this.spinner.hide('popupSpinner');
                this.popupConfig.popover.close();
            }, err => {
                this.spinner.hide('popupSpinner');
                this.popupConfig.popover.close();
            });
        }
    }

    decreaseProductQuantity(item) {
        if (Number(item.quantity) > 1) {
            item.quantity = Number(item.quantity) - 1;
            this.saveProduct(item);
        }
    }

    increaseProductQuantity(item) {
        if (Number(item.product?.quantity) >= Number(item.quantity)) {
            item.quantity = Number(item.quantity) + 1;
            this.saveProduct(item);
        }
    }

    saveProduct(item) {
        const body = {
            quantita: item?.quantity
        };
        this.shopOrderService.putShopOrderProduct(item?.id, body).subscribe(() => {
            this.refresh.emit(false);
        }, err => {
            this.refresh.emit(false);
        });
    }

    deleteProduct(id) {
        this.shopOrderService.deleteShopOrderProduct(id).subscribe(() => {
            this.refresh.emit(false);
        });
    }

    addProduct() {
        const body = {
            id_prodotto: this.productForm.value?.product?.id,
            quantita: this.productForm.value?.quantity,
            prezzo: this.unitProductPrice,
            id_ordine: this.order?.id
        };
        if (this.selectedVariant) {
            body['product_variant_id'] = this.selectedVariant?.id;
        }
        this.busyProduct = this.shopOrderService.postShopOrderProduct(body).subscribe(() => {
            this.refresh.emit(false);
            this.modalService.dismissAll();
        }, err => {
            this.modalService.dismissAll();
        });
    }

    get totalProductPrice() {
        return (Number(this.unitProductPrice) * Number(this.productForm.value.quantity
            ? this.productForm.value.quantity : 1)).toFixed(2);
    }

    get unitProductPrice() {
        if (this.selectedVariant) {
            return this.selectedVariant.price;
        }
        return this.order.payment === 2 ? this.selectedProduct?.prezzoacquisto : this.selectedProduct?.prezzo;
    }

    get selectedProduct() {
        return this.productForm.get('product').value as StoreProduct;
    }

    get selectedVariant() {
        return this.productForm.get('variant').value as StoreProductVariant;
    }

    get variants() {
        if (!this.selectedProduct) {
            return [];
        }
        return this.selectedProduct.variants as StoreProductVariant[];
    }

    loadProducts(term?) {
        this.productLoading = true;
        const params = {
            // 'id_deleted': 0,
            // 'is_visible': 1
        };
        if (term) {
            params['name_or_id'] = term;
        }
        this.storeService.getProducts(params).subscribe((response: StoreProductPagination) => {
            this.products = response?.data;
            this.productLoading = false;
        }, err => {
            this.productLoading = false;
        });
    }

    showProductModal() {
        this.productForm.reset();
        this.loadProducts();
        this.modalService.open(this.productFormTemplate, {size: 'md'});
    }

    showFileModal() {
        this.fileForm.reset();
        this.modalService.open(this.fileFormTemplate, {size: 'md'});
    }

    replace() {
        this.shopOrderService.putShopOrder(this.order?.id, {replace_serial: 1}).subscribe((response) => {
            this.shopOrderService.successAlert();
        }, err => {
            this.shopOrderService.errorAlert();
        });
    }

    uploadFile() {
        const orderFile = new ShopOrderFile();
        orderFile.file = this.submittedFile;
        this.busyFile = this.shopOrderService.uploadFile(this.order?.id, orderFile).subscribe((response) => {
            this.modalService.dismissAll();
            this.refresh.emit(false);
        }, err => {
            this.modalService.dismissAll();
            this.refresh.emit(false);
        });
    }

    handleFileInput(file: File) {
        this.submittedFile = file;
        this.fileForm.get('file').setValue(file.name);
    }

    handleFileRemoveModal() {
        this.fileForm.get('file').setValue('');
        this.submittedFile = null;
    }

    showNoteModal(order: ShopOrderList) {
        this.noteForm.setValue({
            note_op: order?.note_op
        });
        this.modalService.open(this.noteFormTemplate, {size: 'md'});
    }

    saveNote() {
        this.busyNote = this.shopOrderService.putShopOrder(this.order?.id, this.noteForm.value).subscribe((response) => {
            this.refresh.emit(false);
            this.modalService.dismissAll();
        }, err => {
            this.modalService.dismissAll();
        });
    }

    get file() {
        return this.fileForm.get('file').value;
    }
}
