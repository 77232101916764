<aside #mainSidebar class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a [routerLink]="['/']" class="brand-link">
        <img src="/assets/img/logo.png" class="brand-image"/>
        <span class="brand-text">
      <img src="/assets/img/typography_white.png" style="margin-bottom: 5px"/>
    </span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
        <!-- Sidebar user (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
            <div class="image">
                <img
                        src="/assets/img/user2-160x160.jpg"
                        class="img-circle elevation-2"
                        alt="User Image"
                />
            </div>
            <div class="info text-white">
                <p class="d-block text-white contact-name">
                    {{ currentUser?.profile.nome }} {{ currentUser?.profile?.cognome }}
                </p>
                <p class="d-block text-white organization-name">
                    {{ currentUser?.profile?.ragione_sociale }}
                </p>
                <a [routerLink]="['/profile']" class="mr-2" translate>Profilo</a>·<a
                    (click)="logout()" href="javascript:void(0)"
                    class="ml-2"
                    translate
            >Esci</a
            >
            </div>
        </div>

        <!-- Sidebar Menu -->
        <nav class="mt-2">
            <ul
                    class="nav nav-pills nav-sidebar nav-child-indent flex-column"
                    data-widget="treeview"
            >
                <!--        <li class="nav-header">RADIO MASTER GROUP</li>-->
                <!--        <div class="card ml-1">-->
                <!--          <div class="card-body px-2 py-2">-->
                <!--            <img-->
                <!--                    height="60px"-->
                <!--                    width="60px"-->
                <!--                    src="https://dvbx02a03u1kk.cloudfront.net/radios/319811/cover/id3/c0992537-6589-4e65-9d6b-3b91ee896338.jpg" />-->
                <!--            <div style="position: fixed; left: 90px; display: inline;">-->
                <!--              <p>Running</p>-->
                <!--              <p class="text-muted text-sm">Apsley</p>-->
                <!--              <p class="text-danger text-xs"><i class="fas fa-circle mr-1"></i>live</p>-->
                <!--            </div>-->

                <!--            <div style="position: fixed; left: 200px; display: inline;">-->
                <!--              <i class="fas fa-play-circle text-lg" style="margin-top: 20px;"></i>-->
                <!--            </div>-->
                <!--          </div>-->
                <!--        </div>-->
                <ng-container *show="['agent', 'dealer']">
                    <li class="nav-header" translate>CREDITO MATURATO</li>
                    <li class="nav-item">
                        <a [routerLink]="['/credito']" class="nav-link">
                            <i class="nav-icon fas fa-wallet text-success"></i>
                            <p class="text">
                                {{ currentUser.profile.credito | currency: "€ " }}
                            </p>
                        </a>
                    </li>
                    <ng-container *isVendorEnabled="['Kena Mobile']">
                        <li class="nav-header" translate>CREDITO KDRICARICA</li>
                        <li class="nav-item" id="epay-credit">
                            <a routerLink="/e-commerce/orders" class="nav-link">
                                <i class="nav-icon fa fa-euro-sign text-success"></i>
                                <p>
                                    {{ currentUser.profile.e_shop_credit | currency: "€ " }}
                                </p>
                            </a>
                        </li>
                    </ng-container>
                    <ng-container *show="['dealer']">
                        <li class="nav-header" translate>SMS DISPONIBILI</li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-paper-plane text-info"></i>
                                <p class="text">
                                    <span>{{ this.currentUser.profile.sms_disponibili }}</span>
                                    sms
                                </p>
                            </a>
                        </li>
                    </ng-container>
                </ng-container>

                <!--        <li class="nav-header">SLOT MACHINE</li>-->
                <!--        <li class="nav-item">-->
                <!--          <a href="#" class="nav-link">-->
                <!--            <i class="nav-icon fas fa-coins text-warning"></i>-->
                <!--            <p class="text"><span id="menu-total-spins">0</span> gettoni</p>-->
                <!--          </a>-->
                <!--        </li>-->
                <li class="nav-header">MENU</li>

                <li
                        *ngFor="let route of menu"
                        class="nav-item"
                        [ngClass]="{ 'has-treeview': route.children.length > 0 }"
                >
                    <ng-container *ngIf="route.externalUrl">
                        <a [href]="route.path" target="_blank" class="nav-link">
                            <i class="nav-icon" [ngClass]="route.icon"></i>
                            <p>
                                <span translate>{{ route.label }}</span>
                            </p>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="!route.externalUrl">
                        <a
                                [routerLink]="route.path"
                                class="nav-link"
                                [routerLinkActive]="route.activeClass"
                                [ngClass]="{'bg-gradient-success text-white': route.new}"
                        >
                            <i class="nav-icon" [ngClass]="route.icon"></i>
                            <p>
                                <span translate>{{ route.label }}</span>
                                <span class="badge badge-info right" *ngIf="route.badge > 0">
                                {{ route.badge }}
                              </span>
                            </p>

                            <i
                                    class="fas fa-angle-left right"
                                    *ngIf="route.children.length > 0"
                            ></i>

                            <div class="ribbon-wrapper" *ngIf="route.new">
                                <div class="ribbon bg-white text-sm">
                                    New
                                </div>
                            </div>
                        </a>
                        <ul class="nav nav-treeview" *ngIf="route.children.length > 0">
                            <li
                                    *ngFor="let subroute of route.children"
                                    class="nav-item"
                                    [ngClass]="{ 'has-treeview': subroute.children.length > 0 }"
                            >
                                <a
                                        [routerLink]="subroute.path"
                                        class="nav-link"
                                        [routerLinkActive]="route.activeClass"
                                >
                                    <i class="nav-icon" [ngClass]="subroute.icon"></i>
                                    <p>
                                        <span translate>{{ subroute.label }}</span>
                                    </p>
                                </a>
                            </li>
                        </ul>
                    </ng-container>
                </li>
            </ul>
        </nav>
        <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
</aside>
