import {Component, OnInit} from '@angular/core';
import {InvoiceDocumentService} from '@services/invoice-document.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SubjectClient, SubjectClientPagination, SubjectSupplier, SubjectSupplierPagination} from '@models/invoice/subject';
import {MetaPagination} from '@models/meta-pagination';
import * as moment from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {Moment} from 'moment';
import {DocumentMap} from '@models/invoice/document';

@Component({
    selector: 'app-invoice-report',
    templateUrl: './invoice-report.component.html',
    styleUrls: ['./invoice-report.component.css']
})
export class InvoiceReportComponent implements OnInit {
    clients: SubjectClient[] = [];
    clientsPagination: MetaPagination;
    suppliers: SubjectSupplier[] = [];
    suppliersPagination: MetaPagination;
    currency = InvoiceDocumentService.currency;
    clientName = '';
    supplierName = '';
    filter = {
        date: moment().add(-3, 'M').format(this.dateRangePickerOptions.settings.locale.format)
            + this.dateRangePickerOptions.settings.locale.separator +
            moment().format(this.dateRangePickerOptions.settings.locale.format),
        competence: ''
    };
    selectedDateRange = {
        from: moment().add(-3, 'M').format(this.dateRangePickerOptions.settings.locale.apiFormat),
        to: moment().format(this.dateRangePickerOptions.settings.locale.apiFormat),
    };
    categories = [
        {
            key: '1',
            name: 'Invoice'
        },
        {
            key: '0',
            name: 'Cash flow'
        }
    ];
    markers: DocumentMap[] = [];
    mapPinOptions = {
        url: 'assets/img/flats/pin.png',
        scaledSize: {
            width: 30,
            height: 45
        }
    };
    lat = 40.8518;
    lng = 14.2681;

    constructor(
        private invoiceDocumentService: InvoiceDocumentService,
        private spinner: NgxSpinnerService,
        private dateRangePickerOptions: DaterangepickerConfig) {
    }

    ngOnInit(): void {
        this.loadMapReport();
        this.loadData();
    }

    loadClients() {
        this.spinner.show('table-clients');
        const params = {};
        if (this.selectedDateRange.from) {
            params['from'] = this.selectedDateRange.from;
        }
        if (this.selectedDateRange.to) {
            params['to'] = this.selectedDateRange.to;
        }
        if (this.clientsPagination?.currentPage) {
            params['page'] = this.clientsPagination?.currentPage;
        }
        if (this.clientName) {
            params['company_name'] = this.clientName;
        }
        if (this.filter.competence) {
            params['competence'] = this.filter.competence;
        }
        this.invoiceDocumentService.getSubjectClients(params).subscribe((response: SubjectClientPagination) => {
            this.clients = response.data;
            this.clients.forEach((item) => {
                item.percentage = this.calculatePercentage(item.revenue, item.total_revenue);
            });
            this.clientsPagination = response._meta;
            this.spinner.hide('table-clients');
        }, err => {
            this.spinner.hide('table-clients');
        });
    }

    loadSuppliers() {
        this.spinner.show('table-suppliers');
        const params = {};
        if (this.selectedDateRange.from) {
            params['from'] = this.selectedDateRange.from;
        }
        if (this.selectedDateRange.to) {
            params['to'] = this.selectedDateRange.to;
        }
        if (this.suppliersPagination?.currentPage) {
            params['page'] = this.clientsPagination?.currentPage;
        }
        if (this.supplierName) {
            params['company_name'] = this.supplierName;
        }
        if (this.filter.competence) {
            params['competence'] = this.filter.competence;
        }
        this.invoiceDocumentService.getSubjectSuppliers(params).subscribe((response: SubjectSupplierPagination) => {
            this.suppliers = response.data;
            this.suppliersPagination = response._meta;
            this.suppliers.forEach((item) => {
                item.percentage = this.calculatePercentage(item.cost, item.total_cost);

            });
            this.spinner.hide('table-suppliers');
        }, err => {
            this.spinner.hide('table-suppliers');
        });
    }

    calculatePercentage(value: any, total: any) {
        if (value && total) {
            const percentage = Number(value) / Number(total) * 100;
            return percentage.toFixed(2);
        }
        return 0;
    }

    getDateRangeString(startDate: any, endDate: any) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        const start = moment(startDate);
        const end = moment(endDate);
        this.selectedDateRange.from = start.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.selectedDateRange.to = end.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        return start.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + end.format(localDateFormat);
    }

    setSelectedDateRange(from: Moment, to: Moment) {
        const selectedDateRange = this.getDateRangeString(from, to);
        this.filter.date = selectedDateRange;
    }

    loadData() {
        setTimeout(() => {
            this.loadClients();
            this.loadSuppliers();
        });
    }

    loadMapReport() {
        this.spinner.show('map');
        this.invoiceDocumentService.getMapReport().subscribe((response: DocumentMap[]) => {
            this.markers = response;
            this.spinner.hide('map');
        }, err => {
            this.spinner.hide('map');
        });
    }

    calculateRadius(total: any) {
        const num = Number(total) * 3000;
        return num < 6000 ? 6000 : num > 50000 ? 50000 : num;
    }

    parseFloat(num) {
        return parseFloat(num);
    }
}
