import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {EPayService} from '@services/e-pay.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ShopBrand, ShopBrandPagination} from '@models/e-pay/shop-brand';
import {MetaPagination} from '@models/meta-pagination';
import {ShopProductCategory, ShopProductCategoryPagination} from '@models/e-pay/shop-product-category';
import {ShopProduct, ShopProductPagination} from '@models/e-pay/shop-product';
import {animate, style, transition, trigger} from '@angular/animations';
import {GuidedTour, GuidedTourService, Orientation} from 'ngx-guided-tour';
import {LocalStorage, LocalStorageService} from 'ngx-webstorage';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {CartService} from '@services/cart.service';
import {Router} from '@angular/router';
import {DataService} from '@services/data.service';
import {Product} from '@models/product';

@Component({
    selector: 'app-e-pay-shop',
    animations: [
        trigger('fade', [
            transition('void => *', [
                style({opacity: 0}),
                animate(1000, style({opacity: 1}))
            ]),
        ])

    ],
    templateUrl: './e-pay-shop.component.html',
    styleUrls: ['./e-pay-shop.component.css']
})
export class EPayShopComponent implements OnInit {

    @ViewChild("freeAccessSwal")
    public readonly freeAccessSwal!: SwalComponent;

    shopModal: NgbModalRef;
    shopBrandProducts: NgbModalRef;
    @ViewChild('shopFormTemplate') private shopFormTemplate;
    @ViewChild('shopBrandTemplate') private shopBrandTemplate;
    brands: ShopBrand[] = [];
    brandsPagination: MetaPagination;
    categories: ShopProductCategory[] = [];
    categoriesPagination: MetaPagination;
    selectedProduct: ShopProduct;
    selectedBrandId: number = null;
    selectedCategoryId: number = null;
    rowNumber: number = null;
    colNumber: number = null;
    loadingProducts = false;
    products: ShopProduct[] = [];
    searchFilter = '';

    shopProducts: Product[];

    loading = false;
    canAccess = false;

    loadingFreeAccess = false;

    @LocalStorage('hideTourEpay')
    private hideTourEpay;

    public firstTour: GuidedTour = {
        tourId: 'first-tour',
        useOrb: false,
        skipCallback: () => { this.storage.store('hideTourEpay', true) },
        steps: [
            {
                title: 'Benvenuto nel servizio KDRicarica!',
                content: "Scopri attraverso questo tour guidato com'è facile utilizzare la piattaforma.",
            },
            {
                title: 'Scegli il brand',
                selector: '#brandStep',
                orientation: Orientation.Right,
                content: 'Seleziona il brand per mostrare i prodotti disponibili',
                closeAction: () => this.selectBrand(1,1,0,0)
            },
        ]
    };

    public secondTour: GuidedTour = {
        tourId: 'second-tour',
        useOrb: false,
        skipCallback: () => { this.storage.store('hideTourEpay', true) },
        steps: [
            {
                title: 'Scegli il prodotto',
                selector: '#productStep',
                orientation: Orientation.Right,
                content: 'Seleziona tra quelli disponibili il prodotto che vuoi acquistare',
                closeAction: () => this.showModal(this.products[0])
            },
        ]
    };

    public thirdTour: GuidedTour = {
        tourId: 'third-tour',
        useOrb: false,
        skipCallback: () => { this.storage.store('hideTourEpay', true) },
        completeCallback: () => { this.storage.store('hideTourEpay', true); this.selectedBrandId = null; this.modalService.dismissAll(); },
        steps: [
            {
                title: 'Scegli la variante',
                selector: '#variantStep',
                orientation: Orientation.Bottom,
                content: 'Seleziona il taglio della ricarica o buono regalo che vuoi acquistare',
            },
            {
                title: 'Prosegui',
                selector: '#nextStep',
                orientation: Orientation.Right,
                content: 'Clicca prosegui per andare nel carrello e concludere l\'acquisto',
            },
        ]
    };

    get subscribeOptions () {
        return {
            title: 'Complimenti!',
            html: '<img src="/assets/img/flats/fire-cracker.png" class="mb-3" /><p>Sei tra i primi 500 iscritti ed hai ottenuto la piattaforma gratis per 1 anno!</p>',
            confirmButtonText: 'Accedi subito a KDRicarica!',
            customClass: {
                confirmButton: 'btn bg-gradient-success',
            },
        };
    };

    constructor(
        private modalService: NgbModal,
        private router: Router,
        private dataService: DataService,
        private ePayService: EPayService,
        private storage: LocalStorageService,
        private tourService: GuidedTourService,
        private cartService: CartService,
        private spinner: NgxSpinnerService) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.spinner.show('spinner');

        this.ePayService.checkAccess().subscribe((data: boolean) => {
            // this.spinner.hide('spinner');
            this.canAccess = data;
            if(this.canAccess) {
                this.loadCategoriesAndProducts();
            }
            else {
                this.dataService.get('/get-prodotti')
                    .subscribe((data: any) => {
                        this.shopProducts = new Product().fromArray(data.result);
                        this.loading = false;
                    });
            }
        });
    }

    showModal(product: ShopProduct) {
        this.selectedProduct = product;
        this.shopModal = this.modalService.open(this.shopFormTemplate, {size: 'md'});

        if(!this.hideTourEpay) {
            setTimeout(() => {
                this.tourService.startTour(this.thirdTour);
            }, 500);
        }
    }

    loadBrands(perPage?) {
        const params = {};
        if (this.searchFilter) {
            params['name'] = this.searchFilter;
        }
        params['is_active'] = 1;
        params['pagination'] = 1;
        this.spinner.show('brands');
        this.ePayService.getBrands(params).subscribe((response: ShopBrandPagination) => {
            this.brands = response.data;
            this.brandsPagination = response._meta;
            this.spinner.hide('brands');
        }, err => {
            this.spinner.hide('brands');
        });
    }

    loadCategoriesAndProducts(brandId?: number) {
        const params = {};
        if (brandId) {
            params['brand_id'] = brandId;
        }
        this.spinner.show('categories');
        this.ePayService.getCategoriesAndProducts(params).subscribe((response: ShopProductCategoryPagination) => {
            this.categories = response.data;
            this.categoriesPagination = response._meta;
            this.spinner.hide('categories');


            if(!this.hideTourEpay) {
                setTimeout(() => {
                    this.tourService.startTour(this.firstTour);
                }, 2000);
            }
            this.loading = false;
        }, err => {
            this.spinner.hide('categories');

            this.loading = false;
        });
    }

    selectBrand(categoryId: number, brandId: number, rowNumber: number, colNumber: number) {
        let params = {
            brand_id: brandId,
            category_id: categoryId
        };

        this.spinner.show('products');
        this.products = [];
        this.loadingProducts = true;
        this.rowNumber = rowNumber;
        this.colNumber = colNumber;
        this.selectedBrandId = brandId;
        this.selectedCategoryId = categoryId;

        this.ePayService.getProducts(params)
            .subscribe((response: ShopProductPagination) => {
                this.products = response.data;
                this.loadingProducts = false;
                this.spinner.hide('products');
                if(!this.hideTourEpay) {
                    setTimeout(() => {
                        this.tourService.startTour(this.secondTour);
                    }, 500);
                }
        }, err => {
        });
        // this.shopBrandProducts = this.modalService.open(this.shopBrandTemplate, {size: 'md'});
    }

    isShownAll() {
        return this.brandsPagination.totalCount === this.brandsPagination.perPage;
    }

    round(number: number) {
        return Math.ceil(number);
    }

    getRows(brands) {
        let myArray = [];
        for(let i = 0; i < brands.length; i += 9) {
            myArray.push(brands.slice(i, i+9));
        }
        return myArray;
    }

    getFreeAccess() {
        this.loadingFreeAccess = true;
        this.ePayService.getFreeAccess()
            .subscribe(success => {
                if(success) {
                    this.freeAccessSwal.fire();
                }
            });
    }

    reloadAccess() {
        this.ngOnInit();
    }

    getPaidAccess() {
        const id = 752;
        this.cartService.emptyCart();
        const prod = this.shopProducts.find(item => item.id.toString() === id.toString());
        this.cartService.addToCart(prod);
        this.router.navigate(['/negozio/carrello']);
    }
}
