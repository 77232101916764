import {Component, Input, OnInit} from '@angular/core';
import {DashboardService} from "@services/dashboard.service";
import {NgxSpinnerService} from "ngx-spinner";
import {OperatorService} from "@services/operator.service";
import {DataService} from '@services/data.service';

@Component({
  selector: 'app-autoload-table',
  templateUrl: './autoload-table.component.html',
  styleUrls: ['./autoload-table.component.css']
})
export class AutoloadTableComponent implements OnInit {
    uniqueID;
    _url: string;
    @Input() firstColumnClass = false;
    @Input() headerClass = false;
    @Input() striped = true;
    @Input() small = false;
    @Input() hideFooterDescription = false;
    @Input() hideFooter = false;
    @Input() hideHeader = false;
    @Input() operator = false;
    @Input() dealer = false;
    @Input() pageSize = 10;

    @Input() pagination = true;

    data: any;
    columns = [];
    rows = [];

    error = false;
    loading = true;
    additionalData: [];
    filters: [];

    @Input() set url(value: string) {
        this._url = value;

        if (this._url !== undefined && this._url !== null) {
            this.load();
        }
    }

    constructor(
        private dashboardService: DashboardService,
        private operatorService: OperatorService,
        private dataService: DataService,
        private spinner: NgxSpinnerService
    ) {
        this.uniqueID = Math.random().toString(36).substring(7);
    }

    ngOnInit() {

    }

    load() {
        this.error = false;
        this.loading = true;
        this.spinner.show('loader-table-'+ this.uniqueID);


        if(this.operator) {
            this.operatorService.get(this._url)
                .subscribe((data) => {
                    this.rows = data.result.rows;
                    this.columns = data.result.columns;
                    this.spinner.hide('loader-table-' + this.uniqueID);
                    this.loading = false;
                }, (error) => {
                    this.spinner.hide('loader-table-' + this.uniqueID);
                    this.error = true;
                    this.loading = false;
                });
        }
        else if(this.dealer) {
            this.dataService.get(this._url)
                .subscribe((data) => {
                    this.rows = data.result.rows;
                    this.columns = data.result.columns;
                    this.spinner.hide('loader-table-' + this.uniqueID);
                    this.loading = false;
                }, (error) => {
                    this.spinner.hide('loader-table-' + this.uniqueID);
                    this.error = true;
                    this.loading = false;
                });
        }
        else {
            this.dashboardService.get(this._url)
                .subscribe((data) => {
                    this.rows = data.result.rows;
                    this.columns = data.result.columns;
                    this.additionalData = data.result.additionalData;
                    this.filters = data.result.filters;
                    this.spinner.hide('loader-table-' + this.uniqueID);
                    this.loading = false;
                }, (error) => {
                    this.spinner.hide('loader-table-' + this.uniqueID);
                    this.error = true;
                    this.loading = false;
                });
        }
    }

    additionalDataUpdate($event: any) {
        this.error = false;
        this.loading = true;
        this.spinner.show('loader-table-'+ this.uniqueID);

        this.additionalData = $event;

        this.dashboardService.post(this._url, {
            additionalData: this.additionalData,
            filters: this.filters
        })
            .subscribe((data) => {
                this.rows = data.result.rows;
                this.columns = data.result.columns;
                this.additionalData = data.result.additionalData;
                this.filters = data.result.filters;
                this.spinner.hide('loader-table-' + this.uniqueID);
                this.loading = false;
            }, (error) => {
                this.spinner.hide('loader-table-' + this.uniqueID);
                this.error = true;
                this.loading = false;
            });
    }

    filtersUpdate($event) {
        this.error = false;
        this.loading = true;
        this.spinner.show('loader-table-'+ this.uniqueID);

        this.filters = $event;

        this.dashboardService.post(this._url, {
            additionalData: this.additionalData,
            filters: this.filters
        })
            .subscribe((data) => {
                this.rows = data.result.rows;
                this.columns = data.result.columns;
                this.additionalData = data.result.additionalData;
                this.filters = data.result.filters;
                this.spinner.hide('loader-table-' + this.uniqueID);
                this.loading = false;
            }, (error) => {
                this.spinner.hide('loader-table-' + this.uniqueID);
                this.error = true;
                this.loading = false;
            });
    }
}
