<div class="card mt-3 bg-gradient-light">
    <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="campaign-details"
            [fullScreen]="false"
    >
        <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <div class="card-header">
        <h4 class="mb-0">{{campaign.name}}
            <span class="float-right lead"><span
                    translate>Campaign period</span>: {{campaign.start_date|amDateFormat:localFormat}}
                - {{campaign.end_date|amDateFormat:localFormat}}
                <span class="badge badge-success ml-4" *ngIf="campaign.status==status.active"
                      translate>Active</span>
                     <span class="badge badge-danger ml-4" *ngIf="campaign.status==status.expired"
                           translate>Expired</span>
                    <span class="badge badge-warning ml-4" *ngIf="campaign.status==status.on_hold"
                          translate>On Hold</span>
                <button class="btn btn-xs btn-light ml-2 ml-lg-4" translate (click)="showCampaignModal()">
                Change
            </button>
            <button class="btn btn-xs btn-danger ml-2" translate [swal]="deleteCampaignOptions"
                    (confirm)="deleteCampaign(campaign.id)">
                Delete
            </button>
            </span>
        </h4>
    </div>
    <div class="card-body">
        <p [innerHTML]="campaign.description"></p>
    </div>
</div>
<div class="card mt-3 bg-gradient-light">
    <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="campaign-widget"
            [fullScreen]="false"
    >
        <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <div class="card-body" style="min-height: 100px;">
        <div class="row d-flex align-items-center" *ngIf="report">
            <div class="col-lg col-12 dashboard-card">
                <div class="d-flex align-items-center"><img alt=""
                                                            class="d-inline-flex" width="70px"
                                                            src="assets/img/flats/employee.png">
                    <div class="d-block w-100 pl-3">
                        <p class="lead" translate>There are</p>
                        <h1 class="display-6">{{report?.number_of_leads}} {{report?.number_of_leads > 1 ? 'leads' : 'lead'}}</h1>
                    </div>
                </div>
            </div>
            <div class="col-lg col-12 dashboard-card">
                <div class="d-flex align-items-center">
                    <img class="d-inline-flex" width="70px"
                         src="assets/img/flats/phone-receiver.png">
                    <div class="d-block w-100 pl-3">
                        <p class="lead" translate>Calls made</p>
                        <h1 class="display-6">{{report?.number_of_calls}} <span
                                translate>{{report?.number_of_calls > 1 ? 'calls' : 'call' | translate}}</span></h1>
                    </div>
                </div>
            </div>
            <div class="col-lg col-12 dashboard-card">
                <div class="d-flex align-items-center"><img
                        class="d-inline-flex" width="70px"
                        src="assets/img/flats/deal.png">
                    <div class="d-block w-100 pl-3">
                        <p class="lead" translate>Positive calls</p>
                        <h1 class="display-6">{{report?.number_of_positive_calls}} <span
                                translate>{{report?.number_of_positive_calls > 1 ? 'calls' : 'call'}}</span></h1>
                    </div>
                </div>
            </div>
            <div class="col-lg col-12 dashboard-card">
                <div class="d-flex align-items-center">
                    <img class="d-inline-flex" width="70px"
                         src="assets/img/flats/dislike.png">
                    <div class="d-block w-100 pl-3">
                        <p class="lead" translate>Negative calls</p>
                        <h1 class="display-6">{{report?.number_of_negative_calls}} <span
                                translate>{{report?.number_of_negative_calls > 1 ? 'calls' : 'call'}}</span></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 col-lg-6">
        <div class="card bg-transparent  mb-0">
            <div class="card-header">
                <div class="row">
                    <div class="col-lg-8 col-12">
                        <h4 class="mb-0" translate>Operators</h4>
                    </div>
                    <div class="col-lg-4 col-12">
                        <form (ngSubmit)="loadOperatorsReport()">
                            <div class="input-group input-group-sm w-100">
                                <input class="form-control float-right" name="table_search" placeholder="Cerca ..."
                                       [(ngModel)]="operatorFilter"
                                       type="text">
                                <div class="input-group-append">
                                    <button class="btn btn-default" type="submit"><i class="fas fa-search"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="card-body  table-responsive p-0">
                <table class="table table-sm mb-0 table-striped ng-star-inserted">
                    <thead>
                    <tr>
                        <th translate>Operator</th>
                        <th translate>Time on call</th>
                        <th translate>Positive calls</th>
                        <th translate>Negative calls</th>
                        <th translate>Total calls</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ngx-spinner
                            bdColor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="square-jelly-box"
                            name="table-operators"
                            [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <tr *ngFor="let operator of operators">
                        <td>{{operator.caller_name}}</td>
                        <td>{{operator.total_duration}}</td>
                        <td>{{operator.positive_calls}}
                            <small>{{percentage(operator.total_calls, operator.positive_calls)}}%</small></td>
                        <td>{{operator.negative_calls}}
                            <small>{{percentage(operator.total_calls, operator.negative_calls)}}%</small></td>
                        <td>{{operator.total_calls}}</td>
                    </tr>
                    <tr *ngIf="operators?.length<=0">
                        <td translate colspan="5">List is empty</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer" *ngIf="operators?.length>0">
                <span><span translate>There are</span> <b> {{ this.operatorsPagination.totalCount }}</b>
                    {{this.operatorsPagination.totalCount <= 1 ? 'operator' : 'operators'}}.</span>
                <ngb-pagination *ngIf="operatorsPagination.pageCount>1"
                                size="sm"
                                [pageSize]="operatorsPagination.perPage"
                                [maxSize]="5"
                                [(page)]="operatorsPagination.currentPage"
                                [collectionSize]="operatorsPagination.totalCount"
                                (pageChange)="loadOperatorsReport()"
                                [boundaryLinks]="true"
                ></ngb-pagination>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6">
        <div class="card bg-transparent mb-0">
            <div class="card-header">
                <h4 class="mb-0" translate>Questions report</h4>
            </div>
            <div class="card-body table-responsive p-0">
                <ngx-spinner
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="default"
                        color="#fff"
                        type="square-jelly-box"
                        name="questions-report"
                        [fullScreen]="false"
                >
                    <p style="color: white">Caricamento...</p>
                </ngx-spinner>
                <table class="table table-striped" *ngFor="let questionCategory of questionCategories">
                    <thead>
                    <tr>
                        <th class="bg-gradient-dark" translate>{{questionCategory.name}}</th>
                        <th class="bg-gradient-dark"></th>
                        <th class="bg-gradient-dark"></th>
                    </tr>
                    </thead>
                    <tbody *ngFor="let question of questions">
                    <tr *ngIf="question.info.category == questionCategory.key">
                        <td class="bg-gradient-gray w-50"> {{question.info.title}}
                        </td>
                        <td class="w-25">{{question.number_of_answers}} <span translate>shops</span></td>
                        <td class="w-25">{{percentage(question.number_of_answers, question.positive_answers)}}%</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #campaignFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Change Campaign</h4>
        <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="modalBusy">
        <form [formGroup]="campaignForm" (ngSubmit)="editCampaign()">
            <div class="form-row">
                <div class="col-12 col-lg-9 form-group">
                    <label for="name" translate>Name</label>
                    <input class="form-control" id="name" formControlName="name">
                    <val-errors controlName="name">
                        <ng-template valError="required">
                            <span translate>Campaign name is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-lg-3 form-group">
                    <label for="validityPeriod" translate>Campaign period</label>
                    <input id="validityPeriod" class="form-control" type="text" daterangepicker
                           formControlName="date_range" (selected)="setDateRange($event.start,$event.end)"/>
                    <val-errors controlName="date_range">
                        <ng-template valError="required">
                            <span translate>Validity period is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 form-group">
                    <label for="description" translate>Description</label>
                    <editor apiKey="1nilu7q8vcp0ntuviwq5nhcskmj17c6awfhpbnr0hhv4smdw" formControlName="description" id="description" [init]="defaultConfig"></editor>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="campaignForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
