<div class="card bg-gradient-1">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                 width="160px"
                 src="assets/img/flats/placeholder.png">
            <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>Dealers map</h1>
                <hr>
                <form (ngSubmit)="loadData()">
                    <div class="form-row align-items-end">
                        <div class="col-lg-6 col-12"><label for="date" translate>From month - To month</label>
                            <app-month-range-picker
                                    (selected)="selectedDateRange($event.start, $event.end)"
                                    id="date"></app-month-range-picker>
                        </div>
                        <div class="col-lg-3 col-12"><label for="masterAgent" translate>Master agent</label>
                            <app-master-agent-select id="masterAgent"
                                                     (onChange)="filter.master_agent_id = $event"></app-master-agent-select>
                        </div>
                        <div class="col-lg-3 col-12"><label for="agent" translate>Agent</label>
                            <app-agent-select (onChange)="filter.agent_id = $event" id="agent"></app-agent-select>
                        </div>
                        <div class="col-lg-3 col-12"><label for="operator" translate>Operator</label>
                            <ng-select [items]="segments" bindValue="id" bindLabel="title" id="operator"></ng-select>
                        </div>
                        <div class="col-lg-3 col-12"><label for="region" translate>Region</label>
                            <app-state-select id="region"
                                              (onChange)="changeRegion($event)"
                            ></app-state-select>
                        </div>
                        <div class="col-lg-3 col-12"><label for="province" translate>Province</label>
                            <app-province-select id="province"
                                                 [state]="this.filter.region_id"
                                                 [onDemand]="true"
                                                 (onChange)="changeProvince($event)"
                            ></app-province-select>
                        </div>
                        <div class="col-lg-3 col-12 mt-2 mt-lg-auto">
                            <button class="btn btn-info btn-block" type="submit" translate>Filter
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-header">
        <div ngbDropdown class="d-inline-block" [autoClose]="false">
            <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle translate>
                Dealers
            </button>
            <div ngbDropdownMenu>
                <ul style="max-height: 300px; overflow-y: scroll; padding: 0;">
                    <li ngbDropdownItem *ngFor="let item of dealerFields">
                        <label style="min-width: 100%" class="mb-0" >
                            <input type="checkbox" [(ngModel)]="item.checked"/>
                            <span class="ml-2" translate>{{ item.label }}</span>
                        </label>
                    </li>
                </ul>
                <div class="px-4 mt-2">
                    <button
                            class="btn btn-primary btn-sm btn-block" (click)="refreshDealersInMap()" translate
                    >
                        Refresh
                    </button>
                </div>
            </div>
        </div>
        <div ngbDropdown class="d-inline-block ml-2" [autoClose]="false">
            <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle translate>
                Cities
            </button>
            <div ngbDropdownMenu>
                <ul style="max-height: 300px; overflow-y: scroll; padding: 0;">
                    <li ngbDropdownItem *ngFor="let item of cityFields">
                        <label style="min-width: 100%" class="mb-0">
                            <input type="checkbox" [(ngModel)]="item.checked"/>
                            <span class="ml-2" translate>{{ item.label }}</span>
                        </label>
                    </li>
                </ul>
                <div class="px-4 mt-2">
                    <button
                            class="btn btn-primary btn-sm btn-block" (click)="refreshCitiesInMap()"
                            translate
                    >
                        Refresh
                    </button>
                </div>
            </div>
        </div>
        <div class="card-tools mt-2 mt-lg-0">
            <button class="btn btn-success btn-sm" (click)="exportTable()" translate
                    [disabled]="dealers?.length<=0 && cities?.length<=0">
                Export
            </button>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="row no-gutters">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="map"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <div class="col-12 col-lg-6">
                <agm-map #gm
                         [latitude]='42.2894929'
                         [longitude]='11.7101332'
                         [zoom]="6"
                         [showDefaultInfoWindow]="false"
                         [clickableIcons]="false"
                         [styles]="defaultStyles">
                    <agm-marker
                            *ngFor="let dealer of dealers"
                            [latitude]="dealer.lat"
                            [longitude]="dealer.lng"
                            [openInfoWindow]="true"
                            [iconUrl]="dealer?.map_options"
                            (markerClick)="gm.lastOpen?.close(); gm.lastOpen = infoWindow"
                    >
                        <agm-info-window #infoWindow>
                            <p class="w-100">
                                <strong>{{dealer.company_name}}</strong>
                            </p>
                            <p class="w-100"><span class="pr-2"
                                                   translate>Sales from {{selectedDate.from|amDateFormat:'MMMM'}}
                                to {{selectedDate.to|amDateFormat:'MMMM'}}</span>
                                <span
                                        class="float-right"><strong>{{dealer.sales}}</strong></span>
                            </p>
                        </agm-info-window>
                    </agm-marker>
                    <agm-marker
                            *ngFor="let city of citiesWithShops"
                            [latitude]="city.lat"
                            [longitude]="city.lon"
                            [iconUrl]="greenPinOptions"
                            [openInfoWindow]="true"
                            (markerClick)="gm.lastOpen?.close(); gm.lastOpen = cityInfoWindow1"
                    >
                        <agm-info-window #cityInfoWindow1>
                            <p class="w-100">
                                <strong>{{city.name}}</strong>
                            </p>
                            <p class="w-100"><span class="pr-2"
                                                   translate>Shops</span>
                                <span
                                        class="float-right"><strong>{{city.shops}}</strong></span>
                            </p>
                        </agm-info-window>
                    </agm-marker>
                    <agm-marker
                            *ngFor="let city of citiesWithoutShops"
                            [latitude]="city.lat"
                            [longitude]="city.lon"
                            [iconUrl]="redPinOptions"
                            [openInfoWindow]="true"
                            (markerClick)="gm.lastOpen?.close(); gm.lastOpen = cityInfoWindow2"
                    >
                        <agm-info-window #cityInfoWindow2>
                            <p class="w-100">
                                <strong>{{city.name}}</strong>
                            </p>
                            <p class="w-100"><span class="pr-2"
                                                   translate>Shops</span>
                                <span
                                        class="float-right"><strong>{{city.shops}}</strong></span>
                            </p>
                        </agm-info-window>
                    </agm-marker>
                </agm-map>
            </div>
            <div class="col-12 col-lg-6">
                <div class="table-responsive p-0 table-container">
                    <table class="table table-sm mb-0 table-striped" *ngIf="isDealerMap">
                        <thead>
                        <tr>
                            <th scope="col" translate>Company name</th>
                            <th scope="col" translate>Region</th>
                            <th scope="col" translate>Province</th>
                            <th scope="col" translate>City</th>
                            <th scope="col" translate>Sales</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ngx-spinner
                                bdColor="rgba(0, 0, 0, 0.8)"
                                size="default"
                                color="#fff"
                                type="square-jelly-box"
                                name="table-visits"
                                [fullScreen]="false"
                        >
                            <p style="color: white">Caricamento...</p>
                        </ngx-spinner>
                        <tr *ngFor="let dealer of dealers">
                            <td>
                                <a [routerLink]="['/dealer', dealer?.id]" target="_blank">{{
                                    dealer.company_name
                                    }}</a></td>
                            <td>{{dealer?.region_name}}</td>
                            <td>{{dealer?.province_name}}</td>
                            <td>{{dealer?.city_name}}</td>
                            <td>{{dealer?.sales}}</td>
                        </tr>
                        <tr *ngIf="dealers?.length<=0">
                            <td translate colspan="5" translate>List is empty</td>
                        </tr>
                        </tbody>
                    </table>
                    <table class="table table-sm mb-0 table-striped" *ngIf="!isDealerMap">
                        <thead>
                        <tr>
                            <th scope="col" translate>City</th>
                            <th scope="col" translate>Province</th>
                            <th scope="col" translate>Region</th>
                            <th scope="col" translate>Shops</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ngx-spinner
                                bdColor="rgba(0, 0, 0, 0.8)"
                                size="default"
                                color="#fff"
                                type="square-jelly-box"
                                name="table-visits"
                                [fullScreen]="false"
                        >
                            <p style="color: white">Caricamento...</p>
                        </ngx-spinner>
                        <tr *ngFor="let city of cities">
                            <td>{{city?.name}}</td>
                            <td>{{city?.province_name}}</td>
                            <td>{{city?.region_name}}</td>
                            <td>{{city?.shops}}</td>
                        </tr>
                        <tr *ngIf="cities?.length<=0">
                            <td translate colspan="5" translate>List is empty</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
