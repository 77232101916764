import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import * as _ from 'lodash';
import {DatePipe} from '@angular/common';
import {DataService} from '@services/data.service';
import {Subscription} from 'rxjs';
import {Customer} from '@models/customer';
import {debounceTime, distinct, distinctUntilChanged} from 'rxjs/operators';
import {Helpers} from '@helpers/helpers';
import {Provincia} from '@models/provincia';
import {Comune} from '@models/comune';
import {Stato} from '@models/stato';
import * as moment from 'moment';

declare var EXIF: any;

@Component({
    selector: 'app-customer-form',
    templateUrl: './customer-form.component.html',
    styleUrls: ['./customer-form.component.scss'],
})
export class CustomerFormComponent implements OnInit {
    public form: FormGroup;

    busy: Subscription;

    @Output() isValid = new EventEmitter();
    @Output() customerData = new EventEmitter();

    @Input()
    isBusinessCustomer: boolean;
    @Input()
    customers: Customer[];

    selectOptions = {
        doc_identita: [],
        sesso: []
    };
    stati: Stato[] = [];
    province: Provincia[] = [];
    comuniSede: Comune[] = [];
    comuniResidenza: Comune[] = [];
    comuniNascita: Comune[] = [];
    selectedCustomer: Customer;

    constructor(
        public dataService: DataService,
        public formBuilder: FormBuilder
    ) {
    }

    ngOnInit() {
        this.dataService.get('/get-province').subscribe((data) => this.province = data.result);
        this.dataService.get('/get-stati').subscribe((data) => this.stati = data.result);

        this.form = this.formBuilder.group({
            id: [null, []],
            business: [this.isBusinessCustomer ? 1 : 0, []],
            ragione_sociale: [null, this.isBusinessCustomer ? [Validators.required] : []],
            partita_iva: [null, this.isBusinessCustomer ? [Validators.required] : []],
            nome: [null, [Validators.required]],
            cognome: [null, [Validators.required]],
            codfiscale: [null, [Validators.required, /*Validators.pattern(this.config.patternCF)*/]],
            sesso: [null, [Validators.required]],
            nascita_nazione: [106, [Validators.required]],
            nascita_provincia: [null, [Validators.required]],
            nascita_localita: [null, [Validators.required]],
            nascita_data: [null, [Validators.required, /*Validators.pattern(this.config.patternDate)*/, this.validaDataDiNascita()]],
            residenza_provincia: [null, [Validators.required]],
            residenza_localita: [null, [Validators.required]],
            residenza_cap: [null, [Validators.required, /*Validators.pattern(this.config.patternCAP)*/]],
            residenza_indirizzo: [null, [Validators.required]],
            residenza_civico: [null, [Validators.required]],
            sede_provincia: [null, this.isBusinessCustomer ? [Validators.required] : []],
            sede_localita: [null, this.isBusinessCustomer ? [Validators.required] : []],
            sede_cap: [null, this.isBusinessCustomer ? [Validators.required] : []],
            sede_indirizzo: [null, this.isBusinessCustomer ? [Validators.required] : []],
            sede_civico: [null, this.isBusinessCustomer ? [Validators.required] : []],
            documento_tipo: [null, [Validators.required]],
            documento_numero: [null, [Validators.required]],
            documento_rilascio: [null, [Validators.required, /*Validators.pattern(this.config.patternDate)*/,
                this.validaRilascioDocumento()]],
            documento_scadenza: [null, [Validators.required, /*Validators.pattern(this.config.patternDate)*/,
                this.validaScadenzaDocumento()]],
            telefono: [null, [Validators.required]],
            email: [null, [Validators.required, Validators.email]],
            documento_path: [null, [Validators.required]],
            altro_documento_path: [null, [Validators.required]],
        });

        this.f().nascita_nazione.valueChanges.subscribe((value) => {
            if (value !== 106) {
                this.f().nascita_provincia.setValue(109);
            } else {
                this.f().nascita_provincia.setValue(null);
            }
        });
        this.f().nascita_provincia.valueChanges.subscribe((value) => {
            this.f().nascita_localita.setValue(null);
            this.dataService.post('/get-comuni', {id_provincia: value})
                .subscribe((data) => {
                    this.comuniNascita = data.result;
                    if (value === 109) {
                        this.f().nascita_localita.setValue(8101);
                    }
                });
        });
        this.f().residenza_provincia.valueChanges.subscribe((value) => {
            this.dataService.post('/get-comuni', {id_provincia: value}).subscribe((data) => this.comuniResidenza = data.result);
        });

        this.f().sede_provincia.valueChanges.subscribe((value) => {
            this.dataService.post('/get-comuni', {id_provincia: value}).subscribe((data) => this.comuniSede = data.result);
        });

        this.selectOptions.doc_identita = [
            {id: 1, nome: 'Carta d\'identità'},
            {id: 2, nome: 'Patente'},
            {id: 3, nome: 'Passaporto'}
        ];
        this.selectOptions.sesso = [
            {id: 0, nome: 'Maschio'},
            {id: 1, nome: 'Femmina'}
        ];

        this.f().id.valueChanges.pipe(distinct()).subscribe(() => this.loadCustomer());

        this.form.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        )
            .subscribe((data) => {
                this.handleChanges();
            });
    }

    f() {
        return this.form.controls;
    }

    handleChanges() {
        const isValid = this.form.valid;

        this.isValid.emit(isValid);

        this.customerData.emit(this.form.value);
    }

    setFiles($event: any, field: string) {
        const files = $event.target.files.item(0);

        if (!files) {
            return;
        }

        if (field === 'scansione_fronte') {
            this.f().documento_path.setValue(files);
        }

        if (field === 'scansione_retro') {
            this.f().altro_documento_path.setValue(files);
        }

        this.handleChanges();
    }

    /**
     * Alla selezine di un cliente esistente carica i dati nel  form
     */
    private loadCustomer() {
        if (this.f().id.value) {
            const customer = _.find(this.customers, item => item.id === this.f().id.value);
            this.selectedCustomer = customer;
            const keyPairs = Object.keys(this.f());

            for (const keyPair of keyPairs) {
                this.f()[keyPair].setValue(customer[keyPair]);
            }

            this.f().nascita_data
                .setValue(
                    new DatePipe('en-US').transform(
                        this.f().nascita_data.value, 'dd-MM-yyyy'
                    )
                );

            this.f().documento_rilascio
                .setValue(
                    new DatePipe('en-US').transform(
                        this.f().documento_rilascio.value, 'dd-MM-yyyy'
                    )
                );
        } else {
            this.selectedCustomer = null;
            this.form.reset();
        }
    }

    setCustomerData($event: any) {
        const keyPairs = Object.keys($event);

        for (const keyPair of keyPairs) {
            this.f()[keyPair].setValue($event[keyPair]);
        }

    }

    validaDataDiNascita(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control.value != null) {
                let day = moment(control.value, 'DD-MM-YYYY');
                const checkPassed = moment().diff(day, 'years') >= 18;
                return checkPassed ? null : {adult: {value: 'E\' necessario essere maggiorenni'}};
            } else {
                return {required: {value: control.value}};
            }
        };
    }

    validaRilascioDocumento(): ValidatorFn {
        // console.log('VALIDAZIONE RILASCIO DOCUMENTO');
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control.value != null) {
                const inputDate = control.value.split('-');
                const checkPassed = (new Date(inputDate[2] + '-' + inputDate[1] + '-' + inputDate[0])) <= (new Date());
                return checkPassed ? null : {period: {value: 'La data inserita deve essere precedente al giorno corrente'}};
            } else {
                return {required: {value: control.value}};
            }
        };
    }

    validaScadenzaDocumento(): ValidatorFn {
        // console.log('VALIDAZIONE SCADENZA DOCUMENTO');
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control.value != null) {
                const inputDate = control.value.split('-');
                const checkPassed = (new Date(inputDate[2] + '-' + inputDate[1] + '-' + inputDate[0])) >= (new Date());
                return checkPassed ? null : {period: {value: 'La data inserita deve essere precedente al giorno corrente'}};
            } else {
                return {required: {value: control.value}};
            }
        };
    }

}
