<div class="modal-body" [ngBusy]="busy">
    <form *ngIf="modalType =='campaign'" [formGroup]="campaignForm" (ngSubmit)="saveCampaign()">
        <div class="form-row">
            <div class="col-6 form-group">
                <label for="title" translate>Campaign title</label>
                <input class="form-control" id="title" formControlName="title">
                <val-errors controlName="title">
                    <ng-template valError="required">
                        <span translate>Campaign title is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-6 form-group">
                <label for="validityPeriod" translate>Validity period</label>
                <input id="validityPeriod" class="form-control" type="text" daterangepicker
                       (selected)="setDateRange($event.start,$event.end)"
                       formControlName="date_range"/>
                <val-errors controlName="date_range">
                    <ng-template valError="required">
                        <span translate>Validity period is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12">
                <button type="submit" class="btn btn-success btn-block" [disabled]="campaignForm.invalid" translate>
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                </button>
            </div>
        </div>
    </form>
    <form *ngIf="modalType =='rules'" [formGroup]="rulesForm" (ngSubmit)="saveRule()">
        <div class="form-row">
            <div class="col-6 form-group">
                <label for="vendorSegment" translate>Vendor Segment</label>
                <ng-select [items]="vendorSegments" id="vendorSegment" formControlName="vendor_segment_id"
                           [readonly]="selectedItem"
                           searchable="true"
                           bindLabel="title" bindValue="id" (ngModelChange)="loadOffers($event)"></ng-select>
                <val-errors controlName="vendor_segment_id">
                    <ng-template valError="required">
                        <span translate>Vendor Segment is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-6 form-group">
                <label for="offer" translate>Offer</label>
                <ng-select [items]="offers" id="offer" formControlName="offer" searchable="true"
                           [readonly]="selectedItem"
                           bindLabel="offer" bindValue="offer"></ng-select>
                <val-errors controlName="offer">
                    <ng-template valError="required">
                        <span translate>Offer is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-6 form-group">
                <label for="count" translate>Number of sales</label>
                <input type="number" class="form-control" id="count" min="0" max="10000000" formControlName="count" step="1">
                <val-errors controlName="count">
                    <ng-template valError="required">
                        <span translate>Number of sales is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-6 form-group">
                <label for="points" translate>Points</label>
                <input type="number" class="form-control" id="points" min="0" formControlName="points" max="10000000" step="1">
                <val-errors controlName="points">
                    <ng-template valError="required">
                        <span translate>Points are required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12">
                <button type="submit" class="btn btn-success btn-block" [disabled]="rulesForm.invalid" translate>
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                </button>
            </div>
        </div>
    </form>
    <form *ngIf="modalType =='prizes'" [formGroup]="prizesForm" (ngSubmit)="savePrize()">
        <div class="form-row">
            <div class="col-6 form-group">
                <label for="prizeTitle" translate>Title</label>
                <input class="form-control" id="prizeTitle" formControlName="title">
                <val-errors controlName="title">
                    <ng-template valError="required">
                        <span translate>Title is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-6 form-group">
                <label for="required_points" translate>Required Points</label>
                <input class="form-control" id="required_points" type="number" min="0" max="10000000" step="1"
                       formControlName="required_points">
                <val-errors controlName="required_points">
                    <ng-template valError="required">
                        <span translate>Required Points are required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-6 form-group">
                <label for="image" translate>Image</label>
                <div class="custom-file" *ngIf="!imageName">
                    <input id="image" accept="image/*" type="file" class="custom-file-input"
                           (change)="handleFileInput($event?.target?.files[0])">
                    <label class="custom-file-label" for="image" translate>
                        Scegli file...
                    </label>
                </div>
                <div *ngIf="imageName">
                    <span>{{imageName}}</span>
                    <button class="btn btn-xs btn-warning float-right" type="button" (click)="handleFileRemoveModal()">
                        <i class="fa fa-times m-0 px-1"></i>
                    </button>
                </div>
            </div>
            <div class="col-12 col-lg-6  form-group">
                <label for="description" translate>Description</label>
                <textarea class="form-control" id="description" formControlName="description"></textarea>
            </div>
            <div class="col-12">
                <button type="submit" class="btn btn-success btn-block" [disabled]="prizesForm.invalid" translate>
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span></button>
            </div>
        </div>
    </form>
</div>

