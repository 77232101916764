import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {User} from '@models/user';
import {AuthenticationService} from '@services/authentication.service';
import * as moment from 'moment';
import {CampaignService} from '@services/campaign.service';
import {Campaign, CampaignListPagination, UsedPrize, UsedPrizesListPagination} from '@models/campaign';
import {ConfirmOptionsService} from '@services/confirm-options.service';
import {Prize, PrizeListPagination} from '@models/prize';
import {CampaignSegment, CampaignSegmentListPagination} from '@models/campaign-segment';
import {NgxSpinnerService} from 'ngx-spinner';
import {MetaPagination} from '@models/meta-pagination';


@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.css']
})
export class CampaignsComponent implements OnInit {

  user: User;
  selectedModal: string;
  modalTypes = ['campaign', 'rules', 'prizes'];
  selectedIndex: number;
  selectedItem;
  campaigns: Campaign[];
  pagination: MetaPagination;
  usedPrizesPagination: MetaPagination;
  format = 'd MMM y';
  busy: any;
  usedPrizesBusy: any;
  usedPrizes: UsedPrize[];
  selectedCampaign: Campaign;
  constructor(private modalService: NgbModal,
              private authenticationService: AuthenticationService,
              private campaignService: CampaignService,
              private spinner: NgxSpinnerService) {
    this.authenticationService.currentUser.subscribe((user: User) => {
          this.user = user;
        }
    );
    this.loadCampaigns();
  }

  ngOnInit(): void {
  }

  openModal(content, modal: string, i = null, selectedItem = null) {
    this.selectedItem = selectedItem;
    this.selectedIndex = i;
    this.selectedModal = modal;
    this.modalService.open(content, {size: 'lg', backdrop: 'static'});
  }

  openUsesPrizesModal(content, campaign: Campaign) {
    this.usedPrizes = [];
    this.selectedCampaign = campaign;
    this.modalService.open(content, {size: 'xl', backdrop: 'static'});
    if (campaign?.used_prizes) {
      this.usedPrizes = campaign?.used_prizes.data;
      this.usedPrizesPagination = campaign.used_prizes['_meta'];
    } else {
      this.loadUsedPrizes(campaign);
    }
  }

  isActive(startDate: string, endDate: string) {
    if (endDate) {
      return moment().isSameOrAfter(startDate) && moment().isBefore(endDate);
    }
    return false;
  }

  isExpired(endDate: string) {
    if (endDate) {
      return moment().isAfter(endDate);
    }
    return false;
  }

  isPending(startDate: string) {
    if (startDate) {
      return moment().isBefore(startDate);
    }
    return false;
  }

  get deleteCampaignOptions() {
    return new ConfirmOptionsService({
      title: 'Vuoi cancellarla?',
      text: 'Non potrai tornare indietro!',
      confirmButtonText: 'Si',
      cancelButtonText: 'Annulla'
    });
  }

  deleteCampaign(campaignId) {
    return this.campaignService.deleteCampaign(campaignId).subscribe(() => {
      this.campaigns = this.removeItemFromList(campaignId, this.campaigns);
      this.campaignService.successAlert('Deleted Campaign');
    }, err => {
      this.campaignService.errorAlert(err.message);
    });
  }

  deletePrize(prizeId, campaign) {
    return this.campaignService.deletePrize(prizeId).subscribe(() => {
      campaign.prizes.data = this.removeItemFromList(prizeId, campaign.prizes.data);
      this.campaignService.successAlert('Deleted Prize');
    }, err => {
      this.campaignService.errorAlert(err.message);
    });
  }

  deleteRule(ruleId, segment) {
    return this.campaignService.deleteRule(ruleId).subscribe(() => {
      segment.rules = this.removeItemFromList(ruleId, segment.rules);
      this.campaignService.successAlert('Deleted Rule');
    }, err => {
      this.campaignService.errorAlert(err.message);
    });
  }

  removeItemFromList(selectedId, list) {
    return this.campaignService.removeItemFromList(selectedId, list);
  }

  loadRulesAndPrizes(campaign: Campaign) {
    if (!campaign.segments) {
      this.loadRules(campaign);
    }
    if (!campaign.prizes) {
      this.loadPrize(campaign);
    }
  }

  loadCampaigns(event?) {
    this.busy = this.campaignService.getCampaigns(event).subscribe((response: CampaignListPagination) => {
      this.campaigns = response.data;
      this.pagination = response['_meta'];
    });
  }

  loadPrize(campaign, pageSize?) {
    this.spinner.show('prizes' + campaign.id);
    this.campaignService.getPrizes(campaign.id, pageSize).subscribe((response: PrizeListPagination) => {
      campaign.prizes = response;
      this.spinner.hide('prizes' + campaign.id);
    }, err => {
      this.spinner.hide('prizes' + campaign.id);
    });
  }

  loadAllPrizes(campaign) {
    if (!this.isShownAll(campaign?.prizes)) {
      return this.loadPrize(campaign, campaign.prizes['_meta'].totalCount);
    }
    return this.loadPrize(campaign);
  }

  loadRules(campaign, pageSize?) {
    this.spinner.show('rules' + campaign.id);
    this.campaignService.getSegmentsWithRules(campaign.id, pageSize).subscribe((response: CampaignSegmentListPagination) => {
      campaign.segments = response;
      this.spinner.hide('rules' + campaign.id);
    }, err => {
      this.spinner.hide('rules' + campaign.id);
    });
  }

  loadAllRules(campaign) {
    if (!this.isShownAll(campaign?.segments)) {
      return this.loadRules(campaign, campaign.segments['_meta'].totalCount);
    }
    return this.loadRules(campaign);
  }

  isShownAll(prizes) {
    return prizes['_meta'].totalCount === prizes['_meta'].perPage;
  }

  loadUsedPrizes(campaign: Campaign, pageNumber?) {
    this.usedPrizesBusy = this.campaignService.getUsedPrize(campaign.id, pageNumber).subscribe((response: UsedPrizesListPagination) => {
          campaign.used_prizes = response;
          this.usedPrizes = response['data'];
          this.usedPrizesPagination = response['_meta'];
        }, err => {
          this.usedPrizes = [];
          this.usedPrizesPagination = new MetaPagination();
        }
    );
  }
}
