<ng-template #modal let-modal size="xl">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{this.model.title}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <data-builder-table [url]="this.model.url"></data-builder-table>
    </div>
</ng-template>
