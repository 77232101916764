/* tslint:disable:variable-name */
export class SimKena {
  seriale: string;
  usato: number;
  created: string;

  get disponibile() {
    return this.usato === 0;
  }

  get serial() {
    return this.seriale.split('/')[0];
  }

  get numero() {
    return this.seriale.split('/')[1];
  }

  get stato() {
    return this.usato === 0 ? 'Disponibile' : 'Usato';
  }

  fromArray(result: []) {
    const array: SimKena[] = [];
    for (const item of result) {
      const tempItem: SimKena = new SimKena();
      Object.assign(tempItem, item);
      array.push(tempItem);
    }
    return array;
  }
}
