<div class="wrapper">
    <app-header (toggleMenuSidebar)="toggleMenuSidebar()" *hideDashboard></app-header>

    <app-menu-sidebar *hideDashboard
                      (mainSidebarHeight)="mainSidebarHeight($event)"
    ></app-menu-sidebar>

    <div class="content-wrapper p-3" *hideDashboard>
        <val-default-errors>
            <ng-template valError="date">La data dev'essere nel formato gg-mm-yyyy</ng-template>
            <ng-template valError="number">Questo campo non è numerico</ng-template>
            <ng-template valError="required">Questo campo è obbligatorio</ng-template>
            <ng-template valError="email">Non è un email valida</ng-template>
            <ng-template valError="pattern">Non è un campo valido</ng-template>
            <ng-template valError="min" let-error="error">Dev'essere minore di {{ error.min | number }}</ng-template>
            <ng-template valError="minlength" let-error="error">Dev'essere almeno di {{ error.requiredLength | number }}
                cifre/caratteri
            </ng-template>
            <ng-template valError="maxlength" let-error="error">Dev'essere al
                massimo {{ error.requiredLength | number }} cifre/caratteri
            </ng-template>
        </val-default-errors>
        <router-outlet></router-outlet>
        <ngx-guided-tour nextText="Continua" doneText="Fatto" closeText="Chiudi" skipText="Chiudi" backText="Indietro"></ngx-guided-tour>
    </div>

    <div class="content-wrapper p-3" style="margin-left: 0px; background: #f4f6f9;" *showDashboard>
        <router-outlet></router-outlet>
    </div>

    <app-toasts-container></app-toasts-container>
    <data-builder-modal></data-builder-modal>
    <app-footer *hideDashboard></app-footer>
    <!--  <aside class="control-sidebar control-sidebar-dark"></aside>-->
    <!--  <div id="sidebar-overlay" (click)="toggleMenuSidebar()"></div>-->
</div>
