<div class="py-5">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)"
                 size="default"
                 color="#fff"
                 type="square-jelly-box"
                 name="loader-table-{{this.uniqueID}}"
                 [fullScreen]="false">
        <p style="color: white"> Caricamento... </p>
    </ngx-spinner>
</div>
