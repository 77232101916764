import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {InvoiceDocumentService} from '@services/invoice-document.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {
    BankTransaction,
    BankTransactionFile,
    BankTransactionPagination,
    CreditCardFile,
    CreditCardPayment, CreditCardPaymentPagination
} from '@models/invoice/bank-transaction';
import {MetaPagination} from '@models/meta-pagination';
import * as moment from 'moment';
import {Moment} from 'moment';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Document, DocumentPagination, DocumentPayment, DocumentPaymentPagination} from '@models/invoice/document';
import {SubjectPagination, Subject} from '@models/invoice/subject';
import {BankAccount, BankAccountPagination} from '@models/invoice/bank-account';
import {Meta} from '@angular/platform-browser';
import {Dealer} from '@models/dealer';
import {TinyMCE} from '@models/tinyMCE';

@Component({
    selector: 'app-bank-transaction',
    templateUrl: './bank-transaction.component.html',
    styleUrls: ['./bank-transaction.component.css']
})
export class BankTransactionComponent implements OnInit {
    fileBusy: any;
    creditCardBusy: any;
    documentBusy: any;
    transactions: BankTransaction[] = [];
    transactionsPagination: MetaPagination;
    subjects: Subject[] = [];
    subjectLoading = false;
    documentLoading = false;
    currency = InvoiceDocumentService.currency;
    localFormat = 'DD/MM/YYYY';
    filter = {
        execution_date: '',
        code: '',
        is_credit: '',
        bank_description: '',
        is_matched: null,
        bank_account_id: '',
        is_handled: null,
        is_refund: null
    };
    executionDateRange = {
        from: '',
        to: ''
    };
    categories = InvoiceDocumentService.categories;
    @ViewChild('fileFormTemplate') private fileFormTemplate;
    @ViewChild('documentFormTemplate') private documentFormTemplate;
    @ViewChild('transactionFormTemplate') private transactionFormTemplate;
    @ViewChild('paymentDetailTemplate') private paymentDetailTemplate;
    @ViewChild('creditCardFormTemplate') private creditCardFormTemplate;
    transactionForm = new FormGroup({
        bank_account_id: new FormControl('', Validators.required),
        file: new FormControl('', Validators.required)
    });
    creditCardForm = new FormGroup({
        file: new FormControl('', Validators.required)
    });
    documentForm = new FormGroup({
        document_id: new FormControl(''),
        document_ids: new FormControl([]),
    });
    submittedFile: File;
    selectedTransaction: BankTransaction;
    unmatchedDocuments: Document[] = [];
    selectedSubjectId = null;
    bankAccountLoading = false;
    bankAccounts: BankAccount[] = [];
    filterBankAccounts: BankAccount[] = [];
    isLoading = false;
    selectedPaymentIds = [];
    chosenPayments: DocumentPayment[] = [];
    paymentList: DocumentPayment[] = [];
    documentModal: NgbModalRef;
    paymentModal: NgbModalRef;
    unmatchedPayments: DocumentPayment[] = [];
    unmatchedPaymentsPagination: MetaPagination;
    chosenDocument: Document;
    selectedAmount = 0;
    selectedDocumentId = null;
    creditCards: CreditCardPayment[] = [];
    creditCardsPagination: MetaPagination;
    selectAll: boolean;
    transactionNote = null;
    transactionRefund = false;
    defaultConfig = new TinyMCE();

    constructor(private modalService: NgbModal,
                private invoiceDocumentService: InvoiceDocumentService,
                private spinner: NgxSpinnerService,
                private dateRangePickerOptions: DaterangepickerConfig) {
    }

    ngOnInit(): void {
        this.loadData();
    }

    loadData() {
        this.loadBankTransactions();
        setTimeout(() => {
            this.loadCreditCards();
            this.loadFilterBankAccount();
        });
    }

    loadBankTransactions() {
        this.spinner.show('table-transaction');
        const params = {};
        if (this.transactionsPagination?.currentPage) {
            params['page'] = this.transactionsPagination?.currentPage;
        }
        if (this.executionDateRange.from) {
            params['from'] = this.executionDateRange.from;
        }
        if (this.executionDateRange.to) {
            params['to'] = this.executionDateRange.to;
        }
        if (this.filter.code) {
            params['code'] = this.filter.code;
        }
        if (this.filter.is_credit) {
            params['is_credit'] = this.filter.is_credit;
        }
        if (this.filter.bank_description) {
            params['description'] = this.filter.bank_description;
        }
        if (this.filter.bank_account_id) {
            params['bank_account_id'] = this.filter.bank_account_id;
        }
        if (this.filter.is_refund) {
            params['is_refund'] = this.filter.is_refund;
        }
        if (this.filter.is_handled) {
            params['is_handled'] = this.filter.is_handled;
        }
        if (this.filter.is_matched) {
            params['is_matched'] = this.filter.is_matched;
        }
        params['expand'] = 'company_name,order_id,suggested_subject,suggested_document,suggested_payments';
        this.invoiceDocumentService.getBankTransactions(params).subscribe((response: BankTransactionPagination) => {
            this.transactions = response.data;
            this.transactionsPagination = response._meta;
            this.spinner.hide('table-transaction');
        }, err => {
            this.spinner.hide('table-transaction');
        });
    }

    loadCreditCards() {
        this.spinner.show('table-credit-cards');
        const params = {};
        if (this.creditCardsPagination?.currentPage) {
            params['page'] = this.creditCardsPagination?.currentPage;
        }
        this.invoiceDocumentService.getCreditCards(params).subscribe((response: CreditCardPaymentPagination) => {
            this.creditCards = response.data;
            this.creditCardsPagination = response._meta;
            this.spinner.hide('table-credit-cards');
        }, err => {
            this.spinner.hide('table-credit-cards');
        });
    }

    resetFilter() {
        this.filter = {
            execution_date: '',
            code: '',
            is_credit: '',
            bank_description: '',
            is_matched: null,
            bank_account_id: null,
            is_handled: null,
            is_refund: null
        };
    }

    setSelectedDateRange(start: Moment, end: Moment) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        this.executionDateRange.from = start.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.executionDateRange.to = end.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.filter.execution_date = start.format(localDateFormat) +
            this.dateRangePickerOptions.settings.locale.separator + end.format(localDateFormat);
    }

    showModal() {
        this.loadBankAccounts();
        this.modalService.open(this.fileFormTemplate, {size: 'md'});
    }

    showCreditCardModal() {
        this.modalService.open(this.creditCardFormTemplate, {size: 'md'});
    }

    showTransactionModal(transaction: BankTransaction) {
        this.paymentList = [];
        this.selectedTransaction = transaction;
        if (transaction?.payments) {
            transaction.payments.forEach(item => {
                this.paymentList.push(item);
                this.selectedPaymentIds.push(item.id);
            });
        }
        this.transactionRefund = this.selectedTransaction?.is_refund === 1;
        this.transactionNote = this.selectedTransaction?.note;
        this.modalService.open(this.transactionFormTemplate, {size: 'lg'});
    }

    showDocumentModal() {
        this.refreshPaymentFormTable();
        this.refreshDocumentSelect();
        this.refreshSubjectSelect();
        this.loadSuggestedDocument();
        this.documentModal = this.modalService.open(this.documentFormTemplate, {size: 'lg'});
    }

    loadSuggestedDocument() {
        if (this.selectedTransaction?.suggested_payments?.length > 0) {
            this.unmatchedPayments = this.selectedTransaction?.suggested_payments;
            this.selectedAmount = 0;
            this.unmatchedPayments.forEach((payment) => {
                payment.is_selected = false;
            });
            const pagination = new MetaPagination();
            pagination.currentPage = 1;
            pagination.totalCount = this.selectedTransaction?.suggested_payments?.length;
            pagination.pageCount = 1;
            pagination.perPage = this.selectedTransaction?.suggested_payments?.length;
            this.unmatchedPaymentsPagination = pagination;
        } else if (this.selectedTransaction?.suggested_document) {
            this.loadSubject(this.selectedTransaction?.suggested_document?.company_name, true);
            this.unmatchedDocuments.push(this.selectedTransaction?.suggested_document);
            this.unmatchedDocuments.slice(0);
            this.selectedDocumentId = this.selectedTransaction?.suggested_document?.id;
            this.loadPayments();
        } else if (this.selectedTransaction?.suggested_subject) {
            this.subjects.push(this.selectedTransaction?.suggested_subject);
            this.subjects.slice(0);
            this.selectedSubjectId = this.selectedTransaction?.suggested_subject?.id;
            this.loadUnmatchedDocuments(null, true);
        } else {
            this.loadSubject();
        }
    }

    handleFileInput(file: File) {
        this.submittedFile = file;
        this.transactionForm.get('file').setValue(file.name);
    }

    handleCreditCardFileInput(file: File) {
        this.submittedFile = file;
        this.creditCardForm.get('file').setValue(file.name);
    }

    handleCreditCardFileRemoveModal() {
        this.creditCardForm.get('file').setValue('');
        this.submittedFile = null;
    }

    handleFileRemoveModal() {
        this.transactionForm.get('file').setValue('');
        this.submittedFile = null;
    }

    get file() {
        return this.transactionForm.get('file').value;
    }

    get creditCardFile() {
        return this.creditCardForm.get('file').value;
    }

    dismissModal() {
        this.handleFileRemoveModal();
        this.documentForm.get('document_id').setValue('');
        this.transactionForm.get('file').setValue('');
        this.transactionForm.get('bank_account_id').setValue('');
        this.paymentList = [];
        this.selectedPaymentIds = [];
        this.chosenPayments = [];
        this.modalService.dismissAll();
    }

    dismissCreditCardModal() {
        this.creditCardForm.get('file').setValue('');
        this.modalService.dismissAll();
    }

    dismissDocumentModal() {
        this.refreshPaymentFormTable();
        this.refreshDocumentSelect();
        this.refreshSubjectSelect();
        this.documentModal.dismiss();
    }

    saveTransactionFile() {
        const transaction = new BankTransactionFile();
        transaction.file = this.submittedFile;
        transaction.bank_account_id = this.transactionForm.value.bank_account_id;
        this.fileBusy = this.invoiceDocumentService.postBankTransactionFromFile(transaction).subscribe(() => {
            this.invoiceDocumentService.successAlert('Uploaded File!');
            this.dismissModal();
            this.loadBankTransactions();
        }, err => {
            this.invoiceDocumentService.errorAlert(err);
            this.dismissModal();
        });
    }

    saveCreditCardFile() {
        const creditCard = new CreditCardFile();
        creditCard.file = this.submittedFile;
        this.creditCardBusy = this.invoiceDocumentService.postCreditCardPaymentsFromFile(creditCard).subscribe(() => {
            this.invoiceDocumentService.successAlert('Uploaded File!');
            this.dismissCreditCardModal();
            this.loadData();
        }, err => {
            this.invoiceDocumentService.errorAlert(err);
            this.dismissModal();
        });
    }

    updateBankTransaction() {
        const body = {
            payment_ids: this.selectedPaymentIds,
            note: this.transactionNote,
            is_refund: this.transactionRefund ? 1 : 0
        };
        this.documentBusy = this.invoiceDocumentService.putBankTransaction(this.selectedTransaction.id, body).subscribe((response) => {
            this.loadBankTransactions();
            this.invoiceDocumentService.successAlert('Added Payment!');
            this.dismissModal();
        }, err => {
            this.invoiceDocumentService.errorAlert(err);
            this.dismissModal();
        });
    }

    changeHandledTransaction(transaction: BankTransaction) {
        const body = {
            handled: transaction?.is_handled ? 1 : 0
        };
        this.invoiceDocumentService.putBankTransaction(transaction.id, body).subscribe((response) => {
            this.loadBankTransactions();
            this.invoiceDocumentService.successAlert('Changed!');
        }, err => {
            this.invoiceDocumentService.errorAlert(err);
        });
    }

    loadPayments() {
        this.refreshPaymentFormTable();
        this.chosenDocument = this.selectedDocumentId ?
            this.unmatchedDocuments.find(data => data.id === this.selectedDocumentId) : null;
        if (this.selectedDocumentId) {
            this.spinner.show('table-payments');
            const params = {};
            if (this.unmatchedPaymentsPagination?.currentPage) {
                params['page'] = this.unmatchedPaymentsPagination?.currentPage;
            }
            if (this.selectedDocumentId) {
                params['document_id'] = this.selectedDocumentId;
            }
            params['is_not_matched'] = 1;
            this.invoiceDocumentService.getDocumentPayments(params).subscribe((response: DocumentPaymentPagination) => {
                this.unmatchedPayments = response.data;
                this.unmatchedPaymentsPagination = response._meta;
                this.spinner.hide('table-payments');
            }, err => {
                this.spinner.hide('table-payments');
            });
        }
    }

    loadSubject(term?: string, selected = false) {
        this.refreshPaymentFormTable();
        this.refreshDocumentSelect();
        this.subjectLoading = true;
        const params = {};
        if (term) {
            params['company_name'] = term;
        }
        this.invoiceDocumentService.getSubject(params).subscribe((response: SubjectPagination) => {
            this.subjects = response.data;
            if (response.data?.length === 1 && selected) {
                this.selectedSubjectId = response.data[0]?.id;
            }
            this.subjectLoading = false;
        }, err => {
            this.subjectLoading = false;
        });
    }

    loadUnmatchedDocuments(term?, selected = false) {
        this.refreshPaymentFormTable();
        this.refreshDocumentSelect();
        if (this.selectedSubjectId) {
            this.documentLoading = true;
            const params = {};
            params['subject_id'] = this.selectedSubjectId;
            if (term) {
                params['number'] = term;
            }
            params['matched_document'] = 0;
            this.invoiceDocumentService.getDocuments(params).subscribe((response: DocumentPagination) => {
                this.unmatchedDocuments = response.data;
                this.documentLoading = false;
                if (response.data?.length === 1 && selected) {
                    this.selectedDocumentId = response.data[0]?.id;
                    this.chosenDocument = response.data[0];
                }
            }, err => {
                this.unmatchedPayments = [];
                this.documentLoading = false;
            });
        }
    }

    loadBankAccounts(term?) {
        this.bankAccountLoading = true;
        const params = {};
        if (term) {
            params['bank_name'] = term;
        }
        this.invoiceDocumentService.getBankAccounts(params).subscribe((response: BankAccountPagination) => {
            this.bankAccounts = response.data;
            this.bankAccountLoading = false;
        }, err => {
            this.bankAccountLoading = false;
        });
    }

    loadFilterBankAccount(term?) {
        this.isLoading = true;
        const params = {};
        if (term) {
            params['bank_name'] = term;
        }
        this.invoiceDocumentService.getBankAccounts(params).subscribe((response: BankAccountPagination) => {
            this.filterBankAccounts = response.data;
            this.isLoading = false;
        }, err => {
            this.isLoading = false;
        });
    }

    selectPayment(event, payment: DocumentPayment) {
        if (event.currentTarget.checked) {
            payment.is_selected = true;
            this.chosenPayments.push(payment);
            this.selectedAmount += Number(payment?.amount);
        } else {
            this.selectAll = false;
            const index = this.chosenPayments.findIndex(data => data.id === payment.id);
            if (index > -1) {
                payment.is_selected = false;
                this.selectedAmount -= Number(payment?.amount);
                this.chosenPayments.splice(index, 1);
            }
        }
    }

    selectAllPayments(event) {
        if (event.currentTarget.checked) {
            this.unmatchedPayments.forEach((payment) => {
                payment.is_selected = true;
                this.chosenPayments.push(payment);
                this.selectedAmount += Number(payment?.amount);
            });
        } else {
            this.unmatchedPayments.forEach((payment) => {
                payment.is_selected = false;
            });
            this.selectedAmount = 0;
            this.chosenPayments = [];
        }
    }

    saveChosenPayments() {
        this.chosenPayments.forEach(item => {
            this.paymentList.push(item);
            this.selectedPaymentIds.push(item.id);
        });
        this.dismissDocumentModal();
    }

    removePayment(id: number) {
        const index = this.paymentList.findIndex(item => item.id === id);
        if (index >= 0) {
            this.paymentList.splice(index, 1);
        }
        const indexId = this.selectedPaymentIds.findIndex(item => item === id);
        if (indexId >= 0) {
            this.selectedPaymentIds.splice(indexId, 1);
        }
    }

    findMethod(dueMethod) {
        const method = InvoiceDocumentService.dueMethods.find(item => item.key === dueMethod);
        return method ? method.name : dueMethod;
    }

    refreshPaymentFormTable() {
        this.transactionNote = null;
        this.transactionRefund = null;
        this.unmatchedPayments = [];
        this.unmatchedPaymentsPagination = null;
        this.selectAll = null;
        this.selectedAmount = 0;
        this.chosenPayments = [];
    }

    refreshDocumentSelect() {
        this.unmatchedDocuments = [];
        this.selectedDocumentId = null;
        this.chosenDocument = null;
    }

    refreshSubjectSelect() {
        this.subjects = [];
        this.selectedSubjectId = null;
    }
}
