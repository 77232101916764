import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';

export class Document extends BaseModel {
    id: number;
    subject_id: number;
    category: string;
    type: string;
    amount: string;
    vat_amount: string;
    withholding_tax: string;
    number: string;
    date: string;
    vat_date?: string;
    note: string;
    welded: any;
    draft: any;
    pdf_file?: string;
    xml_file?: string;
    company_name: string;
    is_credit: any;
    transaction_id?: any;
    document_pdf: File;
    document_xml: File;
    total_amount: string;
    is_selected?: boolean;
    payments: DocumentPayment[];
    net_amount?: string;
    vat_type: string;
    total_to_pay: string;

    fromArray(result: []) {
        const array: Document[] = [];
        for (const item of result) {
            array.push(new Document(item));
        }
        return array;
    }

    get postPayloadDocument() {
        const payload = new FormData();
        this.addToPayload(payload, this.subject_id, 'subject_id');
        this.addToPayload(payload, this.category, 'category');
        this.addToPayload(payload, this.type, 'type');
        this.addToPayload(payload, this.date, 'date');
        this.addToPayload(payload, this.vat_date, 'vat_date');
        this.addToPayload(payload, this.net_amount, 'net_amount');
        this.addToPayload(payload, this.vat_amount, 'vat_amount');
        this.addToPayload(payload, this.withholding_tax, 'withholding_tax');
        this.addToPayload(payload, this.number, 'number');
        this.addToPayload(payload, this.note, 'note');
        this.addToPayload(payload, this.welded, 'welded');
        this.addToPayload(payload, this.draft, 'draft');
        this.addToPayload(payload, this.is_credit, 'is_credit');
        this.addToPayload(payload, this.document_pdf, 'document_pdf');
        this.addToPayload(payload, this.document_xml, 'document_xml');
        this.addToPayload(payload, this.payments, 'document_payments');
        this.addToPayload(payload, this.vat_type, 'vat_type');
        return payload;
    }
}

export class DocumentPagination extends BaseModel {
    data: Document[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class DocumentDashboard extends BaseModel {
    cost_percentage: number;
    net_percentage: number;
    invoice_percentage: number;
    total_invoice: string;
    total_cost: string;
    total_net: string;
    credit_total: string;
    debit_total: string;
    profitability_index: any;
    not_welded_invoice: string;
    not_welded_cost: string;
}

export class DocumentChart extends BaseModel {
    dates: Array<any>;
    costs: Array<any>;
    net: Array<any>;
    incomes: Array<any>;
    outcomes: Array<any>;
    invoices: Array<any>;
    costsByCategory: Array<any>;
    invoicesByCategory: Array<any>;
}

export class DocumentIncomes extends BaseModel {
    date: string;
    cost: any;
    net: any;
    withholding_tax: any;
    vat_credit: any;
    vat_debit: any;
    gross_turnover: any;
    credit_received: any;
    credit_issued: any;
}

export class DocumentOutcomes extends BaseModel {
    date: string;
    incomes: any;
    outcomes: any;
    credit_received: any;
    gross_turnover: any;
    vat_credit: any;
    vat_debit: any;
    withholding_tax: any;
}

export class DocumentMap {
    total_cost: string;
    total_net: string;
    percentage: number;
    lat: number;
    lng: number;
}

export class DocumentPayment {
    id: number;
    document_id: number;
    transaction_id: number;
    due_date: string;
    due_method: string;
    amount: string;
    welded: any;
    is_selected?: boolean;
    document_number: string;
    document_date: string;
    document?: Document;
    note: string;
    type?: string;
}

export class DocumentPaymentPagination extends BaseModel {
    data: DocumentPayment[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class DocumentCategory extends BaseModel {
    name: string;
}
