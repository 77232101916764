<form [formGroup]="form" [ngBusy]="busy">
    <div class="row">
        <div class="col-12">
            <h1 class="display-7"><i class="fa fa-database"></i> Cerca un cliente già anagrafato</h1>
            <hr>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label class="lead">Anagrafica</label>
                <ng-select [items]="customers"
                           bindLabel="fullName"
                           bindValue="id"
                           formControlName="id">
                </ng-select>
            </div>
        </div>
        <div class="col-12" *ngIf="selectedCustomer && selectedCustomer.isin_copertura">
            <div class="callout callout-success">
                <h5>Copertura Fibra 1 Giga con Tiscali <img src="/assets/img/brands/tiscali.png" height="30px" class="float-right"/></h5>
                <p>Il cliente {{selectedCustomer.fullName}} è coperto dalla fibra Tiscali a 1 Giga.</p>
                <p>Non perdere l'occasione di guadagnare un'ulteriore provvigione di <b>{{ 120 | currency: '€ '}}</b>.</p>
                <p>Proponigli l'attivazione della linea a <b>21,95€</b> al mese!</p>
            </div>
        </div>
        <div class="col-12">
            <h1 class="display-7"><i class="fa fa-id-card"></i> Scansione Documenti</h1>
            <hr>
        </div>
        <app-ocr-modal #ocrModal (done)="setCustomerData($event)"></app-ocr-modal>
        <div class="col-lg-6 col-12">
            <div class="form-group" *ngIf="!this.f().id.value">
                <label class="lead">Scansione Fronte</label>
                <input type="file"
                       (change)="setFiles($event, 'scansione_fronte');"/>
            </div>
            <div *ngIf="this.f().id.value" class="embed-responsive embed-responsive-1by1">
                <iframe [src]="this.f().documento_path.value | safe" class="embed-responsive-item"></iframe>
            </div>
        </div>
        <div class="col-lg-6 col-12">
            <div class="form-group" *ngIf="!this.f().id.value">
                <label class="lead">Scansione Retro</label>
                <input type="file"
                       (change)="setFiles($event, 'scansione_retro');"/>
            </div>
            <div *ngIf="this.f().id.value" class="embed-responsive embed-responsive-1by1">
                <iframe [src]="this.f().altro_documento_path.value | safe" class="embed-responsive-item"></iframe>
            </div>
        </div>
    </div>
    <div *ngIf="isBusinessCustomer" class="row">
        <div class="col-12">
            <h1 class="display-7"><i class="fas fa-user-tie"></i> Dati Azienda</h1>
            <hr>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Ragione sociale</label>
                <input type="text" formControlName="ragione_sociale" class="form-control"/>
                <val-errors controlName="ragione_sociale">
                    <ng-template valError="required">
                        La ragione sociale è obbligatoria
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Partita IVA</label>
                <input type="text" formControlName="partita_iva" class="form-control"/>
                <val-errors controlName="partita_iva">
                    <ng-template valError="required">
                        La patita IVA è obbligatoria
                    </ng-template>
                </val-errors>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="isBusinessCustomer">
        <div class="col-12">
            <h1 class="display-7"><i class="fa fa-map-marked-alt"></i> Sede Legale</h1>
            <hr>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Provincia della sede</label>
                <ng-select [items]="this.province"
                           bindLabel="provincia"
                           bindValue="id"
                           formControlName="sede_provincia">
                </ng-select>
                <val-errors controlName="sede_provincia">
                    <ng-template valError="required">
                        La provincia della sede è obbligatoria
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Comune della sede</label>
                <ng-select [items]="this.comuniSede"
                           bindLabel="comune"
                           bindValue="id"
                           formControlName="sede_localita"
                >
                </ng-select>
                <val-errors controlName="sede_localita">
                    <ng-template valError="required">
                        Il comune della sede è obbligatoria
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">CAP della sede</label>
                <input formControlName="sede_cap" type="text" minlength="5" maxlength="5"
                       class="form-control"/>
                <val-errors controlName="sede_cap">
                    <ng-template valError="required">
                        Il CAP della sede è obbligatorio
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Indirizzo della sede</label>
                <input formControlName="sede_indirizzo" type="text" class="form-control"/>
                <val-errors controlName="sede_indirizzo">
                    <ng-template valError="required">
                        L'indirizzo della sede è obbligatorio
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Civico della sede</label>
                <input formControlName="sede_civico" type="text" class="form-control"/>
                <val-errors controlName="sede_civico">
                    <ng-template valError="required">
                        Il civico della sede è obbligatorio
                    </ng-template>
                </val-errors>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h1 class="display-7"><i class="fa fa-user-edit"></i> Dati Anagrafici</h1>
            <hr>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Nome</label>
                <input type="text" formControlName="nome" class="form-control"/>
                <val-errors controlName="nome">
                    <ng-template valError="required">
                        Il nome è obbligatorio
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Cognome</label>
                <input type="text" class="form-control" formControlName="cognome"/>
                <val-errors controlName="cognome">
                    <ng-template valError="required">
                        Il cognome è obbligatorio
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Codice Fiscale</label>
                <input type="text" class="form-control" formControlName="codfiscale"/>
                <val-errors controlName="codfiscale">
                    <ng-template valError="required">
                        Il codice fiscale è obbligatorio
                    </ng-template>
                    <ng-template valError="pattern">
                        Il codice fiscale non è del formato corretto
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Sesso</label>
                <ng-select [items]="this.selectOptions.sesso"
                           bindLabel="nome"
                           bindValue="id"
                           formControlName="sesso">
                </ng-select>
                <val-errors controlName="sesso">
                    <ng-template valError="required">
                        Il sesso è obbligatorio
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Stato di nascita</label>
                <ng-select [items]="this.stati"
                           bindLabel="nome"
                           bindValue="id"
                           formControlName="nascita_nazione">
                </ng-select>
                <val-errors controlName="nascita_nazione">
                    <ng-template valError="required">
                        Lo stato di nascita è obbligatorio
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Provincia di nascita</label>
                <ng-select [items]="this.province"
                           bindLabel="provincia"
                           bindValue="id"
                           formControlName="nascita_provincia">
                </ng-select>
                <val-errors controlName="nascita_provincia">
                    <ng-template valError="required">
                        La provincia di nascita è obbligatorio
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Comune di nascita</label>
                <ng-select [items]="this.comuniNascita"
                           bindLabel="comune"
                           bindValue="id"
                           formControlName="nascita_localita"
                >
                </ng-select>
                <val-errors controlName="nascita_localita">
                    <ng-template valError="required">
                        Il comune di nascita è obbligatorio
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Data di nascita</label>
                <input class="form-control" placeholder="gg-mm-aaaa"
                       formControlName="nascita_data" ngbDatepicker #data_nascita="ngbDatepicker"
                       (click)="data_nascita.toggle()" [minDate]="{year: 1920, month: 1, day: 1}">
                <val-errors controlName="nascita_data">
                    <ng-template valError="required">
                        La data di nascita è obbligatoria
                    </ng-template>
                    <ng-template valError="pattern">
                        La data dev'essere del formato gg/mm/aaaa
                    </ng-template>
                    <ng-template valError="adult">
                        E' necessario essere maggiorenni
                    </ng-template>
                </val-errors>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <h1 class="display-7"><i class="fa fa-map-marked-alt"></i> Indirizzo di Residenza</h1>
            <hr>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Provincia di residenza</label>
                <ng-select [items]="this.province"
                           bindLabel="provincia"
                           bindValue="id"
                           formControlName="residenza_provincia">
                </ng-select>
                <val-errors controlName="residenza_provincia">
                    <ng-template valError="required">
                        La provincia di residenza è obbligatoria
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Comune di residenza</label>
                <ng-select [items]="this.comuniResidenza"
                           bindLabel="comune"
                           bindValue="id"
                           formControlName="residenza_localita"
                           [disabled]="!comuniResidenza || comuniResidenza.length === 0"
                >
                </ng-select>
                <val-errors controlName="residenza_localita">
                    <ng-template valError="required">
                        Il comune di residenza è obbligatoria
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">CAP di residenza</label>
                <input formControlName="residenza_cap" type="text" minlength="5" maxlength="5"
                       class="form-control"/>
                <val-errors controlName="residenza_cap">
                    <ng-template valError="required">
                        Il CAP di residenza è obbligatoria
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Indirizzo di residenza</label>
                <input formControlName="residenza_indirizzo" type="text" class="form-control"/>
                <val-errors controlName="residenza_indirizzo">
                    <ng-template valError="required">
                        L'indirizzo di residenza è obbligatoria
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Civico di residenza</label>
                <input formControlName="residenza_civico" type="text" class="form-control"/>
                <val-errors controlName="residenza_civico">
                    <ng-template valError="required">
                        Il civico di residenza è obbligatorio
                    </ng-template>
                </val-errors>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <legend><i class="fa fa-id-card"></i> Documento d'identità</legend>
            <hr>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Documento di idenitità</label>
                <ng-select [items]="this.selectOptions.doc_identita"
                           bindLabel="nome"
                           bindValue="id"
                           formControlName="documento_tipo">
                </ng-select>
                <val-errors controlName="documento_tipo">
                    <ng-template valError="required">
                        Il documento d'identità è obbligatorio
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Numero Documento</label>
                <input formControlName="documento_numero" type="text" class="form-control"/>
                <val-errors controlName="documento_numero">
                    <ng-template valError="required">
                        Il numero del documento è obbligatorio
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Emissione del Documento</label>
                <input class="form-control" placeholder="gg-mm-aaaa"
                       formControlName="documento_rilascio" ngbDatepicker #emissione_documento="ngbDatepicker"
                       (click)="emissione_documento.toggle()">
                <val-errors controlName="documento_rilascio">
                    <ng-template valError="required">
                        La data di emissione è obbligatoria
                    </ng-template>
                    <ng-template valError="pattern">
                        La data dev'essere del formato gg/mm/aaaa
                    </ng-template>
                    <ng-template valError="period">
                        La data inserita deve essere precedente al giorno corrente
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Scadenza del Documento</label>
                <input class="form-control" placeholder="gg-mm-aaaa"
                       formControlName="documento_scadenza" ngbDatepicker #scadenza_documento="ngbDatepicker"
                       (click)="scadenza_documento.toggle()">
                <val-errors controlName="documento_scadenza">
                    <ng-template valError="required">
                        La data di emissione è obbligatoria
                    </ng-template>
                    <ng-template valError="pattern">
                        La data dev'essere del formato gg/mm/aaaa
                    </ng-template>
                    <ng-template valError="period">
                        La data inserita deve essere posteriore al giorno corrente
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">Cellulare</label>
                <input formControlName="telefono" type="text" class="form-control"/>
                <val-errors controlName="telefono">
                    <ng-template valError="required">
                        Il cellulare è obbligatorio
                    </ng-template>
                </val-errors>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="form-group">
                <label class="lead">E-Mail</label>
                <input formControlName="email" type="text" class="form-control"/>
                <val-errors controlName="email">
                    <ng-template valError="required">
                        L'email è obbligatoria
                    </ng-template>
                    <ng-template valError="email">
                        Non è un e-mail valida
                    </ng-template>
                </val-errors>
            </div>
        </div>
    </div>
</form>
