import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Dealer} from '@app/models/dealer';
import {AgenteService} from '@app/services/agente.service';
import {PresidiService} from '@services/presidi.service';
import {Presidi} from '@models/presidi';

@Component({
    selector: 'app-stand-select',
    templateUrl: './stand-select.component.html',
    styleUrls: ['./stand-select.component.scss']
})
export class StandSelectComponent implements OnInit {
    @Input()
    small = false;
    @Input()
    disabled = false;

    @Input()
    presidio: any;

    @Input()
    vendors: [];

    @Input() master = false;

    data: Presidi[] = [];

    @Output() onChange = new EventEmitter();

    loading = false;

    constructor(
        private service: PresidiService
    ) {
    }

    ngOnInit(): void {
        let params = {fields: 'id,nome', 'per-page': 0};

        if (this.vendors && this.vendors.length > 0) {
            params['vendors'] = this.vendors.join(',');
        }

        this.loading = true;
        this.service.list(params).subscribe((data) => {
            this.data = new Presidi().fromArray(data.rows);
            this.loading = false;
        });
    }

}
