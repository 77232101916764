import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {RequestService} from '@services/request.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AgenteService {

  ENDPOINT = 'agentes';

  constructor(public request: RequestService) {
  }

  list(params = {}) {
    return this.request
      .get(`${this.ENDPOINT}/list`, params, { observe: "response" })
      .pipe(
        map((data: HttpResponse<any>) => {
          return {
            rows: data.body,
            pagination: {
              totalCount: parseInt(data.headers.get("X-Pagination-Total-Count")),
              page: parseInt(data.headers.get("X-Pagination-Current-Page")),
              pageSize: parseInt(data.headers.get("X-Pagination-Per-Page")),
              pages: parseInt(data.headers.get("X-Pagination-Page-Count")),
            },
          };
        })
      );
  }

  getDealer(id, params = {}) {
    return this.request.get(`${this.ENDPOINT}/${id}`, params);
  }

  saveDealer(data) {
    return this.request.post(`${this.ENDPOINT}`, data);
  }

  updateDealer(id, data) {
    return this.request.post(`${this.ENDPOINT}/${id}`, data);
  }

  deleteDealer(id) {
    return this.request.delete(`${this.ENDPOINT}/${id}`);
  }

  restoreDealer(id) {
    return this.request.put(`${this.ENDPOINT}/restore/${id}`);
  }

  isUsernameAvailable(username) {
    return this.request.get(`${this.ENDPOINT}/username-available/${username}`);
  }
  
  isVatAvalaible(vat) {
    return this.request.get(`${this.ENDPOINT}/vat-available/${vat}`);
  }

  changePassword(id, password) {
    return this.request.post(`${this.ENDPOINT}/change-password/${id}`, { password: password });
  }

  deleteVisit(id) {
    return this.request.delete(`${this.ENDPOINT}/delete-visit/${id}`);
  }

  moveVisit(id, date) {
    return this.request.put(`${this.ENDPOINT}/move-visit/${id}/${date}`);
  }

  kenaRetailersToVisit() {
    return this.request.get(`${this.ENDPOINT}/kena-retailers-to-visit`);
  }


  listaAgenti(master) {
    return this.request.get(`${this.ENDPOINT}/get-lista-agenti?master=${master}`);
  }

  calendarioVisite() {
    return this.request.get(`${this.ENDPOINT}/calendario-visite-programmate`);
  }

  visiteProgrammate() {
    return this.request.get(`${this.ENDPOINT}/lista-visite-programmate`);
  }

  inserisciVisitaProgrammata(data) {
    return this.request.post(`${this.ENDPOINT}/inserisci-visita-programmata`, data);
  }

  confermaVisitaProgrammata(data) {
    return this.request.post(`${this.ENDPOINT}/attiva-visita-programmata`, data);
  }
}
