import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';

export class CampaignSegment extends BaseModel {
    id: number;
    title: string;
    slug: string;
    rules: Rule[];

    fromArray(result: []) {
        const array: CampaignSegment[] = [];
        for (const item of result) {
            array.push(new CampaignSegment(item));
        }
        return array;
    }
}

export class Rule extends BaseModel {
    id: number;
    campaign_id: number;
    vendor_segment_id: number;
    points: number;
    offer: string;
    count: number;
}


export class CampaignSegmentListPagination extends BaseModel {
    data: CampaignSegment[];
    _meta: MetaPagination;
    _links: Array<any>;
}
