<app-master-header [ngBusy]="busy"
                   cardClass="bg-gradient-light"
                   image="assets/img/flats/placeholder.png"
                   title="Nuove Affiliazioni"
                   [operatori]="true"
                   [periodoSingle]="true"
                   (onChange)="this.updateUrls($event.operatore, $event.periodo)"
>
</app-master-header>

<app-master-subheader cardClass="bg-gradient-1" [url]="urlSubheader"></app-master-subheader>

<div class="row" *show="['master', 'masteragent']">
    <div class="col-12 col-xl-8" >
        <app-master-card
                bodyClass="p-0"
                title="Nuove aperture"
                image="assets/img/flats/employee.png"
        >
            <app-autoload-table
                    [url]="urlAgentiTabella"
                    headerClass="bg-gradient-dark"
                    firstColumnClass="bg-gradient-gray"
            ></app-autoload-table>
        </app-master-card>
        <app-master-card
                bodyClass="p-0"
                title="Affiliazioni dei mesi precedenti ancora improduttive"
                image="assets/img/flats/team.png"
        >

            <app-autoload-table
                    [url]="urlImproduttiviTabella"
                    [small]="true"
                    [hideFooterDescription]="true"
            ></app-autoload-table>
        </app-master-card>
    </div>
    <div class="col-12 col-xl-4">
        <app-master-card
                title="Classifica per key account"
                image="assets/img/flats/pie-chart-2.png"
        >
            <app-autoload-horizontal-bar-chart [url]="urlAgenti" [gradient]="false"></app-autoload-horizontal-bar-chart>
        </app-master-card>
        <app-master-card
                title="Classifica per regione"
                image="assets/img/flats/pie-chart.png"
        >
            <app-autoload-horizontal-bar-chart [url]="urlRegioni" [gradient]="false"></app-autoload-horizontal-bar-chart>
        </app-master-card>
    </div>
</div>

<div class="row" *show="['agent']">
    <div class="col-xl-6 col-12" >
        <app-master-card
                bodyClass="p-0"
                title="Nuove aperture"
                image="assets/img/flats/employee.png"
        >
            <app-autoload-table
                    [url]="urlNegoziTabella"
                    headerClass="bg-gradient-dark"
                    firstColumnClass="bg-gradient-gray"
            ></app-autoload-table>
        </app-master-card>
    </div>
    <div class="col-12 col-xl-6">
        <app-master-card
                bodyClass="p-0"
                title="Affiliazioni dei mesi precedenti ancora improduttive"
                image="assets/img/flats/team.png"
        >

            <app-autoload-table
                    [url]="urlImproduttiviTabella"
                    [small]="true"
                    [hideFooterDescription]="true"
            ></app-autoload-table>
        </app-master-card>
    </div>
</div>
