import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '@services/authentication.service';
import {map} from 'rxjs/operators';
import {environment} from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(
        public http: HttpClient,
        public autenthicationService: AuthenticationService
    ) {
    }


    post(url, data) {
        return this.http
            .post(`${environment.dashboardUrl}${url}`, data)
            .pipe(map((response: any) => {
                if (response.hasOwnProperty('profile')) {
                    this.autenthicationService.updateAccountData(response.profile);
                }
                return response;
            }));
    }

    get(url, data = {}, old = false) {
        let baseUrl = environment.dashboardUrl;
        return this.http.get(`${baseUrl}${url}`, {
            params: data
        })
            .pipe(map((response: any) => {
                if (response.hasOwnProperty('profile')) {
                    this.autenthicationService.updateAccountData(response.profile);
                }
                return response;
            }));
    }
}
