<div class="row" [ngBusy]="busy">
    <div class="col-12 col-lg-9">
        <div class="card bg-gradient-light">
            <div class="card-body">
                <ngb-tabset #tabs class="nav-wizard">
                    <ngb-tab id="offerta">
                        <ng-template ngbTabTitle><i class="fa fa-briefcase"></i> Offerta</ng-template>
                        <ng-template ngbTabContent>
                            <app-contract-new-offerta
                                    [me]="me"
                                    [skeletons]="skeletons"
                                    [initialForm]="this.contractForm.offerta"
                                    (formChange)="syncOfferta($event)"
                                    (valid)="this.validSteps.offerta = $event;"
                                    [venditori]="venditori"
                                    [bypassRicariche]="bypassRicariche"
                            ></app-contract-new-offerta>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab id="pagamento" [disabled]="!this.validSteps.offerta">
                        <ng-template ngbTabTitle><i class="fa fa-wallet"></i> Pagamento</ng-template>
                        <ng-template ngbTabContent>
                            <app-contract-new-pagamento
                                    [me]="me"
                                    [skeletons]="pagamenti"
                                    [campi]="campiPagamento"
                                    [initialForm]="this.contractForm.pagamento"
                                    (formChange)="syncPagamento($event)"
                                    (valid)="this.validSteps.pagamento = $event;"
                            ></app-contract-new-pagamento>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab id="cliente" [disabled]="!this.validSteps.pagamento">
                        <ng-template ngbTabTitle><i class="fa fa-users-cog"></i> Cliente</ng-template>
                        <ng-template ngbTabContent>
                            <app-customer-form
                                    [customers]="customers"
                                    [isBusinessCustomer]="business"
                                    (isValid)="this.validSteps.cliente = $event;"
                                    (customerData)="syncCliente($event)"
                            ></app-customer-form>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>
            <ng-container [ngSwitch]="this.tabs.activeId">
                <div class="card-footer" *ngSwitchCase="'offerta'">
                    <button class="btn btn-info float-right" [disabled]="!this.validSteps.offerta"
                            (click)="this.tabs.select('pagamento')" type="button">
                        Procedi con l'attivazione <i class="fa fa-arrow-alt-circle-right"></i>
                    </button>
                </div>
                <div class="card-footer" *ngSwitchCase="'pagamento'">
                    <button class="btn btn-dark" type="submit"
                            (click)="this.tabs.select('offerta')"><i class="fa fa-arrow-alt-circle-left"></i> Torna
                        indietro
                    </button>

                    <button class="btn btn-info float-right" [disabled]="!this.validSteps.pagamento" type="button"
                            (click)="this.tabs.select('cliente')">
                        Procedi con l'attivazione <i class="fa fa-arrow-alt-circle-right"></i>
                    </button>
                </div>
                <div class="card-footer" *ngSwitchCase="'cliente'">
                    <button class="btn btn-dark" type="submit"
                            (click)="this.tabs.select('pagamento')"><i class="fa fa-arrow-alt-circle-left"></i> Torna
                        indietro
                    </button>

                    <button class="btn btn-info float-right" [disabled]="!this.validSteps.cliente" type="button"
                            (click)="save()">
                        Attiva <i class="fa fa-check-circle-o"></i>
                    </button>
                </div>
            </ng-container>

        </div>
    </div>

    <div class="col-12 col-lg-3" *ngIf="getOfferta()">
        <div class="info-box bg-gradient-success elevation-1">
            <span class="info-box-icon"><i class="fa fa-euro-sign"></i></span>

            <div class="info-box-content">
                <span class="info-box-text lead">La tua provvigione</span>

                <span class="info-box-number lead text-lg"><b>-</b></span>

                <div class="progress">
                    <div class="progress-bar" style="width: 0%"></div>
                </div>
                <span class="progress-description lead text-md">
                    <b>Sei in ultima fascia. Guadagni il massimo del gettone!</b>
                </span>
            </div>
            <!-- /.info-box-content -->
        </div>

        <div class="card bg-gradient-light" *ngIf="costiOfferta.length > 0">
            <div class="card-header bg-gradient-info">
                <h1 class="lead mb-0"><i class="fa fa-shopping-cart mr-2"></i> Costi per il cliente</h1>
            </div>
            <div class="card-body p-0" >
                <table class="table">
                    <tr>
                        <th>Descrizione</th>
                        <th class="text-right">Costo</th>
                    </tr>
                    <tr *ngFor="let costo of costiOfferta">
                        <td>{{costo.titolo}}</td>
                        <td class="text-right">{{ costo.costo | currency: '€ ' }}</td>
                    </tr>
                    <tr>
                        <th>Totale Costi Mensili</th>
                        <th class="text-right">{{ costiOfferta | where: ['tipo_costo', 2] | pluck: 'costo' | sum | currency: '€ '}}</th>
                    </tr>
                    <tr>
                        <th>Totale Costi Una Tantum</th>
                        <th class="text-right">{{ costiOfferta | where: ['tipo_costo', 1] | pluck: 'costo' | sum | currency: '€ '}}</th>
                    </tr>
                </table>
            </div>
        </div>

        <div class="card bg-gradient-light">
            <div class="card-header bg-gradient-info">
                <h1 class="lead mb-0"><i class="fa fa-list-alt mr-2"></i> Informazioni</h1>
            </div>
            <div class="card-body p-0 info-offerta" *ngIf="infoOfferta" [innerHTML]="infoOfferta.testo">
            </div>
        </div>
    </div>
</div>

