<div class="card bg-gradient-1" *hideDashboard>
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                 width="160px"
                 src="assets/img/flats/deliverytruck.png">
            <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>Shop Orders</h1>
                <hr>
                <div class="row align-items-end">
                    <div class="col-lg-4 col-12"><label for="validityPeriod" translate>Select date</label>
                        <input id="validityPeriod" class="form-control" type="text" daterangepicker
                               [(ngModel)]="selectedDate"
                               [options]="dateOptions" (selected)="loadNewDate($event?.start, $event?.end)"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *showDashboard>
    <h1>Statistiche Ordini</h1>
    <hr>
</div>

<div class="row">
    <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name='report'
            [fullScreen]="false"
    >
    </ngx-spinner>
    <div class="col-6">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-4 text-center border-right">
                        <img src="/assets/img/flats/money-sign.png" height="64" />
                        <p class="lead mt-2">Accrediti di plafond</p>
                        <h1 class="display-5">{{ shopOrdersReport ? shopOrdersReport?.plafond.handled_orders : 0}}</h1>
                    </div>
                    <div class="col-4 text-center border-right">
                        <img src="/assets/img/flats/credit-card.png" height="64" />
                        <p class="lead mt-2">Totale plafond ricaricato</p>
                        <h1 class="display-5">{{ (shopOrdersReport ? shopOrdersReport?.plafond.plafond_charged : 0) | currency: '€'}}</h1>
                    </div>
                    <div class="col-4 text-center">
                        <img src="/assets/img/flats/time.png" height="64" />
                        <p class="lead mt-2">Tempo medio di gestione</p>
                        <h1 class="display-5">{{ (shopOrdersReport ? shopOrdersReport?.plafond.average_time : null) | timeToText }}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-4 text-center border-right">
                        <img src="/assets/img/flats/box.png" height="64" />
                        <p class="lead mt-2">Spedizioni
                            sim</p>
                        <h1 class="display-5">{{ shopOrdersReport ? shopOrdersReport?.sim.handled_orders : 0}}</h1>
                    </div>
                    <div class="col-4 text-center border-right">
                        <img src="/assets/img/flats/sim-card.png" height="64" />
                        <p class="lead mt-2">Totale sim spedite</p>
                        <h1 class="display-5">{{ shopOrdersReport ? shopOrdersReport?.sim.sim_shipped : 0}}</h1>
                    </div>
                    <div class="col-4 text-center">
                        <img src="/assets/img/flats/time.png" height="64" />
                        <p class="lead mt-2">Tempo medio di gestione</p>
                        <h1 class="display-5">{{ (shopOrdersReport ? shopOrdersReport?.sim.average_time : null)  | timeToText }}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card">
            <div class="card-header bg-kena-gradient">
                <h1 class="card-title">Operatori che hanno gestito gli ordini</h1>
            </div>
            <div class="card-body p-0">
                <table class="table table-bordered table-striped table-sm">
                    <thead>
                        <tr>
                            <th></th>
                            <th colspan="2" class="text-center">Ordini con sim</th>
                            <th colspan="2" class="text-center">Ordini con plafond</th>
                        </tr>
                        <tr>
                            <th>Operatore</th>
                            <th class="text-center">Gestiti</th>
                            <th class="text-center">Tempo medio</th>
                            <th class="text-center">Gestiti</th>
                            <th class="text-center">Tempo medio</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let row of (shopOrdersReport ? shopOrdersReport.ranking : [])">
                        <td>{{row.operatore}}</td>
                        <td class="text-center">{{row.orders}}</td>
                        <td class="text-center">{{row.average_time | timeToText}}</td>
                        <td class="text-center">{{row.orders_with_plafond}}</td>
                        <td class="text-center">{{row.average_time_plafond | timeToText}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card">
            <div class="card-header bg-kena-gradient">
                <h1 class="card-title">Ordini gestiti</h1>
            </div>
            <div class="card-body p-0">
                <table class="table table-bordered table-striped table-sm">
                    <thead>
                        <tr>
                            <th>Negozio</th>
                            <th class="text-center">Sim</th>
                            <th class="text-center">Plafond</th>
                            <th class="text-center">Data Ordine</th>
                            <th class="text-center">Tempo Gestione</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let row of (shopOrdersReport ? shopOrdersReport.orders : [])">
                        <td>{{row.dealer}}</td>
                        <td class="text-center">{{row.sim}}</td>
                        <td class="text-center">{{row.plafond | currency: '€' }}</td>
                        <td class="text-center">{{row.created | amDateFormat: 'hh:mm' }}</td>
                        <td class="text-center">{{row.handling_time_plafond_interval | timeToText }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

