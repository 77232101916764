
import { Component, OnInit, ViewChild } from "@angular/core";
import { EventiPresidiati, EventsPagination } from "@app/models/eventi_presidiati/eventi_presidiati";
import { MetaPagination } from "@app/models/meta-pagination";
import { EventsService } from "@app/services/events.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Moment } from "moment";
import { DaterangepickerConfig } from "ng2-daterangepicker";
import { NgxSpinnerService } from "ngx-spinner";


@Component({
    selector: 'app-event-list',
    templateUrl: './event-list.component.html',
    styleUrls: ['./event-list.component.css'],
})
export class EventListComponent implements OnInit {
    @ViewChild('eventDetailTemplate') private eventDetailTemplate;
    @ViewChild('eventEditTemplate') private eventEditTemplate;
    filter = {
        dealerID: null,
        status: null,
        date_range: null,
    };

    apiDates = {
        date_range: {
            from: '',
            to: '',
        },
    };
    eventStatuses = EventsService.eventStatuses;

    events: EventiPresidiati[] = [];
    eventsPagination: MetaPagination;
    selectedEvent: EventiPresidiati;
    localFormat = 'DD/MM/YYYY';

    constructor(
        private dateRangePickerOptions: DaterangepickerConfig,
        private modalService: NgbModal,
        private spinner: NgxSpinnerService,
        private eventsService: EventsService
    ) {
    }

    ngOnInit(): void {
        this.loadEventsList();
    }

    loadEventsList() {
        this.spinner.show('table-events');
        const params = {};
        if (this.eventsPagination?.currentPage) {
            params['page'] = this.eventsPagination?.currentPage;
        }
        if (this.filter.dealerID) {
            params['id_dealer'] = this.filter.dealerID;
        }
        if (this.filter.status != null) {
            params['stato'] = this.filter.status;
        }
        if (this.filter.date_range) {
            params['from_date'] = this.apiDates.date_range.from;
            params['to_date'] = this.apiDates.date_range.to;
        }

        this.eventsService.getEvents(params).subscribe(
            (response: EventsPagination) => {
                this.events = response.data;
                this.eventsPagination = response._meta;
                this.spinner.hide('table-events');
            },
            (err) => {
                this.spinner.hide('table-events');
            }
        );
    }

    resetFilter() {
        this.filter = {
            dealerID: null,
            status: null,
            date_range: null,
        };
        this.apiDates = {
            date_range: {
                from: null,
                to: null,
            },
        };
    }

    public generateDateRangeString(startDate: Moment, endDate: Moment, key) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        this.apiDates[key].from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.apiDates[key].to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.filter[key] =
            startDate.format(localDateFormat) +
            this.dateRangePickerOptions.settings.locale.separator +
            endDate.format(localDateFormat);
    }

    setSubject($event: any) {
        this.filter.dealerID = $event ? $event : '';
    }

  
  showDetailModal(event?: EventiPresidiati) {
    this.selectedEvent = event;
    this.modalService.open(this.eventDetailTemplate, { size: "xl" });
  }


    showEditModal(event?: EventiPresidiati) {
        this.selectedEvent = event;
        this.modalService.open(this.eventEditTemplate, {size: 'md'});
    }
}
