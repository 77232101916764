import {Component, Input, OnInit} from '@angular/core';
import {DashboardService} from "@services/dashboard.service";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
    selector: 'app-autoload-chartjs',
    templateUrl: './autoload-chartjs.component.html',
    styleUrls: ['./autoload-chartjs.component.css']
})
export class AutoloadChartjsComponent implements OnInit {

    _url: string;
    data: any;

    get options() {
        if(this.data) {
            return this.data.options;
        }

        return [];
    };

    get datasets() {
        if(this.data) {
            return this.data.datasets;
        }

        return [];
    };

    get labels() {
        if(this.data) {
            return this.data.labels;
        }

        return [];
    };

    get legend() {
        if(this.data) {
            return this.data.legend;
        }

        return [];
    };

    get colors() {
        if(this.data) {
            return this.data.colors;
        }

        return [];
    };

    @Input()
    type = null;

    @Input()
    set url(value: string) {
        this._url = value;

        if (this._url) {
            this.refresh();
        }
    }

    get url() {
        return this._url;
    }

    constructor(private dashboardService: DashboardService,
                private spinner: NgxSpinnerService) {
    }

    ngOnInit() {

    }

    public setUrl(value) {
        this.url = value;
    }

    refresh() {
        this.spinner.show('loader-chart');
        this.dashboardService.get(this.url)
            .subscribe((data) => {
               this.data = data.result;
                this.spinner.hide('loader-chart');
            });
    }
}
