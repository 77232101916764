<div [ngBusy]="busy">
  <div class="row">
    <div class="col-12">
      <div class="card bg-gradient-dark">
        <div class="card-body row align-items-center">
          <div class="col-2 text-center">
            <img
              src="/assets/img/svgs/undraw_file_searching_duff.svg"
              class="header-logo"
            />
          </div>
          <div class="col-8">
            <h1 class="header mb-0">Storico Credito</h1>
            <p class="subtitle mb-4 text-white">
              Qui puoi trovare lo storico dei tuoi addebiti e accrediti del
              credito.
            </p>
            <ng-container *show="['operator']">
              <app-retailer-typeahead
                (onChange)="router.navigate(['/gestione-credito', $event])"
              ></app-retailer-typeahead>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-12">
      <div class="info-box elevation-1">
        <span class="info-box-icon bg-info"
          ><i class="fa fa-euro-sign"></i
        ></span>

        <div class="info-box-content">
          <span class="info-box-text">Credito attuale</span>
          <span class="info-box-number">{{
            credito - debito | currency: "€ "
          }}</span>
        </div>
        <!-- /.info-box-content -->
      </div>
      <!-- /.info-box -->
    </div>
    <!-- /.col -->
    <div class="col-md-3 col-sm-6 col-12">
      <div class="info-box elevation-1">
        <span class="info-box-icon bg-success"
          ><i class="fa fa-cart-plus"></i
        ></span>

        <div class="info-box-content">
          <span class="info-box-text">Totale credito</span>
          <span class="info-box-number">{{ credito | currency: "€ " }}</span>
        </div>
        <!-- /.info-box-content -->
      </div>
      <!-- /.info-box -->
    </div>
    <!-- /.col -->
    <div class="col-md-3 col-sm-6 col-12">
      <div class="info-box elevation-1">
        <span class="info-box-icon bg-warning"
          ><i class="far fa-copy"></i
        ></span>

        <div class="info-box-content">
          <span class="info-box-text">Totale addebito</span>
          <span class="info-box-number">{{ debito | currency: "€ " }}</span>
        </div>
        <!-- /.info-box-content -->
      </div>
      <!-- /.info-box -->
    </div>
    <!-- /.col -->
    <div class="col-md-3 col-sm-6 col-12">
      <div class="info-box elevation-1">
        <span class="info-box-icon bg-danger"
          ><i class="fa fa-list-ul"></i
        ></span>

        <div class="info-box-content">
          <span class="info-box-text">Totale transazioni</span>
          <span class="info-box-number">{{ this.rows.length }}</span>
        </div>
        <!-- /.info-box-content -->
      </div>
      <!-- /.info-box -->
    </div>
    <!-- /.col -->
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card bg-gradient-light">
        <div class="card-header">
          <div class="card-title">
            Lista Transazioni
          </div>
          <div class="card-tools" *ngIf="this.dealerId">
            <button class="btn btn-sm btn-success" (click)="showCreateTransactionModal()" *show="['operator']">Nuova Transazione</button>
          </div>
        </div>
        <div class="card-body p-0">
          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th class="text-center" *show="['operator']"></th>
                <th class="text-center">Scadenza</th>
                <th>Descrizione</th>
                <th *show="['operator']">Operator</th>
                <th class="text-right">Accredito</th>
                <th class="text-right">Addebito</th>
                <th class="text-center">Data</th>
                <th class="text-center">Saldato</th>
                <th class="text-center" *show="['operator']">Totale</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of rows; let i = index;">
                <td *show="['operator']" class="text-center">
                  <i class="fa fa-times text-danger cursor-hand" [swal]="transactionConfirmDeleteOptions"
                  (confirm)="deleteTransaction(row)"></i>
                  <i class="fa fa-edit text-primary cursor-hand ml-2"
                  (click)="showUpdateTransactionModal(row)"></i>
                </td>
                <td class="text-center">
                  {{ row.scadenza | amDateFormat: "DD/MM/YYYY" }}
                </td>
                <td>{{ row.descrizione }}</td>
                <td *show="['operator']">{{ row.operator }}</td>
                <td class="text-right">
                  <span *ngIf="row.isAccredito">{{
                    row.valore | currency: "€"
                  }}</span>
                </td>
                <td class="text-right">
                  <span *ngIf="row.isAddebbito">{{
                    row.valore | currency: "€"
                  }}</span>
                </td>
                <td class="text-center">
                  {{ row.created | amDateFormat: "DD/MM/YYYY" }}
                </td>
                <td *show="['dealer', 'agent']" class="text-center">
                  <input
                    type="checkbox"
                    [(ngModel)]="row.checked"
                    [disabled]="true"
                  />
                </td>
                <td *show="['operator']" class="text-center">
                  <input type="checkbox" [(ngModel)]="row.checked" (change)="saveTransaction(row)" />
                </td>
                <td class="text-right text-bold" *show="['operator']">
                  {{getTotal(rows, i) | currency: "€" }}
                </td>
              </tr>
              <tr>
                <td *show="['operator']"></td>
                <td></td>
                <th class="pl-1">Totale</th>
                <td *show="['operator']"></td>
                <th class="text-right text-success">
                  <span *ngIf="credito - debito > 0">{{
                    credito - debito | currency: "€ "
                  }}</span>
                </th>
                <th class="text-right text-danger">
                  <span *ngIf="credito - debito < 0">{{
                    -(credito - debito) | currency: "€ "
                  }}</span>
                </th>
                <th></th>
                <th ></th>
                <th *show="['operator']"></th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #transactionFormTemplate let-modal>
  <div class="modal-header" [ngBusy]="busy">
    <h4 class="modal-title clamp-title">{{ transactionModalTitle }}</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="transactionForm">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="lead">Descrizione</label>
            <input
              [(ngModel)]="selectedTransaction.descrizione"
              formControlName="descrizione"
              type="text"
              class="form-control"
            />
            <val-errors controlName="descrizione">
              <ng-template valError="required">
                La descrizione è obbligatoria
              </ng-template>
            </val-errors>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label class="lead"
              >Valore</label
            >
            <input
              [(ngModel)]="selectedTransaction.valore"
              formControlName="valore"
              type="number"
              class="form-control"
            />
            <val-errors controlName="valore">
              <ng-template valError="required">
                Il valore è obbligatorio
              </ng-template>
            </val-errors>
          </div>
        </div>
        <div class="col-12 mt-3">
          <button
            class="btn btn-success btn-block"
            [disabled]="!this.transactionForm.valid"
            (click)="saveTransaction(selectedTransaction)"
          >
            <i class="fa fa-check-circle mr-2"></i>Salva
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
