import {Component, OnInit} from '@angular/core';
import {DataService} from '@services/data.service';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '@services/authentication.service';
import {TableColumn} from '@app/pages/partials/table/table.component';
import {SimKena} from '@models/sim-kena';

@Component({
  selector: 'app-assets-kena-index',
  templateUrl: './assets-kena-index.component.html',
  styleUrls: ['./assets-kena-index.component.scss']
})
export class AssetsKenaIndexComponent implements OnInit {

  busy: Subscription;

  rows: SimKena[] = [];
  columns: TableColumn[];

  totaleSimDisponibili: number;

  constructor(
      public dataService: DataService,
      public authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.columns = [
      {key: 'serial', text: 'Seriale'},
      {key: 'numero', text: 'Numero'},
      {key: 'stato', text: 'Stato'},
      {key: 'created', text: 'Assegnato il'},
    ];

    this.busy = this.dataService.get('/get-lista-magazzino-sim-kena')
      .subscribe((data: any) => {
        this.rows = new SimKena().fromArray(data.result);

        this.totaleSimDisponibili = this.rows.filter(item => item.disponibile).length;
      });
  }

}
