import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {RequestService} from '@services/request.service';
import {map} from 'rxjs/operators';
import {AlertService} from '@services/alert.service';

@Injectable({
    providedIn: 'root',
})
export class DealerService {
    ENDPOINT = 'dealers';

    constructor(public request: RequestService) {
    }

    list(params = {}) {
        return this.request
            .get(`${this.ENDPOINT}/list`, params, {observe: 'response'})
            .pipe(
                map((data: HttpResponse<any>) => {
                    return {
                        rows: data.body,
                        pagination: {
                            totalCount: parseInt(data.headers.get('X-Pagination-Total-Count')),
                            page: parseInt(data.headers.get('X-Pagination-Current-Page')),
                            pageSize: parseInt(data.headers.get('X-Pagination-Per-Page')),
                            pages: parseInt(data.headers.get('X-Pagination-Page-Count')),
                        },
                    };
                })
            );
    }

    listByUnweldedTransactions() {
        return this.request.get(
            `${this.ENDPOINT}/list-by-unwelded-transactions?fields=id,company_name,iban,blacklist&expand=unweldedTransactions`
        );
    }

    isUsernameAvailable(username) {
        return this.request.get(`${this.ENDPOINT}/username-available/${username}`);
    }

    isVatAvalaible(vat) {
        return this.request.get(`${this.ENDPOINT}/vat-available/${vat}`);
    }

    getDealer(id, params = {}) {
        return this.request.get(`${this.ENDPOINT}/${id}`, params);
    }

    saveDealer(data) {
        return this.request.post(`${this.ENDPOINT}`, data);
    }

    updateDealer(id, data) {
        return this.request.post(`${this.ENDPOINT}/${id}`, data);
    }

    deleteDealer(id) {
        return this.request.delete(`${this.ENDPOINT}/${id}`);
    }

    restoreDealer(id) {
        return this.request.put(`${this.ENDPOINT}/restore/${id}`);
    }

    changePassword(id, password) {
        return this.request.post(`${this.ENDPOINT}/change-password/${id}`, {password: password});
    }

    saveShopAccess(data) {
        return this.request.post(`${this.ENDPOINT}/shop-access`, data);
    }

    deleteShopAccess(id) {
        return this.request.delete(`${this.ENDPOINT}/shop-access/${id}`);
    }

    // list(id) {
    //     return this.request.get(`${this.ENDPOINT}/lista-dealer?deleted=` + id);
    // }

    // getDealer(id) {
    //   return this.request.get(`${this.ENDPOINT}/get-dealer?id=` + id);
    // }

    // saveDealer(data) {
    //   return this.request.post(`${this.ENDPOINT}/save-dealer`, data);
    // }
    listaDealers() {
        return this.request.get(`${this.ENDPOINT}/get-lista-dealer`);
    }

    /**
     * get dealer list with scope for agent or operater
     * and with English attribute names
     */
    getList() {
        return this.request.get(`dealers`);
    }

    editPassword(body, isGuest) {
        const path = isGuest ? 'reset-password' : 'change-password';
        return this.request.put(`${this.ENDPOINT}/${path}`, body);
    }
}
