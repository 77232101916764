import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {OperatorService} from "@services/operator.service";
import {DataService} from "@services/data.service";
import {CustomValidators} from "ngx-custom-validators";
import {Subscription} from "rxjs";
import {ModalComponent} from "@widgets/modal/modal.component";

@Component({
    selector: 'app-gestione-stand-dettagli',
    templateUrl: './gestione-stand-dettagli.component.html',
    styleUrls: ['./gestione-stand-dettagli.component.css']
})
export class GestioneStandDettagliComponent implements OnInit {
    new = false;
    busy: Subscription;
    @ViewChild('ctrModal') ctrModal: ModalComponent;

    presidio: string;
    form: FormGroup;
    formContratto: FormGroup;
    contrattoFile: any;
    comuni = [];

    data = {
        presidio: [],
        statistiche: {rows: [], columns: []},
        contratti: [],
        province: [],
        regioni: [],
        am: []

    };

    setComuni($event: any) {
        this.dataService.post('/get-comuni', {id_provincia: $event.id}).subscribe((data) => {this.comuni = data.result; console.log(this.comuni,'32'); });

    }

    get regioni() {
        if (this.data && this.data.regioni) {
            return this.data.regioni;
        }

        return [];
    }


    get province() {
        if (this.data && this.data.province) {
            return this.data.province;
        }

        return [];
    }

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        private operatorService: OperatorService,
        private dataService: DataService,
        private formBuilder: FormBuilder
    ) {
        this.presidio = this.route.snapshot.paramMap.get('id');
        if(!this.presidio) {
            this.new = true;
        }
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            id: [null, []],
            deleted: [null, [Validators.required]],
            accesso: [null, [Validators.required]],
            nome: [null, [Validators.required]],
            user: [null, [Validators.required]],
            password: [null, [Validators.required]],
            email: [null, []],
            id_areamanager: [null, []],
            regione: [null, []],
            provincia: [null, []],
            comune: [null, []],
            indirizzo: [null, []],
        });

        this.formContratto = this.formBuilder.group({
            id: [null, []],
            id_presidio: [this.presidio, []],
            inizio: [null, [Validators.required]],
            fine: [null, [Validators.required]],
            tipo_contratto: [null, [Validators.required]],
            percorso: [null, []],
            fitto: [null, [Validators.required, CustomValidators.number]],
            stato: [null, [Validators.required]],
        });

        this.refresh();
    }

    c() {
        return this.form.controls;
    }

    refresh() {
        this.busy = this.operatorService.get(`/get-presidio?id=${this.presidio}`)
            .subscribe((data) => {
                this.data = data.result;

                if(this.new) {
                    this.form.markAllAsTouched();
                }
                else {
                    this.form.patchValue(this.data.presidio);
                }
            });
    }

    save() {
        if (this.form.valid) {
            this.busy = this.operatorService.post(`/save-presidio`, this.form.value)
                .subscribe((data: any) => {
                    if (data.success) {
                        if (this.new) {
                            this.router.navigate(['/gestione-stand/stand/', data.result.id]);
                        }
                        this.refresh();
                    }
                });
        }
        else {
            Object.keys(this.form.controls).forEach(key => {

            const controlErrors: ValidationErrors = this.form.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
            this.form.markAllAsTouched();
        }
    }

    setContrattoFile($event: any) {
        const file = $event.target.files.item(0);
        if (!file) {
            return;
        }

        this.contrattoFile = file;
    }

    saveContratto() {
        this.formContratto.controls.id_presidio.setValue(this.presidio);
        const formData = new FormData();
        if(!this.formContratto.controls.id.value) {
            formData.append('file', this.contrattoFile, this.contrattoFile.name);
        }
        formData.append('contratto', JSON.stringify(this.formContratto.value));

        this.busy = this.operatorService.post(`/save-presidio-contratto`, formData)
            .subscribe((data: any) => {
                if(data.success) {
                    this.formContratto.reset();
                    this.contrattoFile = null;
                    this.ctrModal.dismiss();
                    this.refresh();
                }
            });
    }

    editContratto(ctr: any) {
        this.formContratto.patchValue(ctr);
        this.ctrModal.open();
    }

    delContratto() {
        this.busy = this.operatorService.get(`/delete-presidio-contratto?id=${this.formContratto.controls.id.value}`)
            .subscribe((data: any) => {
                if(data.success) {
                    this.formContratto.reset();
                    this.contrattoFile = null;
                    this.ctrModal.dismiss();
                    this.refresh();
                }
            });
    }

}
