import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OperatorService } from "@services/operator.service";
import { Subscription } from "rxjs";
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
} from "angular-animations";
import { ModalComponent } from "@widgets/modal/modal.component";

@Component({
  selector: "app-gestione-storni",
  templateUrl: "./gestione-storni.component.html",
  animations: [
    fadeInOnEnterAnimation({ duration: 200 }),
    fadeOutOnLeaveAnimation({ duration: 200 }),
  ],
  styleUrls: ["./gestione-storni.component.css"],
})
export class GestioneStorniComponent implements OnInit {
  @ViewChild("messageModal") messageModal: ModalComponent;
  modal = {
    title: "",
    icon: "",
    message: "",
    description: "",
  };

  _periodo = null;
  _operatore = null;

  startup: any;
  busy: Subscription;
  data: any;

  set periodo(value: string) {
    if (!this._periodo) {
      this._periodo = value;
    } else {
      this._periodo = value;
      this.refresh();
    }
  }

  get periodo() {
    return this._periodo;
  }

  set operatore(value: number) {
    if (!this._operatore) {
      this._operatore = value;
    } else {
      this._operatore = value;
      this.refresh();
    }
  }

  get operatore() {
    return this._operatore;
  }

  constructor(
    private modalService: NgbModal,
    private operatorService: OperatorService
  ) {}

  ngOnInit() {
    this.busy = this.operatorService
      .get("/storni-period")
      .subscribe((data) => {
        this.startup = data.result;
        this.periodo = this.startup.periodo;
        this.operatore = this.startup.operatore;

        this.refresh();
      });
  }

  refresh() {
    this.busy = this.operatorService
      .get(
        `/gestione-storni?operatore=${this.operatore}&periodo=${this.periodo}`
      )
      .subscribe((data) => {
        this.data = data.result;
      });
  }

  showModal(title, icon, message, description) {
    this.modal.title = title;
    this.modal.icon = icon;
    this.modal.message = message;
    this.modal.description = description;
    this.messageModal.open();
  }

  save() {
    this.busy = this.operatorService
      .post(
        `/gestione-storni?operatore=${this.operatore}&periodo=${this.periodo}`,
        { data: this.data }
      )
      .subscribe((data) => {
        this.data = data.result;

        if (data.success) {
          this.showModal(
            "Ottimo",
            "fa-check-circle text-success",
            "Operazione completata",
            "I dati sono stati salvati correttamente."
          );
        } else {
          this.showModal(
            "Peccato",
            "fa-times-circle text-danger",
            "Errore",
            "Il salvataggio non è andato a buon fine..."
          );
        }
      });
  }

  getRows(card: any) {
    if (card.hasOwnProperty("parent")) {
      return this.data[card.parent][card.data].rows;
    }

    return this.data[card.data].rows;
  }

  getCols(card: any) {
    if (card.hasOwnProperty("parent")) {
      return this.data[card.parent][card.data].cols;
    }

    return this.data[card.data].cols;
  }

  callAction(url: any) {
    this.busy = this.operatorService
      .get(`/${url}&operatore=${this.operatore}&periodo=${this.periodo}`)
      .subscribe((data) => {
        // this.modalService.dismissAll();
        if (data.success) {
          this.data[data.result.replace].rows = data.result.rows;
          //     this.excelStorni = '';
          //     this.showModal('Ottimo', 'fa-check-circle text-success',
          //         'Operazione completata',
          //         'Il report è stato aggiornato correttamente.');
        } else {
          //     this.showModal('Peccato', 'fa-times-circle text-danger',
          //         'Errore',
          //         'Riprova a riaggiornarlo, se dopo un paio di tentativi ti da ancora errore chiedi a Lucio :D');
        }
      });
  }
}
