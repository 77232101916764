import {BaseModel} from '@models/base-model';
import {Dealer} from '@models/dealer';
import {PbxQuestion} from '@models/pbx/pbx-question';
import {MetaPagination} from '@models/meta-pagination';


export class PbxCampaign extends BaseModel {
    id: number;
    name: string;
    description: string;
    start_date: Date;
    end_date: Date;
    status: any;
    table_url: string;
    leads: Dealer[];
    questions: PbxQuestion[];
    dealerIds: Array<any>;
    questionIds: Array<any>;
}

export class PbxCampaignReport extends BaseModel {
    number_of_leads: number;
    number_of_calls: number;
    number_of_positive_calls: number;
    number_of_negative_calls: number;
}

export class PbxCampaignOperatorPagination extends BaseModel {
    data: PbxCampaignOperator[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class PbxCampaignOperator extends BaseModel {
    id: number;
    caller_id: number;
    caller_name: string;
    negative_calls: string;
    positive_calls: string;
    total_calls: string;
    total_duration: string;
}

export class PbxQuestionReport extends BaseModel {
    id: number;
    campaign_id: number;
    question_id: number;
    number_of_answers: string;
    positive_answers: string;
    info: PbxQuestion;
}
