import {Component, Input, OnInit} from '@angular/core';
import {DashboardService} from "@services/dashboard.service";
import {NgxSpinnerService} from "ngx-spinner";
import {OperatorService} from "@services/operator.service";
import {RequestService} from '@services/request.service';

@Component({
  selector: 'app-master-subheader',
  templateUrl: './master-subheader.component.html',
  styleUrls: ['./master-subheader.component.css']
})
export class MasterSubheaderComponent implements OnInit {
    _url: string;
    @Input() data: any;
    @Input() cardClass = 'bg-gradient-light';
    @Input() imgClass = null;
    @Input() operator = false;
    @Input() free = false;

    error = false;

    @Input() set url(value: string) {
        this._url = value;

        if (this._url !== undefined) {
            this.load();
        }
    }

    constructor(
        private dashboardService: DashboardService,
        private operatorService: OperatorService,
        private requestService: RequestService,
        private spinner: NgxSpinnerService
    ) {
    }

    ngOnInit() {

    }

    load() {
        this.error = false;
        this.spinner.show('loader-subheader');
        if(this.free) {
            this.requestService.get(this._url)
                .subscribe((data) => {
                    this.data = data;
                    this.spinner.hide('loader-subheader');
                }, (error) => {
                    this.spinner.hide('loader-subheader');
                    this.error = true;
                });
        }
        else {
            if (this.operator) {
                this.operatorService.get(this._url)
                    .subscribe((data) => {
                        this.data = data;
                        this.spinner.hide('loader-subheader');
                    }, (error) => {
                        this.spinner.hide('loader-subheader');
                        this.error = true;
                    });
            } else {
                this.dashboardService.get(this._url)
                    .subscribe((data) => {
                        this.data = data.result;
                        this.spinner.hide('loader-subheader');
                    }, (error) => {
                        this.spinner.hide('loader-subheader');
                        this.error = true;
                    });
            }
        }
    }


}
