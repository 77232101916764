<input class="form-control bg-white" type="text" [(ngModel)]="dateRangeValue" (focus)="showMonthPicker()"
       readonly=" true"/>

<div class="position-relative" *ngIf="showPicker">
    <div class="daterangepicker ltr show-ranges show-calendar opensleft d-block">
        <div class="d-lg-inline-flex">
            <div class="ranges" *ngIf = "!singleMonthPicker">
                <ul>
                    <li *ngFor="let range of getListOfRanges();index as i" (click)="selectRange(range,i)"
                        [ngClass]="{active: activeRange==i}" translate>{{range}}</li>
                </ul>
            </div>
            <div class="drp-calendar" [ngClass]="{'border-0': singleMonthPicker}">
                <div class="calendar-table">
                    <div class="topPanel d-flex justify-content-between">
                        <button class="prevYearButton float-left" (click)="decrementYear()">
                            <span class="prev available"><span></span></span>
                        </button>
                        <span class="yearLabel px-5 font-weight-bold text-dark">{{ years[currentYearIndex] }}</span>
                        <button class="nextYearButton" (click)="incrementYear()">
                            <span class="next available"><span></span></span>
                        </button>
                    </div>
                    <div class="contentPanel">
                        <button (click)="setDate(i)" class="btn monthItem text-capitalize" type="button"
                                *ngFor="let month of monthDataSlice; index as i" [disabled]="this.disabledMonth(month)"
                                [ngClass]="setClassName(month,i)">{{month.monthName}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="drp-buttons"><span class="drp-selected">{{selectedRange}}</span>
            <button class="cancelBtn btn btn-sm btn-default" type="button" translate (click)="cancel()">Cancel</button>
            <button class="applyBtn btn btn-sm btn-primary" type="button" translate (click)="apply()"
                    [disabled]="(!singleMonthPicker && !rangeIndexes[1]) || (singleMonthPicker && !rangeIndexes[0])">Apply
            </button>
        </div>
    </div>
</div>
