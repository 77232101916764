import {Component, OnInit, ViewChild} from '@angular/core';
import {CartService} from '@services/cart.service';
import {DataService} from '@services/data.service';
import {Product} from '@models/product';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalComponent} from '@widgets/modal/modal.component';

@Component({
    selector: 'app-customer-base-disabled',
    templateUrl: './customer-base-disabled.component.html',
    styleUrls: ['./customer-base-disabled.component.scss']
})
export class CustomerBaseDisabledComponent implements OnInit {
    @ViewChild('planModal') planModal: ModalComponent;
    @ViewChild('premiumModal') premiumModal: ModalComponent;

    products: Product[];


    tiers = [
        {
            title: 'Starter',
            subtitle: '',
            price: 114,
            sms: '200',
            ut: 89,
            ppm: 0.125,
            productid: 245,
            special: true
        },
        {
            title: 'Starter PRO',
            subtitle: '',
            price: 134,
            sms: '500',
            ut: 79,
            ppm: 0.11,
            productid: 252,
            special: true
        },
        {
            title: 'Standard',
            subtitle: '',
            price: 154,
            sms: '1000',
            ut: 69,
            ppm: 0.085,
            productid: 253,
            special: false
        },
        {
            title: 'Smart',
            subtitle: '',
            price: 229,
            sms: '2500',
            ut: 59,
            ppm: 0.068,
            productid: 254,
            special: false
        },
        {
            title: 'Connect',
            subtitle: '',
            price: 349,
            sms: '5000',
            ut: 49,
            ppm: 0.06,
            productid: 255,
            special: false
        },
        {
            title: 'Professional',
            subtitle: '',
            price: 609,
            sms: '10000',
            ut: 29,
            ppm: 0.058,
            productid: 256,
            special: false
        },
        {
            title: 'Business',
            subtitle: '',
            price: 1060,
            sms: '20000',
            ut: 0,
            ppm: 0.053,
            productid: 257,
            special: false
        },
    ];

    private currentPlan: any;

    constructor(
        private cartService: CartService,
        private modalService: NgbModal,
        private dataService: DataService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.dataService.get('/get-prodotti')
            .subscribe((data: any) => {
                this.products = new Product().fromArray(data.result);
            });
    }

    buy(id: number) {
        this.cartService.emptyCart();
        const prod = this.products.find(item => item.id.toString() === id.toString());
        this.cartService.addToCart(prod);
        this.modalService.dismissAll();
        this.router.navigate(['/negozio/carrello']);
    }

    showBasePlane(tier: any) {
        this.currentPlan = tier;
        this.modalService.open(this.planModal, { size: 'xl' });
    }

    showPremiumPlane() {
        this.modalService.open(this.premiumModal, { size: 'xl' });
    }
}
