import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {DataService} from "@services/data.service";
import {takeUntil} from "rxjs/operators";
import {TableColumn, TableColumnAlignment} from "@app/pages/partials/table/table.component";

interface ItemClassificaVenditore {
  id: number;
  venditore: string;
  totale_contratti: number;
  kena: number;
  // al: number;
  // mnp: number;
}

@Component({
  selector: 'app-classifica-venditori',
  templateUrl: './classifica-venditori.component.html',
  styleUrls: ['./classifica-venditori.component.css']
})
export class ClassificaVenditoriComponent implements OnInit, OnDestroy  {

  @Input()
  idPresidio: number;

  busy: Subscription;
  // observable
  private destroy$ = new Subject();
  classifica: [ItemClassificaVenditore];
  columns: TableColumn[] = [
    {key: 'id', text: 'Id', alignment: TableColumnAlignment.CENTER},
    {key: 'venditore', text: 'venditore', alignment: TableColumnAlignment.LEFT},
    {key: 'kena', text: 'Kena', alignment: TableColumnAlignment.CENTER},
    {key: 'totale_contratti', text: 'Totale', alignment: TableColumnAlignment.CENTER},
  ];
  constructor(
      private dataService: DataService,
  ) { }

  ngOnInit(): void {
    this.busy = this.dataService
        .get(`/classifica-venditori?id=${this.idPresidio}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any) => {
          this.classifica = data.result;
        });
  }

  ngOnDestroy(): void {
    this.destroy$.next();  // trigger the unsubscribe
    this.destroy$.complete(); // finalize & clean up the subject stream
  }

}
