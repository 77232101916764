import {VisiteService} from './../../../services/visite.service';
import {Component, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {DashboardService} from '@services/dashboard.service';
import {ActivatedRoute} from '@angular/router';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AgenteService} from '@services/agente.service';
import {CalendarOptions} from '@fullcalendar/core';
import {Moment} from 'moment';
import * as moment from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {AgentVisitsService} from '@services/agent-visits.service';
import {AgentVisit} from '@models/visita-agente';
import {AgentConfirmVisit, AgentCustomVisit, AgentFormVisit} from '@models/agent-visit';
import {environment} from '@environments/environment';

@Component({
    selector: 'app-agent-visite-index',
    templateUrl: './agent-visite-index.component.html',
    styleUrls: ['./agent-visite-index.component.css'],
})
export class AgentVisiteIndexComponent implements OnInit {
    busy: Subscription;
    busyModal: Subscription;
    startup: {
        agente: '';
        periodi: any;
        periodo: any;
    };

    selectedDateRange = {
        from: '',
        to: '',
    };

    filterForm = {
        dateRange: '',
    };

    urlSubheader: string;
    id: any;
    urlTabellaDealers: any;
    table = {
        rows: [],
        columns: [],
    };

    visiteProgrammate = {
        rows: [],
        columns: [],
    };

    @ViewChild('nuovaVisita') modal;
    @ViewChild('nuovaVisitaCustom') modalCustom;
    @ViewChild('visitaProgrammata') modalProgrammata;
    form: FormGroup;
    formCustom: FormGroup;
    formProgrammata: FormGroup;
    curDealerId: any;
    curDealerName: any;
    curVisitaProgrammata: any;

    calendarOptions: CalendarOptions = {
        initialView: 'dayGridMonth',
        events: [],
        locale: 'it',
    };

    defaultView = true;
    global: any;
    timeline: any = [];
    baseAwsUrl = environment.baseAwsUrl;
    currentLocation = {
        lat: null,
        lng: null
    };

    constructor(
        private modalService: NgbModal,
        private dashboardService: DashboardService,
        private agentVisitsService: AgentVisitsService,
        private service: VisiteService,
        private agenteService: AgenteService,
        public formBuilder: FormBuilder,
        private dateRangePickerOptions: DaterangepickerConfig,
        public route: ActivatedRoute
    ) {
        this.id = this.route.snapshot.paramMap.get('id');
        this.dateRangePickerOptions.settings.locale.format = 'DD/MM/YYYY';
        this.setDefaultRange();
        navigator.geolocation.getCurrentPosition((position) => {
            this.currentLocation.lat = position.coords.latitude;
            this.currentLocation.lng = position.coords.longitude;
        });
    }

    ngOnInit() {
        this.loadVisitSchedule();
        this.form = this.formBuilder.group({
            id_dealer: [null, [Validators.required]],
            date: [moment().format('DD-MM-YYYY'), [Validators.required]],
            affiliato: [null, []],
            attivita_chiusa: [null, []],
            note_visita: [null, [Validators.required]],
            documents: new FormArray([]),
            actual_plafond: [null, [Validators.min(1)]],
            warehouse_sim: [null, [Validators.min(1)]],
        });

        this.formProgrammata = this.formBuilder.group({
            id_visita_programmata: [null, [Validators.required]],
            date: [moment().format('DD-MM-YYYY'), [Validators.required]],
            note_visita: [null, [Validators.required]],
            affiliato: [null, []],
            attivita_chiusa: [null, []],
            documents: new FormArray([]),
            actual_plafond: [null, [Validators.min(1)]],
            warehouse_sim: [null, [Validators.min(1)]],
        });

        this.formCustom = this.formBuilder.group({
            negozio: [null, [Validators.required]],
            date: [moment().format('DD-MM-YYYY'), [Validators.required]],
            note_visita: [null, [Validators.required]],
            affiliato: [null, []],
            attivita_chiusa: [null, []],
            provincia: [null, [Validators.required]],
            citta: [null, [Validators.required]],
            documents: new FormArray([]),
            actual_plafond: [null, [Validators.min(1)]],
            warehouse_sim: [null, [Validators.min(1)]],
        });
    }

    refresh() {
        this.busy = this.service
            .salesAgentOverview(
                this.selectedDateRange.from,
                this.selectedDateRange.to
            )
            .subscribe((data) => {
                this.timeline = data.timeline;
                this.global = data.overview;
                this.refreshTable();
            });
    }

    setDefaultRange() {
        this.setSelectedDateRange(moment().startOf('month'), moment());
    }

    setSelectedDateRange(from: Moment, to: Moment) {
        if (from.isSame(moment().startOf('month')) && to.isSame(moment())) {
            this.defaultView = true;
        } else {
            this.defaultView = false;
        }

        const selectedDateRange = this.getDateString(from, to);
        this.filterForm.dateRange = selectedDateRange;
        this.refresh();
    }

    private getDateString(startDate: any, endDate: any) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        const start = moment(startDate);
        const end = moment(endDate);
        this.selectedDateRange.from = start.format('YYYY-MM-DD');
        this.selectedDateRange.to = end.format('YYYY-MM-DD');
        return (
            start.format(localDateFormat) +
            this.dateRangePickerOptions.settings.locale.separator +
            end.format(localDateFormat)
        );
    }

    loadVisitSchedule() {
        this.agenteService.visiteProgrammate().subscribe((data) => {
            this.visiteProgrammate = data.result;
        });
    }

    private refreshTable() {
        this.busy = this.dashboardService
            .get(`/visite-tabella`)
            .subscribe((data) => {
                this.table = data.result;
            });
    }

    trigger($event: any) {
        this.form.reset();
        this.curDealerId = $event.value;
        this.curDealerName = this.table.rows.find(
            (item) => item.action === $event.value
        ).negozio;
        this.form.controls.id_dealer.setValue(this.curDealerId);
        this.modalService.open(this.modal, {size: 'lg', scrollable: true});
    }

    triggerCustom() {
        this.formCustom.reset();
        this.modalService.open(this.modalCustom, {size: 'lg', scrollable: true});
    }

    saveVisita() {
        const body = new AgentFormVisit(this.form.value);
        body.current_lat = this.currentLocation.lat;
        body.current_lng = this.currentLocation.lng;
        this.busyModal = this.agentVisitsService.postVisit(body.postPayload)
            .subscribe((data) => {
                this.modalService.dismissAll();
                this.form.reset();
                this.refresh();
                this.refreshTable();
            });
    }

    saveVisitaCustom() {
        const body = new AgentCustomVisit(this.formCustom.value);
        body.current_lat = this.currentLocation.lat;
        body.current_lng = this.currentLocation.lng;
        this.busyModal = this.agentVisitsService.postVisit(body.postPayload)
            .subscribe((data) => {
                this.formCustom.reset();
                this.modalService.dismissAll();
                this.refresh();
                this.refreshTable();
            });
    }

    setIndirizzo($event: any) {
        this.formCustom.controls.citta.setValue($event.city);
        this.formCustom.controls.provincia.setValue($event.province);
    }

    sendReply(item: any) {
        this.busy = this.agentVisitsService
            .postResponse({
                id_visita: item.id,
                testo: item.reply,
            })
            .subscribe((data) => {
                this.refresh();
            });
    }

    triggerProgrammata($event: any) {
        this.formProgrammata.reset();
        this.formProgrammata.patchValue({id_visita_programmata: $event.value});
        this.modalService.open(this.modalProgrammata, {size: 'lg', scrollable: true});
    }

    saveVisitaProgrammata() {
        const body = new AgentConfirmVisit(this.formProgrammata.value);
        body.current_lat = this.currentLocation.lat;
        body.current_lng = this.currentLocation.lng;
        this.busyModal = this.agentVisitsService.postVisit(body.postPayload)
            .subscribe((data) => {
                this.formProgrammata.reset();
                this.modalService.dismissAll();
                this.refresh();
                this.refreshTable();
                this.loadVisitSchedule();
            });
    }

    addDocument(form: FormGroup) {
        const documents = form.get('documents') as FormArray;
        documents.push(new FormGroup({
            file: new FormControl(''),
            file_name: new FormControl(''),
        }));
    }

    removeDocument(form: FormGroup, index) {
        const documents = form.get('documents') as FormArray;
        return documents.removeAt(index);
    }

    handleFileInput(file, form: FormGroup) {
        form.get('file').setValue(file);
        form.get('file_name').setValue(file?.name);
    }

    handleFileRemove(form: FormGroup) {
        form.get('file').setValue(null);
        form.get('file_name').setValue('');
    }
}
