<div class="card bg-gradient-light">
    <div class="card-header">
        <h1 class="card-title">Filter</h1>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Ragione Sociale</label>
                <input
                        type="text"
                        [(ngModel)]="filter.name"
                        (keyup.enter)="refreshList()"
                        class="form-control form-control-sm"
                />
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Nome Rappresentante</label>
                <input
                        type="text"
                        [(ngModel)]="filter.owner_name"
                        (keyup.enter)="refreshList()"
                        class="form-control form-control-sm"
                />
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Cognome Rappresentante</label>
                <input
                        type="text"
                        [(ngModel)]="filter.owner_surname"
                        (keyup.enter)="refreshList()"
                        class="form-control form-control-sm"
                />
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Partita Iva</label>
                <input
                        type="text"
                        [(ngModel)]="filter.vat"
                        (keyup.enter)="refreshList()"
                        class="form-control form-control-sm"
                />
            </div>
        </div>
        <div class="row">
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Regione</label>
                <app-state-select
                        (onChange)="this.filter.state = $event"
                        [small]="true"
                ></app-state-select>
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Provincia</label>
                <app-province-select
                        [state]="this.filter.state"
                        [onDemand]="true"
                        [small]="true"
                        (onChange)="this.filter.province = $event"
                ></app-province-select>
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Comune</label>
                <app-city-select
                        [province]="this.filter.province"
                        [onDemand]="true"
                        [small]="true"
                        (onChange)="this.filter.city = $event"
                ></app-city-select>
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Data Creazione</label>
                <input
                        class="form-control form-control-sm"
                        #availabilityRangePicker
                        type="text"
                        [(ngModel)]="filter.created_range"
                        daterangepicker
                        [options]="{}"
                        (selected)="setRangeCreated($event.start, $event.end)"
                />
            </div>
        </div>
        <div class="row">
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Telefono</label>
                <input
                        type="text"
                        [(ngModel)]="filter.phone"
                        (keyup.enter)="refreshList()"
                        class="form-control form-control-sm"
                />
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Cellulare</label>
                <input
                        type="text"
                        [(ngModel)]="filter.mobile"
                        (keyup.enter)="refreshList()"
                        class="form-control form-control-sm"
                />
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Operatori</label>
                <app-vendor-select
                        [small]="true"
                        [multiple]="true"
                        (onChange)="this.filter.vendors = $event"
                ></app-vendor-select>
            </div>

            <div class="form-group col-12 col-md-4 col-lg-3">
                <label>Stato</label>
                <select
                        class="form-control form-control-sm"
                        [(ngModel)]="filter.deleted"
                >
                    <option [value]="0">Attivi</option>
                    <option [value]="1">Disattivi</option>
                </select>
            </div>
            <div class="form-group col-12 col-md-4 col-lg-3">
                <label translate>Roles</label>
                <ng-select class="form-control-sm" [(ngModel)]="filter.roles" [clearable]="false">
                    <ng-option [value]="dealerRole" *show="['master','master-agent','agent','operator']" translate>
                        Retailer
                    </ng-option>
                    <ng-option [value]="agentRole" *show="['master','operator']">Key Account</ng-option>
                    <ng-option [value]="masterAgentRole" *show="['master']">Area Manager</ng-option>
                    <ng-option [value]="operatorRole" *show="['master']">Staff</ng-option>
                    <ng-option [value]="submasterRole" *show="['master']">Sub Master</ng-option>
                </ng-select>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <button class="btn btn-dark btn-sm mr-2" (click)="resetFilters()">
            Reset
        </button>
        <button
                class="btn btn-primary btn-sm"
                type="submit"
                (click)="refreshList(true)"
        >
            Filtra
        </button>
    </div>
</div>
<div class="card bg-gradient-light">
    <div class="card-header">
        <div class="d-inline-block">
            <div ngbDropdown [autoClose]="false">
                <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle>
                    Mostra più dati
                </button>
                <div ngbDropdownMenu>
                    <ul style="max-height: 300px; overflow-y: scroll; padding: 0;">
                        <li ngbDropdownItem *ngFor="let item of this.fields">
                            <label style="min-width: 100%" class="mb-0">
                                <input type="checkbox" [(ngModel)]="item.checked"/>
                                {{ item.label }}
                            </label>
                        </li>
                        <li ngbDropdownItem *ngFor="let item of this.expand">
                            <label style="min-width: 100%" class="mb-0">
                                <input type="checkbox" [(ngModel)]="item.checked"/>
                                {{ item.label }}
                            </label>
                        </li>
                    </ul>
                    <div class="px-4 mt-2">
                        <button
                                class="btn btn-primary btn-sm btn-block"
                                (click)="refreshList()"
                        >
                            Aggiorna
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-tools">
      <span *ngIf="isDealersList">
        <button *show="['master','operator']" class="btn btn-secondary btn-sm mr-2" translate
                (click)="showCreateCampaignModal()"
                [disabled]="selectedDealersForCampaign?.length<=0">
          Create Campaign
        </button>
      </span>
            <button class="btn btn-success btn-sm" *show="['agent', 'master','operator']"
                    (click)="showCreateDealerModal()">
                Nuovo Negozio
            </button>
        </div>
    </div>

    <div class="card-body p-0 rounded-bottom table-responsive">
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th *ngIf="isDealersList">
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" id="select-all" [(ngModel)]="selectAll"
                               (change)="selectDealer($event)">
                        <label for="select-all" class="custom-control-label" translate></label>
                    </div>
                </th>
                <th *show="['operator','master']"></th>
                <th *ngIf="this.activeFields['id']">ID</th>
                <th *ngIf="this.activeFields['username']">Username</th>
                <th *ngIf="this.activeFields['company_name']">Rag. Sociale</th>
                <th *ngIf="this.activeFields['piva']">P. IVA</th>
                <th *ngIf="this.activeFields['codfiscale']">Cod. Fiscale</th>
                <th *ngIf="this.activeFields['company_owner']">Responsabile</th>
                <th *ngIf="this.activeFields['cellulare']">Cellulare</th>
                <th *ngIf="this.activeFields['telefono']">Telefono</th>
                <th *ngIf="this.activeFields['email']">E-Mail</th>
                <th *ngIf="this.activeFields['pec']">PEC</th>
                <th *ngIf="this.activeFields['indirizzo']">Indirizzo</th>
                <th *ngIf="this.activeExpand['city']">Comune</th>
                <th *ngIf="this.activeExpand['province']">Provincia</th>
                <th *ngIf="this.activeExpand['state']">Regione</th>
                <th *ngIf="this.activeExpand['enabledVendors']" class="text-center">
                    Operatori
                </th>
                <ng-container *ngIf="this.activeExpand['vendors']">
                    <th *ngFor="let vendor of enabledVendors">
                        Codice {{ vendor.name }}
                    </th>
                </ng-container>
                <ng-container *ngIf="this.activeExpand['agents']">
                    <th *ngFor="let vendor of enabledVendors">
                        Key Account {{ vendor.name }}
                    </th>
                </ng-container>
                <th *ngIf="this.activeFields['iban']">IBAN</th>
                <th *ngIf="this.activeFields['created']">Inserito il</th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="table-dealers"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let dealer of dealers;index as i">
                <td *ngIf="isDealersList">
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" [id]="i" [(ngModel)]="dealer.isSelected"
                               (change)="selectDealer($event, dealer)">
                        <label [for]="i" class="custom-control-label"></label>
                    </div>
                </td>
                <td *show="['operator', 'master']">
                    <i
                            class="fas fa-pencil-alt link text-primary mr-2"
                            (click)="showUpdateDealerModal(dealer)"
                    ></i>
                    <i
                            class="fas fa-times-circle link text-danger"
                            [swal]="dealerConfirmDeleteOptions"
                            (confirm)="deleteDealer(dealer)"
                            *ngIf="dealer.deleted === 0"
                    ></i>
                    <i
                            class="fas fa-check-circle link text-success"
                            [swal]="dealerConfirmRestoreOptions"
                            (confirm)="restoreDealer(dealer)"
                            *ngIf="dealer.deleted === 1"
                    ></i>
                </td>
                <td *ngIf="this.activeFields['id']">{{ dealer.id }}</td>
                <td *ngIf="this.activeFields['username']">{{ dealer.username }}</td>
                <td *ngIf="this.activeFields['company_name']">
                    <a [routerLink]="['/dealer', dealer.id]" target="_blank">{{
                        dealer.company_name
                        }}</a>
                </td>
                <td *ngIf="this.activeFields['piva']">{{ dealer.piva }}</td>
                <td *ngIf="this.activeFields['codfiscale']">{{ dealer.codfiscale }}</td>
                <td *ngIf="this.activeFields['company_owner']">
                    {{ dealer.company_owner }}
                </td>
                <td *ngIf="this.activeFields['cellulare']">{{ dealer.cellulare }}</td>
                <td *ngIf="this.activeFields['telefono']">{{ dealer.telefono }}</td>
                <td *ngIf="this.activeFields['email']">{{ dealer.email }}</td>
                <td *ngIf="this.activeFields['pec']">{{ dealer.pec }}</td>
                <td *ngIf="this.activeFields['indirizzo']">{{ dealer.indirizzo }}</td>
                <td *ngIf="this.activeExpand['city']">{{ dealer.city }}</td>
                <td *ngIf="this.activeExpand['province']">{{ dealer.province }}</td>
                <td *ngIf="this.activeExpand['state']">{{ dealer.state }}</td>
                <td *ngIf="this.activeExpand['enabledVendors']" class="text-center">
                    <img
                            *ngFor="let vendor of dealer.enabledVendors"
                            [src]="vendor.smallImage"
                            class="mr-1"
                            height="18px"
                    />
                </td>
                <ng-container *ngIf="this.activeExpand['vendors']">
                    <td *ngFor="let vendor of enabledVendors">
                        {{ getCodeFromRetailer(dealer, vendor) }}
                    </td>
                </ng-container>
                <ng-container *ngIf="this.activeExpand['agents']">
                    <td *ngFor="let vendor of enabledVendors">
                        {{ getAgentFromRetailer(dealer, vendor) }}
                    </td>
                </ng-container>
                <td *ngIf="this.activeFields['iban']">{{ dealer.iban }}</td>
                <td *ngIf="this.activeFields['created']">
                    {{ dealer.created | amDateFormat: "DD/MM/YYYY" }}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer">
    <span
    >Ci sono <b>{{ this.pagination.totalCount }}</b> dealers.
      <a class="link" (click)="exportDealers()"
      >Esporta la lista completa</a
      ></span>
        <ngb-pagination
                size="sm"
                [pageSize]="pagination.pageSize"
                [maxSize]="10"
                [(page)]="pagination.page"
                [collectionSize]="pagination.totalCount"
                (pageChange)="refreshList()"
                [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>

<ng-template #dealerFormTemplate let-modal>
    <div class="modal-header" [ngBusy]="busy">
        <h4 class="modal-title clamp-title">
            {{ dealerModalTitle }}
        </h4>
        <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="dealerForm">
            <div class="row">
                <div class="col-12 col-lg-4" *ngIf="selectedDealer.isNew">
                    <div class="form-group">
                        <label>Username</label>
                        <input
                                [(ngModel)]="selectedDealer.username"
                                formControlName="username"
                                type="text"
                                class="form-control form-control-sm"
                        />
                        <val-errors controlName="username">
                            <ng-template valError="required">
                                L'username è obbligatorio
                            </ng-template>
                            <ng-template valError="invalidAsync">
                                L'username è già esistente
                            </ng-template>
                            <ng-template valError="pattern">
                                <span translate>Whitespace not allowed</span>
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!selectedDealer.isNew">
                <div class="row">
                    <div class="col-6">

                    </div>
                    <div class="col-6 text-right">
                        <a href="javascript:void(0)" class="text-small" (click)="showAllDealerModalCollapse()">mostra
                            tutto</a> /
                        <a href="javascript:void(0)" class="text-small" (click)="hideAllDealerModalCollapse()">nascondi
                            tutto</a>
                    </div>
                </div>
            </ng-container>
            <a href="javascript:void(0)" class="display-6" (click)="collapse.azienda = !collapse.azienda">
                Dati Azienda
                <i class="fas fa-exclamation-circle text-danger" *ngIf="this.dealerFormSectionErrors.azienda"></i>
                <i class="fas float-right link-black pt-2"
                   [ngClass]="{'fa-angle-down': collapse.azienda, 'fa-angle-up': !collapse.azienda}"></i>
            </a>
            <hr>
            <div class="row" [ngbCollapse]="collapse.azienda">
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Ragione Sociale</label>
                        <input
                                [(ngModel)]="selectedDealer.ragsociale"
                                formControlName="ragsociale"
                                type="text"
                                class="form-control form-control-sm"
                        />
                        <val-errors controlName="ragsociale">
                            <ng-template valError="required">
                                La ragione sociale è obbligatoria
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Partita IVA</label>
                        <input
                                [(ngModel)]="selectedDealer.piva"
                                formControlName="piva"
                                type="text"
                                class="form-control form-control-sm"
                        />
                        <val-errors controlName="piva">
                            <ng-template valError="required">
                                La partita iva è obbligatoria
                            </ng-template>
                            <ng-template valError="invalidAsync">
                                La partita iva è già presente. Chiedere al backoffice di
                                riabilitare il negozio.
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Codice SDI</label>
                        <input
                                [(ngModel)]="selectedDealer.codice_sdi"
                                formControlName="codice_sdi"
                                type="text"
                                class="form-control form-control-sm"
                        />
                        <val-errors controlName="codice_sdi">
                            <ng-template valError="required">
                                Il codice SDI è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-8">
                    <div class="form-group">
                        <label>IBAN</label>
                        <input
                                [(ngModel)]="selectedDealer.iban"
                                formControlName="iban"
                                type="text"
                                class="form-control form-control-sm"
                        />
                        <val-errors controlName="iban">
                            <ng-template valError="required">
                                L'iban è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4" *ngIf="!selectedDealer.isNew">
                    <div class="form-group">
                        <label translate>Role</label>
                        <ng-select class="form-control-sm" [(ngModel)]="selectedDealer.role" [clearable]="false"
                                   formControlName="role">
                            <ng-option [value]="dealerRole" *show="['master','master-agent','agent','operator']"
                                       translate>Retailer
                            </ng-option>
                            <ng-option [value]="agentRole" *show="['master','operator']">Key Account</ng-option>
                            <ng-option [value]="masterAgentRole" *show="['master']">Area Manager</ng-option>
                            <ng-option [value]="operatorRole" *show="['master']">Staff</ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
            <a href="javascript:void(0)" class="display-6" (click)="collapse.rappresentante = !collapse.rappresentante">
                Rappresentante dell'azienda
                <i class="fas fa-exclamation-circle text-danger"
                   *ngIf="this.dealerFormSectionErrors.rappresentante"></i>
                <i class="fas float-right link-black pt-2"
                   [ngClass]="{'fa-angle-down': collapse.rappresentante, 'fa-angle-up': !collapse.rappresentante}"></i>
            </a>
            <hr>
            <div class="row" [ngbCollapse]="collapse.rappresentante">
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Nome</label>
                        <input
                                [(ngModel)]="selectedDealer.nome"
                                formControlName="nome"
                                type="text"
                                class="form-control form-control-sm"
                        />
                        <val-errors controlName="nome">
                            <ng-template valError="required">
                                Il nome è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Cognome</label>
                        <input
                                [(ngModel)]="selectedDealer.cognome"
                                formControlName="cognome"
                                type="text"
                                class="form-control form-control-sm"
                        />
                        <val-errors controlName="cognome">
                            <ng-template valError="required">
                                Il cognome è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Codice Fiscale</label>
                        <input
                                [(ngModel)]="selectedDealer.codfiscale"
                                formControlName="codfiscale"
                                type="text"
                                class="form-control form-control-sm"
                        />
                        <val-errors controlName="codfiscale">
                            <ng-template valError="required">
                                Il codice fiscale è obbligatorio
                            </ng-template>
                            <ng-template valError="pattern">
                                Il codice fiscale non è del formato corretto
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
            </div>
            <a href="javascript:void(0)" class="display-6" (click)="collapse.sede = !collapse.sede">
                Sede
                <i class="fas fa-exclamation-circle text-danger" *ngIf="this.dealerFormSectionErrors.sede"></i>
                <i class="fas float-right link-black pt-2"
                   [ngClass]="{'fa-angle-down': collapse.sede, 'fa-angle-up': !collapse.sede}"></i>
            </a>
            <hr>
            <div class="row" [ngbCollapse]="collapse.sede">
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Provincia</label>
                        <app-province-select
                                [small]="true"
                                [province]="selectedDealer.provincia"
                                (onChange)="this.dealerForm.patchValue({ provincia: $event })"
                        ></app-province-select>
                        <val-errors controlName="provincia">
                            <ng-template valError="required">
                                La provincia è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Comune</label>
                        <app-city-select
                                [province]="this.dealerForm.get('provincia').value"
                                [onDemand]="true"
                                [small]="true"
                                [city]="selectedDealer.localita"
                                (onChange)="this.dealerForm.patchValue({ localita: $event })"
                        ></app-city-select>
                        <val-errors controlName="localita">
                            <ng-template valError="required">
                                Il comune è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Indirizzo</label>
                        <input
                                [(ngModel)]="selectedDealer.indirizzo"
                                formControlName="indirizzo"
                                type="text"
                                class="form-control form-control-sm"
                        />
                        <val-errors controlName="indirizzo">
                            <ng-template valError="required">
                                L'indirizzo è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>CAP</label>
                        <input
                                [(ngModel)]="selectedDealer.cap"
                                formControlName="cap"
                                type="text"
                                class="form-control form-control-sm"
                        />
                        <val-errors controlName="cap">
                            <ng-template valError="required">
                                Il cap è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4" *ngIf="selectedDealer.role == agentRole">
                    <div class="form-group">
                        <label translate>Car code</label>
                        <input
                                [(ngModel)]="selectedDealer.car_code"
                                formControlName="car_code"
                                type="text"
                                class="form-control form-control-sm"
                        />
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!selectedDealer.isNew" formArrayName="vendors">
                <ng-container
                        *ngFor="let vendor of selectedDealer.vendors; let i = index"
                >
                    <a href="javascript:void(0)" class="display-6"
                       (click)="collapse.operators[i] = !collapse.operators[i]">{{ vendor.vendor_name }}</a>
                    <hr>
                    <div class="row" [formGroupName]="i" [ngbCollapse]="collapse.operators[i]">
                        <div class="col-12 col-lg-4">
                            <div class="form-group">
                                <label>Abilitato</label>
                                <select
                                        formControlName="id_dealer"
                                        [(ngModel)]="selectedDealer.vendors[i].id_dealer"
                                        class="form-control form-control-sm"
                                >
                                    <option [value]="null">No</option>
                                    <option [value]="selectedDealer.id">Si</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="form-group">
                                <label>Key Account</label>
                                <app-agent-select
                                        [small]="true"
                                        [disabled]="!selectedDealer.vendors[i].id_dealer"
                                        [agent]="selectedDealer.vendors[i].id_agente"
                                        [vendors]="[selectedDealer.vendors[i].id_operator]"
                                        (onChange)="setVendorAgentOnFormArray(i, $event)"
                                >
                                </app-agent-select>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="form-group">
                                <label>Codice {{ vendor.vendor_name }}</label>
                                <input
                                        [(ngModel)]="selectedDealer.vendors[i].code"
                                        [disabled]="!selectedDealer.vendors[i].id_dealer"
                                        formControlName="code"
                                        type="text"
                                        class="form-control form-control-sm"
                                />
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <a href="javascript:void(0)" class="display-6" (click)="collapse.altro = !collapse.altro">
                Altro
                <i class="fas fa-exclamation-circle text-danger" *ngIf="this.dealerFormSectionErrors.altro"></i>
                <i class="fas float-right link-black pt-2"
                   [ngClass]="{'fa-angle-down': collapse.altro, 'fa-angle-up': !collapse.altro}"></i>
            </a>
            <hr>
            <div class="row" [ngbCollapse]="collapse.altro">
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>E-Mail</label>
                        <input
                                [(ngModel)]="selectedDealer.email"
                                formControlName="email"
                                type="text"
                                class="form-control form-control-sm"
                        />
                        <val-errors controlName="email">
                            <ng-template valError="required">
                                L'email è obbligatoria
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Telefono</label>
                        <input
                                [(ngModel)]="selectedDealer.telefono"
                                formControlName="telefono"
                                type="text"
                                class="form-control form-control-sm"
                        />
                        <val-errors controlName="telefono">
                            <ng-template valError="required">
                                Il telefono è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                        <label>Cellulare</label>
                        <input
                                [(ngModel)]="selectedDealer.cellulare"
                                formControlName="cellulare"
                                type="text"
                                class="form-control form-control-sm"
                        />
                        <val-errors controlName="cellulare">
                            <ng-template valError="required">
                                Il cellulare è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!selectedDealer.isNew">
                <a href="javascript:void(0)" class="display-6" (click)="collapse.fatturazione = !collapse.fatturazione">
                    Dati Fatturazione
                    <i class="fas fa-exclamation-circle text-danger"
                       *ngIf="this.dealerFormSectionErrors.fatturazione"></i>
                    <i class="fas float-right link-black pt-2"
                       [ngClass]="{'fa-angle-down': collapse.fatturazione, 'fa-angle-up': !collapse.fatturazione}"></i>
                </a>
                <hr>
                <div class="row" [ngbCollapse]="collapse.fatturazione">
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>Stato Freeinvoice</label>
                            <select
                                    formControlName="stato_freeinvoice"
                                    [(ngModel)]="selectedDealer.stato_freeinvoice"
                                    class="form-control form-control-sm"
                            >
                                <option value="0">Profilo Incompleto</option>
                                <option value="1">E-Mail Errata</option>
                                <option value="2">C.F./P.IVA/PEC errata</option>
                                <option value="3">Conferma PEC</option>
                                <option value="4">Procedura Completata</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>Tipo Ritenuta</label>
                            <ng-select [items]="options.tipoRitenuta"
                                       formControlName="tipo_ritenuta"
                                       [(ngModel)]="selectedDealer.tipo_ritenuta"
                                       class="form-control-sm">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>Causale Ritenuta</label>
                            <ng-select [items]="options.causaleRitenuta"
                                       formControlName="causale_ritenuta"
                                       [(ngModel)]="selectedDealer.causale_ritenuta"
                                       class="form-control-sm">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>Regime Fiscale</label>
                            <ng-select [items]="options.regimeFiscale"
                                       formControlName="regime_fiscale"
                                       [(ngModel)]="selectedDealer.regime_fiscale"
                                       class="form-control-sm">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>Tipologia Società</label>
                            <ng-select [items]="options.tipoSocieta"
                                       formControlName="tipologia_societa"
                                       [(ngModel)]="selectedDealer.tipologia_societa"
                                       class="form-control-sm">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="form-group">
                            <label>PEC</label>
                            <input
                                    [(ngModel)]="selectedDealer.pec"
                                    formControlName="pec"
                                    type="text"
                                    class="form-control form-control-sm"
                            />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Api Key Free Invoice</label>
                            <input
                                    [(ngModel)]="selectedDealer.fi_api_key"
                                    formControlName="fi_api_key"
                                    type="text"
                                    class="form-control form-control-sm"
                            />
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="mode === 'retailer'">
                    <a href="javascript:void(0)" class="display-6"
                       (click)="collapse.kdricariche = !collapse.kdricariche">
                        KDRicariche
                        <i class="fas float-right link-black pt-2"
                           [ngClass]="{'fa-angle-down': collapse.kdricariche, 'fa-angle-up': !collapse.kdricariche}"></i>
                    </a>
                    <hr>
                    <div [ngbCollapse]="collapse.kdricariche">
                        <div class="row" formGroupName="shopAccess">
                            <div class="col-12 col-lg-4">
                                <div class="form-group">
                                    <label>Può accedere dal</label>
                                    <input
                                            [(ngModel)]="shopAccess.from"
                                            placeholder="gg-mm-aaaa"
                                            ngbDatepicker
                                            formControlName="from"
                                            #from="ngbDatepicker"
                                            (click)="from.toggle()"
                                            type="text"
                                            class="form-control form-control-sm"
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="form-group">
                                    <label>Al</label>
                                    <input
                                            [(ngModel)]="shopAccess.to"
                                            type="text"
                                            placeholder="gg-mm-aaaa"
                                            ngbDatepicker
                                            formControlName="to"
                                            #to="ngbDatepicker"
                                            (click)="to.toggle()"
                                            class="form-control form-control-sm"
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <label>&nbsp;</label>
                                <button class="btn btn-success btn-block btn-sm"
                                        [disabled]="!this.shopAccess.from || !this.shopAccess.to"
                                        (click)="saveShopAccess()">Salva
                                </button>
                            </div>
                        </div>
                        <div class="row" *ngFor="let access of this.selectedDealer.shopAccesses">
                            <div class="col-12 col-lg-4">
                                <div class="form-group">
                                    <label>Può accedere dal</label>
                                    <input
                                            [value]="access.from | amDateFormat: 'DD-MM-YYYY'"
                                            type="text"
                                            [attr.disabled]="true"
                                            class="form-control form-control-sm"
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="form-group">
                                    <label>Al</label>
                                    <input
                                            [value]="access.to | amDateFormat: 'DD-MM-YYYY'"
                                            type="text"
                                            class="form-control form-control-sm"
                                            [attr.disabled]="true"
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <label>&nbsp;</label>
                                <button class="btn btn-danger btn-block btn-sm" (click)="deleteShopAccess(access.id)">
                                    Cancella
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Note</label>
                        <textarea
                                [(ngModel)]="selectedDealer.note"
                                formControlName="note"
                                class="form-control form-control-sm"
                                rows="5"
                        ></textarea>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <button
                            class="btn btn-success btn-block"
                            [disabled]="!this.dealerForm.valid"
                            (click)="saveDealer(selectedDealer)"
                    >
                        <i class="fa fa-check-circle mr-2"></i> Salva
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #campaignFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate> Create Campaign</h4>
        <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-pbx-campaign-modal [dealerIds]="selectedDealersForCampaign" (createdCampaign)="handleSavedCampaign($event)"
                            [filters]="selectedFilters">
    </app-pbx-campaign-modal>
</ng-template>
