import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sms-sales',
  templateUrl: './sms-sales.component.html',
  styleUrls: ['./sms-sales.component.css']
})
export class SmsSalesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
