import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '@app/services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {interval, Subscription} from 'rxjs';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    public loginForm: FormGroup;
    returnUrl: string;
    loading = false;
    submitted = false;
    busy: Subscription;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
    }

    ngOnInit() {
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }

        this.renderer.addClass(document.body, 'login-page');

        this.loginForm = new FormGroup({
            email: new FormControl(null, [Validators.required]),
            password: new FormControl(null, [Validators.required]),
        });

        this.returnUrl = '/';
    }

    get f() {
        return this.loginForm.controls;
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.busy = this.authenticationService
            .login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                (data) => {
                    if (data?.loggedIn && data?.profile?.is_psw_expired) {
                        this.router.navigate(['/change-password']);
                    } else {
                        this.authenticationService.listenForUpdates();
                        this.router.navigate(['/']);
                    }
                },
                (error) => {
                    this.loading = false;
                    this.toastr.error(
                        error,
                        'Errore'
                    );
                }
            );
    }

    ngOnDestroy() {
        this.renderer.removeClass(document.body, 'login-page');
    }
}
