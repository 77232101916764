import {Component, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InvoiceDocumentService} from '@services/invoice-document.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {DocumentCategory, DocumentChart, DocumentDashboard, DocumentIncomes, DocumentOutcomes} from '@models/invoice/document';
import * as moment from 'moment';
import {chunk} from 'lodash';


@Component({
    selector: 'app-invoice-dashboard',
    templateUrl: './invoice-dashboard.component.html',
    styleUrls: ['./invoice-dashboard.component.css']
})
export class InvoiceDashboardComponent implements OnInit {
    Highcharts: typeof Highcharts = Highcharts;
    chartOptions: Highcharts.Options = {
        xAxis: {
            categories: [],
        },
        yAxis: {
            title: {
                text: 'Totale'
            }
        },
        chart: {
            width: 750,
            height: 300,
            marginTop: 50
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
    };
    revenueOptions: Highcharts.Options;
    netOptions: Highcharts.Options;
    incomesOptions: Highcharts.Options;
    outcomesOptions: Highcharts.Options;
    categoryCostOptions: Highcharts.Options;
    categoryInvoiceOptions: Highcharts.Options;
    dashboardReport: DocumentDashboard = new DocumentDashboard(
        {
            month_total_cost: '',
            month_total_net: '',
            total_cost: '',
            total_net: '',
            customer_total: '',
            supplier_total: '',
            profitability_index: 0
        }
    );
    currency = InvoiceDocumentService.currency;
    incomes: DocumentIncomes[] = [];
    outcomes: DocumentOutcomes[] = [];
    isChartLoaded = false;
    filter = {
        date: '',
        category: '',
        chart_date: ''
    };
    selectedDateRange = {
        date_from: '',
        date_to: '',
        chart_date_from: '',
        chart_date_to: ''
    };
    localMonthFormat = 'MMM YYYY';
    defaultMonthRange = [];
    defaultChartMonthRange = [moment().subtract(11, 'month').startOf('month'), moment().subtract(0, 'month').endOf('month')];
    categories: DocumentCategory[] = [];
    categoryLoading = false;

    constructor(
        private modalService: NgbModal,
        private invoiceDocumentService: InvoiceDocumentService,
        private spinner: NgxSpinnerService,
        private dateRangePickerOptions: DaterangepickerConfig) {
        this.loadDateRange();
    }

    ngOnInit(): void {
        this.loadData();
        this.loadCategories();
    }

    loadDashboard() {
        this.spinner.show('dashboard');
        const params = {};
        if (this.selectedDateRange.date_from) {
            params['from'] = this.selectedDateRange.date_from;
        }
        if (this.selectedDateRange.date_to) {
            params['to'] = this.selectedDateRange.date_to;
        }
        if (this.filter.category) {
            params['category'] = this.filter.category;
        }
        this.invoiceDocumentService.getDashboardReport(params).subscribe((response: DocumentDashboard) => {
            this.dashboardReport = response;
            this.spinner.hide('dashboard');
        }, err => {
            this.spinner.hide('dashboard');
        });
    }

    loadChart() {
        this.spinner.show('chart');
        const params = {};
        if (this.selectedDateRange.chart_date_from) {
            params['from'] = this.selectedDateRange.chart_date_from;
        }
        if (this.selectedDateRange.chart_date_to) {
            params['to'] = this.selectedDateRange.chart_date_to;
        }
        if (this.filter.category) {
            params['category'] = this.filter.category;
        }

        this.invoiceDocumentService.getChartReport(params).subscribe((response: DocumentChart) => {
            const dates = this.getChartDates(response.dates);

            this.categoryCostOptions = {
                ...this.chartOptions,
                chart: {
                    width: 750,
                    height: 400,
                    marginTop: 0
                },
                series: [{
                    name: 'Costi',
                    type: 'pie',
                    data: response.costsByCategory,
                }],
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            formatter: function() {
                                if (this.y > 1000000) {
                                    return Highcharts.numberFormat(this.y / 1000000, 3) + 'M';
                                } else if (this.y > 1000) {
                                    return this.key + " " + Highcharts.numberFormat(this.y / 1000, 0) + 'K';
                                }  else {
                                    return this.y;
                                }
                            }
                        }
                    }
                },
            };

            this.categoryInvoiceOptions = {
                ...this.chartOptions,
                series: [{
                    name: 'Ricavi',
                    type: 'pie',
                    data: response.invoicesByCategory,
                }],
                chart: {
                    width: 750,
                    height: 400,
                    marginTop: 0
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            formatter: function() {
                                if (this.y > 1000000) {
                                    return Highcharts.numberFormat(this.y / 1000000, 3) + 'M';
                                } else if (this.y > 1000) {
                                    return this.key + " " + Highcharts.numberFormat(this.y / 1000, 0) + 'K';
                                }  else {
                                    return this.y;
                                }
                            }
                        }
                    }
                },
            };

            this.revenueOptions = {
                ...this.chartOptions,
                series: [{
                    name: 'Costi',
                    data: response.costs,
                    type: 'column',
                    color: 'rgb(244,91,91)',
                    // fillColor: 'rgba(244, 91, 91, 0.3)'
                }, {
                    name: 'Fatturato',
                    data: response.invoices,
                    type: 'column',
                    color: 'rgb(0,166,73)',
                    // fillColor: 'rgba(124, 181, 236, 0.3)',
                }
                ],
                xAxis: {
                    categories: dates
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            formatter: function() {
                                if (this.y > 1000000) {
                                    return Highcharts.numberFormat(this.y / 1000, 3) + 'M';
                                } else if (this.y > 100000) {
                                    return Highcharts.numberFormat(this.y / 1000, 0) + 'K';
                                } else if (this.y > 1000) {
                                    return Highcharts.numberFormat(this.y / 1000, 3) + 'K';
                                } else {
                                    return this.y;
                                }
                            }
                        }
                    }
                },
            };
            this.netOptions = {
                ...this.chartOptions,
                series: [{
                    name: 'Netto',
                    data: response.net,
                    type: 'column',
                    color: 'rgba(67, 67, 72, 1)',
                    // fillColor: 'rgba(67, 67, 72, 0.3)'
                }],
                xAxis: {
                    categories: dates
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            formatter: function() {
                                if (this.y > 1000000 || this.y < -1000000) {
                                    return Highcharts.numberFormat(this.y / 1000, 3) + 'M';
                                } else if (this.y > 1000 || this.y < -1000) {
                                    return Highcharts.numberFormat(this.y / 1000, 0) + 'K';
                                } else {
                                    return this.y;
                                }
                            }
                        }
                    }
                },
            };
            this.incomesOptions = {
                ...this.chartOptions,
                series: [{
                    name: 'Entrate Bancarie',
                    data: response.incomes,
                    type: 'column',
                    color: 'rgba(144, 237, 125)',
                    // fillColor: 'rgba(144, 237, 125, 0.3)'
                }],
                xAxis: {
                    categories: dates
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            formatter: function() {
                                if (this.y > 1000000 || this.y < -1000000) {
                                    return Highcharts.numberFormat(this.y / 1000, 3) + 'M';
                                } else if (this.y > 100000 || this.y < -100000) {
                                    return Highcharts.numberFormat(this.y / 1000, 0) + 'K';
                                } else if (this.y > 1000 || this.y < -1000) {
                                    return Highcharts.numberFormat(this.y / 1000, 3) + 'K';
                                } else {
                                    return this.y;
                                }
                            }
                        }
                    }
                },
            };
            this.outcomesOptions = {
                ...this.chartOptions,
                series: [{
                    name: 'Uscite Bancarie',
                    data: response.outcomes,
                    type: 'column',
                    color: 'rgba(244, 91, 91, 1)',
                    // fillColor: 'rgba(244, 91, 91, 0.3)'
                }],
                xAxis: {
                    categories: dates
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            formatter: function() {
                                if (this.y > 1000000 || this.y < -1000000) {
                                    return Highcharts.numberFormat(this.y / 1000, 3) + 'M';
                                } else if (this.y > 100000 || this.y < -100000) {
                                    return Highcharts.numberFormat(this.y / 1000, 0) + 'K';
                                } else if (this.y > 1000 || this.y < -1000) {
                                    return Highcharts.numberFormat(this.y / 1000, 3) + 'K';
                                } else {
                                    return this.y;
                                }
                            }
                        }
                    }
                },
            };
            this.isChartLoaded = true;
            this.spinner.hide('chart');
        }, err => {
            this.spinner.hide('chart');
        });
    }

    loadIncomesReport() {
        this.spinner.show('table-incomes');
        const params = {};
        if (this.selectedDateRange.date_from) {
            params['from'] = this.selectedDateRange.date_from;
        }
        if (this.selectedDateRange.date_to) {
            params['to'] = this.selectedDateRange.date_to;
        }
        if (this.filter.category) {
            params['category'] = this.filter.category;
        }
        this.invoiceDocumentService.getInvoiceReport(params).subscribe((response: DocumentIncomes[]) => {
            this.incomes = response;
            this.spinner.hide('table-incomes');
        }, err => {
            this.spinner.hide('table-incomes');
        });
    }

    loadOutcomesReport() {
        this.spinner.show('table-outcomes');
        const params = {};
        if (this.selectedDateRange.date_from) {
            params['from'] = this.selectedDateRange.date_from;
        }
        if (this.selectedDateRange.date_to) {
            params['to'] = this.selectedDateRange.date_to;
        }
        if (this.filter.category) {
            params['category'] = this.filter.category;
        }
        this.invoiceDocumentService.getCashOutReport(params).subscribe((response: DocumentOutcomes[]) => {
            this.outcomes = response;
            this.spinner.hide('table-outcomes');
        }, err => {
            this.spinner.hide('table-outcomes');
        });
    }

    getChartDates(dates) {
        const list = [];
        dates.forEach((date) => {
            list.push(moment(date).format('MMM YYYY'));
        });
        return list;
    }

    loadData() {
        this.loadDashboard();
        setTimeout(() => {
            this.loadChart();
            this.loadIncomesReport();
            this.loadOutcomesReport();
        });
    }

    setSelectedDateRange(from: any, to: any, key) {
        const start = moment(from);
        const end = moment(to);
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        this.selectedDateRange[key + '_from'] = start.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.selectedDateRange[key + '_to'] = end.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.filter[key] = start.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator
            + end.format(localDateFormat);
    }

    calculateTotal(items, key) {
        let sum = 0;
        items.forEach(item => {
            sum += Number(item[key]);
        });
        return sum;
    }

    loadDateRange() {
        const list = [...Array(13).keys()];
        list.splice(0, 1);
        const year = moment().format('YYYY');
        const currentMonth = moment().format('M');
        const ranges = chunk(list, 3);
        let startDate;
        let endDate;
        ranges.forEach(item => {
            if (item.find(x => x === Number(currentMonth))) {
                const date = year + '-' + item[0] + '-' + '01';
                startDate = moment(new Date(date));
                endDate = moment(new Date(date)).add(2, 'M');
                this.defaultMonthRange = [startDate, endDate];
                this.setSelectedDateRange(startDate, endDate, 'date');
            }
        });
    }

    loadCategories(term?) {
        this.categoryLoading = true;
        const params = {};
        if (term) {
            params['name'] = term;
        }
        this.invoiceDocumentService.getDocumentCategories(params).subscribe((response: DocumentCategory[]) => {
            this.categories = response;
            this.categoryLoading = false;
        }, err => {
            this.categoryLoading = false;
        });
    }
}
