<section class="content-header">
    <div class="row mb-2">
        <div class="col-sm-6">
            <h1>
                Dettaglio Compensi
            </h1>
        </div>
        <div class="col-sm-6">
            <button class="float-right btn btn-secondary" [routerLink]="['/fatturazione']"><i
                    class="fa fa-arrow-left mr-1"></i> Torna alla lista
            </button>
        </div>
    </div>
</section>

<div class="row" [ngBusy]="busy">
    <div class="col-sm-12">
        <div class="callout callout-info">
            <h5><i class="fas fa-info-circle"></i> Nota:</h5>
            Questa è solo un'anteprima dei compensi e non costituisce un documento fiscale.
            <strong>Per emettere fattura e ricevere i compensi segui le istruzioni a destra.</strong>
        </div>
    </div>

    <div class="col-xl-8 col-lg-8 col-sm-12">
        <div class="invoice p-3 mb-3">
            <div class="ribbon-wrapper ribbon-xl" *ngIf="invoice.status_code === 1">
                <div class="ribbon bg-success text-lg">
                    Confermata
                </div>
            </div>
            <div class="ribbon-wrapper ribbon-xl" *ngIf="invoice.status_code === 2">
                <div class="ribbon bg-danger text-lg">
                    Rifiutata
                </div>
            </div>
            <!-- title row -->
            <div class="row">
                <div class="col-12">
                    <h4>
                        <i class="fas fa-list"></i> Dettaglio Compensi
                        <!--                        <small class="float-right">Data: {{ invoice.created | date: 'dd/MM/yyyy' }}</small>-->
                    </h4>
                </div>
                <!-- /.col -->
            </div>
            <!-- info row -->
            <div class="row invoice-info">
                <div class="col-sm-4 invoice-col">
                    Prestatore
                    <address>
                        <strong>{{ profile.ragione_sociale }}</strong><br>
                        {{ profile.sede_indirizzo }}<br>
                        {{ profile.sede_comune }}, {{ profile.sede_provincia }} - {{ profile.sede_cap }}<br>
                        <!--            SDI: {{ me.codice_sdi }}<br>-->
                        <!--            PEC: {{ me.fecustomerpec }}-->
                    </address>
                </div>
                <!-- /.col -->
                <div class="col-sm-4 invoice-col">
                    Committente
                    <address>
                        <strong>{{invoice.config?.company_name}}</strong><br>
                        {{invoice?.config?.address}}<br>
                        {{invoice?.config?.city}}, {{invoice?.config?.province}} - {{invoice?.config?.zipcode}}<br>
                        P.IVA : {{invoice?.config?.vat_number}}<br>
                        SDI: {{invoice?.config?.sdi_code}}<br>
                        PEC: {{invoice?.config?.email}}
                    </address>
                </div>
                <!-- /.col -->
                <div class="col-sm-4 invoice-col">
                    <b>ID Fattura {{invoice.id}}</b><br>
                    <br>
                    <!--                    <b>Pagamento:</b> 30gg data fattura<br>-->
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->

            <!-- Table row -->
            <div class="row">
                <div class="col-12 table-responsive">
                    <table class="table table-striped table-sm">
                        <thead>
                        <tr>
                            <th class="text-center"></th>
                            <th class="text-center">Q.tà</th>
                            <th>Nome</th>
                            <th>Descrizione</th>
                            <!--              <th class="text-center">Iva</th>-->
                            <th class="text-right">Importo</th>
                            <ng-container *ngIf="invoice.is_old === 0">
                                <th class="text-right">IVA</th>
                                <th class="text-right">Ritenuta</th>
                                <th class="text-right">Totale</th>
                            </ng-container>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let lineItem of invoice.voci; let i=index;">
                            <tr>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-bold">{{ lineItem.tipo }}</td>
                                <td class="text-bold">Periodo {{ lineItem.mese }} {{ lineItem.anno }}</td>
                                <!--              <td class="text-center">{{ lineItem.tax1 | number }}%</td>-->
                                <td class="text-right text-bold">{{ lineItem.totale | currency: '€ '}}</td>
                                <ng-container *ngIf="invoice.is_old === 0">
                                    <td class="text-right">{{lineItem.iva | currency: '€ '}}</td>
                                    <td class="text-right">{{lineItem.ritenuta | currency: '€ '}}</td>
                                    <td class="text-right text-bold">{{lineItem.netto | currency: '€ '}}</td>
                                </ng-container>
                            </tr>
                            <ng-container *ngFor="let group of lineItem.groups">
                                <tr>
                                    <th class="text-center">
                                        <i class="fas cursor-hand"
                                           (click)="group.show = !group.show"
                                           [ngClass]="{'fa-plus-circle text-info': !group.show, 'fa-minus-circle text-dark': group.show}"></i>
                                    </th>
                                    <th class="text-center">{{group.size}}</th>
                                    <td>{{ group.offerta }}</td>
                                    <td></td>
                                    <td class="text-right">{{ group.totale | currency: '€ '}}</td>
                                    <ng-container *ngIf="invoice.is_old === 0">
                                        <td class="text-right"></td>
                                        <td class="text-right"></td>
                                        <td class="text-right text-bold"></td>
                                    </ng-container>
                                </tr>
                                <ng-container *ngIf="group.show">
                                    <tr *ngFor="let dettaglio of group.lista">
                                        <td></td>
                                        <td></td>
                                        <td>{{ dettaglio.info }}</td>
                                        <td>{{ dettaglio.tipo }}</td>
                                        <td class="text-right">{{ dettaglio.compenso | currency: '€ '}}</td>
                                        <ng-container *ngIf="invoice.is_old === 0">
                                            <td class="text-right"></td>
                                            <td class="text-right"></td>
                                            <td class="text-right text-bold"></td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        </tbody>
                    </table>
                    <ng-container *ngIf="invoice.gara">
                        <hr>
                        <blockquote [innerHTML]="invoice.gara | safeHtml"></blockquote>
                    </ng-container>
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->
            <!-- /.row -->
        </div>
    </div>

    <ng-template #confirmModal let-modal class="bg-gradient-white">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Conferma compensi</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>
                Confermando i compensi ci autorizzi ad emettere fattura per conto tuo solo ed esclusivamente a Master
                Group.
            </p>
            <p>
                Ti riepiloghiamo i dati che verranno utilizzati per emettere la fattura:
            </p>
            <table class="table table-sm mt-3">
                <tr>
                    <th>Tipo Documento</th>
                    <td class="text-right">{{invoice.tipo_documento}}</td>
                </tr>
                <tr>
                    <th>Metodo di pagamento</th>
                    <td class="text-right">{{invoice.metodo_pagamento}}</td>
                </tr>
                <tr>
                    <th>Condizioni di pagamento</th>
                    <td class="text-right">{{invoice.condizioni_pagamento}}</td>
                </tr>
                <tr>
                    <th>Totale Documento</th>
                    <td class="text-right">{{invoice.totale | currency: '€ '}}</td>
                </tr>
                <tr>
                    <th>Totale IVA</th>
                    <td class="text-right">{{invoice.importo_iva | currency: '€ '}}</td>
                </tr>
                <tr>
                    <th *ngIf="profile.regime_fiscale === 'RF01 - Ordinario'">Totale Ritenuta (23% su 50%)</th>
                    <th *ngIf="profile.regime_fiscale === 'RF01 - Ordinario 20%'">Totale Ritenuta (23% su 20%)</th>
                    <td class="text-right">{{invoice.importo_ritenuta | currency: '€ '}}</td>
                </tr>
                <tr>
                    <th>Totale a Pagare</th>
                    <td class="text-right">{{invoice.importo_netto | currency: '€ '}}</td>
                </tr>
            </table>
            <p>La fattura sarà inviata per tuo conto con i seguenti dati:</p>
            <table class="table table-sm  mt-3">
                <tr>
                    <th>Ragione Sociale</th>
                    <td class="text-right">{{profile.ragione_sociale}}</td>
                </tr>
                <tr>
                    <th>Partita IVA</th>
                    <td class="text-right">{{profile.partita_iva}}</td>
                </tr>
                <tr>
                    <th>IBAN</th>
                    <td class="text-right">{{profile.iban}}</td>
                </tr>
                <tr>
                    <th>Codice Fiscale</th>
                    <td class="text-right">{{profile.codfiscale}}</td>
                </tr>
                <tr>
                    <th>Codice SDI</th>
                    <td class="text-right">{{profile.codice_sdi}}</td>
                </tr>
                <tr>
                    <th>Regime Fiscale</th>
                    <td class="text-right">{{profile.regime_fiscale}}</td>
                </tr>
                <tr>
                    <th>Tipo Ritenuta</th>
                    <td class="text-right">{{profile.tipo_ritenuta}}</td>
                </tr>
                <tr>
                    <th>Causale Ritenuta</th>
                    <td class="text-right">{{profile.causale_ritenuta}}</td>
                </tr>
                <tr>
                    <th>Tipo Società</th>
                    <td class="text-right">{{profile.tipologia_societa}}</td>
                </tr>
            </table>
            <p>Se i dati non sono corretti, rifiuta i compensi e apri un ticket.</p>
            <!--            <form [formGroup]="accountForm">-->
            <!--                <div class="row">-->
            <!--                    <div class="col-xl-4 col-lg-6 col-sm-12">-->
            <!--                        <div class="form-group">-->
            <!--                            <label class="lead">Ragione Sociale</label>-->
            <!--                            <input type="text" formControlName="accountname" class="form-control"/>-->
            <!--                            <val-errors controlName="accountname">-->
            <!--                                <ng-template valError="required">-->
            <!--                                    La ragione sociale è obbligatoria-->
            <!--                                </ng-template>-->
            <!--                            </val-errors>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <div class="col-xl-4 col-lg-6 col-sm-12">-->
            <!--                        <div class="form-group">-->
            <!--                            <label class="lead">Partita IVA</label>-->
            <!--                            <input type="text" formControlName="partita_iva" class="form-control"/>-->
            <!--                            <val-errors controlName="partita_iva">-->
            <!--                                <ng-template valError="required">-->
            <!--                                    La partita iva è obbligatoria-->
            <!--                                </ng-template>-->
            <!--                                <ng-template valError="maxLength">-->
            <!--                                    La partita IVA dev'essere di 11 caratteri-->
            <!--                                </ng-template>-->
            <!--                                <ng-template valError="minLength">-->
            <!--                                    La partita IVA dev'essere di 11 caratteri-->
            <!--                                </ng-template>-->
            <!--                            </val-errors>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <div class="col-xl-4 col-lg-6 col-sm-12">-->
            <!--                        <div class="form-group">-->
            <!--                            <label class="lead">Codice SDI</label>-->
            <!--                            <input type="text" formControlName="codice_sdi" class="form-control"/>-->
            <!--                            <val-errors controlName="codice_sdi">-->
            <!--                                <ng-template valError="required">-->
            <!--                                    Il codice SDI è obbligatorio-->
            <!--                                </ng-template>-->
            <!--                                <ng-template valError="maxLength">-->
            <!--                                    Il codice SDI dev'essere di 7 caratteri-->
            <!--                                </ng-template>-->
            <!--                                <ng-template valError="minLength">-->
            <!--                                    Il codice SDI dev'essere di 7 caratteri-->
            <!--                                </ng-template>-->
            <!--                            </val-errors>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <div class="col-xl-4 col-lg-6 col-sm-12">-->
            <!--                        <div class="form-group">-->
            <!--                            <label class="lead">Regime Fiscale</label>-->
            <!--                            &lt;!&ndash;              <select2 formControlName="fe_regime_fiscale"&ndash;&gt;-->
            <!--                            &lt;!&ndash;                       (valueChanged)="this.c().fe_regime_fiscale.setValue($event.value)">&ndash;&gt;-->
            <!--                            &lt;!&ndash;                <option selected>Seleziona...</option>&ndash;&gt;-->
            <!--                            &lt;!&ndash;                <option *ngFor="let option of this.picklists.fe_regime_fiscale" [value]="option.value">{{option.label}}</option>&ndash;&gt;-->
            <!--                            &lt;!&ndash;              </select2>&ndash;&gt;-->
            <!--                            <val-errors controlName="fe_regime_fiscale">-->
            <!--                                <ng-template valError="required">-->
            <!--                                    Il regime fiscale è obbligatorio-->
            <!--                                </ng-template>-->
            <!--                            </val-errors>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <div class="col-xl-4 col-lg-6 col-sm-12">-->
            <!--                        <div class="form-group">-->
            <!--                            <label class="lead">Esigibilità IVA</label>-->
            <!--                            &lt;!&ndash;              <select2 formControlName="vattype"&ndash;&gt;-->
            <!--                            &lt;!&ndash;                       (valueChanged)="this.c().vattype.setValue($event.value)">&ndash;&gt;-->
            <!--                            &lt;!&ndash;                <option selected>Seleziona...</option>&ndash;&gt;-->
            <!--                            &lt;!&ndash;                <option *ngFor="let option of this.picklists.vattype" [value]="option.value">{{option.label}}</option>&ndash;&gt;-->
            <!--                            &lt;!&ndash;              </select2>&ndash;&gt;-->
            <!--                            <val-errors controlName="vattype">-->
            <!--                                <ng-template valError="required">-->
            <!--                                    L'esigibilità dell'IVA è obbligatoria-->
            <!--                                </ng-template>-->
            <!--                            </val-errors>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <div class="col-xl-4 col-lg-6 col-sm-12">-->
            <!--                        <div class="form-group">-->
            <!--                            <label class="lead">Tipo di Ritenuta</label>-->
            <!--                            &lt;!&ndash;              <select2 formControlName="fe_tipo_ritenuta"&ndash;&gt;-->
            <!--                            &lt;!&ndash;                       (valueChanged)="this.c().fe_tipo_ritenuta.setValue($event.value)">&ndash;&gt;-->
            <!--                            &lt;!&ndash;                <option selected>Seleziona...</option>&ndash;&gt;-->
            <!--                            &lt;!&ndash;                <option *ngFor="let option of this.picklists.fe_tipo_ritenuta" [value]="option.value">{{option.label}}</option>&ndash;&gt;-->
            <!--                            &lt;!&ndash;              </select2>&ndash;&gt;-->
            <!--                            <val-errors controlName="fe_tipo_ritenuta">-->
            <!--                                <ng-template valError="required">-->
            <!--                                    Il tipo di ritenuta è obbligatoria-->
            <!--                                </ng-template>-->
            <!--                            </val-errors>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <div class="col-xl-4 col-lg-6 col-sm-12">-->
            <!--                        <div class="form-group">-->
            <!--                            <label class="lead">Causale Ritenuta</label>-->

            <!--                            &lt;!&ndash;              <select2 formControlName="fe_causale_ritenuta"&ndash;&gt;-->
            <!--                            &lt;!&ndash;                       (valueChanged)="this.c().fe_causale_ritenuta.setValue($event.value)">&ndash;&gt;-->
            <!--                            &lt;!&ndash;                <option selected>Seleziona...</option>&ndash;&gt;-->
            <!--                            &lt;!&ndash;                <option *ngFor="let option of this.picklists.fe_causale_ritenuta" [value]="option.value">{{option.label}}</option>&ndash;&gt;-->
            <!--                            &lt;!&ndash;              </select2>&ndash;&gt;-->
            <!--                            <val-errors controlName="fe_causale_ritenuta">-->
            <!--                                <ng-template valError="required">-->
            <!--                                    La causale della ritenuta è obbligatoria-->
            <!--                                </ng-template>-->
            <!--                            </val-errors>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <div class="col-xl-4 col-lg-6 col-sm-12">-->
            <!--                        <div class="form-group">-->
            <!--                            <label class="lead">Indirizzo PEC</label>-->
            <!--                            <input type="text" formControlName="fecustomerpec" class="form-control"/>-->
            <!--                            <val-errors controlName="fecustomerpec">-->
            <!--                                <ng-template valError="required">-->
            <!--                                    L'indirizzo della PEC è obbligatorio-->
            <!--                                </ng-template>-->
            <!--                                <ng-template valError="email">-->
            <!--                                    Hai inserito un indirizzo email formalmente errato.-->
            <!--                                </ng-template>-->
            <!--                            </val-errors>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <div class="col-sm-12">-->
            <!--                        <div class="form-group">-->
            <!--                            <label class="lead">IBAN</label>-->
            <!--                            <input type="text" formControlName="iban" class="form-control"/>-->
            <!--                            <val-errors controlName="iban">-->
            <!--                                <ng-template valError="required">-->
            <!--                                    L'iban è obbligatorio-->
            <!--                                </ng-template>-->
            <!--                                <ng-template valError="maxLength">-->
            <!--                                    Il codice IBAN dev'essere di 27 caratteri-->
            <!--                                </ng-template>-->
            <!--                                <ng-template valError="minLength">-->
            <!--                                    Il codice IBAN dev'essere di 27 caratteri-->
            <!--                                </ng-template>-->
            <!--                                <ng-template valError="pattern">-->
            <!--                                    Il codice IBAN non è del formato corretto-->
            <!--                                </ng-template>-->
            <!--                            </val-errors>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </form>-->
            <!--            <app-address-->
            <!--                    (isValid)="this.addressValid = $event"-->
            <!--                    (addressData)="this.billingAddress = $event"-->
            <!--                    [startData]="billingAddress"-->
            <!--                    [modalSize]="true"-->
            <!--            ></app-address>-->
        </div>
        <div class="modal-footer text-right">
            <button class="btn btn-secondary" (click)="modal.dismiss()">Indietro</button>
            <button class="btn float-right btn-danger" (click)="cancel()"><i class="fa fa-times mr-1"></i>
                Rifiuta compensi
            </button>
            <button class="btn float-right btn-success" (click)="confirm()"><i class="fa fa-check mr-1"></i>
                Conferma compensi
            </button>
        </div>
    </ng-template>

    <div class="col-xl-4 col-lg-4 col-sm-12" *ngIf="invoice">
        <div class="card bg-gradient-white">
            <div class="card-header">
                <div class="card-title">Istruzioni</div>
            </div>
            <div class="card-body" *ngIf="invoice.status_code === 0">
                <ul class="timeline mb-0">
                    <li>
                        <span class="font-weight-bold bg-gradient-info text-white">1</span>
                        <div class="timeline-container">
                            <p class="timeline-header">Verifica che siano presenti tutti i compensi che ti saresti
                                aspettato in questo invito</p>
                            <p class="timeline-content">Se manca qualcosa puoi segnalarcelo <a href="#">qui</a>,
                                verificheremo e ti risponderemo al più presto.</p>
                        </div>
                    </li>
                    <ng-container *ngIf="invoice.is_old === 0">
                        <li>
                            <span class="font-weight-bold bg-gradient-info text-white">2</span>
                            <div class="timeline-container">
                                <p class="timeline-header">Conferma i compensi cliccando sul pulsante qui sotto</p>
                            </div>
                        </li>
                        <li>
                            <span class="font-weight-bold bg-gradient-info text-white">3</span>
                            <div class="timeline-container">
                                <p class="timeline-header">Confermando i compensi ci autorizzi ad emettere fattura per
                                    conto tuo solo ed esclusivamente a Master Group.</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-container">
                                <p class="timeline-header w-100">
                                    <button class="btn btn-sm btn-block btn-info" (click)="showModal(confirmModal)">
                                        Conferma o rifiuta compensi
                                    </button>
                                </p>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>
            <div class="card-body" *ngIf="invoice.status_code === 1">
                <ul class="timeline mb-0">
                    <li>
                        <span class="font-weight-bold bg-gradient-info text-white">1</span>
                        <div class="timeline-container">
                            <p class="timeline-header">Hai correttamente confermato i compensi</p>
                            <p class="timeline-content">Inviremo la fattura per conto tuo a noi stessi. Se ci fossero
                                problemi ti contatteremo</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="card-body" *ngIf="invoice.status_code === 2">
                <ul class="timeline mb-0">
                    <li>
                        <span class="font-weight-bold bg-gradient-info text-white">1</span>
                        <div class="timeline-container">
                            <p class="timeline-header">Hai rifiutato i compensi</p>
                            <p class="timeline-content">Apri un ticket e spiegaci la motivazione per cui hai rifiutato.
                                Risolveremo al più presto.</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="card-body table-responsive p-0">
                <table class="table  mb-0">
                    <tbody>
                    <ng-container *ngIf="invoice.is_old === 0">
                        <tr>
                            <th style="width:70%">Totale Imponibile:</th>
                            <td class="text-right">{{ invoice.totale | currency: '€ ' }}</td>
                        </tr>
                        <tr>
                            <th style="width:70%">Totale Iva:</th>
                            <td class="text-right text-success">{{ invoice.importo_iva | currency: '€ ' }}</td>
                        </tr>
                        <tr>
                            <th style="width:70%" *ngIf="profile.regime_fiscale === 'RF01 - Ordinario'">Totale Ritenuta
                                (23% su 50%)
                            </th>
                            <th style="width:70%" *ngIf="profile.regime_fiscale === 'RF01 - Ordinario 20%'">Totale
                                Ritenuta (23% su 20%)
                            </th>
                            <td class="text-right text-danger">{{ invoice.importo_ritenuta | currency: '€ ' }}</td>
                        </tr>
                        <tr>
                            <th style="width:70%">Totale a pagare:</th>
                            <td class="text-right text-bold">{{ invoice.importo_netto | currency: '€ ' }}</td>
                        </tr>
                    </ng-container>
                    <tr *ngIf="invoice.is_old === 1">
                        <th style="width:70%">Totale fattura:</th>
                        <td class="text-right text-bold">{{ invoice.totale | currency: '€ ' }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <ng-container *isVendorEnabled="['Tim']">
            <div class="card" *ngIf="isTim">
                <div class="card-header">
                    <h1 class="card-title">Scopri come vengono calcolati i compensi per Tim</h1>
                </div>
                <div class="card-body">
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe allowFullScreen="allowFullScreen" class="embed-responsive-item"
                                src="https://www.youtube.com/embed/vdcmrMOreY8" allowtransparency="true"
                                frameborder="0"></iframe>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
