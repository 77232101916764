import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';

export class PbxLead extends BaseModel {
    id: number;
    campaign_id: number;
    dealer_id: string;
    company_name: string;
    company_owner: string;
    address: string;
    email: string;
    cell_phone: string;
    phone: string;
    number_of_calls: number;
    call_status: string;
    feedback_status: string;
    scheduled_at: string;
    scheduled_call_id: number;
    scheduled_destination: string;
    scheduled_call_status: string;
    table_url: string;
    region: string;
    province: string;
    city: string;
}

export class PbxLeadListPagination extends BaseModel {
    data: PbxLead[];
    _meta: MetaPagination;
    _links: Array<any>;
}
