<div class="modal-header">
    <h4 class="modal-title clamp-title" translate>{{campaign?.title}}</h4>
    <button (click)="modalService.dismissAll()"
            type="button"
            class="close"
            aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [ngBusy]="busy">
    <div *ngIf="report.isRecipients" class = "table-responsive">
        <h5 translate>Recipients</h5>
        <table class="table table-sm">
            <thead>
            <tr>
                <th scope="col" translate="">E-mail</th>
                <th scope="col" translate>Company name</th>
                <th scope="col" translate>First name</th>
                <th scope="col" translate>Last name</th>
                <th scope="col" translate>Opens</th>
                <th scope="col" translate>Last open</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let recipient of recipientPagination?.data">
                <td>{{recipient.email}}</td>
                <td>{{recipient.company_name}}</td>
                <td>{{recipient.first_name}}</td>
                <td>{{recipient.last_name}}</td>
                <td>{{recipient.opens_count}}</td>
                <td>{{recipient.last_open|amDateFormat:localFormat}}</td>
            </tr>
            <tr *ngIf="recipientPagination.data?.length<=0">
                <td translate>List is empty</td>
            </tr>
            </tbody>
            <tfoot>
            <tr *ngIf="recipientPagination?.data?.length>0">
                <td colspan="2" class="pt-2">
                        <span><span translate>There are</span><b> {{ this.recipientPagination?.total_items }}</b>
                            {{this.recipientPagination?.total_items <= 1 ? 'recipient' : 'recipients'|translate}}
                            .</span>
                </td>
                <td colspan="4" class="text-right pt-2">
                    <ngb-pagination
                            size="sm"
                            [pageSize]="10"
                            [maxSize]="5"
                            [(page)]="recipientPagination.current_page"
                            [collectionSize]="recipientPagination.total_items"
                            (pageChange)="loadRecipients()"
                            [boundaryLinks]="true"
                    ></ngb-pagination>
                </td>
            </tr>
            </tfoot>
        </table>
    </div>
    <div *ngIf="report.isOpens" class = "table-responsive">
        <h5 translate>Open Members</h5>
        <table class="table table-sm">
            <thead>
            <tr>
                <th scope="col" translate>E-mail</th>
                <th scope="col" translate>Company name</th>
                <th scope="col" translate>First name</th>
                <th scope="col" translate>Last name</th>
                <th scope="col" translate>Opens</th>
                <th scope="col" translate>Status</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let openMember of openMemberPagination?.data">
                <td>{{openMember.email}}</td>
                <td>{{openMember.company_name}}</td>
                <td>{{openMember.first_name}}</td>
                <td>{{openMember.last_name}}</td>
                <td>{{openMember.opens_count}}</td>
                <td>
                    <span *ngIf="openMember.contact_status == contactStatuses[0].key"
                          class="text-success" translate>{{contactStatuses[0].label}}</span>
                    <span *ngIf="openMember.contact_status == contactStatuses[1].key"
                          class="text-danger" translate>{{contactStatuses[1].label}}</span>
                </td>
            </tr>
            <tr *ngIf="openMemberPagination?.data?.length<=0">
                <td translate>List is empty</td>
            </tr>
            </tbody>
            <tfoot>
            <tr *ngIf="openMemberPagination?.data?.length>0">
                <td colspan="2" class="pt-2">
                        <span><span translate>There are</span><b> {{ openMemberPagination?.total_items }}</b>
                            {{openMemberPagination?.total_items <= 1 ? 'member' : 'members'|translate}}
                            .</span>
                </td>
                <td colspan="4" class="text-right pt-2">
                    <ngb-pagination
                            size="sm"
                            [pageSize]="10"
                            [maxSize]="5"
                            [(page)]="openMemberPagination.current_page"
                            [collectionSize]="openMemberPagination.total_items"
                            (pageChange)="loadOpensReport()"
                            [boundaryLinks]="true"
                    ></ngb-pagination>
                </td>
            </tr>
            </tfoot>
        </table>
    </div>
    <div *ngIf="report.isClicks" class = "table-responsive">
        <h5 translate>Clicks</h5>
        <table class="table table-sm">
            <thead>
            <tr>
                <th scope="col" translate>Url</th>
                <th scope="col" translate>Clicks</th>
                <th scope="col" translate>Last click</th>
                <th class="text-right"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let click of clicksPagination?.data">
                <td><a [href]="click?.url" target="_blank">{{click?.url}}</a></td>
                <td>{{click.total_clicks}} <small class="pl-1">{{click.click_percentage}}%</small></td>
                <td>{{click.last_click|amDateFormat:localFormat}}</td>
                <td class="text-right">
                    <button class="btn btn-link" (click)="showClickMemberModal(click)">
                        <i class=" fa fa-eye text-info"></i>
                    </button>
                </td>
            </tr>
            <tr *ngIf="clicksPagination?.data?.length<=0">
                <td translate>List is empty</td>
            </tr>
            </tbody>
            <tfoot>
            <tr *ngIf="clicksPagination?.data?.length>0">
                <td colspan="2" class="pt-2">
                        <span><span translate>There are</span><b> {{ clicksPagination?.total_items }}</b>
                            {{clicksPagination?.total_items <= 1 ? 'click' : 'clicks'|translate}}
                            .</span>
                </td>
                <td colspan="4" class="text-right pt-2">
                    <ngb-pagination
                            size="sm"
                            [pageSize]="10"
                            [maxSize]="5"
                            [(page)]="clicksPagination.current_page"
                            [collectionSize]="clicksPagination.total_items"
                            (pageChange)="loadClicksReport()"
                            [boundaryLinks]="true"
                    ></ngb-pagination>
                </td>
            </tr>
            </tfoot>
        </table>
    </div>
    <div *ngIf="report.isContent" class = "email-content">
        <p [innerHTML]="content?.archive_html" *ngIf="content?.archive_html"></p>
        <p translate *ngIf="!content?.archive_html">No content</p>
    </div>
</div>
<ng-template #clickMemberTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>
            <a [href]="selectedClick?.url" target="_blank">{{selectedClick?.url}}</a></h4>
        <button (click)="dismissClickMemberModal()"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body table-responsive" [ngBusy]="clickBusy">
        <h5 translate>Click Members</h5>
        <table class="table table-sm">
            <thead>
            <tr>
                <th scope="col" translate>E-mail</th>
                <th scope="col" translate>Company name</th>
                <th scope="col" translate>First name</th>
                <th scope="col" translate>Last name</th>
                <th scope="col" translate>Opens</th>
                <th scope="col" translate>Status</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let clickMember of clickMemberPagination?.data">
                <td>{{clickMember.email}}</td>
                <td>{{clickMember.company_name}}</td>
                <td>{{clickMember.first_name}}</td>
                <td>{{clickMember.last_name}}</td>
                <td>{{clickMember.clicks_count}}</td>
                <td>
                            <span *ngIf="clickMember.contact_status == contactStatuses[0].key"
                                  class="text-success" translate>{{contactStatuses[0].label}}</span>
                    <span *ngIf="clickMember.contact_status == contactStatuses[1].key"
                          class="text-danger" translate>{{contactStatuses[1].label}}</span>
                </td>
            </tr>
            <tr *ngIf="clickMemberPagination?.data?.length<=0">
                <td translate>List is empty</td>
            </tr>
            </tbody>
            <tfoot>
            <tr *ngIf="clickMemberPagination?.data?.length>0">
                <td colspan="2" class="pt-2">
                                <span><span translate>There are</span><b> {{ clickMemberPagination?.total_items }}</b>
                                    {{clickMemberPagination?.total_items <= 1 ? 'member' : 'members'|translate}}
                                    .</span>
                </td>
                <td colspan="4" class="text-right pt-2">
                    <ngb-pagination
                            size="sm"
                            [pageSize]="10"
                            [maxSize]="5"
                            [(page)]="clickMemberPagination.current_page"
                            [collectionSize]="clickMemberPagination.total_items"
                            (pageChange)="loadClickMembers()"
                            [boundaryLinks]="true"
                    ></ngb-pagination>
                </td>
            </tr>
            </tfoot>
        </table>
    </div>
</ng-template>
