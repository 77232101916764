<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)"
             size="default"
             color="#fff"
             type="square-jelly-box"
             name="loader-target"
             [fullScreen]="false">
    <p style="color: white"> Caricamento... </p>
</ngx-spinner>

<div class="row align-items-center" *ngFor="let row of data; let i = index;">
    <div class="col-xl-2 text-center">
        <img [src]="row.image" height="50px">
        <p class="mt-1 mb-0">{{row.label}}</p>
    </div>
    <div class="col">
        <p class="lead mb-0" style="font-size: 18px;">
            Avanzamento con {{row.text}}
            <span class="float-right"><strong>{{row.current}}</strong>/{{row.target}}</span>
        </p>
        <div class="progress border rounded">
            <div class="progress-bar" [ngClass]="row.progress_color" aria-valuenow="100" aria-valuemin="0"
                 aria-valuemax="100" [style.width.%]="row.perc">{{row.perc}}%
            </div>
        </div>
    </div>
    <div class="col-xl-2 text-center">
        <i [ngClass]="row.icon" style="font-size: 37px;"></i>
    </div>
    <div class="col-xl-12">
        <hr *ngIf="i < data.length-1">
    </div>
</div>
