import { Injectable } from '@angular/core';
import {RequestService} from '@services/request.service';

@Injectable({
  providedIn: 'root'
})
export class NegozioService {
  ENDPOINT = 'negozio';

  constructor(public request: RequestService) {
  }

  listaOrdiniDaConvalidare() {
    return this.request.get(`${this.ENDPOINT}/ordini-da-convalidare`);
  }

  creaRateizzazione(id) {
    return this.request.post(`${this.ENDPOINT}/crea-rateizzazione`, {
      id_prodotto: id
    });
  }

  confermaRateizzazione(id, file) {
    const formData = new FormData();
    formData.append('pda_file', file, file.name);
    formData.append('id_rateizzazione', id);

    return this.request.post(`${this.ENDPOINT}/accetta-rateizzazione`, formData);
  }

  rifiutaRateizzazione(id) {
    return this.request.post(`${this.ENDPOINT}/rifiuta-rateizzazione`, {
      id_rateizzazione: id
    });
  }

  listaRateizzazioni() {
    return this.request.get(`${this.ENDPOINT}/lista-rateizzazioni`);
  }
}
