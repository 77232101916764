<app-master-header
        cardClass="bg-gradient-light"
        headerBackground="assets/img/italy.jpg"
        title="Classifica"
        [operatori]="true"
        (onChange)="this.updateUrls($event.operatore);"
>
</app-master-header>

<ul ngbNav #nav="ngbNav" class="nav-tabs mg-tabs">
    <li ngbNavItem>
        <a ngbNavLink>{{stand ? 'Venditori' : 'Negozi' }}</a>
        <ng-template ngbNavContent>
            <app-master-card
                    bodyClass="p-0"
                    [title]="'Classifica ' + (stand ? 'Venditori' : 'Negozi')"
                    image="assets/img/flats/ribbon.png"
            >
                <data-builder-table [url]="urlClassificaItalia"></data-builder-table>
            </app-master-card>
        </ng-template>
    </li>
    <li ngbNavItem *show="['master', 'masteragent', 'operator']">
        <a ngbNavLink>{{stand ? 'Presidi' : 'Key Account' }}</a>
        <ng-template ngbNavContent>
            <app-master-card
                    bodyClass="p-0"
                    [title]="'Classifica ' + (stand ? 'Presidi' : 'Key Account')"
                    image="assets/img/flats/employee.png"
            >
                <data-builder-table [url]="urlClassificaAgenti"></data-builder-table>
            </app-master-card>
        </ng-template>
    </li>
    <li ngbNavItem *show="['master', 'masteragent', 'operator']">
        <a ngbNavLink>Regioni</a>
        <ng-template ngbNavContent>
            <app-master-card
                    bodyClass="p-0"
                    title="Classifica Regioni"
                    image="assets/img/flats/global-network.png"
            >
                <data-builder-table [url]="urlClassificaRegioni"></data-builder-table>
            </app-master-card>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
