<ng-select
  [ngClass]="{ 'form-control-sm': small }"
  [items]="data"
  bindValue="id"
  bindLabel="provincia"
  [(ngModel)]="province"
  (ngModelChange)="onChange.emit($event)"
  [loading]="loading"
  loadingText="Caricamento..."
  [disabled]="this.onDemand && !this.state"
></ng-select>
