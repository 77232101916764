import {Component, OnInit} from '@angular/core';
import {WhatsappService} from '@app/services/whatsapp.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-master-whatsapp',
    templateUrl: './master-whatsapp.component.html',
    styleUrls: ['./master-whatsapp.component.scss'],
})
export class MasterWhatsappComponent implements OnInit {
    chatsWaitingReply = [];
    ranking = [];
    overview = {
        total_chats: 0,
        active_chats: 0,
        pending_chats: 0,
    };

    previewMessages = [];

    constructor(
        private service: WhatsappService,
        private spinner: NgxSpinnerService
    ) {
    }

    ngOnInit() {
        this.service
            .getChatsWaitingReply()
            .subscribe((data) => (this.chatsWaitingReply = data));

        this.service.getOverview().subscribe((data) => (this.overview = data));
        this.service.getAgentRanking().subscribe((data) => (this.ranking = data));
    }

    loadPreview(popover, chat: any) {
        this.spinner.show('previewSpinner');
        popover.open();

        this.service.getMessages(chat.id).subscribe((messages) => {
            this.spinner.hide('previewSpinner');
            this.previewMessages = messages;
        });
    }
}
