import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DashboardService} from "@services/dashboard.service";
import {Subscription} from "rxjs";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
    selector: 'app-master-economics-fatturato',
    templateUrl: './master-economics-fatturato.component.html',
    styleUrls: ['./master-economics-fatturato.component.css']
})
export class MasterEconomicsFatturatoComponent implements OnInit {
    tipo: string;
    data: any;
    busy: Subscription;
    startup: any;

    allColumns = [];
    selectedColumns =[];

    _periodo: string;

    set periodo(value: string) {
        this._periodo = value;
    }

    get periodo() {
        return this._periodo;
    }

    constructor(
        public route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private dashboardService: DashboardService
    ) {
        this.tipo = this.route.snapshot.paramMap.get('tipo');
        this.periodo = this.route.snapshot.paramMap.get('periodo');
    }

    ngOnInit() {
        this.dashboardService.get('/economics')
            .subscribe((data) => {
                this.startup = data.result;
            });

        this.spinner.show();
        this.dashboardService.get(`/economics-fatturato?tipo=${this.tipo}&periodo=${this.periodo}`)
            .subscribe(data => {
                this.data = data.result;
                this.allColumns = data.result.allColumns;
                this.selectedColumns = data.result.columns;
                this.spinner.hide();
            });
    }

    refresh() {
        this.spinner.show();
        this.dashboardService.post(`/economics-fatturato?tipo=${this.tipo}&periodo=${this.periodo}`, { 'columns' : JSON.stringify(this.selectedColumns)})
            .subscribe(data => {
                this.data = data.result;
                this.allColumns = data.result.allColumns;
                this.selectedColumns = data.result.columns;
                this.spinner.hide();
            });
    }
}
