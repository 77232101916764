<div [ngBusy]="busy">
  <div class="row">
    <div class="col-12">
      <div class="card card-main-header bg-gradient-1">
        <div class="card-body">
          <img class="bg-gradient-light" src="/assets/img/flats/gear.png">
          <div class="card-content row align-items-center">
            <div class="col">
              <h1>Supporto</h1>
              <hr>
              <h2 class="mb-0">Se hai un problema tecnico puoi scriverci un ticket e lo gestiremo al più presto.</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-ticket-modal #ticketModal></app-ticket-modal>

  <div class="row">
    <div class="col-12">
      <div class="card bg-light-gradient">
        <div class="card-body">
          <button class="btn btn-success" (click)="ticketModal.open()"> Apri un ticket</button>
        </div>
      </div>
    </div>
    <div class="col-12">
      <app-table [rows]="rows" [columns]="columns" (onSelect)="onSelect($event)"></app-table>
    </div>
  </div>
</div>
