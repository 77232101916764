import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DashboardService} from '@services/dashboard.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-master-centralino',
    templateUrl: './master-centralino.component.html',
    styleUrls: ['./master-centralino.component.css']
})
export class MasterCentralinoComponent implements OnInit {
    busy: Subscription;
    startup: any;

    _periodo = 'Today';

    autoloadTableUrl: string;
    urlSubheader: string;
    urlBackoffice: string;
    urlNegozi: string;
    urlAgenti: string;

    set periodo(value: string) {
        this._periodo = value;
        this.updateUrls();
    }

    get periodo() {
        return this._periodo;
    }

    constructor(
        private dashboardService: DashboardService,
        private modalService: NgbModal
    ) {
    }

    ngOnInit() {
        this.busy = this.dashboardService.get(`/centralino?periodo=${this.periodo}`)
            .subscribe((data) => {
                this.startup = data.result;
                this.updateUrls();
            }, (error) => {
                this.updateUrls();
            });
    }

    updateUrls(specific = null) {
        if (!specific || specific === 'subheader') {
            this.urlBackoffice = `/centralino-backoffice?periodo=${this.periodo}`;
        }
        if (!specific || specific === 'subheader') {
            this.urlNegozi = `/centralino-negozi?periodo=${this.periodo}`;
        }
        if (!specific || specific === 'subheader') {
            this.urlAgenti = `/centralino-agenti?periodo=${this.periodo}`;
        }

        this.dashboardService.get(`/centralino?periodo=${this.periodo}`)
            .subscribe((data) => {
                this.startup = data.result;
            }, (error) => {
            });
    }

    openAutoloadTable(modal, url) {
        this.autoloadTableUrl = `${url}?periodo=${this.periodo}`;

        this.modalService.open(modal, {size: 'xl'});
    }
}
