import {Component, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {MasterPlanningService} from '@services/master-planning.service';
import {MasterPlan} from '@models/master-plan';
import {NgxSpinnerService} from 'ngx-spinner';
import {environment} from '@environments/environment';
import {Segment} from '@models/segment';
import {MasterPlanningCalculationService} from '@services/master-planning-calculation.service';
import {FormControl, FormGroup} from '@angular/forms';
import {User} from '@models/user';
import {AuthenticationService} from '@services/authentication.service';

moment.locale(environment.language);

@Component({
    selector: 'app-master-planning',
    templateUrl: './master-planning.component.html',
    styleUrls: ['./master-planning.component.css']
})
export class MasterPlanningComponent implements OnInit {
    activeTab = 0;
    busy = {
        global: null,
        affiliations: null,
        sales: null
    };
    masterPlans: MasterPlan;
    filterForm = {
        dateRange: {start: '', end: ''},
        scope: 'general',
        scopeId: ''
    };
    targetTypes = ['affiliations', 'sales', 'revenue'];
    activeSegment: Segment;
    targetYear;
    openingsTotal = {plan: 0, real: 0, shops: 0, isInSchedule: false};
    productionTotal = {plan: 0, real: 0, average: 0, ctrPerShopAverage: 0, isInSchedule: false};
    revenueTotal = {
        plan: 0,
        real: 0,
        shopCommission: 0,
        agentCommission: 0,
        netMargin: 0,
        perContract: 0,
        average: 0,
        averagePerContract: 0,
        cost: 0,
        isInSchedule: false
    };
    revenueAverage = {master: '', dealer: '', agent: ''};
    salesCalculation = {salesIncreaseValue: '', involvementBreakDown: ''};
    segments: Segment[];
    monthsList = [];
    datesList = {affiliations: [], sales: [], revenue: []};
    drillDowns = ['month', 'week', 'days'];
    drillDown = {affiliations: this.drillDowns[0], sales: this.drillDowns[0]};
    popupConfig: any;
    defaultMonthRange: any[] = [];
    apiFormat = 'YYYY-MM';
    selectedYear = moment().year();
    breakDownTypes = [];
    affiliationBreakDownTypes = [];
    editPlan = new FormGroup({
        planValue: new FormControl(''),
    });
    public options: any =
        {
            minDate: moment().clone().startOf('month').format('DD/MM/YYYY'),
            maxDate: moment().clone().endOf('month').format('DD/MM/YYYY')
        };
    localFormat = 'DD/MM/YYYY';
    localMonthFormat = 'MMM YYYY';


    private user: User;
    stand = false;

    constructor(private masterPlanningService: MasterPlanningService,
                private masterPlanningCalculationService: MasterPlanningCalculationService,
                private authService: AuthenticationService,
                private spinner: NgxSpinnerService) {
        this.authService.currentUser.subscribe((user) => (this.user = user));
        this.stand = this.user && this.user.profile.operatori.includes('Kena Mobile Stands');

        if(this.stand) {
            this.filterForm.scope = 'general_stand';
        }

        this.defaultMonthRange = [moment().set('month', 6).startOf('month'), moment().set('month', 11).endOf('month')];
        this.setDefaultRange();
        this.masterPlanningService.getOperators().subscribe((segments: Segment[]) => {
            if (segments.length > 0) {
                this.segments = segments;
                this.activeSegment = this.segments[0];
                this.getPlans();
            }
        });
    }

    ngOnInit() {
        this.masterPlanningService.drillDown.subscribe(data => {
            this.drillDown.affiliations = data;
        });
    }

    setDefaultDrillDown() {
        this.targetTypes.forEach(type => {
            this.drillDown[type] = this.drillDowns[0];
        });
    }

    getPlans(busyType = 'global') {
        const param = this.masterPlanningService.getFilterParams(this.filterForm);
        this.busy[busyType] = this.masterPlanningService.getPlans(this.activeSegment.id, param).subscribe((plans: MasterPlan) => {
            this.setDefaultDrillDown();
            this.masterPlans = plans;
            this.setMonthList(plans);
            this.calculatedContractsPerShop();
            this.targetYear = this.selectedYear;
            this.datesList.affiliations.forEach(date => {
                this.affiliationBreakDownTypes = this.masterPlans.affiliations[date].breakdown ?
                    this.getObjectKeysList(this.masterPlans.affiliations[date].breakdown) : [];
            });
            this.datesList.sales.forEach(date => {
                if (!this.masterPlans.revenue[date].revenue_per_contract) {
                    this.masterPlans.revenue[date].revenue_per_contract_final = false;
                    this.masterPlans.revenue[date].revenue_per_contract = this.calculatedAverageRevenuePerContract(this.masterPlans.sales[date].real, this.masterPlans.revenue[date].real);
                } else {
                    this.masterPlans.revenue[date].revenue_per_contract_final = true;
                }
                this.masterPlans.revenue[date].shop_per_contract = this.calculatedAverageRevenuePerContract(this.masterPlans.sales[date].real, this.masterPlans.revenue[date].shop_commission);
                this.masterPlans.revenue[date].sales = this.masterPlans.sales[date].real;
                this.breakDownTypes = this.masterPlans.sales[date].breakdown ?
                    this.getObjectKeysList(this.masterPlans.sales[date].breakdown) : [];
            });
            this.salesCalculation = this.masterPlanningCalculationService.salesCalculation(this.datesList.sales, this.masterPlans.sales,
                this.breakDownTypes);
            this.revenueAverage = this.masterPlanningCalculationService.revenueCalculation(this.monthsList, this.masterPlans.revenue);
            this.setTotal();
        }, error => {
            this.masterPlanningService.errorAlert(error);
        });
    }
    setMonthList(plans) {
        this.monthsList = this.getObjectKeysList(plans.affiliations ? plans.affiliations : plans.sales > 0 ? plans.sales :
            plans.revenue > 0 ? plans.revenue : []);
        this.datesList.affiliations = this.getObjectKeysList(plans.affiliations ? plans.affiliations : []);
        this.datesList.sales = this.getObjectKeysList(plans.sales ? plans.sales : []);
    }

    setTotal() {
        if (this.masterPlans.affiliations) {
            this.openingsTotal.plan = this.calculatePlanTotal(this.masterPlans.affiliations);
            this.openingsTotal.real = this.calculateTotal(this.masterPlans.affiliations, 'real');
            this.openingsTotal.shops = this.calculateShopsTotal(this.masterPlans.affiliations);
            this.openingsTotal.isInSchedule = this.openingsTotal.plan < this.openingsTotal.real;
        }
        if (this.masterPlans.sales) {
            this.setCalculation(this.productionTotal, this.masterPlans.sales);
            this.productionTotal.ctrPerShopAverage = this.calculatedCtrPerShopAverage(this.masterPlans.sales);
        }
        if (this.masterPlans.revenue) {
            this.setCalculation(this.revenueTotal, this.masterPlans.revenue);
            this.revenueTotal.shopCommission = this.calculateTotal(this.masterPlans.revenue, 'shop_commission');
            this.revenueTotal.agentCommission = this.calculateTotal(this.masterPlans.revenue, 'agent_commission');
            this.revenueTotal.netMargin = this.calculateTotal(this.masterPlans.revenue, 'net_margin');
            this.revenueTotal.cost = this.calculateTotal(this.masterPlans.revenue, 'cost');
            this.revenueTotal.perContract = this.calculateTotal(this.masterPlans.revenue, 'revenue_per_contract');
            this.revenueTotal.averagePerContract = this.calculateTotalPerMonth(this.masterPlans.revenue, this.revenueTotal.perContract);
        }
    }

    isInSchedule(items, averagePlan) {
        const real = this.calculateTotal(items, 'real');
        const averageReal = this.calculateTotalPerMonth(this.masterPlans.sales, real);
        return averagePlan < averageReal;
    }

    setDefaultRange() {
        this.setSelectedDateRange(this.defaultMonthRange[0], this.defaultMonthRange[1]);
    }

    setSelectedDateRange(from: any, to: any) {
        this.filterForm.dateRange.start = moment(from).format(this.apiFormat);
        this.filterForm.dateRange.end = moment(to).format(this.apiFormat);
        this.selectedYear = moment(to).year();
    }
    setScopeId(event) {
        this.filterForm.scopeId = event;
    }

    isValidFilterForm() {
        if (this.filterForm.scope !== 'general' && this.filterForm.scope !== 'general_stand' && this.filterForm.scope && !this.filterForm.scopeId) {
            return true;
        }
        return false;
    }

    calculatePlanTotal(items) {
        return this.masterPlanningCalculationService.calculatePlanTotal(items);
    }

    calculateBreakDownTotal(dateList, item, type, objectName?) {
        return this.masterPlanningCalculationService.calculateBreakDownTotal(type, dateList, item, objectName);
    }

    calculateTotal(items, obj) {
        return this.masterPlanningCalculationService.calculateTotal(items, obj);
    }

    calculateTotalPerMonth(items, total) {
        const datesList = this.getObjectKeysList(items);
        const months = datesList.length;
        return Number((total / months).toFixed(2));
    }

    calculateShopsTotal(items) {
        const datesList = this.getObjectKeysList(items);
        const date = datesList[datesList.length - 1];
        if (date) {
            return items[date].shops;
        }
    }

    calculateAveragePerContract(length, total) {
        return total / length;
    }

    calculatedCtrPerShopAverage(items) {
        return this.masterPlanningCalculationService.calculatedCtrPerShopAverage(items);
    }

    setCalculation(total, items) {
        total.plan = this.calculatePlanTotal(items);
        total.real = this.calculateTotal(items, 'real');
        total.average = this.calculateTotalPerMonth(items, total.plan);
        total.isInSchedule = this.isInSchedule(items, total.average);
    }
    getObjectKeysList(items) {
        return this.masterPlanningCalculationService.getObjectKeysList(items);
    }

    changeSegment(operator, i) {
        this.activeSegment = operator;
        this.activeTab = i;
        return this.getPlans();
    }
    changePlan() {
        this.spinner.show('planSpinner');
        if (this.popupConfig.selectedItem.plan) {
            return this.masterPlanningService.updatePlan(this.popupConfig.selectedItem.plan, this.newPlanValue).subscribe((response: MasterPlan) => {
                this.setNewValue(this.popupConfig.popup, this.popupConfig.date, response, this.popupConfig.targetType);
            }, err => {
                this.handleUpdateError(this.popupConfig.popup, err);
            });
        }
        return this.masterPlanningService.createPlan(this.activeSegment.id, this.popupConfig, this.filterForm, this.newPlanValue).subscribe((response: MasterPlan) => {
            this.setNewValue(this.popupConfig.popup, this.popupConfig.date, response, this.popupConfig.targetType);
        }, err => {
            this.handleUpdateError(this.popupConfig.popup, err);
        });
    }

    get newPlanValue(): any {
        return this.editPlan.get('planValue').value;
    }

    openEditTable(popOver: any, item, selectedDate, name, breakdownType = '') {
        if (item.plan) {
            this.editPlan.controls.planValue.setValue(parseFloat(item.plan.value));
        } else {
            this.editPlan.controls.planValue.setValue('');
        }
        this.popupConfig = {
            selectedItem: item,
            date: selectedDate,
            targetType: name,
            popup: popOver,
            breakDownType: breakdownType
        };
        popOver.open();
    }

    focusInput() {
        document.getElementById('input').focus();
    }

    closeEditTable() {
        this.editPlan.controls.planValue.setValue(0);
        this.popupConfig.popup.close();
        this.spinner.hide('planSpinner');
    }

    setNewValue(popOver: any, date, response, targetType) {
        this.spinner.hide('planSpinner');
        if (response.breakdown_type) {
            this.masterPlans[targetType][date].breakdown[response.breakdown_type].plan = response;
        } else {
            this.masterPlans[targetType][date].plan = response;
            if (this.masterPlans[targetType][date].up_to_general) {
                this.masterPlans[targetType][date].up_to_general = Number(this.masterPlans[targetType][date].up_to_general) - Number(response.value);
            }
        }
        this.setTotal();
        this.popupConfig = {};
        popOver.close();
    }

    setDrillDownDate(event, targetType) {
        this.masterPlans[targetType] = event;
        this.datesList[targetType] = this.getObjectKeysList(event ? event : []);
        this.setTotal();
    }

    backDrillDown(targetType) {
        return this.getPlansDrillDown(this.datesList[targetType][0], targetType, true);
    }

    getPlansDrillDown(date, targetType, isBack = false) {
        const drillDown = this.masterPlanningService.getDrillDown(this.drillDown[targetType], isBack);
        if (drillDown !== this.drillDowns[0]) {
            return this.busy[targetType] = this.masterPlanningService.getPlansDrillDown(date, this.filterForm, targetType, drillDown).subscribe(response => {
                    this.drillDown[targetType] = drillDown;
                    this.setDrillDownDate(response[targetType], targetType);
                }
            );
        } else {
            return this.getPlans(targetType);
        }
    }

    handleUpdateError(popOver: any, err?) {
        this.spinner.hide('planSpinner');
        this.popupConfig = {};
        popOver.close();
        this.masterPlanningService.errorAlert(err);
    }

    showPopupNote() {
        const item = this.popupConfig.selectedItem;
        if (item.up_to_general && item.up_to_general >= 0) {
            return 'There are ' + this.formatNumber(item.up_to_general) + ' left to achieve general value.';
        } else if (item.up_to_general && item.up_to_general < 0) {
            return 'You are in line with the schedule.';
        }
        return '';
    }

    formatNumber(num) {
        return this.masterPlanningCalculationService.formatNumber(num);
    }

    formatMonth(date: any) {
        if (date) {
            return moment(date).format('MMM');
        }
        return date;
    }

    calculatedContractsPerShop() {
        this.datesList.sales.forEach(month => {
            if (this.masterPlans.sales[month].shops !== 0) {
                this.masterPlans.sales[month].contract_per_shop = this.formatNumber(this.masterPlans.sales[month].real / this.masterPlans.sales[month].shops);
            }
        });
    }

    showContractsPerTotalShop(date) {
        if (this.masterPlans?.affiliations[date].shops !== 0) {
            return this.formatNumber((this.masterPlans?.sales[date].real / this.masterPlans?.affiliations[date].shops).toFixed(2));
        }
        return '--';
    }

    calculatedAverageRevenuePerContract(sale, revenue) {
        if (sale !== 0) {
            return revenue / sale;
        }
    }

    currencyFormat(value: any) {
        return this.masterPlanningCalculationService.currencyFormat(Number(value));
    }

    isCurrentMonth(month) {
        return moment().isSame(month, 'month');
    }

    encodeName(name: string) {
        return name.split('_').join(' ');
    }

    showPlans(drillDown) {
        return drillDown === this.drillDowns[0];
    }

    setStartDate(date) {
        return this.masterPlanningService.setStartDate(date, this.localFormat);
    }

    setEndDate(date) {
        return this.masterPlanningService.setEndDate(date, this.localFormat);
    }

    formatDate(date, targetType) {
        return this.masterPlanningService.formatDate(date, this.drillDown[targetType]);
    }

    isDateRangeVisible(targetType) {
        return this.drillDown[targetType] === this.drillDowns[1];
    }

    isDateVisible(targetType) {
        return this.drillDown[targetType] === this.drillDowns[2];
    }

    isDisabled(targetType) {
        return this.drillDown[targetType] === this.drillDowns[2];
    }
}
