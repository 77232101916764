import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ShopProduct, ShopProductVariant} from '@models/e-pay/shop-product';
import {EPayService} from '@services/e-pay.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ShopOrder, ShopOrderItem} from '@models/e-pay/shop-order';
import {User} from '@models/user';
import {AuthenticationService} from '@services/authentication.service';


@Component({
    selector: 'app-shop-modal',
    templateUrl: './shop-modal.component.html',
    styleUrls: ['./shop-modal.component.css']
})
export class ShopModalComponent implements OnInit {
    @Input() product: ShopProduct;
    showCheckout = false;
    currency = EPayService.currency;
    productTypes = EPayService.productTypes;
    orderForm = new FormGroup({
        phone_number: new FormControl(''),
        total_price: new FormControl('', Validators.required),
    });
    orderItem: ShopOrderItem = new ShopOrderItem();
    order: ShopOrder;
    user: User;
    busy: any;
    isAuthorize = false;
    areaCode = 39;
    constructor(
        private modalService: NgbModal,
        public authenticationService: AuthenticationService,
        private ePayService: EPayService) {
    }

    ngOnInit(): void {
        this.orderItem.product_name = this.product.name;
        this.orderItem.product_id = this.product.id;
        this.orderItem.type = this.product.type;
        this.orderItem.ean = this.product.ean;
        this.orderItem.pan = this.product.pan;
        this.defaultCheck();
        if (this.productTypes[0].key === this.product.type) {
            this.orderForm.controls['phone_number'].setValidators(Validators.required);
            this.orderForm.controls['phone_number'].updateValueAndValidity();
        }
    }

    dismissModal() {
        this.showCheckout = false;
        this.isAuthorize = false;
        this.modalService.dismissAll();
    }

    showCheckoutModal() {
        const order = new ShopOrder();
        order.phone_number = this.orderForm.value.phone_number;
        order.total_price = Number(this.orderForm.value.total_price);
        order.order_items = [this.orderItem];
        order.dealer_id = this.user?.profile?.id;
        this.order = order;
        if (this.productTypes[0].key === this.product.type) {
            this.busy = this.ePayService.authenticateOrder(order).subscribe((response) => {
                if (response && response?.txt_id) {
                    order.txt_id = response.txt_id;
                    this.isAuthorize = true;
                }
                this.showCheckout = true;
            }, err => {
                this.isAuthorize = false;
                this.showCheckout = true;
            });
        } else {
            this.showCheckout = true;
            this.isAuthorize = true;
        }
    }

    defaultCheck() {
        const price = this.product.variants.length > 0 ? this.product.variants[0].price : this.product.price;
        this.orderForm.get('total_price').setValue(price);
        this.orderItem.price = price;
        this.orderItem.dealer_price = this.product.variants.length > 0 ? this.product.variants[0].dealer_price : this.product.dealer_price;
        this.orderItem.our_price = this.product.variants.length > 0 ? this.product.variants[0].our_price : this.product.our_price;
        if (this.product.variants?.length > 0) {
            this.orderItem.product_variant_id = this.product.variants[0].id;
            this.orderItem.product_variant_name = this.product.variants[0].name;
            this.orderItem.ean = this.product.variants[0].ean;
            this.orderItem.pan = this.product.variants[0].pan;
        }
    }

    selectedVariant(variant: ShopProductVariant) {
        this.orderItem.product_variant_id = variant.id;
        this.orderItem.product_variant_name = variant.name;
        this.orderItem.price = variant.price;
        this.orderItem.dealer_price = variant.dealer_price;
        this.orderItem.our_price = variant.our_price;
        this.orderItem.ean = variant.ean;
        this.orderItem.pan = variant.pan;
    }
}
