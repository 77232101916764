import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { DashboardService } from "@services/dashboard.service";

@Component({
  selector: "app-master-analisi-fasce",
  templateUrl: "./master-analisi-fasce.component.html",
  styleUrls: ["./master-analisi-fasce.component.css"],
})
export class MasterAnalisiFasceComponent implements OnInit {
  busy: Subscription;

  urlAnalisiFasceFrom: string;
  urlAnalisiFasceTo: string;

  constructor() {}

  ngOnInit() {}

  updateUrls(operatore, from, to) {
    this.urlAnalisiFasceFrom = `/analisi-fasce-from?operatore=${operatore}&from=${from}&to=${to}`;
    this.urlAnalisiFasceTo = `/analisi-fasce-to?operatore=${operatore}&from=${from}&to=${to}`;
  }
}
