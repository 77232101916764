<ng-template #content let-modal class="bg-gradient-light">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Modifica</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="form">
            <div class="row">
                <div *ngFor="let control of f() | toArray; index as i" class="col-12">
                    <div class="form-group">
                        <label class="lead">{{labels[i]}}</label>
                        <input type="text" [formControlName]="getName(control)" class="form-control" *ngIf="selectOptions.length === 0"/>
                        <ng-select *ngIf="selectOptions.length > 0" [items]="this.selectOptions"
                                   [formControlName]="getName(control)">
                        </ng-select>
                        <val-errors [controlName]="getName(control)">
                            <ng-template valError="required">
                                Il campo è obbligatorio
                            </ng-template>
                            <ng-template valError="pattern">
                                Il valore inserito non è corretto
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <button class="btn btn-success btn-block" [disabled]="!this.form.valid" (click)="save()"><i class="fa fa-check-circle mr-2"></i>Salva</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
