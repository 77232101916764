<div style="height: 250px;">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)"
                 size="default"
                 color="#fff"
                 type="square-jelly-box"
                 name="loader-chart"
                 [fullScreen]="false">
        <p style="color: white"> Caricamento... </p>
    </ngx-spinner>

    <ngx-charts-line-chart
            [scheme]="colorScheme"
            [showRefLabels]="true"
            [roundDomains]="true"
            [xAxis]="true"
            [yAxis]="true"
            [showGridLines]="false"
            [legend]="false"
            [results]="chartData"
            [showXAxisLabel]="false"
            [showYAxisLabel]="false"
    >
    </ngx-charts-line-chart>
</div>
