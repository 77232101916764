import { Injectable } from '@angular/core';
import { SweetAlertCustomClass, SweetAlertGrow, SweetAlertHideClass, SweetAlertIcon, SweetAlertInput, SweetAlertOptions, SweetAlertPosition, SweetAlertShowClass } from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root'
})
export class ConfirmOptionsService implements SweetAlertOptions {

  title?: string | HTMLElement | JQuery;
  titleText?: string;
  text?: string;
  html?: string | HTMLElement | JQuery;
  icon?: SweetAlertIcon;
  iconColor?: string;
  iconHtml?: string;
  footer?: string | HTMLElement | JQuery;
  template?: string | HTMLTemplateElement;
  backdrop?: boolean | string;
  toast?: boolean;
  target?: string | HTMLElement;
  input?: SweetAlertInput;
  width?: number | string;
  padding?: number | string;
  background?: string;
  position?: SweetAlertPosition;
  grow?: SweetAlertGrow;
  showClass?: SweetAlertShowClass;
  hideClass?: SweetAlertHideClass;
  customClass?: SweetAlertCustomClass;
  timer?: number;
  timerProgressBar?: boolean;
  animation?: any;
  heightAuto?: boolean;
  allowOutsideClick?: any;
  allowEscapeKey?: any;
  allowEnterKey?: any;
  stopKeydownPropagation?: boolean;
  keydownListenerCapture?: boolean;
  showConfirmButton?: boolean;
  showDenyButton?: boolean;
  showCancelButton?: boolean;
  confirmButtonText?: string;
  denyButtonText?: string;
  cancelButtonText?: string;
  confirmButtonColor?: string;
  denyButtonColor?: string;
  cancelButtonColor?: string;
  confirmButtonAriaLabel?: string;
  denyButtonAriaLabel?: string;
  cancelButtonAriaLabel?: string;
  buttonsStyling?: boolean;
  reverseButtons?: boolean;
  focusConfirm?: boolean;
  focusDeny?: boolean;
  focusCancel?: boolean;
  showCloseButton?: boolean;
  closeButtonHtml?: string;
  closeButtonAriaLabel?: string;
  loaderHtml?: string;
  showLoaderOnConfirm?: boolean;
  imageUrl?: string;
  imageWidth?: number | string;
  imageHeight?: number | string;
  imageAlt?: string;
  inputLabel?: string;
  inputPlaceholder?: string;
  inputValue?: any;
  inputOptions?: any;
  inputAutoTrim?: boolean;
  inputAttributes?: Record<string, string>;
  inputValidator?(inputValue: string): any;
  returnInputValueOnDeny?: boolean;
  validationMessage?: string;
  progressSteps?: readonly string[];
  currentProgressStep?: string;
  progressStepsDistance?: string;

  constructor(options: SweetAlertOptions) {
    this.setDefaults();
    Object.assign(this, options);
  }

  setDefaults() {
    this.icon = 'warning';
    this.title = 'Elimina';
    this.text = 'Sei sicuro di voler rimuovere?'
    this.showCancelButton = true;
    this.confirmButtonText = 'Sì';
    this.cancelButtonText = 'Annulla';
    this.customClass = {
      confirmButton: 'btn btn-danger',
      cancelButton: 'btn btn-default'
    };
  }
}
