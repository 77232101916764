import {Component, Input, OnInit} from '@angular/core';
import {Subject} from '@models/invoice/subject';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InvoiceDocumentService} from '@services/invoice-document.service';

@Component({
    selector: 'app-subject-view',
    templateUrl: './subject-view.component.html',
    styleUrls: ['./subject-view.component.css']
})
export class SubjectViewComponent implements OnInit {
    @Input() subject: Subject;
    @Input() subjectId;
    currency = '€';
    localFormat = 'DD/MM/YYYY';
    busy: any;

    constructor(private modalService: NgbModal,
                private invoiceDocumentService: InvoiceDocumentService) {
    }

    ngOnInit(): void {
        if (!this.subject && this.subjectId) {
            this.busy = this.invoiceDocumentService.getSubjectDetail(this.subjectId).subscribe((response: Subject) => {
                this.subject = response;
            });
        }
    }

    dismissModal() {
        this.modalService.dismissAll();
    }
}
