import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DashboardService} from "@services/dashboard.service";
import {OperatorService} from "@services/operator.service";
import {NgxSpinnerService} from "ngx-spinner";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-modal-questions',
    templateUrl: './modal-questions.component.html',
    styleUrls: ['./modal-questions.component.css']
})
export class ModalQuestionsComponent implements OnInit {
    @ViewChild('questionTable') modal: NgbActiveModal;

    questionUrl = null;
    questions = [];
    question: '';
    step = 0;
    label: any;
    param: any;
    answer: any;
    operatore: any;
    output: any;
    opQuestions: [];
    outQuestions: [];
    dsQuestions: [];

    constructor(
        private modalService: NgbModal,
        private dashboardService: DashboardService,
        private spinner: NgxSpinnerService) {
    }

    ngOnInit() {
    }

    startQuestion(question) {
        this.modalService.dismissAll();
        this.modalService.open(this.modal, {size: 'xl'});
        this.spinner.show();

        this.dashboardService.get(question)
            .subscribe((data) => {
                this.spinner.hide();
                this.step = 1;

                this.questionUrl = data.result.url;
                this.label = data.result.label;
                this.question = data.result.question;
                this.param = data.result.param;
                this.questions = data.result.opQuestions;
                this.dsQuestions = data.result.dsQuestions;
                this.opQuestions = data.result.opQuestions;
                this.outQuestions = data.result.outQuestions;
            })
    }

    get answerLabel() {
        return this.dsQuestions.find(item => item['key'] === this.answer)['question'];
    }
    get outputLabel() {
        return this.outQuestions.find(item => item['key'] === this.output)['question'];
    }

    nextQuestion(key: any) {
        if (this.step === 1) {
            this.operatore = key;
            this.questions = this.datasourceQuestions;
            this.step = 2;
            this.label = `Cosa vuoi vedere nel ${this.question} ${this.param}`;
        }
        else if (this.step === 2) {
            this.questions = this.outQuestions;
            this.answer = key;
            this.step = 3;
            this.label = `Come vuoi visualizzare ${this.answerLabel} di ${this.operatore} nel ${this.question} ${this.param}`;
        }
        else if (this.step === 3) {
            this.output = key;
            this.questionUrl = `-detail/${this.question}?param=${this.param}&datasource=${this.answer}&operatore=${this.operatore}&output=${this.output}`;
            this.step = 4;
            this.label = `Stai visualizzando ${this.answerLabel} di ${this.operatore} nel ${this.question} ${this.param} nel formato ${this.outputLabel}`;
        }
    }

    get datasourceQuestions() {
        if(this.dsQuestions) {
            return this.dsQuestions.filter((item: any) => {
                if ('only' in item && item['only'].length > 0) {
                    return item['only'].includes(this.operatore);
                }
                return true;
            });
        }
        return [];
    }

    goBack() {
        if(this.step == 2) {
            this.label = `Cosa vuoi vedere nel ${this.question} ${this.param}`;
            this.questions = this.opQuestions;
            this.operatore = null;
            this.step = 1;
        }
        else if(this.step == 3) {
            this.label = `Per che operatore vuoi visualizzare ${this.answerLabel} nel ${this.question} ${this.param}`;
            this.questions = this.datasourceQuestions;
            this.step = 2;
            this.answer = null;
        }
        else if(this.step == 4) {
            this.label = `Come vuoi visualizzare ${this.answerLabel} di ${this.operatore} nel ${this.question} ${this.param}`;
            this.questions = this.outQuestions;
            this.step = 3;
            this.output = null;
        }
    }
}
