import { Component, OnInit, ViewChild } from "@angular/core";
import { DataService } from "@services/data.service";
import { Subscription } from "rxjs";
import { AuthenticationService } from "@services/authentication.service";
import { TransactionCredito } from "@models/transaction-credito";
import {
  TableColumn,
  TableColumnAlignment,
  TableColumnType,
} from "@app/pages/partials/table/table.component";
import { TransactionService } from "@app/services/transaction.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Transaction } from "@app/models/transaction";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ConfirmOptionsService } from "@app/services/confirm-options.service";

@Component({
  selector: "app-credito-index",
  templateUrl: "./credito-index.component.html",
  styleUrls: ["./credito-index.component.scss"],
})
export class CreditoIndexComponent implements OnInit {
  busy: Subscription;

  rows: Transaction[] = [];

  credito: number;
  debito: number;

  dealerId = "";

  @ViewChild("transactionFormTemplate") private transactionFormTemplate;

  transactionForm: FormGroup;
  transactionModalTitle: string;
  transactionFormModal: NgbModalRef;
  selectedTransaction: Transaction;

  get transactionConfirmDeleteOptions() {
    return new ConfirmOptionsService({
      title: "Elimina transazione",
      text: "Sei sicuro di voler rimuovere questa transazione?",
    });
  }

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public service: TransactionService,
    private modalService: NgbModal,
    public router: Router,
    public route: ActivatedRoute,
    public authenticationService: AuthenticationService
  ) {
    this.dealerId = this.route.snapshot.paramMap.get("id");
  }

  ngOnInit() {
    this.refresh();

    this.transactionForm = this.formBuilder.group({
      descrizione: [null, [Validators.required]],
      valore: [null, [Validators.required]],
    });
  }

  refresh() {
    this.busy = this.service
      .list({ id: this.dealerId, "per-page": 0 })
      .subscribe((data: any) => {
        this.rows = new Transaction().fromArray(data);

        this.processStatistics();
      });
  }

  processStatistics() {
    this.credito = this.rows
      .filter((item) => item.isAccredito)
      .reduce((prev, curr) => {
        return prev + curr.valore;
      }, 0);

    this.debito = this.rows
      .filter((item) => item.isAddebbito)
      .reduce((prev, curr) => {
        return prev + curr.valore;
      }, 0);
  }

  showCreateTransactionModal() {
    this.selectedTransaction = new Transaction({ id_dealer: this.dealerId });
    this.transactionModalTitle = "Nuova transazione";
    this.transactionFormModal = this.modalService.open(
      this.transactionFormTemplate,
      {
        size: "lg",
      }
    );
    this.transactionFormModal.result.then((res) => {}).catch((res) => {});
  }

  showUpdateTransactionModal(transaction: Transaction) {
    this.selectedTransaction = new Transaction(transaction);
    this.transactionModalTitle = `Aggiorna transazione ${transaction.descrizione}`;
    this.transactionFormModal = this.modalService.open(
      this.transactionFormTemplate,
      {
        size: "lg",
      }
    );
  }

  saveTransaction(transaction: Transaction) {
    let payload = {
      descrizione: transaction.descrizione,
      valore: transaction.valore,
      id_dealer: transaction.id_dealer,
      saldato: transaction.saldato,
    };

    if(transaction.segno) {
      payload['segno'] = transaction.segno;
    }
    else {
      if (parseFloat(transaction.valore.toString()) > 0) {
        payload["segno"] = 0;
      } else {
        payload.valore = -payload.valore;
        payload["segno"] = 1;
      }
    }

    let isNew = transaction.id ? false : true;
    let request = isNew
      ? this.service.saveTransaction(payload)
      : this.service.updateTransaction(transaction.id, payload);

    this.busy = request.subscribe(
      (data: any) => {
        let savedTransaction = new Transaction(data);
        savedTransaction.isNew = isNew;

        this.updateLocalStateTransaction(savedTransaction);
        this.transactionFormModal.close();
        this.toastr.success(
          `Transazione ${isNew ? "creata" : "aggiornata"} con successo`,
          "Notifica"
        );
      },
      (err: any) =>
        this.toastr.error(
          `Si è verificato un errore durante la ${
            isNew ? "creazione" : "aggiornamento"
          } della transazione`,
          "Errore"
        )
    );
  }

  deleteTransaction(transaction: Transaction) {
    let request = this.service.deleteTransaction(transaction.id);

    this.busy = request.subscribe((data: any) => {
      if (data === null) {
        this.deleteLocalStateCategory(transaction);
      }
    });
  }

  deleteLocalStateCategory(transaction: Transaction) {
    let index = this.rows.findIndex(function (o) {
      return o.id === transaction.id;
    });
    if (index !== -1) {
      this.rows.splice(index, 1);
    }
    this.processStatistics();
  }

  updateLocalStateTransaction(transaction: Transaction) {
    for (let i in this.rows) {
      if (this.rows[i].id == transaction.id) {
        this.rows[i] = transaction;
        return;
      }
    }
    let newTransaction = this.rows.find((c) => {
      return c.id !== transaction.id;
    });
    if (newTransaction || this.rows.length === 0) {
      this.rows.push(transaction);
    }

    this.processStatistics();
  }

    getTotal(rows: Transaction[], i: number) {
        let total = 0;
        for(let j = 0; j <= i; j++) {
            total += rows[j].segno === 0 ? rows[j].valore : rows[j].valore * -1;
        }
        return total;
    }
}
