<app-master-header cardClass="bg-gradient-1"
                   title="Gestione Presidi"
                   image="assets/img/flats/stall.png"
                   [ngBusy]="busy"
>
    <p class="lead">Da qui puoi gestire i presidi e le risorse all'interno</p>
</app-master-header>

<div class="mb-3">
    <button class="btn btn-primary" routerLink="/gestione-stand/2">Mostra tutti i presidi</button>
    <button class="btn btn-success ml-3" routerLink="/gestione-stand/0">Mostra solo attivi</button>
    <button class="btn btn-danger ml-3" routerLink="/gestione-stand/1">Mostra solo cancellati</button>
    <button class="btn btn-dark float-right" routerLink="/gestione-stand/nuovo/stand">Aggiungi Presidio</button>
    <button class="btn btn-warning float-right mr-2" routerLink="/gestione-stand/-1/venditori">Mostra tutti i venditori</button>
</div>

<app-master-card
        title="Lista Presidi"
        bodyClass="p-0"
>
    <app-table
            [rows]="data.rows"
            [columns]="data.columns"
            [small]="true"
            [pageSize]="20"
            [hideFooterDescription]="true"
    >
    </app-table>
</app-master-card>
