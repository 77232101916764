import {Component, OnInit, ViewChild} from '@angular/core';
import {MetaPagination} from '@models/meta-pagination';
import {Moment} from 'moment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Popup, PopupPagination} from '@models/popup';
import * as moment from 'moment';
import {PopupService} from '@services/popup.service';
import {ConfirmOptionsService} from '@services/confirm-options.service';
import {TinyMCE} from '@models/tinyMCE';

@Component({
    selector: 'app-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {
    popups: Popup[] = [];
    popupsPagination: MetaPagination;
    filter = {
        date: ''
    };
    filterApiDate = {
        from: '',
        to: ''
    };
    apiDate = {
        start_date: '',
        end_date: ''
    };
    selectedPopup: Popup;
    @ViewChild('popupFormTemplate') private popupFormTemplate;
    busy: any;
    popupForm = new FormGroup({
        title: new FormControl(''),
        description: new FormControl('', Validators.required),
        start_date: new FormControl('', Validators.required),
        end_date: new FormControl('', Validators.required),
    });
    pickerOptions = {
        singleDatePicker: true,
        ranges: []
    };
    defaultConfig = new TinyMCE();
    deleteOptions: ConfirmOptionsService;
    localFormat = 'DD/MM/YYYY';

    constructor(public modalService: NgbModal,
                private spinner: NgxSpinnerService,
                private dateRangePickerOptions: DaterangepickerConfig,
                private popupService: PopupService) {
        this.deleteOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro!',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });
        this.defaultConfig.menubar = true;
        this.defaultConfig.plugins = [
            'advlist autolink lists link image charmap print preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars code fullscreen',
            'insertdatetime media nonbreaking save table contextmenu directionality',
            'emoticons template paste textcolor colorpicker textpattern'
        ];
    }

    ngOnInit(): void {
        this.loadPopups();
    }

    loadPopups() {
        this.spinner.show('table-popup');
        const params = {};
        if (this.popupsPagination?.currentPage) {
            params['page'] = this.popupsPagination?.currentPage;
        }
        if (this.filterApiDate?.from) {
            params['from'] = this.filterApiDate.from;
        }
        if (this.filterApiDate?.to) {
            params['to'] = this.filterApiDate.to;
        }
        this.popupService.getPopup(params).subscribe((response: PopupPagination) => {
            this.popups = response.data;
            this.popupsPagination = response._meta;
            this.spinner.hide('table-popup');
        }, err => {
            this.spinner.hide('table-popup');
        });
    }

    showModal(popup?) {
        this.selectedPopup = popup;
        if (this.selectedPopup) {
            this.loadFormData();
        } else {
            this.popupForm.reset();
            this.popupForm.get('start_date').setValue(moment().format(this.dateRangePickerOptions.settings.locale.format));
            this.apiDate.start_date = moment().format(this.dateRangePickerOptions.settings.locale.apiFormat);
        }
        this.modalService.open(this.popupFormTemplate, {size: 'lg'});
    }

    public generateDateRangeString(startDate: Moment, endDate: Moment) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        this.filterApiDate.from = startDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.filterApiDate.to = endDate.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.filter.date = startDate.format(localDateFormat)
            + this.dateRangePickerOptions.settings.locale.separator + endDate.format(localDateFormat);
    }

    selectedDate(value, date: Moment) {
        const selectedDate = date.format(this.dateRangePickerOptions.settings.locale.format);
        this.apiDate[value] = date.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.popupForm.get(value).setValue(selectedDate);
    }

    savePopup() {
        const popup = new Popup();
        popup.title = this.popupForm.value.title;
        popup.description = this.popupForm.value.description;
        popup.start_date = this.apiDate.start_date;
        popup.end_date = this.apiDate.end_date;
        if (this.selectedPopup) {
            return this.changePopup(popup);
        }
        return this.addPopup(popup);
    }

    changePopup(popup: Popup) {
        this.busy = this.popupService.putPopup(this.selectedPopup.id, popup).subscribe((response: Popup) => {
            this.modalService.dismissAll();
            this.popupService.successAlert('Changed Popup!');
            this.loadPopups();
        }, err => {
            this.popupService.errorAlert(err);
            this.modalService.dismissAll();
        });
    }

    addPopup(popup: Popup) {
        this.busy = this.popupService.postPopup(popup).subscribe((response: Popup) => {
            this.modalService.dismissAll();
            this.popupService.successAlert('Created Popup!');
            this.loadPopups();
        }, err => {
            this.popupService.errorAlert(err);
            this.modalService.dismissAll();
        });
    }

    deletePopup(id: number) {
        this.popupService.deletePopup(id).subscribe(() => {
            this.popupService.successAlert('Deleted Document!');
            this.loadPopups();
        }, (err) => {
            this.popupService.errorAlert(err);
        });
    }

    loadFormData() {
        this.popupForm.setValue({
            title: this.selectedPopup?.title,
            description: this.selectedPopup?.description,
            start_date: this.selectedPopup.start_date ? moment(this.selectedPopup.start_date)
                .format(this.dateRangePickerOptions.settings.locale.format) : '',
            end_date: this.selectedPopup.end_date ? moment(this.selectedPopup.end_date)
                .format(this.dateRangePickerOptions.settings.locale.format) : '',
        });
        this.apiDate = {
            start_date: this.selectedPopup.start_date ? moment(this.selectedPopup.start_date)
                .format(this.dateRangePickerOptions.settings.locale.apiFormat) : '',
            end_date: this.selectedPopup.end_date ? moment(this.selectedPopup.end_date)
                .format(this.dateRangePickerOptions.settings.locale.apiFormat) : '',
        };
    }
}
