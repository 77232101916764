import {Component, OnInit} from '@angular/core';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {NgxSpinnerService} from 'ngx-spinner';
import * as moment from 'moment';
import {MasterPlanningService} from '@services/master-planning.service';
import {Segment} from '@models/segment';
import {MapTypeStyle} from '@agm/core/services/google-maps-types';
import {environment} from '@environments/environment';
import * as XLSX from 'xlsx';

moment.locale(environment.language);

@Component({
    selector: 'app-dealer-map',
    templateUrl: './dealer-map.component.html',
    styleUrls: ['./dealer-map.component.css']
})
export class DealerMapComponent implements OnInit {
    filter = {
        date: '',
        province_id: '',
        region_id: '',
        agent_id: '',
        master_agent_id: '',
        vendor_id: 2
    };
    selectedDate = {
        from: '',
        to: ''
    };
    segments: Segment[] = [];
    defaultStyles: MapTypeStyle[] = [
        {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [
                {visibility: 'off'}
            ]
        }
    ];
    dealerFields = [
        {
            label: 'All dealers',
            key: 'all-dealers',
            checked: true
        },
        {
            label: 'Productive dealers',
            key: 'productive-dealers',
            checked: false
        },
        {
            label: 'Unproductive dealers',
            key: 'unproductive-dealers',
            checked: false
        }
    ];
    cityFields = [
        {
            label: 'Cities with dealers',
            key: 'cities-with-dealers',
            checked: false
        },
        {
            label: 'Cities without dealers',
            key: 'cities-without-dealers',
            checked: false
        }
    ];
    dealers = [];
    citiesWithShops = [];
    citiesWithoutShops = [];
    greenPinOptions = {
        url: 'assets/img/flats/green-pin.png',
        scaledSize: {
            width: 30,
            height: 48
        }
    };
    redPinOptions = {
        url: 'assets/img/flats/red-pin.png',
        scaledSize: {
            width: 30,
            height: 45
        }
    };
    mapPinOptions = {
        url: 'assets/img/flats/pin.png',
        scaledSize: {
            width: 30,
            height: 45
        }
    };
    monthFormat = 'YYYY-MM';
    cities = [];
    isDealerMap = true;
    isLoadingMap = false;
    isDisabled = false;

    constructor(private dateRangePickerOptions: DaterangepickerConfig,
                private spinner: NgxSpinnerService,
                private masterPlanningService: MasterPlanningService) {
        this.loadSegments();
        this.selectedDateRange(moment().startOf('month'), moment().endOf('month'));
    }

    ngOnInit(): void {
        this.loadDealerSales();
    }

    public selectedDateRange(startDate: Moment, endDate: Moment) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        const start = moment(startDate);
        const end = moment(endDate);
        this.selectedDate.from = start.format(this.monthFormat);
        this.selectedDate.to = end.format(this.monthFormat);
        this.filter.date = start.format(localDateFormat) +
            +this.dateRangePickerOptions.settings.locale.separator + end.format(localDateFormat);
    }

    changeRegion(event) {
        this.filter.region_id = event;
        if (!event) {
            this.filter.province_id = '';
        }
    }

    changeProvince(event) {
        this.filter.province_id = event;
        if (!event) {
            this.filter.region_id = '';
        }
    }

    loadSegments() {
        this.masterPlanningService.getOperators().subscribe((segments: Segment[]) => {
            if (segments.length > 0) {
                this.segments = segments;
                this.filter.vendor_id = segments[0].id;
            }
        });
    }

    loadDealerSales() {
        this.isDisabled = true;
        this.isLoadingMap = true;
        this.spinner.show('map');
        const params = {};
        if (this.filter.master_agent_id) {
            params['master_agent_id'] = this.filter.master_agent_id;
        }
        if (this.filter.agent_id) {
            params['agent_id'] = this.filter.agent_id;
        }
        if (this.filter.region_id) {
            params['region_id'] = this.filter.region_id;
        }
        if (this.filter.province_id) {
            params['province_id'] = this.filter.province_id;
        }
        if (this.filter.date) {
            params['from'] = this.selectedDate.from;
            params['to'] = this.selectedDate.to;
        }
        params['productive'] = this.dealerFields[1].checked ? 1 : 0;
        params['unproductive'] = this.dealerFields[2].checked ? 1 : 0;
        params['vendor_segment_id'] = this.filter.vendor_id ? this.filter?.vendor_id : 2;
        this.masterPlanningService.getDealerSales(params).subscribe((response) => {
            this.dealers = response;
            this.spinner.hide('map');
            this.isDisabled = false;
        }, err => {
            this.spinner.hide('map');
        });
    }

    loadCitiesWithShops() {
        const params = {};
        if (this.filter.region_id) {
            params['region_id'] = this.filter.region_id;
        }
        if (this.filter.province_id) {
            params['province_id'] = this.filter.province_id;
        }
        this.masterPlanningService.getCitiesWithShops(params).subscribe((response) => {
            this.citiesWithShops = response;
            this.loadCitiesTable(response);
            this.spinner.hide('map');
        }, err => {
            this.spinner.hide('map');
        });
    }

    loadCitiesWithoutShops() {
        const params = {};
        if (this.filter.region_id) {
            params['region_id'] = this.filter.region_id;
        }
        if (this.filter.province_id) {
            params['province_id'] = this.filter.province_id;
        }
        this.masterPlanningService.getCitiesWithoutShops(params).subscribe((response) => {
            this.citiesWithoutShops = response;
            this.loadCitiesTable(response);
            this.spinner.hide('map');
        }, err => {
            this.spinner.hide('map');
        });
    }

    refreshCitiesInMap() {
        this.spinner.show('map');
        this.isDealerMap = false;
        this.clearMap();
        if (this.cityFields[0].checked) {
            this.loadCitiesWithShops();
        }
        if (this.cityFields[1].checked) {
            this.loadCitiesWithoutShops();
        }
    }

    clearMap() {
        this.dealers = [];
        this.cities = [];
        this.citiesWithoutShops = [];
        this.citiesWithShops = [];
    }

    refreshDealersInMap() {
        this.isDealerMap = true;
        this.clearMap();
        this.loadDealerSales();
    }

    loadData() {
        return this.isDealerMap ? this.refreshDealersInMap() : this.refreshCitiesInMap();
    }

    loadCitiesTable(data) {
        if (this.cities?.length <= 0) {
            return this.cities = data;
        }
        return this.cities.concat(data);
    }

    exportTable() {
        const list = this.dealers?.length > 0 ? this.dealers : this.cities?.length > 0 ? this.cities : [];
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(list);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'export.xlsx');
    }
}
