import {Component, OnInit, ViewChild} from '@angular/core';
import {BankAccount, BankAccountPagination} from '@models/invoice/bank-account';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InvoiceDocumentService} from '@services/invoice-document.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {MetaPagination} from '@models/meta-pagination';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {fadeOutCollapseOnLeaveAnimation} from 'angular-animations';

@Component({
    selector: 'app-bank-account',
    templateUrl: './bank-account.component.html',
    styleUrls: ['./bank-account.component.css']
})
export class BankAccountComponent implements OnInit {
    bankAccounts: BankAccount[] = [];
    bankAccountsPagination: MetaPagination;
    busy: any;
    filter = {
        bank_name: '',
        account_number: '',
        file_type: ''
    };
    bankAccountForm = new FormGroup({
        bank_name: new FormControl('', Validators.required),
        account_number: new FormControl('', Validators.required),
        file_type: new FormControl('', Validators.required)
    });
    types = InvoiceDocumentService.bankAccountTypes;
    @ViewChild('bankAccountFormTemplate') private bankAccountFormTemplate;

    constructor(private modalService: NgbModal,
                private invoiceDocumentService: InvoiceDocumentService,
                private spinner: NgxSpinnerService) {
    }

    ngOnInit(): void {
        this.loadBankAccounts();
    }

    showModal() {
        this.modalService.open(this.bankAccountFormTemplate, {size: 'md'});
    }

    loadBankAccounts() {
        console.log(this.filter.file_type);
        this.spinner.show('table-bank-accounts');
        const params = {};
        if (this.bankAccountsPagination?.currentPage) {
            params['page'] = this.bankAccountsPagination.currentPage;
        }
        if (this.filter.bank_name) {
            params['bank_name'] = this.filter.bank_name;
        }
        if (this.filter.account_number) {
            params['account_number'] = this.filter.account_number;
        }
        if (this.filter.file_type) {
            params['file_type'] = this.filter.file_type;
        }
        this.invoiceDocumentService.getBankAccounts(params).subscribe((response: BankAccountPagination) => {
            this.bankAccounts = response.data;
            this.bankAccountsPagination = response._meta;
            this.bankAccounts.forEach(account => {
                const type = this.types.find(item => item.key === account?.file_type);
                account.file_type_name = type ? type.name : account.file_type;
            });
            this.spinner.hide('table-bank-accounts');
        }, err => {
            this.spinner.hide('table-bank-accounts');
        });
    }

    saveBankAccount() {
        const bankAccount = new BankAccount();
        bankAccount.bank_name = this.bankAccountForm.value.bank_name;
        bankAccount.account_number = this.bankAccountForm.value.account_number;
        bankAccount.file_type = this.bankAccountForm.value.file_type;
        this.busy = this.invoiceDocumentService.postBankAccount(bankAccount).subscribe((response: BankAccount) => {
            this.bankAccounts.unshift(response);
            this.invoiceDocumentService.successAlert('Created Bank Account!');
            this.dismissModal();
        }, err => {
            this.invoiceDocumentService.errorAlert(err);
            this.dismissModal();
        });
    }

    dismissModal() {
        this.bankAccountForm.setValue({
            bank_name: '',
            account_number: '',
            file_type: ''
        });
        this.modalService.dismissAll();
    }

    resetFilter() {
        this.filter = {
            bank_name: '',
            account_number: '',
            file_type: ''
        };
    }
}
