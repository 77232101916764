<div class="card bg-gradient-light">
    <div class="card-header bg-gradient-info">
        <p class="lead mb-0">Carrello</p>
    </div>
    <div class="card-body py-2">
        <div class="cart-list">
            <ng-container *ngFor="let item of this.cartService.cart.items">
                <div class="row">
                    <div class="col-6 cart-item-name">
                        <i class="fa"
                           [ngClass]="{'fa-box': item.subProducts.length === 0, 'fa-boxes': item.subProducts.length > 0}"></i>
                        {{ item.product.productname }}
                    </div>
                    <div class="col-3 cart-item-quantity text-center">
                        <ng-container *ngIf="item.subProducts.length === 0 && !item.product?.variant">
                            <i class="fa fa-caret-down text-danger"
                               (click)="cartService.removeFromCart(item.product)"></i>
                            <span>{{ item.quantity }}</span>
                            <i class="fa fa-caret-up text-success" (click)="cartService.addToCart(item.product)"></i>
                        </ng-container>
                        <span *ngIf="item.product?.variant">{{item?.product?.variant?.quantity }}</span>
                    </div>
                    <div class="col-3 cart-item-price text-right">
                        <span *ngIf="cartService.cart.payment == 2 && item?.product?.prezzoacquisto">{{  item.product.prezzoacquisto | currency: '€ '}}</span>
                        <span *ngIf="cartService.cart.payment != 2 || !item?.product?.prezzoacquisto">{{  item.product.unit_price | currency: '€ '}}</span>
                    </div>
                </div>
                <div class="row cart-sub-product" *ngFor="let subItem of item.subProducts">
                    <div class="col-6 cart-item-name">
                        <i class="fa fa-box"></i> {{ subItem.product.productname }}
                    </div>
                    <div class="col-3 cart-item-quantity text-center">
                        <span>{{ subItem.quantity }}</span>
                    </div>
                    <div class="col-3 cart-item-price text-right"></div>
                </div>
            </ng-container>
            <div class="row" *ngIf="cartService.cart.items.length === 0">
                <div class="col-12 cart-item-name">
                    Non ci sono articoli nel carrello
                </div>
            </div>
            <ng-container *ngFor="let item of this.cartService.cart.taxes">
                <div class="row cart-summary">
                    <div class="col-6 cart-item-name">
                        <i class="fa fa-file-invoice"></i> {{ item.tax.name }}
                    </div>
                    <div class="col-3 cart-item-quantity text-center">

                    </div>
                    <div class="col-3 cart-item-price text-right">{{ item.value | currency: '€ '}}</div>
                </div>
            </ng-container>
            <div class="row cart-summary" *ngIf="cartService.cart.vat > 0">
                <div class="col-6 cart-item-name">
                    <i class="fa fa-file-invoice"></i> IVA al 22%
                </div>
                <div class="col-3 cart-item-quantity text-center">

                </div>
                <div class="col-3 cart-item-price text-right">{{ cartService.cart.vat | currency: '€ '}}</div>
            </div>
            <div class="row cart-summary">
                <div class="col-6 cart-item-name">
                    <i class="fa fa-file-invoice-dollar"></i> Totale Carrello
                </div>
                <div class="col-3 cart-item-quantity text-center">

                </div>
                <div class="col-3 cart-item-price text-right">{{ cartService.cartGrandTotal | currency: '€ '}}</div>
            </div>
        </div>
    </div>
    <div class="card-footer" *ngIf="!hideFooter">
        <div class="row">
            <div class="col-6">
                <button class="btn bg-gradient-secondary btn-block btn-sm"
                        [disabled]="cartService.cart.items.length === 0"
                        (click)="cartService.emptyCart()"><i class="fa fa-trash"></i> Svuota
                </button>
            </div>
            <div class="col-6">
                <button class="btn bg-gradient-success btn-block btn-sm" *ngIf="cartService.cart.items.length > 0"
                        [routerLink]="['/negozio/carrello']"><i
                        class="fa fa-shopping-cart"></i> Acquista
                </button>
            </div>
        </div>
    </div>
</div>
