import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {RequestService} from '@services/request.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PresidiService {

  ENDPOINT = 'presidi';

  constructor(public request: RequestService) {
  }

  list(params = {}) {
    return this.request
      .get(`${this.ENDPOINT}/list`, params, { observe: "response" })
      .pipe(
        map((data: HttpResponse<any>) => {
          return {
            rows: data.body,
            pagination: {
              totalCount: parseInt(data.headers.get("X-Pagination-Total-Count")),
              page: parseInt(data.headers.get("X-Pagination-Current-Page")),
              pageSize: parseInt(data.headers.get("X-Pagination-Per-Page")),
              pages: parseInt(data.headers.get("X-Pagination-Page-Count")),
            },
          };
        })
      );
  }
}
