import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DashboardService} from '@services/dashboard.service';

@Component({
    selector: 'app-master-nuove-affiliazioni',
    templateUrl: './master-nuove-affiliazioni.component.html',
    styleUrls: ['./master-nuove-affiliazioni.component.css']
})
export class MasterNuoveAffiliazioniComponent implements OnInit {

    busy: Subscription;

    urlSubheader: string;
    urlAgenti: string;
    urlRegioni: string;
    urlAgentiTabella: string;
    urlImproduttiviTabella: string;
    urlNegoziTabella: string;

    constructor() {
    }

    ngOnInit() {
    }

    updateUrls(operatore, periodo) {
        this.urlSubheader = `/nuove-affiliazioni-header?operatore=${operatore}&periodo=${periodo}`;
        this.urlAgenti = `/nuove-affiliazioni-agenti?operatore=${operatore}&periodo=${periodo}`;
        this.urlNegoziTabella = `-detail/affiliazioni?param=&datasource=pdv_nuovi&operatore=${operatore}&output=lista_negozi`;
        this.urlRegioni = `/nuove-affiliazioni-regioni?operatore=${operatore}&periodo=${periodo}`;
        this.urlAgentiTabella = `/nuove-affiliazioni-dettaglio?operatore=${operatore}&periodo=${periodo}`;
        this.urlImproduttiviTabella = `/nuove-affiliazioni-improduttivi?operatore=${operatore}&periodo=${periodo}`;
    }

}
