import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {AuthenticationService} from '@services/authentication.service';
import {User} from '@models/user';

@Component({
    selector: 'app-master-produzione-persa',
    templateUrl: './master-produzione-persa.component.html',
    styleUrls: ['./master-produzione-persa.component.css']
})
export class MasterProduzionePersaComponent implements OnInit {
    busy: Subscription;

    urlSubheader: string;
    urlNegoziImproduttivi: string;
    urlNegoziMenoProduttivi: string;

    private user: User;
    stand = false;

    constructor(
        private authService: AuthenticationService) {
        this.authService.currentUser.subscribe((user) => (this.user = user));

        this.stand = this.user && this.user.profile.operatori.includes('Kena Mobile Stands');
    }

    updateUrls(operatore, from, to) {
        const fromMoment = moment(from);
        const toMoment = moment(to);
        let fromDate = {
            start: fromMoment.startOf('month').format('YYYY-MM-DD'),
            end: fromMoment.endOf('month').format('YYYY-MM-DD'),
        };
        let endDate = {
            start: toMoment.startOf('month').format('YYYY-MM-DD'),
            end: toMoment.endOf('month').format('YYYY-MM-DD')
        };

        if (moment(to).format('YYYY-MM') === moment().format('YYYY-MM')) {
            endDate.end = moment().format('YYYY-MM-DD');
            fromDate.end = moment(from).set('date', parseInt(moment().format('DD'))).format('YYYY-MM-DD');
        }

        this.urlSubheader = `sales/lost-production?vendor=${operatore}&date[current_month]=${endDate.start};${endDate.end}&date[sales1]=${fromDate.start};${fromDate.end}`;
        this.urlNegoziImproduttivi = `sales/shops-no-more-productive?vendor=${operatore}&date[current_month]=${endDate.start};${endDate.end}&date[sales1]=${fromDate.start};${fromDate.end}`;
        this.urlNegoziMenoProduttivi = `sales/shops-less-productive?vendor=${operatore}&date[current_month]=${endDate.start};${endDate.end}&date[sales1]=${fromDate.start};${fromDate.end}`;
    }

    ngOnInit(): void {
    }

}
