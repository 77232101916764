import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';

export class BankAccount extends BaseModel {
    id: number;
    account_number: number;
    bank_name: string;
    file_type: string;
    file_type_name?: string;
}

export class BankAccountPagination extends BaseModel {
    data: BankAccount[];
    _meta: MetaPagination;
    _links: Array<any>;
}
