<div class="row" [ngBusy]="busy">
    <div class="col-3">
        <div class="info-box elevation-1 bg-gradient-light py-2" *ngFor="let box of detailBoxes">
            <span class="info-box-icon mx-3">
                <img [src]="box.image" height="70px">
            </span>
            <div class="info-box-content">
                <p class="lead mb-0">{{box.title}}</p>
                <h1 class="display-7 mb-0">{{box.description}}</h1>
            </div>
        </div>
    </div>
    <div class="col-9">
        <app-master-header
                cardClass="bg-gradient-dark"
                image="assets/img/flats/shopping-cart.png"
                title="Centralino - Dettaglio Operatore"
                [smallTitle]="true"
        >
            <div class="row align-items-end">
                <div class="col-6">
                    <label>Seleziona periodo</label>
                    <div class="form-group form-group-icon mb-0">
                        <i class="far fa-calendar-alt"></i>
                        <select class="form-control" [(ngModel)]="this.periodo">
                            <ng-container *ngIf="this.startup">
                                <option *ngFor="let per of this.startup.periodi"
                                        [value]="per.value">{{per.label}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <label>Seleziona tipo</label>
                    <div class="form-group form-group-icon mb-0">
                        <i class="far fa-calendar-alt"></i>
                        <select class="form-control" [(ngModel)]="this.tipo">
                            <ng-container *ngIf="this.startup">
                                <option *ngFor="let per of this.startup.tipi" [value]="per.value">{{per.label}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>
            </div>
        </app-master-header>
        <div>
            <app-autoload-table
                    [url]="urlDettaglio"
            ></app-autoload-table>
        </div>
    </div>
</div>
