<div class="card bg-gradient-light">
    <form (ngSubmit)="loadOrders()">
        <div class="card-header">
            <h1 class="card-title" translate>Filters</h1>
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-12 col-lg">
                    <label for="orderId" translate>ID</label>
                    <input id="orderId" type="text" name="orderId" [(ngModel)]="filter.order_id"
                           class="form-control form-control-sm"
                    />
                </div>
                <div class="form-group col-12 col-lg-3">
                    <label for="dealer" translate>Dealer</label>
                    <app-retailer-typeahead (onChange)="filter.dealer_id = $event" id="dealer"
                                            [small]="true"></app-retailer-typeahead>
                </div>
                <div class="form-group col-12 col-lg-3">
                    <label for="agent" translate>Agent</label>
                    <app-agent-select id="agent" [small]="true"
                                      (onChange)="filter.agent_id = $event"></app-agent-select>
                </div>
                <div class="form-group col-12 col-lg-3">
                    <label for="date" translate>Date</label>
                    <input id="date"
                           type="text" daterangepicker class="form-control form-control-sm"
                           [(ngModel)]="filter.date" name="date"
                           (selected)="generateDateRangeString($event.start, $event.end)"
                    />
                </div>
                <div class="form-group col-12 col-lg-3">
                    <label for="status" translate>Status</label>
                    <ng-select id="status" class="form-control-sm" [(ngModel)]="filter.status" name="status"
                               [items]="statuses" bindValue="key" bindLabel="label" (change)="restartCounter()">
                    </ng-select>
                </div>
                <div class="form-group col-12 col-lg-3">
                    <label for="payment" translate>Payment</label>
                    <ng-select id="payment" class="form-control-sm" [(ngModel)]="filter.payment" name="payment"
                               [items]="payments" bindValue="key" bindLabel="label">
                    </ng-select>
                </div>
                <div class="form-group col-12 col-lg-3">
                    <label for="product" translate>Product</label>
                    <input id="product" type="text" [(ngModel)]="filter.product_name" name="productName"
                           class="form-control form-control-sm"
                    />
                </div>
                <div class="form-group col-12 col-lg">
                    <label for="noteFilter" translate>Note</label>
                    <input id="noteFilter" type="text" [(ngModel)]="filter.note" name="note"
                           class="form-control form-control-sm"
                    />
                </div>
                <div class="form-group col-12 col-lg">
                    <label for="noteOpFilter" translate>Note Operator</label>
                    <input id="noteOpFilter" type="text" [(ngModel)]="filter.note_op" name="note_op"
                           class="form-control form-control-sm"
                    />
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button class="btn btn-dark btn-sm mr-2" type="button" (click)="resetFilter()" translate>
                Reset
            </button>
            <button
                    class="btn btn-primary btn-sm"
                    type="submit" translate
            >
                Filter
            </button>
        </div>
    </form>
</div>
<div class="card">
    <div class="card-header">
        <div class="row d-flex justify-content-between">
            <div class="col">
                <h4 translate>Orders</h4>
            </div>
            <div class="col d-flex justify-content-end align-items-center" *ngIf="selectedStatus == -2">
                <countdown #countdown [config]="countdownConfig" (event)="refreshOrders($event)"
                >
                </countdown>
                <span class="pl-2">
                    <i class="fas fa-sync-alt text-info link" (click)="refreshOrders({action: 'done'})"></i>
                </span>
            </div>
        </div>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <table class="table table-sm">
            <thead>
            <tr>
                <th></th>
                <th translate>ID</th>
                <th translate>Company name</th>
                <th translate>Agent</th>
                <th translate>Date</th>
                <th translate class="text-right">Order total</th>
                <th translate>Payment type</th>
                <th translate class="text-center">Plafond status</th>
                <th translate class="text-center">Order status</th>
                <th translate class="text-right">Convalid</th>
            </tr>
            </thead>
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="table-orders"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tbody class="bg-gradient-white" *ngFor="let order of orders;index as i">
            <tr>
                <td>
                    <a class="btn btn-outline-info btn-sm text-center" type="button"
                       (click)="loadDetails(order,'collapse'+order?.id)" [id]="order?.id"
                       data-toggle="collapse" [href]="'#collapse'+order?.id" role="button" aria-expanded="false"
                       translate>
                        <i class="fas fa-plus mr-0"></i>
                        <i class="fas fa-minus mr-0"></i>
                    </a>
                </td>
                <td>{{order?.id}}</td>
                <td>{{order?.dealer?.company_name}}</td>
                <td>{{order?.agent?.company_name}}</td>
                <td>{{order.created|amDateFormat:localTimeFormat}}</td>
                <td class="text-right">{{order?.amount|currency:currency}}</td>
                <td>
                    <i class="fas fa-check-circle text-success"
                       *ngIf="(order.payment == 0 && order?.file_path)
                    || (order.payment == 2 && order?.paid == 1)"></i>
                    <i class="fas fa-times-circle text-danger" *ngIf="(order.payment == 0 && !order?.file_path)
                    || (order.payment == 2 && order?.paid == 0)"></i>
                    <a  href="javascript:void(0)" *ngIf="order?.can_edit"
                       (click)="openPopover(paymentPopover,order?.id,order?.payment,'pagamento',payments)"
                       #paymentPopover="ngbPopover" autoClose="outside" triggers="manual"
                       [ngbPopover]="popContent" popoverTitle="Cambia valore" translate>
                        {{order?.payment_name}}
                    </a>
                    <span *ngIf="!order?.can_edit"> {{order?.payment_name}}</span>
                    <span *ngIf="order?.is_compensation == 1"> + Compensazione</span>
                </td>
                <td class="text-center">
                    <a *ngIf="order?.is_plafond" href="javascript:void(0)" [disablePopover]="!order?.can_edit"
                       (click)="openPopover(plafondStatusPopover,order?.id,order?.plafond_status,'plafond_status',plafondStatuses)"
                       #plafondStatusPopover="ngbPopover" autoClose="outside" triggers="manual"
                       [ngbPopover]="popContent" popoverTitle="Cambia valore">
                        <i class="text-success fas fa-check" *ngIf="order?.plafond_status == 1"></i>
                        <i class="text-danger fas fa-times" *ngIf="order?.plafond_status == 0"></i>
                    </a>
                </td>
                <td class="text-center">
                    <a *ngIf="order?.can_edit" href="javascript:void(0)"
                       (click)="openPopover(statusPopover,order?.id,order?.status,'stato',statuses)"
                       #statusPopover="ngbPopover" autoClose="outside" triggers="manual"
                       [ngbPopover]="popContent" popoverTitle="Cambia valore">
                        {{order?.status_name}}
                    </a>
                    <span *ngIf="!order?.can_edit"> {{order?.status_name}}</span>
                </td>
                <td class="text-right">
                    <div class="custom-control custom-switch" *ngIf="order?.is_sim">
                        <input type="checkbox" class="custom-control-input" [id]="'switch'+order.id"
                               [disabled]="!order?.can_edit"
                               [(ngModel)]="order.convalid" (ngModelChange)="convalidOrder(order)">
                        <label class="custom-control-label" [for]="'switch'+order.id"></label>
                    </div>
                </td>
            </tr>
            <tr class="collapse" [id]="'collapse'+order?.id">
                <td colspan="11" class="py-4">
                    <app-shop-order-detail [order]="order" (refresh)="loadOrders(true,true)"></app-shop-order-detail>
                </td>
            </tr>
            </tbody>
            <tbody *ngIf="orders?.length<=0">
            <tr>
                <td translate colspan="11">List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="orders?.length>0">
    <span><span translate>There are</span><b> {{ this.ordersPagination?.totalCount }}</b>
        {{this.ordersPagination?.totalCount <= 1 ? 'order' : 'orders'|translate}}.</span>
        <ngb-pagination *ngIf="ordersPagination.pageCount>1"
                        size="sm"
                        [pageSize]="ordersPagination.perPage"
                        [maxSize]="10"
                        [(page)]="ordersPagination.currentPage"
                        [collectionSize]="ordersPagination.totalCount"
                        (pageChange)="loadOrders()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>

<ng-template #popContent>
    <app-edit-popup [config]="popupConfig" (onSave)="save($event)"></app-edit-popup>
</ng-template>
