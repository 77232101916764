import {Component, OnInit} from '@angular/core';
import {DataService} from '@services/data.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {environment} from '@environments/environment';

@Component({
    selector: 'app-filled-pdf',
    templateUrl: './filled-pdf.component.html',
    styleUrls: ['./filled-pdf.component.scss']
})
export class FilledPdfComponent implements OnInit {
    public uploadSuccess = false;
    busy: Subscription;

    pageCountError = '';
    fileTypeError = '';

    public id: string;
    environment = environment;

    constructor(
        public dataService: DataService,
        public route: ActivatedRoute
    ) {
        this.id = this.route.snapshot.paramMap.get('id');
    }

    ngOnInit() {
    }

    uploadContratto($event: any) {
        this.pageCountError = '';
        this.fileTypeError = '';
        const file = $event.target.files.item(0);

        if (!file) {
            return;
        }

        if (file.type !== 'application/pdf') {
            this.fileTypeError = 'Il file non è un PDF. Per favore carica un PDF.';
            return;
        }

        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('id', this.route.snapshot.paramMap.get('id'));
        formData.append('tipologia', 'pda');

        this.busy = this.dataService.post('/carica-documento',
            formData
        ).subscribe((data: any) => {
            if (data.success) {
                this.uploadSuccess = true;
            }
        });

        // this.readFile(file)
        //     .then((result: ArrayBuffer) => {
        //         PDFDocument.load(result)
        //             .then((pdf) => {
        //                 // console.log(pdf.getPageCount());
        //                 //          if (pdf.getPageCount() < this.service.neededPages) {
        //                 //              this.pageCountError = `Il PDF caricato contiene ${pdf.getPageCount()}
        //                 // pagine e non le ${this.service.neededPages} pagine richieste.`;
        //                 //          } else {
        //                 const formData = new FormData();
        //                 formData.append('file', file, file.name);
        //                 formData.append('id', this.route.snapshot.paramMap.get('id'));
        //                 formData.append('tipologia', 'pda');
        //
        //                 this.busy = this.dataService.post('/carica-documento',
        //                     formData
        //                 ).subscribe((data: any) => {
        //                     if (data.success) {
        //                         this.uploadSuccess = true;
        //                     }
        //                 });
        //                 // }
        //             });
        //     });
    }

    // readFile = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //
    //         reader.onload = () => resolve(reader.result);
    //         reader.onerror = error => reject(error);
    //
    //         reader.readAsArrayBuffer(file);
    //     });
    // };
}
