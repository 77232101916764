<h4 translate>Filters</h4>
<div class="card bg-gradient-white elevation-1 category p-3 mb-4">
<form (ngSubmit)="loadBrands()">
    <div class="form-row d-flex justify-content-between">
        <div class="col-xl-4 col-md-6 col-sm-12 mt-auto mb-0">
            <div class="form-group">
                <label translate for="name">Name</label>
                <input type="text" class="form-control" id="name" [(ngModel)]="filter.name" name="name">
            </div>
        </div>
        <div class="col-xl-2 col-md-6 col-sm-12 mt-auto mb-0">
            <div class="form-group">
                <button class="btn btn-info btn-block" type="submit" translate>Filter
                </button>
            </div>
        </div>
    </div>
</form>
</div>
<div class="card">
    <div class="card-header">
        <div class="row">
            <div class="col-lg-10 col-12">
                <h4 class="mb-0" translate>Brands</h4>
            </div>
            <div class="col-lg-2 col-12">
                <button class="btn btn-success btn-sm float-right" translate (click)="showModal()">
                    Add Brand
                </button>
            </div>
        </div>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th translate>Brand</th>
                <th translate class="text-center">Active</th>
                <th></th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="table-brands"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let brand of brands">
                <td>
                    <div class="media d-flex align-items-center">
                        <div class="logo-img">
                            <img class="mr-3"
                                 [src]="brand.image_path"
                                 alt="">
                        </div>
                        <div class="media-body ml-2">
                            <h5 class="mt-0 mb-0"><strong>{{brand.name}}</strong></h5>
                            <small>
                                <app-rich-text-truncate [text]="brand.description"></app-rich-text-truncate>
                            </small>
                        </div>
                    </div>
                </td>
                <td class="text-center">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" [id]="'switch'+brand.id"
                               [(ngModel)]="brand.is_active" (ngModelChange)="changeStatus(brand)">
                        <label class="custom-control-label" [for]="'switch'+brand.id"></label>
                    </div>
                </td>
                <td class="text-right">
                    <button class="btn btn-link">
                        <i class="fa fa-edit text-muted" (click)="showModal(brand)"></i>
                    </button>
                    <button class="btn btn-link" [swal]="deleteBrandOptions"
                            (confirm)="deleteBrand(brand.id)">
                        <i class="fa fa-trash text-danger"></i>
                    </button>
                </td>
            </tr>
            <tr *ngIf="brands.length<=0">
                <td translate colspan="3" class="text-muted">List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="brands.length>0 && brandsPagination">
    <span><span translate>There are</span><b> {{ this.brandsPagination.totalCount }}</b>
        {{this.brandsPagination.totalCount <= 1 ? 'brand' : 'brands'}}.</span>
        <ngb-pagination *ngIf="brandsPagination.pageCount>1"
                        size="sm"
                        [pageSize]="brandsPagination.perPage"
                        [maxSize]="10"
                        [(page)]="brandsPagination.currentPage"
                        [collectionSize]="brandsPagination.totalCount"
                        (pageChange)="loadBrands()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>

<ng-template #brandFormTemplate let-modal>
    <app-brand-modal [brandModal]="brandModal" [brands]="brands" [selectedBrand]="selectedBrand"></app-brand-modal>
</ng-template>
