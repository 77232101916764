<div class="card bg-gradient-1">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                 width="160px"
                 src="assets/img/flats/mail.png">
            <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>E-mail Campaign</h1>
                <hr>
                <form (ngSubmit)="loadCampaigns()">
                    <div class="form-row align-items-end">
                        <div class="col-lg-5 col-12"><label for="dueDate" translate>Date</label>
                            <input id="dueDate" class="form-control" type="text" daterangepicker
                                   [(ngModel)]="filter.date" name="date"
                                   (selected)="setSelectedDateRange($event.start, $event.end)"
                            />
                        </div>
                        <div class="col-lg-5 col-12"><label for="type" translate>Type</label>
                            <ng-select id="type" [items]="types" bindLabel="label" bindValue="key" name="type"
                                       [(ngModel)]="filter.type"></ng-select>
                        </div>
                        <div class="col-lg-2 col-12 mt-2 mt-lg-auto">
                            <button class="btn btn-info btn-block" type="submit" translate>Filter
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-header">
        <h4 class="mb-0" translate>Campaigns</h4>
    </div>
    <div class="card-body table-responsive">
        <table class="table">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="campaign-table"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tbody>
            <tr *ngFor="let campaign of campaigns">
                <td class="status-icon">
                    <i class="far fa-check-circle text-success"></i>
                </td>
                <td>
                    <div class="d-flex w-100 justify-content-between">
                        <div>
                            <h5 class="mb-1">{{campaign.title}} - {{campaign.type}}</h5>
                            <p class="mb-1">{{campaign.subject_line}}</p>
                            <p class="mb-1">
                                {{campaign.send_time|amDateFormat:localFormat}}</p>
                            <small class="text-muted">{{campaign.preview_text}}</small>
                        </div>
                    </div>
                </td>
                <td>
                    <strong class="w-100 d-flex justify-content-center">{{campaign.emails_sent}}
                    </strong>
                    <p class="mb-1 text-muted w-100 d-flex justify-content-center" translate>E-mails sent</p>
                </td>
                <td>
                    <strong class="w-100 d-flex justify-content-center align-items-end">{{campaign.clicks_total}}
                        <small class="pl-1">{{campaign.click_rate}}%</small></strong>
                    <p class="mb-1 text-muted w-100 d-flex justify-content-center">Clicks</p>
                </td>
                <td>
                    <strong class="w-100 d-flex justify-content-center align-items-end">{{campaign.opens_total}}
                        <small class="pl-1"> {{campaign.open_rate}}%</small></strong>
                    <p class="mb-1 text-muted w-100 d-flex justify-content-center" translate>Opens</p>
                </td>
                <td class="text-right">
                    <div class="btn-group">
                        <button type="button" class="btn btn-info dropdown-toggle" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false" translate>
                            View Report
                        </button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="javascript:void(0)" translate
                               (click)="showReportModal(campaign, true)">Recipients</a>
                            <a class="dropdown-item" href="javascript:void(0)" translate
                               (click)="showReportModal(campaign,false,true)">Clicks</a>
                            <a class="dropdown-item" href="javascript:void(0)" translate
                               (click)="showReportModal(campaign,false,false,true)">Opens</a>
                            <a class="dropdown-item" href="javascript:void(0)" translate
                               (click)="showReportModal(campaign,false,false,false,true)">Preview</a>
                        </div>
                    </div>
                </td>
            </tr>
            <tr *ngIf="campaigns?.length<=0">
                <td translate>List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="campaigns?.length>0">
    <span><span translate>There are</span><b> {{ this.totalItems }}</b>
        {{this.totalItems <= 1 ? 'campaign' : 'campaigns'|translate}}.</span>
        <ngb-pagination
                size="sm"
                [pageSize]="10"
                [maxSize]="10"
                [(page)]="currentPage"
                [collectionSize]="totalItems"
                (pageChange)="loadCampaigns()"
                [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>
<ng-template #reportTemplate let-modal>
    <app-e-mail-campaign-modal [campaign]="selectedCampaign" [report]="report"></app-e-mail-campaign-modal>
</ng-template>
