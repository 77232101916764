import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '@app/services/authentication.service';
import {ROLE_AGENT, ROLE_DEALER, ROLE_MASTER, ROLE_OPERATOR} from '@models/user';

@Injectable({providedIn: 'root'})
export class RoleGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser?.loggedIn && currentUser?.profile?.is_psw_expired) {
            this.router.navigate(['/change-password']);
            return false;
        }
        if (route.data.roles.indexOf('demo') !== -1 && currentUser?.profile?.id?.toString() === '169') {
            return true;
        }

        if (route.data.roles && route.data.roles.indexOf(currentUser?.profile?.role) !== -1) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/'], {queryParams: {returnUrl: state.url}});
        return false;
    }
}
