import {Component, OnInit, Renderer2} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DealerService} from '@services/dealer.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@services/authentication.service';
import {CustomValidators} from '@helpers/custom-validators';
import {first} from 'rxjs/operators';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
    passwordForm: FormGroup;
    busy: any;
    currentUser;
    isGuestUser = false;

    constructor(private renderer: Renderer2,
                private dealerService: DealerService,
                private toastr: ToastrService,
                private router: Router,
                private authenticationService: AuthenticationService,
                private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.currentUser = this.authenticationService.currentUserValue;
        this.renderer.addClass(document.body, 'login-page');
        this.passwordForm = new FormGroup({
            old_password: new FormControl('', [
                Validators.required,
            ]),
            new_password: new FormControl('', [
                Validators.required,
                CustomValidators.patternValidator(/\d/, {hasNumber: true}),
                CustomValidators.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
                CustomValidators.patternValidator(/[a-z]/, {hasSmallCase: true}),
                CustomValidators.patternValidator(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/, {hasSpecialCharacters: true}),
                Validators.minLength(8),
            ]),
            password_repeat: new FormControl('', [
                Validators.required,
            ])
        }, {
            validators: this.password.bind(this)
        });
        this.activatedRoute.queryParams.subscribe(params => {
            this.isGuestUser = false;
            const token = params['token'];
            if (token && !this.currentUser?.loggedIn) {
                this.isGuestUser = true;
                this.authenticationService.setCurrentUserTokenInLS(token);
            } else if ((!token && !this.currentUser?.loggedIn) || !this.currentUser?.profile?.is_psw_expired) {
                 window.location.href = '/';
            }
            if (!this.isGuestUser) {
                this.passwordForm.controls['old_password'].setValidators(Validators.required);
            } else {
                this.passwordForm.controls['old_password'].clearValidators();
            }
            this.passwordForm.get('old_password').updateValueAndValidity();
        });
    }

    changePassword() {
        const body = {
            new_password: this.passwordForm.value.new_password,
            password_repeat: this.passwordForm.value.password_repeat,
        };
        if (!this.isGuestUser) {
            body['old_password'] = this.passwordForm.value.old_password;
        }
        this.busy = this.dealerService.editPassword(body, this.isGuestUser).subscribe((response) => {
            this.toastr.success(
                'Password cambiata con successo!'
            );
            this.authenticationService.login(response?.username, this.passwordForm.value?.new_password)
                .pipe(first())
                .subscribe(
                    (data) => {
                        this.authenticationService.listenForUpdates();
                        window.location.href = '/';
                    },
                    (error) => {
                        this.toastr.error(
                            error,
                            'An error was occurred!'
                        );
                    }
                );
        }, error => {
            this.toastr.error(
                error,
                'An error was occurred!'
            );
        });
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    password(formGroup: FormGroup) {
        const {value: password} = formGroup.get('new_password');
        const {value: confirmPassword} = formGroup.get('password_repeat');
        return password === confirmPassword ? null : formGroup.get('password_repeat').setErrors({passwordNotMatch: true});
    }

    get f() {
        return this.passwordForm.controls;
    }
}
