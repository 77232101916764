import {CartProduct} from '@models/shop/cart-product';
import {Product} from '@models/product';
import {CartSubProduct} from '@models/shop/cart-sub-product';
import {CartTax} from '@models/shop/cart-tax';

export class Cart {
    items: CartProduct[] = [];
    taxes: CartTax[];
    vat = 0;
    payment = null

    constructor() {
    }

    addProduct(product: Product, subProducts: CartSubProduct[] = []) {
        const found = this.items.findIndex((item) => item.id === product.id);

        if (found === -1) {
            const item = new CartProduct();
            item.product = product;
            item.id = product.id;
            item.quantity = 1;
            item.subProducts = subProducts;
            this.items.push(item);
        } else {
            this.items[found].quantity++;

            for (const subProduct of subProducts) {
                const subProductIndex = this.items[found].subProducts.findIndex((item) => item.id === subProduct.id);
                const curQty = this.items[found].subProducts[subProductIndex].quantity;
                this.items[found].subProducts[subProductIndex].quantity = parseInt(String(subProduct.quantity), 10) + parseInt(String(curQty), 10);
            }
        }
    }

    fromJSON(value: Cart) {
        const cart = new Cart();
        Object.assign(cart, value);
        return cart;
    }

    removeProduct(product: Product) {
        const found = this.items.findIndex((item) => item.id === product.id);

        if (this.items[found].quantity === 1) {
            this.items.splice(found, 1);
        } else {
            this.items[found].quantity--;
        }
    }
}

