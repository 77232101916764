<div class="row" [ngBusy]="busy">
    <div class="col-6">
        <h1 class="display-5">{{this.c().nome.value}}</h1>
    </div>
    <div class="col-6">
        <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a routerLink="/gestione-stand">Gestione Presidi</a></li>
        </ol>
    </div>
    <div class="col-12" *ngIf="!this.new">
        <div class="card bg-gradient-light">
            <div class="card-header">
                <h1 class="display-6 mb-0">Statistiche attivazioni</h1>
            </div>
            <div class="card-body p-0">
                <app-table [rows]="data.statistiche.rows" [columns]="data.statistiche.columns" [hideFooter]="true" [hideHeader]="true"></app-table>
            </div>
        </div>
    </div>
    <div [ngClass]="{'col-6': true, 'offset-3': this.new}">
        <div class="card bg-gradient-light">
            <div class="card-header">
                <h1 class="display-6 mb-0">Anagrafica Presidio</h1>
            </div>
            <div class="card-body">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>ID Presidio</label>
                                <input type="text" class="form-control form-control-sm" formControlName="id" disabled="disabled"/>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Attivo</label>
                                <select formControlName="deleted" class="form-control-sm form-control">
                                    <option value="0">Si</option>
                                    <option value="1">No</option>
                                </select>
                                <val-errors controlName="deleted"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Nome</label>
                                <input type="text" class="form-control form-control-sm" formControlName="nome"/>
                                <val-errors controlName="nome"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Accesso</label>
                                <input type="text" class="form-control form-control-sm" formControlName="accesso"/>
                                <val-errors controlName="accesso"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Username KeApp</label>
                                <input type="text" class="form-control form-control-sm" formControlName="user"/>
                                <val-errors controlName="password"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Password KeApp</label>
                                <input type="text" class="form-control form-control-sm" formControlName="password"/>
                                <val-errors controlName="password"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>E-Mail</label>
                                <input type="text" class="form-control form-control-sm" formControlName="email"/>
                                <val-errors controlName="email"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Area Manager</label>
                                <ng-select bindLabel="name"
                                           bindValue="id"
                                           class="form-control-sm"
                                           formControlName="id_areamanager"
                                           [items]="this.data.am"
                                ></ng-select>
                                <val-errors controlName="id_areamanager"></val-errors>
                            </div>
                        </div>

                        <div class="col-12">
                            <legend>Ubicazione</legend>
                            <hr>
                        </div>

                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Regione</label>
                                <ng-select bindLabel="regione"
                                           bindValue="regione"
                                           class="form-control-sm"
                                           formControlName="regione"
                                           [items]="this.regioni"
                                ></ng-select>
                            </div>
                            <val-errors controlName="regione"></val-errors>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Provincia</label>
                                <ng-select bindLabel="provincia"
                                           bindValue="provincia"
                                           class="form-control-sm"
                                           formControlName="provincia"
                                           (change)="this.setComuni($event)"
                                           [items]="this.province"
                                ></ng-select>
                            </div>
                            <val-errors controlName="provincia"></val-errors>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Comune</label>
                                <ng-select [items]="this.comuni"
                                           bindLabel="comune"
                                           bindValue="comune"
                                           class="form-control-sm"
                                           formControlName="comune"
                                ></ng-select>
                            </div>
                            <val-errors controlName="comune"></val-errors>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Indirizzo</label>
                                <input type="text" class="form-control form-control-sm" formControlName="indirizzo"/>
                            </div>
                            <val-errors controlName="indirizzo"></val-errors>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-footer text-right">
                <button class="btn btn-success" (click)="save()">Salva</button>
            </div>
        </div>
    </div>

    <div class="col-xxl-3 col-xl-6" *ngIf="!this.new">
        <div class="card bg-gradient-light">
            <div class="card-header">
                <h1 class="display-6 mb-0">Contratti</h1>
            </div>
            <div class="card-body px-0">
                <div *ngFor="let ctr of data.contratti; let i = index;">
                    <div class="box-hover-dark rounded px-2 mx-2" (click)="editContratto(ctr)">
                        <p>
                            <i class="fa fa-file-pdf cursor-hand mr-2"></i>
                            <strong>{{ ctr.tipo_contratto }}</strong>
                            <span class="float-right">{{ctr.stato}}</span>
                        </p>
                        <p class="text-muted">
                            {{ctr.inizio}} - {{ctr.fine}}
                        </p>
                    </div>
                    <hr *ngIf="i < data.contratti.length-1">
                </div>
            </div>
            <div class="card-footer">
                <button class="btn btn-success btn-block btn-sm" (click)="this.formContratto.reset();ctrModal.open()">Nuovo Contratto</button>
            </div>
        </div>
    </div>
</div>

<app-modal title="Contratto" #ctrModal [showFooter]="false">
    <form [formGroup]="formContratto">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>Inizio Contratto</label>
                    <input type="text" ngbDatepicker #inizio_contratto="ngbDatepicker" placeholder="dd-mm-yyyy"
                           (click)="inizio_contratto.toggle()" class="form-control form-control-sm" formControlName="inizio"/>
                    <val-errors controlName="inizio"></val-errors>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Fine Contratto</label>
                    <input type="text" ngbDatepicker #fine_contratto="ngbDatepicker" placeholder="dd-mm-yyyy"
                           (click)="fine_contratto.toggle()" class="form-control form-control-sm" formControlName="fine"/>
                    <val-errors controlName="fine"></val-errors>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Tipo Contratto</label>
                    <select class="form-control form-control-sm" formControlName="tipo_contratto">
                        <option [value]="null">Seleziona un tipo di  contratto</option>
                        <option value="Settimanale">Settimanale</option>
                        <option value="Mensile">Mensile</option>
                        <option value="Annuale">Annuale</option>
                    </select>
                    <val-errors controlName="tipo_contratto"></val-errors>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Fitto</label>
                    <input type="text" class="form-control form-control-sm" formControlName="fitto"/>
                    <val-errors controlName="fitto"></val-errors>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label>Stato Contratto</label>
                    <select class="form-control form-control-sm" formControlName="stato">
                        <option [value]="null">Seleziona uno stato</option>
                        <option value="In revisione">In revisione</option>
                        <option value="Accettato">Accettato</option>
                        <option value="Rifiutato">Rifiutato</option>
                    </select>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label>Contratto</label>
                    <input type="file" (change)="setContrattoFile($event)" *ngIf="!this.formContratto.controls.id.value"/>
                    <div class="embed-responsive embed-responsive-1by1" *ngIf="this.formContratto.controls.id.value">
                        <iframe [src]="this.formContratto.controls.percorso.value | safe" class="embed-responsive-item"></iframe>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <button *ngIf="this.formContratto.controls.id.value" class="btn btn-sm btn-danger btn-block mb-2" (click)="delContratto()">
                    Cancella Contratto
                </button>
                <button class="btn-success btn btn-block btn-sm"
                        (click)="saveContratto()"
                        [disabled]="this.formContratto.invalid || (!this.contrattoFile && !this.formContratto.controls.id.value)"
                >
                    <ng-container *ngIf="!this.formContratto.controls.id.value">Aggiungi Contratto</ng-container>
                    <ng-container *ngIf="this.formContratto.controls.id.value">Salva</ng-container>
                </button>
            </div>
        </div>
    </form>
</app-modal>
