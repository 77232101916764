import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ProductInstallmentService} from '@services/product-installment.service';
import {InstallmentFile, InstallmentsPagination, InstallmentType, ProductInstallment} from '@models/product';
import {NgxSpinnerService} from 'ngx-spinner';
import {MetaPagination} from '@models/meta-pagination';
import {DocumentCategory} from '@models/invoice/document';

@Component({
    selector: 'app-shop-product-installments',
    templateUrl: './shop-product-installments.component.html',
    styleUrls: ['./shop-product-installments.component.css']
})
export class ShopProductInstallmentsComponent implements OnInit {
    @ViewChild('fileFormTemplate') private fileFormTemplate;

    installments: ProductInstallment[] = [];
    installmentsPagination: MetaPagination;

    fileBusy: any;
    fileForm = new FormGroup({
        file: new FormControl('', Validators.required)
    });
    submittedFile: File;

    filter = {
        product_name: '',
        nmu: '',
        type: null,
        fin_code: ''

    };

    types: InstallmentType[];
    typeLoading = false;

    get file() {
        return this.fileForm.get('file').value;
    }

    constructor(
        private modalService: NgbModal,
        private installmentsService: ProductInstallmentService,
        private spinner: NgxSpinnerService
    ) {
    }

    ngOnInit(): void {
        this.loadInstallments();
        this.loadTypes();
    }

    loadInstallments() {
        this.spinner.show('table-installments');
        const params = {};
        if (this.installmentsPagination?.currentPage) {
            params['page'] = this.installmentsPagination?.currentPage;
        }
        if (this.filter.product_name) {
            params['product_name'] = this.filter.product_name;
        }
        if (this.filter.nmu) {
            params['nmu'] = this.filter.nmu;
        }
        if (this.filter.type) {
            params['type'] = this.filter.type;
        }
        if (this.filter.fin_code) {
            params['fin_code'] = this.filter.fin_code;
        }
        params['expand'] = 'stocks';
        // params['sort'] = 'stocks';
        this.installmentsService.getInstallments(params).subscribe((response: InstallmentsPagination) => {
            this.installments = response.data;
            this.installmentsPagination = response._meta;
            this.spinner.hide('table-installments');
        }, err => {
            this.spinner.hide('table-installments');
        });
    }

    loadTypes(term?) {
        this.typeLoading = true;
        const params = {};
        if (term) {
            params['name'] = term;
        }
        this.installmentsService.getTypes(params).subscribe((response: InstallmentType[]) => {
            this.types = response;
            this.typeLoading = false;
        }, err => {
            this.typeLoading = false;
        });
    }

    showModal() {
        this.modalService.open(this.fileFormTemplate, {size: 'md'});
    }

    dismissModal() {
        this.handleFileRemoveModal();
        this.fileForm.get('file').setValue('');
        this.modalService.dismissAll();
    }

    handleFileInput(file: File) {
        this.submittedFile = file;
        this.fileForm.get('file').setValue(file.name);
    }

    handleFileRemoveModal() {
        this.fileForm.get('file').setValue('');
        this.submittedFile = null;
    }

    saveFile() {
        const installment = new InstallmentFile();
        installment.file = this.submittedFile;
        this.fileBusy = this.installmentsService.postInstallmentsFromFile(installment).subscribe(() => {
            this.installmentsService.successAlert('Uploaded File!');
            this.dismissModal();
        }, err => {
            this.installmentsService.errorAlert(err);
            this.dismissModal();
        });
    }


    resetFilter() {
        this.filter = {
            product_name: '',
            nmu: '',
            type: null,
            fin_code: ''
        };
    }
}
