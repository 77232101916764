<ngx-spinner [fullScreen]="false" bdColor="rgba(255, 255, 255, .7)"
             color="#333333" name="popupSpinner"
             class="h-100 w-100"
             type="ball-clip-rotate-multiple"
             size="small">
</ngx-spinner>
<form class="d-flex align-items-center my-2 w-100  popup-container" (ngSubmit)="save()" [formGroup]="orderForm">
    <div class="form-group mr-1  my-auto w-100" *ngIf="!config?.isSelect">
        <input type="text" class="pl-2 form-control" id="input" formControlName="value">
    </div>
    <div class="form-group mr-1 my-auto w-100" *ngIf="config?.isSelect">
        <ng-select formControlName="value" [items]="config?.items" bindLabel="label" bindValue="key">
        </ng-select>
    </div>
    <button class="btn btn-info mr-1" type="submit" [disabled]="orderForm.invalid">
        <i class="fa fa-check m-0"></i></button>
    <button class="btn btn-light" type="button" (click)="closePopover()"><i
            class="fa fa-times m-0"></i>
    </button>
</form>
