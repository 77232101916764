import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';
import {UsedPrize} from '@models/campaign';

export class Prize extends BaseModel {
    id: number;
    campaign_id: number;
    title: string;
    required_points: number;
    image_name: string;
    image_path: string;
    description: string;
    used_prize: UsedPrize;
    is_used: boolean;
    file: File;

    fromArray(result: []) {
        const array: Prize[] = [];
        for (const item of result) {
            array.push(new Prize(item));
        }
        return array;
    }

    get postPayloadPrize() {
        const payload = new FormData();
        this.addToPayload(payload, this.campaign_id, 'campaign_id');
        this.addToPayload(payload, this.title, 'title');
        this.addToPayload(payload, this.required_points, 'required_points');
        this.addToPayload(payload, this.description, 'description');
        this.addToPayload(payload, this.image_name, 'image_name');
        this.addToPayload(payload, this.file, 'file');
        return payload;
    }
}
export class PrizeListPagination extends BaseModel {
    data: Prize[];
    _meta: MetaPagination;
    _links: Array<any>;
}
