import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DashboardService} from '@services/dashboard.service';

@Component({
    selector: 'app-master-mappa',
    templateUrl: './master-mappa.component.html',
    styleUrls: ['./master-mappa.component.css']
})
export class MasterMappaComponent implements OnInit {

    busy: Subscription;
    startup: any;
    markers: [];

    _zona: string;
    _operatore: string;

    urlSubheader: string;
    urlBrand: string;
    urlComuni: string;

    set zona(value: string) {
        this._zona = value;
        this.updateUrls();
    }

    get zona() {
        return this._zona;
    }

    set operatore(value: string) {
        this._operatore = value;
        this.updateUrls();
    }

    get operatore() {
        return this._operatore;
    }

    constructor(private dashboardService: DashboardService) {
    }

    ngOnInit() {
        this.busy = this.dashboardService.get('/mappa')
            .subscribe((data) => {
                this.startup = data.result;
                this.zona = this.startup.zona;
                this.operatore = this.startup.operatore;
                this.updateUrls();
            }, (error) => {
                this.updateUrls();
            });
    }

    updateUrls(specific = null) {
        if (!specific || specific === 'subheader') {
            this.urlSubheader = `/mappa-header?zona=${this.zona}&operatore=${this.operatore}`;
        }
        if (!specific || specific === 'subheader') {
            this.urlBrand = `/mappa-brand?zona=${this.zona}`;
        }
        if (!specific || specific === 'subheader') {
            this.urlComuni = `/mappa-comuni?zona=${this.zona}&operatore=${this.operatore}`;
        }

        this.dashboardService.get( `/mappa-markers?zona=${this.zona}&operatore=${this.operatore}`)
            .subscribe((data) => {
                this.markers = data.result;
            });
    }
}
