<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)"
             size="default"
             color="#fff"
             type="square-jelly-box"
             name="loader-fatturato"
             [fullScreen]="false">
    <p style="color: white"> Caricamento... </p>
</ngx-spinner>

<div class="row py-3 justify-content-center" *ngFor="let row of data">
    <div *ngFor="let col of row; let i = index;" class="d-flex align-items-center {{col.size}}" [ngClass]="{'border-right': i < row.length-1}">
        <img [src]="col.image" height="45px" class="d-inline-flex" />
        <div class="d-inline-block pl-2">
            <p class="lead">{{col.title}}</p>
            <p>{{col.subtitle}}</p>
        </div>
    </div>
</div>
