import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';
import {ShopOrder} from '@models/e-pay/shop-order';

export class ShopDealerCredit extends BaseModel {
    id: number;
    total_amount: string;
    company_name: string;
    company_owner: string;
}

export class ShopDealerCreditPagination extends BaseModel {
    data: ShopDealerCredit[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class ShopCredit extends BaseModel {
    id: number;
    dealer_id: number;
    order_id: number;
    amount: string;
    created_at: string;
    order: ShopOrder;
    is_credit: boolean;
    current_sum: string;
    operator: string;
    note: string;
    status: string;
    service: string;
    serviceType?: {
        label: string,
        key: string,
        path: string
    };
}

export class ShopCreditPagination extends BaseModel {
    data: ShopCredit[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class ShopCreditReport extends BaseModel {
    credit: string;
    debit: string;
    total_amount: string;
    total_transaction: string;
}
