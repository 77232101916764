import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DashboardService} from '@services/dashboard.service';

@Component({
    selector: 'app-master-centralino-operatore',
    templateUrl: './master-centralino-operatore.component.html',
    styleUrls: ['./master-centralino-operatore.component.css']
})
export class MasterCentralinoOperatoreComponent implements OnInit {
    busy: Subscription;
    startup: any;

    _periodo: string;
    _tipo: string;

    urlSubheader: string;
    urlDettaglio: string;
    detailBoxes = [];

    set periodo(value: string) {
        this._periodo = value;
        this.updateUrls();
    }

    get periodo() {
        return this._periodo;
    }

    set tipo(value: string) {
        this._tipo = value;
        this.updateUrls();
    }

    get tipo() {
        return this._tipo;
    }

    constructor(private dashboardService: DashboardService) {
    }

    ngOnInit() {
        this.busy = this.dashboardService.get('/centralino-operatore')
            .subscribe((data) => {
                this.startup = data.result;
                this.periodo = this.startup.periodo;
                this.tipo = this.startup.tipo;
                this.updateUrls();
            }, (error) => {
                this.updateUrls();
            });
    }

    updateUrls(specific = null) {
        if (!specific || specific === 'subheader') {
            this.urlDettaglio = `/centralino-operatore-dettaglio?periodo=${this.periodo}&tipo=${this.tipo}`;
        }

        this.dashboardService.get(`/centralino-operatore-boxes?periodo=${this.periodo}`)
            .subscribe((data) => {
                this.detailBoxes = data.result;
            }, (error) => {
                this.updateUrls();
            });
    }


}
