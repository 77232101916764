<div *showDashboard>
    <h1><i class="fab fa-whatsapp text-success"></i> Statistiche Whatsapp</h1>
    <hr>
</div>

<div class="row">
    <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name='report'
            [fullScreen]="false"
    >
    </ngx-spinner>
    <div class="col-6">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-4 text-center border-right">
                        <img src="/assets/img/flats/chat.png" height="64" />
                        <p class="lead mt-2">Chat arrivate oggi</p>
                        <h1 class="display-5">0</h1>
                    </div>
                    <div class="col-4 text-center border-right">
                        <img src="/assets/img/flats/checked.png" height="64" />
                        <p class="lead mt-2">Chat gestite oggi</p>
                        <h1 class="display-5">0</h1>
                    </div>
                    <div class="col-4 text-center">
                        <img src="/assets/img/flats/settings_2.png" height="64" />
                        <p class="lead mt-2">Chat in gestione oggi</p>
                        <h1 class="display-5">0</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-4 text-center border-right">
                        <img src="/assets/img/flats/calendar.png" height="64" />
                        <p class="lead mt-2">Chat ancora in gestione</p>
                        <h1 class="display-5">0</h1>
                    </div>
                    <div class="col-4 text-center border-right">
                        <img src="/assets/img/flats/hourglass.png" height="64" />
                        <p class="lead mt-2">Velocità di risposta</p>
                        <h1 class="display-5">0</h1>
                    </div>
                    <div class="col-4 text-center">
                        <img src="/assets/img/flats/time.png" height="64" />
                        <p class="lead mt-2">Tempo medio di gestione</p>
                        <h1 class="display-5">0</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="false">
    <div class="col-12 col-lg-3">
        <div class="info-box bg-gradient-white elevation-1">
      <span class="info-box-icon">
        <img src="/assets/img/flats/chat.png"/>
      </span>

            <div class="info-box-content">
                <span class="info-box-text text-lg">Chat totali</span>
                <span class="info-box-number text-xl">{{ overview.total_chats }}</span>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-3">
        <div class="info-box bg-gradient-white elevation-1">
      <span class="info-box-icon">
        <img src="/assets/img/flats/settings_2.png"/>
      </span>

            <div class="info-box-content">
                <span class="info-box-text text-lg">Chat in gestione</span>
                <span class="info-box-number text-xl">{{ overview.active_chats }}</span>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-3">
        <div class="info-box bg-gradient-white elevation-1">
      <span class="info-box-icon">
        <img src="/assets/img/flats/hourglass.png"/>
      </span>

            <div class="info-box-content">
                <span class="info-box-text text-lg">Chat da gestire</span>
                <span class="info-box-number text-xl">{{
                    overview.pending_chats
                    }}</span>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12 col-lg-6">
        <div class="card">
            <div class="card-header bg-gradient-success">
                <h1 class="card-title">Clienti in attesa di risposta</h1>
            </div>
            <div class="card-body p-0">
                <table class="table">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Cliente</th>
                        <th>Key Account</th>
                        <th class="text-center">Non lette</th>
                        <th>Attende da</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let chat of this.chatsWaitingReply">
                        <td><img class="table-avatar" [src]="chat.contact_image"/></td>
                        <td>{{ chat.name }}</td>
                        <td>{{ chat.agent }}</td>
                        <td class="text-center">{{ chat.meta.unreadCount }}</td>
                        <td>{{ chat.elapsedTimeSinceLastMessage }}</td>
                        <td>
                            <i
                                    class="fas fa-search text-info link"
                                    (click)="loadPreview(preview, chat)"
                                    triggers="manual"
                                    #preview="ngbPopover"
                                    [ngbPopover]="chatPreview"
                            ></i>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6">
        <div class="card">
            <div class="card-header bg-gradient-success">
                <h1 class="card-title">Chat in gestione</h1>
            </div>
            <div class="card-body p-0">
                <table class="table">
                    <thead>
                    <tr>
                        <th>Key Account</th>
                        <th class="text-center">Chat gestite</th>
                        <th class="text-center">Messaggi inviati</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let rank of this.ranking">
                        <td>{{ rank.agent }}</td>
                        <td class="text-center">{{ rank.chats }}</td>
                        <td class="text-center">{{ rank.messages }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #chatPreview>
    <ngx-spinner
            [fullScreen]="false"
            bdColor="rgba(255, 255, 255, .7)"
            color="#333333"
            name="previewSpinner"
            class="h-100 w-100"
            type="ball-clip-rotate-multiple"
            size="small"
    >
    </ngx-spinner>
    <div class="direct-chat-messages">
        <ng-container *ngFor="let message of this.previewMessages">
            <div
                    class="direct-chat-msg"
                    [ngClass]="{
          right: message.flow === 'outbound',
          clearfix: message.flow === 'inbound'
        }"
            >
                <div class="direct-chat-text">
                    {{ message.body }}
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
