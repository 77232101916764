<div class="row" [ngBusy]="busy">
  <div class="col-6">
    <div class="card bg-gradient-light">
      <div class="card-header bg-gradient-info">
        <h1 class="display-7 mb-0">Documenti per il cliente</h1>
      </div>
      <div class="card-body p-0">
        <div class="embed-responsive embed-responsive-1by1">
          <iframe class="embed-responsive-item"
                  [src]="this.environment.apiUrl + '/get-pda?id=' + this.id | safe"></iframe>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="card bg-gradient-info">
      <div class="card-header">
        <h1 class="display-7 mb-0">Istruzioni</h1>
      </div>
      <div class="card-body">
        <ul class="timeline">
          <li>
            <span class="font-weight-bold">1</span>
            <div class="timeline-container">
              <p class="timeline-header">Scarica il contratto da far firmare al cliente</p>
              <p class="timeline-content">Puoi stamparlo nella finestra a sinistra cliccando il simbolo <i
                class="ml-2 fa fa-print"></i></p>
            </div>
          </li>
          <li>
            <span class="font-weight-bold">2</span>
            <div class="timeline-container">
              <p class="timeline-header">Fai firmare il contratto dove richiesto</p>
            </div>
          </li>
          <li>
            <span class="font-weight-bold">3</span>
            <div class="timeline-container">
              <p class="timeline-header">Carica il contratto firmato</p>
              <div *ngIf="!this.uploadSuccess" class="card bg-gradient-light elevation-1 mt-2">
                <div class="card-body">
                  <div class="form-group has-error mb-0">
                    <div class="custom-file" id="customFile" lang="it">
                      <input type="file" class="custom-file-input" (change)="uploadContratto($event)"
                             id="exampleInputFile" aria-describedby="fileHelp" accept="application/pdf">
                      <label class="custom-file-label" for="exampleInputFile">
                        Seleziona il contratto firmato
                      </label>
                      <div class="invalid-feedback" [ngClass]="{'d-block': this.pageCountError || this.fileTypeError}">
                        <ng-container *ngIf="this.pageCountError">{{pageCountError}}</ng-container>
                        <ng-container *ngIf="this.fileTypeError">{{fileTypeError}}</ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p *ngIf="this.uploadSuccess" class="timeline-content mt-2">Hai caricato il contratto firmato correttamente</p>
            </div>
          </li>
<!--          <li>-->
<!--            <span class="font-weight-bold">4</span>-->
<!--            <div class="timeline-container">-->
<!--              <p class="timeline-header">Carica la fattura</p>-->
<!--              <div *ngIf="!this.uploadSuccess" class="card bg-gradient-light elevation-1 mt-2">-->
<!--                <div class="card-body">-->
<!--                  <div class="form-group has-error mb-0">-->
<!--                    <div class="custom-file" id="customFile" lang="it">-->
<!--                      <input type="file" class="custom-file-input" (change)="uploadContratto($event)"-->
<!--                             id="exampleInputFile" aria-describedby="fileHelp" accept="application/pdf">-->
<!--                      <label class="custom-file-label" for="exampleInputFile">-->
<!--                        Seleziona il contratto firmato-->
<!--                      </label>-->
<!--                      <div class="invalid-feedback" [ngClass]="{'d-block': this.pageCountError || this.fileTypeError}">-->
<!--                        <ng-container *ngIf="this.pageCountError">{{pageCountError}}</ng-container>-->
<!--                        <ng-container *ngIf="this.fileTypeError">{{fileTypeError}}</ng-container>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <p *ngIf="this.uploadSuccess" class="timeline-content mt-2">Hai caricato il contratto firmato correttamente</p>-->
<!--            </div>-->
<!--          </li>-->
        </ul>

        <a class="btn btn-light btn-block" [routerLink]="['/contratti/lista']"><i class="fa fa-list-ul mr-2"></i> Vai
          alla lista contratti</a>
      </div>
    </div>
  </div>
</div>
