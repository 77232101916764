<div class="modal-header">
  <h4 class="modal-title clamp-title" translate>Change Event Status</h4>
  <button (click)="dismissModal()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [ngBusy]="busy">
  <form [formGroup]="eventForm" (ngSubmit)="saveChanges()">
    <div class="form-row">
      <div class="col-12 form-group">
        <ng-select name="status" formControlName="status" id="status">
          <ng-option *ngFor="let status of eventStatuses" [value]="status.key" translate>{{ status.name }}</ng-option>
        </ng-select>
        <val-errors controlName="status">
          <ng-template valError="required">
            <span translate>Event status is required</span>
          </ng-template>
        </val-errors>
      </div>
      <div class="col-12 mt-2">
        <button type="submit" class="btn btn-success btn-block" [disabled]="eventForm.invalid">
          <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
        </button>
      </div>
    </div>
  </form>
</div>
