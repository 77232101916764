<swal
    #freeAccessSwal
    title="Complimenti!"
    html="<img src='/assets/img/flats/fire-cracker.png' class='mb-3' /><p>Sei tra i primi 500 iscritti ed hai ottenuto la piattaforma gratis per 1 anno!</p>"
    confirmButtonText="Accedi subito a KDRicarica!"
    [customClass]="{ confirmButton: 'btn bg-gradient-success' }"
    (confirm)="this.reloadAccess()"
    (close)="this.reloadAccess()"
>
</swal>

<div class="row mb-3">
    <div class="col-12 text-center">
        <img src="https://kdistribution.it/wp-content/uploads/2021/08/kdricarica_logo-e1629282445587.png" height="100px"
             alt="KDRicarica"/>
    </div>
    <div class="col-12 text-center" *ngIf="loading">
        <svg xmlns="http://www.w3.org/2000/svg" width="200px" height="200px" viewBox="0 0 100 100"
             preserveAspectRatio="xMidYMid">
            <g transform="translate(20 50)">
                <circle cx="0" cy="0" r="6" fill="#353535">
                    <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline"
                                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s"
                                      repeatCount="indefinite"></animateTransform>
                </circle>
            </g>
            <g transform="translate(40 50)">
                <circle cx="0" cy="0" r="6" fill="#666666">
                    <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline"
                                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s"
                                      repeatCount="indefinite"></animateTransform>
                </circle>
            </g>
            <g transform="translate(60 50)">
                <circle cx="0" cy="0" r="6" fill="#9b9b9b">
                    <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline"
                                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s"
                                      repeatCount="indefinite"></animateTransform>
                </circle>
            </g>
            <g transform="translate(80 50)">
                <circle cx="0" cy="0" r="6" fill="#d4d4d4">
                    <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline"
                                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s"
                                      repeatCount="indefinite"></animateTransform>
                </circle>
            </g>
        </svg>
    </div>
</div>

<table class="brand-grids">
    <ng-container *ngFor="let category of categories; let c=index;">
        <tr *ngFor="let brandsRow of category.brandRows; let i=index;" style="min-height: 1px;">
            <td class="card-grid" *ngIf="i === 0" [attr.rowspan]="category.brandRows.length" width="10%">
                <div class="card mb-0 h-100 darker d-table" [ngClass]="category.background">
                    <div class="card-body p-0">
                        <img
                                width="100%"
                                [src]="category.image"
                                [alt]="category.name">
                    </div>
                </div>
            </td>
            <ng-container *ngFor="let brand of brandsRow; let j=index;">
                <td width="10%" style="min-height: 1px;">
                    <div [id]="(j === 0 && i === 0 && c === 0) ? 'brandStep' : ''" class="card mb-0 h-100 w-100 zoom"
                         @fade [ngClass]="[
                        category.background,
                        selectedBrandId !== brand.id && selectedCategoryId === category.id && (products.length > 0 || loadingProducts) ?  'darker' : ''
                    ]"
                         (click)="selectBrand(category.id, brand.id, i, j)">
                        <div class="card-body p-0" [ngClass]="{
                            'selected': selectedBrandId === brand.id && selectedCategoryId === category.id
                             }
                        ">
                            <img
                                    width="100%"
                                    [src]="brand.image_path"
                                    [alt]="brand.name">
                        </div>
                    </div>
                </td>

                <ng-container
                        *ngIf="products.length > 0 && selectedBrandId === brand.id && rowNumber === i && colNumber === j">
                    <td *ngFor="let product of products; let p = index;" width="10%" class="card-grid">
                        <div [id]="(p === 0) ? 'productStep' : ''" class="card mb-0 h-100 w-100 zoom d-table"
                             (click)="showModal(product)">
                            <div class="card-body">
                                <img
                                        width="100%"
                                        [defaultImage]="'https://kdistribution.it/wp-content/uploads/2021/08/Ripple-1s-200px.gif'"
                                        [lazyLoad]="product.image_path"
                                        [alt]="product.name">
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container
                        *ngIf="loadingProducts && selectedBrandId === brand.id && rowNumber === i && colNumber === j">
                    <td width="10%" class="card-grid">
                        <div class="card mb-0 h-100 w-100 d-table">
                            <div class="card-body text-center">
                                <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
                                             type="square-jelly-box" [fullScreen]="false" name="products"></ngx-spinner>
                            </div>
                        </div>
                    </td>
                </ng-container>
            </ng-container>
        </tr>
    </ng-container>
</table>

<div class="row align-items-center justify-content-center" *ngIf="!this.canAccess && !this.loading">
    <div class="col-12 text-center my-3">
<!--        <h1 class="subline"><b>GRATIS</b>&nbsp;<i>per i primi</i> 500 che accedono al servizio</h1>-->
        <h1 class="headline">Tutto quello che ti serve</h1>
        <h1 class="headline">In un unico portale</h1>
    </div>
    <div class="col-12 col-md-8 col-lg-4 text-center">
        <div class="embed-responsive embed-responsive-16by9">
            <iframe allowFullScreen="allowFullScreen" class="embed-responsive-item"
                    src="https://www.youtube.com/embed/ZUXRP4yGSuM" allowtransparency="true"
                    frameborder="0"></iframe>
        </div>
    </div>
    <div class="w-100 my-3"></div>
    <div class="col-12 text-center">
        <button class="btn call-to-not-action mr-4">
            <p class="lead">valore piattaforma</p>
            <h1 class="headline"><b>399,00 €</b></h1>
            <p class="lead">se non ti iscrivi adesso</p>
        </button>
        <button class="btn bg-gradient-success shadow call-to-action pulse-animation"
                (click)="this.getPaidAccess()">
            <ng-container>
                <p class="lead">solo per i nostri affiliati</p>
                <h1 class="headline"><b>99,00 €</b></h1>
                <p class="lead"><b>all'anno</b></p>
            </ng-container>
        </button>
        <button class="btn bg-gradient-success shadow call-to-action"
                *ngIf="false"
                [ngClass]="{'pulse-animation': !this.loadingFreeAccess, 'disabled': this.loadingFreeAccess}"
                (click)="this.getFreeAccess()">
            <svg xmlns="http://www.w3.org/2000/svg" width="133px" height="133px" viewBox="0 0 100 100"
                 preserveAspectRatio="xMidYMid" *ngIf="this.loadingFreeAccess">
                <g transform="translate(20 50)">
                    <circle cx="0" cy="0" r="6" fill="#FFFFFF">
                        <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline"
                                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s"
                                          repeatCount="indefinite"></animateTransform>
                    </circle>
                </g>
                <g transform="translate(40 50)">
                    <circle cx="0" cy="0" r="6" fill="#FFFFFF">
                        <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline"
                                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s"
                                          repeatCount="indefinite"></animateTransform>
                    </circle>
                </g>
                <g transform="translate(60 50)">
                    <circle cx="0" cy="0" r="6" fill="#FFFFFF">
                        <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline"
                                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s"
                                          repeatCount="indefinite"></animateTransform>
                    </circle>
                </g>
                <g transform="translate(80 50)">
                    <circle cx="0" cy="0" r="6" fill="#FFFFFF">
                        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline"
                                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s"
                                          repeatCount="indefinite"></animateTransform>
                    </circle>
                </g>
            </svg>
            <ng-container *ngIf="!this.loadingFreeAccess">
            <p class="lead">solo questa settimana</p>
            <h1 class="headline"><b>GRATIS</b></h1>
            <p class="lead"><b>per i primi 500 iscritti</b></p>
            </ng-container>
        </button>
    </div>
</div>

<ng-template #shopFormTemplate let-modal>
    <app-shop-modal [product]="selectedProduct"></app-shop-modal>
</ng-template>
