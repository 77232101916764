<div class="row" [ngBusy]="busy">
    <div class="col-6">
        <h1 class="display-5">{{this.c().nome.value}} {{this.c().cognome.value}}</h1>
    </div>
    <div class="col-6">
        <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a routerLink="/gestione-stand">Gestione Presidi</a></li>
        </ol>
    </div>
    <div class="col-12" *ngIf="!this.new">
        <div class="card bg-gradient-light">
            <div class="card-header">
                <h1 class="display-6 mb-0">Statistiche attivazioni</h1>
            </div>
            <div class="card-body p-0">
                <app-table [rows]="data.statistiche.rows" [columns]="data.statistiche.columns" [hideFooter]="true" [hideHeader]="true"></app-table>
            </div>
        </div>
    </div>
    <div [ngClass]="{'col-6': true, 'offset-3': this.new}">
        <div class="card bg-gradient-light">
            <div class="card-header">
                <h1 class="display-6 mb-0 d-inline-block">Anagrafica Venditore</h1>
                <div class="card-tools">
                    <button type="submit" class="btn btn-success" (click)="save()">Salva</button>
                </div>
            </div>
            <div class="card-body">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>ID Venditore</label>
                                <input type="text" class="form-control form-control-sm" formControlName="id" disabled="disabled"/>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Codice Padre</label>
                                <ng-select [items]="this.data.codici_padre"
                                           bindLabel="label"
                                           bindValue="id"
                                           class="form-control-sm"
                                           formControlName="id_padre"
                                ></ng-select>
                                <val-errors controlName="id_padre"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Password Fatturazione</label>
                                <input type="text" class="form-control form-control-sm" formControlName="password"/>
                                <val-errors controlName="password"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Attivo</label>
                                <select formControlName="deleted" class="form-control-sm form-control">
                                    <option value="0">Si</option>
                                    <option value="1">No</option>
                                </select>
                                <val-errors controlName="deleted"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Visibile in statistiche</label>
                                <select formControlName="visibile" [disableControl]="this.data.venditore?.visibile === 0" class="form-control-sm form-control">
                                    <option value="1">Si</option>
                                    <option value="0">No</option>
                                </select>
                                <val-errors controlName="visibile"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Nascosto il</label>
                                <input type="text" disabled="disabled" [value]="(this.data.venditore ? this.data.venditore.hidden_date : null)| amDateFormat: 'H:m DD/MM/YYYY'" class="form-control-sm form-control" />
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Presidio</label>
                                <ng-select [items]="this.presidi"
                                           bindLabel="nome"
                                           bindValue="id"
                                           class="form-control-sm"
                                           formControlName="id_presidio"
                                ></ng-select>
                                <val-errors controlName="id_presidio"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Nome</label>
                                <input type="text" class="form-control form-control-sm" formControlName="nome"/>
                                <val-errors controlName="nome"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Cognome</label>
                                <input type="text" class="form-control form-control-sm" formControlName="cognome"/>
                                <val-errors controlName="cognome"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Data di nascita</label>
                                <input type="date"  placeholder="dd-mm-yyyy" class="form-control form-control-sm" formControlName="data_nascita"/>
                                <val-errors controlName="data_nascita"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Provincia di nascita</label>
                                <ng-select bindLabel="provincia"
                                           bindValue="provincia"
                                           class="form-control-sm"
                                           formControlName="provincia_nascita"
                                           (change)="this.setComuniNascita($event)"
                                           [items]="this.province"
                                ></ng-select>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Comune di nascita</label>
                                <ng-select [items]="this.comuniNascita"
                                           bindLabel="comune"
                                           bindValue="comune"
                                           class="form-control-sm"
                                           formControlName="comune_nascita"
                                ></ng-select>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Telefono</label>
                                <input type="text" class="form-control form-control-sm" formControlName="telefono"/>
                                <val-errors controlName="telefono"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>E-Mail</label>
                                <input type="text" class="form-control form-control-sm" formControlName="email"/>
                                <val-errors controlName="email"></val-errors>
                            </div>
                        </div>
                        <div class="col-12">
                            <legend>Residenza</legend>
                            <hr>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Provincia di residenza</label>
                                <ng-select bindLabel="provincia"
                                           bindValue="provincia"
                                           class="form-control-sm"
                                           formControlName="provincia"
                                           (change)="this.setComuni($event)"
                                           [items]="this.province"
                                ></ng-select>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Comune di residenza</label>
                                <ng-select [items]="this.comuni"
                                           bindLabel="comune"
                                           bindValue="comune"
                                           class="form-control-sm"
                                           formControlName="comune"
                                ></ng-select>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>CAP di residenza</label>
                                <input type="text" class="form-control form-control-sm" formControlName="cap"/>
                                <val-errors controlName="cap"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Indirizzo</label>
                                <input type="text" class="form-control form-control-sm" formControlName="indirizzo"/>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Civico</label>
                                <input type="text" class="form-control form-control-sm" formControlName="civico"/>
                            </div>
                        </div>
                        <div class="col-12">
                            <legend>Dati Fiscali</legend>
                            <hr>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Cod. Fiscale</label>
                                <input type="text" class="form-control form-control-sm" formControlName="cod_fiscale"/>
                                <val-errors controlName="cod_fiscale"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Partita IVA</label>
                                <select formControlName="piva" class="form-control-sm form-control">
                                    <option value="1">Si</option>
                                    <option value="0">No</option>
                                </select>
                                <val-errors controlName="piva"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>Partita IVA</label>
                                <input type="text" class="form-control form-control-sm" formControlName="partita_iva"/>
                                <val-errors controlName="partita_iva"></val-errors>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-6">
                            <div class="form-group">
                                <label>PEC</label>
                                <input type="text" class="form-control form-control-sm" formControlName="pec"/>
                                <val-errors controlName="pec"></val-errors>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label>IBAN</label>
                                <input type="text" class="form-control form-control-sm" formControlName="iban"/>
                                <val-errors controlName="iban"></val-errors>
                            </div>
                        </div>
                        <div class="col-12">
                            <legend>Sede Legale</legend>
                            <hr>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>Provincia</label>
                                <ng-select bindLabel="provincia"
                                           bindValue="provincia"
                                           class="form-control-sm"
                                           formControlName="provincia_sede_legale"
                                           (change)="this.setComuniSede($event)"
                                           [items]="this.province"
                                ></ng-select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>Comune</label>
                                <ng-select [items]="this.comuniSede"
                                           bindLabel="comune"
                                           bindValue="comune"
                                           class="form-control-sm"
                                           formControlName="comune_sede_legale"
                                ></ng-select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>CAP</label>
                                <input type="text" class="form-control form-control-sm" formControlName="cap_sede_legale"/>
                                <val-errors controlName="cap_sede_legale">
                                    <ng-template valError="maxLength">The email must be a valid email address</ng-template>
                                </val-errors>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>Indirizzo</label>
                                <input type="text" class="form-control form-control-sm" formControlName="indirizzo_sede_legale"/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-footer text-right">
                <button type="submit" class="btn btn-success" (click)="save()">Salva</button>
            </div>
        </div>
    </div>
    <div class="col-xxl-3 col-xl-6" *ngIf="!this.new">
        <div class="card bg-gradient-light">
            <div class="card-header">
                <h1 class="display-6 mb-0">Documenti</h1>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6" *ngFor="let documento of data.documenti">
                                <div class="document" (click)="showModal.showDocument(documento.percorso)">
                                    <img src="/assets/img/flats/document.png" class="img-fluid"/>
                                    <p>{{ documento.descrizione }}</p>
                                    <span>{{ documento.created | date: 'longDate' }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button class="btn btn-success btn-block btn-sm" (click)="docModal.open()">Aggiungi Documento</button>
            </div>
        </div>
        <div class="card bg-gradient-light">
            <div class="card-header">
                <h1 class="display-6 mb-0">Contratti</h1>
            </div>
            <div class="card-body px-0">
                <div *ngFor="let ctr of data.contratti; let i = index;">
                    <div class="box-hover-dark rounded px-2 mx-2" (click)="editContratto(ctr)">
                        <p>
                            <i class="fa fa-file-pdf cursor-hand mr-2"></i>
                            <strong>{{ ctr.tipo_contratto }}</strong>
                            <span class="float-right">{{ctr.stato}}</span>
                        </p>
                        <p class="text-muted">
                            {{ctr.inizio}} - {{ctr.fine}}
                            <strong class="float-right">{{ctr.rimborso | currency: '€ ' }}</strong>
                        </p>
                    </div>
                    <hr *ngIf="i < data.contratti.length-1">
                </div>
            </div>
            <div class="card-footer">
                <button class="btn btn-success btn-block btn-sm" (click)="this.formContratto.reset();ctrModal.open()">Nuovo Contratto</button>
            </div>
        </div>
        <div class="card bg-gradient-light">
            <div class="card-header">
                <h1 class="display-6 mb-0">Scontrini</h1>
            </div>
            <div class="card-body">
            </div>
        </div>
        <div class="card bg-gradient-light d-xl-block d-xxl-none" *ngFor="let periodo of data.fatture">
            <div class="card-header">
                <h1 class="display-6 mb-0">Fatture del {{periodo.anno}}</h1>
            </div>
            <div class="card-body">
                <div *ngFor="let fattura of periodo.lista; let i = index;">
                    <strong>
                        <i class="fa fa-file-pdf"></i> {{ fattura.anno + "-" + fattura.mese | amLocale:'it' | amDateFormat: 'MMMM YYYY' | upperfirst }}
                    </strong>
                    <p class="text-muted">
                        Fatt. n.{{fattura.numero}} del {{fattura.data | amLocale:'it' | amDateFormat: 'DD MMM YYYY'}}
                        <strong class="float-right">{{fattura.lordo | currency: '€ ' }}</strong>
                    </p>
                    <hr *ngIf="i < periodo.lista.length-1">
                </div>
            </div>
            <div class="card-footer display-6">
                Totale <strong class="float-right">{{periodo.totale | currency: '€ '}}</strong>
            </div>
        </div>
    </div>
    <div class="col-xxl-3 d-xxl-block d-none" *ngIf="!this.new">
        <div class="card bg-gradient-light" *ngFor="let periodo of data.fatture">
            <div class="card-header">
                <h1 class="display-6 mb-0">Fatture del {{periodo.anno}}</h1>
            </div>
            <div class="card-body">
                <div *ngFor="let fattura of periodo.lista; let i = index;">
                    <strong>
                        <i class="fa fa-file-pdf"></i> {{ fattura.anno + "-" + fattura.mese | amLocale:'it' | amDateFormat: 'MMMM YYYY' | upperfirst }}
                    </strong>
                    <p class="text-muted">
                        Fatt. n.{{fattura.numero}} del {{fattura.data | amLocale:'it' | amDateFormat: 'DD MMM YYYY'}}
                        <strong class="float-right">{{fattura.lordo | currency: '€ ' }}</strong>
                    </p>
                    <hr *ngIf="i < periodo.lista.length-1">
                </div>
            </div>
            <div class="card-footer display-6">
                Totale <strong class="float-right">{{periodo.totale | currency: '€ '}}</strong>
            </div>
        </div>
    </div>
</div>

<app-modal title="Aggiungi Documento" #docModal>
    <div class="form-group">
        <label>Descrizione</label>
        <input type="text" [(ngModel)]="descrizioneDocumento" class="form-control" />
        <p class="text-muted">La descrizione dev'essere almeno di 6 caratteri</p>
    </div>
    <div class="custom-file" id="customFile" lang="it">
        <input type="file" class="custom-file-input" (change)="uploadFile($event)"
               id="exampleInputFile" aria-describedby="fileHelp" [disabled]="this.descrizioneDocumento.length <= 5">
        <label class="custom-file-label" for="exampleInputFile">
            Seleziona il file
        </label>
    </div>
</app-modal>

<app-modal title="Contratto" #ctrModal [showFooter]="false">
    <form [formGroup]="formContratto">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>Inizio Contratto</label>
                    <input type="text" ngbDatepicker #inizio_contratto="ngbDatepicker" placeholder="dd-mm-yyyy"
                           (click)="inizio_contratto.toggle()" class="form-control form-control-sm" formControlName="inizio"/>
                    <val-errors controlName="inizio"></val-errors>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Fine Contratto</label>
                    <input type="text" ngbDatepicker #fine_contratto="ngbDatepicker" placeholder="dd-mm-yyyy"
                           (click)="fine_contratto.toggle()" class="form-control form-control-sm" formControlName="fine"/>
                    <val-errors controlName="fine"></val-errors>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Tipo Contratto</label>
                    <select class="form-control form-control-sm" formControlName="tipo_contratto">
                        <option [value]="null">Seleziona un tipo di  contratto</option>
                        <option value="Collaborazione Occasionale">Collaborazione Occasionale</option>
                        <option value="Partita IVA">Partita IVA</option>
                        <option value="Co.Co.Co">Co.Co.Co</option>
                    </select>
                    <val-errors controlName="tipo_contratto"></val-errors>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Rimborso</label>
                    <input type="text" class="form-control form-control-sm" formControlName="rimborso"/>
                    <val-errors controlName="rimborso"></val-errors>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label>Stato Contratto</label>
                    <select class="form-control form-control-sm" formControlName="stato">
                        <option [value]="null">Seleziona uno stato</option>
                        <option value="In revisione">In revisione</option>
                        <option value="Accettato">Accettato</option>
                        <option value="Rifiutato">Rifiutato</option>
                    </select>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label>Contratto</label>
                    <input type="file" (change)="setContrattoFile($event)" *ngIf="!this.formContratto.controls.id.value"/>
                    <div class="embed-responsive embed-responsive-1by1" *ngIf="this.formContratto.controls.id.value">
                        <iframe [src]="this.formContratto.controls.percorso.value | safe" class="embed-responsive-item"></iframe>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <button *ngIf="this.formContratto.controls.id.value" class="btn btn-sm btn-danger btn-block mb-2" (click)="delContratto()">
                    Cancella Contratto
                </button>
                <button class="btn-success btn btn-block btn-sm"
                        (click)="saveContratto()"
                        [disabled]="this.formContratto.invalid || (!this.contrattoFile && !this.formContratto.controls.id.value)"
                >
                    <ng-container *ngIf="!this.formContratto.controls.id.value">Aggiungi Contratto</ng-container>
                    <ng-container *ngIf="this.formContratto.controls.id.value">Salva</ng-container>
                </button>
            </div>
        </div>
    </form>
</app-modal>

<app-documents-modal #showModal></app-documents-modal>
