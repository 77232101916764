import {Injectable} from '@angular/core';
import {RequestService} from '@services/request.service';
import {AlertService} from '@services/alert.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Popup, PopupPagination} from '@models/popup';

const popup = 'popup-news';

@Injectable({
    providedIn: 'root'
})
export class PopupService {

    constructor(private request: RequestService, public alertService: AlertService) {
    }

    public getPopup(params?): Observable<PopupPagination> {
        return this.request.get(popup, params).pipe(
            map((response: any) => {
                return new PopupPagination(response);
            })
        );
    }

    public getActivePopup(): Observable<Popup> {
        return this.request.get(popup + '/active').pipe(
            map((response: any) => {
                return new Popup(response);
            })
        );
    }

    public postPopup(body: Popup, params?): Observable<Popup> {
        return this.request.post(popup, body, params).pipe(
            map((response: any) => {
                return new Popup(response);
            })
        );
    }

    public putPopup(id: number, body: Popup): Observable<Popup> {
        return this.request.put(popup + '/' + id, body).pipe(
            map((response: any) => {
                return new Popup(response);
            })
        );
    }

    public deletePopup(id: number) {
        return this.request.delete(popup + '/' + id);
    }

    public successAlert(text) {
        this.alertService.show(
            '',
            'Successfully ' + text,
            {classname: 'bg-success text-light', delay: 2000}
        );
    }

    public errorAlert(message?) {
        this.alertService.show(
            'An error occurred!',
            message ? message : 'Try again!',
            {classname: 'bg-danger text-light', delay: 2000}
        );
    }
}
