import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DealerService} from '@app/services/dealer.service';
import {Observable, of} from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';

@Component({
    selector: 'app-retailer-typeahead',
    templateUrl: './retailer-typeahead.component.html',
    styleUrls: ['./retailer-typeahead.component.scss'],
})
export class RetailerTypeaheadComponent implements OnInit {
    dealer: any;
    searching = false;
    searchFailed = false;
    @Input()
    small = false;
    @Output() onChange = new EventEmitter();

    constructor(private service: DealerService) {
    }

    ngOnInit() {
    }

    formatter = (x: any) => {

        this.onChange.emit(x.id);
        return x.ragsociale;
    };

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => (this.searching = true)),
            switchMap((term) =>
                this.service.list({
                    fields: 'ragsociale,id',
                    name: term,
                    'per-page': 10
                }).pipe(
                    map((data) => data.rows),
                    tap(() => (this.searchFailed = false)),
                    catchError(() => {
                        this.searchFailed = true;
                        return of([]);
                    })
                )
            ),
            tap(() => (this.searching = false))
        );
    dismissPopup = (event) => {
        if (!event) {
            this.onChange.emit(null);
        }
    };
}
