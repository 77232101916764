import {Component, OnInit, ViewChild} from '@angular/core';
import {Product} from '@models/product';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CartService} from '@services/cart.service';
import {StoreProductTemplate, StoreProductVariant} from '@models/store/store-product';
import {CartSubProduct} from '@models/shop/cart-sub-product';
import {ShopSubProductsPickerComponent} from '@app/pages/shop/partials/sub-products/shop-sub-products-picker.component';

@Component({
    selector: 'app-shop-printing-product',
    templateUrl: './shop-printing-product.component.html',
    styleUrls: ['./shop-printing-product.component.css']
})
export class ShopPrintingProductComponent implements OnInit {
    @ViewChild('content') private content;
    product: Product;
    qty: number;
    fronte: number;
    retro: number;
    currency = '€';
    selectedVariant: StoreProductVariant;
    selectedTemplate = {
        id: null,
        discount: 0,
        path: null,
        file: null,
        fileName: null
    };
    submittedFile: any;
    submittedFilePath = null;
    submittedFileName = null;
    selectedVariantId = null;
    @ViewChild('picker')
    public picker: ShopSubProductsPickerComponent;
    isBtnDisabled = true;
    sectionFocus = 'quantity';

    constructor(
        private modalService: NgbModal,
        private cartService: CartService
    ) {
    }

    ngOnInit(): void {
    }

    open() {
        this.sectionFocus = 'quantity';
        this.refreshSelectedTemplate();
        this.refreshVariant();
        this.modalService.open(this.content, {
            size: 'xl'
        });
    }

    changeTemplate(template?: StoreProductTemplate) {
        this.selectedTemplate.discount = template?.sconto;
        this.selectedTemplate.path = template?.template;
        this.selectedTemplate.id = template?.id;
    }

    changeVariant(variant?: StoreProductVariant) {
        this.selectedVariant = variant;
        this.sectionFocus = 'graphics';
    }

    get unitPrice() {
        if (!this.qty) {
            return null;
        }

        return this.product.fasce_di_prezzo
            .find(item => item.from_qnt <= this.qty && item.to_qnt >= this.qty).prezzo_unitario;
    }

    get firstUnitPrice() {
        if (!this.qty) {
            return null;
        }

        return this.product.fasce_di_prezzo[0].prezzo_unitario;
    }

    get priceEuro() {
        let price = this.product.price;

        if (this.qty) {
            price = this.unitPrice * this.qty;
        }

        if (this.fronte && this.fronte.toString() !== '-1') {
            const template = this.product.personalizzati.find(item => item.id === this.fronte);

            if (template.is_master) {
                price = price * ((100 - template.sconto) / 100);
            }
        }

        return this.calcPriceEuro(price);
    }

    get priceCent() {
        let price = this.product.price;

        if (this.qty) {
            price = this.unitPrice * this.qty;
        }

        if (this.fronte && this.fronte.toString() !== '-1') {
            const template = this.product.personalizzati.find(item => item.id === this.fronte);

            if (template.is_master) {
                price = price * ((100 - template.sconto) / 100);
            }
        }

        return this.calcPriceEuro(price);
    }

    get totalPrice() {
        if (!this.variantPrice) {
            return 0;
        }
        return Number(this.variantPrice) - Number(this.discountPrice);
    }

    get discountPrice() {
        const discount = Number(this.selectedTemplate?.discount);
        return discount > 0 ? (Number(this.selectedTemplate?.discount) * Number(this.variantPrice) / 100).toFixed(2) : '0';
    }

    get variantPrice() {
        this.isBtnDisabled = !this.selectedVariant;
        if (this.product?.variants?.length > 0) {
            const price = this.selectedVariant ? this.selectedVariant?.total_price : 0;
            return price.toFixed(2);
        }
        return this.product.price?.toFixed(2);
    }

    get disabledBtn() {
        return Number(this.variantPrice) <= 0;
    }

    calcPriceEuro(value) {
        return value.toString().split('.')[0];
    }

    calcPriceCent(value) {
        return value.toString().split('.')[1];
    }


    setProduct(product: Product) {
        this.product = product;
    }

    addToCart() {
        if (this.product?.variants?.length > 0) {
            this.product.qty_per_unit = this.selectedVariant.quantity.toFixed();
            this.product.unit_price = this.totalPrice.toFixed(2);
            this.product.variant = this.selectedVariant;
        } else if (this.totalPrice) {
            this.product.unit_price = this.totalPrice.toFixed(2);
        }
        this.selectedTemplate.file = this.submittedFile;
        this.selectedTemplate.fileName = this.submittedFileName;
        this.product.template = this.selectedTemplate;
        if (this.product.sub_products.length === 0) {
            this.cartService.addToCart(this.product);
            this.modalService.dismissAll();
        } else if (this.product.sub_products.length === 1) {
            const subProduct = new CartSubProduct();
            subProduct.product = this.product.sub_products[0];
            subProduct.quantity = this.product.qty_per_unit_n;
            subProduct.id = subProduct.product.productid;
            this.cartService.addToCart(this.product, [subProduct]);
            this.modalService.dismissAll();
        } else {
            this.picker.setProduct(this.product);
            this.picker.open();
        }

    }

    handleFileInput(file: File) {
        this.refreshSelectedTemplate();
        const reader = new FileReader();
        this.submittedFileName = file?.name;
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.submittedFilePath = reader.result;
            this.submittedFile = reader.result;
        };
    }

    handleFileRemoveModal() {
        this.refreshSelectedTemplate();
        this.submittedFile = null;
        this.submittedFilePath = null;
        this.submittedFileName = null;
    }

    refreshSelectedTemplate() {
        this.selectedTemplate = {
            id: null,
            discount: 0,
            path: null,
            file: null,
            fileName: null
        };
    }

    refreshVariant() {
        this.selectedVariantId = null;
        this.selectedVariant = null;
    }
}
