import { Injectable } from "@angular/core";
import { RequestService } from "./request.service";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  ENDPOINT = "settings";

  constructor(public request: RequestService) {}

  get(domain, key) {
    return this.request.get(`${this.ENDPOINT}/${domain}/${key}`);
  }
  
  toggle(domain, key) {
    return this.request.put(`${this.ENDPOINT}/toggle/${domain}/${key}`);
  }

}
