import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '@services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class NotFoundGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser?.loggedIn && currentUser?.profile?.is_psw_expired) {
            this.router.navigate(['/change-password'], {queryParams: {returnUrl: state.url}});
            return false;
        }
        return true;
    }

}
