<div class="card bg-gradient-light">
  <div class="card-body row align-items-center">
    <div class="col-3 text-center d-none d-lg-block">
      <img
        [src]="product.image"
        style="max-width: 100%"
        [style.height.px]="product.personalizzabile === 2 ? 150 : 100"
      />
    </div>
    <div class="col-12 col-lg-9">
      <div class="row">
        <div class="col-6 col-lg-9">
          <h1 class="display-7 mb-0">
            {{ product.productname }}
          </h1>
          <!--          <ngb-rating rate="4" max="5" readonly class="text-warning" *ngIf="product.tags.length === 0"></ngb-rating>-->
          <p
            class="d-none d-lg-block"
            *ngIf="product.description.length < 40"
            [innerHTML]="product.description"
          ></p>

          <ng-container *ngIf="product.hasTags">
            <ul ngbNav #nav="ngbNav" [(activeId)]="tabId" class="nav-pills nav-product">
              <li [ngbNavItem]="1">
                <a ngbNavLink>Rateizzazione con Tim</a>
                <ng-template ngbNavContent>
                  <table class="table table-borderless lead product-table mb-0">
                    <tr *ngFor="let tag of product.tags['method_1']">
                      <td>{{ tag.key }}</td>
                      <td>{{ tag.value }}</td>
                    </tr>
                  </table>
                </ng-template>
              </li>
              <li [ngbNavItem]="2">
                <a ngbNavLink>Finanziamento Santander con Carta di credito</a>
                <ng-template ngbNavContent>
                  <table class="table table-borderless lead product-table mb-0">
                    <tr *ngFor="let tag of product.tags['method_2']">
                      <td>{{ tag.key }}</td>
                      <td>{{ tag.value }}</td>
                    </tr>
                  </table>
                </ng-template>
              </li>
              <li [ngbNavItem]="3">
                <a ngbNavLink>Finanziamento Santander con RID Bancario</a>
                <ng-template ngbNavContent>
                  <table class="table table-borderless lead product-table mb-0">
                    <tr *ngFor="let tag of product.tags['method_3']">
                      <td>{{ tag.key }}</td>
                      <td>{{ tag.value }}</td>
                    </tr>
                  </table>
                </ng-template>
              </li>
            </ul>
  
            <div [ngbNavOutlet]="nav"></div>  
          </ng-container>
          <ng-container *ngIf="product.offerte.length > 0">
            <hr />
            <p>Rateizzabile con</p>
            <div class="row">
              <div
                class="col-12 col-lg-6 mb-2"
                *ngFor="let off of product.offerte"
              >
                <button
                  class="btn bg-gradient-dark btn-block btn-sm"
                  (click)="offerta = off; dettaglioOffertaModal.open('xl')"
                >
                  <i class="fa fa-info-circle"></i> {{ off.nome }}
                </button>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-6 col-lg-3 text-right">
          <p class="product-price">
            € {{ product.priceEuro }},<span>{{ product.priceCent }}</span>
          </p>
          <!--          <p *ngIf="(product.p_min_qty_free_ship  && product.p_free_shipping_payment !== '' && product.productcategory !== 'Plafond'); else elseBlock">-->
          <!--            <span class="badge badge-pill badge-success"><i class="fa fa-truck"></i> Spedizione Gratis</span>-->
          <!--          </p>-->
          <!--          <ng-template #elseBlock><p class="font-italic">prezzo per te</p></ng-template>-->
          <p class="font-italic">prezzo per te</p>
        </div>
      </div>
      <hr />
      <div class="row align-items-center">
        <div class="col-12">
          <p
            class="product-detail d-inline-block"
            *ngIf="product.personalizzabile !== 2"
          >
            <i class="fa fa-info-circle"></i> Q.tà in mag.
            <b>{{ product.htmlStock }}</b>
          </p>
          <!--                    <button *ngFor="let off of product.offerte" class="btn btn-sm btn-primary mr-2"-->
          <!--                            (click)="offerta=off; offertaModal.open('xl')">-->
          <!--                        Rateizza con {{off.nome}}-->
          <!--                    </button>-->
          <button
            *ngIf="product.personalizzabile === 2"
            class="btn btn-sm bg-tim-gradient mr-2"
            (click)="offertaModal.open()"
          >
            <img src="assets/img/small_brands/tim.png" height="14px" /> Prenota
            seriale per rateizzazione
          </button>

          <div class="float-right">
            <span
              *ngIf="
                product.productcategory === 'Plafond' &&
                cartService.getProduct(product)
              "
              class="font-italic text-muted mr-2"
            >
              Hai già nel carrello
              <b
                >{{
                  cartService.getProduct(product).quantity *
                    product.qty_per_unit
                }}
                €</b
              >
              di plafond
            </span>
            <span
              *ngIf="
                product.productcategory === 'Sim' &&
                cartService.getProduct(product)
              "
              class="font-italic text-muted mr-2"
            >
              Hai già nel carrello
              <b>{{
                cartService.getProduct(product).quantity * product.qty_per_unit
              }}</b>
              sim
            </span>
            <button
              *ngIf="product.description.length > 40"
              class="btn bg-gradient-secondary btn-sm px-3 mr-2"
              (click)="schedaModal.open('xl')"
            >
              <i class="fa fa-info-circle"></i> Dettagli
            </button>
            <button
              class="btn bg-gradient-info btn-sm px-3"
              *ngIf="product.stock > 0 && stillAvalaible(product)"
              (click)="addToCart()"
            >
              <i class="fa fa-cart-plus"></i> Aggiungi
            </button>
            <button
              class="btn bg-gradient-danger btn-sm px-3"
              *ngIf="product.stock === 0 || !stillAvalaible(product)"
              [disabled]="true"
            >
              Non disponibile
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-shop-sub-products-picker #picker></app-shop-sub-products-picker>
<app-shop-printing-product #printing></app-shop-printing-product>

<app-modal title="Scheda Tecnica" #schedaModal>
  <div class="row">
    <div class="col-4 text-center">
      <img *ngIf="product.image" [src]="product.image" class="img-fluid" />
    </div>
    <div class="col-8">
      <h1 class="display-6">
        <b>{{ product.productname }}</b>
      </h1>
      <div [innerHTML]="product.description | safeHtml"></div>
    </div>
  </div>
</app-modal>

<app-modal title="Prenota Seriale" #offertaModal>
  <div class="lead">
    Vuoi prenotare un seriale di uno <b>{{ product.productname }}</b> per
    attivare un contratto <b>{{ offerta.nome }}</b
    >?
  </div>
  <button
    class="btn bg-gradient-success mt-2 btn-block"
    [ladda]="loadingPrenotazione"
    (click)="prenota(product.id)"
  >
    <i class="fas fa-tag"></i> Prenota Seriale
  </button>
</app-modal>

<app-modal title="Dati Offerta" #dettaglioOffertaModal>
  <div class="p-2">
    <h1 class="display-6">
      <b>{{ offerta.nome }}</b>
    </h1>
    <div class="lead" [innerHTML]="offerta.descrizione"></div>
  </div>
</app-modal>

<ng-template #prenotazione let-modal>
  <div class="card-header">
    <h1 class="card-title">Il tuo seriale</h1>
  </div>
  <div class="card-body">
    <h1 class="display-6">Istruzioni</h1>
    <ul class="lead px-4">
      <li>
        Ti abbiamo riservato questo seriale:
        <span class="text-bold">{{ seriale }}</span>
      </li>
      <li>Il seriale sarà bloccato nel nostro magazzino per 6 ore.</li>
      <li>
        Se l'inserimento del contratto non andrà a buon fine, il seriale verrà
        bruciato e non sarà più utilizzabile.
      </li>
      <li>
        Se l'inserimento del contratto avverrà correttamente, nella sezione
        delle prenotazioni caricherai la PDA del contratto e noi procederemo
        alla spedizione del telefono presso il tuo punto vendita entro 48 ore.
      </li>
    </ul>
  </div>
  <div class="card-footer">
    <button class="btn btn-success btn-block" (click)="goToPrenotazioni()">
      Vai alla lista delle rateizzazioni
    </button>
  </div>
</ng-template>
