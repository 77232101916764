import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  ENDPOINT = 'transactions';

  constructor(
      public request: RequestService
  ) {
  }

  saveTransaction(data) {
    return this.request.post(`${this.ENDPOINT}`, data);
  }
  
  updateTransaction(id, data) {
    return this.request.put(`${this.ENDPOINT}/${id}`, data);
  }

  deleteTransaction(id) {
    return this.request.delete(`${this.ENDPOINT}/${id}`);
  }


  list(params = {}) {
    return this.request.get(`${this.ENDPOINT}/list`, params);
  }

  previewCbi(data) {
      return this.request.post(`${this.ENDPOINT}/preview-cbi`, data, { responseType: 'blob' }).subscribe(data => this.request.downloadFile(data, 'application/xml'));
  }

  saveCbi(data) {
    return this.request.post(`${this.ENDPOINT}/save-cbi`, data);
  }
}
