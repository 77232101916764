<app-master-header
  image="/assets/img/flats/payment.png"
  title="Ricariche"
  cardClass="bg-gradient-1"
>
  <p class="lead" *show="['dealer']">Richiedi qui le tue ricariche.</p>
  <div class="row" *show="['operator']">
    <div class="form-group col-12 col-md-4 col-lg-3">
      <div class="form-group">
        <label>Filtra per negozio</label>
        <app-retailer-typeahead
          (onChange)="this.filter.id_dealer = $event; this.refresh()"
        ></app-retailer-typeahead>
      </div>
    </div>
    <div class="form-group col-12 col-md-4 col-lg-3">
      <div class="form-group">
        <label>Filtra per operatore</label>
        <app-vendor-select
          (onChange)="this.filter.vendor = $event; this.refresh()"
        ></app-vendor-select>
      </div>
    </div>

    <div class="form-group col-12 col-md-4 col-lg-3">
      <div class="form-group">
        <label>Filtra per stato</label>
        <select
          [(ngModel)]="this.filter.status"
          (ngModelChange)="refresh()"
          class="form-control"
        >
          <option value="Completata">Completata</option>
          <option value="In Attesa">In Attesa</option>
          <option value="Rifiutata">Rifiutata</option>
        </select>
      </div>
    </div>
    <div class="form-group col-12 col-md-4 col-lg-3">
      <div class="form-group">
        <label>Filtra per numero</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="this.filter.number"
          />
          <span class="input-group-append">
            <button type="button" class="btn btn-default" (click)="refresh()">
              Cerca
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</app-master-header>

<div class="row">
  <div class="col-12 col-md-4" *show="['dealer']">
    <div class="card">
      <div class="card-header bg-gradient-light">
        <div class="card-title">Richiedi Ricarica</div>
      </div>
      <div class="card-body">
        <ngx-spinner
          bdColor="rgba(0, 0, 0, 0.8)"
          size="default"
          color="#fff"
          type="square-jelly-box"
          name="form-recharge"
          [fullScreen]="false"
        >
          <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <div [formGroup]="form">
          <div class="form-group">
            <label><i class="fa fa-chevron-circle-right"></i>Brand</label>
            <div
              class="btn-group btn-group-toggle"
              ngbRadioGroup
              formControlName="id_operatore"
            >
              <label ngbButtonLabel *isVendorEnabled="['Tim']">
                <input ngbButton type="radio" [value]="11" /><img
                  src="/assets/img/brands/tim.png"
                  height="35px"
                />
              </label>
              <label ngbButtonLabel *isVendorEnabled="['Kena Mobile']">
                <input ngbButton type="radio" [value]="6" /><img
                  src="/assets/img/brands/kena.png"
                  height="35px"
                />
              </label>
              <label ngbButtonLabel *isVendorEnabled="['Tiscali Mobile']">
                <input ngbButton type="radio" [value]="4" /><img
                  src="/assets/img/brands/tiscali.png"
                  height="35px"
                />
              </label>
            </div>
            <val-errors controlName="id_operatore"></val-errors>
          </div>

          <div class="form-group">
            <label><i class="fa fa-chevron-circle-right"></i>Importo</label>
            <select formControlName="importo" class="form-control">
              <option value="">Seleziona un taglio</option>
              <ng-container *ngIf="this.form.controls.id_operatore.value != 11">
                <option value="5">5 €</option>
                <option value="10">10 €</option>
                <option value="20">20 €</option>
                <option value="50">50 €</option>
              </ng-container>
              <ng-container *ngIf="this.form.controls.id_operatore.value == 11">
                <option *ngFor="let i of tagliTim" [value]="i">
                  {{ i | currency: "€ " }}
                </option>
              </ng-container>
            </select>
            <val-errors controlName="importo"></val-errors>
          </div>

          <div class="form-group">
            <label><i class="fa fa-chevron-circle-right"></i>Numero</label>
            <input
              type="tel"
              maxlength="10"
              minlength="9"
              formControlName="numero"
              class="form-control"
            />
            <val-errors controlName="numero"></val-errors>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button
          type="submit"
          class="btn btn-success btn-block"
          [disabled]="this.form.invalid"
          (click)="this.saveRecharge()"
        >
          Inserisci Richiesta
        </button>
      </div>
    </div>
  </div>
  <div class="col-12" [ngClass]="{ 'col-md-8': me.role === 'dealer' }">
    <div class="card bg-gradient-light">
      <div class="card-header">
        <h1 class="card-title">Lista Ricariche</h1>
      </div>
      <div class="card-body p-0">
        <ngx-spinner
          bdColor="rgba(0, 0, 0, 0.8)"
          size="default"
          color="#fff"
          type="square-jelly-box"
          name="table-recharges"
          [fullScreen]="false"
        >
          <p style="color: white">Caricamento...</p>
        </ngx-spinner>
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <th class="text-center" *show="['operator']"></th>
              <th class="text-center">#</th>
              <th>Dealer</th>
              <th>Numero</th>
              <th class="text-center">Operatore</th>
              <th class="text-right">Importo</th>
              <th class="text-center">Stato</th>
              <th class="text-center">Ricevuta</th>
              <th class="text-center">Data</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let row of recharges">
              <td class="text-center" *show="['operator']">
                <i
                  class="fas fa-cogs link"
                  *ngIf="row.stato !== 'Rifiutatas'"
                  (click)="showUpdateRechargeModal(row)"
                ></i>
              </td>
              <td class="text-center">{{ row.id }}</td>
              <td>{{ row.retailer }}</td>
              <td>{{ row.numero }}</td>
              <td class="text-center">
                <img [src]="row.vendor.image" height="26px" />
              </td>
              <td class="text-right">{{ row.importo | currency: "€ " }}</td>
              <td class="text-center">
                <span class="badge" [ngClass]="row.statusBackground">{{
                  row.stato
                }}</span>
              </td>
              <td class="text-center">
                <a [href]="row.file_path" *ngIf="row.file_path">Ricevuta</a>
              </td>
              <td class="text-center">
                {{ row.data | amDateFormat: "H:mm DD/MM/YYYY" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card-footer">
        <span
          >Ci sono <b>{{ this.pagination.totalCount }}</b> ricariche.</span
        >
        <ngb-pagination
          size="sm"
          [maxSize]="pagination.pageSize"
          [(page)]="pagination.page"
          [collectionSize]="pagination.totalCount"
          (pageChange)="this.pagination.page = $event; refresh()"
        ></ngb-pagination>
      </div>
    </div>
  </div>
</div>

<ng-template #rechargeFormTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title">{{ rechargeModalTitle }}</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="rechargeForm">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="lead">Stato</label>
            <select
              [(ngModel)]="selectedRecharge.stato"
              formControlName="stato"
              class="form-control"
            >
              <option value="Completata">Completata</option>
              <option value="In Attesa">In Attesa</option>
              <option value="Rifiutata">Rifiutata</option>
            </select>
            <val-errors controlName="stato">
              <ng-template valError="required">
                Lo stato è obbligatorio
              </ng-template>
            </val-errors>
          </div>
        </div>
        <div class="col-12" *ngIf="selectedRecharge.stato === 'Completata'">
          <div class="form-group">
            <label class="lead">Ricevuta</label>
            <div class="custom-file">
              <input
                formControlName="receipt"
                type="file"
                class="custom-file-input"
                id="validatedCustomFile"
                (change)="handleFileInput($event.target.files)"
              />
              <label class="custom-file-label" for="validatedCustomFile">{{
                fileName
              }}</label>
            </div>
            <val-errors controlName="receipt">
              <ng-template valError="required">
                La ricevuta è obbligatoria
              </ng-template>
            </val-errors>
          </div>
        </div>
        <div class="col-12 mt-3">
          <button
            class="btn btn-success btn-block"
            [disabled]="!this.rechargeForm.valid"
            (click)="saveRecharge(selectedRecharge)"
          >
            <i class="fa fa-check-circle mr-2"></i>Salva
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
