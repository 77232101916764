import {Injectable, isDevMode} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Profile, User} from '@models/user';
import {BehaviorSubject, interval, Observable, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {environment} from '@environments/environment';
import {Menu} from '@app/models/menu';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    private currentMenuSubject: BehaviorSubject<Menu>;
    public currentMenu: Observable<Menu>;

    private listener: Subscription;

    constructor(private router: Router, private http: HttpClient) {
        const jsonUser = JSON.parse(localStorage.getItem('currentUser'));
        let user = new User();
        if (jsonUser && jsonUser.hasOwnProperty('profile')) {
            user = new User().deserialize(jsonUser.profile);
        }
        if (jsonUser && jsonUser.hasOwnProperty('loggedIn')) {
            user.loggedIn = jsonUser.loggedIn;
        }
        if (jsonUser && jsonUser.hasOwnProperty('token')) {
            user.token = jsonUser.token;
        }
        this.currentUserSubject = new BehaviorSubject<User>(user);
        this.currentUser = this.currentUserSubject.asObservable();

        const jsonMenu = JSON.parse(localStorage.getItem('menu'));
        let menu = new Menu();
        if (jsonMenu) {
            menu = new Menu(jsonMenu);
        }
        this.currentMenuSubject = new BehaviorSubject<Menu>(menu);
        this.currentMenu = this.currentMenuSubject.asObservable();
        if (this.currentUserValue && this.currentUserValue.loggedIn) {
            this.listenForUpdates();
        }
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public get currentUserToken() {
        return localStorage.getItem('currentUserToken');
    }

    public get currentMenuValue(): Menu {
        return this.currentMenuSubject.value;
    }

    public get operatori(): any {
        return this.currentUserValue.profile.operatori;
    }

    login(username: string, password: string) {
        return this.http
            .post<any>(`${environment.apiUrl}/login`, {username, password})
            .pipe(
                map((data) => {
                    // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
                    const user = new User().deserialize(data.result.profile);
                    user.loggedIn = data.success;
                    user.token = this.generateUserToken(username, password);
                    this.setCurrentUserInLS(user);
                    this.setCurrentUserTokenInLS(data?.result?.token);
                    this.currentUserSubject.next(user);
                    return user;
                })
            );
    }

    setCurrentUserInLS(user: User) {
        localStorage.setItem('currentUser', JSON.stringify(user));
    }

    setCurrentUserTokenInLS(token: string) {
        localStorage.setItem('currentUserToken', token);
    }

    generateUserToken(username, password) {
        return window.btoa(username + ':' + password);
    }

    listenForUpdates() {
        if (this.listener) {
            this.listener.unsubscribe();
        }

        if (this.currentUserValue.profile.id !== 4281 && environment.production) {
            this.listener = interval(20000).subscribe((x) => {
                this.ping();
            });
        }
    }

    logout() {
        if (this.listener) {
            this.listener.unsubscribe();
        }
        localStorage.clear();
        this.currentUserSubject.next(null);
        this.currentMenuSubject.next(null);
    }

    ping() {
        return this.http.get<any>(`${environment.baseUrl}ping`).subscribe(
            (data) => {
                this.updateAccountData(data.profile);
                this.updateMenu(data.menu);
            },
            (err: any) => {
                this.logout();
            }
        );
    }

    updateMenu(data: Menu) {
        const menu = new Menu(data);
        localStorage.removeItem('menu');
        localStorage.setItem('menu', JSON.stringify(menu));
        this.currentMenuSubject.next(menu);
    }

    updateAccountData(profile: Profile) {
        const user = new User().deserialize(profile);
        user.loggedIn = true;
        user.token = this.currentUserValue.token;
        localStorage.removeItem('currentUser');
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
    }
}
