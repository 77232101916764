<ng-select
  [ngClass]="{ 'form-control-sm': small }"
  [items]="data"
  bindValue="id"
  bindLabel="nome"
  [(ngModel)]="presidio"
  (ngModelChange)="onChange.emit($event)"
  [disabled]="disabled"
  [loading]="loading"
  loadingText="Caricamento..."
></ng-select>
