<app-master-header [ngBusy]="busy"
                    cardClass="bg-gradient-light"
                   title="Confronto tra regioni"
                   [operatori]="true"
                   [periodoSingle]="true"
                   (onChange)="this.updateUrls($event.operatore, $event.periodo)"
>
</app-master-header>

<div class="row">
    <div class="col-4" *ngFor="let regione of regioni">
        <div class="card bg-gradient-light elevation-1">
            <div class="card-body">
                <h1 class="display-6">{{regione.regione}}</h1>
                <div class="table-responsive table-borderless rounded">
                    <app-table
                            [hideFooter]="true"
                            [striped]="true"
                            [hover]="true"
                            [small]="true"
                            [rows]="regione.table.rows"
                            [columns]="regione.table.columns">

                    </app-table>
                </div>
                <h1 class="display-6 mt-2">Totale sim:<span class="float-right">{{regione.sim}}</span></h1>
            </div>
        </div>
    </div>
</div>
