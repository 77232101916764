<div class="modal-body" [ngBusy]="busyFormModal">
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                       <span class="d-inline-flex">
                    <label for="dealer" class="d-inline" translate>Dealer: </label>
                    <p class="ml-1" *ngIf="affiliationValues?.operator">{{affiliationValues.dealer?.company_name}}</p>
                </span>
                <app-retailer-typeahead (onChange)="handleDealerSelectedModal($event)"
                                        *ngIf="!affiliationValues?.dealer"></app-retailer-typeahead>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <span class="d-inline-flex">
                    <label for="operator" class="d-inline" translate>Operatore: </label>
                    <p class="ml-1" *ngIf="affiliationValues?.operator">{{affiliationValues.operator?.company_name}}</p>
                </span>
                <ng-select (isValid)="true" [(ngModel)]="formSelectedOperatorId" *ngIf="!affiliationValues?.operator"
                           (change)="handleOperatorSelectedModal($event)" [items]="formOperators" bindValue="id"
                           bindLabel="company_name"></ng-select>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="!formDocuments?.length">
        <div class="col-12">
            <div class="alert alert-warning">
                <p class="text-center" translate>Non ci sono requisiti per quest'operatore.</p>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="formDocuments?.length">
        <div class="col-6">
            <label translate>Titolo e descrizione</label>
        </div>
        <div class="col-5">
            <label translate>Carica documento</label>
        </div>
        <div class="col-1 text-right pr-4">
            <label translate>Links</label>
        </div>
        <div class="col-12" *ngFor="let document of formDocuments">
            <div class="card bg-gradient-white elevation-1 category">
                <div class="card-body row align-content-center">
                    <div class="col-6">
                        <h5>{{ document.name }}</h5>
                        <p>{{ document.description }}</p>
                    </div>
                    <div class="col my-auto">
                        <div *ngIf="document.fileName" class="">
                            <span>{{ document.fileName }}</span>
                            <button class="btn btn-xs btn-warning ml-2" (click)="handleFileRemoveModal(document)">
                                <i class="fa fa-times m-0 px-1"></i>
                            </button>
                        </div>
                        <div *ngIf="!document.fileName" class="row no-gutters">
                            <div class="form-group mb-0 col-6">
                                <div *ngIf="!document.hasFile" class="custom-file">
                                    <input id="fileUpload-{{ document.id }}" type="file" class="custom-file-input"
                                           (change)="handleFileInputModal($event.target.files[0], document)">
                                    <label class="custom-file-label" for="fileUpload-{{ document.id }}" translate>
                                        Scegli file...
                                    </label>
                                </div>
                            </div>
                            <div class="col-auto px-2 pt-1">OR</div>
                            <div class="col">
                                <ng-select (change)="setUploadedIn($event, document)"
                                           [(ngModel)]="document.uploadedInId" [items]="document.uploadedAsList"
                                           bindValue="id"
                                           bindLabel="value">
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="col-auto text-right my-auto">
                        <a *ngIf="document.link" href="{{ document.link }}" target="_blank">
                            <i class="fa fa-external-link-alt text-primary pointer"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mt-3">
            <button class="btn btn-success btn-block" (click)="saveAffiliation()"
                    [disabled]="!validateSubmittedDocument()">
                <i class="fa fa-check-circle mr-2"></i> <span translate>Salva</span>
            </button>
        </div>
    </div>
</div>
