<div class="row" [ngBusy]="busy">
    <div class="col-xl-4 col-lg-6 col-12">
        <div class="card bg-gradient-1">
            <div class="card-body">
                <div class="ribbon-wrapper ribbon-xl">
                    <div class="ribbon bg-danger text-lg" translate>
                        Loyalty points
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-12">
                        <h1 class="display-6 d-inline-block" translate>Fidelity Card - Lots of gifts for you</h1>
                    </div>
                    <div class="col-12">
                        <hr class="mb-2 mt-1">
                    </div>
                    <div class="col-3 text-center">
                        <img src="/assets/img/flats/barcode.png" width="75%">
                        <p translate>Current Points</p>
                        <p class="text-bold text-lg">{{loyaltyCard?.points.total}}</p>
                    </div>
                    <div class="col-9" *ngIf="user">
                        <p class="text-bold font-italic" translate>Company:</p>
                        <p class="lead">{{user?.profile.ragione_sociale}}</p>
                        <hr class="mb-2 mt-1">
                        <p class="text-bold font-italic" translate>Contact person:</p>
                        <p class="lead">{{user?.profile.nome}} {{user?.profile.cognome}}</p>
                        <hr class="mb-2 mt-1">
                        <p class="text-bold font-italic" translate>Period of validity:</p>
                        <p class="lead">{{loyaltyCard?.start_date | date:format}}
                            - {{loyaltyCard?.end_date | date:format}}</p>
                        <hr class="mb-2 mt-1">
                    </div>
                </div>
            </div>
        </div>
        <h1 class="display-6 mb-0 mb-2" translate>The prizes</h1>
        <p class="text-muted" *ngIf="prizes && prizes?.data.length<=0">List is empty</p>
        <ngx-spinner
                bdColor="rgba(0, 0, 0, 0.8)"
                size="default"
                color="#fff"
                type="square-jelly-box"
                name='prizes'
                [fullScreen]="false"
        >
        </ngx-spinner>
        <div class="card" *ngFor="let prize of prizes?.data">
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col-lg-3 col-12 text-center">
                        <img [src]="prize.image_path" [alt]="prize.image_name" class="img-fluid">
                    </div>
                    <div class="col-lg-5 col-12">
                        <h1 class="display-6 mb-0">{{prize.title}}</h1>
                        <p class="lead mb-0"><b>{{prize.required_points}}</b> punti richiesti</p>
                    </div>
                    <div class="col-lg-4 col-12 text-right">
                        <button class="btn bg-gradient-primary"
                                [disabled]="prize.required_points>loyaltyCard.points.total || prize?.is_used"
                                translate
                                [swal]="withdrawPrizeOptions"
                                (confirm)="withdrawPrize(prize)">
                            <span translate *ngIf="!prize?.is_used"><i
                                    class="fa fa-cart-plus mr-2"></i>
                            Ritira</span>
                            <span translate *ngIf="prize?.is_used">Already retired</span>

                        </button>
                    </div>
                    <div class="col-12">
                        <p class="text-muted">{{prize.description}}</p>
                    </div>
                </div>
            </div>
        </div>
        <button class="btn btn-link float-right" (click)="loadAllPrizes(loyaltyCard, prizes)" translate
                *ngIf="prizes && prizes?.data.length>0">
            <span translate *ngIf="!isShownAll(prizes)">Show All</span>
            <span translate *ngIf="isShownAll(prizes)">Show Less</span>
        </button>
    </div>
    <div class="col-xl-4 col-lg-6 col-12">
        <div class="card bg-gradient-light">
            <div class="card-header">
                <div class="card-title" translate>Rules</div>
            </div>
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name='rules'
                    [fullScreen]="false"
            >
            </ngx-spinner>
            <div class="card-body p-0">
                <p class="text-muted pl-4 py-2" *ngIf="segments && segments?.data?.length<=0">List is empty</p>
                <table class="table" *ngFor="let segment of segments?.data">
                    <tr>
                        <td colspan="2"><img
                                [src]="'/assets/img/brands/'+segment.slug+'.png'"
                                [alt]="segment.title"
                                height="30px"></td>
                    </tr>
                    <tr *ngFor="let  rule of segment.rules">
                        <td>{{rule.count}} {{rule.offer}}</td>
                        <td class="text-right">{{rule.points}} <span translate>points</span>
                    </tr>
                </table>
            </div>
        </div>
        <button class="btn btn-link float-right" (click)="loadAllRules(loyaltyCard, segments)" translate
                *ngIf="segments && segments?.data.length>0">
            <span translate *ngIf="!isShownAll(segments)">Show All</span>
            <span translate *ngIf="isShownAll(segments)">Show Less</span>
        </button>
    </div>
    <div class="col-xl-4 col-lg-6 col-12">
        <h1 class="display-6 mb-0 mb-2">
            Timeline
            <button type="button" class="btn btn-sm bg-gradient-info"
                    ngbPopover="Qui puoi trovare la lista delle azioni che hai compiuto, che ti hanno generato dei punti.
                    Esempio: Se condividi una notizia dalle nostre pagine social o dall’app Master Group guadagni punteggi"
                    triggers="mouseenter:mouseleave"
                    popoverTitle="Timeline" translate>
                <i class="fa fa-info-circle"></i>Find out more
            </button>
        </h1>
        <div class="timeline min-vh-25">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name='timeline'
                    [fullScreen]="false"
            >
            </ngx-spinner>
            <ng-container *ngFor="let timeline of timelines">
                <div class="time-label lead"><span class="bg-gradient-success">{{timeline.date|date:dayFormat}}</span>
                </div>
                <div *ngFor="let usedPrize of timeline.used_prizes">
                    <i class="fas fa-sim-card bg-gradient-info"></i>
                    <div class="elevation-1 timeline-item">
                        <h3 class="timeline-header"><span translate>Your retired prize: </span>
                            <strong><em>{{usedPrize.title}}</em></strong>
                        </h3>
                        <div class="timeline-footer" style="margin: 0 0 45px;padding: 0;position: relative;">
                            <div class="row align-items-center">
                                <div class="col text-right">
                                    <p class="mb-0 text-muted mr-2" style="font-size: 14px;"><i
                                            class="fas fa-plus-circle mr-2"></i>
                                        <span translate>Points spent: </span>
                                        <strong>{{usedPrize.required_points}}</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngFor="let sale of timeline.sales">
                    <i class="fas fa-sim-card bg-gradient-info"></i>
                    <div class="elevation-1 timeline-item">
                        <h3 class="timeline-header">Ha inserito un contratto
                            <strong><em>{{sale.offer}}</em></strong>
                        </h3>
                        <div class="timeline-footer" style="margin: 0 0 45px;padding: 0;position: relative;">
                            <div class="row align-items-center">
                                <div class="col"><img [src]="'/assets/img/brands/'+sale.logo+'.png'" [alt]="sale.logo"
                                                      height="24px"
                                                      class="m-1"></div>
                                <div class="col text-right">
                                    <p class="mb-0 text-muted mr-2" style="font-size: 14px;"><i
                                            class="fas fa-plus-circle mr-2"></i>Punti
                                        Guadagnati: <strong>{{sale.points}}</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

