<div style="min-height: 250px">
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="default"
    color="#fff"
    type="square-jelly-box"
    name="loader-table-{{ this.uniqueID }}"
    [fullScreen]="false"
  >
    <p style="color: white">Caricamento...</p>
  </ngx-spinner>
  <app-table
    style="height: 100%"
    [rows]="rows"
    [columns]="columns"
    *ngIf="!error && !loading"
    [pagination]="pagination"
    [firstColumnClass]="firstColumnClass"
    [headerClass]="headerClass"
    [striped]="striped"
    [small]="small"
    [hideFooterDescription]="hideFooterDescription"
    [hideFooter]="hideFooter"
    [hideHeader]="hideHeader"
    [pageSize]="pageSize"
    [additionalData]="additionalData"
    [filters]="filters"
    (onAdditionalData)="this.additionalDataUpdate($event)"
    (onFilters)="this.filtersUpdate($event)"
    [hideTable]="error || rows.length === 0"
  >
    <div
      class="d-flex align-items-center justify-content-center flex-wrap"
      style="height: 250px; background-color: #000000d1"
      *ngIf="error"
    >
      <div class="text-center">
        <img src="/assets/img/flats/close.png" height="64px" />
        <p class="lead text-white mt-2">
          C'è stato un errore nel caricamento...
        </p>
        <a (click)="load()" class="text-white mt-2 link"
          ><i class="fas fa-redo-alt"></i
        ></a>
      </div>
    </div>

    <div
      class="d-flex align-items-center justify-content-center flex-wrap"
      style="height: 250px"
      *ngIf="rows.length === 0 && !error && !loading"
    >
      <div class="text-center">
        <img src="/assets/img/flats/settings_2.png" height="64px" />
        <p class="lead mt-2">
          Non ci sono dati con questi filtri. Prova a cambiarli.
        </p>
      </div>
    </div>
  </app-table>
</div>
