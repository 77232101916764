/* tslint:disable:variable-name */

import { BaseModel } from "./base-model";

export class Pagination extends BaseModel {
  totalCount: number;
  page: number;
  pageSize: number;
  pages: number;
}
