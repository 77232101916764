import {Component, OnInit, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StoreProductVariant} from '@models/store/store-product';

@Component({
    selector: 'app-product-variant-detail',
    templateUrl: './product-variant-detail.component.html',
    styleUrls: ['./product-variant-detail.component.css']
})
export class ProductVariantDetailComponent implements OnInit {
    @Input() variants: StoreProductVariant[] = [];
    currency = '€';

    constructor(public modalService: NgbModal) {
    }

    ngOnInit(): void {
    }

}
