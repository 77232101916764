import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dealer } from '@app/models/dealer';
import { StaffService } from '@app/services/staff.service';

@Component({
  selector: 'app-staff-select',
  templateUrl: './staff-select.component.html',
  styleUrls: ['./staff-select.component.scss']
})
export class StaffSelectComponent implements OnInit {
  @Input()
  small = false;
  @Input()
  disabled = false;

  @Input()
  model: any;

  @Input()
  vendors: [];

  data: Dealer[] = [];

  @Output() onChange = new EventEmitter();

  loading = false;

  constructor(
    private service: StaffService
  ) {
  }

  ngOnInit(): void {
    if(this.model) {
      this.model = parseInt(this.model);
    }
    let params = {fields: 'id,ragsociale', 'per-page': 0};

    if(this.vendors && this.vendors.length > 0) {
      params['vendors'] = this.vendors.join(',');
    }

    this.loading = true;
    this.service.list(params).subscribe((data) => {
      this.data = new Dealer().fromArray(data.rows);
      this.loading = false;
    });
  }

}
