import {Component, OnInit} from '@angular/core';
import {PbxQuestion, PbxQuestionListPagination} from '@models/pbx/pbx-question';
import {MetaPagination} from '@models/meta-pagination';
import {PbxCampaignService} from '@services/pbx-campaign.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-pbx-questions',
    templateUrl: './pbx-questions.component.html',
    styleUrls: ['./pbx-questions.component.css']
})
export class PbxQuestionsComponent implements OnInit {
    questions: PbxQuestion[] = [];
    questionsPagination: MetaPagination;
    questionCategories = PbxCampaignService.questionCategories;
    questionTypes = PbxCampaignService.questionTypes;
    filter = {
        category: '',
        type: ''
    };

    constructor(private pbxCampaignService: PbxCampaignService,
                private spinner: NgxSpinnerService) {
        this.loadQuestions();
    }

    ngOnInit(): void {
    }

    loadQuestions() {
        this.spinner.show('table-questions');
        const param = {};
        if (this.filter.category) {
            param['category'] = this.filter.category;
        }
        if (this.filter.type) {
            param['question_type'] = this.filter.type;
        }
        if (this.questionsPagination?.currentPage) {
            param['page'] = this.questionsPagination.currentPage;
        }
        this.pbxCampaignService.getQuestions(param).subscribe((response: PbxQuestionListPagination) => {
            this.questions = response.data;
            this.questionsPagination = response._meta;
            this.spinner.hide('table-questions');
        }, err => {
            this.spinner.hide('table-questions');
        });
    }

    questionCategory(key: string) {
        return key ? PbxCampaignService.questionCategories.find(data => data.key === key)?.name : '';
    }

    questionType(key: string) {
        return key ? PbxCampaignService.questionTypes.find(data => data.key === key)?.name : '';
    }
}
