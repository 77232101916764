import {Directive, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthenticationService} from '@services/authentication.service';
import {User} from '@models/user';

@Directive({
    selector: '[showDashboard]'
})
export class ShowDashboardDirective{
    private user: User;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthenticationService
    ) {
        this.authService.currentUser.subscribe(user => this.user = user);

        if (this.user && this.user.profile.id === 4281) {
            // If condition is true add template to DOM
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            // Else remove template from DOM
            this.viewContainer.clear();
        }
    }
}
