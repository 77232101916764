import {Component, Input, OnInit} from '@angular/core';
import {DashboardService} from "@services/dashboard.service";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
    selector: 'app-autoload-table-riepilogo',
    templateUrl: './autoload-table-riepilogo.component.html',
    styleUrls: ['./autoload-table-riepilogo.component.css']
})
export class AutoloadTableRiepilogoComponent implements OnInit {

    _url: string;
    data: [];

    @Input()
    set url(value: string) {
        this._url = value;

        if (this._url) {
            this.refresh();
        }
    }

    get url() {
        return this._url;
    }

    constructor(private dashboardService: DashboardService,
                private spinner: NgxSpinnerService) {
    }

    ngOnInit() {

    }

    public setUrl(value) {
        this.url = value;
    }

    refresh() {
        this.spinner.show('loader-riepilogo');
        this.dashboardService.get(this.url)
            .subscribe((data) => {
                this.data = data.result;
                this.spinner.hide('loader-riepilogo');
            });
    }
}
