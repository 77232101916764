/* tslint:disable:variable-name */

export class Tax {
  format: string;
  id: string;
  istaxable: string;
  matches: [{name: '', value: string}];
  name: string;
  taxes: [{id: string, tax: '', percentage: ''}];
  type: string;
  value: string;

  fromArray(result: []) {
    const array: Tax[] = [];
    for (const item of result) {
      const tempItem: Tax = new Tax();
      Object.assign(tempItem, item);
      array.push(tempItem);
    }
    return array;
  }
}
