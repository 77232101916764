import {Component, Input, OnInit} from '@angular/core';
import {EventiPresidiati} from '@app/models/eventi_presidiati/eventi_presidiati';
import {EPayService} from '@app/services/e-pay.service';
import {EventsService} from '@app/services/events.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '@environments/environment';

@Component({
    selector: 'app-event-view',
    templateUrl: './event-view.component.html',
    styleUrls: ['./event-view.component.css'],
})
export class EventViewComponent {
    @Input() event: EventiPresidiati;
    busy: any;
    eventStatuses = EventsService.eventStatuses;
    localFormat = 'DD/MM/YYYY';
    currency = EPayService.currency;
    awsUrl = environment.baseAwsUrl;

    constructor(private modalService: NgbModal) {
    }

    dismissModal() {
        this.modalService.dismissAll();
    }
}
