<div class="row" [ngBusy]="busy">
  <div class="col-12 d-block d-lg-none">
    <app-cart></app-cart>
  </div>
  <div class="col-12 col-lg-3">

    <app-shop-menu
            [products]="products"
            [originProducts]="originProducts"
            (productsChange)="this.products = $event">

    </app-shop-menu>
  </div>
  <div class="col-12 col-lg-6">
    <div class="row align-items-center">
      <div class="col-8">
        <p class="lead mb-0">{{ products.length }} prodotti presenti</p>
      </div>
      <div class="col-4">
        <select class="form-control">
          <option>Ultimi prodotti</option>
        </select>
      </div>
    </div>
    <hr>

    <app-product *ngFor="let product of products | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize" [product]="product">
    </app-product>

    <ngb-pagination
      *ngIf="this.products"
      [(page)]="page"
      [pageSize]="pageSize"
      [collectionSize]="products.length">

    </ngb-pagination>

  </div>
  <div class="col-12 col-lg-3 d-none d-lg-block">
    <app-cart></app-cart>
  </div>
</div>
