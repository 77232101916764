import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { DataService } from "@services/data.service";
import { DataRange } from "@app/pages/partials/data-range/data-range.component";
import { objectToFormData } from "object-to-formdata";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { DateRange } from "@fullcalendar/core";

interface Periodo {
  dataRange: DataRange;
  mattina: string;
  pomeriggio: string;
}

@Component({
  selector: "app-eventi-new",
  templateUrl: "./eventi-new.component.html",
  styleUrls: ["./eventi-new.component.scss"],
})
export class EventiNewComponent implements OnInit {
  form: FormGroup;

  // private periodi: Periodo[] = [];
  busy: Subscription;
  submittedFile: File;
  get periodi(): FormArray {
    return this.form.get("periodi") as FormArray;
  }

  constructor(public router: Router, public dataService: DataService, public formBuilder: FormBuilder) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      nome: [null, [Validators.required]],
      provincia: [null, [Validators.required]],
      comune: [null, [Validators.required]],
      indirizzo: [null, [Validators.required]],
      descrizione: [null, [Validators.required]],
      periodo_globale: [null, [Validators.required, this.validatorePeriodoGloable()]],
      periodi: new FormArray([], []),
      costo_totale: [null, [Validators.required]],
      stima_contratti: [null, [Validators.required]],
      evento_tipologia: [null, [Validators.required]],
      evento_allestimenti: [null, [Validators.required]],
      note_allestimento: [null, [Validators.required]],
      file_contratto: [null],
      file_name: [null],
      motivo_no_contratto: [null, [Validators.required]],
    });

    this.form.get("file_contratto").valueChanges.subscribe((val) => {
      if (val === null) {
        this.form.get("motivo_no_contratto").setValidators(Validators.required);
      } else {
        this.form.get("motivo_no_contratto").clearValidators();
      }
      this.form.get("motivo_no_contratto").updateValueAndValidity();
    });
  }
  
  c(): {
    [key: string]: AbstractControl;
  } {
    return this.form.controls;
  }

  submit() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const formData = objectToFormData(this.form.value, { indices: true });

      this.busy = this.dataService.post("/inserisci-presidio-evento", formData).subscribe((data) => {
        if (data.success) {
          this.router.navigate(["/presidia-evento"]);
        }
      });
    }
  }

  // Il periodo deve essere successivo al giorno d'oggi
  validatorePeriodoGloable(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const inputRange = control.value as DataRange;
        const today = new Date();
        inputRange.from.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        let checkPassed = false;
        if (inputRange.to) {
          inputRange.to.setHours(0, 0, 0, 0);
          checkPassed = inputRange && inputRange.from >= today && inputRange.to >= today;
        } else {
          checkPassed = inputRange && inputRange.from >= today;
        }

        return checkPassed ? null : { period: { value: control.value } };
      } else {
        return { required: { value: control.value } };
      }
    };
  }

  validatorePeriodo(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const inputRange = control.value as DataRange;
        const periodoGlobale = this.c().periodo_globale.value;

        inputRange.from.setHours(0, 0, 0, 0);
        let checkPassed = false;
        if (periodoGlobale) {
          periodoGlobale.from.setHours(0, 0, 0, 0);
          periodoGlobale.to.setHours(0, 0, 0, 0);

          if (inputRange.to) {
            inputRange.to.setHours(0, 0, 0, 0);
            checkPassed =
              inputRange.from >= periodoGlobale.from &&
              inputRange.from <= periodoGlobale.to &&
              inputRange.to >= periodoGlobale.from &&
              inputRange.to <= periodoGlobale.to;
          } else {
            checkPassed = inputRange.from >= periodoGlobale.from && inputRange.from <= periodoGlobale.to;
          }
        }
        return checkPassed ? null : { period: { value: control.value } };
      } else {
        return { required: { value: control.value } };
      }
    };
  }

  addPeriodo() {
    this.periodi.push(
      this.formBuilder.group({
        periodo: [null, [Validators.required, this.validatorePeriodo()]],
        mattina: [null, [Validators.required /*Validators.pattern(AppConfig.patternTIME2TIME)*/]],
        pomeriggio: [null, [Validators.required /*Validators.pattern(AppConfig.patternTIME2TIME)*/]],
      })
    );
  }

  removePeriodo(index: number) {
    this.periodi.removeAt(index);
  }

  handleFileInput(file: File) {
    this.c().file_name.setValue(file.name);
    this.c().file_contratto.setValue(file);
  }

  handleFileRemoveModal() {
    this.c().file_contratto.setValue(null);
    this.c().file_name.setValue(null);
  }

  get file() {
    return this.c().file_name.value;
  }
}
