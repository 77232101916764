import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {OperatorService} from "@services/operator.service";
import {Subscription} from "rxjs";
import {DataService} from "@services/data.service";
import {ModalComponent} from "@widgets/modal/modal.component";
import {CustomValidators} from "ngx-custom-validators";

@Component({
    selector: 'app-gestione-venditori-dettagli',
    templateUrl: './gestione-venditori-dettagli.component.html',
    styleUrls: ['./gestione-venditori-dettagli.component.css']
})
export class GestioneVenditoriDettagliComponent implements OnInit {
    @ViewChild('docModal') docModal: ModalComponent;
    @ViewChild('ctrModal') ctrModal: ModalComponent;

    new = false;

    form: FormGroup;
    formContratto: FormGroup;
    presidio: string;
    venditore: string;
    busy: Subscription;
    data = {
        codici_padre: [],
        presidi: [],
        statistiche: {rows: [], columns: []},
        venditore: null,
        documenti: [],
        contratti: [],
        province: [],
        fatture: []
    };
    comuni = [];
    comuniSede = [];
    comuniNascita: [];
    descrizioneDocumento = '';
    contrattoFile: any;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        private operatorService: OperatorService,
        private dataService: DataService,
        private formBuilder: FormBuilder
    ) {
        this.presidio = this.route.snapshot.paramMap.get('id');
        this.venditore = this.route.snapshot.paramMap.get('venditore');
        if(!this.venditore) {
            this.new = true;
        }
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            id: [null, []],
            id_padre: [null, []],
            piva: [null, []],
            visibile: [null, []],
            deleted: [null, [Validators.required]],
            id_presidio: [null, [Validators.required]],
            password: [null, [Validators.required]],
            nome: [null, [Validators.required]],
            cognome: [null, [Validators.required]],
            telefono: [null, [CustomValidators.number]],
            email: [null, [Validators.email]],
            pec: [null, [Validators.email]],
            partita_iva: [null, [CustomValidators.number, Validators.minLength(11), Validators.maxLength(11)]],
            cod_fiscale: [null, []],
            data_nascita: [null, [Validators.required, CustomValidators.date]],
            provincia_nascita: [null, []],
            comune_nascita: [null, []],
            comune: [null, []],
            provincia: [null, []],
            cap: [null, [CustomValidators.number, Validators.minLength(5), Validators.maxLength(5)]],
            indirizzo: [null, []],
            civico: [null, []],
            comune_sede_legale: [null, []],
            provincia_sede_legale: [null, []],
            cap_sede_legale: [null, [Validators.minLength(5), Validators.maxLength(5)]],
            indirizzo_sede_legale: [null, []],
            iban: [null, []],
        });
        this.formContratto = this.formBuilder.group({
            id: [null, []],
            id_operatore: [this.venditore, []],
            inizio: [null, [Validators.required]],
            fine: [null, [Validators.required]],
            tipo_contratto: [null, [Validators.required]],
            percorso: [null, []],
            rimborso: [null, [Validators.required, CustomValidators.number]],
            stato: [null, [Validators.required]],
        });
        this.refresh();
    }

    c() {
        return this.form.controls;
    }

    setComuni($event: any) {
        this.dataService.post('/get-comuni', {id_provincia: $event.id}).subscribe((data) => this.comuni = data.result);
    }

    get presidi() {
        if (this.data && this.data.presidi) {
            return this.data.presidi;
        }

        return [];
    }

    get province() {
        if (this.data && this.data.province) {
            return this.data.province;
        }

        return [];
    }

    setComuniSede($event: any) {
        this.dataService.post('/get-comuni', {id_provincia: $event.id}).subscribe((data) => this.comuniSede = data.result);
    }

    save() {
        if (this.form.valid) {
            this.busy = this.operatorService.post(`/save-venditore`, this.form.value)
                .subscribe((data: any) => {
                    if (data.success) {
                        if (this.new) {
                            this.router.navigate(['/gestione-stand', this.presidio, data.result.id]);
                        }
                        this.refresh();
                    }
                });
        }
        else {
            this.form.markAllAsTouched();
        }
    }

    refresh() {
        this.busy = this.operatorService.get(`/get-venditore?id=${this.venditore}`)
            .subscribe((data) => {
                this.data = data.result;
                this.form.patchValue(this.data.venditore);

                if(this.new) {
                    this.c().id_presidio.setValue(parseInt(this.presidio));
                    this.form.markAllAsTouched();
                }
            });
    }

    setComuniNascita($event: any) {
        this.dataService.post('/get-comuni', {id_provincia: $event.id}).subscribe((data) => this.comuniNascita = data.result);

    }

    uploadFile($event: any) {
        const file = $event.target.files.item(0);
        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('id', this.venditore);
        formData.append('descrizione', this.descrizioneDocumento);

        this.busy = this.operatorService.post(`/save-venditore-document`,
            formData
        ).subscribe((data: any) => {
            this.descrizioneDocumento = '';
            this.docModal.dismiss();
            this.refresh();
        });
    }

    setContrattoFile($event: any) {
        const file = $event.target.files.item(0);
        if (!file) {
            return;
        }

        this.contrattoFile = file;
    }

    saveContratto() {
        this.formContratto.controls.id_operatore.setValue(this.venditore);
        const formData = new FormData();
        if(!this.formContratto.controls.id.value) {
            formData.append('file', this.contrattoFile, this.contrattoFile.name);
        }
        formData.append('contratto', JSON.stringify(this.formContratto.value));

        this.busy = this.operatorService.post(`/save-venditore-contratto`, formData)
            .subscribe((data: any) => {
                if(data.success) {
                    this.formContratto.reset();
                    this.contrattoFile = null;
                    this.ctrModal.dismiss();
                    this.refresh();
                }
            });
    }

    editContratto(ctr: any) {
        this.formContratto.patchValue(ctr);
        this.ctrModal.open();
    }

    delContratto() {
        this.busy = this.operatorService.get(`/delete-venditore-contratto?id=${this.formContratto.controls.id.value}`)
            .subscribe((data: any) => {
                if(data.success) {
                    this.formContratto.reset();
                    this.contrattoFile = null;
                    this.ctrModal.dismiss();
                    this.refresh();
                }
            });
    }
}
