import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "@services/data.service";
import { Subscription } from "rxjs";
import { TableColumn, TableColumnType } from "@app/pages/partials/table/table.component";
import { EventoPresidiato } from "@models/evento-presidiato";
import { EventsService } from "@app/services/events.service";

@Component({
  selector: "app-eventi-index",
  templateUrl: "./eventi-index.component.html",
  styleUrls: ["./eventi-index.component.scss"],
})
export class EventiIndexComponent implements OnInit {
  public rows: EventoPresidiato[];
  public columns: TableColumn[];
  busy: Subscription;
  eventStatuses = EventsService.eventStatuses;

  constructor(public router: Router, public dataService: DataService) {}

  ngOnInit() {
    this.busy = this.dataService.get("/get-lista-eventi-presidiati").subscribe((data: any) => {
      this.rows = new EventoPresidiato().fromArray(data.result).map((row) => {
        return this.getStatus(row);
      });
    });

    this.columns = [
      { key: "nome", text: "Nome Evento" },
      { key: "provincia", text: "Provincia" },
      { key: "comune", text: "Comune" },
      { key: "from_date", text: "Da", type: TableColumnType.DATE },
      { key: "to_date", text: "A", type: TableColumnType.DATE },
      { key: "stato", text: "Stato", type: TableColumnType.BADGE },
      { key: "contributo", text: "Contributo", type: TableColumnType.CURRENCY },
    ];
  }

  getStatus(event: EventoPresidiato) {
    const status = this.eventStatuses.filter(status => status.key === parseInt(event.stato))[0];
    event.stato = status.name;
    return event;
  }

  onSelect(id: any) {
    this.router.navigate(["/presidia-evento/dettaglio", id]);
  }
}
