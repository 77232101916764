import { BaseModel } from "./base-model";
import { Vendor } from "./vendor";

/* tslint:disable:variable-name */
export class Recharge extends BaseModel {
  id: number;
  retailer: string;
  data: string;
  importo: number;
  vendor: Vendor;
  numero: string;
  id_dealer: number;
  stato: string;
  file_path: string;
  receipt: File;

  get statusBackground() {
    switch (this.stato) {
      case "Completata":
        return "badge-success";
      case "In Attesa":
        return "badge-warning";
      case "Rifiutata":
        return "badge-danger";
    }
  }

  get childModels() {
    return {
      vendor: Vendor,
    };
  }

  fromArray(result: []) {
    const array: Recharge[] = [];
    for (const item of result) {
      array.push(new Recharge(item));
    }
    return array;
  }
}
