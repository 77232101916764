import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {EPayService} from '@services/e-pay.service';
import {ShopBrand} from '@models/e-pay/shop-brand';
import {TinyMCE} from '@models/tinyMCE';

@Component({
    selector: 'app-brand-modal',
    templateUrl: './brand-modal.component.html',
    styleUrls: ['./brand-modal.component.css']
})
export class BrandModalComponent implements OnInit {
    @Output() newBrand = new EventEmitter<any>();
    @Input() brandModal: NgbModalRef;
    @Input() brands: ShopBrand[] = [];
    @Input() selectedBrand: ShopBrand;
    busy: any;
    defaultConfig = new TinyMCE();
    brandForm = new FormGroup({
        name: new FormControl('', Validators.required),
        image_name: new FormControl('', Validators.required),
        is_active: new FormControl(true, Validators.required),
        description: new FormControl('')
    });
    submittedFile: File;

    constructor(private ePayService: EPayService) {
    }

    ngOnInit(): void {
        this.loadData();
    }

    get imageName() {
        return this.brandForm.get('image_name').value;
    }

    saveBrands() {
        const brand = new ShopBrand();
        brand.name = this.brandForm.value.name;
        brand.image_name = this.brandForm.value.image_name;
        brand.description = this.brandForm.value.description;
        brand.is_active = this.brandForm.value.is_active ? '1' : '0';
        brand.file = this.submittedFile;
        if (this.selectedBrand) {
            return this.changeBrand(brand);
        }
        return this.addBrand(brand);
    }

    addBrand(brand: ShopBrand) {
        this.busy = this.ePayService.postBrand(brand).subscribe((response: ShopBrand) => {
            this.brands.unshift(response);
            this.newBrand.emit(response);
            this.ePayService.successAlert('Created Brand!');
            this.dismissModal();
        }, err => {
            this.ePayService.errorAlert(err.message);
            this.dismissModal();
        });
    }

    changeBrand(brand: ShopBrand) {
        this.busy = this.ePayService.putBrand(this.selectedBrand.id, brand).subscribe((response: ShopBrand) => {
            const index = this.brands.findIndex(data => data.id === response.id);
            if (index >= 0) {
                this.brands[index] = response;
                this.brands[index].is_active = Number(response.is_active);
            }
            this.ePayService.successAlert('Changed Brand!');
            this.dismissModal();
        }, err => {
            this.ePayService.errorAlert(err.message);
            this.dismissModal();
        });
    }

    handleFileInput(file: File) {
        this.submittedFile = file;
        this.brandForm.get('image_name').setValue(file.name);
    }

    handleFileRemoveModal() {
        this.brandForm.get('image_name').setValue('');
        this.submittedFile = null;
    }

    dismissModal() {
        this.brandModal.dismiss();
    }

    loadData() {
        if (this.selectedBrand) {
            this.brandForm.setValue({
                name: this.selectedBrand.name,
                image_name: this.selectedBrand.image_name,
                is_active: this.selectedBrand.is_active === 1 || this.selectedBrand.is_active === '1',
                description: this.selectedBrand.description
            });
        }
    }
}
