<ng-template #questionTable let-modal size="xl">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{label}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngClass]="{'p-0': step === 4}" style="min-height: 300px;">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0)"
                     size="default"
                     color="#000"
                     type="square-jelly-box"
                     [fullScreen]="false">
            <p style="color: dark;"> Caricamento... </p>
        </ngx-spinner>

        <div class="text-center">
            <div *ngIf="this.step === 1">
                <p class="lead">Vuoi vedere nel <b>{{this.question}} {{this.param}}</b> per il brand</p>
                <i class="fas fa-long-arrow-alt-down text-lg my-3"></i>
                <div class="row justify-content-center" *ngIf="questions.length > 0">
                    <div class="col-3 mb-2" *ngFor="let question of questions">
                        <button class="btn btn-outline-primary btn-block" (click)="nextQuestion(question.key)">{{question.question}}</button>
                    </div>
                </div>
            </div>
            <div *ngIf="this.step === 2">
                <p class="lead">Vuoi vedere nel <b>{{this.question}} {{this.param}}</b> per il brand</p>
                <i class="fas fa-long-arrow-alt-down text-lg my-3"></i>
                <p class="lead"><b>{{this.operatore}}</b></p>
                <i class="fas fa-long-arrow-alt-down text-lg my-3"></i>
                <div class="row justify-content-center" *ngIf="questions.length > 0">
                    <div class="col-3 mb-2" *ngFor="let question of questions">
                        <button class="btn btn-outline-primary btn-block" (click)="nextQuestion(question.key)">{{question.question}}</button>
                    </div>
                </div>
            </div>
            <div *ngIf="this.step === 3">
                <p class="lead">Vuoi vedere nel <b>{{this.question}} {{this.param}}</b> per il brand</p>
                <i class="fas fa-long-arrow-alt-down text-lg my-3"></i>
                <p class="lead"><b>{{this.operatore}}</b></p>
                <i class="fas fa-long-arrow-alt-down text-lg my-3"></i>
                <p class="lead"><b>{{this.answerLabel}}</b></p>
                <i class="fas fa-long-arrow-alt-down text-lg my-3"></i>
                <p class="lead">nel formato</p>
                <i class="fas fa-long-arrow-alt-down text-lg my-3"></i>
                <div class="row justify-content-center" *ngIf="questions.length > 0">
                    <div class="col-3 mb-2" *ngFor="let question of questions">
                        <button class="btn btn-outline-primary btn-block" (click)="nextQuestion(question.key)">{{question.question}}</button>
                    </div>
                </div>
            </div>
        </div>


<!--        <div class="row" *ngIf="questions.length > 0 && step < 4">-->
<!--            <div class="col-4" *ngFor="let question of questions">-->
<!--                <div class="card bg-gradient-dark zoom" (click)="nextQuestion(question.key)">-->
<!--                    <div class="card-body text-center">-->
<!--                        <img [src]="question.image" height="50px" />-->
<!--                        <h1 class="display-6 mt-2">{{question.question}}</h1>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

        <ng-container *ngIf="step === 4">
            <app-autoload-table [url]="questionUrl"></app-autoload-table>
        </ng-container>
    </div>
    <div class="card-footer">
        <button class="btn btn-secondary" [disabled]="step <= 1" (click)="goBack()"><i class="fas fa-arrow-alt-circle-left"></i> Indietro</button>
    </div>
</ng-template>
