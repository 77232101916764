<div class="modal-body table-responsive">
    <table class="table table-sm">
        <thead>
        <tr>
            <th translate>Name</th>
            <th translate>Quantity</th>
            <th class="text-right" translate>Unit price</th>
            <th class="text-right" translate>VAT price</th>
            <th translate>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let variant of variants;index as i">
            <th>{{variant?.name}}</th>
            <th>{{variant?.quantity}}</th>
            <td class="text-right">{{variant?.price|currency:currency}}</td>
            <td class="text-right">{{variant?.vat_price|currency:currency}}</td>
            <td>
                <app-rich-text-truncate [text]="variant?.description"></app-rich-text-truncate>
            </td>
        </tr>
        </tbody>
    </table>
</div>
