import {BaseModel} from './base-model';
import {Company} from './company';

export class MasterPlan extends BaseModel {

    affiliations: AffiliationPlan;
    sales: SalesPlan;
    revenue: RavenuePlan;
}

export class AffiliationPlan extends BaseModel {
    plan: Plan;
    real: number;
    shops: number;
    breakdown: Array<any>;
}

export class Plan extends BaseModel {
    id: number;
    target_type: string;
    month: string;
    year: string;
    value: string;
    operator?: Company;
    agent?: Array<any>;
    region?: Array<any>;
    province?: Array<any>;
}

export class SalesPlan extends BaseModel {
    plan: Plan;
    real: number;
    shops: number;
    breakdown: Array<any>;
}

export class RavenuePlan extends BaseModel {
    real: number;
    shop_commission: number;
    agent_commission: number;
    cost: number;
    net_margin: number;
    plan: Plan;
}



