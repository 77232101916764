import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Vendor } from "@app/models/vendor";
import { VendorService } from "@app/services/vendor.service";

@Component({
  selector: "app-vendor-select",
  templateUrl: "./vendor-select.component.html",
  styleUrls: ["./vendor-select.component.scss"],
})
export class VendorSelectComponent implements OnInit {
  @Input()
  multiple = false;
  @Input()
  small = false;

  model = null;

  data: Vendor[] = [];
  
  @Output() onChange = new EventEmitter();

  constructor(private service: VendorService) {}

  ngOnInit() {
    this.service.getList({fields: 'id,name'}).subscribe((data) => {
      this.data = new Vendor().fromArray(data);
    });
  }
}
