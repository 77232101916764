<app-master-header [ngBusy]="busy"
                   cardClass="bg-gradient-dark"
                   image="assets/img/flats/settings.png"
                   title="Gestione Report"
>
    <div class="row align-items-end">
        <div class="col-6">
            <label>Seleziona un operatore</label>
            <div class="form-group form-group-icon form-group-dark mb-0">
                <i class="fas fa-search"></i>
                <select class="form-control" [(ngModel)]="this.operatore">
                    <ng-container *ngIf="this.startup">
                        <option *ngFor="let op of this.startup.operatori" [value]="op.value">{{op.label}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
        <div class="col-6">
            <label>Seleziona periodo</label>
            <div class="form-group form-group-icon form-group-dark mb-0">
                <i class="far fa-calendar-alt"></i>
                <select class="form-control" [(ngModel)]="this.periodo">
                    <ng-container *ngIf="this.startup">
                        <option *ngFor="let per of this.startup.periodi" [value]="per.value">{{per.label}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
        <div class="col-12">
            <hr>
            <button class="btn btn-success float-right" (click)="save();">Salva Modifiche</button>
        </div>
    </div>
</app-master-header>

<ng-container *ngIf="data">
    <div *ngFor="let row of data.struttura" class="row">
        <div *ngFor="let col of row.cols" [ngClass]="col.class">
            <app-hot *ngFor="let card of col.rows" [title]="card.title" [image]="card.image"
                     [height]="500"
                     [buttons]="card.buttons"
                     [showFilters]="true"
                     [rows]="getRows(card)"
                     [cols]="getCols(card)" [@fadeInOnEnter] [@fadeOutOnLeave]
                     (triggerAction)="this.callAction($event)"
            >

            </app-hot>
        </div>
    </div>
</ng-container>

<app-modal [title]="modal.title" #messageModal>
    <div class="text-center">
        <i class="far mb-2" style="font-size: 3rem;" [ngClass]="modal.icon"></i>
        <h1 class="display-6">{{modal.message}}</h1>
        <p class="lead">{{modal.description}}</p>
    </div>
</app-modal>
