import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-documents-modal',
  templateUrl: './documents-modal.component.html',
  styleUrls: ['./documents-modal.component.scss']
})
export class DocumentsModalComponent implements OnInit {
  @ViewChild('documentModal') private documentModal;
  document: string;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  public showDocument(id: string) {
    // this.document = `${this.globals.apiUrl}/files/${id}`;
    this.document = id;
    this.modalService.open(this.documentModal, { size: 'lg' }).result.then((result) => {
    });
  }

}
