<div [style.min-heihgt.px]="200">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)"
                 size="default"
                 color="#fff"
                 type="square-jelly-box"
                 name="loader-keypoints"
                 [fullScreen]="false">
        <p style="color: white"> Caricamento... </p>
    </ngx-spinner>

    <div class="info-box bg-gradient-light elevation-1" *ngFor="let item of data">
        <span class="info-box-icon">
            <img [src]="item.image"
                 height="40px"/>
        </span>

        <div class="info-box-content">
            <span class="info-box-text">{{item.key}}</span>
            <span class="info-box-number">{{item.value}}</span>
        </div>
    </div>
</div>
