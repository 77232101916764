<div style="height: 200px;">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)"
                 size="default"
                 color="#fff"
                 type="square-jelly-box"
                 name="loader-chart"
                 [fullScreen]="false">
        <p style="color: white"> Caricamento... </p>
    </ngx-spinner>

    <canvas baseChart width="100"
            *ngIf="data"
            [colors]="colors"
            [legend]="legend"
            [options]="options"
            [datasets]="datasets"
            [labels]="labels"
            [chartType]="type"></canvas>
</div>
