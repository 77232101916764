<div class="card">
    <div class="card-header"
         [ngClass]="{'bg-eni-gradient': (tipoVendita === 'residenziale'), 'bg-gradient-primary': (tipoVendita === 'business')}">
        <h1 class="card-title">Impostazioni</h1>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-4">
                <div class="form-group">
                    <label>Tipologia di vendita</label>
                    <select class="form-control" [(ngModel)]="tipoVendita" (ngModelChange)="checkBusiness()">
                        <option value="residenziale">Residenziale</option>
                        <option value="business">Business</option>
                    </select>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>Tipo di pagamento</label>
                    <select class="form-control" [(ngModel)]="tipoPagamento" [disabled]="tipoVendita === 'business'">
                        <option value="rid">RID</option>
                        <option value="bollettino">Bollettino Postale</option>
                    </select>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label>Cadenza Bolletta</label>
                    <select class="form-control" [(ngModel)]="cadenzaBolletta">
                        <option value="mensile">Mensile</option>
                        <option value="bimestrale">Bimestrale</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label>kWh</label>
                <input type="number" class="form-control" [(ngModel)]="kwh" [disabled]="tipoVendita==='business'"/>
            </div>
            <div class="col-3">
                <label>Spesa per materia Energia</label>
                <input type="number" class="form-control" [(ngModel)]="spesaEnergia"/>
            </div>
            <div class="col-3">
                <label>Smc</label>
                <input type="number" class="form-control" [(ngModel)]="smc"/>
            </div>
            <div class="col-3">
                <label>Spesa per materia Gas</label>
                <input type="number" class="form-control" [(ngModel)]="spesaGas"/>
            </div>
        </div>
        <div class="row" *ngIf="tipoVendita === 'business'">
            <div class="col-4">
                <label>kWh in F1</label>
                <input type="number" class="form-control" [(ngModel)]="kwhPrimaFascia"/>
            </div>
            <div class="col-4">
                <label>kWh in F2</label>
                <input type="number" class="form-control" [(ngModel)]="kwhSecondaFascia"/>
            </div>
            <div class="col-4">
                <label>kWh in F3</label>
                <input type="number" class="form-control" [(ngModel)]="kwhTerzaFascia"/>
            </div>
        </div>
    </div>
</div>

<div class="card card-warning card-outline card-outline-tabs" *ngIf="tipoVendita === 'residenziale'">
    <div class="card-header p-0 border-bottom-0">
        <ul class="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active display-6" id="custom-tabs-three-home-tab" data-toggle="pill"
                   href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home"
                   aria-selected="true">Sconto Certo
                    <img src="assets/img/flats/sale.png" height="36px" *ngIf="bestResidenziale === 'scontocerto'"/>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link display-6" id="custom-tabs-three-profile-tab" data-toggle="pill"
                   href="#custom-tabs-three-profile" role="tab" aria-controls="custom-tabs-three-profile"
                   aria-selected="false">Flexi
                    <img src="assets/img/flats/sale.png" height="36px" *ngIf="bestResidenziale === 'flexi'"/>
                </a>
            </li>
        </ul>
    </div>
    <div class="card-body pb-0">
        <div class="tab-content" id="custom-tabs-three-tabContent">
            <div class="tab-pane fade show active" id="custom-tabs-three-home" role="tabpanel"
                 aria-labelledby="custom-tabs-three-home-tab">
                <div class="row">
                    <div class="col-6 border-right">
                        <h1 class="display-6"><i class="fas fa-lightbulb"></i> Simulazione Bolletta Energia</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%" class="text-right">€/Mese</th>
                                <th width="20%" class="text-right">Risparmio</th>
                            </tr>
                            <tr>
                                <th>Spesa energia nel primo anno</th>
                                <td class="text-right">{{ scontoCerto.energia.mensilePrimoAnno | currency: '€ '}}</td>
                                <td class="text-right">{{ scontoCerto.energia.risparmioPrimoAnno | currency: '€ '}}</td>
                            </tr>
                            <tr>
                                <th>Spesa energia nel secondo anno</th>
                                <td class="text-right">{{ scontoCerto.energia.mensileSecondoAnno | currency: '€ '}}</td>
                                <td class="text-right">{{ scontoCerto.energia.risparmioSecondoAnno | currency: '€ '}}</td>
                            </tr>
                        </table>


                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%" class="text-right">1° Anno</th>
                                <th width="20%" class="text-right">2° Anno</th>
                            </tr>
                            <tr>
                                <th>Spesa Energia</th>
                                <td class="text-right">{{ scontoCerto.energia.spesaEnergiaPrimoAnno }} €/kWh</td>
                                <td class="text-right">{{ scontoCerto.energia.spesaEnergiaSecondoAnno }} €/kWh</td>
                            </tr>
                        </table>

                        <h1 class="lead">Altri costi</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Commercializzazione e vendita</th>
                                <td class="text-right"
                                    width="20%">{{ scontoCerto.energia.commercializzazione | currency: '€ '}}</td>
                            </tr>
                        </table>

                        <h1 class="lead">Sconti</h1>


                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Sconto Domiciliazione</th>
                                <td class="text-right" width="20%">{{ scontoCerto.energia.scontoDomiciliazione }} %</td>
                            </tr>
                            <tr>
                                <th class="bg-eni-gradient">Sconto Primo Mese</th>
                                <td class="text-right">Gratis</td>
                            </tr>
                        </table>

                        <h1 class="display-6"><i class="fas fa-list-ul"></i> Riepilogo Offerta</h1>

                        <dl>
                            <dt>Prezzo Fisso</dt>
                            <dd>Corrispettivi Luce boccati per 24 mesi</dd>
                            <dt>Sconti</dt>
                            <dd>Sconto 5% dal 2° al 12° mese su corrispettivo luce</dd>
                            <dd>Sconto 10% dal 13° mese su corrispettivo luce</dd>
                            <dt>Sostenibilità</dt>
                            <dd>Energia Verde, prodotta 100% da fonti energia rinnovabile.</dd>
                            <dt>Vantaggi</dt>
                            <dd>Polizza multi-assistenza Casa in omaggio.</dd>
                            <dd>Voucher manutezione di 48 euro.</dd>
                        </dl>
                    </div>
                    <div class="col-6">
                        <h1 class="display-6"><i class="fas fa-fire"></i> Simulazione Bolletta Gas</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%" class="text-right">€/Mese</th>
                                <th width="20%" class="text-right">Risparmio</th>
                            </tr>
                            <tr>
                                <th>Spesa energia nel primo anno</th>
                                <td class="text-right">{{ scontoCerto.gas.mensilePrimoAnno | currency: '€ '}}</td>
                                <td class="text-right">{{ scontoCerto.gas.risparmioPrimoAnno | currency: '€ '}}</td>
                            </tr>
                            <tr>
                                <th>Spesa energia nel secondo anno</th>
                                <td class="text-right">{{ scontoCerto.gas.mensileSecondoAnno | currency: '€ '}}</td>
                                <td class="text-right">{{ scontoCerto.gas.risparmioSecondoAnno | currency: '€ '}}</td>
                            </tr>
                        </table>


                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%">1° Anno</th>
                                <th width="20%">2° Anno</th>
                            </tr>
                            <tr>
                                <th>Spesa Energia</th>
                                <td class="text-right">{{ scontoCerto.gas.spesaGasPrimoAnno }} €/smc</td>
                                <td class="text-right">{{ scontoCerto.gas.spesaGasSecondoAnno }} €/smc</td>
                            </tr>
                        </table>

                        <h1 class="lead">Altri costi</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Commercializzazione e vendita</th>
                                <td class="text-right"
                                    width="20%">{{ scontoCerto.gas.commercializzazione | currency: '€ '}}</td>
                            </tr>
                        </table>

                        <h1 class="lead">Sconti</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Sconto Domiciliazione</th>
                                <td class="text-right" width="20%">{{ scontoCerto.gas.scontoDomiciliazione }} %</td>
                            </tr>
                            <tr>
                                <th class="bg-eni-gradient">Sconto Primo Mese</th>
                                <td class="text-right">Gratis</td>
                            </tr>
                        </table>

                        <h1 class="display-6"><i class="fas fa-list-ul"></i> Riepilogo Offerta</h1>

                        <dl>
                            <dt>Prezzo Fisso</dt>
                            <dd>Corrispettivi Gas boccati per 24 mesi</dd>
                            <dt>Sconti</dt>
                            <dd>Sconto 5% dal 2° al 12° mese su corrispettivo gas</dd>
                            <dd>Sconto 10% dal 13° mese su corrispettivo gas</dd>
                            <dt>Vantaggi</dt>
                            <dd>Polizza multi-assistenza Casa in omaggio.</dd>
                            <dd>Voucher manutezione di 48 euro.</dd>
                        </dl>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="card bg-gradient-success" *ngIf="scontoCerto.energia.risparmioSecondoAnno > 0">
                            <div class="card-body">
                                <h1 class="display-6 mb-0">
                                    <i class="icon fas fa-check"></i> con Eni sulla bolletta luce risparmi fino
                                    a {{scontoCerto.energia.risparmioSecondoAnno | currency: '€ '}}
                                </h1>
                            </div>
                        </div>
                        <div class="card bg-gradient-danger" *ngIf="scontoCerto.energia.risparmioSecondoAnno < 0">
                            <div class="card-body">
                                <h1 class="display-6 mb-0">
                                    <i class="icon fas fa-check"></i> con Eni sulla bolletta luce
                                    pagheresti {{scontoCerto.energia.risparmioSecondoAnno | currency: '€ '}} in più
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 display-6">

                        <div class="card bg-gradient-success" *ngIf="scontoCerto.gas.risparmioSecondoAnno > 0">
                            <div class="card-body">
                                <h1 class="display-6 mb-0">
                                    <i class="icon fas fa-check"></i> con Eni sulla bolletta gas risparmi fino
                                    a {{-scontoCerto.gas.risparmioSecondoAnno | currency: '€ '}}
                                </h1>
                            </div>
                        </div>
                        <div class="card bg-gradient-danger" *ngIf="scontoCerto.gas.risparmioSecondoAnno < 0">
                            <div class="card-body">
                                <h1 class="display-6 mb-0">
                                    <i class="icon fas fa-check"></i> con Eni sulla bolletta gas
                                    pagheresti {{-scontoCerto.gas.risparmioSecondoAnno | currency: '€ '}} in più
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="custom-tabs-three-profile" role="tabpanel"
                 aria-labelledby="custom-tabs-three-profile-tab">
                <div class="row">
                    <div class="col-6 border-right">
                        <h1 class="display-6"><i class="fas fa-lightbulb"></i> Simulazione Bolletta Energia</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%" class="text-right">€/Mese</th>
                                <th width="20%" class="text-right">Risparmio</th>
                            </tr>
                            <tr>
                                <th>Spesa energia</th>
                                <td class="text-right">{{ flexi.energia.mensile | currency: '€ '}}</td>
                                <td class="text-right">{{ flexi.energia.risparmio | currency: '€ '}}</td>
                            </tr>
                        </table>


                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%" class="text-right">€/kWh</th>
                            </tr>
                            <tr>
                                <th>Spesa Energia</th>
                                <td class="text-right">{{ flexi.energia.spesaEnergia }} €/kWh</td>
                            </tr>
                        </table>

                        <h1 class="lead">Altri costi</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Commercializzazione e vendita</th>
                                <td class="text-right"
                                    width="20%">{{ flexi.energia.commercializzazione | currency: '€ '}}</td>
                            </tr>
                        </table>

                        <h1 class="lead">Sconti</h1>


                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Sconto Domiciliazione</th>
                                <td class="text-right" width="20%">{{ flexi.energia.scontoDomiciliazione }} %</td>
                            </tr>
                        </table>

                        <h1 class="display-6"><i class="fas fa-list-ul"></i> Riepilogo Offerta</h1>

                        <dl>
                            <dt>Prezzo Fisso</dt>
                            <dd>Prezzo Bloccato per 24 mesi</dd>
                            <dt>Opzioni</dt>
                            <dd>Illuminazione Smart a 5.50€/mese in bolletta per 24 mesi</dd>
                            <dd>Sensori Smart a 6.00€/mese in bolletta per 24 mesi</dd>
                        </dl>
                    </div>
                    <div class="col-6">
                        <h1 class="display-6"><i class="fas fa-fire"></i> Simulazione Bolletta Gas</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%" class="text-right">€/Mese</th>
                                <th width="20%" class="text-right">Risparmio</th>
                            </tr>
                            <tr>
                                <th>Spesa gas</th>
                                <td class="text-right">{{ flexi.gas.mensile | currency: '€ '}}</td>
                                <td class="text-right">{{ flexi.gas.risparmio | currency: '€ '}}</td>
                            </tr>
                        </table>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%">€/smc</th>
                            </tr>
                            <tr>
                                <th>Spesa Gas</th>
                                <td class="text-right">{{ flexi.gas.spesaGas }} €/smc</td>
                            </tr>
                        </table>

                        <h1 class="lead">Altri costi</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Commercializzazione e vendita</th>
                                <td class="text-right"
                                    width="20%">{{ flexi.gas.commercializzazione | currency: '€ '}}</td>
                            </tr>
                        </table>

                        <h1 class="lead">Sconti</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Sconto Domiciliazione</th>
                                <td class="text-right" width="20%">{{ flexi.gas.scontoDomiciliazione }} %</td>
                            </tr>
                        </table>

                        <h1 class="display-6"><i class="fas fa-list-ul"></i> Riepilogo Offerta</h1>

                        <dl>
                            <dt>Prezzo Fisso</dt>
                            <dd>Prezzo Bloccato per 24 mesi</dd>
                            <dt>Opzioni</dt>
                            <dd>Manutenzione x2 a 7.90€/mese in bolletta per 24 mesi</dd>
                        </dl>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <div class="card bg-gradient-success" *ngIf="flexi.energia.risparmio > 0">
                            <div class="card-body">
                                <h1 class="display-6 mb-0">
                                    <i class="icon fas fa-check"></i> con Eni sulla bolletta luce risparmi fino
                                    a {{flexi.energia.risparmio | currency: '€ '}}
                                </h1>
                            </div>
                        </div>
                        <div class="card bg-gradient-danger" *ngIf="flexi.energia.risparmio < 0">
                            <div class="card-body">
                                <h1 class="display-6 mb-0">
                                    <i class="icon fas fa-check"></i> con Eni sulla bolletta luce
                                    pagheresti {{-flexi.energia.risparmio | currency: '€ '}} in più
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 display-6">

                        <div class="card bg-gradient-success" *ngIf="flexi.gas.risparmio > 0">
                            <div class="card-body">
                                <h1 class="display-6 mb-0">
                                    <i class="icon fas fa-check"></i> con Eni sulla bolletta gas risparmi fino
                                    a {{flexi.gas.risparmio | currency: '€ '}}
                                </h1>
                            </div>
                        </div>
                        <div class="card bg-gradient-danger" *ngIf="flexi.gas.risparmio < 0">
                            <div class="card-body">
                                <h1 class="display-6 mb-0">
                                    <i class="icon fas fa-check"></i> con Eni sulla bolletta gas
                                    pagheresti {{-flexi.gas.risparmio | currency: '€ '}} in più
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /.card -->
</div>

<div class="card card-primary card-outline card-outline-tabs" *ngIf="tipoVendita === 'business'">
    <div class="card-header p-0 border-bottom-0">
        <ul class="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active display-6" id="custom-tabs-three-home-tab" data-toggle="pill"
                   href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home"
                   aria-selected="true">Raddoppio Sicuro
                    <img src="assets/img/flats/sale.png" height="36px" *ngIf="bestBusiness === 'raddoppio_sicuro'"/>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link display-6" id="custom-tabs-three-profile-tab" data-toggle="pill"
                   href="#custom-tabs-three-profile" role="tab" aria-controls="custom-tabs-three-profile"
                   aria-selected="false">Sconto Su Misura
                    <img src="assets/img/flats/sale.png" height="36px" *ngIf="bestBusiness === 'sconto_su_misura'"/>

                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link display-6" id="custom-tabs-three-profile-tab" data-toggle="pill"
                   href="#custom-tabs-three-yolo" role="tab" aria-controls="custom-tabs-three-profile"
                   aria-selected="false">SuMisura My Energy
                    <img src="assets/img/flats/sale.png" height="36px" *ngIf="bestBusiness === 'su_misura_my_energy'"/>
                </a>
            </li>
        </ul>
    </div>
    <div class="card-body pb-0">
        <div class="tab-content" id="custom-tabs-three-tabContent">
            <div class="tab-pane fade show active" id="custom-tabs-three-home" role="tabpanel"
                 aria-labelledby="custom-tabs-three-home-tab">
                <div class="row">
                    <div class="col-6 border-right">
                        <h1 class="display-6"><i class="fas fa-lightbulb"></i> Simulazione Bolletta Energia</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%" class="text-right">€/Mese</th>
                                <th width="20%" class="text-right">Risparmio</th>
                            </tr>
                            <tr>
                                <th>Profilo Luce Monorario</th>
                                <td class="text-right">{{ raddoppioSicuro.energia.mensileMonoraria | currency: '€ '}}</td>
                                <td class="text-right">{{ raddoppioSicuro.energia.risparmioMonoraria | currency: '€ '}}</td>
                            </tr>
                            <tr>
                                <th>Profilo Luce Monorario dal 4° al 6° mese</th>
                                <td class="text-right">{{ raddoppioSicuro.energia.mensileMonoraria4_6_mese | currency: '€ '}}</td>
                                <td class="text-right">{{ raddoppioSicuro.energia.risparmioMonoraria4_6_mese | currency: '€ '}}</td>
                            </tr>
                            <tr>
                                <th>Profilo Luce Monorario dal 7° mese</th>
                                <td class="text-right">{{ raddoppioSicuro.energia.mensileMonoraria7_mese | currency: '€ '}}</td>
                                <td class="text-right">{{ raddoppioSicuro.energia.risparmioMonoraria7_mese | currency: '€ '}}</td>
                            </tr>
                        </table>
                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%" class="text-right">€/Mese</th>
                                <th width="20%" class="text-right">Risparmio</th>
                            </tr>
                            <tr>
                                <th>Profilo Luce Biorario</th>
                                <td class="text-right">{{ raddoppioSicuro.energia.mensileBioraria | currency: '€ '}}</td>
                                <td class="text-right">{{ raddoppioSicuro.energia.risparmioBioraria | currency: '€ '}}</td>
                            </tr>
                            <tr>
                                <th>Profilo Luce Biorario dal 4° al 6° mese</th>
                                <td class="text-right">{{ raddoppioSicuro.energia.mensileBioraria4_6_mese | currency: '€ '}}</td>
                                <td class="text-right">{{ raddoppioSicuro.energia.risparmioBioraria4_6_mese | currency: '€ '}}</td>
                            </tr>
                            <tr>
                                <th>Profilo Luce Biorario dal 7° mese</th>
                                <td class="text-right">{{ raddoppioSicuro.energia.mensileBioraria7_mese | currency: '€ '}}</td>
                                <td class="text-right">{{ raddoppioSicuro.energia.risparmioBioraria7_mese | currency: '€ '}}</td>
                            </tr>
                        </table>


                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%" class="text-right">F1</th>
                                <th width="20%" class="text-right">F2</th>
                                <th width="20%" class="text-right">F3</th>
                            </tr>
                            <tr>
                                <th>Profilo Luce Mono</th>
                                <td class="text-right" colspan="3">{{ raddoppioSicuro.energia.spesaMonoraria }}€/kWh
                                </td>
                            </tr>
                            <tr>
                                <th>Profilo Luce Biorario</th>
                                <td class="text-right">{{ raddoppioSicuro.energia.spesaBiorariaF1 }} €/kWh</td>
                                <td class="text-right">{{ raddoppioSicuro.energia.spesaBiorariaF2 }} €/kWh</td>
                                <td class="text-right">{{ raddoppioSicuro.energia.spesaBiorariaF3 }} €/kWh</td>
                            </tr>
                        </table>

                        <h1 class="lead">Altri costi</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Commercializzazione e vendita</th>
                                <td class="text-right"
                                    width="20%">{{ raddoppioSicuro.energia.commercializzazione | currency: '€ '}}</td>
                            </tr>
                        </table>

                        <h1 class="lead">Sconti</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Sconto Domiciliazione</th>
                                <td class="text-right" width="20%">{{ raddoppioSicuro.energia.scontoDomiciliazione }}
                                    %
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-gradient-primary">Sconto Sicuro dal 4° al 6° mese</th>
                                <td class="text-right" width="20%">5 %</td>
                            </tr>
                            <tr>
                                <th class="bg-gradient-primary">Sconto Sicuro dal 7° mese</th>
                                <td class="text-right" width="20%">10 %</td>
                            </tr>
                        </table>

                        <h1 class="display-6"><i class="fas fa-list-ul"></i> Riepilogo Offerta</h1>

                        <dl>
                            <dt>Prezzo Fisso</dt>
                            <dd>Prezzo Bloccato per 24 mesi</dd>
                            <dt>Sostenibilità</dt>
                            <dd>Energia Verde, prodotta 100% da fonti energia rinnovabile.</dd>
                            <dt>Sconti</dt>
                            <dd>Sconto del 5% dal 4° al 6° mese</dd>
                            <dd>Sconto del 10% dal 7° mese</dd>
                            <dt>Polizza Assistenza</dt>
                            <dd>ZURICH: alla sottoscrizione verrà riconosciuto in omaggio l'assicurazione Polizza
                                Assistenza Business
                            </dd>
                        </dl>
                    </div>
                    <div class="col-6">
                        <h1 class="display-6"><i class="fas fa-fire"></i> Simulazione Bolletta Gas</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%" class="text-right">€/Mese</th>
                                <th width="20%" class="text-right">Risparmio</th>
                            </tr>
                            <tr>
                                <th>Spesa gas</th>
                                <td class="text-right">{{ raddoppioSicuro.gas.mensile | currency: '€ '}}</td>
                                <td class="text-right">{{ raddoppioSicuro.gas.risparmio | currency: '€ '}}</td>
                            </tr>
                            <tr>
                                <th>Spesa gas dal 4° al 6° mese</th>
                                <td class="text-right">{{ raddoppioSicuro.gas.mensile4_6_mese | currency: '€ '}}</td>
                                <td class="text-right">{{ raddoppioSicuro.gas.risparmio4_6_mese | currency: '€ '}}</td>
                            </tr>
                            <tr>
                                <th>Spesa gas dal 7° mese</th>
                                <td class="text-right">{{ raddoppioSicuro.gas.mensile7_mese | currency: '€ '}}</td>
                                <td class="text-right">{{ raddoppioSicuro.gas.risparmio7_mese | currency: '€ '}}</td>
                            </tr>
                        </table>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%">€/smc</th>
                            </tr>
                            <tr>
                                <th>Spesa Gas</th>
                                <td class="text-right">{{ raddoppioSicuro.gas.spesaGas }} €/smc</td>
                            </tr>
                        </table>

                        <h1 class="lead">Altri costi</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Commercializzazione e vendita</th>
                                <td class="text-right"
                                    width="20%">{{ raddoppioSicuro.gas.commercializzazione | currency: '€ '}}</td>
                            </tr>
                        </table>

                        <h1 class="lead">Sconti</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Sconto Domiciliazione</th>
                                <td class="text-right" width="20%">{{ raddoppioSicuro.gas.scontoDomiciliazione }} %</td>
                            </tr>
                            <tr>
                                <th class="bg-gradient-primary">Sconto Sicuro dal 4° al 6° mese</th>
                                <td class="text-right" width="20%">5 %</td>
                            </tr>
                            <tr>
                                <th class="bg-gradient-primary">Sconto Sicuro dal 7° mese</th>
                                <td class="text-right" width="20%">10 %</td>
                            </tr>
                        </table>

                        <h1 class="display-6"><i class="fas fa-list-ul"></i> Riepilogo Offerta</h1>

                        <dl>
                            <dt>Prezzo Fisso</dt>
                            <dd>Prezzo Bloccato per 24 mesi</dd>
                            <dt>Sconti</dt>
                            <dd>Sconto del 5% dal 4° al 6° mese</dd>
                            <dd>Sconto del 10% dal 7° mese</dd>
                            <dt>Polizza Assistenza</dt>
                            <dd>ZURICH: alla sottoscrizione verrà riconosciuto in omaggio l'assicurazione Polizza
                                Assistenza Business
                            </dd>
                        </dl>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="card bg-gradient-success" *ngIf="raddoppioSicuro.energia.migliorRisparmio > 0">
                            <div class="card-body">
                                <h1 class="display-6 mb-0">
                                    <i class="icon fas fa-check"></i> con Eni sulla bolletta luce risparmi fino
                                    a {{raddoppioSicuro.energia.migliorRisparmio | currency: '€ '}}
                                </h1>
                            </div>
                        </div>
                        <div class="card bg-gradient-danger" *ngIf="raddoppioSicuro.energia.migliorRisparmio < 0">
                            <div class="card-body">
                                <h1 class="display-6 mb-0">
                                    <i class="icon fas fa-check"></i> con Eni sulla bolletta luce
                                    pagheresti {{-raddoppioSicuro.energia.migliorRisparmio | currency: '€ '}} in più
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 display-6">
                        <div class="card bg-gradient-success" *ngIf="raddoppioSicuro.gas.risparmio7_mese > 0">
                            <div class="card-body">
                                <h1 class="display-6 mb-0">
                                    <i class="icon fas fa-check"></i> con Eni sulla bolletta gas risparmi fino
                                    a {{raddoppioSicuro.gas.risparmio7_mese | currency: '€ '}}
                                </h1>
                            </div>
                        </div>
                        <div class="card bg-gradient-danger" *ngIf="raddoppioSicuro.gas.risparmio7_mese < 0">
                            <div class="card-body">
                                <h1 class="display-6 mb-0">
                                    <i class="icon fas fa-check"></i> con Eni sulla bolletta gas
                                    pagheresti {{-raddoppioSicuro.gas.risparmio7_mese | currency: '€ '}} in più
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="custom-tabs-three-profile" role="tabpanel"
                 aria-labelledby="custom-tabs-three-home-tab">
                <div class="row">
                    <div class="col-6 border-right">
                        <h1 class="display-6"><i class="fas fa-lightbulb"></i> Simulazione Bolletta Energia</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%" class="text-right">€/Mese</th>
                                <th width="20%" class="text-right">Risparmio</th>
                            </tr>
                            <tr>
                                <th>Profilo Day</th>
                                <td class="text-right">{{ scontoSuMisura.energia.mensileDay | currency: '€ '}}</td>
                                <td class="text-right">{{ scontoSuMisura.energia.risparmioDay | currency: '€ '}}</td>
                            </tr>
                            <tr>
                                <th>Profilo h24</th>
                                <td class="text-right">{{ scontoSuMisura.energia.mensileH24 | currency: '€ '}}</td>
                                <td class="text-right">{{ scontoSuMisura.energia.risparmioH24 | currency: '€ '}}</td>
                            </tr>
                            <tr>
                                <th>Profilo Night</th>
                                <td class="text-right">{{ scontoSuMisura.energia.mensileNight | currency: '€ '}}</td>
                                <td class="text-right">{{ scontoSuMisura.energia.risparmioNight | currency: '€ '}}</td>
                            </tr>
                        </table>


                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%" class="text-right">F1</th>
                                <th width="20%" class="text-right">F2</th>
                                <th width="20%" class="text-right">F3</th>
                            </tr>
                            <tr>
                                <th>Profilo Day</th>
                                <td class="text-right" colspan="2">{{ scontoSuMisura.energia.spesaDayF1F2 }} €/kWh</td>
                                <td class="text-right">{{ scontoSuMisura.energia.spesaDayF3 }} €/kWh</td>
                            </tr>
                            <tr>
                                <th>Profilo h24</th>
                                <td class="text-right" colspan="3">{{ scontoSuMisura.energia.spesaH24 }} €/kWh</td>
                            </tr>
                            <tr>
                                <th>Profilo Night</th>
                                <td class="text-right" colspan="2">{{ scontoSuMisura.energia.spesaNightF1F2 }}€/kWh
                                </td>
                                <td class="text-right">{{ scontoSuMisura.energia.spesaNightF3 }} €/kWh</td>
                            </tr>
                        </table>

                        <h1 class="lead">Altri costi</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Commercializzazione e vendita</th>
                                <td class="text-right"
                                    width="20%">{{ scontoSuMisura.energia.commercializzazione | currency: '€ '}}</td>
                            </tr>
                        </table>

                        <h1 class="display-6"><i class="fas fa-list-ul"></i> Riepilogo Offerta</h1>

                        <dl>
                            <dt>Profili</dt>
                            <dd>Scegli tra profilo Day, Night o H24</dd>
                            <dt>Sconto Winter e Summer</dt>
                            <dd>Sconto del 10% sul profilo base con canone di 5.99€/mese</dd>
                            <dt>Sconti</dt>
                            <dd>Sconto consumo inclso nel prezzo</dd>
                        </dl>
                    </div>
                    <div class="col-6">
                        <h1 class="display-6"><i class="fas fa-fire"></i> Simulazione Bolletta Gas</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%" class="text-right">€/Mese</th>
                                <th width="20%" class="text-right">Risparmio</th>
                            </tr>
                            <tr>
                                <th>Spesa gas</th>
                                <td class="text-right">{{ scontoSuMisura.gas.mensile | currency: '€ '}}</td>
                                <td class="text-right">{{ scontoSuMisura.gas.risparmio | currency: '€ '}}</td>
                            </tr>
                        </table>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%">€/smc</th>
                            </tr>
                            <tr>
                                <th>Spesa Gas</th>
                                <td class="text-right">{{ scontoSuMisura.gas.spesaGas }} €/smc</td>
                            </tr>
                        </table>

                        <h1 class="lead">Altri costi</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Commercializzazione e vendita</th>
                                <td class="text-right"
                                    width="20%">{{ scontoSuMisura.gas.commercializzazione | currency: '€ '}}</td>
                            </tr>
                        </table>

                        <h1 class="lead">Sconti</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Sconto Domiciliazione</th>
                                <td class="text-right" width="20%">{{ scontoSuMisura.gas.scontoDomiciliazione }} %</td>
                            </tr>
                        </table>

                        <h1 class="display-6"><i class="fas fa-list-ul"></i> Riepilogo Offerta</h1>

                        <dl>
                            <dt>Profili</dt>
                            <dd>Scegli tra profilo Day, Night o H24</dd>
                            <dt>Sconto Winter e Summer</dt>
                            <dd>Sconto del 10% sul profilo base con canone di 5.99€/mese</dd>
                            <dt>Sconti</dt>
                            <dd>Sconto consumo inclso nel prezzo</dd>
                        </dl>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="card bg-gradient-success" *ngIf="scontoSuMisura.energia.migliorRisparmio > 0">
                            <div class="card-body">
                                <h1 class="display-6 mb-0">
                                    <i class="icon fas fa-check"></i> con Eni sulla bolletta luce risparmi fino
                                    a {{scontoSuMisura.energia.migliorRisparmio | currency: '€ '}}
                                </h1>
                            </div>
                        </div>
                        <div class="card bg-gradient-danger" *ngIf="scontoSuMisura.energia.migliorRisparmio < 0">
                            <div class="card-body">
                                <h1 class="display-6 mb-0">
                                    <i class="icon fas fa-check"></i> con Eni sulla bolletta luce
                                    pagheresti {{-scontoSuMisura.energia.migliorRisparmio | currency: '€ '}} in più
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 display-6">
                        <div class="card bg-gradient-success" *ngIf="scontoSuMisura.gas.risparmio > 0">
                            <div class="card-body">
                                <h1 class="display-6 mb-0">
                                    <i class="icon fas fa-check"></i> con Eni sulla bolletta gas risparmi fino
                                    a {{scontoSuMisura.gas.risparmio | currency: '€ '}}
                                </h1>
                            </div>
                        </div>
                        <div class="card bg-gradient-danger" *ngIf="raddoppioSicuro.gas.risparmio < 0">
                            <div class="card-body">
                                <h1 class="display-6 mb-0">
                                    <i class="icon fas fa-check"></i> con Eni sulla bolletta gas
                                    pagheresti {{-scontoSuMisura.gas.risparmio | currency: '€ '}} in più
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="custom-tabs-three-yolo" role="tabpanel"
                 aria-labelledby="custom-tabs-three-home-tab">
                <div class="row">
                    <div class="col-12">
                        <h1 class="display-6"><i class="fas fa-lightbulb"></i> Simulazione Bolletta Energia</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%" class="text-right">€/Mese</th>
                                <th width="20%" class="text-right">Risparmio</th>
                            </tr>
                            <tr>
                                <th>Profilo Day</th>
                                <td class="text-right">{{ suMisuraMyEnergy.energia.mensileDay | currency: '€ '}}</td>
                                <td class="text-right">{{ suMisuraMyEnergy.energia.risparmioDay | currency: '€ '}}</td>
                            </tr>
                            <tr>
                                <th>Profilo h24</th>
                                <td class="text-right">{{ suMisuraMyEnergy.energia.mensileH24 | currency: '€ '}}</td>
                                <td class="text-right">{{ suMisuraMyEnergy.energia.risparmioH24 | currency: '€ '}}</td>
                            </tr>
                            <tr>
                                <th>Profilo Night</th>
                                <td class="text-right">{{ suMisuraMyEnergy.energia.mensileNight | currency: '€ '}}</td>
                                <td class="text-right">{{ suMisuraMyEnergy.energia.risparmioNight | currency: '€ '}}</td>
                            </tr>
                        </table>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Profilo</th>
                                <th width="20%" class="text-right">F1</th>
                                <th width="20%" class="text-right">F2</th>
                                <th width="20%" class="text-right">F3</th>
                            </tr>
                            <tr>
                                <th>Profilo Day</th>
                                <td class="text-right" colspan="2">{{ suMisuraMyEnergy.energia.spesaDayF1F2 }}€/kWh
                                </td>
                                <td class="text-right">{{ suMisuraMyEnergy.energia.spesaDayF3 }} €/kWh</td>
                            </tr>
                            <tr>
                                <th>Profilo h24</th>
                                <td class="text-right" colspan="3">{{ suMisuraMyEnergy.energia.spesaH24 }} €/kWh</td>
                            </tr>
                            <tr>
                                <th>Profilo Night</th>
                                <td class="text-right" colspan="2">{{ suMisuraMyEnergy.energia.spesaNightF1F2 }}€/kWh
                                </td>
                                <td class="text-right">{{ suMisuraMyEnergy.energia.spesaNightF3 }} €/kWh</td>
                            </tr>
                        </table>

                        <h1 class="lead">Sconti</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Bonus Benvenuto</th>
                                <td class="text-right"
                                    width="40%">50 % sul corrispettivo luce per il 1° mese
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-gradient-primary">Opzione Summer</th>
                                <td class="text-right"
                                    width="40%">10 % sul corrispettivo in estate
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-gradient-primary">Sconto Domiciliazione</th>
                                <td class="text-right"
                                    width="40%">5 %
                                </td>
                            </tr>
                        </table>

                        <h1 class="lead">Altri costi</h1>

                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Commercializzazione e vendita</th>
                                <td class="text-right"
                                    width="20%">{{ suMisuraMyEnergy.energia.commercializzazione | currency: '€ '}}</td>
                            </tr>
                            <tr>
                                <th class="bg-gradient-primary">Smart Energy Meter (opzionale) in upfront</th>
                                <td class="text-right"
                                    width="20%">16.99 €</td>
                            </tr>
                            <tr>
                                <th class="bg-gradient-primary">Monitorazzione (opzionale)</th>
                                <td class="text-right"
                                    width="20%">4.99 €/mese per 24 mesi</td>
                            </tr>
                        </table>

                        <h1 class="display-6"><i class="fas fa-list-ul"></i> Riepilogo Offerta</h1>

                        <dl>
                            <dt>Prezzo Fisso</dt>
                            <dd>Prezzo Bloccato per 24 mesi</dd>
                            <dt>Profilo</dt>
                            <dd>Scegli tra profilo Day, Night e h24.</dd>
                            <dt>MyEnergy Meter</dt>
                            <dd>Il cliente riceverà un device per il monitoraggio dei consumi in real time.</dd>
                        </dl>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="card bg-gradient-success" *ngIf="suMisuraMyEnergy.energia.migliorRisparmio > 0">
                            <div class="card-body">
                                <h1 class="display-6 mb-0">
                                    <i class="icon fas fa-check"></i> con Eni sulla bolletta luce risparmi fino
                                    a {{suMisuraMyEnergy.energia.migliorRisparmio | currency: '€ '}}
                                </h1>
                            </div>
                        </div>
                        <div class="card bg-gradient-danger" *ngIf="suMisuraMyEnergy.energia.migliorRisparmio < 0">
                            <div class="card-body">
                                <h1 class="display-6 mb-0">
                                    <i class="icon fas fa-check"></i> con Eni sulla bolletta luce
                                    pagheresti {{-suMisuraMyEnergy.energia.migliorRisparmio | currency: '€ '}} in più
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /.card -->
</div>
