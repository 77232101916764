<div class="login-box" [ngBusy]="busy">
  <div class="card">
    <div class="card-body login-card-body  rounded elevation-1">
      <img [src]="'logo_full.png' | assets" width="100%" class="mb-5">

<!--      <p class="login-box-msg">Inserisci il tuo username</p>-->

      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="input-group">
          <input
            formControlName="email"
            type="text"
            class="form-control"
            placeholder="Username"
          />
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-user"></span>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- /.col -->
          <div class="col-12">
            <div style="color:#999;margin:1em 0">
              Se non vuoi fare il reset della password <a [routerLink]="['/login']">torna indietro</a>.
            </div>
            <button
              type="submit"
              class="btn bg-gradient-dark btn btn-block"
              [disabled]="!loginForm.valid"
            >
              Reset Password <i class="fas fa-key float-right pt-1"></i>
            </button>

            <div class="text-sm mt-2">
              Se non ricordi l'username contatta il backoffice al num. <br> 0885 896208 o invia una mail a supporto@master-dealer.it
            </div>
          </div>
          <!-- /.col -->
        </div>
      </form>
      <ngb-alert class="mt-3" *ngIf="status === 1" type="success">Recupero password effettuato con successo. Controlla la mail {{this.email}}.</ngb-alert>
      <ngb-alert class="mt-3" *ngIf="status === 2" type="success">L'username che hai inserito non esiste. Contatta il backoffice.</ngb-alert>
    </div>
    <!-- /.login-card-body -->
  </div>
</div>
