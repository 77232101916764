import { BaseModel } from "../base-model";

/* tslint:disable:variable-name */
export class Faq extends BaseModel {
  id: number;
  id_categoria: number;
  title: string;
  risposta: string;
  id_user: string;
  created: string;
  category: string;

  fromArray(result: []) {
      const array: Faq[] = [];
      for (const item of result) {
          array.push(new Faq(item));
      }
      return array;
  }
}

export class FaqCategory extends BaseModel{
  id: number;
  id_user: number;
  id_vendor: number;
  title: string;
  faqs: Faq[];
  faq_count: number;

  get childModels() {
      return {
          faqs: Faq
      }
  }

  fromArray(result: []) {
      const array: FaqCategory[] = [];
      for (const item of result) {
          array.push(new FaqCategory(item));
      }
      return array;
  }
}
