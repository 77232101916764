
export class BaseModel {

  protected _errors: Array<any>;
  protected _isEmpty: boolean = true;

  public isNew: boolean = true;

  constructor(attributes?: any) {
    this.setAttributes(this.initialValues);
    if (attributes) {
      this.setAttributes(attributes);
    }
  }

  get initialValues() {
    return {};
  }

  get childModels() {
    return {};
  }

  public setAttributes(input: any): this {
    Object.assign(this, input);
    this._isEmpty = false;

    for (let attribute of Object.keys(this.childModels)) {
      const Model = this.childModels[attribute];
      let value = input[attribute];

      if (Array.isArray(value)) {
        value = value.map(item => new Model(item));
      } else if (value !== null && typeof value === "object") {
        value = new Model(value);
      }
      this[attribute] = value;
    }
    return this;
  }

  public validate() {
    return true;
  }

  public addError(attribute: string, error: string) {
    this._errors[attribute] = error;
  }

  public getError(attribute: string) {
    return this._errors[attribute];
  }

  public getErrors() {
    return this._errors;
  }

  public getIsEmpty() {
    return this._isEmpty;
  }

  protected addToPayload(form_data: FormData, values: any, name: string) {
    if (!values && name) {
      form_data.append(name, '');
    } else {
      if (values instanceof File) {
        form_data.append(name, values, values.name);
      } else if (typeof values == 'object') {
        for (let key in values) {
          if (typeof values[key] == 'object') {
            this.addToPayload(form_data, values[key], name + '[' + key + ']');
          } else {
            form_data.append(name + '[' + key + ']', values[key]);
          }
        }
      } else {
        form_data.append(name, values);
      }
    }
    return form_data;
  }
}

