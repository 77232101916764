<div class="row" [ngBusy]="busy">
    <div class="col-12 mb-3">
        <div class="btn-group btn-group-toggle w-100" ngbRadioGroup
             name="radioBasic" [(ngModel)]="periodo">
            <label ngbButtonLabel class="btn btn-info">
                <input ngbButton type="radio" value="Today"> Oggi
            </label>
            <label ngbButtonLabel class="btn btn-info">
                <input ngbButton type="radio" value="LastSevenDays"> Questa settimana
            </label>
            <label ngbButtonLabel class="btn btn-info">
                <input ngbButton type="radio" value="LastThirtyDays"> Questo mese
            </label>
        </div>
    </div>
    <div class="col-3">
        <app-master-card cardClass="bg-tiscali-gradient">
            <div class="text-center py-2">
                <img src="assets/img/flats/star.png" height="100px" class="bg-gradient-light p-1 img-circle" />
                <p class="lead">L'operatore {{startup.operatoreTop.dicitura}} è</p>
                <h1 class="display-4">{{startup.operatoreTop.nome}}</h1>
                <p class="lead">con</p>
                <p class="lead">{{startup.operatoreTop.totali}} chiamate risposte</p>
            </div>
        </app-master-card>
    </div>
    <div class="col-3">
        <app-master-card cardClass="bg-tiscali-gradient">
            <div class="box-hover-dark text-center py-2" (click)="openAutoloadTable(autoloadTable, '-detail/centralino-risposte')">
                <img src="assets/img/flats/support.png" height="100px" class="bg-gradient-light p-1 img-circle" />
                <p class="lead">{{startup.dicituraTempo}} abbiamo risposto a:</p>
                <h1 class="display-4">{{startup.inbound.totali}} chiamate</h1>
                <p class="lead">con</p>
                <p class="lead">{{startup.inbound.tempo}} ore totali</p>
            </div>
        </app-master-card>
    </div>
    <div class="col-3">
        <app-master-card cardClass="bg-tiscali-gradient">
            <div class="box-hover-dark text-center py-2" (click)="openAutoloadTable(autoloadTable, '-detail/centralino-effettuate')">
                <img src="assets/img/flats/phone-receiver.png" height="100px" class="bg-gradient-light p-1 img-circle" />
                <p class="lead">{{startup.dicituraTempo}} abbiamo effettuato:</p>
                <h1 class="display-4">{{startup.outbound.totali}} chiamate</h1>
                <p class="lead">con</p>
                <p class="lead">{{startup.outbound.tempo}} ore totali</p>
            </div>
        </app-master-card>
    </div>
    <div class="col-3">
        <app-master-card cardClass="bg-tiscali-gradient">
            <div class="box-hover-dark text-center py-2" (click)="openAutoloadTable(autoloadTable, '-detail/centralino-perse')">
                <img src="assets/img/flats/connection.png" height="100px" class="bg-gradient-light p-1 img-circle" />
                <p class="lead">{{startup.dicituraTempo}} abbiamo perso:</p>
                <h1 class="display-4">{{startup.perse.totali}} chiamate</h1>
                <p class="lead">con</p>
                <p class="lead">{{startup.perse.negozi}} dealers diversi</p>
            </div>
        </app-master-card>
    </div>
</div>

<ng-template #autoloadTable let-modal size="xl">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Dettaglio</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <app-autoload-table [url]="autoloadTableUrl"></app-autoload-table>
    </div>
</ng-template>

<div class="row">
    <div class="col-4">
        <app-master-card
                bodyClass="p-0"
                title="Backoffice"
                image="assets/img/flats/support.png"
            >
            <app-autoload-table
                    [url]="urlBackoffice"
                    [hideFooter]="true"
            ></app-autoload-table>
        </app-master-card>
    </div>
    <div class="col-4">
        <app-master-card
                bodyClass="p-0"
                title="Negozi"
                image="assets/img/flats/online-store.png"
            >
            <app-autoload-table
                    [url]="urlNegozi"
                    [hideFooterDescription]="true"
            ></app-autoload-table>
        </app-master-card>
    </div>
    <div class="col-4">
        <app-master-card
                bodyClass="p-0"
                title="Agenti"
                image="assets/img/flats/employee.png"
            >
            <app-autoload-table
                    [url]="urlAgenti"
                    [hideFooter]="true"
            ></app-autoload-table>
        </app-master-card>
    </div>

</div>
