import { BaseModel } from "./base-model";

/* tslint:disable:variable-name */
export class Documento extends BaseModel {
    id: number;
    id_categoria: number;
    nome: string;
    descrizione: string;
    file_path: string;
    created: string;
    file: File;

    fromArray(result: []) {
        const array: Documento[] = [];
        for (const item of result) {
            array.push(new Documento(item));
        }
        return array;
    }
}

export class DocumentiCategoria extends BaseModel{
    id: number;
    id_padre: number;
    id_operatore: number;
    nome: string;
    subcategories: DocumentiCategoria[];
    documents: Documento[];
    documents_count: number;

    get childModels() {
        return {
            subcategories: DocumentiCategoria,
            documents: Documento
        }
    }

    fromArray(result: []) {
        const array: DocumentiCategoria[] = [];
        for (const item of result) {
            array.push(new DocumentiCategoria(item));
        }
        return array;
    }
}
