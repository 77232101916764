<div class="row position-relative">
    <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="detail"
            [fullScreen]="false"
    >
        <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <div class="col-12 col-xl-4">
        <table class="table table-bordered table-sm collapse-table position-relative">
            <thead>
            <tr class="bg-gradient-dark">
                <th colspan="2" class="font-weight-normal">Dati Ordine</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th scope="row">Codice RT</th>
                <td class="text-right">{{order?.detail?.operator_code?.tiscali}}</td>
            </tr>
            <tr>
                <th scope="row">Codice Kena</th>
                <td class="text-right">{{order?.detail?.operator_code?.kena}}</td>
            </tr>
            <tr>
                <th scope="row">Costo Spedizione</th>
                <td class="text-right">
                    <a href="javascript:void(0)" [disablePopover]="!order?.can_edit" [class.disabled]="!order?.can_edit"
                       (click)="openPopover(shippingPopover,order?.id,order?.detail?.shipping_total,
                            'spese_di_spedizione','order')"
                       #shippingPopover="ngbPopover" autoClose="outside" triggers="manual"
                       [ngbPopover]="popContent" popoverTitle="Change value">
                        <span *ngIf="order?.detail?.shipping_total">{{order?.detail?.shipping_total|currency:currency}}</span>
                        <span *ngIf="!order?.detail?.shipping_total">{{0|currency:currency}}</span>
                    </a>
                </td>
            </tr>
            <tr>
                <th scope="row">Operatore che ha gestito l'ordine</th>
                <td class="text-right">{{order?.detail?.operator}}</td>
            </tr>
            <tr>
                <th scope="row">Operatore che ha gestito il plafond</th>
                <td class="text-right">{{order?.detail?.operator_plafond}}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12 col-xl-4">
        <table class="table table-bordered table-sm collapse-table position-relative">
            <thead>
            <tr>
                <th colspan="2" class="font-weight-normal bg-gradient-dark">Dati Pagamento</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="order?.payment === 0">
                <th>Contabile</th>
                <td class="text-right">
                    <a [href]="order?.detail?.file_path" target="_blank"
                       *ngIf="order?.detail?.file_path">Scarica</a>
                    <span *ngIf="!order?.detail?.file_path">-</span>
                    <button class="btn btn-link mr-0 pr-0" *ngIf="order?.can_edit" (click)="showFileModal()">
                        <i class="fas fa-file-upload text-success"></i>
                    </button>
                </td>
            </tr>
            <tr>
                <th>Credito Dealer</th>
                <td class="text-right">
                    <a [href]="'/gestione-credito/'+order?.dealer?.id" target="_blank">Vai</a></td>
            </tr>
            <tr>
                <th>Codice Tracking</th>
                <td class="text-right">
                    <a href="javascript:void(0)" [disablePopover]="!order?.can_edit" [class.disabled]="!order?.can_edit"
                       (click)="openPopover(trackingPopover,order?.id,order?.detail?.tracking,
                            'tracking','order')"
                       #trackingPopover="ngbPopover" autoClose="outside" triggers="manual"
                       [ngbPopover]="popContent" popoverTitle="Change value">
                        {{order?.detail?.tracking ? order?.detail?.tracking : '-'}}
                    </a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12 col-xl-4">
        <table class="table table-bordered table-sm collapse-table position-relative">
            <thead>
            <tr>
                <th class="font-weight-normal bg-gradient-dark">
                    Note
                </th>
                <th class="font-weight-normal bg-gradient-dark" colspan="2">
                    Operator Note
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{{order?.note}}</td>
                <td>{{order?.note_op}}</td>
                <td class="text-right px-2" *ngIf="order?.can_edit">
                    <button class="btn btn-link p-0 m-0" (click)="showNoteModal(order)">
                        <i class="far fa-sticky-note text-success"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12 col-xl-6" *ngIf="order?.is_sim">
        <table class="table table-bordered table-sm collapse-table position-relative">
            <thead>
            <tr>
                <th colspan="2" class="font-weight-normal bg-gradient-dark">Dati Spedizione</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th scope="row">Referente</th>
                <td class="text-right">{{order?.detail?.address?.referente}}</td>
            </tr>
            <tr>
                <th scope="row">Via</th>
                <td class="text-right">{{order?.detail?.address?.indirizzo}}</td>
            </tr>
            <tr>
                <th scope="row">Comune</th>
                <td class="text-right">{{order?.detail?.address?.comune}}</td>
            </tr>
            <tr>
                <th scope="row">Provincia</th>
                <td class="text-right">{{order?.detail?.address?.provincia}}</td>
            </tr>
            <tr>
                <th scope="row">CAP</th>
                <td class="text-right">{{order?.detail?.address?.cap}}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12 col-xl-6" *ngIf="order?.is_sim">
        <table class="table table-bordered table-sm collapse-table position-relative">
            <thead>
            <tr>
                <th colspan="4" class="font-weight-normal bg-gradient-dark">
                    Pacchetti Assegnati
                    <button class="btn btn-success btn-sm float-right" type="button" translate (click)="replace()"
                            [disabled]="!order?.can_edit || !order?.convalid" *ngIf="order?.is_sim">
                        Riassegna Seriali
                    </button>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th scope="row">Lotto</th>
                <th scope="row">Data</th>
                <th scope="row" class="text-center">Pacchetto N°</th>
                <th scope="row" class="text-center">N° Sim</th>
            </tr>
            <tr *ngFor="let package of order?.packages">
                <td>{{package?.lotto}}</td>
                <td>{{package?.data_assegnazione|amDateFormat:localFormat}}</td>
                <td class="text-center">{{package?.numero}}</td>
                <td class="text-center">{{package?.sim}}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12">
        <table class="table table-bordered table-sm collapse-table product-table">
            <thead>
            <tr>
                <th class="font-weight-normal bg-gradient-dark" colspan="7">
                    <span>Prodotti Acquistati</span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th scope="row">Nome</th>
                <th scope="row">Descrizione</th>
                <th scope="row" class="text-center">Quantita</th>
                <th scope="row" class="text-center">Costo Unitario</th>
                <th scope="row" class="text-right">Iva</th>
                <th scope="row" class="text-right">Costo Totale</th>
                <th class="text-right" *ngIf="order?.can_edit"></th>
            </tr>
            <tr *ngFor="let item of order?.items">
                <td>{{item?.product?.name}}</td>
                <td>
                    <app-rich-text-truncate [text]="item?.product?.description"
                                            [charLimit]="10"></app-rich-text-truncate>
                </td>
                <td class="text-center quantity-col">
                    <ng-container>
                        <button class="btn btn-link m-0 p-0" (click)="decreaseProductQuantity(item)"
                                *ngIf="order?.can_edit">
                            <i class="fa fa-minus text-info"></i>
                        </button>
                        <span class="px-3">{{item?.quantity}}</span>
                        <button class="btn btn-link m-0 p-0" (click)="increaseProductQuantity(item)"
                                *ngIf="order?.can_edit">
                            <i class="fa fa-plus text-info"></i>
                        </button>
                    </ng-container>
                </td>
                <td class="text-right">
                    <a href="javascript:void(0)" [disablePopover]="!order?.can_edit" [class.disabled]="!order?.can_edit"
                       (click)="openPopover(paymentPopover,item?.id,item?.price,'prezzo','product')"
                       #paymentPopover="ngbPopover" autoClose="outside" triggers="manual"
                       [ngbPopover]="popContent" popoverTitle="Change value">
                        <span *ngIf="item?.price">{{item?.price|currency:currency}}</span>
                    </a>
                </td>
                <td class="text-right">{{item?.vat|currency:currency}}</td>
                <td class="text-right">{{item?.total_price|currency:currency}}</td>
                <td class="text-right" *ngIf="order?.can_edit">
                    <button class="btn btn-link" [swal]="deleteDocumentOptions"
                            (confirm)="deleteProduct(item.id)">
                        <i class="fa fa-trash text-danger"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
        <a href="javascript:void(0)" translate (click)="showProductModal()" *ngIf="order?.can_edit">Add Product</a>
    </div>
</div>

<ng-template #popContent>
    <app-edit-popup [config]="popupConfig" (onSave)="save($event)"></app-edit-popup>
</ng-template>
<ng-template #productFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Add product</h4>
        <button (click)="modalService.dismissAll()"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busyProduct">
        <form (ngSubmit)="addProduct()" [formGroup]="productForm">
            <div class="form-row">
                <div class="col-12 form-group">
                    <label for="product" translate>Product</label>
                    <ng-select id="product" (search)="loadProducts($event?.term)"
                               [loading]="productLoading" formControlName="product" placeholder="Search by name or id">
                        <ng-option *ngFor="let product of products" [value]="product">
                            [{{product?.id}}] {{product?.nome}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="col-12 col-xl-6 form-group">
                    <label for="variant" translate>Variant</label>
                    <ng-select id="variant" (search)="loadProducts($event?.term)"
                               [loading]="productLoading" formControlName="variant">
                        <ng-option *ngFor="let variant of variants" [value]="variant">
                            [{{variant?.id}}] {{variant?.name}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="col-12 col-xl-6 form-group">
                    <label for="quantity" translate>Quantity</label>
                    <input type="number" id="quantity" class="form-control" formControlName="quantity">
                </div>
                <div class="col-12" *ngIf="selectedProduct">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item px-0">
                            <span [innerHTML]="selectedProduct?.descrizione"></span></li>
                        <li class="list-group-item px-0">
                            <span translate>Unit price</span>
                            <span class="float-right">
                    <strong>{{unitProductPrice|currency:currency}}</strong></span>
                        </li>
                        <li class="list-group-item px-0">
                            <span translate>Total price</span>
                            <span class="float-right">
                    <strong>{{totalProductPrice|currency:currency}}</strong></span>
                        </li>
                    </ul>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="productForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #fileFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Upload file</h4>
        <button (click)="modalService.dismissAll()"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busyFile">
        <form [formGroup]="fileForm" (ngSubmit)="uploadFile()">
            <div class="form-row">
                <div class="col-12 form-group">
                    <label for="xmlFile" translate>File</label>
                    <div class="custom-file" *ngIf="!file">
                        <input id="xmlFile" type="file" class="custom-file-input"
                               (change)="handleFileInput($event?.target?.files[0])">
                        <label class="custom-file-label" for="xmlFile" translate>
                            Upload file...
                        </label>
                    </div>
                    <div *ngIf="file">
                        <span class="text-break">{{file}}</span>
                        <button class="btn btn-xs btn-warning float-right" type="button"
                                (click)="handleFileRemoveModal()">
                            <i class="fa fa-times m-0 px-1"></i>
                        </button>
                    </div>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="fileForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #noteFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Note</h4>
        <button (click)="modalService.dismissAll()"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="saveNote()" [formGroup]="noteForm">
            <div class="form-row">
                <div class="col-12 form-group">
                    <label for="operatorNote" translate>Operator Note</label>
                    <textarea class="form-control" id="operatorNote" rows="5" formControlName="note_op"></textarea>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="noteForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
