<div class="row">
    <div class="col-12 col-xl-4">
        <app-master-card [height]="null" title="Vendite" image="assets/img/flats/diagram.png"
                         bodyClass="{{this.background}} px-1">
            <app-autoload-chart [url]="urlVendite"></app-autoload-chart>
        </app-master-card>
        <app-master-card [height]="null" title="Targets" image="assets/img/flats/target.png" *show="['master', 'masteragent', 'operator']">
            <app-autoload-target [urlTarget]="urlTarget"></app-autoload-target>
        </app-master-card>
    </div>
    <div class="col-12 col-xl-4">
        <app-master-card [height]="null" title="Riepilogo" image="assets/img/flats/folder.png">
            <app-autoload-riepilogo [urlRiepilogo]="urlRiepilogo"></app-autoload-riepilogo>
        </app-master-card>

        <app-master-card [height]="null" title="Fatturato" image="assets/img/flats/profit.png" *show="['master']">
            <app-autoload-fatturato [urlFatturato]="urlFatturato"></app-autoload-fatturato>
        </app-master-card>
    </div>
    <div class="col-12 col-xl-4">
        <app-master-card [height]="null" title="Punti Vendita" image="assets/img/flats/global-network.png">
            <app-autoload-punti-vendita [urlPuntiVendita]="urlPuntiVendita"></app-autoload-punti-vendita>
        </app-master-card>

        <app-master-card [height]="null" title="Offerte" image="assets/img/flats/pie-chart-2.png">
            <app-autoload-offerte [urlOfferte]="urlOfferte"></app-autoload-offerte>
        </app-master-card>
    </div>
</div>
