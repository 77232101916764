import {Component, OnInit, ViewChild} from '@angular/core';
import {InvoiceDocumentService} from '@services/invoice-document.service';
import {Subject, SubjectPagination} from '@models/invoice/subject';
import {Document, DocumentPagination} from '@models/invoice/document';
import {MetaPagination} from '@models/meta-pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-subject-report',
    templateUrl: './subject-report.component.html',
    styleUrls: ['./subject-report.component.css']
})
export class SubjectReportComponent implements OnInit {
    subjects: Subject[] = [];
    subjectsPagination: MetaPagination;
    filter = {
        company_name: null
    };
    documents: Document[] = [];
    documentsPagination: MetaPagination;
    currency = '€';
    localFormat = 'DD/MM/YYYY';
    totalCredit;
    creditReport;
    selectedDocument: Document;
    showDocumentTable = false;
    @ViewChild('paymentDetailTemplate') private paymentDetailTemplate;
    @ViewChild('documentsTemplate') private documentsTemplate;
    paymentModal: NgbModalRef;
    report = {
        total_credit_debit: 0
    };
    selectedSubject: Subject;
    documentBusy: any;

    constructor(private invoiceDocumentService: InvoiceDocumentService,
                private spinner: NgxSpinnerService,
                private modalService: NgbModal) {
    }

    ngOnInit(): void {
        this.loadSubjects();
    }

    loadSubjects() {
        this.spinner.show('table-subjects');
        const params = {};
        if (this.subjectsPagination?.currentPage) {
            params['page'] = this.subjectsPagination?.currentPage;
        }
        if (this.filter?.company_name) {
            params['company_name'] = this.filter?.company_name;
        }
        params['expand'] = 'report';
        this.invoiceDocumentService.getSubject(params).subscribe((response: SubjectPagination) => {
                this.subjects = response.data;
                this.subjectsPagination = response._meta;
                this.spinner.hide('table-subjects');
            },
            err => {
                this.spinner.hide('table-subjects');
            });
    }

    loadDocuments() {
        this.documents = [];
        this.documentsPagination = null;
        const params = {};
        if (this.selectedSubject) {
            params['subject_id'] = this.selectedSubject?.id;
        }
        if (this.documentsPagination?.currentPage) {
            params['page'] = this.documentsPagination?.currentPage;
        }
        params['sort'] = '-due_date';
        this.documentBusy = this.invoiceDocumentService.getDocuments(params).subscribe((response: DocumentPagination) => {
            this.documents = response.data;
            this.documentsPagination = response._meta;
            // this.invoiceDocumentService.getTotalCredit(this.selectedSubject?.id ?
            //     {subject_id: this.selectedSubject?.id} : {}).subscribe((report) => {
            //     this.totalCredit = report;
            // }, err => {
            // });
        }, err => {
        });
    }

    showPaymentModal(document: Document) {
        this.selectedDocument = document;
        this.paymentModal = this.modalService.open(this.paymentDetailTemplate, {size: 'lg'});
    }

    showDocumentModal(subject: Subject) {
        this.selectedSubject = subject;
        this.documents = [];
        this.documentsPagination = null;
        this.loadDocuments();
        this.modalService.open(this.documentsTemplate, {size: 'lg'});
    }

    isCredit(amount: any) {
        return Number(amount) > 0;
    }
}
