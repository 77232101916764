<form [formGroup]="addressForm" [ngBusy]="busy">
  <div class="row">
    <div *ngIf="!small" [ngClass]="{'col-12 col-md-6 col-lg-4 col-xl-3': !modalSize, 'col-12 col-md-6 col-lg-4 col-xl-4': modalSize}">
      <div class="form-group">
        <label class="lead">Cerca indirizzo tramite Google</label>
        <app-autocomplete-address (setAddress)="setAddress($event)"></app-autocomplete-address>
      </div>
    </div>
    <div *ngIf="!small" [ngClass]="{'col-12 col-md-6 col-lg-4 col-xl-3': !modalSize, 'col-12 col-md-6 col-lg-4 col-xl-4': modalSize}">
      <div class="form-group">
        <label class="lead">Indirizzo</label>
        <input formControlName="address" type="text" class="form-control"/>
        <val-errors controlName="address">
          <ng-template valError="required">
            L'indirizzo è obbligatorio
          </ng-template>
        </val-errors>
      </div>
    </div>
    <div *ngIf="!small" [ngClass]="{'col-12 col-md-6 col-lg-4 col-xl-3': !modalSize, 'col-12 col-md-6 col-lg-4 col-xl-4': modalSize}">
      <div class="form-group">
        <label class="lead">Civico</label>
        <input formControlName="number" type="text" class="form-control"/>
        <val-errors controlName="number">
          <ng-template valError="required">
            Il civico è obbligatorio
          </ng-template>
        </val-errors>
      </div>
    </div>
    <div [ngClass]="{'col-12 col-md-6 col-lg-4 col-xl-3': !modalSize && !fullColumn, 'col-12 col-md-6 col-lg-4 col-xl-4': modalSize&& !fullColumn, 'col-12': fullColumn}">
      <div class="form-group">
        <label class="lead">Provincia</label>
        <ng-select [items]="this.province"
                   bindLabel="provincia"
                   bindValue="provincia"
                   formControlName="province">
        </ng-select>
        <val-errors controlName="province">
          <ng-template valError="required">
            La provincia è obbligatoria
          </ng-template>
        </val-errors>
      </div>
    </div>
    <div [ngClass]="{'col-12 col-md-6 col-lg-4 col-xl-3': !modalSize && !fullColumn, 'col-12 col-md-6 col-lg-4 col-xl-4': modalSize && !fullColumn, 'col-12': fullColumn}">
      <div class="form-group">
        <label class="lead">Comune</label>
        <ng-select [items]="this.comuni"
                   bindLabel="comune"
                   bindValue="comune"
                   formControlName="city"
        >
        </ng-select>
        <val-errors controlName="city">
          <ng-template valError="required">
            Il comune è obbligatoria
          </ng-template>
        </val-errors>
      </div>
    </div>
    <div  *ngIf="!small" [ngClass]="{'col-12 col-md-6 col-lg-4 col-xl-3': !modalSize, 'col-12 col-md-6 col-lg-4 col-xl-4': modalSize}">
      <div class="form-group">
        <label class="lead">CAP</label>
        <input formControlName="pcode" type="tel" minlength="5" maxlength="5"
               class="form-control"/>
        <val-errors controlName="pcode">
          <ng-template valError="required">
            Il CAP è obbligatorio
          </ng-template>
        </val-errors>
      </div>
    </div>
<!--    <div [ngClass]="{'col-12 col-md-6 col-lg-4 col-xl-3': !modalSize, 'col-12 col-md-6 col-lg-4 col-xl-4': modalSize}">-->
<!--      <div class="form-group">-->
<!--        <label class="lead">Indirizzo</label>-->
<!--        <input formControlName="address" type="text" class="form-control"/>-->
<!--        <val-errors controlName="address">-->
<!--          <ng-template valError="required">-->
<!--            L'indirizzo è obbligatorio-->
<!--          </ng-template>-->
<!--        </val-errors>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</form>
