<app-master-header [ngBusy]="busy"
                    cardClass="bg-gradient-1"
                   image="assets/img/flats/statistics.png"
                   title="Analisi Fasce"
                   [operatori]="true"
                   [periodoFrom]="true"
                   [periodoTo]="true"
                   (onChange)="this.updateUrls($event.operatore, $event.from, $event.to)"
>
</app-master-header>

<div class="row">
    <div class="col-6">
        <app-master-card
                bodyClass="p-0"
                title="Mese di confronto"
                image="assets/img/flats/settings.png"
        >
            <app-autoload-table [striped]="true" [url]="urlAnalisiFasceFrom" [hideFooter]="true"></app-autoload-table>
        </app-master-card>
    </div>
    <div class="col-6">
        <app-master-card
                bodyClass="p-0"
                title="Mese di riferimento"
                image="assets/img/flats/settings.png"
        >
            <app-autoload-table [striped]="true" [url]="urlAnalisiFasceTo" [hideFooter]="true"></app-autoload-table>
        </app-master-card>
    </div>
</div>
