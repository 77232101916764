import { Injectable } from "@angular/core";
import { RequestService } from "@services/request.service";
import { EventiPresidiati, EventsPagination } from "@app/models/eventi_presidiati/eventi_presidiati";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AlertService } from "./alert.service";

const event = "event";

@Injectable({
  providedIn: "root",
})
export class EventsService {
  constructor(public request: RequestService, private alertService: AlertService) {}

  public static eventStatuses = [
    {
      key: 0,
      name: "In Revisione",
      class: "badge-warning"
    },
    {
      key: 1,
      name: "Approvata",
      class: "badge-success"
    },
    {
      key: 2,
      name: "Rifiutato",
      class: "badge-danger"
    }
  ];

  public getEvents(params?): Observable<EventsPagination> {
    return this.request.get(event, params).pipe(
      map((response: any) => {
        return new EventsPagination(response);
      })
    );
  }

  public putStatus(id: number, body: EventiPresidiati): Observable<EventiPresidiati> {
    return this.request.put(event + "/" + id, body).pipe(
      map((response: any) => {
        return new EventiPresidiati(response);
      })
    );
  }

  //Alerts
  public successAlert(text) {
    this.alertService.show("", "Successfully " + text, { classname: "bg-success text-light", delay: 2000 });
  }
  public errorAlert(message?) {
    this.alertService.show("An error occurred!", message ? message : "Try again!", {
      classname: "bg-danger text-light",
      delay: 2000,
    });
  }
}
