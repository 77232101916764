import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataBuilderModalService {
    modal: ReplaySubject<DataBuilderModalModel>;

    constructor() {
        this.modal = new ReplaySubject<DataBuilderModalModel>();
    }

    show(url, title) {
        this.modal.next(new DataBuilderModalModel(url, title));
    }

}

export class DataBuilderModalModel {
    url: string;
    title: string;

    constructor(url, title) {
        this.url = url;
        this.title = title;
    }
}
