<div class="row" [ngBusy]="this.busy">
  <div class="col-12 col-lg-3">
    <a [routerLink]="['/presidia-evento']">
      <div class="card box bg-gradient-dark elevation-1">
        <div class="card-body">
          <i class="fas fa-arrow-left bg-icon" style="color: #FFFFFF40"></i>
          <p class="lead mb-0">Lista eventi</p>
          <h1 class="display-6 mb-0">Torna indietro</h1>
        </div>
      </div>
    </a>
  </div>
  <div class="col-12 col-lg-3">
    <div class="card box bg-tiscali-gradient elevation-1">
      <div class="card-body">
        <i class="fas fa-wallet bg-icon" style="color: #33333340"></i>
        <p class="lead mb-0">Contratti stimati</p>
        <h1 class="display-6 mb-0">{{ event?.stima_contratti | number }}</h1>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-3">
    <div class="card box bg-gradient-success elevation-1">
      <div class="card-body">
        <i class="far fa-check-circle bg-icon" style="color: #33333340"></i>
        <p class="lead mb-0">Stato evento</p>
        <h1 class="display-6 mb-0">In attesa</h1>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-3">
    <div class="card box bg-gradient-info elevation-1">
      <div class="card-body">
        <i class="fas fa-info-circle bg-icon" style="color: #33333340"></i>
        <p class="lead mb-0">Contributo</p>
        <h1 class="display-6 mb-0"></h1>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 col-lg-4">
    <div class="card card-detail bg-gradient-light elevation-1">
      <div class="card-header">
        <h1 class="display-7 d-inline-block mb-0">Dati Evento</h1>
        <div class="card-tools"><i class="fa fa-list-ul text-1-5"></i></div>
      </div>
      <div class="card-body row">
        <div class="col-12 col-lg-6 form-group">
          <p class="lead">Nome</p>
          <input type="text" class="form-control form-control-sm" [attr.disabled]="true" [value]="event?.nome"/>
        </div>
        <div class="col-12 col-lg-6 form-group">
          <p class="lead">Provincia</p>
          <input type="text" class="form-control form-control-sm" [attr.disabled]="true" [value]="event?.provincia" />
        </div>
        <div class="col-12 col-lg-6 form-group">
          <p class="lead">Città</p>
          <input type="text" class="form-control form-control-sm" [attr.disabled]="true" [value]="event?.comune" />
        </div>
        <div class="col-12 col-lg-6 form-group">
          <p class="lead">Indirizzo</p>
          <input type="text" class="form-control form-control-sm" [attr.disabled]="true" [value]="event?.indirizzo" />
        </div>
        <div class="col-12 form-group">
          <p class="lead">Descrizione</p>
          <textarea class="form-control form-control-sm" [attr.disabled]="true" rows="4">{{event?.note_allestimento}}</textarea>
        </div>
        <div class="col-12">
          <hr>
        </div>
        <div class="col-12 col-lg-6 form-group">
          <p class="lead">Costo totale</p>
          <input type="text" class="form-control form-control-sm" [attr.disabled]="true" [value]="event?.costo_totale | currency: '€ '"/>
        </div>
        <div class="col-12 col-lg-6 form-group">
          <p class="lead">Stima Contratti</p>
          <input type="text" class="form-control form-control-sm" [attr.disabled]="true" [value]="event?.stima_contratti | number" />
        </div>
        <div class="col-12">
          <hr>
        </div>
        <div class="col-12 col-lg-6 form-group">
          <p class="lead">Tipologia Evento</p>
          <input type="text" class="form-control form-control-sm" [attr.disabled]="true"  [value]="event?.evento_tipologia"/>
        </div>
        <div class="col-12 col-lg-6 form-group">
          <p class="lead">Allestimenti</p>
          <input type="text" class="form-control form-control-sm" [attr.disabled]="true"  [value]="event?.evento_allestimenti"/>
        </div>
        <div class="col-12 form-group">
          <p class="lead">Note allestimento</p>
          <textarea class="form-control form-control-sm" [attr.disabled]="true" rows="4">{{event?.note_allestimento}}</textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-4">
    <div class="card card-detail bg-gradient-light elevation-1">
      <div class="card-header">
        <h1 class="display-7 d-inline-block mb-0">Programmazione</h1>
        <div class="card-tools"><i class="fa fa-calendar-alt text-1-5"></i></div>
      </div>
      <div class="card-body row">
        <div class="col-12 col-lg-6 form-group">
          <p class="lead">Inizio Evento</p>
          <input type="text" class="form-control form-control-sm" [attr.disabled]="true" [value]="event?.from_date | date: 'dd/MM/yyyy'"/>
        </div>
        <div class="col-12 col-lg-6 form-group">
          <p class="lead">Fine Evento</p>
          <input type="text" class="form-control form-control-sm" [attr.disabled]="true"  [value]="event?.to_date | date: 'dd/MM/yyyy'"/>
        </div>
        <ng-container *ngFor="let periodo of event?.periodi">
          <div class="col-12">
            <hr>
          </div>
          <div class="col-12 col-lg-6 form-group">
            <p class="lead">Da</p>
            <input type="text" class="form-control form-control-sm" [attr.disabled]="true" [value]="periodo?.from_date | date: 'dd/MM/yyyy'"/>
          </div>
          <div class="col-12 col-lg-6 form-group">
            <p class="lead">A</p>
            <input type="text" class="form-control form-control-sm" [attr.disabled]="true" [value]="periodo?.to_date | date: 'dd/MM/yyyy'"/>
          </div>
          <div class="col-12 col-lg-6 form-group">
            <p class="lead">Mattina</p>
            <input type="text" class="form-control form-control-sm" [attr.disabled]="true" [value]="periodo?.mattina" />
          </div>
          <div class="col-12 col-lg-6 form-group">
            <p class="lead">Pomeriggio</p>
            <input type="text" class="form-control form-control-sm" [attr.disabled]="true" [value]="periodo?.pomeriggio"/>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-4">
    <div class="card card-detail bg-gradient-light elevation-1">
      <div class="card-header">
        <h1 class="display-7 d-inline-block mb-0">Documenti</h1>
        <div class="card-tools"><i class="fa fa-file-alt text-1-5"></i></div>
      </div>
      <div class="card-body p-0">
        <app-documents-modal #showModal></app-documents-modal>
        <ul class="list-group list-group-flush">
<!--          <li class="list-group-item d-flex align-items-center" *ngFor="let document of event.contratti">-->
<!--            <div class="flex-1">-->
<!--              <p><i class="fa fa-file-download mr-2"></i>{{ document.notes_title }}</p>-->
<!--              <p class="text-muted text-sm">{{ document.createdtime | date: 'dd/MM/yyyy' }}</p>-->
<!--            </div>-->
<!--            <div class="flex-1 text-right">-->
<!--              <button class="btn btn-sm btn-info" (click)="showModal.showDocument(document.id)">Mostra</button>-->
<!--            </div>-->
<!--          </li>-->
        </ul>
      </div>
      <div class="card-footer">
        <app-documents-create-modal #newDocument (done)="this.refresh()"></app-documents-create-modal>
      </div>
    </div>
  </div>
</div>
