import {Component, OnInit} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import {ShopOrderService} from '@services/shop-order.service';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {mergeMap} from 'rxjs/operators';
import {ShopOrder} from '@models/shop-order';
import {NgxSpinnerService} from 'ngx-spinner';
import {MetaPagination} from '@models/meta-pagination';

@Component({
    selector: 'app-shop-list',
    templateUrl: './shop-list.component.html',
    styleUrls: ['./shop-list.component.scss']
})
export class ShopListComponent implements OnInit {
    dateOptions = {};
    apiDate: {
        from: any,
        to: any
    };
    selectedDate: string;

    filterForm = {
        dealer: null,
    };

    shopOrdersList: ShopOrder[];
    pagination: MetaPagination = new MetaPagination();
    subscription: Subscription;

    constructor(
        private shopOrderService: ShopOrderService,
        private dateRangePickerConfig: DaterangepickerConfig,
        private spinner: NgxSpinnerService
    ) {
        this.selectedDate = moment().startOf('year').format(this.dateRangePickerConfig.settings.locale.format)
            + this.dateRangePickerConfig.settings.locale.separator + moment().format(this.dateRangePickerConfig.settings.locale.format);
        this.apiDate = {
            from: moment().startOf('year').format(this.dateRangePickerConfig.settings.locale.apiFormat),
            to: moment().format(this.dateRangePickerConfig.settings.locale.apiFormat)
        };
        this.loadShopOrdersList();
    }

    ngOnInit() {
    }

    loadNewDate(start: Moment, end: Moment) {
        if (start && end) {
            this.selectedDate = start.format(this.dateRangePickerConfig.settings.locale.format)
                + this.dateRangePickerConfig.settings.locale.separator +
                end.format(this.dateRangePickerConfig.settings.locale.format);
            this.apiDate.from = start.format(this.dateRangePickerConfig.settings.locale.apiFormat);
            this.apiDate.to = end.format(this.dateRangePickerConfig.settings.locale.apiFormat);
            this.loadShopOrdersList();
        }
    }

    loadShopOrdersList() {
        this.spinner.show('report');
        this.subscription = timer(0, 2 * 60 * 1000)
            .pipe(
                mergeMap(() => this.shopOrderService.getShopOrders(this.apiDate, this.pagination.currentPage, this.filterForm.dealer))
            )
            .subscribe((response) => {
                this.shopOrdersList = response.data;
                this.pagination = response._meta;
                this.spinner.hide('report');
            }, err => {
                this.spinner.hide('report');
            });
    }

    handleDealerSelectedModal(dealerId) {
        this.filterForm.dealer = null;
        if (dealerId) {
            this.filterForm.dealer = dealerId;
        }
        this.loadShopOrdersList();
    }

}
