<div class="row">
    <div class="col-8">
        <div class="card bg-gradient-light">
            <div class="card-body p-0">
                <div class="embed-responsive embed-responsive-1by1">
                    <iframe class="embed-responsive-item"
                            [src]="'https://api.master-attivazioni.it/shop-orders/pos/'+this.id | safe"></iframe>
                </div>
            </div>
        </div>
    </div>
    <div class="col-4">
        <div class="card bg-gradient-info">
            <div class="card-header">
                <h1 class="display-7 mb-0">Istruzioni</h1>
            </div>
            <div class="card-body">
                <ul class="timeline">
                    <li>
                        <span class="font-weight-bold">1</span>
                        <div class="timeline-container">
                            <p class="timeline-header">Seleziona il tipo di carta con cui vuoi pagare</p>
                            <p class="timeline-content">Puoi scegliere tra Visa, Master Card, Visa Pay e Maestro</p>
                        </div>
                    </li>
                    <li>
                        <span class="font-weight-bold">2</span>
                        <div class="timeline-container">
                            <p class="timeline-header">Inserisci tutti i dati richiesti dal sito Unicredit</p>
                            <p class="timeline-content">I dati richiesti sono i classici Nome e Cognome, Numero Carta, Scadenza e CVV</p>
                        </div>
                    </li>
                    <li>
                        <span class="font-weight-bold">3</span>
                        <div class="timeline-container">
                            <p class="timeline-header">Unicredit processerà il pagamento</p>
                            <p class="timeline-content">Il pagamento viene effetuato in sicurezza con protocollo HTTPS direttamente da Unicredit</p>
                        </div>
                    </li>
                </ul>
                <p>Se il pagamento è andato a buon fine, puoi uscire dalla pagina</p>
            </div>
        </div>
    </div>
</div>
