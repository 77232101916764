import { Injectable } from '@angular/core';
import {RequestService} from '@services/request.service';

@Injectable({
  providedIn: 'root'
})
export class SupportoService {
  ENDPOINT = 'supporto';

  constructor(public request: RequestService) {

  }

  analyticsHeader(periodo) {
    return this.request.get(`${this.ENDPOINT}/header`, {
      periodo
    });
  }

  analyticsClassifica(periodo) {
    return this.request.get(`${this.ENDPOINT}/classifica`, {
      periodo
    });
  }

  analyticsLista(periodo) {
    return this.request.get(`${this.ENDPOINT}/lista`, {
      periodo
    });
  }
}
