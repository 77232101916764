import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EPayService} from '@services/e-pay.service';
import {ShopOrder} from '@models/e-pay/shop-order';
import {ShopProduct} from '@models/e-pay/shop-product';

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
    @Input() authorizationFailed = false;
    @Input() order: ShopOrder;
    @Input() product: ShopProduct;
    @Input() isRechargeProduct = false;
    currency = EPayService.currency;
    busy: any;
    isReceipt = false;
    format: 'dd/MM/yyyy';
    paymentFailed = false;
    creditErrorMessage = 'Not enough credit for payment!';

    constructor(
        private modalService: NgbModal,
        private ePayService: EPayService) {
    }

    ngOnInit(): void {
    }

    dismissModal() {
        this.isReceipt = false;
        this.modalService.dismissAll();
    }
    placeOrder() {
        this.busy = this.ePayService.postOrder(this.order).subscribe((response: ShopOrder) => {
            this.order = response;
            this.ePayService.successAlert('Created Order!');
            this.isReceipt = true;
        }, (err) => {
            if (err === this.creditErrorMessage) {
                this.paymentFailed = true;
                this.isReceipt = false;
            } else {
                this.ePayService.errorAlert(err);
                this.dismissModal();
            }
        });
    }
}
