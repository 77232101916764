<div class="modal-header">
    <h4 class="modal-title clamp-title" translate>Add Operator</h4>
    <button (click)="dismissModal()"
            type="button"
            class="close"
            aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [ngBusy]="busy">
    <form [formGroup]="operatorForm" (ngSubmit)="saveOperator()">
        <div class="form-row">
            <div class="col-12 form-group">
                <label for="name" translate>Name</label>
                <input type="text" class="form-control" id="name" formControlName="name">
                <val-errors controlName="name">
                    <ng-template valError="required">
                        <span translate>Name is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 mt-2">
                <button type="submit" class="btn btn-success btn-block" [disabled]="operatorForm.invalid">
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span></button>
            </div>
        </div>
    </form>
</div>
