<app-master-header [ngBusy]="busy"
                   cardClass="bg-tiscali-gradient"
                   image="assets/img/flats/italy.png"
                   [rounded]="false"
                   [background]="false"
                   title="Punti Vendita sul territorio"
>
    <div class="row align-items-end">
        <div class="col-12 col-xl-6">
            <label>Seleziona la zona</label>
            <div class="form-group form-group-icon mb-0">
                <i class="fas fa-map-marker-alt"></i>
                <select class="form-control" [(ngModel)]="this.zona">
                    <ng-container *ngIf="this.startup">
                        <option *ngFor="let per of this.startup.zone" [value]="per.value">{{per.label}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
        <div class="col-12 col-xl-6">
            <label>Seleziona l'operatore</label>
            <div class="form-group form-group-icon mb-0">
                <i class="fas fa-search"></i>
                <select class="form-control" [(ngModel)]="this.operatore">
                    <ng-container *ngIf="this.startup">
                        <option *ngFor="let per of this.startup.operatori" [value]="per.value">{{per.label}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
    </div>
</app-master-header>

<div class="row">
    <div class="col-12 col-xl-4">
        <app-master-card
                bodyClass="p-0"
                title="Punti Vendita in Italia"
                image="assets/img/flats/destination.png"
        >
            <agm-map
                    [style.height]="600"
                    [latitude]='42.2894929'
                    [longitude]='11.7101332'
                    [zoom]="6"
                    mapTypeId='roadmap'>

                <agm-marker
                        *ngFor="let marker of markers"
                        [latitude]="marker.latitude"
                        [longitude]="marker.longitude"
                        [openInfoWindow]="true"
                >
                    <agm-info-window>
                        <strong>{{marker.title}}</strong>
                        <div [innerHTML]="marker.description">
                        </div>
                    </agm-info-window>
                </agm-marker>
            </agm-map>
        </app-master-card>
    </div>
    <div class="col-12 col-xl-8">
        <app-master-subheader *show="['master', 'masteragent']" cardClass="bg-gradient-light" [url]="urlSubheader"></app-master-subheader>

        <div class="row">
            <div class="col-12 col-xl-6">
                <app-master-card
                        headerClass="bg-gradient-success"
                        bodyClass="p-0"
                        title="I nostri brand in Italia"
                        image="assets/img/flats/map.png"
                >
                    <app-autoload-table
                            [url]="urlBrand"
                            [hideFooter]="true"
                    ></app-autoload-table>
                </app-master-card>
            </div>
            <div class="col-12 col-xl-6">

                <app-master-card
                        headerClass="bg-gradient-success"
                        bodyClass="p-0"
                        title="La nostra presenza nei comuni"
                        image="assets/img/flats/percentage.png"
                >
                    <app-autoload-table
                            [url]="urlComuni"
                            [hideFooter]="true"
                    ></app-autoload-table>
                </app-master-card>
            </div>
        </div>
    </div>
</div>
