/* tslint:disable:variable-name */
import {SubProduct} from '@models/sub-product';
import {environment} from '@environments/environment';

export class LineItem {
    comment: string;
    deleted: string;
    description: string;
    id_prodotto_padre: string;
    discount_amount: string;
    discount_percent: string;
    entity_type: string;
    id: string;
    image: string;
    incrementondel: string;
    listprice;
    margin: string;
    parent_id: string;
    product_name: string;
    productid: string;
    purchase_cost: string;
    quantity: string;
    sequence_no: string;
    tax1: string;
    tax2: string;
    tax3: string;
    tax4: string;
    template;

    get imageurl() {
        // if (this.image === '') {
        //   return '/assets/img/svgs/undraw_collecting_fjjl.svg';
        // }
        return environment + '/shop/image/' + this.productid.split('x')[1];
    }

    get vat() {
        return parseFloat(this.tax1) * this.totalprice / 100;
    }

    get totale_ritenuta() {
        return parseFloat(this.tax4) * this.totalprice / 100;
    }

    get totalprice() {
        return this.qty * this.price;
    }

    get qty() {
        return parseFloat(this.quantity);
    }

    get price() {
        return parseFloat(this.listprice);
    }

    fromArray(result: []) {
        const array: LineItem[] = [];
        for (const item of result) {
            const tempItem: LineItem = new LineItem();
            Object.assign(tempItem, item);
            array.push(tempItem);
        }
        return array;
    }
}
