import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {KpiService} from '@services/kpi.service';
import {SupportoService} from '@services/supporto.service';

@Component({
  selector: 'app-master-tickets',
  templateUrl: './master-tickets.component.html',
  styleUrls: ['./master-tickets.component.css']
})
export class MasterTicketsComponent implements OnInit {
  classifica = {
    columns: [],
    rows: [],
  };
  lista = {
    columns: [],
    rows: [],
  };
  subheader: null;
  subheaderLoading: Subscription;
  classificaLoading: Subscription;
  listaLoading: Subscription;

  constructor(private suppService: SupportoService) {
  }

  ngOnInit(): void {

  }

  refresh(periodo: any) {
    this.subheaderLoading = this.suppService.analyticsHeader(periodo)
        .subscribe((data) => {
          this.subheader = data.result;
        });

    this.classificaLoading = this.suppService.analyticsClassifica(periodo)
        .subscribe((data) => {
          this.classifica = data.result;
        });

    this.listaLoading = this.suppService.analyticsLista(periodo)
        .subscribe((data) => {
          this.lista = data.result;
        });
  }

}
