<div class="card mt-3">
    <div class="card-header">
        <h4 class="mb-0" translate>List of calls</h4>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th translate>Company name</th>
                <th translate>Company owner</th>
                <th translate>Operator</th>
                <th translate>Phone</th>
                <th translate>Datetime</th>
                <th translate>Duration</th>
                <th translate>Note</th>
                <th translate>Scheduled</th>
                <th translate>Status</th>
                <th translate>Feedback</th>
                <th translate class="text-right"></th>
            </tr>
            </thead>
            <tbody>
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="table-calls"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let call of calls">
                <td>
                    <a [routerLink]="['/dealer', call.lead.dealer_id]" target="_blank">{{
                        call.lead.company_name
                        }}</a>
                </td>
                <td>{{call.lead.company_owner}}</td>
                <td>{{call.caller_name}}</td>
                <td>{{call.destination}}</td>
                <td class = "m-w-150">{{call.start_time|amDateFormat:localFormat}}</td>
                <td>{{call.duration}}</td>
                <td class = "m-w-150">
                    <app-rich-text-truncate [charLimit]="10"
                            [text]="call?.response"></app-rich-text-truncate>
                </td>
                <td class = "m-w-150">{{call.scheduled_at|amDateFormat:localFormat}}</td>
                <td><span
                        class="text-{{callStatus(call.call_status)?.color}}"
                        translate>{{callStatus(call.call_status)?.name}}</span>
                </td>
                <td><span
                        class="text-{{feedbackStatus(call.feedback_status)?.color}}"
                        translate>{{feedbackStatus(call.feedback_status)?.name}}</span>
                </td>
                <td translate class="text-right">
                    <button class="btn btn-link text-info" (click)="showAnswersModal(call)"><i class="fas fa-eye"></i>
                    </button>
                    <button class="btn btn-link" (click)="showCallModal(call)">
                        <i class="fa fa-edit text-muted"></i>
                    </button>
                    <button class="btn btn-link" [swal]="deleteCallOptions"
                            (confirm)="deleteCall(call.id)">
                        <i class="fa fa-trash text-danger"></i>
                    </button>
                </td>
            </tr>
            <tr *ngIf="calls?.length<=0">
                <td class="text-muted" colspan="11" translate>List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="calls?.length>0">
        <span><span translate>There are</span> <b> {{ this.callsPagination.totalCount }}</b>
            {{this.callsPagination.totalCount <= 1 ? 'call' : 'calls'|translate}}.</span>
        <ngb-pagination *ngIf="callsPagination.pageCount>1"
                        size="sm"
                        [pageSize]="callsPagination.perPage"
                        [maxSize]="5"
                        [(page)]="callsPagination.currentPage"
                        [collectionSize]="callsPagination.totalCount"
                        (pageChange)="loadCalls()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>

<ng-template #answersFormTemplate let-modal>
    <app-pbx-answers-modal [call]="selectedCall" [questionAnswers]="questionAnswers"></app-pbx-answers-modal>
</ng-template>

<ng-template #callFormTemplate let-modal>
    <app-pbx-call-modal [call]="selectedEditCall" [isEdit]="true" [calls]="calls"></app-pbx-call-modal>
</ng-template>
