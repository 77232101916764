import {Component, OnInit} from '@angular/core';
import {DataService} from '@services/data.service';
import {Subscription} from 'rxjs';
import {TransactionTiscali} from '@models/transaction-tiscali';
import {AuthenticationService} from '@services/authentication.service';
import {TableColumn, TableColumnType} from '@app/pages/partials/table/table.component';

@Component({
    selector: 'app-plafond-tiscali-index',
    templateUrl: './plafond-tiscali-index.component.html',
    styleUrls: ['./plafond-tiscali-index.component.scss']
})
export class PlafondTiscaliIndexComponent implements OnInit {

    busy: Subscription;

    rows: TransactionTiscali[] = [];
    columns: TableColumn[];

    totalePlafondAcquistato: number;
    totaleRicaricheFatte: number;

    constructor(
        public dataService: DataService,
        public authenticationService: AuthenticationService
    ) {
    }

    ngOnInit() {
        this.columns = [
            {key: 'note', text: 'Descrizione'},
            {key: 'valoreAccredito', text: 'Accredito', type: TableColumnType.CURRENCY},
            {key: 'valoreAddebito', text: 'Addebito', type: TableColumnType.CURRENCY},
            {key: 'created', text: 'Data', type: TableColumnType.DATE},
        ];

        this.busy = this.dataService.get('/get-lista-transazioni-tiscali')
            .subscribe((data: any) => {
                const rows = new TransactionTiscali().fromArray(data.result);

                // Trasforma le righe in modo da essere conformi alla struttura della tabella
                this.rows = rows.map(row => {
                    if (row.isAccredito) {
                        row['valoreAccredito'] = row.valoreNumerico;
                    } else if (row.isAddebbito) {
                        row['valoreAddebito'] = row.valoreNumerico;
                    }
                    return row;
                });

                this.totalePlafondAcquistato = this.rows.filter(item => item.isAccredito).reduce((prev, curr) => {
                    return prev + curr.valoreNumerico;
                }, 0);

                this.totaleRicaricheFatte = -this.rows.filter(item => item.isAddebbito).reduce((prev, curr) => {
                    return prev + curr.valoreNumerico;
                }, 0);
            });
    }

}
