import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class EconomicsService {

  ENDPOINT = 'economics';

  constructor(public request: RequestService) {

  }

  overview(from, to, vendor) {
    return this.request.get(`${this.ENDPOINT}/overview/${from}/${to}/${vendor}`);
  }
}
