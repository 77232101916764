import {Component, OnInit, ViewChild} from '@angular/core';
import {Subject, SubjectPagination} from '@models/invoice/subject';
import {Document, DocumentPagination, DocumentPayment, DocumentPaymentPagination} from '@models/invoice/document';
import {InvoiceDocumentService} from '@services/invoice-document.service';
import * as moment from 'moment';
import {Moment} from 'moment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {MetaPagination} from '@models/meta-pagination';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TinyMCE} from '@models/tinyMCE';

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
    filter = {
        date: '',
        subject_id: '',
        document_id: '',
        due_method: '',
        welded: '',
        is_credit: ''
    };
    subjects: Subject[] = [];
    documents: Document[] = [];
    payments: DocumentPayment[] = [];
    paymentsPagination: MetaPagination;
    subjectLoading = false;
    documentLoading = false;
    currency = '€';
    localFormat = 'DD/MM/YYYY';
    selectedDateRange = {
        from: '',
        to: ''
    };
    weldedFilters = [
        {
            label: 'Yes',
            key: '1'
        },
        {
            label: 'No',
            key: '0'
        },
    ];
    creditFilters = [
        {
            label: 'Credit',
            key: '1'
        },
        {
            label: 'Debit',
            key: '0'
        },
    ];
    dueMethods = InvoiceDocumentService.dueMethods;
    totalCredit;
    selectedSubjectId;
    selectedDocumentId;
    @ViewChild('subjectDetailTemplate') private subjectDetailTemplate;
    @ViewChild('documentDetailTemplate') private documentDetailTemplate;
    @ViewChild('paymentFormTemplate') private paymentFormTemplate;
    busy: any;
    paymentForm = new FormGroup({
        note: new FormControl('')
    });
    defaultConfig = new TinyMCE();
    selectedPayment: DocumentPayment;

    constructor(private modalService: NgbModal,
                private invoiceDocumentService: InvoiceDocumentService,
                private spinner: NgxSpinnerService,
                private dateRangePickerOptions: DaterangepickerConfig) {
    }

    ngOnInit(): void {
        this.loadPayments();
        this.loadSubject();
        this.loadDocuments();
    }

    loadPayments() {
        this.totalCredit = null;
        this.spinner.show('table-payments');
        const params = {};
        if (this.paymentsPagination?.currentPage) {
            params['page'] = this.paymentsPagination?.currentPage;
        }
        if (this.filter?.date) {
            params['from'] = this.selectedDateRange.from;
            params['to'] = this.selectedDateRange.to;
        }
        if (this.filter?.subject_id) {
            params['subject_id'] = this.filter?.subject_id;
        }
        if (this.filter?.document_id) {
            params['document_id'] = this.filter?.document_id;
        }
        if (this.filter?.due_method) {
            params['due_method'] = this.filter?.due_method;
        }
        if (this.filter?.welded) {
            params['welded'] = this.filter?.welded;
        }
        if (this.filter?.is_credit) {
            params['is_credit'] = this.filter?.is_credit;
        }
        this.invoiceDocumentService.getDocumentPayments(params).subscribe((response: DocumentPaymentPagination) => {
            this.payments = response?.data;
            this.paymentsPagination = response?._meta;
            this.invoiceDocumentService.getPaymentTotalCredit(params).subscribe((creditResponse) => {
                this.totalCredit = creditResponse;
            });
            this.spinner.hide('table-payments');
        }, err => {
            this.spinner.hide('table-payments');
        });
    }

    loadSubject(term?: string) {
        const params = {};
        if (term) {
            params['company_name'] = term;
        }
        this.invoiceDocumentService.getSubject(params).subscribe((response: SubjectPagination) => {
            this.subjects = response.data;
            this.subjectLoading = false;
        }, err => {
            this.subjectLoading = false;
        });
    }

    loadDocuments(term?: string) {
        const params = {};
        if (term) {
            params['number'] = term;
        }
        this.invoiceDocumentService.getDocuments(params).subscribe((response: DocumentPagination) => {
            this.documents = response.data;
            this.documentLoading = false;
        }, err => {
            this.documentLoading = false;
        });
    }

    resetFilter() {
        this.filter = {
            date: '',
            subject_id: '',
            document_id: '',
            due_method: '',
            welded: '',
            is_credit: ''
        };
    }

    getDateRangeString(startDate: any, endDate: any) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        const start = moment(startDate);
        const end = moment(endDate);
        this.selectedDateRange.from = start.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        this.selectedDateRange.to = end.format(this.dateRangePickerOptions.settings.locale.apiFormat);
        return start.format(localDateFormat) + this.dateRangePickerOptions.settings.locale.separator + end.format(localDateFormat);
    }

    setSelectedDateRange(from: Moment, to: Moment) {
        const selectedDateRange = this.getDateRangeString(from, to);
        this.filter.date = selectedDateRange;
    }

    isDatePassed(date) {
        return date ? moment().isAfter(date, 'date') : false;
    }

    showSubjectModal(subjectId) {
        this.selectedSubjectId = subjectId;
        this.modalService.open(this.subjectDetailTemplate, {size: 'xl'});
    }

    showDocumentModal(documentId) {
        this.selectedDocumentId = documentId;
        this.modalService.open(this.documentDetailTemplate, {size: 'xl'});
    }

    showPaymentModal(payment: DocumentPayment) {
        this.selectedPayment = payment;
        this.modalService.open(this.paymentFormTemplate, {size: 'md'});
    }

    savePayment() {
        const payment = new DocumentPayment();
        payment.welded = this.selectedPayment?.welded;
        payment.due_method = this.selectedPayment?.due_method;
        payment.amount = this.selectedPayment?.amount;
        payment.document_id = this.selectedPayment?.document_id;
        payment.due_date = this.selectedPayment?.due_date;
        payment.note = this.paymentForm.value.note;
        this.busy = this.invoiceDocumentService.putPayment(this.selectedPayment?.id, payment).subscribe((response: DocumentPayment) => {
            this.invoiceDocumentService.successAlert('Added Note!');
            this.dismissModal();
            this.loadPayments();
        }, err => {
            this.invoiceDocumentService.errorAlert(err);
            this.dismissModal();
        });
    }

    dismissModal() {
        this.paymentForm.setValue({
            note: ''
        });
        this.modalService.dismissAll();
    }

    findType(type) {
        const method = this.dueMethods.find(item => item.key === type);
        return method ? method.name : type;
    }
}
