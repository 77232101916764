import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Product} from '@models/product';
import {CartSubProduct} from '@models/shop/cart-sub-product';
import {CartService} from '@services/cart.service';

@Component({
    selector: 'app-shop-sub-products-picker',
    templateUrl: './shop-sub-products-picker.component.html',
    styleUrls: ['./shop-sub-products-picker.component.scss']
})
export class ShopSubProductsPickerComponent implements OnInit {
    @ViewChild('content') private content;
    private product: Product;

    subProducts: CartSubProduct[] = [];

    constructor(
        private modalService: NgbModal,
        private cartService: CartService
    ) {
    }

    ngOnInit() {
    }

    open() {
        this.modalService.open(this.content, {});
    }

    get availableQuantity() {
        console.log(this.product.qty_per_unit_n);
        let quantity = this.product.qty_per_unit_n;

        for (const subProduct of this.subProducts) {
            quantity -= subProduct.quantity;
        }

        return quantity;

        // return this.product.qty_per_unit_n - this.subProducts.reduce((prev, next) => {
        //   return prev + next.quantity;
        // }, 0);
    }

    public setProduct(product: Product) {
        this.product = product;
        this.subProducts = [];
        for (const subProduct of this.product.sub_products) {
            const tempProduct = new CartSubProduct();
            tempProduct.product = subProduct;
            tempProduct.quantity = 0;
            tempProduct.id = subProduct.productid;
            if (product?.variant) {
                tempProduct.total_price = product?.price;
                tempProduct.product_variant_id = product?.variant?.id;
            }
            this.subProducts.push(tempProduct);
        }
    }

    productSizes(subProduct: CartSubProduct) {
        let n = this.availableQuantity / subProduct.product.qty_per_unit_n;
        n += subProduct.quantity / subProduct.product.qty_per_unit_n;
        const steps = [];
        for (let i = 1; i <= n; i++) {
            steps.push(i * subProduct.product.qty_per_unit_n);
        }
        return steps;
    }

    addToCart() {
        this.cartService.addToCart(this.product, this.subProducts);
        this.modalService.dismissAll();
    }
}
