import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {DashboardService} from "@services/dashboard.service";
import { Helpers } from '@app/helpers/helpers';

@Component({
  selector: 'app-master-economics-global',
  templateUrl: './master-economics-global.component.html',
  styleUrls: ['./master-economics-global.component.css']
})
export class MasterEconomicsGlobalComponent implements OnInit {
    data = null;
    busy: Subscription;
    
    periodi = Helpers.getPeriods();

    _periodo: string;
    urlFatturato: any;
    urlCostiDealer: any;
    urlCostiAgenti: any;
    urlUtile: any;

    set periodo(value: string) {
        this._periodo = value;
        this.updateUrls();
    }

    get periodo() {
        return this._periodo;
    }

    constructor(
        public route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private dashboardService: DashboardService
    ) {
        this.periodo = this.route.snapshot.paramMap.get('periodo');
    }

    ngOnInit() {
    }

    updateUrls() {
        this.urlFatturato = `/economics-fatturato-chart?periodo=${this.periodo}`
        this.urlCostiDealer = `/economics-dealer-chart?periodo=${this.periodo}`
        this.urlCostiAgenti = `/economics-agenti-chart?periodo=${this.periodo}`
        this.urlUtile = `/economics-utile-chart?periodo=${this.periodo}`
    }

}
