<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)"
             size="default"
             color="#fff"
             type="square-jelly-box"
             name="loader-punti-vendita"
             [fullScreen]="false">
    <p style="color: white"> Caricamento... </p>
</ngx-spinner>

<div class="row">
    <div class="col">
        <div class="row align-items-center">
            <div class="col-xl-3 text-center"><img
                    src="../../../../../assets/img/flats/tiscali/italy-1.png" height="70px"></div>
            <div class="col mr-2">
                <div class="p-2 box-hover-dark rounded"
                     (click)="dbm.show(data.urls.pdvTotali, 'Punti Vendita')">
                    <p class="lead mb-0">{{data?.pdv_totali || 0 }}
                        <span *isVendorEnabled="['Kena Mobile Stands']">venditori</span><span *isVendorDisabled="'Kena Mobile Stands'">punti vendita</span></p>
                    <p class="mb-0">in tutta Italia</p>
                    <div class="progress rounded mt-1">
                        <div class="progress-bar bg-info" aria-valuenow="50" aria-valuemin="0"
                             aria-valuemax="100" [style.width.%]="data?.pdv_totali_perc || 0">{{data?.pdv_totali_perc || '-' }}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<hr>
<div class="row">
    <div class="col">
        <div class="row align-items-center border-right">
            <div class="col mr-2">
                <div class="p-2 box-hover-dark rounded"
                     (click)="dbm.show(data.urls.pdvProduttivi, 'Punti Vendita Produttivi')">
                    <p class="lead mb-0">{{data?.produttivi || 0}} <span *isVendorEnabled="['Kena Mobile Stands']">venditori</span><span *isVendorDisabled="'Kena Mobile Stands'">punti vendita</span></p>
                    <p class="mb-0" style="font-size: 15.5px;">produttivi a {{data?.mese || ''}}</p>
                    <div class="progress rounded mt-1">
                        <div class="progress-bar bg-success" aria-valuenow="29"
                             aria-valuemin="0" aria-valuemax="100"
                             [style.width.%]="data?.produttivi_perc || 0">{{data?.produttivi_perc || '-'}}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="row align-items-center">
            <div class="col mr-2">
                <div class="p-2 box-hover-dark rounded"
                     (click)="dbm.show(data.urls.pdvImproduttivi, 'Punti Vendita Improduttivi')">
                    <p class="lead mb-0">{{data?.improduttivi || 0}} <span *isVendorEnabled="['Kena Mobile Stands']">venditori</span><span *isVendorDisabled="'Kena Mobile Stands'">punti vendita</span></p>
                    <p class="mb-0" style="font-size: 15.5px;">improduttivi a {{data?.mese || ''}}</p>
                    <div class="progress rounded mt-1">
                        <div class="progress-bar bg-danger" aria-valuenow="71" aria-valuemin="0"
                             aria-valuemax="100" [style.width.%]="data?.improduttivi_perc || 0">{{data?.improduttivi_perc || '-'}}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<hr>
<p class="lead text-center">rispetto al mese precedente hai {{data?.paragone || 0}}:</p>
<div class="row">
    <div class="col">
        <div class="row justify-content-center align-items-center border-right">
            <div class="col-xl-12 text-center mb-2"><img src="../../../../../assets/img/flats/meeting.png"
                                                         height="70px"></div>
            <div class="col text-center mr-2">
                <div class="p-2 box-hover-dark rounded"
                     (click)="dbm.show(data.urls.pdvMenoProduttiviParagone, 'Punti Vendita Meno Produttivi')">
                    <p class="mb-0">dal 1 al {{data?.giorno_paragone || 0}} {{data?.prevMonth || 0}}</p>
                    <p class="lead mb-0">{{data?.produttivi_persi_paragone || 0}} <span *isVendorEnabled="['Kena Mobile Stands']">venditori prod.</span><span *isVendorDisabled="'Kena Mobile Stands'">pdv vendita</span></p>
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="row justify-content-center align-items-center">
            <div class="col-xl-12 text-center mb-2"><img src="../../../../../assets/img/flats/graph.png"
                                                         height="70px"></div>
            <div class="col text-center mr-2">
                <div class="p-2 box-hover-dark rounded"
                     (click)="dbm.show(data.urls.pdvMenoProduttiviMese, 'Punti Vendita non più produttivi')">
                    <p class="mb-0">su tutto {{data?.prevMonth || 0}}</p>
                    <p class="lead mb-0">{{data?.produttivi_persi_prev || 0}} <span *isVendorEnabled="['Kena Mobile Stands']">venditori prod.</span><span *isVendorDisabled="'Kena Mobile Stands'">pdv produttivi</span></p>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #autoloadTable let-modal size="xl">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Dettaglio</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <app-autoload-table [url]="autoloadTableUrl"></app-autoload-table>
    </div>
</ng-template>
