<div class="card">
    <div class="card-header bg-gradient-light">
        <h1 class="display-6 mb-0 d-inline-block">
            {{this.title}}
        </h1>
        <div class="float-right" *ngIf="this.buttons.length > 0">
            <button *ngFor="let btn of this.buttons" class="btn btn-secondary btn-sm" (click)="btnAction(btn.url)">{{btn.title}}</button>
        </div>
    </div>
    <div class="card-body p-0" [style.min-height.px]="height" >
        <hot-table
                [height]="height"
                licenseKey="non-commercial-and-evaluation"
                stretchH="all"
                language="it-IT"
                [colHeaders]="true"
                [columns]="columns"
                [columnSorting]="showFilters"
                [dropdownMenu]="showFilters"
                [filters]="showFilters"
                [settings]="hotSettings"
                [rowHeaders]="true"
                [contextMenu]="['row_below', 'row_above', 'remove_row']"
                [data]="rows">
        </hot-table>
    </div>
    <div class="card-footer">
        <button class="btn btn-success btn-sm" (click)="exportTable()">Esporta</button>
    </div>
</div>

