<div class="row" [ngBusy]="busy">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12 text-center">
                        <img src="assets/img/misc/caldaia.png" class="img-fluid">
                    </div>
                    <div class="col-12 text-center mb-2">
                        <p class="text-danger">Le segnalazioni sono valide solo per queste province Latina, Frosinone, Caserta, Palermo, Milano, Varese, Bologna, Rimini</p>
                    </div>
                    <div class="col-6 border-right">
                        <div class="mt-2">
                            <h1 class="display-5 font-italic">Vuoi guadagnare con il minimo sforzo?</h1>
                            <h1 class="display-6 font-italic">Ogni tua segnalazione può valere <b>100€</b>! Scopri
                                come...</h1>
                            <p class="lead">
                                Ti basta far firmare il modulo cartaceo per il consenso ai
                                dati del cliente che verrà ricontattato per la proposta di acquisto di una caldaia.
                                Se la vendita va a buon fine, ti verrà riconosciuto un gettone di <b>100€</b>.
                            </p>
                            <p class="lead mt-2">
                                Ogni cliente che entra nel tuo negozio potrebbe essere interessato alla caldaia o al
                                condizionatore.
                                Con la compilazione di pochi dati, puoi guadagnare con la sola segnalazione.
                            </p>
                            <p class="lead mt-2">
                                <b>Procedura:</b>
                            </p>
                            <ul class="lead">
                                <li>Compila i dati del cliente a destra</li>
                                <li>Stampa il modulo precompilato e fallo firmare al cliente</li>
                                <li>Carica il modulo firmato</li>
                                <li>Il cliente verrà ricontattato senza impegno</li>
                            </ul>
                            <p>
                                <button class="btn btn-primary" (click)="isCollapsed = !isCollapsed">
                                    Scopri di più
                                </button>
                            </p>
                            <div id="collapseExample" [ngbCollapse]="isCollapsed">
                                Hai fatto manutenzione alla tua vecchia caldaia e non funziona più come dovrebbe?
                                Consumi troppo o va in blocco quando ne hai più bisogno?<br>
                                Oppure stai pensando di rinfrescare meglio la tua casa, cambiando quel vecchio
                                climatizzatore rumoroso?<br>
                                Sei nel posto giusto: puoi sostituire caldaia e climatizzatore passando a modelli più
                                efficienti nel modo più conveniente ed economico grazie ai contributi e alle
                                agevolazioni fiscali!<br>
                                Grazie al Decreto Crescita (D.L. 30/04/2019 n. 34), puoi ottenere importanti detrazioni
                                fiscali o cedere i tuoi crediti di imposta ottenendo subito uno sconto del 65% sul
                                prezzo della caldaia e del climatizzatore.<br>
                                <br>
                                Ecco come funziona:<br>
                                IL VANTAGGIO DELLE DETRAZIONI FISCALI<br>
                                Lo Stato garantisce a tutti i soggetti che hanno migliorato l’efficienza energetica
                                della propria abitazione, un’agevolazione fiscale sotto forma di Detrazione di una quota
                                fino al 65% della spesa sostenuta, nella dichiarazione dei redditi (modello Unico o
                                730).<br>
                                Sostituire la caldaia con una nuova caldaia a condensazione o un vecchio climatizzatore
                                con uno con tecnologia “inverter” e a pompa di calore ha pertanto un duplice vantaggio:
                                efficientamento energetico – con un conseguente risparmio sulle bollette – e riduzione
                                delle imposte in sede di dichiarazione dei redditi.<br>
                                I contribuenti possono usufruire di questa agevolazione in 10 rate annuali, a partire
                                dall’anno successivo a quello in cui è stato fatto l’intervento.<br>
                                La Termostore, partner di Master Group,che contatterà il tuo cliente oltre a offrire i
                                prodotti adatti alle agevolazioni, si occuperà anche della pratica di detrazione
                                fiscale: e il cliente non dovrà fare altro che godersi un clima migliore in casa
                                sua.<br>
                                <br>
                                LO SCONTO DA CESSIONE DEL CREDITO<br>
                                A partire da luglio 2019, il Decreto Crescita ha introdotto una importante novità,
                                consentendo, per caldaie e climatizzatori, di cedere a Termostore tutto il credito di
                                imposta, ottenendo uno sconto immediato.<br>
                                La Cessione del Credito, introdotta dal Decreto Crescita, consente di recuperare subito
                                tutti i benefici delle Detrazioni Fiscali a seguito dell’intervento per efficientamento
                                energetico riguardante una caldaia.<br>
                                Tutti coloro che hanno diritto ad accedere alle Detrazioni Fiscali, hanno la possibilità
                                di ottenere uno sconto immediato sul corrispettivo dovuto che verrà anticipato da
                                Termostore.<br>
                                La Cessione del Credito è operativa per tutti gli interventi che aumentano l’efficienza
                                energetica di unità immobiliari singole o condominiali e nello specifico riguarda:<br>
                                <br>
                                la sostituzione di caldaie tradizionali con caldaie a condensazione (in Classe A o A+)
                                senza l’obbligo di installazione delle valvole termostatiche<br>
                                gli interventi di installazione di nuovi climatizzatori appartenenti ad elevate classi
                                energetiche<br>
                                l’installazione di impianti a pompa di calore (in questo caso la cessione del credito
                                può essere ancora maggiore, fino a coprire la totalità dell’importo speso)<br><br>
                                AFFRETTATI: L’OFFERTA È LIMITATA<br>
                                Da Termostore tutte queste agevolazioni sono già operative e ci occuperemo noi di
                                gestire la tua pratica, senza pensieri. La Cessione del Credito è già operativa e il
                                servizio di installazione per una nuova caldaia a condensazione comprensiva del
                                termostato di ultima generazione, oggi potrebbe essere tua con il 65% di sconto. Ma
                                l’opportunità è valida fino al 31/12/2020 <br>
                                Infatti il decreto è valido solo fino a quella data (salvo che il Governo estenda i
                                benefici, inserendoli nuova Legge di Bilancio). Ma perché rischiare?<br>
                                <br>
                                Lascia i contatti del tuo cliente nel form di seguito, fai firmare il modulo privacy per
                                il ricontatto, e verrà chiamato nel giro di poche ore per valutare senza impegno la
                                soluzione più adatta!<br>
                                Se l'acquisto andrà a buon fine avrai guadagnato un gettone per la sola segnalazione!
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <form [formGroup]="form">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <h1 class="display-5">Compila il Modulo Privacy per il ricontatto del tuo
                                        cliente</h1>
                                </div>
                                <div class="form-group col-6">
                                    <p class="lead">Nome</p>
                                    <input type="text" formControlName="nome" class="form-control form-control-sm" value=""/>
                                </div>
                                <div class="form-group col-6">
                                    <p class="lead">Cognome</p>
                                    <input type="text" formControlName="cognome" class="form-control form-control-sm" value=""/>
                                </div>
                                <div class="form-group col-12">
                                    <p class="lead">Codice Fiscale</p>
                                    <input type="text" formControlName="cf" class="form-control form-control-sm" value=""/>
                                </div>
                                <div class="form-group col-6">
                                    <p class="lead">Indirizzo</p>
                                    <input type="text" formControlName="via" class="form-control form-control-sm" value=""/>
                                </div>
                                <div class="form-group col-6">
                                    <p class="lead">Civico</p>
                                    <input type="text" formControlName="n" class="form-control form-control-sm" value=""/>
                                </div>
                                <div class="form-group col-6">
                                    <p class="lead">Città</p>
                                    <input type="text" formControlName="citta" class="form-control form-control-sm" value=""/>
                                </div>
                                <div class="form-group col-6">
                                    <p class="lead">Provincia</p>
                                    <input type="text" formControlName="prov" class="form-control form-control-sm" value=""/>
                                </div>
                                <div class="form-group col-12">
                                    <p class="lead">CAP</p>
                                    <input type="text" formControlName="cap" class="form-control form-control-sm" value=""/>
                                </div>
                                <div class="form-group col-6">
                                    <p class="lead">Cellulare</p>
                                    <input type="text" formControlName="cell" class="form-control form-control-sm" value=""/>
                                </div>
                                <div class="form-group col-6">
                                    <p class="lead">E-Mail</p>
                                    <input type="text" formControlName="email" class="form-control form-control-sm" value=""/>
                                </div>
                                <div class="col-12">
                                    <button class="btn bg-gradient-success btn-block mt-1" [disabled]="form.invalid" (click)="download()">Genera
                                        modulo da scaricare
                                    </button>
                                </div>
                                <div class="col-12 mt-2" *ngIf="showUpload">
                                    <hr>
                                    <p class="lead mb-1">Carica il modulo firmato</p>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">Upload</span>
                                        </div>
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="inputGroupFile01" (change)="uploadFile($event)">
                                            <label class="custom-file-label" for="inputGroupFile01">Scegli file</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal title="Ottimo" #modal>
    <div class="text-center">
        <i class="far fa-check-circle text-success mb-2" style="font-size: 3rem;"></i>
        <h1 class="display-6">Abbiamo inoltrato correttamente la tua segnalazione</h1>
        <p class="lead">Ti invieremo una mail se la vendita andrà a buon fine.</p>
    </div>

</app-modal>
