<app-master-header
  [ngBusy]="busy"
  cardClass="bg-gradient-dark"
  image="assets/img/flats/settings.png"
  title="Gestione Costi"
  [operatori]="true"
  [periodoSingle]="true"
  (onChange)="this.refresh($event.operatore, $event.periodo)"
>
</app-master-header>

<button class="btn btn-success mb-2" (click)="save()">Salva Modifiche</button>

<ng-container *ngIf="data">
  <div *ngFor="let row of data.struttura" class="row">
    <div *ngFor="let col of row.cols" [ngClass]="col.class">
      <app-hot
        *ngFor="let card of col.rows"
        [title]="card.title"
        [image]="card.image"
        [buttons]="card.buttons"
        [rows]="getRows(card)"
        [cols]="getCols(card)"
        [@fadeInOnEnter]
        [@fadeOutOnLeave]
      >
      </app-hot>
    </div>
  </div>
</ng-container>

<app-modal [title]="modal.title" #messageModal>
  <div class="text-center">
    <i class="far mb-2" style="font-size: 3rem" [ngClass]="modal.icon"></i>
    <h1 class="display-6">{{ modal.message }}</h1>
    <p class="lead">{{ modal.description }}</p>
  </div>
</app-modal>
