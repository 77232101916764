<div class="card bg-gradient-light">
    <form (ngSubmit)="loadPayments()">
        <div class="card-header">
            <h1 class="card-title" translate>Filters</h1>
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-lg-4 col-12"><label for="date" translate>Date</label>
                    <input id="date" class="form-control form-control-sm" type="text" daterangepicker
                           [(ngModel)]="filter.date" name="date"
                           (selected)="setSelectedDateRange($event.start, $event.end)"/>
                </div>
                <div class="form-group col-12 col-lg-4">
                    <label for="subjectId" translate>Subject</label>
                    <ng-select [items]="subjects" bindLabel="company_name" id="subjectId" class="form-control-sm"
                               [loading]="subjectLoading" [(ngModel)]="filter.subject_id"
                               name="subject_id"
                               bindValue="id" [searchable]="true" (search)="loadSubject($event?.term)"></ng-select>
                </div>
                <div class="form-group col-12 col-lg-4">
                    <label for="documentId" translate>Document</label>
                    <ng-select [loading]="documentLoading" [(ngModel)]="filter.document_id" class="form-control-sm"
                               name="document_id"
                               [searchable]="true" (search)="loadDocuments($event?.term)" id="documentId">
                        <ng-option *ngFor="let document of documents" [value]="document?.id">
                            {{document?.number}} [{{document?.amount|currency:currency}}]
                        </ng-option>
                    </ng-select>
                </div>
                <div class="form-group col-12 col-lg-4">
                    <label for="type" translate>Payment type</label>
                    <ng-select [(ngModel)]="filter.due_method" id="type" class="form-control-sm" name="due_method">
                        <ng-option translate [value]="dueMethod.key"
                                   *ngFor="let dueMethod of dueMethods">{{dueMethod.name}}</ng-option>
                    </ng-select>
                </div>
                <div class="form-group col-12 col-lg-4">
                    <label for="welded" translate>Welded</label>
                    <ng-select [(ngModel)]="filter.welded" class="form-control-sm" id="welded" name="welded">
                        <ng-option translate [value]="weldedFilter.key"
                                   *ngFor="let weldedFilter of weldedFilters">{{weldedFilter.label}}</ng-option>
                    </ng-select>
                </div>
                <div class="form-group col-12 col-lg-4">
                    <label for="credit" translate>Credit/Debit</label>
                    <ng-select [(ngModel)]="filter.is_credit" class="form-control-sm" id="credit" name="is_credit">
                        <ng-option translate [value]="creditFilter.key"
                                   *ngFor="let creditFilter of creditFilters">{{creditFilter.label}}</ng-option>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button class="btn btn-dark btn-sm mr-2" type="button" (click)="resetFilter()" translate>
                Reset
            </button>
            <button
                    class="btn btn-primary btn-sm"
                    type="submit" translate
            >
                Filter
            </button>
        </div>
    </form>
</div>
<div class="card">
    <div class="card-header">
        <h4 class="mb-0" translate>Payments</h4>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th translate>Due date</th>
                <th translate>Company name</th>
                <th translate>Document number</th>
                <th translate>Payment type</th>
                <th translate>Note</th>
                <th translate class="text-right">Credit</th>
                <th translate class="text-right">Debit</th>
                <th translate class="text-center">Welded</th>
                <th translate class="text-right"></th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="table-payments"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let payment of payments">
                <td>
                    <span [ngClass]="{'text-danger':isDatePassed(payment?.due_date)}">
                        {{payment?.due_date|amDateFormat:localFormat}}
                    </span>
                </td>
                <td>
                    <a href="javascript:void(0)"
                       (click)="showSubjectModal(payment?.document?.subject_id)">{{payment?.document?.company_name}}</a>
                </td>
                <td>
                    <a href="javascript:void(0)"
                       (click)="showDocumentModal(payment?.document_id)">
                        {{payment?.document?.number}}</a></td>
                <td>{{findType(payment?.due_method)}}</td>
                <td><app-rich-text-truncate [charLimit]="50" [text]="payment.note"></app-rich-text-truncate></td>
                <td class="text-right text-success"><span
                        *ngIf="payment?.document.is_credit==1">{{payment?.amount|currency:currency}}</span></td>
                <td class="text-right text-danger"><span
                        *ngIf="payment?.document.is_credit==0">{{payment?.amount|currency:currency}}</span></td>
                <td class="text-center w-20">
                    <i class="text-success fas fa-check" *ngIf="payment?.welded==1"></i>
                    <i class="text-danger fas fa-times" *ngIf="payment?.welded==0"></i>
                </td>
                <td class="text-right">
                    <button class="btn btn-link" (click)="showPaymentModal(payment)">
                        <i class=" fa fa-edit text-muted"></i>
                    </button>
                </td>
            </tr>
            <tr *ngIf="payments?.length<=0">
                <td class="text-muted" colspan="9" translate>List is empty</td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <td colspan="5" translate class="pl-4"><strong>TOTAL</strong></td>
                <td class="text-right text-success"><strong>{{totalCredit?.credit|currency:currency}}</strong></td>
                <td class="text-right text-danger"><strong>{{totalCredit?.debit|currency:currency}}</strong></td>
                <td colspan="2" class="pr-4 text-right text-{{totalCredit?.is_credit==1?'success':'danger'}}"><span>
                    <strong>{{totalCredit?.amount|currency:currency}}</strong></span></td>
            </tr>
            </tfoot>
        </table>
    </div>
    <div class="card-footer" *ngIf="payments?.length>0">
    <span><span translate>There are</span><b> {{ this.paymentsPagination.totalCount }}</b>
        {{this.paymentsPagination.totalCount <= 1 ? 'payment' : 'payments'|translate}}.</span>
        <ngb-pagination *ngIf="paymentsPagination.pageCount>1"
                        size="sm"
                        [pageSize]="paymentsPagination.perPage"
                        [maxSize]="10"
                        [(page)]="paymentsPagination.currentPage"
                        [collectionSize]="paymentsPagination.totalCount"
                        (pageChange)="loadPayments()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>
<ng-template #subjectDetailTemplate let-modal>
    <app-subject-view [subjectId]="selectedSubjectId"></app-subject-view>
</ng-template>
<ng-template #documentDetailTemplate let-modal>
    <app-document-view [documentId]="selectedDocumentId"></app-document-view>
</ng-template>
<ng-template #paymentFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Add a note</h4>
        <button (click)="modalService.dismissAll()"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busy">
        <form [formGroup]="paymentForm" (ngSubmit)="savePayment()">
            <div class="form-row">
                <div class="col-12 form-group">
                    <label for="categoryDescription" translate>Note</label>
                    <editor apiKey="1nilu7q8vcp0ntuviwq5nhcskmj17c6awfhpbnr0hhv4smdw" id="categoryDescription" [init]="defaultConfig" formControlName="note"></editor>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="paymentForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
