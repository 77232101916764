import {Component, OnInit, ViewChild} from '@angular/core';
import {KpiService} from '@services/kpi.service';
import {Subscription} from 'rxjs';
import {ModalComponent} from '@widgets/modal/modal.component';
import {DataService} from '@services/data.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '@services/authentication.service';
import {User} from '@models/user';

@Component({
    selector: 'app-master-kpi',
    templateUrl: './master-kpi.component.html',
    styleUrls: ['./master-kpi.component.css']
})
export class MasterKpiComponent implements OnInit {
    @ViewChild('targetModal') targetModal: ModalComponent;

    table = {
        columns: [],
        rows: [],
    };
    loading: Subscription;
    target = null;
    form: FormGroup;

    operatore = null;
    agente = null;
    periodo = null;


    constructor(private kpiService: KpiService,
                public authService: AuthenticationService,
                private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            id_dealer: [null, [Validators.required]],
            vendor: [null, [Validators.required]],
            plan: [null, [Validators.required]],
            breakdown: [null, [Validators.required]],
            mese: [null, [Validators.required]],
            anno: [null, [Validators.required]],
            target: [null, [Validators.required]],
        });

    }

    refresh(operatore: any, periodo: any, agente: any) {
        this.operatore = operatore;
        this.periodo = periodo;
        this.agente = agente;
        this.loading = this.kpiService.salesAgentTable(agente, operatore, periodo)
            .subscribe((data) => {
                this.table = data.result;
            });
    }

    trigger($event: any) {
        this.form.patchValue({
            id_dealer: $event.value.id,
            vendor: $event.value.vendor,
            plan: $event.value.plan,
            breakdown: $event.value.breakdown,
            mese: $event.value.m,
            anno: $event.value.y,
        })

        this.targetModal.open();
    }

    save() {
        this.targetModal.dismiss();
        this.loading = this.kpiService.saveTarget(this.form.value)
            .subscribe((data) => {
                this.form.reset();
                this.refresh(this.operatore, this.periodo, this.agente);
            });
    }
}
