import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-shop-pos',
    templateUrl: './shop-pos.component.html',
    styleUrls: ['./shop-pos.component.css']
})
export class ShopPosComponent implements OnInit {
    id: any;

    constructor(
        public route: ActivatedRoute
    ) {
        this.id = this.route.snapshot.paramMap.get('id');
    }

    ngOnInit(): void {
    }

}
