<ng-template #content let-modal class="bg-gradient-light">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Scansione dei documenti</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
<!--    <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)"-->
<!--                   (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">-->
<!--      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">-->
<!--        <div class="text-center py-4">-->
<!--          <p>Trascina il documento qui. Provvederemo noi a compilare il tuo contratto.</p>-->
<!--          <p>o</p>-->
<!--          <button type="button" class="btn btn-primary btn-sm" (click)="openFileSelector()">Scegli il file</button>-->
<!--        </div>-->
<!--      </ng-template>-->
<!--    </ngx-file-drop>-->

    <div  class="position-relative mt-3" style="height: 100px;" *ngIf="this.items.length == 0">
      <ngx-spinner [fullScreen]="false" bdColor="rgba(51,51,51,0)" color="#333333" type="ball-clip-rotate-multiple" size="medium">
        <p class="loading">{{ ocrState }}</p>
      </ngx-spinner>
    </div>

    <div [@listAnimation]="items.length"
         class="ocr-list"
    >
      <div *ngFor="let item of items">
        <div>{{ item }}</div>
      </div>
    </div>

    <button *ngIf="this.items.length === this.ocr.length" class="btn btn-success btn-block mt-3" (click)="close()">Chiudi</button>
  </div>
</ng-template>
