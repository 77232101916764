import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';

export class MandrillMail extends BaseModel {
    id_dealer: number;
    id_agente: number;
    id_masteragente: number;
    id_mandrill: number;
    dealer: string;
    agent: string;
    masteragent: string;
    mandrill: any;
    sent: string;
}

export class MandrillMailPagination extends BaseModel {
    data: MandrillMail[];
    _meta: MetaPagination;
    _links: Array<any>;
}
