<div class="row">
  <div class="col-12 col-lg-8 offset-lg-2">
    <!-- <app-master-header
      cardClass="bg-gradient-dark"
      image="assets/img/flats/dollar.png"
      title="Bilancio Mensile"
      [operatori]="true"
      [periodoSingle]="true"
      (onChange)="this.refresh($event.periodo, $event.operatore)"
    >
    </app-master-header> -->

    <div class="row">
      <div class="col-12 col-md-6">
        <h1 class="text-italic">Situazione Economica</h1>
      </div>

      <div class="col-12 col-md-6 mb-5">
        <app-month-range-picker
          localFormat="MMMM YYYY"
          (selected)="setSelectedDateRange($event.start, $event.end)"
          [defaultMonthRange]="defaultMonthRange"
        ></app-month-range-picker>
      </div>

      <div class="col-12 col-md-4">
        <div
          class="card"
          [ngClass]="{
            'bg-danger': data && data.balance < 0,
            'bg-success': data && data.balance > 0
          }"
        >
          <div class="card-body">
            <p class="lead mb-2">
              <i
                class="fas"
                [ngClass]="{
                  'fa-sad-cry': data && data.balance < 0,
                  'fa-grin-stars': data && data.balance > 0
                }"
              ></i>
              Bilancio
            </p>

            <h1>
              <i class="fas fa-circle-notch fa-spin" *ngIf="loading"></i>

              <ng-container>
                <span>{{ data.balance | currency: "€ " }}</span>

                <div
                  class="badge badge-outline badge-pill text-lg float-right mt-2"
                >
                  <span class="mr-2">-30%</span>
                  <i class="fas fa-long-arrow-alt-down"></i>
                </div>
              </ng-container>
            </h1>
            <p>
              <small>per il mese di {{ dateRange.from | amDateFormat: "MMMM" }}</small>
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card bg-gray-dark">
          <div class="card-body">
            <p class="lead mb-2">
              <i class="fas fa-wallet"></i>
              Profitti con bonus affiliazioni
            </p>

            <h1>
              <i class="fas fa-circle-notch fa-spin" *ngIf="loading"></i>

              <ng-container>
                <span>{{ data.revenues.total | currency: "€ " }}</span>

                <div
                  class="badge badge-outline badge-pill text-lg float-right mt-2"
                >
                  <span class="mr-2">+10%</span>
                  <i class="fas fa-long-arrow-alt-up"></i>
                </div>
              </ng-container>
            </h1>
            <p>
              <small>per il mese di {{ dateRange.from | amDateFormat: "MMMM" }}</small>
            </p>
          </div>
        </div>
        <!-- <div class="card text-center">
          <div class="card-body py-4">
            <p class="lead mb-3">Il fatturato di questo mese è di</p>
            <h1 class="">
              <i class="fas fa-circle-notch fa-spin" *ngIf="loading"></i>
              <span *ngIf="!loading"
                >+ {{ data.revenues.total | currency: "€ " }}</span
              >
            </h1>
            <p class="card-text">
              <small class="text-muted">Last updated 3 mins ago</small>
            </p>
          </div>
        </div> -->
      </div>
      <div class="col-12 col-md-4">
        <div class="card bg-gray-dark">
          <div class="card-body">
            <p class="lead mb-2">
              <i class="fas fa-cash-register"></i>
              Costi
            </p>

            <h1>
              <i class="fas fa-circle-notch fa-spin" *ngIf="loading"></i>

              <ng-container>
                <span>{{ data.costs.total | currency: "€ " }}</span>

                <div
                  class="badge badge-outline badge-pill text-lg float-right mt-2"
                >
                  <span class="mr-2">+120%</span>
                  <i class="fas fa-long-arrow-alt-up"></i>
                </div>
              </ng-container>
            </h1>
            <p>
              <small>per il mese di {{ dateRange.from | amDateFormat: "MMMM" }}</small>
            </p>
          </div>
        </div>
        <!-- <div class="card text-center">
          <div class="card-body py-4">
            <p class="lead mb-3">Il fatturato di questo mese è di</p>
            <h1 class="">
              <i class="fas fa-circle-notch fa-spin" *ngIf="loading"></i>
              <span *ngIf="!loading"
                >+ {{ data.revenues.total | currency: "€ " }}</span
              >
            </h1>
            <p class="card-text">
              <small class="text-muted">Last updated 3 mins ago</small>
            </p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card bg-white">
          <div class="card-body p-0">
            <table class="table table-bordered table-sm">
              <thead>
                <th width="5%"></th>
                <th>Descrizione</th>
                <th width="5%" class="text-right">Q.tà</th>
                <th width="20%" class="text-right">Costo Unitario</th>
                <th width="20%" class="text-right">Costo Totale</th>
                <th width="10%" class="text-right">% sul totale</th>
              </thead>
              <tbody>
                <ng-container *ngFor="let group of data.costs.list">
                  <tr [ngClass]="{ 'bg-dark': group.expanded }">
                    <th>
                      <i
                        class="fas cursor-hand"
                        [ngClass]="{
                          'fa-plus-circle': !group.expanded,
                          'fa-minus-circle': group.expanded
                        }"
                        (click)="group.expanded = !group.expanded"
                      ></i>
                    </th>
                    <th colspan="3">{{ group.group }}</th>
                    <th class="text-right">
                      {{ group.total | currency: "€" }}
                    </th>
                    <th class="text-right">{{ group.percentage | percent }}</th>
                  </tr>
                  <ng-container *ngIf="group.expanded">
                    <tr *ngFor="let cost of group.list">
                      <td></td>
                      <td>{{ cost.description }}</td>
                      <td class="text-right">{{ cost.qty }}</td>
                      <td class="text-right">
                        {{ cost.unit_cost | currency: "€" }}
                      </td>
                      <td class="text-right">
                        {{ cost.total | currency: "€" }}
                      </td>
                      <td class="text-right">
                        {{ cost.percentage | percent }}
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
                <tr class="bg-danger">
                  <th></th>
                  <th colspan="3">Totale Costi</th>
                  <th class="text-right">
                    {{ data.costs.total | currency: "€" }}
                  </th>
                  <th class="text-right">{{ 1 | percent }}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="row" [ngBusy]="busy">
    <div class="col-12">
        <div class="card bg-tiscali-gradient">
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col">
                        <h1 class="display-4 mb-0">Sintesi Economica</h1>
                    </div>
                    <div class="col text-right">
                        <h1 class="d-inline-block display-6"><em>{{this.periodoLabel}}</em></h1>
                    </div>
                </div>
                <hr>
                <div class="row align-items-end">
                    <div class="col-xl-12">
                        <label>Seleziona periodo</label>
                        <div class="form-group form-group-icon mb-0">
                            <i class="far fa-calendar-alt"></i>
                            <select class="form-control" [(ngModel)]="this.periodo">
                                <option *ngFor="let per of this.periodi" [value]="per.value">{{per.label}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center mt-4">
                    <div class="col">
                        <div class="card bg-gradient-light elevation-1 zoom" [routerLink]="['/economics/global', this.periodo]">
                            <div class="card-body">
                                <h1 class="text-center display-5"><i class="fas fa-file-invoice-dollar mr-2"></i>Fatturato</h1>
                                <hr>
                                <h1 class="text-center display-5">{{this.data.header.fatturato | currency: '€ '}}</h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-1 text-center">
                        <h1 class="text-center">-</h1>
                    </div>
                    <div class="col">
                        <div class="card bg-gradient-light elevation-1 zoom" [routerLink]="['/economics/global', this.periodo]">
                            <div class="card-body">
                                <h1 class="text-center display-5"><i class="fas fa-hand-holding-usd mr-2"></i>Costi</h1>
                                <hr>
                                <h1 class="text-center display-5">{{this.data.header.costi | currency: '€ '}}</h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-1">
                        <h1 class="text-center">=</h1>
                    </div>
                    <div class="col">
                        <div class="card bg-gradient-light elevation-1 zoom" [routerLink]="['/economics/global', this.periodo]">
                            <div class="card-body">
                                <h1 class="text-center display-5"><i class="fas fa-coins mr-2"></i>Utile</h1>
                                <hr>
                                <h1 class="text-center display-5">{{this.data.header.utile | currency: '€ '}}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="card bg-gradient-success">
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col">
                        <h1 class="display-4 mb-0">Fatturato</h1>
                    </div>
                    <div class="col text-right">
                        <h1 class="d-inline-block display-6"><em>{{this.periodoLabel}}</em></h1>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-2" *ngFor="let card of this.data.fatturato">
                        <div class="card bg-gradient-light elevation-1 zoom" [routerLink]="['/economics/fatturato', card.label, this.periodo]">
                            <div class="card-body text-center">
                                <img [src]="card.image" height="40px">
                                <hr>
                                <circle-progress [radius]="40"
                                                 [space]="-10"
                                                 [toFixed]="0"
                                                 [maxPercent]="1000"
                                                 [outerStrokeGradient]="true"
                                                 [outerStrokeWidth]="10"
                                                 [innerStrokeWidth]="10"
                                                 [outerStrokeColor]="card.color"
                                                 [outerStrokeGradientStopColor]="card.color"
                                                 innerStrokeColor="#e7e8ea"
                                                 [title]="this.card.percentuale + '%'"
                                                 [showUnits]="false"
                                                 [showSubtitle]="false"
                                                 [percent]="this.card.percentuale"
                                >

                                </circle-progress>
                                <p class="lead my-2" style="height: 90px">{{card.label}} rappresenta il <strong>{{this.card.percentuale }}%</strong> del tuo fatturato</p>
                                <hr>
                                <h2 class="text-center"><strong>{{this.card.fatturato | currency: '€ '}}</strong></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="card bg-gradient-danger">
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col">
                        <h1 class="display-4 mb-0">Costi</h1>
                    </div>
                    <div class="col text-right">
                        <h1 class="d-inline-block display-6"><em>{{this.periodoLabel}}</em></h1>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-4">
                        <div class="card bg-gradient-light elevation-1 zoom" [routerLink]="['/economics/agenti', this.periodo]">
                            <div class="card-body text-center">
                                <h1 class="text-center display-5"><i class="fas fa-user-tie mr-2"></i>Agenti</h1>
                                <hr>
                                <circle-progress [radius]="40"
                                                 [space]="-10"
                                                 [toFixed]="0"
                                                 [maxPercent]="1000"
                                                 [outerStrokeGradient]="true"
                                                 [outerStrokeWidth]="10"
                                                 [innerStrokeWidth]="10"
                                                 outerStrokeColor="#dc3545"
                                                 outerStrokeGradientStopColor="#dc3545"
                                                 innerStrokeColor="#e7e8ea"
                                                 [title]="this.data.costi.agenti.percentuale + '%'"
                                                 [showUnits]="false"
                                                 [showSubtitle]="false"
                                                 [percent]="this.data.costi.agenti.percentuale"
                                >

                                </circle-progress>
                                <p class="lead my-2">Gli agenti rappresentano il <strong>{{this.data.costi.agenti.percentuale }}%</strong> dei costi.</p>
                                <hr>
                                <h2 class="text-center"><strong>{{this.data.costi.agenti.fatturato | currency: '€ '}}</strong></h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="card bg-gradient-light elevation-1 zoom" [routerLink]="['/economics/negozi', this.periodo]">
                            <div class="card-body text-center">
                                <h1 class="text-center display-5"><i class="fas fa-users mr-2"></i>Negozi</h1>
                                <hr>
                                <circle-progress [radius]="40"
                                                 [space]="-10"
                                                 [toFixed]="0"
                                                 [maxPercent]="1000"
                                                 [outerStrokeGradient]="true"
                                                 [outerStrokeWidth]="10"
                                                 [innerStrokeWidth]="10"
                                                 outerStrokeColor="#dc3545"
                                                 outerStrokeGradientStopColor="#dc3545"
                                                 innerStrokeColor="#e7e8ea"
                                                 [title]="this.data.costi.negozi.percentuale + '%'"
                                                 [showUnits]="false"
                                                 [showSubtitle]="false"
                                                 [percent]="this.data.costi.negozi.percentuale"
                                >

                                </circle-progress>
                                <p class="lead my-2">I negozi rappresentano il <strong>{{this.data.costi.negozi.percentuale }}%</strong> dei costi.</p>
                                <hr>
                                <h2 class="text-center"><strong>{{this.data.costi.negozi.fatturato | currency: '€ '}}</strong></h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="card bg-gradient-light elevation-1 zoom">
                            <div class="card-body text-center">
                                <h1 class="text-center display-5"><i class="fas fa-building mr-2"></i>Ufficio</h1>
                                <hr>
                                <circle-progress [radius]="40"
                                                 [space]="-10"
                                                 [toFixed]="0"
                                                 [maxPercent]="1000"
                                                 [outerStrokeGradient]="true"
                                                 [outerStrokeWidth]="10"
                                                 [innerStrokeWidth]="10"
                                                 outerStrokeColor="#dc3545"
                                                 outerStrokeGradientStopColor="#dc3545"
                                                 innerStrokeColor="#e7e8ea"
                                                 [title]="this.data.costi.ufficio.percentuale + '%'"
                                                 [showUnits]="false"
                                                 [showSubtitle]="false"
                                                 [percent]="this.data.costi.ufficio.percentuale"
                                >

                                </circle-progress>
                                <p class="lead my-2">L'ufficio rappresenta il <strong>{{this.data.costi.ufficio.percentuale }}%</strong> dei costi.</p>
                                <hr>
                                <h2 class="text-center"><strong>{{this.data.costi.ufficio.fatturato | currency: '€ '}}</strong></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
