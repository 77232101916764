import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DataService} from "@services/data.service";
import {Subscription} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ModalComponent} from "@widgets/modal/modal.component";

@Component({
    selector: 'app-segnalazione-caldaia',
    templateUrl: './segnalazione-caldaia.component.html',
    styleUrls: ['./segnalazione-caldaia.component.css']
})
export class SegnalazioneCaldaiaComponent implements OnInit {
    isCollapsed = true;
    form: FormGroup;
    showUpload = false;
    busy: Subscription;

    @ViewChild('modal') modal: ModalComponent;

    constructor(private formBuilder: FormBuilder,
                private dataService: DataService) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            nome: [null, [Validators.required]],
            cognome: [null, [Validators.required]],
            cf: [null, [Validators.required]],
            via: [null, [Validators.required]],
            n: [null, [Validators.required]],
            cap: [null, [Validators.required]],
            citta: [null, [Validators.required]],
            prov: [null, [Validators.required]],
            cell: [null, [Validators.required]],
            email: [null, [Validators.required]],
        });
    }

    download() {
        let params: any = Object.entries(this.form.value);
        params = params.map(([k,v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
        params = params.join('&');

        var downloadURL = 'https://api.master-attivazioni.it/v1/io-segnalo-pdf?' + params;
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "modulo.pdf";
        link.click();

        this.showUpload = true;
    }

    uploadFile($event: any) {
        const file = $event.target.files.item(0);
        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append('file', file, file.name);

        Object.keys(this.form.value).forEach(k => formData.append(k, this.form.value[k]));

        this.busy = this.dataService.post(`/inserisci-segnalo`,
            formData
        ).subscribe((data: any) => {
            this.form.reset();
            this.modal.open();
        });
    }
}
