<div class="modal-body" [ngBusy]="busy">
    <form [formGroup]="campaignForm" (ngSubmit)="createCampaign()">
        <div class="form-row">
            <div class="col-12 col-lg-9 form-group">
                <label for="name" translate>Name</label>
                <input class="form-control" id="name" formControlName="name">
                <val-errors controlName="name">
                    <ng-template valError="required">
                        <span translate>Campaign name is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-3 form-group">
                <label for="validityPeriod" translate>Campaign period</label>
                <input id="validityPeriod" class="form-control" type="text" daterangepicker
                       formControlName="date_range" (selected)="setDateRange($event.start,$event.end)"/>
                <val-errors controlName="date_range">
                    <ng-template valError="required">
                        <span translate>Validity period is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 form-group">
                <label for="description" translate>Description</label>
                <editor apiKey="1nilu7q8vcp0ntuviwq5nhcskmj17c6awfhpbnr0hhv4smdw" formControlName="description" id="description" [init]="defaultConfig"></editor>
            </div>
        </div>
        <div class="form-row">
            <div class="col-12">
                <h5 translate>Questions</h5>
                <label translate>Choose from existing questions</label>
            </div>
            <div class="col-12 col-lg-9 form-group">
                <ng-select multiple="true" bindLabel="title"
                           [searchable]="true" (search)="loadQuestions($event?.term)" formControlName="questions"
                           [hideSelected]="true"
                           [loading]="questionsLoading"
                           (remove)="removeSelectedQuestion($event)"
                           [items]="questions"
                           [clearable]="false"
                           notFoundText="{{'No Items Found' | translate}}"
                ></ng-select>
                <val-errors controlName="questions">
                    <ng-template valError="required">
                        <span translate>Question is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-3 form-group">
                <button type="button" class="btn btn-secondary btn-block" translate (click)="showCreateQuestionModal()">
                    Add New Question
                </button>
            </div>
            <div class="col-12">
                <h5 translate>Chosen questions:</h5>
                <div class="question-container">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item px-0" *ngFor="let selectedQuestion of selectedQuestions;index as i">
                            {{selectedQuestion.title}}
                            <button type="button" class="float-right btn btn-link text-danger m-0 p-0"
                                    (click)="removeSelectedQuestion(selectedQuestion.id)"><i
                                    class="fas fa-times-circle"></i></button>
                            <p class="text-muted" [innerHTML]="selectedQuestion.text"></p></li>
                        <li class="list-group-item px-0 text-muted" *ngIf="selectedQuestions?.length<=0" translate>List
                            is empty
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 form-group mt-3">
                <h5><span translate>Selected</span>
                    <b> {{dealersCount}}</b> {{dealersCount <= 1 ? 'dealer' : 'dealers'}}
                    .</h5>
            </div>
            <div class="col-12 mt-2">
                <button type="submit" class="btn btn-success btn-block" [disabled]="campaignForm.invalid">
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                </button>
            </div>
        </div>
    </form>
</div>

<ng-template #questionFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Create Question</h4>
        <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="dismissQuestionModal()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="questionBusy">
        <form [formGroup]="questionForm" (ngSubmit)="createQuestion()">
            <div class="form-row">
                <div class="col-12 form-group">
                    <label for="title" translate>Title</label>
                    <input class="form-control" id="title" formControlName="title"/>
                    <val-errors controlName="title">
                        <ng-template valError="required">
                            <span translate>Title is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-lg-6 form-group">
                    <label for="category" translate>Category</label>
                    <ng-select formControlName="category" id="category">
                        <ng-option [value]="questionCategory.key"
                                   *ngFor="let questionCategory of questionCategories"
                                   translate>{{questionCategory.name}}</ng-option>
                    </ng-select>
                    <val-errors controlName="category">
                        <ng-template valError="required">
                            <span translate>Category is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 col-lg-6 form-group">
                    <label for="questionType" translate>Question Type</label>
                    <ng-select id="questionType" formControlName="question_type">
                        <ng-option [value]="questionType.key"
                                   *ngFor="let questionType of questionTypes"
                                   translate>{{questionType.name}}</ng-option>
                    </ng-select>
                    <val-errors controlName="question_type">
                        <ng-template valError="required">
                            <span translate>Question Type is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 form-group">
                    <label for="text" translate>Text</label>
                    <editor apiKey="1nilu7q8vcp0ntuviwq5nhcskmj17c6awfhpbnr0hhv4smdw" formControlName="text" id="text" [init]="defaultConfig"></editor>
                    <val-errors controlName="text">
                        <ng-template valError="required">
                            <span translate>Text is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="questionForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
