import {BaseModel} from './base-model';

export const DBSortDesc = 3;
export const DBSortAsc = 4;

export class DBTable extends BaseModel {
    columnSet: DBColumnSet[];
    columns: DBColumn[] = [];
    rows: DBRow[];
    originalRows: DBRow[];
    totalRow: DBRow[];
    classes: [];
    dataProvider: [{ icon: string, label: string, key: string }];
    export: boolean;
    pageSize: number;
    sort: {};
    filters: {};
    filterValues: {};
    showFilters;
    dateRanges: {};
    pbxCampaignAvailable = false;

    constructor(attributes) {
        super(attributes);

        for (let i = 0; i < this.columnSet.length; i++) {
            let set = this.columnSet[i];
            this.columns = this.columns.concat(set.columns);
        }

        if (this.rows && this.rows.length > 0 && this.rows[0].hasOwnProperty('default_id')) {
            this.pbxCampaignAvailable = true;
        }
    }

    get ids() {
        return this.rows.map(row => row['default_id']);
    }

    get childModels() {
        return {
            columnSet: DBColumnSet,
            rows: DBRow,
        };
    }

    public init() {
        this.originalRows = this.rows;
    }
}

export class DBRow extends BaseModel {
    fromArray(result: []) {
        const array: DBRow[] = [];
        for (const item of result) {
            array.push(new DBRow(item));
        }
        return array;
    }
}

export const DBColumnStandard = 0;
export const DBColumnDateRange = 1;

export const DBStandard = 0;
export const DBPercentage = 1;
export const DBBoolean = 2;
export const DBCurrency = 3;

export const DBFilterSelect = 2;

export class DBColumnSet extends BaseModel {
    key: string;
    label: string;
    span: number;
    columns: DBColumn[];
    columnType: number;
    type: number;
    filter: number;
    classes: [];
    subset: [{ checked: boolean, label: string, key: string }];
    percentageSubset: [{ checked: boolean, label: string, key: string }];

    constructor(attributes) {
        super(attributes);

        this.span = this.columns.length;
    }

    get childModels() {
        return {
            columns: DBColumn,
        };
    }

    fromArray(result: []) {
        const array: DBColumnSet[] = [];
        for (const item of result) {
            array.push(new DBColumnSet(item));
        }
        return array;
    }
}

export class DBColumn extends BaseModel {
    key: string;
    label: string;
    columnType: number;
    type: number;
    filter: number;
    classes: [];

    fromArray(result: []) {
        const array: DBColumn[] = [];
        for (const item of result) {
            array.push(new DBColumn(item));
        }
        return array;
    }
}
