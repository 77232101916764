export class EMailCampaignPagination {
    data: EMailCampaign[];
    current_page: any;
    total_items: any;
}

export class EMailCampaign {
    id: string;
    title: string;
    subject_line: string;
    preview_text: string;
    send_time: string;
    type: string;
    list_id: string;
    list_name: string;
    emails_sent: number;
    clicks_total: number;
    click_rate: number;
    opens_total: number;
    open_rate: number;
    unsubscribed: number;
}

export class EMailRecipientPagination {
    data: EMailRecipient[];
    current_page: any;
    total_items: any;
}

export class EMailRecipient {
    campaign_title: string;
    list_id: string;
    email: string;
    company_name: string;
    first_name: string;
    last_name: string;
    opens_count: string;
    last_open: number;
}

export class EMailOpenMemberPagination {
    data: EMailOpenMember[];
    current_page: any;
    total_items: any;
}

export class EMailOpenMember {
    email: string;
    company_name: string;
    first_name: string;
    last_name: string;
    contact_status: string;
    opens_count: number;
    opens: Array<any>;
}

export class EMailClickMemberPagination {
    data: EMailClickMember[];
    current_page: any;
    total_items: any;
}

export class EMailClickMember {
    email: string;
    company_name: string;
    first_name: string;
    last_name: string;
    clicks_count: number;
    contact_status: string;
}

export class EMailClickDetailPagination {
    data: EMailClickDetail[];
    current_page: any;
    total_items: any;
}

export class EMailClickDetail {
    id: string;
    url: string;
    total_clicks: number;
    click_percentage: number;
    last_click: number;
    contact_status: string;
    unique_clicks: number;
    unique_click_percentage_: number;
}
