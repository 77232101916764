<div class="row">
    <div class="col-12 col-lg-6 offset-xl-3">
        <app-master-header
                cardClass="bg-gradient-dark"
                image="assets/img/flats/target.png"
                title="KPI & Targets"
                [agenti]="this.authService.currentUserValue.profile.role === 'master' || this.authService.currentUserValue.profile.role === 'masteragent'"
                [master]="true"
                [operatori]="true"
                [periodoSingle]="true"
                (onChange)="refresh($event.operatore, $event.periodo, $event.agente)"
        >
        </app-master-header>

        <div class="card bg-gradient-light">
            <div class="card-body p-0">
                <app-table *spinner="loading && !loading.closed" [rows]="table?.rows" [columns]="table?.columns"
                           [pageSize]="100" [hideHeader]="true" [hideFooter]="true"
                           (onTrigger)="trigger($event)"></app-table>
            </div>
        </div>
    </div>
</div>

<app-modal title="Modifica Target" #targetModal>
    <form [formGroup]="form">
        <label>Imposta il target</label>
        <input type="text" class="form-control" formControlName="target">
        <button class="btn btn-success btn-block mt-2" (click)="save()">Salva</button>
    </form>
</app-modal>
