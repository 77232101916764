import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { EventiPresidiati } from "@app/models/eventi_presidiati/eventi_presidiati";
import { EventsService } from "@app/services/events.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-event-edit-form-template",
  templateUrl: "./event-edit-form-template.component.html",
  styleUrls: ["./event-edit-form-template.component.css"],
})
export class EventEditFormTemplateComponent implements OnInit {
  @Input() event: EventiPresidiati;
  @Input() events: EventiPresidiati[];
  busy: any;
  eventForm: FormGroup;
  eventStatuses = EventsService.eventStatuses;

  constructor(private modalService: NgbModal, private eventService: EventsService) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.eventForm = new FormGroup({ status: new FormControl(this.event.stato, Validators.required) });
  }

  saveChanges() {
    this.event.stato = this.eventForm.value.status;
    this.changeStatus(this.event);
  }

  changeStatus(event: EventiPresidiati) {
    this.busy = this.eventService.putStatus(event.id,event).subscribe(
      (response: EventiPresidiati) => {
        const index = this.events.findIndex((data) => data.id === response.id);
        if (index >= 0) {
          this.events[index] = response;
        }
        this.eventService.successAlert("Changed Status!");
        this.dismissModal();
      },
      (err) => {
        this.eventService.errorAlert(err.message);
        this.dismissModal();
      }
    );
  }

  dismissModal() {
    this.modalService.dismissAll();
  }
}
