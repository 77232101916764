import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-master-card',
    templateUrl: './master-card.component.html',
    styleUrls: ['./master-card.component.css']
})
export class MasterCardComponent implements OnInit {
    @Input() title: string;
    @Input() image: string;
    @Input() cardClass: string;
    @Input() bodyClass: string;
    @Input() headerClass = 'bg-gradient-light';
    @Input() height: number = 250;

    constructor() {
    }

    ngOnInit(): void {
    }

}
