<div class="card bg-gradient-1">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                 width="160px"
                 src="assets/img/flats/help.png">
            <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>Questions</h1>
                <hr>
                <div class="row align-items-end justify-content-between">
                    <div class="col-lg-5 col-12"><label translate for="category">Category</label>
                        <ng-select id="category" [(ngModel)]="filter.category">
                            <ng-option [value]="questionCategory.key"
                                       *ngFor="let questionCategory of questionCategories"
                                       translate>{{questionCategory.name}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="col-lg-5 col-12"><label translate for="category">Question Type</label>
                        <ng-select id="type" [(ngModel)]="filter.type">
                            <ng-option [value]="questionType.key"
                                       *ngFor="let questionType of questionTypes"
                                       translate>{{questionType.name}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="col-lg-2 col-12">
                        <button class="btn btn-info btn-block" (click)="loadQuestions()" translate>Filter
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="card mt-3">
    <div class="card-header">
        <h4 class="mb-0" translate>Questions</h4>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th translate>Title</th>
                <th translate>Text</th>
                <th translate>Category</th>
                <th translate>Question Type</th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
                    bdColor="rgba(0, 0, 0, 0.8)"
                    size="default"
                    color="#fff"
                    type="square-jelly-box"
                    name="table-questions"
                    [fullScreen]="false"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <tr *ngFor="let question of questions">
                <td>{{question.title}}</td>
                <td>
                    <app-rich-text-truncate [text]="question?.text"></app-rich-text-truncate>
                </td>
                <td translate>{{questionCategory(question.category)}}</td>
                <td translate>{{questionType(question.question_type)}}</td>
            </tr>
            <tr *ngIf="questions?.length<=0">
                <td class="text-muted" colspan="14" translate>List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer" *ngIf="questions?.length>0">
        <span><span translate>There are</span> <b> {{ this.questionsPagination.totalCount }} </b>
            <span translate>{{this.questionsPagination.totalCount <= 1 ? 'question' : 'questions'}}</span>.</span>
        <ngb-pagination *ngIf="questionsPagination.pageCount>1"
                        size="sm"
                        [pageSize]="questionsPagination.perPage"
                        [maxSize]="10"
                        [(page)]="questionsPagination.currentPage"
                        [collectionSize]="questionsPagination.totalCount"
                        (pageChange)="loadQuestions()"
                        [boundaryLinks]="true"
        ></ngb-pagination>
    </div>
</div>
