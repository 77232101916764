<div [style.min-height]="height">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)"
                 size="default"
                 color="#fff"
                 type="square-jelly-box"
                 name="loader-horizontal-bar-chart-{{this.uniqueID}}"
                 [fullScreen]="false">
        <p style="color: white"> Caricamento... </p>
    </ngx-spinner>

    <ngx-charts-bar-horizontal
            *ngIf="!error && chartData.length > 0 && !loading"
            [scheme]="{
                domain: colors
            }"
            [results]="chartData"
            [gradient]="gradient"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="legend"
            legendPosition="below"
            [showXAxisLabel]="false"
            [showYAxisLabel]="false">
    </ngx-charts-bar-horizontal>

    <div class="d-flex align-items-center justify-content-center flex-wrap"
         style="height: 250px; background-color: #000000d1;" *ngIf="error">
        <div class="text-center">
            <img src="/assets/img/flats/close.png" height="64px"/>
            <p class="lead text-white mt-2">C'è stato un errore nel caricamento...</p>
            <a (click)="load()" class="text-white mt-2 link"><i class="fas fa-redo-alt"></i></a>
        </div>
    </div>

    <div class="d-flex align-items-center justify-content-center flex-wrap"
         style="height: 250px;" *ngIf="chartData.length === 0 && !error && !loading">
        <div class="text-center">
            <img src="/assets/img/flats/settings_2.png" height="64px"/>
            <p class="lead mt-2">Non ci sono dati con questi filtri. Prova a cambiarli.</p>
        </div>
    </div>
</div>
