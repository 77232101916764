/* tslint:disable:variable-name */

import {BaseModel} from './base-model';
import {Dealer} from './dealer';

export const INVOICE_TO_APPROVE = 'Da Approvare';
export const INVOICE_APPROVED = 'Approvata';
export const INVOICE_SENT = 'Inviata';

export class DealerInvoiceLineDetail extends BaseModel {
    id: string;
    dealer: string;
    info: string;
    tipo: string;
    compenso: number;
    offerta: string;
    iva: number;
    ritenuta: number;
    netto: number;
    soggetto_a_ritenuta: number;
    editMode = false;
}

export class DealerInvoiceLine extends BaseModel {
    id: string;
    tipo: string;
    mese: number;
    monthName: string;
    anno: number;
    totale: number;
    iva: number;
    ritenuta: number;
    netto: number;
    descrizione: string;
    voce_fattura: string;
    sublines: DealerInvoiceLineDetail[];
    editMode = false;

    get childModels() {
        return {
            sublines: DealerInvoiceLineDetail,
        };
    }
}

export class DealerInvoice extends BaseModel {
    id: number;
    id_link: number;
    id_merge: number;
    destinatario: number;
    subject: string;
    periods: string;
    vendors: string;
    profile_status: number;
    stato: number;
    is_old: number;
    numero_fattura: string;
    stato_invio_sdi: number;
    stato_freeinvoice: number;
    data_fattura: string;
    totale: number;
    iva: number;
    importo_ritenuta: number;
    created: string;
    receiver: string;
    send: boolean;
    sendProblem: boolean;
    update: boolean;
    resend: boolean;
    transactions_count: number;
    company: Dealer;
    lines: DealerInvoiceLine[];
    checked = false;

    get childModels() {
        return {
            lines: DealerInvoiceLine,
            company: Dealer,
        };
    }

    get profileProblem() {
        switch (this.profile_status) {
            case 0:
                return {
                    icon: 'far fa-id-card',
                    text: 'Profilo incompleto',
                };
            case 1:
                return {
                    icon: 'fas fa-envelope',
                    text: 'E-Mail errata',
                };
            case 2:
                return {
                    icon: 'fas fa-info-circle',
                    text: 'C.F./P.IVA/PEC errata',
                };
            case 3:
                return {
                    icon: 'fas fa-paper-plane',
                    text: 'Conferma PEC',
                };
            case 4:
            case 5:
                return {
                    icon: 'fas fa-code',
                    text: 'No Api Key',
                };
        }
    }

    get type() {
        switch (this.is_old) {
            case 0:
                return 'Nuova';
            case 1:
                return 'Vecchia';
        }
    }

    get status() {
        switch (this.stato) {
            case -1:
                return 'Nascosta';
            case 0:
                return 'In attesa';
            case 1:
                return 'Approvata';
            case 2:
                return 'Rifiutata';
        }
    }

    get sdiStatus() {
        switch (this.stato_invio_sdi) {
            case 0:
                return 'Da inviare';
            case 4:
                return 'Scartata';
            case 5:
                return 'Elaborazione';
            case 6:
                return 'Non inviata';
            case 7:
                return 'Inviata';
            case 8:
                return 'Consegnata';
            case 9:
                return 'Non consegnata';
        }
    }

    fromArray(result: []) {
        const array: DealerInvoice[] = [];
        for (const item of result) {
            array.push(new DealerInvoice(item));
        }
        return array;
    }
}
