<ngx-spinner
        bdColor="rgba(51,51,51,0.76)"
        size="medium"
        color="#fff"
        type="timer"
        [fullScreen]="true"
>
    <p style="color: white"> Caricamento... </p>
</ngx-spinner>


<div class="card bg-gradient-1">
    <div class="card-body">
        <div class="row align-items-center">
            <div class="col">
                <h1 class="display-4 mb-0">Costi Agenti</h1>
            </div>
        </div>
        <hr>
        <div class="row align-items-end">
            <div class="col-xl-12">
                <label>Seleziona periodo</label>
                <div class="form-group form-group-icon mb-0">
                    <i class="far fa-calendar-alt"></i>
                    <select class="form-control" [(ngModel)]="this.periodo">
                        <option *ngFor="let per of this.periodi" [value]="per.value">{{per.label}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>

<app-master-subheader cardClass="bg-gradient-light" [url]="urlSubheader"></app-master-subheader>

<app-master-card
        *ngIf="this.data"
        bodyClass="p-0"
        title="Agenti"
        [height]="false"
>
    <app-table
            [striped]="true"
            [rows]="this.data.rows" [columns]="this.data.columns">
    </app-table>
</app-master-card>
