<div class="modal-header">
    <h4 class="modal-title clamp-title" translate>Add Call Schedule</h4>
    <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="dismissModal()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [ngBusy]="modalBusy">
    <h4><i
            class="fas fa-headset text-info mr-2"></i>{{operator?.profile?.ragione_sociale}}
    </h4>
    <h4>
        <i class="fas fa-store text-info mr-2"></i>{{lead?.company_owner}}
    </h4>
    <form [formGroup]="scheduleCallForm" (ngSubmit)="save()">
        <div class="form-row mt-2">
            <div class="col-12 form-group">
                <label for="scheduleDate" translate>Datetime</label>
                <input class="form-control" id="scheduleDate" type="datetime-local" formControlName="scheduled_at">
                <val-errors controlName="scheduled_at">
                    <ng-template valError="required">
                        <span translate>Datetime is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12">
                <label for="phone" translate>Phone</label>
                <ng-select id="phone" formControlName="destination">
                    <ng-option [value]="lead?.cell_phone">{{lead?.cell_phone}}</ng-option>
                    <ng-option [value]="lead?.phone">{{lead?.phone}}</ng-option>
                </ng-select>
                <val-errors controlName="destination">
                    <ng-template valError="required">
                        <span translate>Phone number is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 mt-3">
                <button type="submit" class="btn btn-success btn-block" [disabled]="scheduleCallForm.invalid">
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                </button>
            </div>
        </div>
    </form>
</div>
