<div class="card bg-gradient-1">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                 width="160px"
                 src="assets/img/flats/payment.png">
            <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>Dashboard</h1>
                <hr>
                <form (ngSubmit)="loadData()">
                    <div class="row align-items-end">
                        <div class="col-lg-4 col-12"><label for="category" translate>Category</label>
                            <ng-select [(ngModel)]="filter.category" [items]="categories" bindLabel="name"
                                       [loading]="categoryLoading" name="category" id="category"
                                       bindValue="name" [searchable]="true"
                                       (search)="loadCategories($event?.term)"></ng-select>
                        </div>
                        <div class="col-lg-3 col-12">
                            <label translate>Date</label>
                            <app-month-range-picker [localFormat]="localMonthFormat"
                                                    (selected)="setSelectedDateRange($event.start, $event.end,'date')"
                                                    [defaultMonthRange]="defaultMonthRange"
                            ></app-month-range-picker>
                        </div>
                        <div class="col-lg-3 col-12">
                            <label translate>Chart date</label>
                            <app-month-range-picker [localFormat]="localMonthFormat"
                                                    (selected)="setSelectedDateRange($event.start, $event.end,'chart_date')"
                                                    [defaultMonthRange]="defaultChartMonthRange"
                            ></app-month-range-picker>
                        </div>
                        <div class="col-lg-2 col-12">
                            <button class="btn btn-info btn-block" type="submit" translate>Filter
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="dashboard"
            [fullScreen]="false"
    >
        <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <div class="card-body">
        <div class="row">
            <div class="col-6 col-xl text-center border-right">
                <img src="/assets/img/flats/file.png" height="64"/>
                <p class="lead mt-2" translate>Total Invoiced</p>
                <h1 class="display-5 mb-0">{{dashboardReport.total_invoice|currency:currency}}</h1>
                <p class="text-muted">{{dashboardReport.invoice_percentage}}% <span translate>last 31 days</span></p>
            </div>
            <div class="col-6 col-xl text-center border-right">
                <img src="/assets/img/flats/dollar.png" height="64"/>
                <p class="lead mt-2" translate>Total Costs</p>
                <h1 class="display-5 mb-0">{{dashboardReport.total_cost|currency:currency}}</h1>
                <p class="text-muted">{{dashboardReport.cost_percentage}}% <span translate>last 31 days</span></p>
            </div>
            <div class="col-6 col-xl text-center border-right">
                <img src="/assets/img/flats/shuffle.png" height="64"/>
                <p class="lead mt-2" translate>Total Net</p>
                <h1 class="display-5 mb-0">{{dashboardReport.total_net|currency:currency}}</h1>
                <p class="text-muted">{{dashboardReport.net_percentage}}% <span translate>last 31 days</span></p>
            </div>
            <div class="col-6 col-xl text-center border-right">
                <img src="/assets/img/flats/percentage.png" height="64"/>
                <p class="lead mt-2" translate>Profitability Index</p>
                <h1 class="display-5 mb-0">{{dashboardReport.profitability_index}}%</h1>
                <p class="text-muted" translate>profit / revenue</p>
            </div>
            <div class="col-6 col-xl text-center border-right">
                <img src="/assets/img/flats/money-sign.png" height="64"/>
                <p class="lead mt-2" translate>Customers Credit</p>
                <h1 class="display-5 mb-0">{{dashboardReport.not_welded_invoice|currency:currency}}</h1>
                <p class="text-muted" translate>gross of VAT and also considering the pro forma</p>
            </div>
            <div class="col-6 col-xl text-center">
                <img src="/assets/img/flats/money-loss.png" height="64"/>
                <p class="lead mt-2" translate>Suppliers Debit</p>
                <h1 class="display-5 mb-0">{{dashboardReport.not_welded_cost|currency:currency}}</h1>
                <p class="text-muted" translate>payables to suppliers</p>
            </div>
        </div>
    </div>
</div>
<div class="card chart-card">
    <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="chart"
            [fullScreen]="false"
    >
        <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <div class="card-body">
        <div class="row">
            <div class="col-12 col-xl-6">
                <highcharts-chart *ngIf="isChartLoaded"
                                  title="Invoice"
                                  [Highcharts]="Highcharts"
                                  [options]="revenueOptions"
                ></highcharts-chart>
            </div>
            <div class="col-12 col-xl-6">
                <highcharts-chart *ngIf="isChartLoaded"
                                  title="Invoice"
                                  [Highcharts]="Highcharts"
                                  [options]="netOptions"
                ></highcharts-chart>
            </div>
        </div>
    </div>
</div>

<div class="card chart-card">
    <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="chart"
            [fullScreen]="false"
    >
        <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <div class="card-body">
        <div class="row">
            <div class="col-12 col-xl-6">
                <highcharts-chart *ngIf="isChartLoaded"
                                  title="Costi"
                                  [Highcharts]="Highcharts"
                                  [options]="categoryCostOptions"
                ></highcharts-chart>
            </div>
            <div class="col-12 col-xl-6">
                <highcharts-chart *ngIf="isChartLoaded"
                                  title="Costi"
                                  [Highcharts]="Highcharts"
                                  [options]="categoryInvoiceOptions"
                ></highcharts-chart>
            </div>
        </div>
    </div>
</div>

<div class="card chart-card">
    <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="default"
            color="#fff"
            type="square-jelly-box"
            name="chart"
            [fullScreen]="false"
    >
        <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <div class="card-body">
        <div class="row">
            <div class="col-12 col-xl-6">
                <highcharts-chart *ngIf="isChartLoaded"
                                  title="Invoice"
                                  [Highcharts]="Highcharts"
                                  [options]="incomesOptions"
                ></highcharts-chart>
            </div>
            <div class="col-12 col-xl-6">
                <highcharts-chart *ngIf="isChartLoaded"
                                  title="Invoice"
                                  [Highcharts]="Highcharts"
                                  [options]="outcomesOptions"
                ></highcharts-chart>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 col-xl-6">
        <div class="card">
            <div class="card-header bg-gradient-1">
                <h4 class="mb-0" translate>Invoice</h4>
            </div>
            <div class="card-body p-0 table-responsive">
                <table class="table table-bordered table-striped table-sm">
                    <ngx-spinner
                            bdColor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="square-jelly-box"
                            name="table-incomes"
                            [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <thead>
                    <tr>
                        <th translate colspan="2"></th>
                        <th class="text-right" *ngFor="let income of incomes">{{income.date|amDateFormat:'MMMM'}}</th>
                        <th class="text-right" translate>Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td colspan="2"><strong translate>Gross turnover</strong></td>
                        <td class="text-right pr-2" *ngFor="let income of incomes">
                            {{income.gross_turnover|currency:currency}}
                        </td>
                        <td class="text-right">{{calculateTotal(incomes, 'gross_turnover')|currency:currency}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong translate>Costs</strong></td>
                        <td class="text-right pr-2"
                            *ngFor="let income of incomes">{{income.cost|currency:currency}}</td>
                        <td class="text-right">{{calculateTotal(incomes, 'cost')|currency:currency}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong translate>Credit notes issued</strong></td>
                        <td class="text-right pr-2"
                            *ngFor="let income of incomes">{{income.credit_issued|currency:currency}}</td>
                        <td class="text-right">{{calculateTotal(incomes, 'credit_issued')|currency:currency}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong translate>Credit notes received</strong></td>
                        <td class="text-right pr-2"
                            *ngFor="let income of incomes">{{income.credit_received|currency:currency}}</td>
                        <td class="text-right">{{calculateTotal(incomes, 'credit_received')|currency:currency}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong translate>Gross net</strong></td>
                        <td class="text-right pr-2"
                            *ngFor="let income of incomes">{{income.net|currency:currency}}</td>
                        <td class="text-right">{{calculateTotal(incomes, 'net')|currency:currency}}</td>
                    </tr>
                    <tr>
                        <th rowspan="2" scope="rowgroup"><strong translate>VAT Credit/Debit</strong></th>
                        <th class="pl-2 text-success" translate>VAT credit</th>
                        <td class="text-right text-success pr-2"
                            *ngFor="let income of incomes">{{income.vat_credit|currency:currency}}</td>
                        <td rowspan="2"
                            class="text-right">{{calculateTotal(incomes, 'vat_credit')|currency:currency}}</td>
                    </tr>
                    <tr>
                        <th class=" pl-2 text-danger" translate>VAT debit</th>
                        <td class="text-right text-danger pr-2"
                            *ngFor="let income of incomes">{{income.vat_debit|currency:currency}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong translate>Withholding tax</strong></td>
                        <td class="text-right pr-2"
                            *ngFor="let income of incomes">{{income.withholding_tax|currency:currency}}</td>
                        <td class="text-right">{{calculateTotal(incomes, 'withholding_tax')|currency:currency}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-12 col-xl-6">
        <div class="card">
            <div class="card-header bg-gradient-1">
                <h4 class="mb-0" translate>Cash Out</h4>
            </div>
            <div class="card-body p-0 table-responsive">
                <table class="table table-bordered table-striped table-sm">
                    <ngx-spinner
                            bdColor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="square-jelly-box"
                            name="table-outcomes"
                            [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <thead>
                    <tr>
                        <th translate colspan="2"></th>
                        <th class="text-right"
                            *ngFor="let outcome of outcomes">{{outcome.date|amDateFormat:'MMMM'}}</th>
                        <th class="text-right" translate>Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td colspan="2"><strong translate>Incomes (VAT Excluded)</strong></td>
                        <td class="text-right pr-2"
                            *ngFor="let outcome of outcomes">{{outcome.incomes|currency:currency}}</td>
                        <td class="text-right">{{calculateTotal(outcomes, 'incomes')|currency:currency}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong translate>Outcomes (VAT Excluded)</strong></td>
                        <td class="text-right pr-2"
                            *ngFor="let outcome of outcomes">{{outcome.outcomes|currency:currency}}</td>
                        <td class="text-right">{{calculateTotal(outcomes, 'outcomes')|currency:currency}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong translate>Credit notes received</strong></td>
                        <td class="text-right pr-2"
                            *ngFor="let outcome of outcomes">{{outcome.credit_received|currency:currency}}</td>
                        <td class="text-right">{{calculateTotal(outcomes, 'credit_received')|currency:currency}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong translate>Gross turnover</strong></td>
                        <td class="text-right pr-2"
                            *ngFor="let outcome of outcomes">{{outcome.gross_turnover|currency:currency}}</td>
                        <td class="text-right">{{calculateTotal(outcomes, 'gross_turnover')|currency:currency}}</td>
                    </tr>
                    <tr>
                        <th rowspan="2" scope="rowgroup"><strong translate>VAT Credit/Debit</strong></th>
                        <th class=" pl-2 text-success" translate>VAT credit</th>
                        <td class="text-right text-success pr-2"
                            *ngFor="let outcome of outcomes">{{outcome.vat_credit|currency:currency}}</td>
                        <td rowspan="2"
                            class="text-right">{{calculateTotal(outcomes, 'vat_credit')|currency:currency}}</td>
                    </tr>
                    <tr>
                        <th class=" pl-2 text-danger" translate>VAT debit</th>
                        <td class="text-right text-danger pr-2"
                            *ngFor="let outcome of outcomes">{{outcome.vat_debit|currency:currency}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong translate>Withholding tax</strong></td>
                        <td class="text-right pr-2"
                            *ngFor="let outcome of outcomes">{{outcome.withholding_tax|currency:currency}}</td>
                        <td class="text-right">{{calculateTotal(outcomes, 'withholding_tax')|currency:currency}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
