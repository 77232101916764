import {Injectable} from '@angular/core';
import {RequestService} from '@services/request.service';
import {map} from 'rxjs/operators';
import {MasterPlan} from '@models/master-plan';
import {BehaviorSubject, Observable} from 'rxjs';
import {AlertService} from '@services/alert.service';
import * as moment from 'moment';

const plans = 'plans';
const segments = 'segments';
const agentsPerformance = 'agents-performance';
const drillDown = 'drill-down';

@Injectable({
  providedIn: 'root'
})

export class MasterPlanningService {
  constructor(private request: RequestService, private alertService: AlertService) {
  }

  apiFormat = 'YYYY-MM-DD';
  segmentId: number;
  drillDowns = ['month', 'week', 'days'];
  selectedDrillDown = this.drillDowns[0];
  private DRILL_DOWN = new BehaviorSubject(this.drillDowns[0]);
  public drillDown = this.DRILL_DOWN.asObservable();

  getFilterParams(form) {
    const param = {};
    if (form.dateRange) {
      param['from'] = form.dateRange.start;
      param['to'] = form.dateRange.end;
    }
    if (form.scope && form.scope.toLowerCase() === 'general' || !form.scope) {
      param['target'] = 'general';
    }
    if (form.scope && form.scope.toLowerCase() !== 'general') {
      param['target'] = form.scope.toLowerCase();
      param[form.scope.toLowerCase() + '_id'] = form.scopeId;
    }
    return param;
  }

  getPlans(segmentId, params): Observable<MasterPlan> {
    this.segmentId = segmentId;
    return this.request.get(plans + '/' + segmentId, params).pipe(
        map((response: any) => {
          return new MasterPlan(response);
        })
    );
  }

  setDrillDownParams(startDate, endDate, filters, targetType, period) {
    const params = this.getFilterParams(filters);
    params['target_type'] = targetType;
    params['drill_down'] = period;
    params['from'] = startDate;
    params['to'] = endDate;
    return params;
  }

  getPlansDrillDown(date, filters, targetType, selectedDrillDown, isBack = false): Observable<MasterPlan> {
    const period = selectedDrillDown === this.drillDowns[2] ? 'week' : 'month';
    const selectedDate = moment(date).format(this.apiFormat);
    let startDate = moment(selectedDate).clone().startOf(period).format(this.apiFormat);
    let endDate = moment(selectedDate).clone().endOf(period).add(1, 'days').format(this.apiFormat);
    if (period === this.drillDowns[1]) {
      startDate = moment(selectedDate).format(this.apiFormat);
      endDate = moment(this.setEndDate(date)).add(1, 'days').format(this.apiFormat);
    }
    const params = this.setDrillDownParams(startDate, endDate, filters, targetType, selectedDrillDown);
    return this.request.get(plans + '/' + this.segmentId + '/' + drillDown, params).pipe(
        map((response: any) => {
          return new MasterPlan(response);
        })
    );
  }

  getDrillDown(value, isBack = false) {
    for (let i = 0; i <= this.drillDowns.length - 1; i++) {
      if (value === this.drillDowns[i]) {
        return isBack ? this.drillDowns[i - 1] : this.drillDowns[i + 1];
      }
    }
  }

  formatDate(date, period) {
    if (date) {
      return period === this.drillDowns[1] ? moment(date).week() : period === this.drillDowns[2]
          ? moment(date).format('dd') : moment(date).format('MMM');
    }
    return date;
  }

  setStartDate(date, format) {
    return moment(date).format(format);
  }

  setEndDate(date, format = 'YYYY-MM-DD') {
    const lastDayInMonth = moment(date).clone().endOf('month').format(this.apiFormat);
    const endOfWeek = moment(date).add(6, 'days').format(this.apiFormat);
    if (moment(lastDayInMonth).diff(endOfWeek, 'days') > -1) {
      return moment(endOfWeek).format(format);
    }
    return moment(lastDayInMonth).format(format);
  }

  getOperators() {
    return this.request.get('vendor/' + segments).pipe(
        map((response: any) => {
          return response;
        })
    );
  }

  updatePlan(plan, newValue): Observable<MasterPlan> {
    const body = {
      value: newValue
    };
    return this.request.put(plans + '/' + plan.id, body).pipe(
        map((response: any) => {
          return new MasterPlan(response);
        })
    );
  }

  createPlan(segmentId, selectedItem, filterForm, newValue): Observable<MasterPlan> {
    const body = {
      target_type: selectedItem.targetType,
      target: filterForm.scope,
      vendor_segment_id: segmentId,
      month: selectedItem.date.split('-')[1],
      year: selectedItem.date.split('-')[0],
      value: newValue
    };
    if (filterForm.scope !== 'general') {
      body[filterForm.scope + '_id'] = filterForm.scopeId;
    }
    if (selectedItem.breakDownType) {
      body['breakdown_type'] = selectedItem.breakDownType;
    }
    return this.request.post(plans, body).pipe(
        map((response: any) => {
          return new MasterPlan(response);
        })
    );
  }

  getAgentsPerformance(params) {
    return this.request.get(plans + '/' + this.segmentId + '/' + agentsPerformance, params);
  }

  errorAlert(message?) {
    this.alertService.show(
        'An error occurred!',
        message ? message : 'Try again!',
        {classname: 'bg-danger text-light', delay: 2000}
    );
  }

  getDealerSales(params?) {
    return this.request.get('dealer/sales', params).pipe(
        map((response: any) => {
          return response;
        })
    );
  }

  getCitiesWithShops(params?) {
    return this.request.get('dealer/sales/cities-with-shops', params).pipe(
        map((response: any) => {
          return response;
        })
    );
  }

  getCitiesWithoutShops(params?) {
    return this.request.get('dealer/sales/cities-without-shops', params).pipe(
        map((response: any) => {
          return response;
        })
    );
  }
}
