import { BaseModel } from "./base-model";
import { Vendor } from "./vendor";

export const MENU_RECHARGE = 'ricariche';
export const MENU_TICKET = 'ticket';

/* tslint:disable:variable-name */
export class Menu extends BaseModel {

  badges: {
    MENU_RECHARGE: number,
    MENU_TICKETS: number,
  };
}