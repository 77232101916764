import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DashboardService} from '@services/dashboard.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Color, BaseChartDirective, Label} from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';

@Component({
    selector: 'app-master-dealer-dettaglio',
    templateUrl: './master-dealer-dettaglio.component.html',
    styleUrls: ['./master-dealer-dettaglio.component.scss']
})
export class MasterDealerDettaglioComponent implements OnInit {
    id: string;
    data: any;

    public lineChartData: ChartDataSets[] = [
        {data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A'},
    ];
    public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    public lineChartOptions: (ChartOptions & { annotation: any }) = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            // We use this empty structure as a placeholder for dynamic theming.
            xAxes: [{}],
            yAxes: [
                {
                    position: 'left',
                    gridLines: {
                        color: 'rgba(0, 0, 0, 0)',
                    }
                },
                {
                    position: 'right',
                    gridLines: {
                        color: 'rgba(0, 0, 0, 0)',
                    },
                }
            ]
        },
        annotation: {
            annotations: [
                {
                    type: 'line',
                    mode: 'vertical',
                    scaleID: 'x-axis-0',
                    value: 'March',
                    borderColor: 'orange',
                    borderWidth: 2,
                    label: {
                        enabled: true,
                        fontColor: 'orange',
                        content: 'LineAnno'
                    }
                },
            ],
        },
    };
    public lineChartColors: Color[] = [
        {
            backgroundColor: '#ffc8009e',
            borderColor: '#ffb100',
            pointBackgroundColor: '#ffb100',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
    ];
    public lineChartColorsFatturato: Color[] = [
        {
            backgroundColor: '#ff001e9e',
            borderColor: 'rgba(145,0,21,0.62)',
            pointBackgroundColor: 'rgba(145,0,21,0.62)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
    ];
    public lineChartLegend = false;
    public lineChartType: ChartType = 'line';
    public lineChartPlugins = [];

    public pieChartOptions: ChartOptions = {
        responsive: true,
        legend: {
            position: 'top',
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    const label = ctx.chart.data.labels[ctx.dataIndex];
                    return label;
                },
            },
        }
    };
    public pieChartLabels: Label[] = [['Amministrazione'], ['Contratti'], 'Pagamenti'];
    public pieChartData: number[] = [300, 500, 100];
    public pieChartType: ChartType = 'pie';
    public pieChartLegend = false;
    public pieChartPlugins = [pluginDataLabels];
    public pieChartColors = [
        {
            backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
        },
    ];

    riepilogoPeriod = {
        from: moment().format('YYYY-MM-01'),
        to: moment().format('YYYY-MM-DD'),
    };
    timeline = [];

    constructor(
        public route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private dashboardService: DashboardService
    ) {
        this.route.params.subscribe(params => {
            this.id = params['id'];
            this.reload(); // reset and set based on new parameter this time
        });
    }

    get dealer() {
        if (this.data) {
            return this.data.dealer;
        }

        return {
            ragsociale: ''
        };
    }

    get chartPeriod() {
        if (this.data) {
            return this.data.chartPeriod;
        }

        return {
            from: moment().format('YYYY-01-01'),
            to: moment().format('YYYY-MM-DD'),
        };
    }

    affiliazione(id) {
        if (this.data) {
            if (id in this.data.affiliazioni) {
                return this.data.affiliazioni[id];
            }
        }

        return null;
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');

        this.reload();
    }

    reload() {
        this.spinner.show();

        this.dashboardService.get(`/negozio-dettaglio?id=${this.id}`)
            .subscribe(data => {
                this.data = data.result;
                this.spinner.hide();
            });

        this.dashboardService.get(`-detail/negozio-visite?id=${this.id}`)
            .subscribe(data => {
                this.timeline = data.result;
            });
    }

    sendReply(item: any) {
        this.spinner.show();

        this.dashboardService.post(`/visite-risposta`, {
            id_visita: item.id,
            testo: item.reply
        })
            .subscribe((data) => {
                this.dashboardService.get(`-detail/negozio-visite?id=${this.id}`)
                    .subscribe(data => {
                        this.timeline = data.result;

                        this.spinner.hide();
                    });
            });
    }
}
