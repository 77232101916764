import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PbxCall} from '@models/pbx/pbx-call';
import {MetaPagination} from '@models/meta-pagination';
import {PbxCampaignService} from '@services/pbx-campaign.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {PbxLead} from '@models/pbx/pbx-lead';
import {ConfirmOptionsService} from '@services/confirm-options.service';
import {PbxCampaignQuestion} from '@models/pbx/pbx-question';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-pbx-calls',
  templateUrl: './pbx-calls.component.html',
  styleUrls: ['./pbx-calls.component.css']
})
export class PbxCallsComponent implements OnInit {
  @Input() calls: PbxCall[] = [];
  @Input() callsPagination: MetaPagination;
  @Output() changePage = new EventEmitter<number>();
  localFormat = PbxCampaignService.datetimeLocalFormat;
  @ViewChild('answersFormTemplate') private answersFormTemplate;
  @ViewChild('callFormTemplate') private callFormTemplate;
  deleteCallOptions: ConfirmOptionsService;
  answersModal: NgbModalRef;
  questionAnswers: PbxCampaignQuestion[] = [];
  callModal: NgbModalRef;
  selectedEditCall: PbxCall;
  selectedCall: PbxCall;

  constructor(
      private modalService: NgbModal,
      private pbxCampaignService: PbxCampaignService,
      private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.deleteCallOptions = new ConfirmOptionsService({
      title: 'Vuoi cancellarla?',
      text: 'Non potrai tornare indietro!',
      confirmButtonText: 'Si',
      cancelButtonText: 'Annulla'
    });
    this.pbxCampaignService.changedCampaign.subscribe(data => {
      if (this.callsPagination && data) {
        this.changePage.emit(1);
      }
    });
  }

  loadCalls() {
    this.changePage.emit(this.callsPagination.currentPage);
  }

  showAnswersModal(call: PbxCall) {
    this.questionAnswers = [];
    this.selectedCall = call;
    this.loadQuestionsAndAnswers(call);
    this.answersModal = this.modalService.open(this.answersFormTemplate, {size: 'lg'});
    this.answersModal.result.then((res) => {
    }).catch((res) => {
    });
  }

  showCallModal(call: PbxCall) {
    this.selectedEditCall = call;
    this.callModal = this.modalService.open(this.callFormTemplate, {size: 'xl'});
    this.callModal.result.then((res) => {
    }).catch((res) => {
    });
  }

  callStatus(key: string) {
    return key ? PbxCampaignService.callStatuses.find(status => status.key === key) : '';
  }
  feedbackStatus(key: string) {
    return key ? PbxCampaignService.feedbackStatuses.find(status => status.key === key) : '';
  }
  deleteCall(id: number) {
    this.pbxCampaignService.deleteCall(id).subscribe((response) => {
      const index = this.calls.findIndex(call => call.id === id);
      if (index > -1) {
        this.calls.splice(index, 1);
      }
    });
  }

  loadQuestionsAndAnswers(call: PbxCall) {
    this.spinner.show('table-questions');
    this.pbxCampaignService.getQuestionAnswers(call.id, call.lead.campaign_id).subscribe((response: PbxCampaignQuestion[]) => {
      this.questionAnswers = response;
      this.spinner.hide('table-questions');
    }, err => {
      this.spinner.hide('table-questions');
    });
  }
}
