<app-master-header [ngBusy]="busy"
                   cardClass="bg-gradient-light"
                   title="Presidi"
>
    <div class="row align-items-end">
        <div class="col-6">
            <label>Seleziona il periodo di riferimento</label>
            <div class="form-group form-group-icon form-group-dark mb-0">
                <i class="far fa-calendar-alt"></i>
                <select class="form-control" [(ngModel)]="this.periodo">
                    <ng-container *ngIf="this.startup">
                        <option *ngFor="let per of this.startup.periodi" [value]="per.value">{{per.label}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
        <div class="col-6">
            <label>Proiezione</label>
            <div class="form-group form-group-icon form-group-dark mb-0">
                <i class="fas fa-chart-line form-input-icon "></i>
                <select class="form-control" [(ngModel)]="this.proiezione">
                    <option value="No">No</option>
                    <option value="Si">Si</option>
                </select>
            </div>
        </div>
    </div>
</app-master-header>

<app-master-subheader imgClass="p-1 img-circle bg-gradient-light elevation-1" cardClass="bg-kena-gradient" [url]="urlSubheader"></app-master-subheader>

<div class="row">
    <div class="col-8">
        <app-master-card
                bodyClass="p-0"
                title="Analisi presidi"
                image="assets/img/flats/chart.png"
        >
            <app-autoload-table
                    [pageSize]="16"
                    [url]="urlDettaglio"
            ></app-autoload-table>
        </app-master-card>

    </div>
    <div class="col-4">
        <app-master-card
                title="Mix Offerte"
                image="assets/img/flats/pie-chart.png"
        >
            <app-autoload-horizontal-bar-chart
                    [url]="urlOfferte"
                    [gradient]="false"
            ></app-autoload-horizontal-bar-chart>
        </app-master-card>
        <app-master-card
                title="Mix Operatori"
                image="assets/img/flats/pie-chart-2.png"
        >
            <app-autoload-horizontal-bar-chart
                    [url]="urlOperatori"
                    [gradient]="false"
            ></app-autoload-horizontal-bar-chart>
        </app-master-card>
    </div>
</div>
