<div class="card bg-gradient-light">
    <form (ngSubmit)="loadBankTransactions()">
        <div class="card-header">
            <h1 class="card-title" translate>Filters</h1>
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-12 col-md-4">
                    <label for="date" translate>Execution date</label>
                    <input id="date" class="form-control form-control-sm" type="text" daterangepicker
                           [(ngModel)]="filter.execution_date" name="execution_date"
                           (selected)="setSelectedDateRange($event.start, $event.end)"/>
                </div>
                <div class="form-group col-12 col-md-4">
                    <label for="bankDescription" translate>Bank description</label>
                    <input id="bankDescription" name="bank_description" [(ngModel)]="filter.bank_description"
                           type="text"
                           class="form-control form-control-sm"
                    />
                </div>
                <div class="form-group col-12 col-md-4">
                    <label for="bankAccount" translate>Bank Account</label>
                    <ng-select [(ngModel)]="filter.bank_account_id"
                               [searchable]="true" (search)="loadFilterBankAccount($event?.term)" [loading]="isLoading"
                               name="bankAccount" class="form-control-sm" id="bankAccount">
                        <ng-option [value]="bankAccount.id"
                                   *ngFor="let bankAccount of filterBankAccounts">{{bankAccount.bank_name}}
                            -  {{bankAccount.account_number}}</ng-option>
                    </ng-select>
                </div>
                <div class="form-group col-12 col-md-4">
                    <label for="documentType" translate>Code</label>
                    <input id="documentType" name="type" [(ngModel)]="filter.code"
                           type="text"
                           class="form-control form-control-sm"
                    />
                </div>
                <div class="form-group col-6 col-md-2">
                    <label for="documentType" translate>Competence</label>
                    <ng-select [(ngModel)]="filter.is_credit"
                               name="competence" class="form-control-sm">
                        <ng-option *ngFor="let category of categories" translate
                                   [value]="category.key">{{category.name}}</ng-option>
                    </ng-select>
                </div>
                <div class="col-6 col-md-2 form-group">
                    <label for="matchedBank" translate>Matched Bank</label>
                    <ng-select id="matchedBank" name="matched_bank" [(ngModel)]="filter.is_matched"
                               class="form-control-sm">
                        <ng-option translate value="1">Yes</ng-option>
                        <ng-option translate value="0">No</ng-option>
                    </ng-select>
                </div>
                <div class="col-6 col-md-2 form-group">
                    <label for="matchedBank" translate>Handled</label>
                    <ng-select id="handled" name="is_handled" [(ngModel)]="filter.is_handled" class="form-control-sm">
                        <ng-option translate value="1">Yes</ng-option>
                        <ng-option translate value="0">No</ng-option>
                    </ng-select>
                </div>
                <div class="col-6 col-md-2 form-group">
                    <label for="matchedBank" translate>Refund</label>
                    <ng-select id="refund" name="is_refund" [(ngModel)]="filter.is_refund" class="form-control-sm">
                        <ng-option translate value="1">Yes</ng-option>
                        <ng-option translate value="0">No</ng-option>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button class="btn btn-dark btn-sm mr-2" type="button" (click)="resetFilter()" translate>
                Reset
            </button>
            <button
                    class="btn btn-primary btn-sm"
                    type="submit" translate
            >
                Filter
            </button>
        </div>
    </form>
</div>
<nav>
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a class="nav-link active" id="nav-bank-tab" data-toggle="tab" href="#nav-bank" role="tab"
           aria-controls="nav-bank" aria-selected="true">
            Bank transaction</a>
        <a class="nav-link" id="nav-credit-tab" data-toggle="tab" href="#nav-credit" role="tab"
           aria-controls="nav-credit" aria-selected="false">Credit Transaction</a>
    </div>
</nav>
<div class="tab-content" id="nav-tabContent">
    <div class="tab-pane fade show active" id="nav-bank" role="tabpanel" aria-labelledby="nav-bank-tab">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-lg-10 col-12">
                        <h4 translate>Bank Incomes/Outcomes</h4>
                    </div>
                    <div class="col-lg-2 col-6">
                        <button class="btn btn-success btn-sm float-lg-right" type="button" translate
                                (click)="showModal()">
                            Upload File
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body p-0 rounded-bottom table-responsive">
                <table class="table table-sm table-striped">
                    <thead>
                    <tr>
                        <th translate>Execution date</th>
                        <th translate>Value date</th>
                        <th translate>Description</th>
                        <th translate>Code</th>
                        <th translate class="text-right">Amount</th>
                        <th translate class="text-center">Matched</th>
                        <th translate class="text-center">Refund</th>
                        <th translate class="text-center">Handled</th>
                        <th class="text-right"></th>
                    </tr>
                    </thead>
                    <tbody class="bg-gradient-white">
                    <ngx-spinner
                            bdColor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="square-jelly-box"
                            name="table-transaction"
                            [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <tr *ngFor="let transaction of transactions">
                        <td>{{transaction.execution_date|amDateFormat:localFormat}}</td>
                        <td>{{transaction.value_date|amDateFormat:localFormat}}</td>
                        <td>
                            <app-rich-text-truncate [text]="transaction.description"
                                                    charLimit="20"></app-rich-text-truncate>
                        </td>
                        <td>{{transaction.code}} </td>
                        <td class="text-right text-success"
                            *ngIf="transaction.is_credit">{{transaction.amount|currency:currency}}</td>
                        <td class="text-right text-danger"
                            *ngIf="!transaction.is_credit">{{transaction.amount|currency:currency}}</td>
                        <td class="text-center">
                            <i class="text-success fas fa-check" *ngIf="transaction?.is_matched == 1"></i>
                            <i class="text-danger fas fa-times" *ngIf="transaction?.is_matched == 0"></i>
                        </td>
                        <td class="text-center">
                            <i class="text-success fas fa-check" *ngIf="transaction?.is_refund == 1"></i>
                            <i class="text-danger fas fa-times" *ngIf="transaction?.is_refund == 0"></i>
                        </td>
                        <td class="text-center">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" [id]="'transaction'+transaction.id"
                                       [(ngModel)]="transaction.is_handled"
                                       [disabled]="transaction?.is_matched==1"
                                       (ngModelChange)="changeHandledTransaction(transaction)">
                                <label class="custom-control-label" [for]="'transaction'+transaction.id"></label>
                            </div>
                        </td>
                        <td class="text-right">
                            <button class="btn btn-link" (click)="showTransactionModal(transaction)">
                                <i class=" fa fa-edit text-muted"></i>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="transactions?.length<=0">
                        <td class="text-muted" colspan="13" translate>List is empty</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer" *ngIf="transactions?.length>0">
    <span><span translate>There are</span><b> {{ this.transactionsPagination.totalCount }}</b>
        {{this.transactionsPagination.totalCount <= 1 ? 'transaction' : 'transactions'|translate}}.</span>
                <ngb-pagination *ngIf="transactionsPagination.pageCount>1"
                                size="sm"
                                [pageSize]="transactionsPagination.perPage"
                                [maxSize]="10"
                                [(page)]="transactionsPagination.currentPage"
                                [collectionSize]="transactionsPagination.totalCount"
                                (pageChange)="loadBankTransactions()"
                                [boundaryLinks]="true"
                ></ngb-pagination>
            </div>
        </div>
    </div>
    <div class="tab-pane fade" id="nav-credit" role="tabpanel" aria-labelledby="nav-credit-tab">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-lg-10 col-12">
                        <h4 translate>Credit cards</h4>
                    </div>
                    <div class="col-lg-2 col-6">
                        <button class="btn btn-success btn-sm float-lg-right" type="button" translate
                                (click)="showCreditCardModal()">
                            Upload File
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body p-0 rounded-bottom table-responsive">
                <table class="table table-sm table-striped">
                    <thead>
                    <tr>
                        <th translate>Transaction date</th>
                        <th translate>Order id</th>
                        <th translate>Card type</th>
                        <th translate class="text-right">Amount</th>
                    </tr>
                    </thead>
                    <tbody class="bg-gradient-white">
                    <ngx-spinner
                            bdColor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="square-jelly-box"
                            name="table-credit-cards"
                            [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <tr *ngFor="let creditCard of creditCards">
                        <td>{{creditCard.transaction_date|amDateFormat:localFormat}}</td>
                        <td>{{creditCard.order_id}}</td>
                        <td>{{creditCard.card_type}}</td>
                        <td class="text-right">{{creditCard.amount|currency:currency}}</td>
                    </tr>
                    <tr *ngIf="creditCards?.length<=0">
                        <td class="text-muted" colspan="4" translate>List is empty</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer" *ngIf="creditCards?.length>0">
    <span><span translate>There are</span><b> {{ this.creditCardsPagination.totalCount }}</b>
        {{this.creditCardsPagination.totalCount <= 1 ? 'credit card' : 'credit cards'|translate}}.</span>
                <ngb-pagination *ngIf="creditCardsPagination.pageCount>1"
                                size="sm"
                                [pageSize]="creditCardsPagination.perPage"
                                [maxSize]="10"
                                [(page)]="creditCardsPagination.currentPage"
                                [collectionSize]="creditCardsPagination.totalCount"
                                (pageChange)="loadCreditCards()"
                                [boundaryLinks]="true"
                ></ngb-pagination>
            </div>
        </div>
    </div>
</div>
<ng-template #fileFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Bank transaction</h4>
        <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="dismissModal()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="fileBusy">
        <form [formGroup]="transactionForm" (ngSubmit)="saveTransactionFile()">
            <div class="form-row">
                <div class="col-12 form-group">
                    <label translate>Bank Account</label>
                    <ng-select
                            formControlName="bank_account_id"
                            [loading]="bankAccountLoading" [searchable]="true"
                            (search)="loadBankAccounts($event?.term)">
                        <ng-option [value]="bankAccount.id"
                                   *ngFor="let bankAccount of bankAccounts">{{bankAccount.bank_name}}
                            -  {{bankAccount.account_number}}</ng-option>
                    </ng-select>
                    <val-errors controlName="bank_account_id">
                        <ng-template valError="required">
                            <span translate>Bank account is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 form-group">
                    <label for="pdfFile" translate>File</label>
                    <div class="custom-file" *ngIf="!file">
                        <input id="pdfFile" type="file" class="custom-file-input"
                               (change)="handleFileInput($event?.target?.files[0])">
                        <label class="custom-file-label" for="pdfFile" translate>
                            Upload file...
                        </label>
                    </div>
                    <div *ngIf="file">
                        <span class="text-break">{{file}}</span>
                        <button class="btn btn-xs btn-warning float-right" type="button"
                                (click)="handleFileRemoveModal()">
                            <i class="fa fa-times m-0 px-1"></i>
                        </button>
                    </div>
                    <val-errors controlName="file">
                        <ng-template valError="required">
                            <span translate>File is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 mt-4">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="transactionForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #transactionFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Transaction</h4>
        <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="dismissModal()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="documentBusy">
        <ul class="list-group list-group-flush">
            <li class="list-group-item px-0">
                <span [innerHTML]="selectedTransaction?.description"></span></li>
            <li class="list-group-item px-0" *ngIf="selectedTransaction?.amount">
                <span translate>Amount</span>
                <span class="float-right">
                    <strong>{{selectedTransaction.amount|currency:currency}}</strong></span></li>
            <li class="list-group-item px-0">
                <span translate>Transaction ID</span>
                <span class="float-right">{{selectedTransaction.id}}</span></li>
            <li class="list-group-item px-0">
                <span translate>Execution date</span>
                <span class="float-right">{{selectedTransaction?.execution_date}}</span></li>
            <li class="list-group-item px-0" *ngIf="selectedTransaction?.payments?.length>0">
                <span translate>Document number</span>
                <p>
                    <span class="mr-2"
                          *ngFor="let payment of selectedTransaction.payments">[{{payment?.document?.number}}]</span>
                </p>
            </li>
            <li class="list-group-item px-0" *ngIf="selectedTransaction?.payments?.length>0">
                <span translate>Document date</span>
                <p>
                    <span class="mr-2"
                          *ngFor="let payment of selectedTransaction.payments">[{{payment?.document?.date}}]</span>
                </p>
            </li>
            <li class="list-group-item px-0">
                <span translate>Total paid</span>
                <span class="float-right"><strong>{{selectedTransaction?.total_paid|currency:currency}}</strong>
                </span>
            </li>
            <li class="list-group-item px-0"></li>
        </ul>
        <form (ngSubmit)="updateBankTransaction()">
            <div class="form-row">
                <div class="col-10 form-group my-0">
                    <label translate>Refund</label>
                </div>
                <div class="col-2 form-group d-flex align-items-center justify-content-end my-0">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="isRefund"
                               [(ngModel)]="transactionRefund" name="transactionRefund">
                        <label class="custom-control-label" for="isRefund" translate></label>
                    </div>
                </div>
                <div class="col-12 form-group">
                    <label for="note" translate>Note</label>
                    <editor apiKey="1nilu7q8vcp0ntuviwq5nhcskmj17c6awfhpbnr0hhv4smdw" id="note" [init]="defaultConfig" [(ngModel)]="transactionNote"
                            name="transactionNote"></editor>
                </div>
                <div class="col-12 form-group">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-lg-9 col-12">
                                    <h4 class="mb-0" translate>Chosen payments</h4>
                                </div>
                                <div class="col-lg-3 col-12">
                                    <button class="btn btn-secondary btn-sm float-right" type="button" translate
                                            (click)="showDocumentModal()">
                                        Add Payment
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0 rounded-bottom table-responsive payment-container">
                            <table class="table table-sm table-striped">
                                <thead>
                                <tr>
                                    <th translate>Company name</th>
                                    <th translate>Document number</th>
                                    <th translate>Due method</th>
                                    <th translate>Due date</th>
                                    <th translate class="text-right">Amount</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody class="bg-gradient-white">
                                <tr *ngFor="let payment of paymentList;index as i">
                                    <td>{{payment?.document?.company_name}}</td>
                                    <td>{{payment?.document?.number}}</td>
                                    <td>{{findMethod(payment.due_method)}}</td>
                                    <td>{{payment.due_date|amDateFormat:localFormat}}</td>
                                    <td class="text-right">
                                        <span class="text-success"
                                              *ngIf="payment?.document?.is_credit==1">{{payment.amount|currency:currency}}</span>
                                        <span class="text-danger"
                                              *ngIf="payment?.document?.is_credit==0">{{payment.amount|currency:currency}}</span>
                                    </td>
                                    <td class="text-right">
                                        <button class="btn btn-link" (click)="removePayment(payment.id)">
                                            <i class="fa fa-trash text-danger"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="paymentList?.length<=0">
                                    <td class="text-muted" colspan="7" translate>List is empty</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-4">
                    <button type="submit" class="btn btn-success btn-block">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #documentFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Subject</h4>
        <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="dismissDocumentModal()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ul class="list-group list-group-flush">
            <li class="list-group-item px-0">
                <span [innerHTML]="selectedTransaction?.description"></span></li>
            <li class="list-group-item px-0" *ngIf="selectedTransaction?.amount">
                <span translate>Amount</span>
                <span class="float-right">
                    <strong>{{selectedTransaction.amount|currency:currency}}</strong></span></li>
            <li class="list-group-item px-0">
                <span translate>Transaction ID</span>
                <span class="float-right">{{selectedTransaction.id}}</span></li>
            <li class="list-group-item px-0">
                <span translate>Execution date</span>
                <span class="float-right">{{selectedTransaction?.execution_date}}</span></li>
            <li class="list-group-item px-0" *ngIf="selectedTransaction?.payments?.length>0">
                <span translate>Document number</span>
                <p>
                    <span class="mr-2"
                          *ngFor="let payment of selectedTransaction.payments">[{{payment?.document?.number}}]</span>
                </p>
            </li>
            <li class="list-group-item px-0" *ngIf="selectedTransaction?.payments?.length>0">
                <span translate>Document date</span>
                <p>
                    <span class="mr-2"
                          *ngFor="let payment of selectedTransaction.payments">[{{payment?.document?.date}}]</span>
                </p>
            </li>
            <li class="list-group-item px-0"></li>
        </ul>
        <div class="form-row">
            <div class="col-12 form-group">
                <label translate>Choose a subject</label>
                <ng-select [items]="subjects" bindLabel="company_name"
                           [loading]="subjectLoading" [(ngModel)]="selectedSubjectId"
                           (change)="loadUnmatchedDocuments()" (clear)="loadSubject()"
                           bindValue="id" [searchable]="true" (search)="loadSubject($event?.term)"></ng-select>
            </div>
            <div class="col-12 form-group">
                <label translate>Choose a document</label>
                <ng-select [loading]="documentLoading" [(ngModel)]="selectedDocumentId"
                           (change)="loadPayments()"
                           [searchable]="true"
                           (search)="loadUnmatchedDocuments($event?.term)" (clear)="loadUnmatchedDocuments()">
                    <ng-option *ngFor="let document of unmatchedDocuments" [value]="document?.id">
                        {{document?.number}} [{{document?.date|amDateFormat:localFormat}}
                        ][{{document?.amount|currency:currency}}]
                    </ng-option>
                </ng-select>
            </div>
        </div>
        <ul class="list-group list-group-flush">
            <li class="list-group-item px-0" *ngIf="chosenDocument">
                <span translate>Amount</span>
                <span class="float-right">{{chosenDocument?.amount|currency:currency}}</span></li>
            <li class="list-group-item px-0" *ngIf="chosenDocument">
                <span translate>VAT amount</span>
                <span class="float-right">{{chosenDocument?.vat_amount|currency:currency}}</span></li>
            <li class="list-group-item px-0">
                <span translate>Selected amount</span>
                <span class="float-right">{{selectedAmount|currency:currency}}</span></li>
            <li class="list-group-item px-0">
        </ul>
        <form (ngSubmit)="saveChosenPayments()">
            <div class="form-row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-lg-10 col-12">
                                    <h4 class="mb-0" translate>Payments</h4>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0 rounded-bottom table-responsive">
                            <table class="table table-sm table-striped">
                                <thead>
                                <tr>
                                    <th>
                                        <div class="custom-control custom-checkbox" *ngIf="unmatchedPayments?.length>0">
                                            <input class="custom-control-input" type="checkbox" id="select-all"
                                                   [(ngModel)]="selectAll" name="selectAll"
                                                   (change)="selectAllPayments($event)">
                                            <label for="select-all" class="custom-control-label" translate></label>
                                        </div>
                                    </th>
                                    <th translate>Due method</th>
                                    <th translate>Due date</th>
                                    <th translate>Document No</th>
                                    <th translate class="text-right">Amount</th>
                                </tr>
                                </thead>
                                <tbody class="bg-gradient-white">
                                <ngx-spinner
                                        bdColor="rgba(0, 0, 0, 0.8)"
                                        size="default"
                                        color="#fff"
                                        type="square-jelly-box"
                                        name="table-payments"
                                        [fullScreen]="false"
                                >
                                    <p style="color: white">Caricamento...</p>
                                </ngx-spinner>
                                <tr *ngFor="let unmatchedPayment of unmatchedPayments;index as i">
                                    <td>
                                        <div class="custom-control custom-checkbox">
                                            <input class="custom-control-input" type="checkbox"
                                                   [id]="i" name="is_selected"
                                                   [(ngModel)]="unmatchedPayment.is_selected"
                                                   (change)="selectPayment($event, unmatchedPayment)">
                                            <label [for]="i" class="custom-control-label"></label>
                                        </div>
                                    </td>
                                    <td>{{findMethod(unmatchedPayment.due_method)}}</td>
                                    <td>{{unmatchedPayment.due_date|amDateFormat:localFormat}}</td>
                                    <td>{{unmatchedPayment.document?.number}}</td>
                                    <td class="text-right text-success">{{unmatchedPayment.amount|currency:currency}}</td>
                                </tr>
                                <tr *ngIf="unmatchedPayments?.length<=0">
                                    <td class="text-muted" colspan="6" translate>List is empty</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer" *ngIf="unmatchedPayments?.length>0">
    <span><span translate>There are</span><b> {{ this.unmatchedPaymentsPagination.totalCount }}</b>
        {{this.unmatchedPaymentsPagination.totalCount <= 1 ? 'document' : 'documents'}}.</span>
                            <ngb-pagination *ngIf="unmatchedPaymentsPagination.pageCount>1"
                                            size="sm"
                                            [pageSize]="unmatchedPaymentsPagination.perPage"
                                            [maxSize]="10"
                                            [(page)]="unmatchedPaymentsPagination.currentPage"
                                            [collectionSize]="unmatchedPaymentsPagination.totalCount"
                                            (pageChange)="loadPayments()"
                                            [boundaryLinks]="true"
                            ></ngb-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-4">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="chosenPayments?.length<=0">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #creditCardFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Credit card payments</h4>
        <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="dismissCreditCardModal()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="creditCardBusy">
        <form [formGroup]="creditCardForm" (ngSubmit)="saveCreditCardFile()">
            <div class="form-row">
                <div class="col-12 form-group">
                    <label for="file" translate>File</label>
                    <div class="custom-file" *ngIf="!creditCardFile">
                        <input id="file" type="file" class="custom-file-input"
                               (change)="handleCreditCardFileInput($event?.target?.files[0])">
                        <label class="custom-file-label" for="pdfFile" translate>
                            Upload file...
                        </label>
                    </div>
                    <div *ngIf="creditCardFile">
                        <span class="text-break">{{creditCardFile}}</span>
                        <button class="btn btn-xs btn-warning float-right" type="button"
                                (click)="handleCreditCardFileRemoveModal()">
                            <i class="fa fa-times m-0 px-1"></i>
                        </button>
                    </div>
                    <val-errors controlName="file">
                        <ng-template valError="required">
                            <span translate>File is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 mt-4">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="creditCardForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
