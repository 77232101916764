<div class="row" [ngBusy]="busy">
    <div class="col-12 col-xl-8 col-xxl-6 offset-md-2 offset-xl-3">
        <div class="card card-primary card-outline">
            <div class="card-body">
                <div class="row">
                    <div class="col-3">
                        <div class="text-center">
                            <img
                                    class="rounded img-fluid"
                                    [src]="profile.pic"
                                    alt="User profile picture"
                            />
                        </div>
                        <div class="mt-3">
                            <p class="text-muted">BRAND AFFILIATI</p>

                            <table class="table table-borderless">
                                <tr *isVendorEnabled="['Kena Mobile']">
                                    <td>
                                        <img
                                                src="/assets/img/small_brands/kena.png"
                                                height="24px"
                                        />
                                    </td>
                                    <td>{{ me.codice_kena }}</td>
                                </tr>
                                <tr *isVendorEnabled="['Tim']">
                                    <td>
                                        <img src="/assets/img/small_brands/tim.png" height="24px"/>
                                    </td>
                                    <td>{{ me.codice_tim }}</td>
                                </tr>
                                <tr *isVendorEnabled="['Tiscali Mobile']">
                                    <td>
                                        <img
                                                src="/assets/img/small_brands/tiscali.png"
                                                height="24px"
                                        />
                                    </td>
                                    <td>{{ me.codice_tiscali }}</td>
                                </tr>
                                <tr *isVendorEnabled="['Eni gas e luce']">
                                    <td>
                                        <img src="/assets/img/small_brands/eni.png" height="24px"/>
                                    </td>
                                    <td>{{ me.codice_eni }}</td>
                                </tr>
                                <tr *isVendorEnabled="['Sky']">
                                    <td>
                                        <img src="/assets/img/small_brands/sky.png" height="24px"/>
                                    </td>
                                    <td>{{ me.codice_sky }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-9">
                        <h3 class="display-5">{{ me.ragione_sociale }}</h3>
                        <p class="lead text-muted">{{ me.nome }} {{ me.cognome }}</p>

                        <ul ngbNav #nav="ngbNav" class="nav-tabs mt-3">
                            <li ngbNavItem>
                                <a ngbNavLink
                                ><i class="fas fa-user-cog mr-2"></i> Anagrafica</a
                                >
                                <ng-template ngbNavContent>
                                    <p class="lead mb-0 font-weight-normal">ID</p>
                                    <p class="text-muted mb-0">{{profile.company_prefix}}-{{ this.profile.id }}</p>
                                    <hr/>
                                    <p class="lead mb-0 font-weight-normal">Partita IVA</p>
                                    <p class="text-muted mb-0">{{ this.profile.partita_iva }}</p>
                                    <hr/>
                                    <p class="lead mb-0 font-weight-normal">E-Mail</p>
                                    <p
                                            class="text-muted mb-0 editable"
                                            (click)="
                      editProfileField('E-Mail', 'email1', this.profile.email, [
                        requiredValidator(),
                        emailValidator()
                      ])
                    "
                                    >
                                        {{ this.profile.email }} <i class="far fa-edit"></i>
                                    </p>
                                    <hr/>
                                    <p class="lead mb-0 font-weight-normal">
                                        Contatti telefonici
                                    </p>
                                    <p
                                            class="text-muted mb-0 editable"
                                            (click)="
                      editProfileField(
                        'Contatti telefonici',
                        'phone',
                        this.profile.telefono,
                        [requiredValidator(), phoneValidator()]
                      )
                    "
                                    >
                                        {{ this.profile.telefono }} <i class="far fa-edit"></i>
                                    </p>
                                    <hr/>
                                    <p class="lead mb-0 font-weight-normal">IBAN</p>
                                    <p
                                            class="text-muted mb-0 editable"
                                            (click)="
                      editProfileField('IBAN', 'iban', this.profile.iban, [
                        requiredValidator()
                      ])
                    "
                                    >
                                        {{ this.profile.iban }} <i class="far fa-edit"></i>
                                    </p>
                                    <hr/>
                                    <p class="lead mb-0 font-weight-normal">Codice Fiscale</p>
                                    <p
                                            class="text-muted mb-0 editable"
                                            (click)="
                      editProfileField(
                        'Codice Fiscale',
                        'codfiscale',
                        this.profile.codfiscale,
                        [requiredValidator()]
                      )
                    "
                                    >
                                        {{ this.profile.codfiscale }} <i class="far fa-edit"></i>
                                    </p>
                                    <hr>
                                    <p class="lead mb-0 font-weight-normal">Posizione del negozio</p>
                                    <p
                                            class="text-muted mb-0 editable"
                                            (click)="
                      editProfileField(
                        'Posizione del negozio',
                        'position',
                        this.profile.position,
                        [requiredValidator()],
                        ['Centrale', 'Periferica', 'Semi-periferica']
                      )
                    "
                                    >
                                        {{ this.profile.position }}
                                        <i class="far fa-edit"></i>
                                    </p>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink
                                ><i class="fas fa-map-marker-alt mr-2"></i> Indirizzi</a
                                >
                                <ng-template ngbNavContent>
                                    <p class="lead mb-0 font-weight-normal">Sede Legale</p>
                                    <p
                                            class="text-muted mb-0 editable"
                                            (click)="
                      editProfileField(
                        ['Via', 'Civico', 'Città', 'Provincia', 'CAP'],
                        [
                          'sede_indirizzo',
                          'sede_civico',
                          'sede_comune',
                          'sede_provincia',
                          'sede_cap'
                        ],
                        [
                          this.profile.sede_indirizzo,
                          this.profile.sede_civico,
                          this.profile.sede_comune,
                          this.profile.sede_provincia,
                          this.profile.sede_cap
                        ],
                        [
                          [requiredValidator()],
                          [requiredValidator()],
                          [requiredValidator()],
                          [requiredValidator()],
                          [requiredValidator(), capValidator()]
                        ]
                      )
                    "
                                    >
                                        {{ this.profile.sede_indirizzo }},
                                        {{ this.profile.sede_civico }} -
                                        {{ this.profile.sede_comune }} ,
                                        {{ this.profile.sede_provincia }} -
                                        {{ this.profile.sede_cap }} <i class="far fa-edit"></i>
                                    </p>
                                    <hr/>

                                    <p class="lead mb-0 font-weight-normal">
                                        Indirizzo di spedizione
                                    </p>
                                    <p
                                            class="text-muted mb-0 editable"
                                            (click)="
                      editProfileField(
                        ['Via', 'Civico', 'Città', 'Provincia', 'CAP'],
                        [
                          'sede_indirizzo',
                          'sede_civico',
                          'sede_comune',
                          'sede_provincia',
                          'sede_cap'
                        ],
                        [
                          this.profile.spedizione_indirizzo,
                          this.profile.spedizione_civico,
                          this.profile.spedizione_comune,
                          this.profile.spedizione_provincia,
                          this.profile.spedizione_cap
                        ],
                        [
                          [requiredValidator()],
                          [requiredValidator()],
                          [requiredValidator()],
                          [requiredValidator()],
                          [requiredValidator(), capValidator()]
                        ]
                      )
                    "
                                    >
                                        {{ this.profile.spedizione_indirizzo }},
                                        {{ this.profile.spedizione_civico }} -
                                        {{ this.profile.spedizione_comune }},
                                        {{ this.profile.spedizione_provincia }} -
                                        {{ this.profile.spedizione_cap }}
                                        <i class="far fa-edit"></i>
                                    </p>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink
                                ><i class="fas fa-file-invoice mr-2"></i> Fatturazione
                                    Elettronica</a
                                >
                                <ng-template ngbNavContent>
                                    <p class="lead mb-0 font-weight-normal">Indirizzo PEC</p>
                                    <p
                                            class="text-muted mb-0 editable"
                                            (click)="
                      editProfileField(
                        'Indirizzo PEC',
                        'pec',
                        this.profile.pec,
                        [requiredValidator(), emailValidator()]
                      )
                    "
                                    >
                                        {{ this.profile.pec }} <i class="far fa-edit"></i>
                                    </p>
                                    <hr/>
                                    <p class="lead mb-0 font-weight-normal">Codice SDI</p>
                                    <p
                                            class="text-muted mb-0 editable"
                                            (click)="
                      editProfileField(
                        'Codice SDI',
                        'codice_sdi',
                        this.profile.codice_sdi,
                        [requiredValidator()]
                      )
                    "
                                    >
                                        {{ this.profile.codice_sdi }} <i class="far fa-edit"></i>
                                    </p>
                                    <hr/>
                                    <p class="lead mb-0 font-weight-normal">Causale Ritenuta</p>
                                    <p
                                            class="text-muted mb-0 editable"
                                            (click)="
                      editProfileField(
                        'Causale Ritenuta',
                        'causale_ritenuta',
                        this.profile.causale_ritenuta,
                        [requiredValidator()],
                        this.options.causaleRitenuta
                      )
                    "
                                    >
                                        {{ this.profile.causale_ritenuta }}
                                        <i class="far fa-edit"></i>
                                    </p>
                                    <hr/>
                                    <p class="lead mb-0 font-weight-normal">Regime Fiscale</p>
                                    <p
                                            class="text-muted mb-0 editable"
                                            (click)="
                      editProfileField(
                        'Regime Fiscale',
                        'regime_fiscale',
                        this.profile.regime_fiscale,
                        [requiredValidator()],
                        this.options.regimeFiscale
                      )
                    "
                                    >
                                        {{ this.profile.regime_fiscale }}
                                        <i class="far fa-edit"></i>
                                    </p>
                                    <hr/>
                                    <p class="lead mb-0 font-weight-normal">Tipo Ritenuta</p>
                                    <p
                                            class="text-muted mb-0 editable"
                                            (click)="
                      editProfileField(
                        'Tipo Ritenuta',
                        'tipo_ritenuta',
                        this.profile.tipo_ritenuta,
                        [requiredValidator()],
                        this.options.tipoRitenuta
                      )
                    "
                                    >
                                        {{ this.profile.tipo_ritenuta }} <i class="far fa-edit"></i>
                                    </p>
                                    <hr/>
                                    <p class="lead mb-0 font-weight-normal">Tipologia Società</p>
                                    <p
                                            class="text-muted mb-0 editable"
                                            (click)="
                      editProfileField(
                        'Tipologia Società',
                        'tipologia_societa',
                        this.profile.tipologia_societa,
                        [requiredValidator()],
                        this.options.tipoSocieta
                      )
                    "
                                    >
                                        {{ this.profile.tipologia_societa }}
                                        <i class="far fa-edit"></i>
                                    </p>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink
                                ><i class="fas fa-camera-retro mr-2"></i> Foto Negozio</a
                                >
                                <ng-template ngbNavContent>
                                    <p class="lead mb-0 font-weight-normal">Foto degli interni</p>
                                    <div class="custom-file mt-2">
                                        <input
                                                type="file"
                                                class="custom-file-input"
                                                id="validatedCustomFile"
                                                (change)="
                        handleFileInput($event.target.files, 'inside_pic_file')
                      "
                                        />
                                        <label
                                                class="custom-file-label"
                                                for="validatedCustomFile"
                                        >{{ fileName["inside_pic_file"] }}</label
                                        >
                                    </div>
                                    <hr/>
                                    <p class="lead mb-0 font-weight-normal">
                                        Altra foto degli interni
                                    </p>
                                    <div class="custom-file mt-2">
                                        <input
                                                type="file"
                                                class="custom-file-input"
                                                id="validatedCustomFile"
                                                (change)="
                        handleFileInput(
                          $event.target.files,
                          'inside_pic_2_file'
                        )
                      "
                                        />
                                        <label
                                                class="custom-file-label"
                                                for="validatedCustomFile"
                                        >{{ fileName["inside_pic_2_file"] }}</label
                                        >
                                    </div>
                                    <hr/>
                                    <p class="lead mb-0 font-weight-normal">Foto da strada</p>
                                    <div class="custom-file mt-2">
                                        <input
                                                type="file"
                                                class="custom-file-input"
                                                id="validatedCustomFile"
                                                (change)="
                        handleFileInput($event.target.files, 'outside_pic_file')
                      "
                                        />
                                        <label
                                                class="custom-file-label"
                                                for="validatedCustomFile"
                                        >{{ fileName["outside_pic_file"] }}</label
                                        >
                                    </div>
                                    <hr/>
                                    <div class="row">
                                        <div class="col-4">
                                            <p class="text-muted">Foto degli interni</p>
                                            <img *ngIf="me.inside_pic" [src]="me.inside_pic" class="img-fluid rounded"/>
                                        </div>
                                        <div class="col-4">
                                            <p class="text-muted">Altra foto degli interni</p>
                                            <img *ngIf="me.inside_pic_2" [src]="me.inside_pic_2"
                                                 class="img-fluid rounded"/>
                                        </div>
                                        <div class="col-4">
                                            <p class="text-muted">Foto da strada</p>
                                            <img *ngIf="me.outside_pic" [src]="me.outside_pic"
                                                 class="img-fluid rounded"/>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </div>
            </div>
            <!-- /.card-body -->
        </div>
    </div>
</div>

<app-dialog-field-edit
        (onSave)="onFieldChanged($event)"
></app-dialog-field-edit>
<app-ticket-modal></app-ticket-modal>
