import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Cart} from '@models/shop/cart';
import {Product} from '@models/product';
import {DataService} from '@services/data.service';
import {Tax} from '@models/tax';
import {CartTax} from '@models/shop/cart-tax';
import {NavigationEnd, Router} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class CartService {
    public currentCartSubject: BehaviorSubject<Cart>;
    public currentCart: Observable<Cart>;
    private taxes: Tax[] = [];
    private payment: string = null;
    public paymentType: BehaviorSubject<any>;
    private busy = false;

    constructor(private dataService: DataService, private router: Router) {
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                const cart = new Cart().fromJSON(
                    JSON.parse(localStorage.getItem('currentCart'))
                );
                cart.vat = null;
                cart.payment = null;
                this.currentCartSubject = new BehaviorSubject<Cart>(cart);
                this.currentCart = this.currentCartSubject.asObservable();
            }
        });
    }

    public get cart(): Cart {
        return this.applyTaxes(this.currentCartSubject.value);
    }

    get isBusy() {
        return this.busy;
    }

    public get onlyVirtualProducts() {
        let virtualProducts = this.cart.items.filter(item => item.product['speciale'].toString() === '1').length;
        let totalProducts = this.cart.items.length;

        return virtualProducts === totalProducts;
    }

    getProduct(product: Product) {
        return this.cart.items.find((item) => item.id === product.id);
    }

    get paymentSet() {
        return this.payment !== null;
    }

    applyTaxes(cart: Cart) {
        cart.taxes = [];
        if (this.paymentSet) {
            for (const tax of this.taxes) {
                let skipTax = false;
                let skipShipping = false;
                if (tax.id === '1' && this.payment) {
                    for (const item of cart.items) {
                        if (
                            item.product.p_free_shipping_payment &&
                            item.product.p_free_shipping_payment.indexOf(this.payment) > -1
                        ) {
                            if (
                                parseInt(item.product.p_min_qty_free_ship, 10) <= item.quantity
                            ) {
                                skipTax = true;
                            }
                        }
                    }
                }
                if (cart.items.length > 0) {
                    if (
                        cart.items.filter(
                            (item) =>
                                item.product.productcategory === 'Plafond' ||
                                item.product.productcategory === 'SMS'
                        ).length === cart.items.length
                    ) {
                        skipTax = true;
                    }
                }

                if (!skipTax) {
                    const cartTax = new CartTax();
                    cartTax.tax = tax;
                    cartTax.id = tax.id;
                    cartTax.value = parseFloat(tax.value);

                    if (this.payment && tax.matches.length > 0) {
                        const matchFound = tax.matches.find(
                            (item) => item.name === this.payment
                        );

                        if (matchFound) {
                            cartTax.value = parseFloat(matchFound.value);
                        }
                    }
                    cart.taxes.push(cartTax);
                }
                let vatSum = 0;
                const vatFound = true;
                if (vatFound) {
                    vatSum +=
                        (cart.items
                                .filter((item) => item.product.iva.toString() === '1')
                                .reduce((prev, next) => {
                                    return (
                                        prev + next.quantity * parseFloat(
                                            cart.payment === 2 && next.product?.prezzoacquisto ? next.product?.prezzoacquisto :
                                                next.product.unit_price
                                        )
                                    );
                                }, 0) *
                            22) /
                        100;
                }
                cart.vat = vatSum;
            }
        }
        return cart;
    }

    get cartGrandTotal() {
        let productTotal = this.cart.items.reduce((prev, next) => {
            const price = this.cart.payment === 2 && next.product?.prezzoacquisto
                ? next.product.prezzoacquisto : next.product.unit_price;
            return prev + next.quantity * parseFloat(price);
        }, 0);

        for (const tax of this.cart.taxes) {
            productTotal += tax.value;
        }

        productTotal += this.cart.vat;

        return productTotal;
    }

    setCart(cart) {
        localStorage.setItem('currentCart', JSON.stringify(cart));
        if (cart instanceof Cart) {
            this.currentCartSubject.next(cart);
        }
    }

    addToCart(product, subProducts = []) {
        const cart: Cart = this.cart;
        cart.addProduct(product, subProducts);
        this.setCart(cart);
    }

    emptyCart() {
        const cart: Cart = new Cart();
        cart.vat = 0;
        cart.taxes = [];
        this.setCart(cart);
    }

    removeFromCart(product: Product) {
        const cart: Cart = this.cart;
        cart.removeProduct(product);
        this.setCart(cart);
    }

    protected get jsonItems() {
        const array = [];

        for (const item of this.cart.items) {
            array.push({
                id: item.id,
                qty: item.quantity,
                price: parseFloat(item.product.unit_price),
                variant: item?.product?.variant
            });

            for (const subitem of item.subProducts) {
                array.push({
                    id: subitem.id,
                    parent_id: item.id,
                    qty: parseFloat(String(subitem.quantity)),
                    price: 0,
                    comment: item.product.productname,
                    total_price: item.product?.price,
                    product_variant_id: item.product?.variant ? item?.product?.variant?.id : item.product?.id
                });
            }
        }

        return array;
    }

    protected get jsonTaxes() {
        const array = [];

        for (const item of this.cart.taxes) {
            array.push({
                id: item.id,
                name: item.tax.name,
                value: item.value,
            });
        }

        return array;
    }

    buy(
        shipping,
        payment,
        idIndirizzo,
        compensazioneParziale,
        description,
        idDealer = null
    ) {
        this.busy = true;
        const templates = this.getTemplates();
        const formData = new FormData();
        for (let i = 0; i < templates.length; i++) {
            for (const key of Object.keys(templates[i])) {
                formData.append('templates[' + i + ']' + '[' + key + ']', templates[i][key]);
            }
        }
        formData.append('items', JSON.stringify(this.jsonItems));
        formData.append('shipping', JSON.stringify(shipping));
        formData.append('payment', payment);
        formData.append('id_indirizzo', idIndirizzo);
        formData.append('description', description);
        formData.append('compensazione_parziale', compensazioneParziale);
        formData.append('vat', String(this.cart.vat));
        formData.append('taxes', JSON.stringify(this.jsonTaxes));

        if (idDealer) {
            formData.append('id_dealer', idDealer);
        }

        this.dataService
            .post('/inserisci-ordine', formData)
            .subscribe((data: any) => {
                if (data.success) {
                    this.emptyCart();
                    this.busy = false;
                    if (data.result.code === 1) {
                        this.router.navigate(['/negozio/dettaglio/', data.result.id]);
                    } else if (data.result.code === 2) {
                        window.location.href =
                            'https://api.master-attivazioni.it/shop-orders/pos/' +
                            data.result.id;
                        // this.router.navigate(['/negozio/pos/', data.result.id]);
                    } else {
                        // this.router.navigate(['/negozio/dettaglio/', data.result.id]);
                    }
                }
            });
    }

    getTemplates() {
        const list = [];
        this.cart.items.forEach(item => {
            if (item?.product?.template) {
                item.product.template['product_variant_id'] = item.product?.variant ? item.product.variant.id : item.product?.id;
                if (item.product.template?.file) {
                    const base64 = item.product.template.file;
                    const base64Parts = base64.split(',');
                    const fileFormat = base64Parts[0].match(/:(.*?);/)[1];
                    const bstr = atob(base64Parts[1]);
                    let n = bstr.length;
                    const u8arr = new Uint8Array(n);
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n);
                    }
                    item.product.template.file = new File([u8arr], item?.product?.template?.fileName, {type: fileFormat});
                }
                list.push(item.product.template);
            }

        });
        return list;
    }

    setTaxes(taxes: Tax[]) {
        this.taxes = taxes;
        const cart = this.cart;
        cart.payment = this.payment;
        this.setCart(cart);
    }

    setPayment(val: any) {
        this.payment = val;
    }
}
