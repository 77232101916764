<div class="modal-header">
    <h4 class="modal-title clamp-title" translate>{{selectedDocument ? 'Change Document' : 'Add Document'}}</h4>
    <button (click)="dismissModal()"
            type="button"
            class="close"
            aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [ngBusy]="busy">
    <form [formGroup]="documentForm" (ngSubmit)="saveDocument()">
        <div class="form-row">
            <div class="col-12 col-lg-4 form-group">
                <label for="subject" translate>Subject</label>
                <ng-select formControlName="subject_id" [items]="subjects" bindLabel="company_name" id="subject"
                           bindValue="id" [searchable]="true" (search)="loadSubjects($event?.term)"></ng-select>
                <val-errors controlName="subject_id">
                    <ng-template valError="required">
                        <span translate>Subject is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="category" translate>Category</label>
                <input type="text" class="form-control" id="category" formControlName="category">
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="type" translate>Type</label>
                <ng-select formControlName="type" [items]="types" bindLabel="name" id="type"
                           bindValue="key"></ng-select>
                <val-errors controlName="type">
                    <ng-template valError="required">
                        <span translate>Type is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="vatType" translate>VAT type</label>
                <ng-select formControlName="vat_type" id="vatType">
                    <ng-option translate *ngFor="let vatType of vatTypes"
                               [value]="vatType.key">{{vatType.name}}</ng-option>
                </ng-select>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="documentNumber" translate>Document number</label>
                <input type="text" class="form-control" id="documentNumber" formControlName="number">
                <val-errors controlName="number">
                    <ng-template valError="required">
                        <span translate>Document number is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="vatDate" translate>VAT date</label>
                <input type="text" class="form-control" id="vatDate" daterangepicker [options]="pickerOptions"
                       (selected)="selectedDate('vat_date',$event.start)"
                       formControlName="vat_date">
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="date" translate>Date</label>
                <input type="text" class="form-control" id="date" daterangepicker [options]="pickerOptions"
                       (selected)="selectedDate('doc_date',$event.start)"
                       formControlName="doc_date">
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="netAmount" translate>Net amount</label>
                <div class="input-group">
                    <input type="text" class="form-control" id="netAmount" aria-describedby="basic-addon"
                           formControlName="net_amount">
                    <div class="input-group-append">
                        <div class="input-group-text bg-white" id="basic-addon"><strong>€</strong></div>
                    </div>
                </div>
                <val-errors controlName="net_amount">
                    <ng-template valError="required">
                        <span translate>Net amount is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="vatAmount" translate>VAT amount</label>
                <div class="input-group">
                    <input type="text" class="form-control" id="vatAmount" aria-describedby="basic-addon-vat"
                           formControlName="vat_amount">
                    <div class="input-group-append">
                        <div class="input-group-text bg-white" id="basic-addon-vat"><strong>€</strong></div>
                    </div>
                </div>
                <val-errors controlName="vat_amount">
                    <ng-template valError="required">
                        <span translate>VAT amount is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="withholdingTax" translate>Withholding tax</label>
                <div class="input-group">
                    <input type="text" class="form-control" id="withholdingTax" aria-describedby="basic-addon-tax"
                           formControlName="withholding_tax">
                    <div class="input-group-append">
                        <div class="input-group-text bg-white" id="basic-addon-tax"><strong>€</strong></div>
                    </div>
                </div>
                <val-errors controlName="withholding_tax">
                </val-errors>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="pdfFile" translate>Invoice in pdf</label>
                <div class="custom-file" *ngIf="!pdfFile">
                    <input id="pdfFile" type="file" class="custom-file-input"
                           (change)="handleFileInput($event?.target?.files[0],'pdf')">
                    <label class="custom-file-label" for="pdfFile" translate>
                        Upload file...
                    </label>
                </div>
                <div *ngIf="pdfFile">
                    <span class="text-break">{{pdfFile}}</span>
                    <button class="btn btn-xs btn-warning float-right" type="button"
                            (click)="handleFileRemoveModal('pdf')">
                        <i class="fa fa-times m-0 px-1"></i>
                    </button>
                </div>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label for="xmlFile" translate>Invoice in xml</label>
                <div class="custom-file" *ngIf="!xmlFile">
                    <input id="xmlFile" type="file" class="custom-file-input"
                           (change)="handleFileInput($event?.target?.files[0],'xml')">
                    <label class="custom-file-label" for="xmlFile" translate>
                        Upload file...
                    </label>
                </div>
                <div *ngIf="xmlFile">
                    <span class="text-break">{{xmlFile}}</span>
                    <button class="btn btn-xs btn-warning float-right" type="button"
                            (click)="handleFileRemoveModal('pdf')">
                        <i class="fa fa-times m-0 px-1"></i>
                    </button>
                </div>
            </div>
            <div class="col-6 col-lg-2 form-group d-flex align-items-center">
                <div class="custom-control custom-switch mt-2">
                    <input type="checkbox" class="custom-control-input" id="isCredit" formControlName="is_credit">
                    <label class="custom-control-label" for="isCredit" translate>Credit/Debit</label>
                </div>
            </div>
            <div class="col-6 col-lg-2 form-group d-flex align-items-center">
                <div class="custom-control custom-switch mt-2">
                    <input type="checkbox" class="custom-control-input" id="draft" formControlName="draft">
                    <label class="custom-control-label" for="draft" translate>Draft</label>
                </div>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label class="mt-2">
                    <span translate>Total amount</span>
                    <span class="float-right">{{totalAmount|currency:currency}}</span>
                </label>
            </div>
            <div class="col-12 col-lg-4 form-group">
                <label class="mt-2">
                    <span translate>Total to pay</span>
                    <span class="float-right">{{totalToPay|currency:currency}}</span>
                </label>
            </div>
            <div class="col-12 form-group">
                <label for="categoryDescription" translate>Note</label>
                <editor apiKey="1nilu7q8vcp0ntuviwq5nhcskmj17c6awfhpbnr0hhv4smdw" id="categoryDescription" [init]="defaultConfig" formControlName="note"></editor>
            </div>
            <div class="col-12 col-lg-8 form-group">
                <h5 translate>Payments:</h5>
            </div>
            <div class="col-12 col-lg-4 form-group d-flex align-items-end">
                <button type="button" class="btn btn-secondary btn-block" translate (click)="showPaymentModal()">
                    Add Payment
                </button>
            </div>
            <div class="col-12 form-group">
                <table class="table table-sm">
                    <thead>
                    <tr>
                        <th>Due date</th>
                        <th>Due method</th>
                        <th class="text-right">Amount</th>
                        <th class="text-center">Welded</th>
                        <th class="text-right"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let payment of payments;index as i">
                        <td>{{payment?.due_date|amDateFormat:localFormat}}</td>
                        <td>{{findMethod(payment?.due_method)}}</td>
                        <td class="text-right">{{payment?.amount|currency:currency}}</td>
                        <td class="text-center">
                            <i class="text-success fas fa-check" *ngIf="payment?.welded==1"></i>
                            <i class="text-danger fas fa-times" *ngIf="payment?.welded==0"></i>
                        </td>
                        <td class="text-right">
                            <button class="btn btn-link" (click)="showPaymentModal(payment,i)" type="button">
                                <i class=" fa fa-edit text-muted"></i>
                            </button>
                            <button class="btn btn-link" (click)="removePayment(i)" type="button">
                                <i class="fa fa-trash text-danger"></i>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="payments?.length<=0">
                        <td translate colspan="5">List is empty</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12">
                <button type="submit" class="btn btn-success btn-block" [disabled]="documentForm.invalid">
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                </button>
            </div>
        </div>
    </form>
</div>

<ng-template #paymentFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Add Payment</h4>
        <button (click)="modal.close('Ok click')"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-payment-modal (paymentChange)="savePayment($event)" [selectedPayment]="selectedPayment"
                       [totalToPay]="totalToPayPayment"></app-payment-modal>
</ng-template>
