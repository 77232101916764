import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';

export class StoreProduct extends BaseModel {
    id: number;
    personalizzabile: string;
    nome: string;
    serial: string;
    id_categoria: number;
    immagine_path: string;
    id_operatore: number;
    id_seriale_tipo: number;
    tipo_estratto_conto: number;
    prezzo: any;
    prezzoacquisto: any;
    compenso: number;
    min_quantita_free_sped: number;
    qty_per_unit: number;
    quantita: number;
    iva: any;
    speciale: any;
    spedizione: any;
    visibile: any;
    descrizione: string;
    file: File;
    id_brand: number;
    category: string;
    brand: string;
    serial_type: string;
    sub_products: SubProduct[];
    sub_product_ids: Array<any>;
    informazioni: any;
    variants: StoreProductVariant[];
    templates: StoreProductTemplate[];
    payment_types: Array<any>;

    fromArray(result: []) {
        const array: StoreProduct[] = [];
        for (const item of result) {
            array.push(new StoreProduct(item));
        }
        return array;
    }

    get postPayloadProduct() {
        const payload = new FormData();
        this.addToPayload(payload, this.personalizzabile, 'personalizzabile');
        this.addToPayload(payload, this.nome, 'nome');
        this.addToPayload(payload, this.serial, 'serial');
        this.addToPayload(payload, this.id_categoria, 'id_categoria');
        this.addToPayload(payload, this.immagine_path, 'immagine_path');
        if (this.id_operatore) {
            this.addToPayload(payload, this.id_operatore, 'id_operatore');
        }
        if (this.id_seriale_tipo) {
            this.addToPayload(payload, this.id_seriale_tipo, 'id_seriale_tipo');
        }
        if (this.tipo_estratto_conto) {
            this.addToPayload(payload, this.tipo_estratto_conto, 'tipo_estratto_conto');
        }
        this.addToPayload(payload, this.prezzo, 'prezzo');
        this.addToPayload(payload, this.prezzoacquisto, 'prezzoacquisto');
        if (this.compenso) {
            this.addToPayload(payload, this.compenso, 'compenso');
        }
        if (this.min_quantita_free_sped) {
            this.addToPayload(payload, this.min_quantita_free_sped, 'min_quantita_free_sped');
        }
        this.addToPayload(payload, this.qty_per_unit, 'qty_per_unit');
        this.addToPayload(payload, this.quantita, 'quantita');
        this.addToPayload(payload, this.descrizione, 'descrizione');
        this.addToPayload(payload, this.file, 'file');
        this.addToPayload(payload, this.visibile, 'visibile');
        this.addToPayload(payload, this.iva, 'iva');
        this.addToPayload(payload, this.speciale, 'speciale');
        this.addToPayload(payload, this.spedizione, 'spedizione');
        if (this.sub_product_ids) {
            this.addToPayload(payload, this.sub_product_ids, 'sub_product_ids');
        }
        this.addToPayload(payload, this.id_brand, 'id_brand');
        if (this.informazioni) {
            this.addToPayload(payload, this.informazioni, 'informazioni');
        }
        this.addToPayload(payload, this.variants, 'variants');
        this.addToPayload(payload, this.payment_types, 'payment_types');
        return payload;
    }
}

export class StoreProductVariant {
    id: number;
    product_id: number;
    name: string;
    quantity: number;
    price: string;
    vat_price: string;
    description: string;
    total_price: any;
}

export class StoreProductTemplate extends BaseModel {
    id: number;
    id_prodotto: number;
    nome: string;
    template: number;
    info: string;
    sconto: any;
    file: File;

    fromArray(result: []) {
        const array: StoreProductTemplate[] = [];
        for (const item of result) {
            array.push(new StoreProductTemplate(item));
        }
        return array;
    }

    get postPayloadTemplate() {
        const payload = new FormData();
        this.addToPayload(payload, this.nome, 'nome');
        this.addToPayload(payload, this.file, 'file');
        this.addToPayload(payload, this.info, 'info');
        this.addToPayload(payload, this.sconto, 'sconto');
        this.addToPayload(payload, this.id_prodotto, 'id_prodotto');
        this.addToPayload(payload, this.template, 'template');
        return payload;
    }
}

export class StorePaymentType {
    id: number;
    product_id: number;
    name: string;
    is_active: number;
}

export class StoreProductPagination extends BaseModel {
    data: StoreProduct[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class ProductSerialTypePagination extends BaseModel {
    data: ProductSerialType[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class ProductSerialType extends BaseModel {
    id: number;
    campo: string;
    obbligatorio: number;
    nome: string;
    tipo_seriale: string;
    created: string;
    visible: number;
}

export class SubProduct extends BaseModel {
    id: number;
    id_prodotto: number;
    id_subprodotto: number;
    product: {
        id: number,
        nome: string
    };
}

export class StoreBrand extends BaseModel {
    id: number;
    id_company: number;
    nome: string;
}

export class StoreBrandPagination extends BaseModel {
    data: StoreBrand[];
    _meta: MetaPagination;
    _links: Array<any>;
}
