/* tslint:disable:variable-name */
import {SubProduct} from '@models/sub-product';
import {StorePaymentType, StoreProductTemplate, StoreProductVariant} from '@models/store/store-product';
import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';
import {BankTransaction} from '@models/invoice/bank-transaction';

export class InstallmentsPagination extends BaseModel {
    data: ProductInstallment[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class ProductInstallment extends BaseModel {
    product_name: string;
    nmu: string;
    pap: number;
    type: string;
    fin_code: string;
    fin_type: string;
    fin_payment: string;
    entry_ticket: number;
    installments: number;
    last_installment: number;
    discount_value: number;
    discount: number;
    stocks: number;
}

export class InstallmentType extends BaseModel {
    name: string;
}

export class InstallmentFile extends BaseModel {
    file: File;

    get postPayloadExcelFile() {
        const payload = new FormData();
        this.addToPayload(payload, this.file, 'file');
        return payload;
    }
}

export class Product {
    createdtime: string;
    description: string;
    id: number;
    imagename: string;
    productcategory: string;
    productname: string;
    qty_per_unit: string;
    qtyinstock: string;
    source: string;
    unit_price: string;
    prezzoacquisto: string;
    usageunit: string;
    sub_products: SubProduct[] = [];
    p_free_shipping_payment: string;
    p_min_qty_free_ship: string;
    vendor_id_meta: string;
    min_quantita_free_sped: string;
    iva: string;
    personalizzabile: number;
    personalizzati: [{
        id: number;
        info: string;
        is_master: boolean;
        nome: string;
        sconto: number;
        template: string;
    }];
    preset_qnt: [];
    offerte: [{ nome: string; descrizione: string; }];
    tags: [{ key: string; value: string; }];
    fasce_di_prezzo: [
        {
            id: number;
            from_qnt: number;
            to_qnt: number;
            prezzo: number;
            prezzo_unitario: number;
        }
    ];
    templates: StoreProductTemplate[];
    variants: StoreProductVariant[];
    variant: StoreProductVariant;
    template: any;
    payment_types: StorePaymentType[];

    get hasTags() {
        return Object.keys(this.tags).length > 0;
    }

    get free_shipping_payments(): string[] {
        return this.p_free_shipping_payment.split(' |##| ');
    }

    get free_shipping_min_qty(): number {
        return parseInt(this.min_quantita_free_sped, 10);
    }

    get qty_per_unit_n(): number {
        return parseInt(this.qty_per_unit, 10);
    }

    get price() {
        return parseFloat(this.unit_price);
    }

    get priceEuro() {
        return this.unit_price.split('.')[0];
    }

    get priceCent() {
        return parseFloat(this.unit_price.split('.')[1]).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
    }

    get stock() {
        return parseInt(this.qtyinstock, 10);
    }

    get htmlStock() {
        return this.stock > 10 ? '+10' : this.stock;
    }

    get image() {
        if (this.imagename === '') {
            return '/assets/img/svgs/undraw_collecting_fjjl.svg';
        }
        return this.imagename;
        // return AppConfig.apiUrl + '/shop/image/' + this.id.split('x')[1];
    }

    fromArray(result: []) {
        const array: Product[] = [];
        for (const item of result) {
            const tempItem: Product = new Product();
            Object.assign(tempItem, item);
            tempItem.sub_products = new SubProduct().fromArray((item as any).sub_products);
            array.push(tempItem);
        }
        return array;
    }
}
