/* tslint:disable:variable-name */
export class ContractSkeleton {
  id: number;
  brand: string;
  name: string;
  piste: PisteSkeleton[];
  seriali: Seriali[];
  pagamenti: CampiPagamentoSkeleton[];
  operatori_mnp: OperatoriMnpSkeleton[];

  fromArray(result: []) {
    const array: ContractSkeleton[] = [];
    for (const item of result) {
      const tempItem: ContractSkeleton = new ContractSkeleton();
      Object.assign(tempItem, item);
      array.push(tempItem);
    }
    return array;
  }
}

export class Venditori {
  id: number;
  ragsociale: string;
}

export class PisteSkeleton {
  id: number;
  nome: string;
  famiglie: FamiglieSkeleton[];
}

export class FamiglieSkeleton {
  id: number;
  nome: string;
  sottofamiglie: SottofamiglieSkeleton[];
  business: number;
}

export class SottofamiglieSkeleton {
  id: number;
  nome: string;
  offerte: OfferteSkeleton[];
}

export class InfoOfferteSkeleton {
  testo: string;
}

export class CampiPagamentoSkeleton {
  id: number;
  nome: string;
  lunghezza: number;
  obbligatorio: number;
  id_pagamento: number;
  id_operatore: number;
}

export class PagamentiSkeleton {
  id_offerta: number;
  id_pagamento: number;
  pagamento: string;
  id_operatore: number;
}

export class CampiSkeleton {
  id: number;
  id_offerta: number;
  id_tipo: number;
  nome: string;
  campo: string;
  obbligatorio: number;
  tipo_campo: string;
  validatore: string;
  pattern: string;
  json: string;
  default_value: string;
}

export class SerialiSkeleton {
  id_offerta: number;
  id_tipo: number;
  nome: string;
  campo: string;
  obbligatorio: number;
  tipo_seriale: string;
  visibile: number;
}

export class Seriali {
  id: number;
  seriale: string;
  usato: number;
  id_tipo: number;
  nome: string;
  campo: string;
  obbligatorio: number;
  tipo_seriale: string;
  visibile: number;
}

export class OperatoriMnpSkeleton {
  id: number;
  operator_name: string;
  operator_value: string;
}

export class CostoOfferteSkeleton {
  id: number;
  id_offerta: number;
  tipo_costo: number;
  costo: number;
  titolo: string;
  descrizione: string;
}

export class GruppoCampo {
  nome: string;
  campi: CampiSkeleton[];
  id: number;
}

export class OfferteSkeleton {
  id: number;
  nome: string;
  ricarica_minima: number;
  portabilita: number;
  costi_offerta: CostoOfferteSkeleton[];
  info_offerta: InfoOfferteSkeleton;
  pagamenti: PagamentiSkeleton[];
  campi_personalizzati: CampiSkeleton[];
  seriali_offerte: SerialiSkeleton[];
  gruppi: GruppoCampo[];
}
