import {Injectable} from '@angular/core';
import {RequestService} from '@services/request.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
    Document,
    DocumentChart,
    DocumentDashboard,
    DocumentIncomes,
    DocumentMap,
    DocumentOutcomes,
    DocumentPagination, DocumentPayment, DocumentPaymentPagination,
} from '@models/invoice/document';
import {AlertService} from '@services/alert.service';
import {Subject, SubjectClientPagination, SubjectPagination, SubjectSupplier, SubjectSupplierPagination} from '@models/invoice/subject';
import {BankTransactionPagination, CreditCardPaymentPagination} from '@models/invoice/bank-transaction';
import {BankAccount, BankAccountPagination} from '@models/invoice/bank-account';

const invoice = 'invoice';
const documents = 'documents';
const subjects = 'subjects';
const bankTransaction = 'bank-transaction';
const bankAccount = 'bank-account';

@Injectable({
    providedIn: 'root'
})
export class InvoiceDocumentService {

    constructor(public request: RequestService, private alertService: AlertService) {
    }

    public static currency = '€';
    public static categories = [
        {
            key: '1',
            name: 'Invoice'
        },
        {
            key: '0',
            name: 'Cash flow'
        }
    ];
    public static types = [
        {
            key: 'invoices',
            name: 'Invoices'
        },
        {
            key: 'proforma',
            name: 'Proforma'
        },
        {
            key: 'estimates',
            name: 'Estimates'
        },
        {
            key: 'orders',
            name: 'Orders'
        },
        {
            key: 'ndc',
            name: 'NDC'
        },
        {
            key: 'receipts',
            name: 'Receipts'
        },
        {
            key: 'ddt',
            name: 'DDT'
        },
        {
            key: 'reports',
            name: 'Reports'
        },
        {
            key: 'purchase',
            name: 'Purchase'
        },
    ];
    public static bankAccountTypes = [
        {
            key: 'unicredit',
            name: 'Unicredit'
        },
        {
            key: 'intesa-san-paolo',
            name: 'Intesa San Paolo'
        },
        {
            key: 'fineco',
            name: 'Fineco'
        },
    ];
    public static subjectTypes = [
        {
            key: 'customer',
            name: 'Customer'
        },
        {
            key: 'supplier',
            name: 'Supplier'
        }
    ];
    public static dueMethods = [
        {
            key: 'cash',
            name: 'Cash',
            checked: true
        },
        {
            key: 'bank-payment',
            name: 'Bank payment',
            checked: true
        },
        {
            key: 'compensation',
            name: 'Compensation',
            checked: false
        },
        {
            key: 'credit-card',
            name: 'Credit card',
            checked: true
        },
        {
            key: 'free',
            name: 'Free',
            checked: true
        },
        {
            key: 'cash-on-delivery',
            name: 'Cash on delivery',
            checked: true
        },
        {
            key: 'check-on-delivery',
            name: 'Check on delivery',
            checked: true
        },
    ];
    public static vatTypes = [
        {
            key: 'immediate',
            name: 'Immediate'
        },
        {
            key: 'deferred',
            name: 'Deferred'
        },
        {
            key: 'split-payment',
            name: 'Split Payment'
        },
    ];

    public getDocuments(params?): Observable<DocumentPagination> {
        return this.request.get(invoice + '/' + documents, params).pipe(
            map((response: any) => {
                return new DocumentPagination(response);
            })
        );
    }

    public getSubject(params?): Observable<SubjectPagination> {
        return this.request.get(invoice + '/' + subjects, params).pipe(
            map((response: any) => {
                return new SubjectPagination(response);
            })
        );
    }

    public getDashboardReport(params?): Observable<DocumentDashboard> {
        return this.request.get(invoice + '/' + documents + '/dashboard', params).pipe(
            map((response: any) => {
                return new DocumentDashboard(response);
            })
        );
    }

    public getChartReport(params?): Observable<DocumentChart> {
        return this.request.get(invoice + '/' + documents + '/chart', params).pipe(
            map((response: any) => {
                return new DocumentChart(response);
            })
        );
    }


    public getInvoiceReport(params?): Observable<DocumentIncomes[]> {
        return this.request.get(invoice + '/' + documents + '/invoice', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getCashOutReport(params?): Observable<DocumentOutcomes[]> {
        return this.request.get(invoice + '/' + documents + '/cash-out', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getSubjectClients(params?): Observable<SubjectClientPagination> {
        return this.request.get(invoice + '/' + subjects + '/clients', params).pipe(
            map((response: any) => {
                return new SubjectClientPagination(response);
            })
        );
    }

    public getSubjectSuppliers(params?): Observable<SubjectSupplierPagination> {
        return this.request.get(invoice + '/' + subjects + '/suppliers', params).pipe(
            map((response: any) => {
                return new SubjectSupplierPagination(response);
            })
        );
    }

    public getBankTransactions(params?): Observable<BankTransactionPagination> {
        return this.request.get(invoice + '/' + bankTransaction, params).pipe(
            map((response: any) => {
                return new BankTransactionPagination(response);
            })
        );
    }

    public getBankAccounts(params?): Observable<BankAccountPagination> {
        return this.request.get(invoice + '/' + bankAccount, params).pipe(
            map((response: any) => {
                return new BankAccountPagination(response);
            })
        );
    }

    public getCreditCards(params?): Observable<CreditCardPaymentPagination> {
        return this.request.get(invoice + '/' + bankTransaction + '/credit-card', params).pipe(
            map((response: any) => {
                return new CreditCardPaymentPagination(response);
            })
        );
    }

    public getDocumentPayments(params?): Observable<DocumentPaymentPagination> {
        return this.request.get(invoice + '/' + documents + '/payments', params).pipe(
            map((response: any) => {
                return new DocumentPaymentPagination(response);
            })
        );
    }

    public getMapReport(): Observable<DocumentMap[]> {
        return this.request.get(invoice + '/' + documents + '/map').pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getCalendarPayment(params?): Observable<any> {
        return this.request.get(invoice + '/' + documents + '/calendar', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getDocumentDetail(id, params?): Observable<Document> {
        return this.request.get(invoice + '/' + documents + '/' + id, params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getSubjectDetail(id, params?): Observable<Subject> {
        return this.request.get(invoice + '/' + subjects + '/' + id, params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getPaymentDetail(paymentId): Observable<DocumentPayment> {
        return this.request.get(invoice + '/' + documents + '/payment-view', {id: paymentId}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getTotalCredit(params?): Observable<any> {
        return this.request.get(invoice + '/' + documents + '/total-credit', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getPaymentTotalCredit(params?): Observable<any> {
        return this.request.get(invoice + '/' + documents + '/payment-total-credit', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getDocumentCategories(params?): Observable<any> {
        return this.request.get(invoice + '/' + documents + '/category', params).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public postBankAccount(body: BankAccount) {
        return this.request.post(invoice + '/' + bankAccount, body).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public postBankTransactionFromFile(body) {
        return this.request.post(invoice + '/' + bankTransaction, body.postPayloadExcelFile).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public postCreditCardPaymentsFromFile(body) {
        return this.request.post(invoice + '/' + bankTransaction + '/credit-card', body.postPayloadExcelFile).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public putBankTransaction(id: number, body) {
        return this.request.put(invoice + '/' + bankTransaction + '/' + id, body).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public postDocument(body: Document): Observable<Document> {
        return this.request.post(invoice + '/' + documents, body.postPayloadDocument).pipe(
            map((response: any) => {
                return new Document(response);
            })
        );
    }

    public postSubject(body: Subject): Observable<Subject> {
        return this.request.post(invoice + '/' + subjects, body).pipe(
            map((response: any) => {
                return new Subject(response);
            })
        );
    }

    public putDocument(id: number, body: Document): Observable<Document> {
        return this.request.post(invoice + '/' + documents + '/' + id, body.postPayloadDocument).pipe(
            map((response: any) => {
                return new Document(response);
            })
        );
    }

    public putSubject(id: number, body: Subject): Observable<Subject> {
        return this.request.put(invoice + '/' + subjects + '/' + id, body).pipe(
            map((response: any) => {
                return new Subject(response);
            })
        );
    }

    public putPayment(paymentId: number, body) {
        return this.request.put(invoice + '/' + documents + '/update-payment', body, {id: paymentId}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteDocument(id: number) {
        return this.request.delete(invoice + '/' + documents + '/' + id);
    }

    public deleteSubject(id: number) {
        return this.request.delete(invoice + '/' + subjects + '/' + id);
    }

    public successAlert(text) {
        this.alertService.show(
            '',
            'Successfully ' + text,
            {classname: 'bg-success text-light', delay: 2000}
        );
    }

    public errorAlert(message?) {
        this.alertService.show(
            'An error occurred!',
            message ? message : 'Try again!',
            {classname: 'bg-danger text-light', delay: 2000}
        );
    }
}
