<div class="modal-header" *ngIf="!showCheckout">
    <h4 class="modal-title clamp-title" translate>Mobile Telephony</h4>
    <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="dismissModal()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" *ngIf="!showCheckout" [ngBusy]="busy">
    <div class="row justify-content-between d-flex">
        <div class="col-12">
            <div class="media d-flex align-items-center">
                <div class="logo-img">
                    <img [src]="product.image_path" [alt]="product.image_name">
                </div>
                <div class="media-body ml-2">
                    <h5 class="mt-0">{{product.name}}</h5>
                    <p class="text-muted" [innerHTML]="product.description"></p>
                </div>
            </div>
        </div>
    </div>
    <form [formGroup]="orderForm" (ngSubmit)="showCheckoutModal()">
        <div class="form-row">
            <div class="col-12 form-group" *ngIf="product.type == productTypes[0].key">
                <label for="phone" translate>Phone number</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text bg-white"><strong>+39</strong></div>
                    </div>
                    <input type="text" class="form-control" id="phone" placeholder="000-0000000"
                           formControlName="phone_number"
                           mask="000-0000009">
                </div>
                <val-errors controlName="phone_number">
                    <ng-template valError="required">
                        <span translate>Phone number is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-12 form-group">
                <label translate>Select variant</label>
                <div class="d-flex flex-wrap">
                    <div *ngFor="let variant of product?.variants;index as i" class="mr-2 variation-btn">
                        <input type="radio" class="btn-check" [id]="i" autocomplete="off" name="total_price"
                               [value]="variant.price" formControlName="total_price" (change)="selectedVariant(variant)">
                        <label [id]="(i === 0) ? 'variantStep' : ''" class="btn btn-outline-primary rounded-pill"
                               [for]="i">{{variant.price|currency:currency}}</label>
                    </div>
                    <div *ngIf="product?.variants.length<=0" class="mr-2 variation-btn">
                        <input type="radio" class="btn-check" id="price" autocomplete="off"
                               formControlName="total_price" [value]="product.price" name="total_price">
                        <label class="btn btn-outline-primary rounded-pill"
                               for="price">{{product.price|currency:currency}}</label>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-between submit-btn">
                <button type="button" class="btn btn-light w-45" (click)="dismissModal()">
                    <i class="fa fa-times-circle mr-2"></i> <span translate>Cancel</span>
                </button>
                <button type="submit" id="nextStep" class="btn btn-success w-45" [disabled]="orderForm.invalid">
                    <span translate>Next </span> <i class="fas fa-arrow-right ml-2"></i>
                </button>
            </div>
        </div>
    </form>
</div>
<app-checkout *ngIf="showCheckout" [order]="order" [product]="product"
              [isRechargeProduct]="product.type==productTypes[0].key"
              [authorizationFailed]="!isAuthorize"></app-checkout>
