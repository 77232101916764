/* tslint:disable:variable-name */
import {Document} from '@models/document';

export class EventoPresidiatoPeriodo {
  id: number;
  id_evento: number;
  from_date: string;
  to_date: string;
  mattina: string;
  pomeriggio: string;
}

export class EventoPresidiato {
  id: number;
  nome: string;
  comune: string;
  indirizzo: string;
  provincia: string;
  from_date: string;
  to_date: string;
  evento_tipologia: string;
  evento_allestimenti: string;
  note_allestimento: string;
  costo_totale: number;
  stima_contratti: number;
  file_contratto: string;
  motivo_no_contratto: string;
  id_dealer: number;
  stato: string;
  periodi: EventoPresidiatoPeriodo[];

  fromArray(result: []) {
    const array: EventoPresidiato[] = [];
    for (const item of result) {
      const tempItem: EventoPresidiato = new EventoPresidiato();
      Object.assign(tempItem, item);
      array.push(tempItem);
    }
    return array;
  }

  fromJSON(result: any) {
    const temp = new EventoPresidiato();
    Object.assign(temp, result);
    return temp;
  }
}
