import {Component, Input, OnInit} from '@angular/core';
import {MasterPlanningCalculationService} from '@services/master-planning-calculation.service';


@Component({
    selector: 'app-real',
    templateUrl: './real.component.html',
    styleUrls: ['./real.component.css']
})
export class RealComponent implements OnInit {
    @Input() item;
    @Input() isBreakdown = false;
    @Input() isCurrentMonth = false;
    additionalValue: string;

    constructor(private masterPlanningCalculationService: MasterPlanningCalculationService) {
    }

    ngOnInit(): void {
        this.additionalValue = this.masterPlanningCalculationService.calculateProjectionValue(this.item.real);
    }

    formatNumber(num) {
        return this.masterPlanningCalculationService.formatNumber(num);
    }

    isDisabled() {
        return !(this.item?.plan?.value > 0 && this.isCurrentMonth);
    }
}
