<div class="card bg-gradient-light">
    <form (ngSubmit)="loadInstallments()">
        <div class="card-header">
            <h1 class="card-title" translate>Filters</h1>
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="form-group col-12 col-md-3">
                    <label for="productName" translate>Product</label>
                    <input id="productName" name="product_name" [(ngModel)]="filter.product_name"
                           type="text"
                           class="form-control form-control-sm"
                    />
                </div>
                <div class="form-group col-12 col-md-3">
                    <label for="nmu" translate>NMU</label>
                    <input id="nmu" name="nmu" [(ngModel)]="filter.nmu"
                           type="text"
                           class="form-control form-control-sm"
                    />
                </div>
                <div class="form-group col-12 col-lg-3">
                    <label for="type" translate>Type</label>
                    <ng-select [(ngModel)]="filter.type" [items]="types" bindLabel="name" id="type"
                               [loading]="typeLoading" class="form-control-sm"
                               bindValue="name" [searchable]="true" name="type"
                               (search)="loadTypes($event?.term)"></ng-select>
                </div>
                <div class="form-group col-12 col-lg-3">
                    <label for="fin_code" translate>Codice Finanziamento</label>
                    <input id="fin_code" name="fin_code" [(ngModel)]="filter.fin_code"
                           type="text"
                           class="form-control form-control-sm"
                    />
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button class="btn btn-dark btn-sm mr-2" type="button" (click)="resetFilter()" translate>
                Reset
            </button>
            <button
                    class="btn btn-primary btn-sm"
                    type="submit" translate
            >
                Filter
            </button>
        </div>
    </form>
</div>

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-lg-10 col-12">
                        <h4 translate>Installments List</h4>
                    </div>
                    <div class="col-lg-2 col-6">
                        <button class="btn btn-success btn-sm float-lg-right" type="button" translate
                                (click)="showModal()" *show="['operator', 'master']">
                            Upload File
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <table class="table table-sm">
                    <thead>
                    <tr>
                        <th>Prodotto</th>
                        <th class="text-right">NMU</th>
                        <th class="text-right">PAP</th>
                        <th>Gamma</th>
                        <th class="text-right">Codice Fin</th>
                        <th>Tipologia</th>
                        <th>Pagamento</th>
                        <th class="text-right">ET</th>
                        <th class="text-right">Rate</th>
                        <th class="text-right">Rata Finale</th>
                        <th class="text-right">Sconto</th>
                        <th class="text-right">% Sconto</th>
                        <th class="text-right">Q.tà in magazzino</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ngx-spinner
                            bdColor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="square-jelly-box"
                            name="table-installments"
                            [fullScreen]="false"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <tr *ngFor="let product of installments">
                        <td>{{product.product_name}}</td>
                        <td class="text-right">{{product.nmu}}</td>
                        <td class="text-right">{{product.pap | currency: '€ ' }}</td>
                        <td>{{product.type}}</td>
                        <td class="text-right">{{product.fin_code}}</td>
                        <td>{{product.fin_type}}</td>
                        <td>{{product.fin_payment}}</td>
                        <td class="text-right">{{product.entry_ticket | currency: '€ '}}</td>
                        <td class="text-right">{{product.installments}}</td>
                        <td class="text-right">{{product.last_installment | currency: '€ '}}</td>
                        <td class="text-right">{{product.discount_value | currency: '€ '}}</td>
                        <td class="text-right">{{product.discount | percent}}</td>
                        <td class="text-right text-bold" [ngClass]="{'bg-green': product.stocks > 0}">{{product.stocks}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer" *ngIf="installments?.length>0">
    <span><span translate>There are</span><b> {{ this.installmentsPagination.totalCount }}</b>
        {{this.installmentsPagination.totalCount <= 1 ? 'installment' : 'installments'|translate}}.</span>
                <ngb-pagination *ngIf="installmentsPagination.pageCount>1"
                                size="sm"
                                [pageSize]="installmentsPagination.perPage"
                                [maxSize]="10"
                                [(page)]="installmentsPagination.currentPage"
                                [collectionSize]="installmentsPagination.totalCount"
                                (pageChange)="loadInstallments()"
                                [boundaryLinks]="true"
                ></ngb-pagination>
            </div>
        </div>
    </div>
</div>

<ng-template #fileFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Listino Finanziamenti</h4>
        <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="dismissModal()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="fileBusy">
        <form [formGroup]="fileForm" (ngSubmit)="saveFile()">
            <div class="form-row">
                <div class="col-12 form-group">
                    <label for="pdfFile" translate>File</label>
                    <div class="custom-file" *ngIf="!file">
                        <input id="pdfFile" type="file" class="custom-file-input"
                               (change)="handleFileInput($event?.target?.files[0])">
                        <label class="custom-file-label" for="pdfFile" translate>
                            Upload file...
                        </label>
                    </div>
                    <div *ngIf="file">
                        <span class="text-break">{{file}}</span>
                        <button class="btn btn-xs btn-warning float-right" type="button"
                                (click)="handleFileRemoveModal()">
                            <i class="fa fa-times m-0 px-1"></i>
                        </button>
                    </div>
                    <val-errors controlName="file">
                        <ng-template valError="required">
                            <span translate>File is required</span>
                        </ng-template>
                    </val-errors>
                </div>
                <div class="col-12 mt-4">
                    <button type="submit" class="btn btn-success btn-block" [disabled]="fileForm.invalid">
                        <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
