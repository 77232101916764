<div class="modal-header">
    <h4 class="modal-title clamp-title" translate>{{document?.company_name}}</h4>
    <button (click)="dismissModal()"
            type="button"
            class="close"
            aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [ngBusy]="busy">
    <ul class="list-group list-group-flush">
        <li class="list-group-item px-0">
            <strong translate>Category</strong>
            <span class="float-right">{{document?.category}}</span></li>
        <li class="list-group-item px-0"><strong translate>Type</strong>
            <span class="float-right" translate>{{findType(document?.type)}}</span></li>
        <li class="list-group-item px-0"><strong translate>VAT type</strong>
            <span class="float-right" translate>{{findVatType(document?.vat_type)}}</span></li>
        <li class="list-group-item px-0"><strong translate>Total to pay</strong>
            <span class="float-right">{{document?.total_to_pay|currency:currency}}</span></li>
        <li class="list-group-item px-0"><strong translate>Doc total amount</strong>
            <span class="float-right">{{document?.total_amount|currency:currency}}</span></li>
        <li class="list-group-item px-0"><strong translate>Net amount</strong>
            <span class="float-right">{{document?.net_amount|currency:currency}}</span></li>
        <li class="list-group-item px-0"><strong translate>VAT amount</strong>
            <span class="float-right">{{document?.vat_amount|currency:currency}}</span></li>
        <li class="list-group-item px-0"><strong translate>WHT amount</strong>
            <span class="float-right">{{document?.withholding_tax|currency:currency}}</span></li>
        <li class="list-group-item px-0" *ngIf="document?.date"><strong translate>Date</strong>
            <span class="float-right">{{document?.date|amDateFormat:localFormat}}</span></li>
        <li class="list-group-item px-0" *ngIf="document?.vat_date"><strong translate> VAT Date</strong>
            <span class="float-right">{{document?.vat_date|amDateFormat:localFormat}}</span></li>
        <li class="list-group-item px-0" *ngIf="document?.pdf_file"><strong translate>Pdf file</strong>
            <span class="float-right"><a [href]="document?.pdf_file" target="_blank">{{document?.pdf_file}}</a></span>
        </li>
        <li class="list-group-item px-0" *ngIf="document?.xml_file"><strong translate>Xml file</strong>
            <span class="float-right"><a [href]="document?.xml_file" target="_blank">{{document?.xml_file}}</a></span>
        </li>
        <li class="list-group-item px-0" *ngIf="document?.note"><strong translate>Note</strong>
            <span class="d-block" [innerHTML]="document?.note"></span></li>
        <li class="list-group-item px-0"></li>
    </ul>
    <div class="payment-container" *ngIf="showPayments">
        <app-payment-view [document]="document"></app-payment-view>
    </div>
</div>
