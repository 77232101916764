import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StoreService} from '@services/store.service';
import {StoreBrand} from '@models/store/store-product';

@Component({
    selector: 'app-operator-modal',
    templateUrl: './operator-modal.component.html',
    styleUrls: ['./operator-modal.component.css']
})
export class OperatorModalComponent implements OnInit {
    @Input() brands: StoreBrand[] = [];
    @Input() selectedBrand: StoreBrand;
    busy: any;
    operatorForm = new FormGroup({
        name: new FormControl('', Validators.required),
    });

    constructor(private modalService: NgbModal,
                private storeService: StoreService) {
    }

    ngOnInit(): void {
        this.loadData();
    }

    saveOperator() {
        const brand = new StoreBrand();
        brand.nome = this.operatorForm.value.name;
        if (this.selectedBrand) {
            return this.changeBrand(brand);
        }
        return this.addBrand(brand);

    }

    changeBrand(brand: StoreBrand) {
        this.busy = this.storeService.putBrand(this.selectedBrand.id, brand).subscribe((response: StoreBrand) => {
            const index = this.brands.findIndex(data => data.id === response.id);
            if (index >= 0) {
                this.brands[index] = response;
            }
            this.storeService.successAlert('Changed Brand!');
            this.dismissModal();
        }, err => {
            this.storeService.errorAlert(err.message);
            this.dismissModal();
        });
    }

    addBrand(brand: StoreBrand) {
        this.busy = this.storeService.postBrand(brand).subscribe((response: StoreBrand) => {
            this.brands.unshift(response);
            this.storeService.successAlert('Created Brand!');
            this.dismissModal();
        }, err => {
            this.storeService.errorAlert(err.message);
            this.dismissModal();
        });
    }

    loadData() {
        if (this.selectedBrand) {
            this.operatorForm.setValue({
                name: this.selectedBrand.nome
            });
        }
    }

    dismissModal() {
        this.modalService.dismissAll();
    }
}
