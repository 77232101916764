import { Component, OnInit, ViewChild } from "@angular/core";
import { OperatorService } from "@app/services/operator.service";
import { ModalComponent } from "@app/widgets/modal/modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from "angular-animations";
import { Subscription } from "rxjs";

@Component({
  selector: "app-costs-management",
  templateUrl: "./costs-management.component.html",
  styleUrls: ["./costs-management.component.css"],
  animations: [
    fadeInOnEnterAnimation({ duration: 200 }),
    fadeOutOnLeaveAnimation({ duration: 200 }),
  ],
})
export class CostsManagementComponent implements OnInit {
  @ViewChild("messageModal") messageModal: ModalComponent;
  modal = {
    title: "",
    icon: "",
    message: "",
    description: "",
  };

  _periodo = null;
  _operatore = null;

  startup: any;
  busy: Subscription;
  data: any;

  periodo;
  operatore;

  constructor(
    private operatorService: OperatorService
  ) {}

  ngOnInit() {
    
  }

  refresh(operatore, periodo) {
    this.operatore = operatore;
    this.periodo = periodo;
    this.busy = this.operatorService
      .get(
        `/costs-management?operatore=${operatore}&periodo=${periodo}`
      )
      .subscribe((data) => {
        this.data = data.result;
      });
  }

  showModal(title, icon, message, description) {
    this.modal.title = title;
    this.modal.icon = icon;
    this.modal.message = message;
    this.modal.description = description;
    this.messageModal.open();
  }

  save() {
    this.busy = this.operatorService
      .post(
        `/costs-management?operatore=${this.operatore}&periodo=${this.periodo}`,
        { data: this.data }
      )
      .subscribe((data) => {
        this.data = data.result;

        if (data.success) {
          this.showModal(
            "Ottimo",
            "fa-check-circle text-success",
            "Operazione completata",
            "I dati sono stati salvati correttamente."
          );
        } else {
          this.showModal(
            "Peccato",
            "fa-times-circle text-danger",
            "Errore",
            "Il salvataggio non è andato a buon fine..."
          );
        }
      });
  }

  getRows(card: any) {
    if (card.hasOwnProperty("parent")) {
      return this.data[card.parent][card.data].rows;
    }

    return this.data[card.data].rows;
  }

  getCols(card: any) {
    if (card.hasOwnProperty("parent")) {
      return this.data[card.parent][card.data].cols;
    }

    return this.data[card.data].cols;
  }
}
