<ng-template #documentModal [ngBusy]="busy">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Carica Documento</h4>
        <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-content></ng-content>
        <div class="custom-file" id="customFile" lang="es">
            <input type="file" class="custom-file-input" (change)="uploadFile($event)"
                   id="exampleInputFile" aria-describedby="fileHelp">
            <label class="custom-file-label" for="exampleInputFile">
                Seleziona il file
            </label>
        </div>
    </div>
</ng-template>
