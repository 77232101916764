import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {DataService} from '@services/data.service';
import {Invoice, INVOICE_APPROVED, INVOICE_TO_APPROVE} from '@models/invoice';
import {TableColumn, TableColumnAlignment, TableColumnType} from '@app/pages/partials/table/table.component';
import {AuthenticationService} from '@services/authentication.service';
import {User} from '@models/user';

@Component({
    selector: 'app-invoice-list',
    templateUrl: './invoice-list.component.html',
    styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {

    waitingInvoices = 0;
    approvedInvoices = 0;
    totalInvoiced = 0;
    totalToBePaid = 0;

    public rows: Invoice[] = [];
    public columns: TableColumn[] = [
        {key: 'id', text: '', type: TableColumnType.BUTTON},
        {key: 'id', text: 'ID Fattura'},
        {key: 'created', text: 'Ricevuta il', type: TableColumnType.DATE, alignment: TableColumnAlignment.RIGHT},
        {key: 'stato', text: 'Stato', type: TableColumnType.BADGE},
        {key: 'stato_sdi', text: 'Stato Invio SDI'},
        {key: 'numero_fattura', text: 'Num. Fattura'},
        {key: 'data_fattura', text: 'Data Fattura', type: TableColumnType.DATE, alignment: TableColumnAlignment.RIGHT},
        {key: 'scadenza_fattura', text: 'Scadenza Pagamento', type: TableColumnType.DATE, alignment: TableColumnAlignment.RIGHT},
        {key: 'totale', text: 'Totale da avere', type: TableColumnType.CURRENCY, alignment: TableColumnAlignment.RIGHT},
    ];
    busy: Subscription;
    me: User;

    constructor(
        public router: Router,
        public dataService: DataService,
        public authenticationService: AuthenticationService
    ) {
    }

    ngOnInit() {
        this.authenticationService.currentUser.subscribe(user => this.me = user);
        this.busy = this.dataService.get('/get-fatture')
            .subscribe((data: any) => {
                this.rows = new Invoice().fromArray(data.result);

                this.waitingInvoices = this.rows.filter((item) => item.stato === INVOICE_TO_APPROVE).length;
                this.approvedInvoices = this.rows.filter((item) => item.stato === INVOICE_APPROVED).length;
                this.totalInvoiced = this.rows.reduce((prev, curr) => {
                    if (!isNaN(curr.totale)) {
                        return prev + curr.totale;
                    }
                    return prev;
                }, 0);
                // this.totalToBePaid = this.rows.reduce((prev, curr) => {
                //   return prev + curr.remainingbalance;
                // }, 0);
            });
    }

}
