import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class ProvinceService {

  ENDPOINT = 'provinces';

  constructor(public request: RequestService) {

  }

  list(params = {}) {
    return this.request.get(`${this.ENDPOINT}/list`, params);
  }
}
