import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {StoreProduct} from '@models/store/store-product';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {StoreService} from '@services/store.service';

@Component({
    selector: 'app-shop-product-detail',
    templateUrl: './shop-product-detail.component.html',
    styleUrls: ['./shop-product-detail.component.css']
})
export class ShopProductDetailComponent implements OnInit {
    @Input() product: StoreProduct;
    currency = '€';
    @ViewChild('variantTemplate') private variantTemplate;
    variationModal: NgbModalRef;

    constructor(public modalService: NgbModal) {
    }

    ngOnInit(): void {
    }

    showVariantModal() {
        this.variationModal = this.modalService.open(this.variantTemplate, {size: 'lg'});
    }

    findProductType(key: any) {
        const type = StoreService.productTypes.find(data => data.key === key.toString());
        return type ? type.name : null;
    }

    findOperator(key: any) {
        const type = StoreService.operators.find(data => data.key === key.toString());
        return type ? type.name : null;
    }

    findStatementType(key: any) {
        const type = StoreService.statementTypes.find(data => data.key === key.toString());
        return type ? type.name : null;
    }
}
