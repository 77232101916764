import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DashboardService} from '@services/dashboard.service';

@Component({
    selector: 'app-master-outbound',
    templateUrl: './master-outbound.component.html',
    styleUrls: ['./master-outbound.component.css']
})
export class MasterOutboundComponent implements OnInit {

    busy: Subscription;
    startup: any;

    _campagna: string;

    urlSubheader: string;
    urlOperatori: string;
    urlNegozi: string;
    urlDettaglio: string;

    set campagna(value: string) {
        this._campagna = value;
        this.updateUrls();
    }

    get campagna() {
        return this._campagna;
    }

    constructor(private dashboardService: DashboardService) {
    }

    ngOnInit() {
        this.busy = this.dashboardService.get('/outbound')
            .subscribe((data) => {
                this.startup = data.result;
                this.campagna = this.startup[0].id;
                this.updateUrls();
            }, (error) => {
                this.updateUrls();
            });
    }

    updateUrls(specific = null) {
        if (!specific || specific === 'subheader') {
            this.urlSubheader = `/outbound-header?campagna=${this.campagna}`;
        }
        if (!specific || specific === 'subheader') {
            this.urlOperatori = `/outbound-operatori?campagna=${this.campagna}`;
        }
        if (!specific || specific === 'subheader') {
            this.urlNegozi = `/outbound-negozi?campagna=${this.campagna}`;
        }
        if (!specific || specific === 'subheader') {
            this.urlDettaglio = `/outbound-dettaglio?campagna=${this.campagna}`;
        }
    }

}
