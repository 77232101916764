import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DataService} from '@services/data.service';
import {Router} from '@angular/router';
import {TableColumn, TableColumnType} from '@app/pages/partials/table/table.component';

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss'],
})
export class TicketListComponent implements OnInit {
  public rows = [];
  temp = [];
  public columns: TableColumn[];
  busy: Subscription;

  constructor(
    private dataService: DataService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.columns = [
      {key: 'id', text: 'ID Ticket'},
      {key: 'categoria', text: 'Categoria'},
      {key: 'operatore', text: 'Operatore'},
      {key: 'risposte', text: 'Risposte'},
      {key: 'stato', text: 'Stato', type: TableColumnType.BADGE},
      {key: 'created', text: 'Data Crezione', type: TableColumnType.DATE},
    ];

    this.busy = this.dataService
      .get(`/get-lista-ticket`)
      .subscribe((data: any) => {
        this.rows = data.result;
        this.temp = [...this.rows];
      });
  }

  onSelect(id: any) {
    this.router.navigate(['supporto/ticket', id]);
    // console.log('Activate Event', event);
  }
}
