<div class="card" [ngClass]="cardClass">
    <div class="card-body">
        <div class="d-flex align-items-center">
            <img *ngIf="image" class="d-none d-xl-inline-flex"
                 [src]="image"
                 [ngClass]="{'img-circle': rounded, 'bg-gradient-light p-1 elevation-1': background}"
                 width="160px">
            <div class="d-block w-100 pl-3">
                <h1 [class.display-6]="smallTitle" [class.display-4]="bigTitle">{{title}}</h1>
                <hr>
                <ng-content></ng-content>

                <div class="row align-items-end">
                    <div class="col-12 col-xl-4" *ngIf="agenti && !stand">
                        <label>Seleziona un key account</label>
                        <app-agente-select [master]="true" (onChange)="onAgenteSelect($event)"></app-agente-select>
                    </div>
                    <div class="col-12 col-xl-4" *ngIf="agenti && stand">
                        <label>Seleziona un presidio</label>
                        <app-stand-select [master]="true" (onChange)="onPresidioSelect($event)"></app-stand-select>
                    </div>
                    <div class="col-12 col-xl-4" *ngIf="operatori">
                        <label>Seleziona un operatore</label>
                        <div class="form-group form-group-icon form-group-dark mb-0">
                            <i class="fas fa-search"></i>
                            <select class="form-control" [(ngModel)]="this.operatore">
                                <ng-container>
                                    <option *ngFor="let op of this.listaOperatori" [value]="op">{{op}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-xl-4" *ngIf="periodoFrom">
                        <label>Seleziona periodo di confronto</label>
                        <div class="form-group form-group-icon form-group-dark mb-0">
                            <i class="far fa-calendar-alt"></i>
                            <select class="form-control" [(ngModel)]="this.from">
                                <ng-container>
                                    <option *ngFor="let per of listaPeriodi" [value]="per.value">{{per.label}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-xl-4" *ngIf="periodoTo">
                        <label>Seleziona periodo di riferimento</label>
                        <div class="form-group form-group-icon form-group-dark mb-0">
                            <i class="far fa-calendar-alt"></i>
                            <select class="form-control" [(ngModel)]="this.to">
                                <ng-container>
                                    <option *ngFor="let per of listaPeriodi" [value]="per.value">{{per.label}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-xl-4" *ngIf="periodoSingle">
                        <label>Seleziona periodo</label>
                        <div class="form-group form-group-icon form-group-dark mb-0">
                            <i class="far fa-calendar-alt"></i>
                            <select class="form-control" [(ngModel)]="this.periodo">
                                <ng-container>
                                    <option *ngFor="let per of listaPeriodi" [value]="per.value">{{per.label}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
