import {Component, Input, OnInit} from '@angular/core';
import {CartService} from '@services/cart.service';
import {DataService} from '@services/data.service';
import {Tax} from '@models/tax';
import {Cart} from '@models/shop/cart';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

    @Input()
    hideFooter = false;

    constructor(
        public cartService: CartService,
        public dataService: DataService
    ) {
    }

    ngOnInit() {
    }

}
