<div class="modal-header">
    <h4 class="modal-title clamp-title" translate>{{product.nome}}</h4>
    <button (click)="modalService.dismissAll()"
            type="button"
            class="close"
            aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ul class="list-group list-group-flush">
        <li class="list-group-item px-0">
            <strong translate>Category</strong>
            <span class="float-right">{{product.category}}</span></li>
        <li class="list-group-item px-0"><strong translate>Type</strong>
            <span class="float-right">{{findProductType(product.personalizzabile)}}</span></li>
        <li class="list-group-item px-0" *ngIf="product?.id_operatore"><strong translate>Operator</strong>
            <span class="float-right">{{findOperator(product.id_operatore)}}</span></li>
        <li class="list-group-item px-0" *ngIf="product?.serial_type"><strong translate>Serial type</strong>
            <span class="float-right">{{product.serial_type}}</span></li>
        <li class="list-group-item px-0" *ngIf="product?.tipo_estratto_conto"><strong translate>Product type for account
            statement</strong>
            <span class="float-right">{{findStatementType(product.tipo_estratto_conto)}}</span></li>
        <li class="list-group-item px-0" *ngIf="product?.prezzo"><strong translate>Standard price</strong>
            <span class="float-right">{{product.prezzo|currency:currency}}</span></li>
        <li class="list-group-item px-0" *ngIf="product?.prezzoacquisto"><strong translate>Price with credit
            card</strong>
            <span class="float-right">{{product.prezzoacquisto|currency:currency}}</span></li>
        <li class="list-group-item px-0" *ngIf="product?.compenso"><strong translate>Dealer compensation per
            unit</strong>
            <span class="float-right">{{product.compenso}}</span></li>
        <li class="list-group-item px-0" *ngIf="product?.min_quantita_free_sped"><strong translate>Free shipping with
            minimum purchase</strong>
            <span class="float-right">{{product.min_quantita_free_sped}}</span></li>
        <li class="list-group-item px-0" *ngIf="product?.qty_per_unit"><strong translate>Quantity per unit</strong>
            <span class="float-right">{{product.qty_per_unit}}</span></li>
        <li class="list-group-item px-0" *ngIf="product?.quantita"><strong translate>Quantity in stock</strong>
            <span class="float-right">{{product.quantita}}</span></li>
        <li class="list-group-item px-0" *ngIf="product?.iva"><strong translate>Product subject to VAT 22%</strong>
            <span class="float-right">
            <i class="text-success fas fa-check" *ngIf="product.iva==1"></i>
            <i class="text-danger fas fa-times" *ngIf="product.iva==0"></i>
            </span>
        </li>
        <li class="list-group-item px-0" *ngIf="product?.speciale"><strong translate>Virtual product</strong>
            <span class="float-right">
            <i class="text-success fas fa-check" *ngIf="product.speciale==1"></i>
            <i class="text-danger fas fa-times" *ngIf="product.speciale==0"></i>
            </span>
        </li>
        <li class="list-group-item px-0" *ngIf="product?.spedizione"><strong translate>Product subject to
            shipment</strong>
            <span class="float-right">
            <i class="text-success fas fa-check" *ngIf="product.spedizione==1"></i>
            <i class="text-danger fas fa-times" *ngIf="product.spedizione==0"></i>
            </span>
        </li>
        <li class="list-group-item px-0" *ngIf="product?.visibile"><strong translate>Visible</strong>
            <span class="float-right">
            <i class="text-success fas fa-check" *ngIf="product.visibile==1"></i>
            <i class="text-danger fas fa-times" *ngIf="product.visibile==0"></i>
            </span>
        </li>
        <li class="list-group-item px-0 align-items-center d-flex" *ngIf="product?.variants?.length>0">
            <span class="w-100">
                   <strong translate>Variants</strong>
            </span>
            <span class="float-right">
              <button class="btn btn-link" (click)="showVariantModal()">
                        <i class="fa fa-eye text-info"></i>
                    </button>
            </span>
        </li>
        <li class="list-group-item px-0" *ngIf="product?.sub_products?.length>0"><strong translate>Sub-products</strong>
        </li>
        <li class="list-group-item px-0" *ngFor="let sub_product of product?.sub_products">
            {{sub_product?.product?.nome}}
        </li>
    </ul>
</div>
<ng-template #variantTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>Variants</h4>
        <button (click)="variationModal.dismiss()"
                type="button"
                class="close"
                aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-product-variant-detail [variants]="product?.variants"></app-product-variant-detail>
</ng-template>
