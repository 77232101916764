import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '@models/user';
import {CampiPagamentoSkeleton, PagamentiSkeleton} from '@models/contract/contract-skeleton';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
    selector: 'app-contract-new-pagamento',
    templateUrl: './contract-new-pagamento.component.html',
    styleUrls: ['./contract-new-pagamento.component.scss']
})
export class ContractNewPagamentoComponent implements OnInit {
    @Input()
    public me: User;
    @Input()
    public skeletons: PagamentiSkeleton[] = [];
    @Input()
    public campi: CampiPagamentoSkeleton[] = [];
    @Input()
    public initialForm: {};
    @Output()
    formChange = new EventEmitter();
    @Output()
    valid = new EventEmitter();

    public form: FormGroup;


    constructor(
        public formBuilder: FormBuilder
    ) {
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            id_pagamento: [null, [Validators.required]],
            campi: this.formBuilder.array([])
        });

        this.c.id_pagamento.valueChanges.subscribe((value) => this.setCampi(value));

        this.form.patchValue(this.initialForm);

        this.form.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe((value) => {
            this.formChange.emit(value);
        });

        this.form.statusChanges.subscribe((value) => {
            this.valid.emit(this.form.valid);
        });
    }

    get c() {
        return this.form.controls;
    }

    private clearFormArray(formArray: FormArray) {
        while (formArray.length !== 0) {
            formArray.removeAt(0);
        }
    }

    getCampi(): FormGroup[] {
        const campiPagamento = this.form.controls.campi as FormArray;
        return campiPagamento.controls as FormGroup[];
    }


    private setCampi(value: any) {
        const campiPersonalizzati = this.campi.filter(item => item.id_pagamento === value);

        const campi = this.form.controls.campi as FormArray;
        this.clearFormArray(campi);

        for (const campo of campiPersonalizzati) {
            const controlsGroup: FormGroup = this.formBuilder.group({
                id_pagamento: [campo.id_pagamento, [Validators.required]],
                nome: [campo.nome, [Validators.required]],
                valore: [null, [Validators.required]]
            });

            campi.push(controlsGroup);
        }
    }
}
