<div class="modal-header">
    <h4 class="modal-title clamp-title" translate *ngIf="!isReceipt && !paymentFailed">Checkout</h4>
    <h4 class="modal-title clamp-title" translate *ngIf="isReceipt">Receipt</h4>
    <h4 class="modal-title clamp-title" translate *ngIf="paymentFailed">Payment failed</h4>
    <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="dismissModal()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [ngBusy]="busy" *ngIf="!isReceipt && !paymentFailed">
    <div class="alert alert-light" role="alert" *ngIf="!authorizationFailed && isRechargeProduct">
        <p class="text-success" translate>We have successfully authorized your purchase, and you can make payment
            now.</p>
    </div>
    <div class="alert alert-light" role="alert" *ngIf="authorizationFailed && isRechargeProduct">
        <p class="text-danger" translate>We were unable to authorize your phone number for this purchase order. Please
            change
            phone number, or select different products.</p>
    </div>
    <div class="alert alert-light" role="alert" *ngIf="!isRechargeProduct && !paymentFailed">
        <p class="text-success" translate>Confirm your order and make payment now.</p>
    </div>
    <ul class="list-group list-group-flush mb-3">
        <li class="list-group-item d-flex justify-content-between lh-condensed px-0" *ngIf="isRechargeProduct">
            <div>
                <h6 class="my-0" translate>Phone number</h6>
            </div>
            <strong>
                {{order.phone_number }}
            </strong>
        </li>
        <ng-container *ngFor="let item of order.order_items">
            <li class="list-group-item d-flex justify-content-between lh-condensed px-0">
                <div>
                    <h6 class="my-0">{{item.product_name}}</h6>
                </div>
                <span class="text-muted" *ngIf="item.product_variant_name">{{item.product_variant_name}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between lh-condensed px-0">
                <div>
                    <h6 class="my-0">Il cliente ti paga</h6>
                </div>
                <span class="text-muted">{{item.price|currency:currency}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between lh-condensed px-0">
                <div>
                    <h6 class="my-0">Noi ti addebitiamo</h6>
                </div>
                <span class="text-muted">{{item.dealer_price|currency:currency}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between px-0">
                <span translate>Il tuo guadagno è</span>
<!--                <strong>{{item.price - item.dealer_price |currency:currency}}</strong>-->
                <span class="badge badge-success text-md">{{item.price - item.dealer_price |currency:currency}}</span>
            </li>
        </ng-container>

<!--        <li class="list-group-item d-flex justify-content-between px-0">-->
<!--            <span translate>Total</span>-->
<!--            <strong>{{order.total_price|currency:currency}}</strong>-->
<!--        </li>-->

<!--        <li class="list-group-item d-flex justify-content-between px-0">-->
<!--            <span translate>Total</span>-->
<!--            <strong>{{order.total_price|currency:currency}}</strong>-->
<!--        </li>-->
    </ul>
    <div class="row">
        <div class="col-12 d-flex justify-content-between submit-btn" *ngIf="!authorizationFailed">
            <button type="submit" class="btn btn-light w-45" (click)="dismissModal()">
                <i class="fa fa-times-circle mr-2"></i> <span translate>Cancel</span>
            </button>
            <button type="button" class="btn btn-success w-45" (click)="placeOrder()">
                <i class="fa fa-check-circle mr-2"></i> <span translate>Confirm</span>
            </button>
        </div>
        <div class="col-12" *ngIf="authorizationFailed">
            <button type="button" class="btn btn-light btn-block" (click)="dismissModal()">
                <i class="fa fa-times-circle mr-2"></i> <span translate>Close</span>
            </button>
        </div>
    </div>
</div>
<div class="modal-body" *ngIf="paymentFailed">
    <div class="alert alert-light" role="alert">
        <p class="text-danger" translate>You don't have enough credit to make a payment!</p>
    </div>
    <div class="w-100 justify-content-center d-flex my-4">
        <a class="btn btn-info w-45" href="/negozio/acquista" translate>
            SHOP NOW
        </a>
    </div>
</div>
<app-receipt [order]="order" *ngIf="isReceipt && !paymentFailed"></app-receipt>
