import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {TableColumn, TableColumnAlignment} from "@app/pages/partials/table/table.component";
import {DataService} from "@services/data.service";
import {takeUntil} from "rxjs/operators";

interface ItemClassifica {
  id: number;
  presidio: string;
  totale_contratti: number;
  al: number;
  mnp: number;
}

@Component({
  selector: 'app-classifica-stand',
  templateUrl: './classifica-stand.component.html',
  styleUrls: ['./classifica-stand.component.css']
})
export class ClassificaStandComponent implements OnInit, OnDestroy {

  busy: Subscription;
  // additional info
  totalePresidi = 0;
  presidiAttivi = 0;
  totaleContratti = 0;
  totaleFatturato = 0;
  // observable
  private destroy$ = new Subject();
  classifica: [ItemClassifica];
  columns: TableColumn[] = [
    {key: 'id', text: 'ID Presidio', alignment: TableColumnAlignment.CENTER},
    {key: 'presidio', text: 'Presidio', alignment: TableColumnAlignment.CENTER},
    {key: 'al', text: 'NEW', alignment: TableColumnAlignment.CENTER},
    {key: 'mnp', text: 'MNP', alignment: TableColumnAlignment.CENTER},
    {key: 'totale_contratti', text: 'Totale Contratti', alignment: TableColumnAlignment.CENTER},
  ];
  constructor(
      private dataService: DataService,
  ) { }

  ngOnInit(): void {
    this.busy = this.dataService
        .get(`/classifica-presidi-kena`)
        // https://stackoverflow.com/questions/35042929/is-it-necessary-to-unsubscribe-from-observables-created-by-http-methods
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any) => {
          this.classifica = data.result;
          // this.filteredRows = this.classifica.slice();
          console.log(this.classifica);
          this.totalePresidi = this.classifica.length;
          this.presidiAttivi = this.classifica.filter((item) => item.totale_contratti > 0).length;
          this.totaleContratti = this.classifica.reduce((sum, current) => sum + current.totale_contratti, 0);
          this.totaleFatturato = 0;
        });
  }

  ngOnDestroy(): void {
    this.destroy$.next();  // trigger the unsubscribe
    this.destroy$.complete(); // finalize & clean up the subject stream
  }
}
