import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '@services/authentication.service';
import {map} from 'rxjs/operators';
import {environment} from '@environments/environment';
import { RequestService } from './request.service';

@Injectable({
    providedIn: 'root'
})
export class OperatorService {
    ENDPOINT = 'operator'

    constructor(
        public http: HttpClient,
        public autenthicationService: AuthenticationService,
        public request: RequestService
    ) {
    }


    post(url, data) {
        return this.http
            .post(`${environment.operatorUrl}${url}`, data)
            .pipe(map((response: any) => {
                if (response.hasOwnProperty('profile')) {
                    this.autenthicationService.updateAccountData(response.profile);
                }
                return response;
            }));
    }

    get(url, data = {}) {
        let baseUrl = environment.operatorUrl;
        return this.http.get(`${baseUrl}${url}`, {
            params: data
        })
            .pipe(map((response: any) => {
                if (response.hasOwnProperty('profile')) {
                    this.autenthicationService.updateAccountData(response.profile);
                }
                return response;
            }));
    }

    options(url, data = {}) {
        let baseUrl = environment.operatorUrl;

        return this.http.options(`${baseUrl}${url}`, {
            params: data
        })
            .pipe(map((response: any) => {
                if (response.hasOwnProperty('profile')) {
                    this.autenthicationService.updateAccountData(response.profile);
                }
                return response;
            }));
    }

    getOperatorsList() {
        return this.request.get(`${this.ENDPOINT}`);
    }
}
