import {Component, Input, OnInit} from '@angular/core';
import {DashboardService} from '@services/dashboard.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-autoload-chart',
  templateUrl: './autoload-chart.component.html',
  styleUrls: ['./autoload-chart.component.css']
})
export class AutoloadChartComponent implements OnInit {
  _url: string;
  chartData: any;

  @Input() colorScheme = {
      domain: ['#000000']
  };

  @Input() set url(value: string) {
    this._url = value;

    if (this._url) {
      this.loadChart();
    }
  }

  constructor(
      private dashboardService: DashboardService,
      private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit() {

  }

  private loadChart() {
    this.spinner.show('loader-chart');
    this.dashboardService.get(this._url)
        .subscribe((data) => {
          this.chartData = data.result.chart;
          this.colorScheme.domain = data.result.colorScheme;
          this.spinner.hide('loader-chart');
        });
  }
}
