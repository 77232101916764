import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';
import {Document, DocumentPayment} from '@models/invoice/document';
import {BankAccount} from '@models/invoice/bank-account';
import {Subject} from '@models/invoice/subject';

export class BankTransaction extends BaseModel {
    id: number;
    bank_account_id: number;
    document_id: number;
    execution_date: string;
    value_date: string;
    description: string;
    amount: string;
    code: number;
    is_credit: number;
    is_handled: number;
    is_matched: number;
    is_refund: number;
    note: string;
    payments?: DocumentPayment[];
    bank_account?: BankAccount;
    payment_ids: Array<any>;
    suggested_payments: DocumentPayment[];
    suggested_document: Document;
    suggested_subject: Subject;
    company_name?: string;
    order_id?: any;
    total_paid: any;
}

export class BankTransactionPagination extends BaseModel {
    data: BankTransaction[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class BankTransactionFile extends BaseModel {
    file: File;
    bank_account_id: number;

    get postPayloadExcelFile() {
        const payload = new FormData();
        this.addToPayload(payload, this.bank_account_id, 'bank_account_id');
        this.addToPayload(payload, this.file, 'file');
        return payload;
    }
}

export class CreditCardFile extends BaseModel {
    file: File;

    get postPayloadExcelFile() {
        const payload = new FormData();
        this.addToPayload(payload, this.file, 'file');
        return payload;
    }
}

export class CreditCardPayment extends BaseModel {
    id: number;
    order_id: string;
    amount: string;
    transaction_date: string;
    card_type: string;
}

export class CreditCardPaymentPagination extends BaseModel {
    data: CreditCardPayment[];
    _meta: MetaPagination;
    _links: Array<any>;
}
