<app-master-header
  cardClass="bg-gradient-info"
  image="assets/img/flats/destination.png"
  title="Visite Agenti"
>
  <div class="row align-items-end">
    <div class="col-lg-4 col-12">
      <label translate>Seleziona periodo</label>
      <input
        class="form-control"
        type="text"
        [(ngModel)]="filterForm.dateRange"
        daterangepicker
        (selected)="setSelectedDateRange($event.start, $event.end)"
      />
    </div>
  </div>
</app-master-header>

<div class="card">
  <div class="card-body">
    <div class="row" *ngIf="global">
      <div class="col-12 col-md-4 col-xl-2" *ngIf="defaultView">
        <div class="icon-block">
          <img class="img-fluid" src="/assets/img/flats/online-store.png" />
          <span class="title">{{ global.today_visits }}</span>
          <span class="description"> Visite di oggi </span>
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-2">
        <div class="icon-block">
          <img class="img-fluid" src="/assets/img/flats/calendar_2.png" />
          <span class="title">{{ global.period_visits }}</span>
          <span class="description"> Visite nel periodo </span>
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-2">
        <div class="icon-block">
          <img class="img-fluid" src="/assets/img/flats/percentage.png" />
          <span class="title">{{ global.average_period_visits }}</span>
          <span class="description"> Media visite </span>
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-2">
        <div class="icon-block">
          <img class="img-fluid" src="/assets/img/flats/settings_2.png" />
          <span class="title">{{ global.period_our_retailers }}</span>
          <span class="description"> Visite uniche MG </span>
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-2">
        <div class="icon-block">
          <img class="img-fluid" src="/assets/img/flats/new.png" />
          <span class="title">{{ global.period_other_retailers }}</span>
          <span class="description"> Visite uniche altri pdv </span>
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-2">
        <div class="icon-block">
          <img class="img-fluid" src="/assets/img/flats/internet.png" />
          <span class="title">{{ global.total_shops }}</span>
          <span class="description"> Negozi totali a portafoglio </span>
        </div>
      </div>
    </div>

    <div class="row mt-4" *ngIf="global">
      <div class="col-12 col-md-4 col-xl-2">
        <div class="icon-block">
          <img class="img-fluid" src="/assets/img/flats/map.png" />
          <span class="title">{{ global.period_cities }}</span>
          <span class="description"> Comuni visitati </span>
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-2">
        <div class="icon-block">
          <img class="img-fluid" src="/assets/img/flats/placeholder.png" />
          <span class="title">{{ global.cities_with_shops }}</span>
          <span class="description"> Comuni con negozi MG </span>
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-2">
        <div class="icon-block">
          <img class="img-fluid" src="/assets/img/flats/global-network.png" />
          <span class="title">{{ global.covered_cities }}</span>
          <span class="description"
            >Comuni potenzialmente coperti con agenti</span
          >
        </div>
      </div>
    </div>
  </div>
</div>

<ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills border-0 mb-3">
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="default"
    color="#fff"
    type="square-jelly-box"
    name="cards"
    [fullScreen]="false"
  >
    <p style="color: white">Caricamento...</p>
  </ngx-spinner>
  <li ngbNavItem>
    <a ngbNavLink>Visuale</a>
    <ng-template ngbNavContent>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3" *ngFor="let card of rows">
          <div class="card bg-gradient-white">
            <div class="card-body box-profile">
              <div class="text-center">
                <img
                  class="profile-user-img img-fluid img-circle"
                  src="assets/img/flats/agent.png"
                />
              </div>
              <h3 class="text-center profile-username">{{ card.agente }}</h3>
              <p class="text-center text-muted mb-2"></p>
              <ul class="list-group list-group-unbordered">
                <li class="list-group-item" *ngIf="defaultView">
                  <img
                    class="mr-2"
                    src="assets/img/flats/online-store.png"
                    height="24px"
                  />
                  <strong>Negozi visitati oggi</strong
                  ><span class="float-right"
                    >{{ card.today_visits }} su {{ card.total_shops }}</span
                  >
                </li>
                <li class="list-group-item">
                  <img
                    class="mr-2"
                    src="assets/img/flats/calendar_2.png"
                    height="24px"
                  />
                  <strong>Negozi visitati nel periodo</strong
                  ><span class="float-right"
                    >{{ card.period_our_retailers }} su
                    {{ card.total_shops }}</span
                  >
                </li>
                <li class="list-group-item">
                  <img
                    class="mr-2"
                    src="assets/img/flats/destination.png"
                    height="24px"
                  />
                  <strong>Comuni Visitati</strong
                  ><span class="float-right"
                    >{{ card.period_cities }} su {{ card.covered_cities }}</span
                  >
                </li>
                <li class="list-group-item">
                  <img
                    class="mr-2"
                    src="assets/img/flats/percentage.png"
                    height="24px"
                  />
                  <strong>Media visite giornaliere</strong
                  ><span class="float-right">{{
                    card.average_period_visits
                  }}</span>
                </li>
              </ul>
              <div class="p-2 bg-gradient-dark elevation-1 rounded my-2">
                <p class="text-white text-center">Esito delle visite</p>
              </div>
              <ul class="list-group list-group-unbordered">
                <li class="list-group-item">
                  <img
                    class="mr-2"
                    src="assets/img/flats/settings_2.png"
                    height="24px"
                  />
                  <strong>Negozi diventati produttivi</strong
                  ><span class="float-right">{{
                    card.retailers_started_to_be_productive
                  }}</span>
                </li>
                <li class="list-group-item">
                  <img
                    class="mr-2"
                    src="assets/img/flats/diagram.png"
                    height="24px"
                  />
                  <strong>Negozi con incremento produttività</strong
                  ><span class="float-right">{{
                    card.retailers_increased_sales
                  }}</span>
                </li>
                <li class="list-group-item">
                  <img
                    class="mr-2"
                    src="assets/img/flats/dollar.png"
                    height="24px"
                  />
                  <strong>Negozi che hanno comprato plafond</strong
                  ><span class="float-right">{{
                    card.retailers_bought_plafond
                  }}</span>
                </li>
                <li class="list-group-item">
                  <img
                    class="mr-2"
                    src="assets/img/flats/sim-card.png"
                    height="24px"
                  />
                  <strong>Negozi che hanno comprato sim</strong
                  ><span class="float-right">{{
                    card.retailers_bought_sim
                  }}</span>
                </li>
              </ul>
              <a
                class="btn bg-gradient-info text-white btn-block btn-sm mt-2"
                [routerLink]="['/visite-riepilogo', card.id_agente]"
                >Dettaglio</a
              >
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
  <li ngbNavItem>
    <a ngbNavLink>Tabellare</a>
    <ng-template ngbNavContent>
      <div class="card bg-gradient-light">
        <div class="card-body p-0">
          <table class="table">
            <thead>
              <tr>
                <th>Key Account</th>
                <th class="text-right">Visite Totali</th>
                <th class="text-right">PDV Totali</th>
                <th class="text-right">PDV Visitati</th>
                <th class="text-right">PDV Non MG</th>
                <th class="text-right">Comuni Totali</th>
                <th class="text-right">Comuni Visitati</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of rows">
                <td>
                  <a [routerLink]="['/visite-riepilogo', row.id_agente]">{{
                    row.agente
                  }}</a>
                </td>
                <td class="text-right">{{ row.period_visits }}</td>
                <td class="text-right">{{ row.total_shops }}</td>
                <td class="text-right">{{ row.period_our_retailers }}</td>
                <td class="text-right">{{ row.period_other_retailers }}</td>
                <td class="text-right">{{ row.covered_cities }}</td>
                <td class="text-right">{{ row.period_cities }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <a class="link" (click)="exportList()">Esporta la lista completa</a>
        </div>
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
