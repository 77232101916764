import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ShopProduct} from '@models/e-pay/shop-product';
import {EPayService} from '@services/e-pay.service';

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
    @Input() product: ShopProduct;
    currency = EPayService.currency;

    constructor(public modalService: NgbModal) {
    }

    ngOnInit(): void {
    }

}
