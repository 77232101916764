import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthenticationService} from '@services/authentication.service';
import {User} from '@models/user';
import {Helpers} from '@helpers/helpers';

interface Month {
  key: string;
  value: string;
}

@Component({
  selector: 'app-period-selector',
  templateUrl: './period-selector.component.html',
  styleUrls: ['./period-selector.component.scss']
})
export class PeriodSelectorComponent implements OnInit {

  @Input()
  icon: string;

  @Input()
  title: string;

  @Input()
  gradient: string;

  @Output()
  onFilterChange = new EventEmitter<string>();

  user: User;
  months: Month[] = [];

  constructor(
      private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe((user) => {
      this.user = user;
      this.generateMonths();
    });
  }

  ngOnInit(): void {
  }

  /**
   * Genera mesi per il filtro a partire dalla data di creazione della company
   */
  generateMonths() {
    // const startingDate = new Date();
    // const dates = Helpers.dateRange(startingDate, new Date());
    // dates.forEach(date => this.months.unshift({
    //   key: date.getMonth() + '-' + date.getFullYear(),
    //   value: Helpers.capitalize(date.toLocaleString('default', { month: 'long' })) + ' ' + date.getFullYear(),
    // }));
  }

}
