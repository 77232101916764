import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { EconomicsService } from "./../../services/economics.service";

@Component({
  selector: "app-master-economics",
  templateUrl: "./master-economics.component.html",
  styleUrls: ["./master-economics.component.css"],
})
export class MasterEconomicsComponent implements OnInit {
  loading = false;
  data;

  defaultMonthRange;
  dateRange = { start: "", end: "", from: null, to: null };
  apiFormat = "YYYY-MM";

  vendor = "Kena Mobile";

  // periodi = Helpers.getPeriods();
  // periodoLabel;

  // data = {
  //     header: {
  //         fatturato: 0,
  //         costi: 0,
  //         utile: 0
  //     },
  //     fatturato: [],
  //     costi: {
  //         agenti: {
  //             fatturato: 0,
  //             percentuale: 0
  //         },
  //         negozi: {
  //             fatturato: 0,
  //             percentuale: 0
  //         },
  //         ufficio: {
  //             fatturato: 0,
  //             percentuale: 0
  //         },
  //     }
  // };

  // _periodo: string;

  // set periodo(value: string) {
  //     this._periodo = value;
  //     this.updateUrls();
  // }

  // get periodo() {
  //     return this._periodo;
  // }

  constructor(private service: EconomicsService) {
    // this.periodo = this.periodi[0].value;
    // this.periodoLabel = this.periodi[0].label;
    this.defaultMonthRange = [
      moment().startOf("month"),
      moment().endOf("month"),
    ];
  }

  ngOnInit() {}

  setSelectedDateRange(from: any, to: any) {
    this.dateRange.from = moment(from);
    this.dateRange.to = moment(from);
    this.dateRange.start = moment(from).format(this.apiFormat);
    this.dateRange.end = moment(to).format(this.apiFormat);
    this.refresh();
  }

  refresh() {
    this.loading = true;
    this.service
      .overview(this.dateRange.start, this.dateRange.end, this.vendor)
      .subscribe((data) => {
        this.data = data;
        this.loading = false;
      });
  }

  // updateUrls() {
  //     this.busy = this.dashboardService.get(`/economics-data?periodo=${this.periodo}`)
  //         .subscribe((data) => {
  //             this.data = data.result;
  //         });
  // }
}
