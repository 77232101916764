import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '@services/authentication.service';
import {CartService} from '@services/cart.service';
import {distinctUntilChanged} from 'rxjs/operators';
import {Profile, ROLE_AGENT} from '@models/user';
import {DealerService} from '@app/services/dealer.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {StorePaymentType} from '@models/store/store-product';
import {StoreService} from '@services/store.service';
import {lightSpeedInAnimation} from 'angular-animations';
import {Cart} from '@models/shop/cart';
import {Tax} from '@models/tax';
import {DataService} from '@services/data.service';

@Component({
    selector: 'app-shop-buy',
    templateUrl: './shop-buy.component.html',
    styleUrls: ['./shop-buy.component.scss'],
})
export class ShopBuyComponent implements OnInit {
    me: Profile;
    busy: Subscription;
    orderForm: FormGroup;
    isShippingValid = false;
    isDealerValid = false;
    id_dealer = null;
    shippingData = {
        city: null,
        province: null,
        pcode: null,
        address: null,
        number: null,
    };
    accettaTermini = false;
    accettaTerminiKD: false;
    availablePayments: StorePaymentType[] = [];
    paymentTypes = StoreService.paymentTypes;
    compensation = StoreService.paymentTypes[2]?.key;
    private taxes: Tax[];
    loadingTaxes = false;

    constructor(
        public dealerService: DealerService,
        public formBuilder: FormBuilder,
        public cartService: CartService,
        public authenticationService: AuthenticationService,
        private storeService: StoreService,
        public dataService: DataService
    ) {
    }

    ngOnInit() {
        this.loadAvailablePayments();
        this.me = this.authenticationService.currentUserValue.profile;
        if (this.me.role === 'dealer') {
            this.shippingData = {
                city: this.me.sede_comune,
                province: this.me.sede_provincia,
                pcode: this.me.sede_cap,
                address: this.me.sede_indirizzo,
                number: this.me.sede_civico,
            };

            if (this.me.id_indirizzo_spedizione) {
                this.shippingData = {
                    city: this.me.spedizione_comune,
                    province: this.me.spedizione_provincia,
                    pcode: this.me.spedizione_cap,
                    address: this.me.spedizione_indirizzo,
                    number: this.me.spedizione_civico,
                };
            }
        }

        this.orderForm = this.formBuilder.group({
            description: ['', []],
            id_indirizzo_spedizione: [this.me.id_indirizzo_spedizione, []],
            so_pagamento: [null, [Validators.required]],
            compensazione_parziale: [false, []],
        });

        this.orderForm.controls.so_pagamento.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((val) => {
                this.cartService.setPayment(val);
                this.loadTaxes(val);
            });
    }

    val(path) {
        return this.orderForm.get(path).value;
    }

    buy() {
        // this.buying =
        this.cartService.buy(
            this.shippingData,
            this.val('so_pagamento'),
            this.val('id_indirizzo_spedizione'),
            this.val('compensazione_parziale'),
            this.val('description'),
            this.id_dealer
        );
    }

    hasSmsProducts() {
        const product = this.cartService.cart.items.find(
            (item) => item.product.productcategory === 'SMS'
        );

        if (product) {
            return true;
        }

        return false;
    }

    hasKDActivation() {
        const product = this.cartService.cart.items.find(
            (item) => item.product.productcategory === 'KDRicarica'
        );

        if (product) {
            return true;
        }

        return false;
    }

    cantBuy() {
        if(this.loadingTaxes) {
            return true;
        }

        if (
            this.authenticationService.currentUserValue.profile.role == ROLE_AGENT
        ) {
            if (!this.id_dealer) {
                return true;
            }
        }

        if (!this.isShippingValid) {
            return true;
        }

        if (this.hasSmsProducts()) {
            return (
                !this.orderForm.valid ||
                this.cartService.cart.items.length === 0 ||
                !this.accettaTermini
            );
        }

        if (this.hasKDActivation()) {
            return (
                !this.orderForm.valid ||
                this.cartService.cart.items.length === 0 ||
                !this.accettaTerminiKD
            );
        }

        return !this.orderForm.valid || this.cartService.cart.items.length === 0;
    }

    assignment($event: any) {
        this.busy = this.dealerService
            .getDealer($event, {expand: 'city,province'})
            .subscribe((data) => {
                this.id_dealer = data.id;
                this.shippingData = {
                    city: data.city,
                    province: data.province,
                    pcode: data.cap,
                    address: data.indirizzo,
                    number: data.civico,
                };
            });
    }

    changeAddress($event: any) {
        this.shippingData = $event;
    }

    loadAvailablePayments() {
        const list = this.cartService.cart.items.map((item) =>
            item.product.id
        );
        const params = {
            product_ids: list.join(',')
        };
        this.storeService.getAvailablePayments(params).subscribe((response: StorePaymentType[]) => {
            this.availablePayments = response;
            this.paymentTypes.forEach((item) => {
                if (this.disabledCompensation(item?.key)) {
                    item.disabled = true;
                } else {
                    const index = this.availablePayments?.findIndex((data) => data?.name === item?.key);
                    item.disabled = index <= -1;
                }
            });
        });
    }

    filterProducts(key) {
        const list = [];
        const defaultList = [];
        this.cartService.cart.items.forEach((item) => {
            const index = item.product?.payment_types?.findIndex(data => data.name === key);
            if (index < 0) {
                list.push(item?.product?.productname);
            }
            defaultList.push(item?.product?.productname);
        });
        return list?.length > 0 ? list : defaultList;
    }

    disabledCompensation(key) {
        return key === this.compensation && Number(this.me.credito) < Number(this.cartService.cartGrandTotal);
    }

    loadTaxes(paymentType) {
        this.loadingTaxes = true;
        this.orderForm.patchValue({
            compensazione_parziale: false
        });
        const params = [];
        const cart = this.cartService.cart;
        cart.items.forEach(item => {
            params.push({
                id: item.product?.id,
                qty: item.quantity
            });
        });
        const ids = this.cartService.cart?.items?.map((item) => item?.product?.id);
        const qty = this.cartService.cart?.items?.map((item) => item?.quantity);
        this.dataService.get('/get-shop-taxes', {productIds: ids, quantities: qty, payment: paymentType})
            .subscribe((data: any) => {
                this.taxes = new Tax().fromArray(data?.result);
                this.cartService.setTaxes(this.taxes);
                this.loadingTaxes = false;
            });
    }
}
